import { Authorizer } from 'Auth/utils/Authorizer';
import { ProjectDTO, OrganisationDTO, PersonDTO, WorkDTO } from '../api/core/models';
import { DashboardType as ViewType } from '../Dashboard/types';

export interface ProjectDashboards {
  persons: number[];
  personsByProjectIncludingChilds: number[];
}
export interface PersonsDashboards {
  organisations: number[];
  projects: number[];
}

export type ExternalIdType = 'orga'|'project'|'person'|'work'|'funder';

export enum DetailViewDashboardType {
  WORKS = 'works',
  WORKS_BY_PERSONS = 'works_by_persons',
  WORKS_BY_PERSONS_HIERARCHY = 'works_by_persons_hierarchy',
  PEOPLE = 'people',
  PEOPLE_INCLUDING_CHILDS = 'people_including_childs',
  ORGS = 'orgs',
  PROJECTS = 'projects',
}

export type ViewDashboards = PersonsDashboards | ProjectDashboards;
export type ViewDashboardKeys = keyof PersonsDashboards | keyof ProjectDashboards;

export { ViewType };

export type DetailViewType = WorkDTO | PersonDTO | OrganisationDTO | ProjectDTO;

export interface DetailViewProps {
  entity: ViewType;
  id: number;
  viewLoading: boolean;
  viewError: string | null;
  view: DetailViewType | null;
  dashboardData: ViewDashboards;
  dashboardLoading: number;
  avatarURL?: string;
}

export interface InnerDetailViewProps extends DetailViewProps {
  authorizer: Authorizer;
}

export enum ProjectRelationType {
  CHILD = 'child',
  PARENT = 'parent',
  PARTNER = 'partner',
  PREDECESSOR = 'predecessor',
  SUCCESSOR = 'successor',
}

export interface TypedId {
  name: string;
  value: string;
}
