/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchDocument
 */
export interface PatchDocument {
    /**
     * 
     * @type {string}
     * @memberof PatchDocument
     */
    op?: PatchDocumentOpEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchDocument
     */
    path?: string;
    /**
     * 
     * @type {object}
     * @memberof PatchDocument
     */
    value?: object;
    /**
     * 
     * @type {string}
     * @memberof PatchDocument
     */
    from?: string;
}

export function PatchDocumentFromJSON(json: any): PatchDocument {
    return PatchDocumentFromJSONTyped(json, false);
}

export function PatchDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'op': !exists(json, 'op') ? undefined : json['op'],
        'path': !exists(json, 'path') ? undefined : json['path'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'from': !exists(json, 'from') ? undefined : json['from'],
    };
}

export function PatchDocumentToJSON(value?: PatchDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'op': value.op,
        'path': value.path,
        'value': value.value,
        'from': value.from,
    };
}

/**
* @export
* @enum {string}
*/
export enum PatchDocumentOpEnum {
    Add = 'add',
    Remove = 'remove',
    Replace = 'replace',
    Move = 'move',
    Copy = 'copy',
    Test = 'test'
}


