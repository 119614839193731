import React, { ReactElement } from 'react';
import { WorkPlaceDTO } from '../../../../api/core/models';
import { MultiInputControls } from '../../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { TextInputP } from '../../../../BaseLayout/components/form/generic/TextInput';
import { CheckPermissionFunction } from '../../../../Auth/components/DataModelGate';
import { DefaultWorkPlace } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';
import { InputLabel } from 'BaseLayout/components/form/generic/InputLabel';

const messages = defineMessages({
  publisherPlace: { id: 'work.attr.places.attr.place' }
});

interface PlacesProps {
  workPlaces?: WorkPlaceDTO[];
  onChange: (workPlace: WorkPlaceDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

export function Place({ workPlaces, onChange, checkPermission, validation }: PlacesProps): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <Border>
      <div className="form" sx={{".work-place": {
        fontSize: '20px',
        i: {
          fontSize: '16px',
          verticalAlign: 'super'
        },
        "div > div": {
          fontSize: '16px'
        }
      }}}>
        <InputLabel
          classes={["work-place"]}
          variant="labelY"
          label={intl.formatMessage({id: 'work.attr.places'})}
          help={intl.formatMessage({id: 'work.attr.places.help'})}
        />
        <MultiInputControls<WorkPlaceDTO>
          lines={workPlaces}
          onChange={onChange}
          add
          remove
          defaultRow={DefaultWorkPlace}
          renderLines={(workPlace: WorkPlaceDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number)=>{
            return (<div className="innerBorder">
              <div className="fieldRow">
                <TextInputP
                  name={intl.formatMessage(messages.publisherPlace)}
                  value={workPlace.place}
                  onChange={(place: string): void => onChange2({ ...workPlace, place })}
                  permissions={checkPermission('place')}
                  validation={validation?.children[idx]?.place}
                  autoFocus={true}
                />
                {!!actions.delete && (
                  <div className='deleteButton'>
                    <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                  </div>
                )}
              </div>
            </div>);
          }}
          renderEnd={(actionAdd) => (
            <div className="addButton">
              <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
            </div>
          )}
        />
      </div>
    </Border>
  );
}
