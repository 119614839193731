import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { ProjectSuggest } from '../../auto-complete/ProjectSuggest';
import { AdminDataWorkDTO, WorkDTO, ReferencedOrganisationDTO } from '../../../../api/core';
import { DataModelGate } from '../../../../Auth/components';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckboxP } from '../../../../BaseLayout/components/form/generic/Checkbox';
import { CheckPermissionFunction, ReadWritePermissions, WritableParam } from '../../../../Auth/components/DataModelGate';

const messages = defineMessages({
  belongToProject: { id: 'work.attr.adminDataWork.attr.belongToProject' },
  belongToProjectHelp: { id: 'work.attr.adminDataWork.attr.belongToProject.help' },
  projectsChecked: { id: 'work.attr.adminDataWork.attr.belongToProject.checked' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  validation: ValidationResult;
  validation2: ValidationResult;
}

export function BelongToProjects({modifiedModel, entity, updateField, validation, validation2}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <>
    <DataModelGate always={false} entity={entity} field="projects">
      {(writable: WritableParam, _readable: boolean, checkPermission: (child?: string) => ReadWritePermissions) => (
        <ProjectSuggest
          name={intl.formatMessage(messages.belongToProject)}
          help={intl.formatMessage(messages.belongToProjectHelp)}
          required={isRequired(checkPermission())}
          validation={validation}
          disabled={!writable}
          tags={modifiedModel.adminDataWork.projects ? modifiedModel.adminDataWork.projects : []}
          onChange={(projects: ReferencedOrganisationDTO[]): void => updateField('adminDataWork', { ...modifiedModel.adminDataWork, projects })}
        />
      )}
    </DataModelGate>
    <DataModelGate always={false} entity={entity} field="projects_checked">
      {(writable: WritableParam, _readable: boolean, checkPermission: (child?: string) => ReadWritePermissions) => (
        <CheckboxP
          checked={modifiedModel.adminDataWork.projectsChecked ? modifiedModel.adminDataWork.projectsChecked : false}
          onChange={(_projectsChecked: boolean) => updateField('adminDataWork', { ...modifiedModel.adminDataWork, projectsChecked: _projectsChecked })}
          disabled={!writable}
          name={intl.formatMessage(messages.projectsChecked)}
          required={isRequired(checkPermission())}
          validation={validation2}
          permissions={checkPermission()}
        ></CheckboxP>
      )}
    </DataModelGate>
  </>;
}
