import * as React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps as IntlProps } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useEffect, useRef, useState } from 'react';

/** @jsx jsx */
import { jsx } from 'theme-ui';

const messages = defineMessages({ buttonActions: { id: 'button.Actions' } });

export interface OptionProps {
  id: string;
  updateResults: () => {};
  options: any[];
  alert?: boolean;
  classNames?: Set<string>;
}

function useOnClickOutside(ref, handler): void {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  );
}

function InnerOptionList(props: (OptionProps & IntlProps)): React.JSX.Element {
  const [optionsOpen, setOptionsOpen] = useState(false);

  const ref:React.MutableRefObject<any> = useRef();
  useOnClickOutside(ref, () => setOptionsOpen(false));

  const { intl } = props;
  const optionListClass = optionsOpen ? 'option-list active' : 'option-list';
  const classNames: Set<string> = props.classNames ?? new Set();
  classNames.add("options").add("freestyle");
  if(props.alert) {
    classNames.add("invalid");
  }
  return (
      <div className='div-option-list' ref={ref}>
        <ul className={optionListClass}>{props.options}</ul>
        <button
          className={[...classNames].join(" ")}
          title={intl.formatMessage(messages.buttonActions)}
          onClick={(e: React.FormEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setOptionsOpen(!optionsOpen);
          }}
        >
          <div sx={{display: 'inline-flex'}}>
            <div>
              <i className="rub-icons option"/>
            </div>
            <div className="edit">
              <FormattedMessage id="option.edit_label"/>
            </div>
          </div>
        </button>
    </div>
  );
}

export const OptionList = injectIntl(InnerOptionList);

export default OptionList;
