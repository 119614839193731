/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PubListConfig,
    PubListConfigFromJSON,
    PubListConfigFromJSONTyped,
    PubListConfigToJSON,
    Share,
    ShareFromJSON,
    ShareFromJSONTyped,
    ShareToJSON,
} from './';

/**
 * 
 * @export
 * @interface ConfigResponse
 */
export interface ConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof ConfigResponse
     */
    apiKey?: string;
    /**
     * 
     * @type {PubListConfig}
     * @memberof ConfigResponse
     */
    config?: PubListConfig;
    /**
     * 
     * @type {Share}
     * @memberof ConfigResponse
     */
    share?: Share;
}

export function ConfigResponseFromJSON(json: any): ConfigResponse {
    return ConfigResponseFromJSONTyped(json, false);
}

export function ConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'apiKey': !exists(json, 'apiKey') ? undefined : json['apiKey'],
        'config': !exists(json, 'config') ? undefined : PubListConfigFromJSON(json['config']),
        'share': !exists(json, 'share') ? undefined : ShareFromJSON(json['share']),
    };
}

export function ConfigResponseToJSON(value?: ConfigResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'apiKey': value.apiKey,
        'config': PubListConfigToJSON(value.config),
        'share': ShareToJSON(value.share),
    };
}


