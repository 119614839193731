/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalIdDTO
 */
export interface ExternalIdDTO {
    /**
     * 
     * @type {number}
     * @memberof ExternalIdDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalIdDTO
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalIdDTO
     */
    type?: string;
}

export function ExternalIdDTOFromJSON(json: any): ExternalIdDTO {
    return ExternalIdDTOFromJSONTyped(json, false);
}

export function ExternalIdDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalIdDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ExternalIdDTOToJSON(value?: ExternalIdDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'externalId': value.externalId,
        'type': value.type,
    };
}


