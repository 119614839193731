/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SolrWork
 */
export interface SolrWork {
    /**
     * 
     * @type {string}
     * @memberof SolrWork
     */
    id: string | null;
    /**
     * 
     * @type {string}
     * @memberof SolrWork
     */
    mainTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SolrWork
     */
    subTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SolrWork
     */
    subseriesTitle?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrWork
     */
    personName?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof SolrWork
     */
    recordCreationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SolrWork
     */
    recordChangeDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SolrWork
     */
    editorialStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof SolrWork
     */
    issued?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SolrWork
     */
    projectsChecked?: boolean;
}

export function SolrWorkFromJSON(json: any): SolrWork {
    return SolrWorkFromJSONTyped(json, false);
}

export function SolrWorkFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolrWork {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mainTitle': !exists(json, 'mainTitle') ? undefined : json['mainTitle'],
        'subTitle': !exists(json, 'subTitle') ? undefined : json['subTitle'],
        'subseriesTitle': !exists(json, 'subseriesTitle') ? undefined : json['subseriesTitle'],
        'personName': !exists(json, 'personName') ? undefined : json['personName'],
        'recordCreationDate': !exists(json, 'recordCreationDate') ? undefined : (new Date(json['recordCreationDate'])),
        'recordChangeDate': !exists(json, 'recordChangeDate') ? undefined : (new Date(json['recordChangeDate'])),
        'editorialStatus': !exists(json, 'editorialStatus') ? undefined : json['editorialStatus'],
        'issued': !exists(json, 'issued') ? undefined : json['issued'],
        'projectsChecked': !exists(json, 'projectsChecked') ? undefined : json['projectsChecked'],
    };
}

export function SolrWorkToJSON(value?: SolrWork | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mainTitle': value.mainTitle,
        'subTitle': value.subTitle,
        'subseriesTitle': value.subseriesTitle,
        'personName': value.personName,
        'recordCreationDate': value.recordCreationDate === undefined ? undefined : (value.recordCreationDate.toISOString()),
        'recordChangeDate': value.recordChangeDate === undefined ? undefined : (value.recordChangeDate.toISOString()),
        'editorialStatus': value.editorialStatus,
        'issued': value.issued,
        'projectsChecked': value.projectsChecked,
    };
}


