import { createThemeableComponent, logoComponents } from 'BaseLayout/themeable-components';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Link, Styled, useColorMode } from 'theme-ui';
import { Link as LinkRouter } from "react-router-dom";
import error404 from 'BaseLayout/assets/img/error/error-404.svg';


/** @jsx jsx */
import { Flex, Grid, jsx } from 'theme-ui';
import { ErrorPagesContext } from 'Utils/ErrorPagesProvider';

export interface Props {}

interface StringMap {
  [theme: string]: {
    homepage: string;
    imprint: string;
    guidelines?: string;
    dataProtection: string;
  };
}

const errorPageFooterLinks: StringMap = {
  rub: {homepage: "error.link.toStartpage.rub", imprint: "rub.footer.imprint_link", dataProtection: "rub.footer.dataProtection_link"},
  tudo: {homepage: "error.link.toStartpage.tudo", imprint: "tudo.footer.imprint_link", guidelines: "tudo.footer.guidelines_link", dataProtection: "tudo.footer.dataProtection_link"},
  hhu: {homepage: "error.link.toStartpage.hhu", imprint: "hhu.footer.imprint_link", dataProtection: "hhu.footer.dataProtection_link"},
};

const getThemeableLinks = (themeableLinks: StringMap, theme: string) => {
    const themeLinks = themeableLinks[theme];
    if (!themeLinks) {
      return null;
    }
    return themeLinks;
};


export const ResourceNotFoundError = (): React.JSX.Element => {
  const {  setHideHeaderFooter } = useContext(ErrorPagesContext);
  const Logo = createThemeableComponent(logoComponents);
  const intl: IntlShape = useIntl();
  const [theme] = useColorMode();
  const footerLinks = getThemeableLinks(errorPageFooterLinks, theme);

  useEffect((): void =>{
    setHideHeaderFooter(true);
  },[setHideHeaderFooter]);

  return (
    <div>
      <Flex
        as="nav"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'baseline',
          pb: 8,
          pr: 8,
          pl: 8,
          mt: '56px',
          borderBottomWidth: "1px",
          borderBottomStyle:"solid",
          borderBottomColor: "light"
        }}
      >
        <LinkRouter to="/" onClick={()=>{setHideHeaderFooter(false);}}>
          <Logo sx={{width: "10em"}} />
        </LinkRouter>
      </Flex>
      <Grid
            columns={[2]}
            mx-auto
            sx={{
              mx: 'auto',
              gap: '0px',
              gridTemplateColumns: '2fr 1fr',
              backgroundColor: '#eae9ee',
              fontSize: 3,
              '.error404-right': {
                pl: 'min(75px, 100%)',
                pr: 'min(150px, 100%)',
                m: 'auto',
                color: 'primary',
                '.error404-right-title, .error404-right-text': {
                  h2: {
                    fontWeight: 'bold'
                  },
                  mb: '1em'
                },
              },
              '.error404-left': {
                p: '0px'
              },
              img: {
                maxWidth: '980px',
                width: 'auto',
                m: 'auto'
              }
            }}
          >
            <div className='error404-left'>
              <img src={error404} alt="" />
            </div>
            <div className='error404-right'>
              <div className='error404-right-title'>
                <h2><FormattedMessage id="error.resourceNotFound.title" /></h2>
              </div>
              <div className='error404-right-text'>
                <h3><FormattedMessage id="error.resourceNotFound.text" /></h3>
              </div>
              <div>
                <Link variant="buttons.primary" href="/" sx={{py: '7px', px: '15px'}}>
                  <FormattedMessage id="error.link.toStartpage" />
                </Link>
              </div>
            </div>
      </Grid>
      {footerLinks && (
        <Flex sx={{
          backgroundColor: '#eae9ee',
          fontWeight:'bold',
          fontSize: 3,
          a: {ml: '4em', width:'max-content'},
          float: 'right',
          pl:'100%',
          pr: '1em',
          pb: '1em'
        }}>
          <Styled.a href="/" onClick={()=>{setHideHeaderFooter(false);}}>
            <FormattedMessage id={footerLinks.homepage} />
          </Styled.a>
          {footerLinks.guidelines && (
            <Styled.a href={intl.formatMessage({ id: footerLinks.guidelines })}>
              <FormattedMessage id="footer.guidelines" />
            </Styled.a>
          )}
          <Styled.a href={intl.formatMessage({ id: footerLinks.dataProtection })}>
            <FormattedMessage id="footer.dataProtection" />
          </Styled.a>
          <Styled.a href={intl.formatMessage({ id: footerLinks.imprint })}>
            <FormattedMessage id="footer.imprint" />
          </Styled.a>
        </Flex>
      )}
    </div>
  );
};
