import * as React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { ProjectApi } from '../../api/core/apis';
import { PatchDocumentFromJSON } from '../../api/core/models';
import { connectOAuth2, OAuth2AwareProps } from 'Auth/utils/connect';
import Popup from 'BaseLayout/components/popup/Popup';
import { useStatusCode } from 'Utils/StatusCodeHandler';
import { handleStatusCode422 } from 'Utils/StatusCodeUtils';

export interface DeleteProjectProps {
  projectId: number;
  projectName: string;
  isDeleteRequest?: boolean;
  onAbort?: () => void;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

type InnerProps = OAuth2AwareProps<DeleteProjectProps>;

const InnerDeleteProject = (props: InnerProps): JSX.Element => {
  const intl: IntlShape = useIntl();
  const projectApi = props.apiWithAuth(new ProjectApi());
  const { setStatusCode } = useStatusCode();

  const submitDelete = async (): Promise<void> => {
    if (!!props.isDeleteRequest) {
      try {
        await projectApi.updateProject({
          projectId: props.projectId,
          patchDocument: [PatchDocumentFromJSON({
            op: 'replace',
            path: '/adminDataProject/editorialStatus',
            value: 'deleted',
          })]
        });
        props.onSuccess();
      } catch (error) {
        props.onError(error);
      }
    } else {
      try {
        const response = await projectApi.deleteProjectRaw({ projectId: props.projectId });
        await setStatusCode({status: response.raw.status, action: 'delete', data: {entity: 'projects', name: props.projectName, id: props.projectId}});
        props.onSuccess();
      } catch (error) {
        if (error.status === 422) {
          props.onError(error);
          return handleStatusCode422(error, setStatusCode);
        }
        setStatusCode({status: error.status});
      }
    }
  };

  const title = props.isDeleteRequest ?
    intl.formatMessage({ id: 'project.deleteRequest.popup.title' }) :
    intl.formatMessage({ id: 'project.delete.popup.title' });

  return (
    <Popup
      open={true}
      title={title}
      onClose={props.onAbort}
      buttons={[
        {
          label: intl.formatMessage({ id: 'popup.button.confirm' }),
          onClick: submitDelete
        },
        {
          label: intl.formatMessage({ id: 'popup.button.abort' }),
          onClick: props.onAbort
        }
      ]}
    >
      {props.isDeleteRequest &&
      <FormattedMessage id="project.deleteRequest.popup.message(projectName)" values={{ projectName: props.projectName }} />
      }
      {!props.isDeleteRequest &&
      <FormattedMessage id="project.delete.popup.message(projectName)" values={{ projectName: props.projectName }} />
      }
    </Popup>
  );
};

export const DeleteProject = connectOAuth2(InnerDeleteProject);

export default DeleteProject;
