import * as React from 'react';
import { useEffect } from 'react';
import { Subscribe } from 'unstated';

import { OAuth2Container } from '../containers/OAuth2Container';

function Logout(props: { container: OAuth2Container; children?: any }) {

  useEffect(()=>{
    void props.container.removeToken();
    void props.container.logoutOnAuthServer();
  },[props.container]);

  return <>{props.children}</>;
}

/**
 * On mounting, remove authentication token and log out at the auth server
 */
export function OAuth2Logout(props: { children?: any }): React.JSX.Element {
    return (
      <Subscribe to={[OAuth2Container]}>
        {(container: OAuth2Container) => <Logout container={container}>{props.children}</Logout>}
      </Subscribe>
    );
}
