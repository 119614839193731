import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction, DataModelGate, WritableParam } from '../../../../Auth/components/DataModelGate';
import { WorkDTO } from '../../../../api/core';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  format: { id: 'work.attr.format' },
});

interface FormatProps {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: string) => void;
  entity: string;
  pubtypeGroup: string;
  validation: ValidationResult;
}

export function Format({
  modifiedModel,
  updateField,
  entity,
  pubtypeGroup,
  validation
}: FormatProps): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <DataModelGate entity={entity} field="format">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        <div className="col" sx={{ gridColumn: "span 1" }}>
          <SelectInputP
            listPartition={pubtypeGroup}
            onChange={(format: string): void => updateField('format', format)}
            name={intl.formatMessage(messages.format)}
            list="format_list"
            value={modifiedModel.format ? modifiedModel.format : undefined}
            inputName="format"
            placeholder=""
            permissions={checkPermission()}
            validation={validation}
          />
        </div>
      )}
    </DataModelGate>
  );
}
