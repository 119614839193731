/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeletionTrackingEntryDTO,
    DeletionTrackingEntryDTOFromJSON,
    DeletionTrackingEntryDTOToJSON,
} from '../models';

export interface GetDeletedOrganisationsRequest {
    from?: Date;
    until?: Date;
}

export interface GetDeletedPersonsRequest {
    from?: Date;
    until?: Date;
}

export interface GetDeletedProjectsRequest {
    from?: Date;
    until?: Date;
}

export interface GetDeletedPublishersRequest {
    from?: Date;
    until?: Date;
}

export interface GetDeletedWorksRequest {
    from?: Date;
    until?: Date;
}

/**
 * 
 */
export class DeletionTrackingApi extends runtime.BaseAPI {

    /**
     * Get information about all deleted organisations. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedOrganisationsRaw(requestParameters: GetDeletedOrganisationsRequest): Promise<runtime.ApiResponse<DeletionTrackingEntryDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = (requestParameters.until as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/deletion-tracking/organisation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeletionTrackingEntryDTOFromJSON(jsonValue));
    }

    /**
     * Get information about all deleted organisations. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedOrganisations(requestParameters: GetDeletedOrganisationsRequest): Promise<DeletionTrackingEntryDTO> {
        const response = await this.getDeletedOrganisationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get information about all deleted persons. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedPersonsRaw(requestParameters: GetDeletedPersonsRequest): Promise<runtime.ApiResponse<DeletionTrackingEntryDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = (requestParameters.until as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/deletion-tracking/person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeletionTrackingEntryDTOFromJSON(jsonValue));
    }

    /**
     * Get information about all deleted persons. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedPersons(requestParameters: GetDeletedPersonsRequest): Promise<DeletionTrackingEntryDTO> {
        const response = await this.getDeletedPersonsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get information about all deleted projects. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedProjectsRaw(requestParameters: GetDeletedProjectsRequest): Promise<runtime.ApiResponse<DeletionTrackingEntryDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = (requestParameters.until as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/deletion-tracking/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeletionTrackingEntryDTOFromJSON(jsonValue));
    }

    /**
     * Get information about all deleted projects. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedProjects(requestParameters: GetDeletedProjectsRequest): Promise<DeletionTrackingEntryDTO> {
        const response = await this.getDeletedProjectsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get information about all deleted publishers. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedPublishersRaw(requestParameters: GetDeletedPublishersRequest): Promise<runtime.ApiResponse<DeletionTrackingEntryDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = (requestParameters.until as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/deletion-tracking/publisher`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeletionTrackingEntryDTOFromJSON(jsonValue));
    }

    /**
     * Get information about all deleted publishers. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedPublishers(requestParameters: GetDeletedPublishersRequest): Promise<DeletionTrackingEntryDTO> {
        const response = await this.getDeletedPublishersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get information about all deleted works. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedWorksRaw(requestParameters: GetDeletedWorksRequest): Promise<runtime.ApiResponse<DeletionTrackingEntryDTO>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.until !== undefined) {
            queryParameters['until'] = (requestParameters.until as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/deletion-tracking/work`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeletionTrackingEntryDTOFromJSON(jsonValue));
    }

    /**
     * Get information about all deleted works. The result can be restricted by the optional parameters from and/or until
     */
    async getDeletedWorks(requestParameters: GetDeletedWorksRequest): Promise<DeletionTrackingEntryDTO> {
        const response = await this.getDeletedWorksRaw(requestParameters);
        return await response.value();
    }

}
