import { css, SerializedStyles } from '@emotion/react';
import { Theme } from 'theme-ui';

// @TODO: Theme the remaining colors?
export default (theme: Theme): SerializedStyles => css(`
  .react-tagsinput {
    &.single {
      position: relative;
      > span {
        display: block;
      }
      .react-tagsinput-tag {
        right: 0;
        z-index: 2;
        display: block;
        font-weight: 400;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
`);
