import * as React from 'react';
import { Modal, ModalTitle, ModalContent, ModalFooter } from '@mattjennings/react-modal';
import { Text, Button as Btn } from 'theme-ui';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface Button {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface Props {
  open: boolean;
  title: string;
  onClose?: () => void;
  buttons?: Button[];
  maxWidth?: string;
}

export const Popup: React.FC<Props> = (props: Props & { children?: React.ReactNode | undefined }): JSX.Element => {
  return (
    <div sx={{
      '.popup': {
        minWidth: '20em',
        maxWidth: props.maxWidth ? props.maxWidth : '30em',
        top: '40%',
        p: 0,
        borderLeft: 'none',
        boxShadow: '10px 20px 15px #737373',
        '.popup-title': {
          backgroundColor: 'primary',
          display: 'grid',
          color: 'white',
          '.popup-button-close': {
              color: 'white',
              mr: '10px'
          },
          '.popup-titleName': {
              color: 'white',
              fontSize: '1em',
              ml: '1em'
          }
        },
        '.popup-content': {
          m: '1em'
        },
        '.popup-buttons': {
          display: props.buttons.length > 0 ? 'block' : 'none',
          mx: props.buttons.length > 0 ? '1em' : '0',
          mb: props.buttons.length > 0 ? '1em' : '0',
          button: {
            p: '10px'
          }
        }
      }
    }}>
      <Modal className='popup' variant="outlined" open={props.open} onClose={props.onClose}>
        {({ onClose } ): JSX.Element => (
          <>
            <div className='popup-title'>
              <ModalTitle opacity={1} marginX={1} marginY={1} paddingX={1} paddingY={1}
                CloseButton={() => props.onClose ? <Btn className='popup-button-close' variant="close" onClick={onClose}><FontAwesomeIcon icon={faWindowClose} /></Btn> : <></>}
              >
                <Text className='popup-titleName' sx={{ fontSize: 3, fontWeight: 'bold' }}>{props.title}</Text>
              </ModalTitle>
            </div>

            <div className='popup-content'>
              <ModalContent opacity={1} marginX={1} marginY={1} paddingX={1} paddingY={1} sx={{ overflow: 'visible' }}>
                {props.children}
              </ModalContent>
            </div>

            <div className='popup-buttons'>
              <ModalFooter opacity={1} marginX={1} marginY={1} paddingX={1} paddingY={1} sx={{ justifyContent: 'flex-start' }}>
                {props.buttons.map((button: Button, index: number): JSX.Element =>
                  <Btn key={index} onClick={button.onClick} disabled={button.disabled !== undefined ? button.disabled : false}>{button.label}</Btn>
                )}
              </ModalFooter>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Popup;
