import React, { ReactElement } from 'react';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { PersonEmailDTO } from '../../../../api/core/models';
import { DefaultPersonEmail } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { CheckPermissionFunction } from '../../../../Auth/components/DataModelGate';
import { Flex } from '@theme-ui/components';
import { Styled } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  email: { id: 'person.attr.personEmail.attr.email' },
  type: { id: 'person.attr.personEmail.attr.type' },
});

export enum EmailField {
  EMAIL = 'email',
  TYPE = 'type',
}

interface EmailProps {
  checkPermission: CheckPermissionFunction;
  emails: PersonEmailDTO[];
  onChange: (subject: PersonEmailDTO[]) => void;
  validation: ValidationResult;
}

export function InnerEmails({ checkPermission, emails, onChange, intl, validation }: EmailProps & WrappedComponentProps): React.JSX.Element {
  return (
    <div className="form form-highlight">
      {checkPermission().read && (
        <>
          <Styled.h2>
            <FormattedMessage id="person.attr.personEmails" />
          </Styled.h2>
          <MultiInputControls<PersonEmailDTO>
            lines={emails}
            onChange={onChange}
            remove
            add
            defaultRow={DefaultPersonEmail}
            renderLines={(pe: PersonEmailDTO, onChange2: (personEmail: PersonEmailDTO) => void, actions: { [p: string]: (e?: any) => void }, idx: number): ReactElement => (
              <div>
                <Flex variant="layout.multiRow">
                  <TextInputP
                    permissions={checkPermission('email')}
                    name={intl.formatMessage(messages.email)}
                    value={pe.email}
                    onChange={(email: string) => onChange2({ ...pe, email })}
                    validation={validation?.children[idx]?.email}
                    autoFocus={true}
                  />
                  <SelectInputP
                    onChange={(type: string) => onChange2({ ...pe, type })}
                    value={pe.type}
                    permissions={checkPermission('type')}
                    name={intl.formatMessage(messages.type)}
                    inputName={'type'}
                    list={'email_types_list'}
                    listPartition={'*'}
                    validation={validation?.children[idx]?.type}
                  />

                </Flex>
                {!!actions.delete && (
                  <div className='deleteButton'>
                    <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                  </div>
                )}
              </div>
            )}
            renderEnd={(actionAdd) => (
              <div className='addButton'>
                <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
              </div>
            )}
          />
        </>
      )}
    </div>
  );
}

export const Emails = injectIntl(InnerEmails);
