/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchFacet,
    SearchFacetFromJSON,
    SearchFacetFromJSONTyped,
    SearchFacetToJSON,
    SolrPublisher,
    SolrPublisherFromJSON,
    SolrPublisherFromJSONTyped,
    SolrPublisherToJSON,
} from './';

/**
 * 
 * @export
 * @interface SolrResultSolrPublisher
 */
export interface SolrResultSolrPublisher {
    /**
     * 
     * @type {number}
     * @memberof SolrResultSolrPublisher
     */
    numberResults?: number;
    /**
     * 
     * @type {Array<SolrPublisher>}
     * @memberof SolrResultSolrPublisher
     */
    results?: Array<SolrPublisher>;
    /**
     * 
     * @type {Array<SearchFacet>}
     * @memberof SolrResultSolrPublisher
     */
    facets?: Array<SearchFacet>;
}

export function SolrResultSolrPublisherFromJSON(json: any): SolrResultSolrPublisher {
    return SolrResultSolrPublisherFromJSONTyped(json, false);
}

export function SolrResultSolrPublisherFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolrResultSolrPublisher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberResults': !exists(json, 'numberResults') ? undefined : json['numberResults'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(SolrPublisherFromJSON)),
        'facets': !exists(json, 'facets') ? undefined : ((json['facets'] as Array<any>).map(SearchFacetFromJSON)),
    };
}

export function SolrResultSolrPublisherToJSON(value?: SolrResultSolrPublisher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberResults': value.numberResults,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(SolrPublisherToJSON)),
        'facets': value.facets === undefined ? undefined : ((value.facets as Array<any>).map(SearchFacetToJSON)),
    };
}


