// Components
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Sidebar from 'BaseLayout/components/sidebar';
import { FacetArea, ResultArea } from './areas';
import { OAuth2Gate } from 'Auth/components';
import { Container } from '@theme-ui/components';
import { Result } from '../Result/Result';

// Types
import { DashboardType, FacetEntry, SearchFacet, SearchFacetValueMap, SearchResultType, SearchSort } from '../../types';

// Data
import { getContextTitle } from '../../../data';

// Stylesheet
import 'react-widgets/dist/css/react-widgets.css';

/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { useEffect, useState } from 'react';

// import getLogger from '../../../log';
// const logger = getLogger('Dashboard/component');

export interface Props {
  className?: string;
  key?: string;
  dashboardType: DashboardType;

  // Search Params
  searchTerm: string;
  searchResultOffset: number;
  searchResultLimit: number;
  searchResultSort?: SearchSort;
  searchOnMount: boolean;

  // Search Param Actions
  onSearchTermUpdate: (value: string) => void;
  onSearchResultLimitUpdate: (value: number) => void;
  onSearchResultOffsetUpdate: (value: number) => void;
  onSearchResultSortUpdate: (value: SearchSort) => void;

  // Search Results Data
  searchResults: SearchResultType[];
  searchResultCount: number;
  searchResultsLoading: boolean;
  searchResultsError: string | null;

  // Facets Data
  facetsLoading: boolean;
  activeFacetEntries: SearchFacetValueMap<FacetEntry[]>;
  availableFacetEntries: SearchFacet[];
  facetOffsets: SearchFacetValueMap<number>;

  // Facets Actions
  onFacetEntriesSet: (facet: string, entries: FacetEntry[]) => void;
  onFacetOffsetUpdate: (facet: string, value: number) => void;

  // Events
  onDidMount: (initialSearch: boolean) => void;
  onWillUnmount: () => void;
  onRefresh: () => void;

  // Options
  embedded: boolean;
  hideSidebar: boolean;
  personOrg?: any;
}

export interface State {
  searchTerm: string;
  gotoOffset: string;
}

export const initialState: State = {
  searchTerm: '',
  gotoOffset: '',
};

export function Dashboard(props: Props): React.JSX.Element {
  const [gotoOffset, setGotoOffset] = useState<string>('');
  const { dashboardType, embedded, hideSidebar } = props;
  const className: string = 'dashboard' + (!!props.className ? ' ' + props.className : '');

  const resultAreaProps = {
    ...props,
    gotoOffset,
    className: 'result-area',
    handleGotoInputChange: (value: string): void => {
      setGotoOffset(value);
    },
  };

  useEffect((): void =>{
    props.onDidMount(props.searchOnMount);
  },[props]);

  useEffect(() => {
    return (): void => {
      props.onWillUnmount();
    };
  }, [props]);

  const renderSearchResult = (index: number, result): React.JSX.Element => {
    const ResultComp = Result(props.dashboardType);
    return (
      <ResultComp {...props}
        key={result.id}
        index={index}
        result={result}
        updateResults={(): void => {
          props.onRefresh();
        }}
      />
    );
  };

  const renderSearchResults = (): JSX.Element => {
    const { searchResults, searchResultLimit, searchResultOffset } = props;
    const pageOffset = Math.floor(searchResultOffset / searchResultLimit) * searchResultLimit;
    const renderedSearchResults: JSX.Element[] = [];
    for (let i = 0; i < searchResults.length; ++i) {
      const searchResult: SearchResultType = searchResults[i];
      renderedSearchResults.push(renderSearchResult(pageOffset + i, searchResult));
    }

    return <div className="result-list">{renderedSearchResults}</div>;
  };

  return (
    <div className={className} sx={{height: "100%"}}>
      {dashboardType === DashboardType.PROJECTS && !embedded &&
      <OAuth2Gate anyRoles={['ROLE_GUEST', 'ROLE_USER']}>
        <Container variant="bannerSearchProject">
          <h1><FormattedMessage id="layout.title" /></h1>
          <h2><FormattedMessage id="search.projects.banner.subtitle" /></h2>
        </Container>
      </OAuth2Gate>
      }
      {dashboardType === DashboardType.PEOPLE && !embedded &&
      <OAuth2Gate anyRoles={['ROLE_GUEST', 'ROLE_USER']}>
        <Container variant="bannerSearchPerson">
          <h1><FormattedMessage id="layout.title" /></h1>
          <h2><FormattedMessage id="search.person.banner.subtitle" /></h2>
        </Container>
      </OAuth2Gate>
      }
      {dashboardType === DashboardType.ORGS && !embedded &&
      <OAuth2Gate anyRoles={['ROLE_GUEST', 'ROLE_USER']}>
        <Container variant="bannerSearchOrganisation">
          <h1><FormattedMessage id="layout.title" /></h1>
          <h2><FormattedMessage id="search.organisation.banner.subtitle" /></h2>
        </Container>
      </OAuth2Gate>
      }
      {dashboardType === DashboardType.WORKS && !embedded &&
      <OAuth2Gate anyRoles={['ROLE_GUEST', 'ROLE_USER']}>
        <Container variant="bannerSearchWork">
          <h1><FormattedMessage id="layout.title" /></h1>
          <h2><FormattedMessage id="search.work.banner.subtitle" /></h2>
        </Container>
      </OAuth2Gate>
      }
      <Flex className={hideSidebar ? 'sidebar-hidden' : null}>
        {hideSidebar !== true && (
          <Sidebar>
            {!embedded && <h1 className="page-title" sx={{textAlign: 'center', color: 'dashboard', fontWeight: 'bold', mb: '0.5em', mt: '0.25em'}}>{getContextTitle(dashboardType)}</h1>}
            <FacetArea {...props} className="facet-area" />
          </Sidebar>
        )}
        <div sx={{flexGrow: 1}}>
          <ResultArea {...resultAreaProps}>{renderSearchResults()}</ResultArea>
        </div>
      </Flex>
    </div>
  );
}
