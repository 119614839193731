import { css, SerializedStyles } from '@emotion/react';
import { Theme } from 'theme-ui';

export const globalFormStyles = (theme: Theme): SerializedStyles =>
  css(`
  // Components
  .input{
    &:invalid{
       border-color: ${theme.colors?.error} !important;
    }
    &:disabled{
      background-color: rgb(243, 243, 243);
      cursor: not-allowed;
      &:hover{
        border: 1px solid #ccc !important;
      }
    }
    &:focus{
      outline: none;
    }
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    // border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    &.inline{
      display: inline-block;
    }
    &:hover, &:focus, &:active{
      border-bottom: 1px solid ${theme.colors?.focused};
    }
  }
  
  .btn{
    background-color: ${theme.colors?.primary};
    color: white !important;
    transition: background-color .5s;
    border-radius: 2em;
    padding: .4em .5em;
    text-decoration: none;
    cursor: pointer;
    font-size: .8rem;
    border: none;
    margin: .5em .25em;
    &.block{
      width: 100%;
    }
    &:hover{
      background-color:${theme.colors?.focused};
    }
  }
  
  button.freestyle{
    border: none;
    padding: 0;
    background: none;
    &:hover, &:focus, &:active{
      border: none;
      background: none;
    }
  }
  
  label.radio-label, label.checkbox-label{
    display: inline-block;
    position: relative;
    .radio, .checkbox{
      &:focus {outline:none;}
      &::-moz-focus-inner {border:0;}
      appearance: none;
      padding-left: 20px;
      ~.helper{
        left: 0em;
        top: 0.1em;
        width: 20px;
        height: 20px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        position: absolute;
      }
      ~.helper.disabled{
        background-color: rgb(243, 243, 243);
        cursor: not-allowed;
      }
      &:checked ~.helper{
        border-color: #ccc;
        background-color: white !important;
        &::before{
          content: ' ';
          z-index: 200;
          background-color: ${theme.colors?.primary} !important;
          margin-top: calc(50% - 10px / 2);
          margin-left: calc(50% - 10px / 2);
          display: block;
          width: 10px;
          height: 10px;
        }
      }
    }
    &:hover .helper{
      background-color: rgba(204, 204, 204, 0.1);
    }
    &.radio-label{
      margin: 0 .3em;
    }
    .radio{
      padding-left: 15px;
    }
    .radio ~.helper{
      border-width: 2px;
      border-radius: 50%;
      &::before{
        border-radius: 50% !important;
      }
    }
  }
  
  .form-group{
    label:not(:last-child){
      min-width: 20%;
      padding-right: .5em;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .form-inner{
    margin-left: 1em;
  }
  
  
  .react-datepicker-wrapper{
    width: 100%;
  }
  .react-datepicker__input-container{
    width: 100%;
  }
`);
