import React, { ReactElement } from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { WorkRelatedIdentifierInternalDTO } from '../../../../api/core/models';
import { WorkSuggest } from '../../auto-complete/WorkSuggest';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultWorkRelatedIdentifierInternal } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';
import { Box, Label, Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  relationType: { id: 'work.attr.relatedIdentifierInternal.attr.type' },
  relatedIdentifier: { id: 'work.attr.relatedIdentifiersInternal.attr.referencedWork'},
  relatedIdentifierHelp: { id: 'work.attr.relatedIdentifiersInternal.attr.referencedWork.help'}
});

interface RelatedIdentifiersInternalProps {
  internals?: WorkRelatedIdentifierInternalDTO[];
  listPartition: string;
  onChange: (data: WorkRelatedIdentifierInternalDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
  source: boolean;
}

function getSelectInput({internals, listPartition, onChange, intl, checkPermission, validation}: RelatedIdentifiersInternalProps & WrappedComponentProps,
                        internal: WorkRelatedIdentifierInternalDTO,
                        onChangeGo2: (data: WorkRelatedIdentifierInternalDTO) => void,
                        actions,
                        idx: number
                      ) {
  return (
    <SelectInputP
    onChange={(relationType: string): void => onChangeGo2({ ...internal, relationType })}
    value={internal.relationType}
    list={'relation_type_list'}
    listPartition={listPartition}
    name={intl.formatMessage(messages.relationType)}
    inputName="role"
    permissions={checkPermission('relation_type')}
    validation={validation?.children[idx]?.relation_type}
  />
  );
}

function getWorkSuggest({internals, listPartition, onChange, intl, checkPermission, validation}: RelatedIdentifiersInternalProps & WrappedComponentProps,
  internal: WorkRelatedIdentifierInternalDTO,
  onChangeGo2: (data: WorkRelatedIdentifierInternalDTO) => void,
  actions,
  idx: number,
  writeReferencedWork: WritableParam) {
  return (
    <WorkSuggest
    required={isRequired(checkPermission('referenced_work'))}
    single={true}
    canDelete={false}
    name={intl.formatMessage(messages.relatedIdentifier)}
    help={intl.formatMessage(messages.relatedIdentifierHelp)}
    tags={[internal.referencedWork]}
    validation={validation?.children[idx]?.referenced_work}
    onChange={(internals: WorkRelatedIdentifierInternalDTO[]): void =>
      onChangeGo2({
        ...internal,
        referencedWork: internals[0],
      })
    }
    disabled={!writeReferencedWork}
    autoFocus={true}
  />
  );
}

function InnerRelatedIdentifiersInternal({
  internals,
  listPartition,
  onChange,
  intl,
  checkPermission,
  validation,
  source
}: RelatedIdentifiersInternalProps & WrappedComponentProps): ReactElement {
  const { read: readReferencedWork, write: writeReferencedWork } = checkPermission('referenced_work');
  return (
    <div className="col">
      <Styled.h2>
        {source ? (<FormattedMessage id="work.attr.relatedIdentifiersInternal" />) : (<FormattedMessage id="work.attr.relatedIdentifiersInternal.target" />)}
      </Styled.h2>
      <Border>
        <MultiInputControls<WorkRelatedIdentifierInternalDTO>
          lines={internals ? internals : []}
          onChange={onChange}
          add
          remove
          defaultRow={DefaultWorkRelatedIdentifierInternal}
          renderLines={(
            internal: WorkRelatedIdentifierInternalDTO,
            onChangeGo2: (data: WorkRelatedIdentifierInternalDTO) => void,
            actions,
            idx: number
          ): ReactElement => (
            <div className="fieldRow" key={idx}>
              {source && readReferencedWork && (<Box className='thisWork'><FormattedMessage id="work.attr.relatedIdentifiersInternal.thisWork" /></Box>)}
              { !source && readReferencedWork ? (
                getWorkSuggest({internals, listPartition, onChange, intl, checkPermission, validation, source},internal, onChangeGo2, actions, idx, writeReferencedWork)
                ) : getSelectInput({internals, listPartition, onChange, intl, checkPermission, validation, source},internal, onChangeGo2, actions, idx)
              }
              { !source && readReferencedWork ?
                getSelectInput({internals, listPartition, onChange, intl, checkPermission, validation, source},internal, onChangeGo2, actions, idx)
                : getWorkSuggest({internals, listPartition, onChange, intl, checkPermission, validation, source},internal, onChangeGo2, actions, idx, writeReferencedWork)
              }
              {!source && readReferencedWork && (<Box className='thisWork'><FormattedMessage id="work.attr.relatedIdentifiersInternal.thisWork" /></Box>)}
              {!!actions.delete && (
                <div className="deleteButton">
                <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          )}
          renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
            <div className="addButton">
              <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
            </div>
          )}
        />
      </Border>
    </div>
  );
}

export const RelatedIdentifiersInternal = injectIntl(InnerRelatedIdentifiersInternal);
