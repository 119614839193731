/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeletionTrackingEntryDTO
 */
export interface DeletionTrackingEntryDTO {
    /**
     * 
     * @type {number}
     * @memberof DeletionTrackingEntryDTO
     */
    id: number | null;
    /**
     * 
     * @type {number}
     * @memberof DeletionTrackingEntryDTO
     */
    entityId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DeletionTrackingEntryDTO
     */
    entityType?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeletionTrackingEntryDTO
     */
    deletedTimestamp?: Date;
}

export function DeletionTrackingEntryDTOFromJSON(json: any): DeletionTrackingEntryDTO {
    return DeletionTrackingEntryDTOFromJSONTyped(json, false);
}

export function DeletionTrackingEntryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletionTrackingEntryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'entityType': !exists(json, 'entityType') ? undefined : json['entityType'],
        'deletedTimestamp': !exists(json, 'deletedTimestamp') ? undefined : (new Date(json['deletedTimestamp'])),
    };
}

export function DeletionTrackingEntryDTOToJSON(value?: DeletionTrackingEntryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'entityId': value.entityId,
        'entityType': value.entityType,
        'deletedTimestamp': value.deletedTimestamp === undefined ? undefined : (value.deletedTimestamp.toISOString()),
    };
}


