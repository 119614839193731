import { Validation, ValidationResult, ValidationResults } from './interfaces';

export const isValid = (data: Validation, fields?: string[]): boolean => {
  if(fields) {
    const root: ValidationResults = {};
    for (const key of Object.keys(data.root)) {
      if(fields.includes(key)) {
        root[key] = data.root[key];
      }
    }
    const admin: ValidationResults = {};
    for (const key of Object.keys(data.admin)) {
      if(fields.includes(key)) {
        root[key] = data.admin[key];
      }
    }
    return validateResults(root) && validateResults(admin);
  }
  return validateResults(data.root) && validateResults(data.admin);
};

const validateResult = (data: ValidationResult): boolean => {
  if(!data.valid) {
    return false;
  }
  if(data.child && !validateResults(data.child)) {
    return false;
  }
  return !(data.children && data.children.some((child: ValidationResults) => !validateResults(child)));
};

const validateResults = (data: ValidationResults): boolean => {
  for (const key of Object.keys(data)) {
    if(!validateResult(data[key])) {
      return false;
    }
  }
  return true;
};
