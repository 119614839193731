import * as React from 'react';
import { SuggestApi } from '../../../api/core/apis/SuggestApi';
import { PublisherDTO, SolrDocument } from '../../../api/core/models';
import { MultiAutocomplete } from '../../../BaseLayout/components/form';
import { DeleteStrategy, Props as MultiAutocompleteProps } from '../../../BaseLayout/components/form/MultiAutocomplete';
import { ReactElement, useState } from 'react';
import { ValidationResult } from '../../validation/interfaces';
import { InputLabel } from '../../../BaseLayout/components/form/generic/InputLabel';

export interface State {
  suggestions?: SolrDocument[];
}
export interface Props {
  onChange?: (values: any[]) => void;
  tags?: PublisherDTO[];
  autocompleteProps?: Partial<MultiAutocompleteProps>;
  single?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  help?: string;
  validation?: ValidationResult;
  autoFocus?: boolean;
}

export function PublisherSuggest(props: Props): React.JSX.Element {
  const tags: PublisherDTO[] = props.tags ?? [];
  const onChange: (values: any[]) => void = props.onChange ? props.onChange : (): void => {};
  const single: boolean = props.single ?? false;
  const canDelete: boolean = props.canDelete ?? true;
  const required: boolean = props.required ?? false;
  const disabled: boolean = props.disabled ?? false;
  const autoFocus: boolean = props.autoFocus ?? false;
  const [suggestions, setSuggestions] = useState<SolrDocument[]>([]);
  const validation: ValidationResult = props.validation ?? { valid: true };
  if(required && tags.length < 1) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }

  return (
    <InputLabel
      label={props.name}
      required={required}
      variant="labelY"
      help={props.help}
      invalid={validation && !validation.valid}>
      <MultiAutocomplete
        autoFocus={autoFocus}
        required={required}
        canDelete={canDelete}
        allwaysSuggest={true}
        remote={true}
        disabled={disabled}
        tags={tags}
        deleteStrategy={DeleteStrategy.SPLICE}
        single={single}
        options={suggestions}
        onSuggestionsClearRequested={(): void => {}}
        onChange={onChange}
        validation={validation}
        onSuggestionSelected={(event, { suggestion }: { suggestion: SolrDocument }) => {
          return {
            name: suggestion.publisher_name,
            id: suggestion.id,
          };
        }}
        onSuggestionsFetchRequested={async (e): Promise<void> => {
          const api: SuggestApi = new SuggestApi();
          const suggestions: SolrDocument[] = await api.publisherSuggest({ q: e.value });
          setSuggestions(suggestions);
        }}
        renderSuggestions={(suggestion: SolrDocument): ReactElement => {
          return (
            <span>
              <ul style={{ listStyleType: 'none' }}>
                <li>
                  <span dangerouslySetInnerHTML={{ __html: suggestion.publisher_name ? suggestion.publisher_name : '' }} />
                </li>
                <li>
                  <small>
                    <ul>
                      {suggestion.publisher_name && suggestion.publisher_name}
                    </ul>
                  </small>
                </li>
              </ul>
            </span>
          );
        }}
        {...props.autocompleteProps}
      />
    </InputLabel>
  );
}
