import React, { ReactElement } from 'react';
import { AdminDataWorkOrcidSyncDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { MultiInputControls } from '../../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultAdminDataWorkOrcidSync } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { validate } from '../../../validation/validate';
import { ValidationResult, ValidationResults } from '../../../validation/interfaces';

const messages = defineMessages({
  orcidId: {
    id: 'work.attr.adminDataWork.attr.adminDataWorkOrcidSyncs.attr.id',
  },
  orcidPutCode: {
    id: 'work.attr.adminDataWork.attr.adminDataWorkOrcidSyncs.attr.putCode',
  },
  orcidVisibility: {
    id: 'work.attr.adminDataWork.attr.adminDataWorkOrcidSyncs.attr.visibility',
  },
});

interface WorkOrcidSyncsProps {
  orcidSyncs: AdminDataWorkOrcidSyncDTO[];
  onChange: (editedOrcidSyncs: AdminDataWorkOrcidSyncDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

interface WorkOrcidSyncProps {
  orcidSync?: AdminDataWorkOrcidSyncDTO;
  onChange: (editedOrcidSync: AdminDataWorkOrcidSyncDTO) => void;
  checkPermission: CheckPermissionFunction;
  validations: ValidationResults;
}

export function WorkOrcidSyncs({ orcidSyncs, onChange, checkPermission, validation }: WorkOrcidSyncsProps): ReactElement {
  return orcidSyncs.length > 0 ? (
    <div className="col">
      <h3>
        <FormattedMessage id="work.attr.adminDataWork.attr.adminDataWorkOrcidSyncs.attr.title" />
      </h3>
      <MultiInputControls<AdminDataWorkOrcidSyncDTO>
        remove
        lines={orcidSyncs}
        add
        defaultRow={DefaultAdminDataWorkOrcidSync}
        onChange={onChange}
        renderLines={(line: any, onChange2: (newValues) => void, actions: { [p: string]: (e?: any) => void }, idx: number): ReactElement => (
          <div key={idx}>
            <React.Fragment>
              <WorkOrcidSync orcidSync={line} onChange={onChange2} checkPermission={checkPermission} validations={validation?.children[idx]} />
              {!!actions.delete && (
                <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
              )}
            </React.Fragment>
          </div>
        )}
        renderEnd={(actionAdd) => (
          <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
        )}
      />
    </div>
  ) : (
    <></>
  );
}

function InnerWorkOrcidSync({
  orcidSync,
  onChange,
  intl,
  checkPermission,
  validations
}: WorkOrcidSyncProps & WrappedComponentProps) {

  return (
    <>
      <TextInputP
        name={intl.formatMessage(messages.orcidId)}
        value={orcidSync.orcidId}
        disabled={true}
        onChange={(orcidId: string): void => {
          console.error('OrcId Change not possible', orcidId);
          /* onChange({ ...orcidSync, orcidId }); */
        }}
        permissions={checkPermission('orcid_id')}
        validation={validations.orcid_id}
      />
      <TextInputP
        name={intl.formatMessage(messages.orcidPutCode)}
        value={orcidSync.orcidPutCode}
        disabled={true}
        onChange={(orcidPutCode: string): void => {
          console.error('OrcId Put Code Change not possible', orcidPutCode);
          /* onChange({ ...orcidSync, orcidPutCode }); */
        }}
        permissions={checkPermission('orcid_put_code')}
        validation={validations.orcid_put_code}
      />
      <TextInputP
        name={intl.formatMessage(messages.orcidVisibility)}
        value={orcidSync.orcidVisibility}
        disabled={true}
        onChange={(orcidVisibility: string): void => {
          console.error('OrcId Visibility Change not possible', orcidVisibility);
          /* onChange({ ...orcidSync, orcidVisibility }); */
        }}
        permissions={checkPermission('orcid_visibility')}
        validation={validations.orcid_visibility}
      />
    </>
  );
}

const WorkOrcidSync = injectIntl(InnerWorkOrcidSync);
