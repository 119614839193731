
import { DataModelGate } from 'Auth/components';
import { ReadWritePermissions, WritableParam } from 'Auth/components/DataModelGate';
import { CheckboxP } from 'BaseLayout/components/form/generic/Checkbox';
import Popup from 'BaseLayout/components/popup/Popup';
import { ProjectSuggest } from 'Edit/components/auto-complete/ProjectSuggest';
import { useStatusCode } from 'Utils/StatusCodeHandler';
import { ReferencedProjectDTO, WorkApi, WorkDTO } from 'api/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { IntlShape, defineMessages, useIntl } from 'react-intl';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import compare from '@mms-v3/patch-lib';
import { isRequired } from 'BaseLayout/components/form/generic/utils';
import { handleStatusCode422 } from 'Utils/StatusCodeUtils';

const messages = defineMessages({
    popupHeader: { id: 'user.profile.work.projects.popup.header' },
    popupButton: { id: 'user.profile.work.projects.popup.confirm' },
    belongToProject: { id: 'user.profile.work.projects.popup.projects' },
    belongToProjectHelp: { id: 'user.profile.work.projects.popup.projects.help' },
    projectsChecked: { id: 'user.profile.work.projects.popup.projects.checked' }
});

interface Props {
    className?: string;
    openPopup: boolean;
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
    workApi: WorkApi;
    work: WorkDTO;
    setWork: React.Dispatch<React.SetStateAction<WorkDTO>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export function WorkBelongToProjects(props: Props) {
    const intl: IntlShape = useIntl();
    const { setStatusCode } = useStatusCode();
    const { openPopup, setOpenPopup, workApi, work, setWork, loading, setLoading } = props;

    const [currentModel, setCurrentModel] = useState<WorkDTO>(work ? work : null);
    const [modifiedModel, setModifiedModel] = useState<WorkDTO>(work ? work : null);

    const updateField = (field: string, value: any) => {
        value = value === '' ? null : value;
        setModifiedModel({ ...modifiedModel, [field]: value });
        setModifiedModel((modifiedModelSet) => modifiedModelSet);
    };

    useEffect(() => {
        setModifiedModel(work);
        setCurrentModel(work);
    }, [work]);

    return <div>
        <Popup
            open={openPopup}
            title={intl.formatMessage(messages.popupHeader)}
            onClose={() => { setOpenPopup(!openPopup); setWork(null); }}
            buttons={[
                {
                    label: intl.formatMessage(messages.popupButton),
                    onClick: () => {
                        const diff: any = compare(currentModel, cloneDeep(modifiedModel));
                        setLoading(true);
                        workApi.updateWorkRaw({ workId: work.id, patchDocument: diff }).then(response => {
                            setOpenPopup(!openPopup);
                            setStatusCode({
                                status: response.raw.status,
                                action: 'update',
                                data: {
                                entity: 'works',
                                name: work.title
                                }
                            });
                            setWork(null);
                            setLoading(false);
                        }).catch((error => {
                            setOpenPopup(!openPopup);
                            if (error.status === 422) return handleStatusCode422(error, setStatusCode);
                            setStatusCode({status: error.status});
                            setWork(null);
                            setLoading(false);
                        }));
                    },
                    disabled: loading || !modifiedModel?.adminDataWork?.projectsChecked
                },
              ]}
        >
            <DataModelGate always={false} entity={'work_admin_data'} field="projects">
                {(writable: WritableParam, _readable: boolean, checkPermission: (child?: string) => ReadWritePermissions) => (
                    <ProjectSuggest
                        name={intl.formatMessage(messages.belongToProject)}
                        help={intl.formatMessage(messages.belongToProjectHelp)}
                        required={isRequired(checkPermission())}
                        //validation={validation}
                        disabled={!writable}
                        tags={modifiedModel?.adminDataWork?.projects ? modifiedModel.adminDataWork.projects : []}
                        onChange={(projects: ReferencedProjectDTO[]): void => {updateField('adminDataWork', { ...modifiedModel.adminDataWork, projects });}}
                    />
                )}
            </DataModelGate>
            <DataModelGate always={false} entity={'work_admin_data'} field="projects_checked">
                {(writable: WritableParam, _readable: boolean, checkPermission: (child?: string) => ReadWritePermissions) => (
                    <CheckboxP
                        checked={modifiedModel?.adminDataWork?.projectsChecked ? modifiedModel.adminDataWork.projectsChecked : false}
                        onChange={(_projectsChecked: boolean) => updateField('adminDataWork', { ...modifiedModel.adminDataWork, projectsChecked: _projectsChecked })}
                        disabled={!writable}
                        name={intl.formatMessage(messages.projectsChecked)}
                        required={isRequired(checkPermission())}
                        permissions={checkPermission()}
                    />
                )}
            </DataModelGate>
        </Popup>
    </div>
}

export default WorkBelongToProjects;
