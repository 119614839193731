import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { TagsInput } from 'BaseLayout/components/form/TagsInput';
import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import {
  ProjectFundingReferenceDTO,
  ProjectFundingReferenceFundingStreamDTO,
  ProjectFundingReferenceFundingSubstreamDTO
} from '../../../api/core/models';
import { SelectInputP } from '../../../BaseLayout/components/form/generic/Select';
import { TextInputP } from '../../../BaseLayout/components/form/generic/TextInput';
import { isRequired } from '../../../BaseLayout/components/form/generic/utils';
import { AddButton, DeleteButton } from '../../../BaseLayout/components/form/MultiInput/Buttons';
import { MultiInputControls } from '../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { DefaultProjectFunder, DefaultProjectFundingRef } from '../../data/emptyModels';
import { Input } from 'theme-ui';
import { Label } from '@theme-ui/components';
import { ValidationResult } from '../../validation/interfaces';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { InputLabel } from 'BaseLayout/components/form/generic/InputLabel';

const messages = defineMessages({
  id: { id: 'project.attr.projectFunding.attr.id' },
  funder: { id: 'project.attr.projectFunding.attr.funder' },
  funderIdentifier: { id: 'project.attr.projectFunding.attr.funderIdentifier' },
  funderIdentifierType: { id: 'project.attr.projectFunding.attr.funderIdentifierType' },
  funderName: { id: 'project.attr.projectFunding.attr.funderName' },
  projectFundingReferenceFundingStreams: { id: 'project.attr.projectFunding.attr.projectFundingReferenceFundingStreams' },
  projectFundingReferenceFundingStreamsHelp: { id: 'project.attr.projectFunding.attr.projectFundingReferenceFundingStreams.help' },
  projectFundingReferenceFundingSubstreams: { id: 'project.attr.projectFunding.attr.projectFundingReferenceFundingSubstreams' },
  projectAcronym: { id: 'project.attr.projectFunding.attr.projectAcronym' },
  externalProjectIdentifier: { id: 'project.attr.projectFunding.attr.externalProjectIdentifier' },
  projectTitle: { id: 'project.attr.projectFunding.attr.projectTitle' },
  projectUri: { id: 'project.attr.projectFunding.attr.projectUri' },
  projectUriHelp: { id: 'project.attr.projectFunding.attr.projectUri.help' },
});

interface Props {
  checkPermission: CheckPermissionFunction;
  fundingRefs: ProjectFundingReferenceDTO[];
  onChange: (subject: ProjectFundingReferenceDTO[]) => void;
  validation: ValidationResult;
}

export function ProjectFundingRefs({
  fundingRefs,
  onChange,
  checkPermission,
  validation
}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <MultiInputControls<ProjectFundingReferenceDTO>
      lines={fundingRefs}
      onChange={onChange}
      add={!!checkPermission().write}
      remove={!!checkPermission().write}
      defaultRow={DefaultProjectFundingRef}
      renderLines={(fundingRef: ProjectFundingReferenceDTO, onChange2: (subject: ProjectFundingReferenceDTO) => void, actions: { [p: string]: (e?: any) => void }, idx: number): ReactElement => {
        const addProjectFunder = (e: React.FormEvent<HTMLButtonElement>): void => {
          e.preventDefault();
          onChange2({...fundingRef, projectFunder: { ...DefaultProjectFunder }});
        };
        const  deleteProjectFunder = (e: React.FormEvent<HTMLButtonElement>): void => {
          e.preventDefault();
          onChange2({...fundingRef, projectFunder: undefined});
        };
        return (
          <div>
            <Label variant="labelY">
              {intl.formatMessage(messages.funder)}
            </Label>
            {!fundingRef.projectFunder && <div sx={{mt: '-16px'}}><AddButton onClick={(e) => addProjectFunder(e)}  disabled={!checkPermission('project_funder').write} /></div>}
            {fundingRef.projectFunder && <>
              <TextInputP
                value={fundingRef.projectFunder?.funderIdentifier}
                onChange={(funderIdentifier: string): void =>
                  onChange2({ ...fundingRef, projectFunder: { ...fundingRef.projectFunder, funderIdentifier } })
                }
                name={intl.formatMessage(messages.funderIdentifier)}
                inputName={'identifier'}
                permissions={checkPermission('project_funder.funder_identifier')}
                validation={validation?.children[idx]?.project_funder.child.funder_identifier}
                autoFocus={true}
              />
              <SelectInputP
                value={fundingRef.projectFunder?.funderIdentifierType?.toLowerCase()}
                onChange={(funderIdentifierType: string): void =>
                  onChange2({ ...fundingRef, projectFunder: { ...fundingRef.projectFunder, funderIdentifierType } })
                }
                list={'funder_id_type_list'}
                listPartition={'*'}
                name={intl.formatMessage(messages.funderIdentifierType)}
                inputName={'identifierType'}
                permissions={checkPermission('project_funder.funder_identifier_type')}
                validation={validation?.children[idx]?.project_funder.child.funder_identifier_type}
              />
              <DeleteButton onClick={deleteProjectFunder} disabled={!checkPermission('project_funder').write} />
            </>}
            <TextInputP
              value={fundingRef.funderName}
              onChange={(funderName: string): void => onChange2({ ...fundingRef, funderName })}
              name={intl.formatMessage(messages.funderName)}
              inputName={'funderName'}
              permissions={checkPermission('funder_name')}
              validation={validation?.children[idx]?.funder_name}
              autoFocus={true}
            />
            {checkPermission('project_funding_reference_funding_streams').read && (
              <div className="form">
                <InputLabel
                  classes={["project-funding-ref"]}
                  variant="labelY"
                  label={intl.formatMessage(messages.projectFundingReferenceFundingStreams)}
                  help={intl.formatMessage(messages.projectFundingReferenceFundingStreamsHelp)}
                />
                <TagsInput
                  renderInput={inputProps => <Input {...inputProps} placeholder={intl.formatMessage({ id: 'tagsinput.placeholder' })} />}
                  required={isRequired(checkPermission('project_funding_reference_funding_streams'))}
                  validation={validation?.children[idx]?.project_funding_reference_funding_streams}
                  value={
                    fundingRef.projectFundingReferenceFundingStreams
                      ? fundingRef.projectFundingReferenceFundingStreams.map(
                        (stream: ProjectFundingReferenceFundingStreamDTO): string => stream.fundingStream
                      )
                      : []
                  }
                  onChange={(values: string[]) =>
                    onChange2({
                      ...fundingRef,
                      projectFundingReferenceFundingStreams: values.map(
                        (value: string): ProjectFundingReferenceFundingStreamDTO => ({ fundingStream: value })
                      ),
                    })
                  }
                  disabled={!checkPermission('project_funding_reference_funding_streams').write}
                />
              </div>
            )}
            {checkPermission('project_funding_reference_funding_substreams').read && (
              <div className="form">
                <Label variant='labelY'>
                  {intl.formatMessage(messages.projectFundingReferenceFundingSubstreams)}
                </Label>
                <TagsInput
                  validation={validation?.children[idx]?.project_funding_reference_funding_substreams}
                  required={isRequired(checkPermission('project_funding_reference_funding_substreams'))}
                  renderInput={inputProps => <Input {...inputProps} placeholder={intl.formatMessage({ id: 'tagsinput.placeholder' })} />}
                  value={
                    fundingRef.projectFundingReferenceFundingSubstreams
                      ? fundingRef.projectFundingReferenceFundingSubstreams.map(
                        (stream: ProjectFundingReferenceFundingSubstreamDTO): string => stream.fundingSubstream
                      )
                      : []
                  }
                  onChange={(values: string[]) =>
                    onChange2({
                      ...fundingRef,
                      projectFundingReferenceFundingSubstreams: values.map(
                        (value: string): ProjectFundingReferenceFundingSubstreamDTO => ({ fundingSubstream: value })
                      ),
                    })
                  }
                  disabled={!checkPermission('project_funding_reference_funding_substreams').write}
                />
              </div>
            )}
            <TextInputP
              value={fundingRef.projectAcronym}
              onChange={(projectAcronym: string): void => onChange2({ ...fundingRef, projectAcronym })}
              name={intl.formatMessage(messages.projectAcronym)}
              inputName={'projectAcronym'}
              permissions={checkPermission('project_acronym')}
              validation={validation?.children[idx]?.project_acronym}
            />
            <TextInputP
              value={fundingRef.externalProjectIdentifier}
              onChange={(externalProjectIdentifier: string): void => onChange2({ ...fundingRef, externalProjectIdentifier })}
              name={intl.formatMessage(messages.externalProjectIdentifier)}
              inputName={'externalProjectIdentifier'}
              permissions={checkPermission('external_project_identifier')}
              validation={validation?.children[idx]?.external_project_identifier}
            />
            <TextInputP
              value={fundingRef.projectTitle}
              onChange={(projectTitle: string): void => onChange2({ ...fundingRef, projectTitle })}
              name={intl.formatMessage(messages.projectTitle)}
              inputName={'projectTitle'}
              permissions={checkPermission('project_title')}
              validation={validation?.children[idx]?.project_title}
            />
            <TextInputP
              value={fundingRef.projectUri}
              onChange={(projectUri: string): void => onChange2({ ...fundingRef, projectUri })}
              name={intl.formatMessage(messages.projectUri)}
              help={intl.formatMessage(messages.projectUriHelp)}
              inputName={'projectUri'}
              permissions={checkPermission('project_uri')}
              validation={validation?.children[idx]?.project_uri}
            />
            {!!actions.delete && (
              <div className='deleteButton'>
                <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
              </div>
            )}
          </div>
        );}
      }
    renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
      <div className='addButton'>
        <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
      </div>
    )}
    />

  );
}
