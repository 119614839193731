import * as React from 'react';
import { Container, Subscribe } from 'unstated';
import { HierarchyApi } from '../../api/core/apis/HierarchyApi';
import { TopOrgaTooltip, Props as HTProps } from '../components/TopOrgaTooltip';
import { TopOrgaEntity } from '../types';

interface State {
  state: 'loading' | 'present' | 'missing' | 'error';
  topOrga?: any[];
  request: number | null;
}

const initialState: State = {
  state: 'missing',
  topOrga: undefined,
  request: null,
};

class TopOrgaTooltipContainer extends Container<State> {
  private static api: HierarchyApi = new HierarchyApi();

  // Do an actual fetch request
  private static async fetchTopOrga(entity: TopOrgaEntity, id: number) {
    const request = { id };

    // Send request
    const response = await ((req) => {
      switch (entity) {
        case TopOrgaEntity.PERSONS:
          return TopOrgaTooltipContainer.api.getPersonTopOrga({personId: req.id});
        case TopOrgaEntity.PROJECTS:
          return TopOrgaTooltipContainer.api.getProjectTopOrga({projectId: req.id});
        case TopOrgaEntity.ORGS:
          return TopOrgaTooltipContainer.api.getOrganisationTopOrga({organisationId: req.id});
        default:
          return [];
      }
    })(request);

    return response;
  }

  public state: State = initialState;

  public entity: TopOrgaEntity;
  public id: number;

  constructor(entity: TopOrgaEntity, id: number) {
    super();

    this.entity = entity;
    this.id = id;
  }

  // Trigger something to load the topOrga into the state
  public async loadTopOrga() {
    // Generate a unique identifier for the request
    const request = Date.now();

    // Set state to loading
    await this.setState((prevState) => {
      if (prevState.state !== 'missing') {
        return null;
      } // Bail out if topOrga is not missing
      return { state: 'loading', request };
    });

    if (this.state.request !== request) {
      return;
    } // Bail out if this request is not the most recent

    // Fire off the actual request
    try {
      const topOrga = await TopOrgaTooltipContainer.fetchTopOrga(this.entity, this.id);
      this.setState((prevState) => {
        if (prevState.request !== request) {
          return null;
        } // Bail out if this request is not the most recent

        return { state: 'present', topOrga, request: null };
      });
    } catch (ex) {
      // On error, update state
      this.setState((prevState) => {
        if (prevState.request !== request) {
          return null;
        } // Bail out if this request is not the most recent

        return { state: 'error', topOrga: undefined, request: null };
      });
    }
  }

  // Reset the state
  public reset() {
    this.setState({ state: 'missing', request: null, topOrga: undefined });
  }
}

export const topOrgaContainerMap: {
  [entity in TopOrgaEntity]: Map<number, TopOrgaTooltipContainer>;
} = {
  [TopOrgaEntity.ORGS]: new Map(),
  [TopOrgaEntity.PROJECTS]: new Map(),
  [TopOrgaEntity.PERSONS]: new Map(),
};

interface Props {
  entity: TopOrgaEntity;
  id: number;
  name: string;
  children?: any;
  key?: string;
}

export function ConnectedTopOrgaTooltip(props: Props) {
  const getRenderProps = (container: TopOrgaTooltipContainer): HTProps & { children?: any } => {
    // Forward some props
    const { entity, id, name, children } = props;

    // Take some from the state container
    const state = container.state.state;
    const topOrga = container.state.topOrga;
    const loadTopOrga = container.loadTopOrga.bind(container);

    return {
      entity,
      id,
      name,
      children,
      state,
      topOrga,
      loadTopOrga,
    };
  };

  // Get the state container for this item
  const getContainer = (): TopOrgaTooltipContainer => {
    if (!topOrgaContainerMap[props.entity].has(props.id)) {
      // Create new container if none exists yet
      const container: TopOrgaTooltipContainer = new TopOrgaTooltipContainer(props.entity, props.id);
      topOrgaContainerMap[props.entity].set(props.id, container);

      return container;
    }

    return topOrgaContainerMap[props.entity].get(props.id);
  };

  const container: TopOrgaTooltipContainer = getContainer();

  // Return the stateless tooltip component wrapped in a Subscriber
  return React.createElement(Subscribe, {
    to: [container],
    children: (c: TopOrgaTooltipContainer): React.ReactNode => {
      // Get the render props using the state container
      const _props = getRenderProps(c);

      return React.createElement(TopOrgaTooltip, _props);
    },
  });
}
