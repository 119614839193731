export type Level = 'assert' | 'error' | 'exception' | 'warn' | 'log' | 'info' | 'debug';
type Type = Level | 'table' | 'trace';
const order: (Level | 'off')[] = ['off', 'assert', 'error', 'exception', 'warn', 'log', 'info', 'debug'];
const fourChars: string[] = [
  'xxxx', // Placeholder for 'off'
  'asrt',
  'err',
  'excp',
  'warn',
  'log',
  'info',
  'debg',
];

const _console = window.console;

// eslint-disable no-cond-assign
export class Logger {
  public get console(): Console {
    return this._console;
  }

  public get parent(): Logger {
    return this._parent;
  }

  public get children(): Map<string, Logger> {
    return this._children;
  }

  public get level(): Level | 'off' {
    return order[this._level];
  }

  public get format(): string {
    return this._format;
  }

  private _console: Console;
  private _level: number = order.indexOf('debug');
  private _format: string = '{ISOTime}  {LEVE}  [{scope}]  ';

  private _children: Map<string, Logger> = new Map();

  constructor(public readonly scope: string, private _parent: Logger) {
    this._console = _console;
  }
  public setLevel(l: Level | 'off', recursive: boolean = true): Logger {
    this._level = order.indexOf(l);
    if (recursive) {
      this._children.forEach((c) => c.setLevel(l, recursive));
    }
    return this;
  }
  public setFormat(f: string, recursive: boolean = true): Logger {
    this._format = f;
    if (recursive) {
      this._children.forEach((c) => c.setFormat(f, recursive));
    }
    return this;
  }

  public find(subscope: string): Logger {
    if (null == subscope || '' === subscope) {
      return this;
    }

    const [scope, ...deeper] = subscope.split('/');

    if (scope === '..') {
      return this.parent;
    } else if (scope === '') {
      return getLogger(deeper.join('/'));
    }

    let sub: Logger = this._children.get(scope);

    if (undefined === sub) {
      const newScopePrefix = this.scope.length ? this.scope + '/' : '';
      sub = new Logger(newScopePrefix + scope, this);
      sub._console = this.console;
      sub._format = this._format;
      sub._level = this._level;
      this._children.set(scope, sub);
    }

    if (deeper.length) {
      return sub.find(deeper.join('/'));
    }

    return sub;
  }

  public assert(...args: any[]): void {
    if ((args = this.before('assert', ...args)) == null) {
      return;
    }
    this.console.assert(...args);
  }
  public debug(...args: any[]): void {
    if ((args = this.before('debug', ...args)) == null) {
      return;
    }
    this.console.debug(...args);
  }
  public error(...args: any[]): void {
    if ((args = this.before('error', ...args)) == null) {
      return;
    }
    this.console.error(...args);
  }
  public exception(...args: any[]): void {
    if ((args = this.before('exception', ...args)) == null) {
      return;
    }
    this.console.exception(...args);
  }
  public info(...args: any[]): void {
    if ((args = this.before('info', ...args)) == null) {
      return;
    }
    this.console.info(...args);
  }
  public log(...args: any[]): void {
    if ((args = this.before('log', ...args)) == null) {
      return;
    }
    this.console.log(...args);
  }
  public table(...args: any[]): void {
    if ((args = this.before('table', ...args)) == null) {
      return;
    }
    this.console.table(...args);
  }
  public trace(...args: any[]): void {
    if ((args = this.before('trace', ...args)) == null) {
      return;
    }
    this.console.trace(...args);
  }
  public warn(...args: any[]): void {
    if ((args = this.before('warn', ...args)) == null) {
      return;
    }
    this.console.warn(...args);
  }

  protected before(type: Type, ...args: any[]): (any|string)[] | null {
    const level: Level = type as Level;
    const idx: number = order.indexOf(level);
    if (idx < 0) {
      return args;
    }

    if (idx > this._level) {
      // Don't log if the configured level is lower
      return null;
    }

    if (args.length > 0 && typeof args[0] === 'string') {
      return [this.formatter(level) + args[0], ...args.slice(1)];
    } else {
      return [this.formatter(level), ...args];
    }
  }

  protected formatter(level: Level): string {
    const leve = fourChars[order.indexOf(level)].padEnd(4, ' ');
    return this.format
      .replace('{ISOTime}', new Date().toISOString())
      .replace('{level}', level)
      .replace('{LEVEL}', level.toUpperCase())
      .replace('{leve}', leve)
      .replace('{LEVE}', leve.toUpperCase())
      .replace('{scope}', this.scope)
      .replace('{ scope}', this.scope.padStart(32, ' '))
      .replace('{scope }', this.scope.padEnd(32, ' '));
  }
}
// eslint-enable no-cond-assign

export const logger = new Logger('', null);
export const getLogger = (scope?: string): Logger => {
  return logger.find(scope);
};
export default getLogger;

const config: { scope: string; level: Level }[] = [
  { scope: '', level: 'debug' },
  { scope: 'OAuth2', level: 'warn' },
  { scope: 'Vocabulary', level: 'error' },
];

config.forEach(({ scope, level }) => logger.find(scope).setLevel(level, true));
