import * as React from 'react';
import { Subscribe } from 'unstated';
import * as Model from '@mms-v3/data-model-js';
import { OAuth2Container } from '../containers/OAuth2Container';
import { Authorizer } from '../utils/Authorizer';

import getLogger from '../../log';
import { checkIsOwnRecord, checkReadPermission, checkWriteObligation } from 'Auth/utils/DataModel';
const logger = getLogger('Auth/DataModelGate');

export type WritableParam = false | Exclude<Model.WriteObligationValue, 'NONE'>;
export type ReadWritePermissions = {
  read?: boolean;
  write?: WritableParam;
};

export type RenderChild = (
  writable: WritableParam,
  readable: boolean,
  checkPerm: (child?: string) => ReadWritePermissions
) => React.ReactNode;

export type CheckPermissionFunction = (child?: string) => ReadWritePermissions;

export interface Props {
  entity?: string;
  field?: string;
  always?: boolean;
  emails?: string[];
  new?: boolean;
  children?: RenderChild;
}

/** Executes a child render function if read permissions are granted (or always if `always` prop is true).
 * The child function is invoked with two parameters `writable` and `readable` indicating the respective permission.
 * `writable` is `false` or a string; truth checks work as if it was a `boolean`.
 *
 * @example // Render an input only if the field is readable and make it read-only unless it is writable
 * <DataModelGate entity="work_generic" field="work_descriptions.language">
 *   {writable => <input type="text" ... readOnly={!writable} />}
 * </DataModelGate>
 * @example // Equivalent to first example, but using `always`:
 * <DataModelGate always entity="work_generic" field="work_descriptions.language">{(writable, readable) => (
 *   readable ? <input type="text" ... readOnly={!writable} /> : null
 * )}</DataModelGate>
 * @param props
 */
export function DataModelGate(props: Props): React.JSX.Element {

  const renderInner = (authorizer: Authorizer) => {
    const always = props.always ? props.always : false;
    const emails = props.emails ? props.emails : [];
    const _new = props.new ? props.new : false;
    const { entity, field } = props;

    const Entity = Model.DataModel.for(entity);
    if (Entity === null) {
      logger.error('No entity definition for', entity);
      return null;
    }

    /**
     * if no permissions exist for the field, all permissions are denied.
     */
    const Field = Entity.field(field);
    if (Field == null) {
      logger.debug('No attribute definition for', entity, field);
      return null;
    }

    const roles = authorizer.getSession()?.roles ? authorizer.getSession()?.roles : [];
    const isOwnRecord = checkIsOwnRecord(authorizer, _new, emails);

    const checkPermissions = (child: string = null): ReadWritePermissions => {
      const fieldData = Field.field(child);
      return {
        read: field ? checkReadPermission(fieldData, roles, isOwnRecord) : null,
        write: field ? checkWriteObligation(fieldData, roles, isOwnRecord) : null,
      };
    };

    const { read: readPermission, write: writeObligation } = checkPermissions();
    logger.debug(
      'Rendering',
      entity,
      'field',
      field,
      'with read perm',
      readPermission,
      'write obligation',
      writeObligation
    );

    return always || readPermission ? props.children(writeObligation, readPermission, checkPermissions) : null;
  };
  /*
  private checkReadPermission(field: Model.Field, roles: string[], isOwn: boolean) {
    return field.readPermission(roles, isOwn);
  }

  private checkWriteObligation(field: Model.Field, roles: string[], isOwn: boolean) {
    const obligation = field.writeObligation(roles, isOwn);
    // Replace `NONE` with `false` for simple truthyness checks (e.g. `if (writable) ...`)
    return obligation === 'NONE' ? false : obligation;
  }

  private checkIsOwnRecord(authorizer: Authorizer) {
    if (this.props.new) {
      return true;
    }
    const { emails } = this.props;
    const session = authorizer.getSession();
    if (!session) {
      return false;
    }
    const userEmail = session?.user_name?.toLowerCase();
    if (userEmail == null || userEmail.length === 0) {
      return false;
    }
    return emails.findIndex((e) => e.toLowerCase() === userEmail) !== -1;
  }
  */
  return <Subscribe to={[OAuth2Container]}>{(c: OAuth2Container) => renderInner(c.authorizer())}</Subscribe>;
}
