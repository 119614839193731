import React, { ReactElement } from 'react';
import { ProjectOtherNameDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connectOAuth2, OAuth2AwareProps } from '../../../../Auth/utils/connect';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultProjectOtherName } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Flex, Styled } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

/** @jsx jsx */
import { jsx } from 'theme-ui';

const messages = defineMessages({
  projectOtherName: { id: 'edit.project.other_name' },
});

interface ProjectOtherNamesProps {
  checkPermission?: CheckPermissionFunction;
  projectOtherNames: ProjectOtherNameDTO[];
  onChange: (projectOtherNames: ProjectOtherNameDTO[]) => void;
  validation: ValidationResult;
}

type InnerProps = OAuth2AwareProps<ProjectOtherNamesProps & WrappedComponentProps>;

function InnerProjectOtherNames(props: InnerProps) {
  const { checkPermission, projectOtherNames, onChange, intl, validation } = props;

  const deleteProjectOtherName = (deleteAction: (_e: any) => void, e: any): void =>{
    e.preventDefault();
    return deleteAction(e);
  };

  return (
    <>
      <div className="form form-highlight">
        <Styled.h2>
          <FormattedMessage id="entity.attr.projectOtherNames" />
        </Styled.h2>
        <MultiInputControls<ProjectOtherNameDTO>
          lines={projectOtherNames}
          onChange={onChange}
          remove
          add
          renderLines={(projectOtherName: ProjectOtherNameDTO, onChange2: (_projectOtherNames: ProjectOtherNameDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
            <>
              <Flex sx={{gap: 3}}>
                <TextInputP
                  name={intl.formatMessage(messages.projectOtherName)}
                  permissions={checkPermission('other_name')}
                  value={projectOtherName.otherName}
                  onChange={(otherName: string): void => onChange2({ ...projectOtherName, otherName })}
                  validation={validation?.children[idx]?.other_names}
                />
              </Flex>
              {!!actions.delete && (
                <div className='deleteButton' sx={{mt: '-0.1em !important'}}>
                  <DeleteButton
                    disabled={!checkPermission().write}
                    onClick={(e) => deleteProjectOtherName(actions.delete, e) }
                  />
                </div>
              )}
            </>
          )}
          renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
            <div className='addButton'>
              <AddButton onClick={actionAdd} disabled={!checkPermission().write} />
            </div>
          )}
        defaultRow={DefaultProjectOtherName}/>
      </div>
    </>
  );
}

export const ProjectOtherNames = connectOAuth2(injectIntl(InnerProjectOtherNames));
