import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { WorkDTO } from '../../../../api/core';
import { DataModelGate } from '../../../../Auth/components';
import { TextInputP } from '../../../../BaseLayout/components/form/generic/TextInput';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';


const messages = defineMessages({
  applicationNumber: { id: 'work.attr.applicationNumber' },
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: string) => void;
  entity: string;
  className?: string;
  validation: ValidationResult;
}

export function ApplicationNumber({modifiedModel, entity, updateField, className, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate always={false} entity={entity} field="application_number">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <TextInputP
        classes={[className]}
        name={intl.formatMessage(messages.applicationNumber)}
        inputName="applicationNumber"
        permissions={checkPermission()}
        validation={validation}
        value={modifiedModel.applicationNumber ? modifiedModel.applicationNumber : ''}
        onChange={(an: string): void => updateField('applicationNumber', an)}
      />
    )}
  </DataModelGate>;
}
