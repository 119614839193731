import * as React from 'react';
import { Subscribe, SubscribeProps } from 'unstated';
import { Props as SearchbarProps, Searchbar } from '../components/misc/Searchbar';
import { SearchContext } from '../../types';
import { SearchContainer } from './SearchContainer';
import { CElement } from 'react';

export function ConnectedSearchbar(): CElement<SubscribeProps, Subscribe> {
  const getRenderProps = (container: SearchContainer): SearchbarProps => {
    // @TODO: Refactor and fix this component
    const {
      context: searchContext,
      term: searchTerm,
      visibleSearchTerm: changedSearchTerm,
      visible: topBarVisibility,
      dropDownVisible: dropDownVisibility,
    } = container.state;

    return {
      searchContext,
      searchTerm,
      changedSearchTerm,
      topBarVisibility,
      dropDownVisibility,

      onSearchSubmit: async (value: string): Promise<void> => {
        await container.setVisibleSearchTerm(value);
        await container.setSearchTerm(value);
        container.submitSearch();
      },
      onSearchContextSelect: async (context: SearchContext): Promise<void> => {
        await container.setContext(context);
      },
      setDropDownVisibility: async (visible: boolean): Promise<void> => {
        await container.setDropDownVisible(visible);
      },
      setSearchTerm: async (value: string): Promise<void> => {
        await container.setVisibleSearchTerm(value);
        await container.setSearchTerm(value);
      },
    };
  };

  // Return the stateless Searchbar component wrapped in a Subscriber
  return React.createElement(Subscribe, {
    to: [SearchContainer],
    children: (c: SearchContainer): React.ReactNode => {
      // Get the render props using the state container
      const _props = getRenderProps(c);
      return React.createElement(Searchbar, _props);
    },
  });
}
