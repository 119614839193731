/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkGeoLocationPolygonDTO,
    WorkGeoLocationPolygonDTOFromJSON,
    WorkGeoLocationPolygonDTOFromJSONTyped,
    WorkGeoLocationPolygonDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkGeoLocationDTO
 */
export interface WorkGeoLocationDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkGeoLocationDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkGeoLocationDTO
     */
    place?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGeoLocationDTO
     */
    pointLongitude?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGeoLocationDTO
     */
    pointLatitude?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGeoLocationDTO
     */
    boxWestBoundLongitude?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGeoLocationDTO
     */
    boxEastBoundLongitude?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGeoLocationDTO
     */
    boxSouthBoundLatitude?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkGeoLocationDTO
     */
    boxNorthBoundLatitude?: string;
    /**
     * 
     * @type {WorkGeoLocationPolygonDTO}
     * @memberof WorkGeoLocationDTO
     */
    workGeoLocationPolygon?: WorkGeoLocationPolygonDTO;
}

export function WorkGeoLocationDTOFromJSON(json: any): WorkGeoLocationDTO {
    return WorkGeoLocationDTOFromJSONTyped(json, false);
}

export function WorkGeoLocationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkGeoLocationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'place': !exists(json, 'place') ? undefined : json['place'],
        'pointLongitude': !exists(json, 'pointLongitude') ? undefined : json['pointLongitude'],
        'pointLatitude': !exists(json, 'pointLatitude') ? undefined : json['pointLatitude'],
        'boxWestBoundLongitude': !exists(json, 'boxWestBoundLongitude') ? undefined : json['boxWestBoundLongitude'],
        'boxEastBoundLongitude': !exists(json, 'boxEastBoundLongitude') ? undefined : json['boxEastBoundLongitude'],
        'boxSouthBoundLatitude': !exists(json, 'boxSouthBoundLatitude') ? undefined : json['boxSouthBoundLatitude'],
        'boxNorthBoundLatitude': !exists(json, 'boxNorthBoundLatitude') ? undefined : json['boxNorthBoundLatitude'],
        'workGeoLocationPolygon': !exists(json, 'workGeoLocationPolygon') ? undefined : WorkGeoLocationPolygonDTOFromJSON(json['workGeoLocationPolygon']),
    };
}

export function WorkGeoLocationDTOToJSON(value?: WorkGeoLocationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'place': value.place,
        'pointLongitude': value.pointLongitude,
        'pointLatitude': value.pointLatitude,
        'boxWestBoundLongitude': value.boxWestBoundLongitude,
        'boxEastBoundLongitude': value.boxEastBoundLongitude,
        'boxSouthBoundLatitude': value.boxSouthBoundLatitude,
        'boxNorthBoundLatitude': value.boxNorthBoundLatitude,
        'workGeoLocationPolygon': WorkGeoLocationPolygonDTOToJSON(value.workGeoLocationPolygon),
    };
}


