/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PagePublisherDTO,
    PagePublisherDTOFromJSON,
    PagePublisherDTOToJSON,
    PatchDocument,
    PatchDocumentFromJSON,
    PatchDocumentToJSON,
    PublisherDTO,
    PublisherDTOFromJSON,
    PublisherDTOToJSON,
} from '../models';

export interface CreatePublisherRequest {
    publisherDTO: PublisherDTO;
}

export interface DeletePublisherRequest {
    publisherId: number;
}

export interface GetPublisherRequest {
    publisherId: number;
}

export interface GetPublishersRequest {
    page: number;
    size: number;
}

export interface UpdatePublisherRequest {
    publisherId: number;
    patchDocument?: Array<PatchDocument>;
}

/**
 * 
 */
export class PublisherApi extends runtime.BaseAPI {

    /**
     */
    async createPublisherRaw(requestParameters: CreatePublisherRequest): Promise<runtime.ApiResponse<PublisherDTO>> {
        if (requestParameters.publisherDTO === null || requestParameters.publisherDTO === undefined) {
            throw new runtime.RequiredError('publisherDTO','Required parameter requestParameters.publisherDTO was null or undefined when calling createPublisher.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publisher`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublisherDTOToJSON(requestParameters.publisherDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PublisherDTOFromJSON(jsonValue));
    }

    /**
     */
    async createPublisher(requestParameters: CreatePublisherRequest): Promise<PublisherDTO> {
        const response = await this.createPublisherRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deletePublisherRaw(requestParameters: DeletePublisherRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.publisherId === null || requestParameters.publisherId === undefined) {
            throw new runtime.RequiredError('publisherId','Required parameter requestParameters.publisherId was null or undefined when calling deletePublisher.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publisher/{publisherId}`.replace(`{${"publisherId"}}`, encodeURIComponent(String(requestParameters.publisherId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePublisher(requestParameters: DeletePublisherRequest): Promise<void> {
        await this.deletePublisherRaw(requestParameters);
    }

    /**
     */
    async getPublisherRaw(requestParameters: GetPublisherRequest): Promise<runtime.ApiResponse<PublisherDTO>> {
        if (requestParameters.publisherId === null || requestParameters.publisherId === undefined) {
            throw new runtime.RequiredError('publisherId','Required parameter requestParameters.publisherId was null or undefined when calling getPublisher.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publisher/{publisherId}`.replace(`{${"publisherId"}}`, encodeURIComponent(String(requestParameters.publisherId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PublisherDTOFromJSON(jsonValue));
    }

    /**
     */
    async getPublisher(requestParameters: GetPublisherRequest): Promise<PublisherDTO> {
        const response = await this.getPublisherRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPublishersRaw(requestParameters: GetPublishersRequest): Promise<runtime.ApiResponse<PagePublisherDTO>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getPublishers.');
        }

        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size','Required parameter requestParameters.size was null or undefined when calling getPublishers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publisher`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePublisherDTOFromJSON(jsonValue));
    }

    /**
     */
    async getPublishers(requestParameters: GetPublishersRequest): Promise<PagePublisherDTO> {
        const response = await this.getPublishersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePublisherRaw(requestParameters: UpdatePublisherRequest): Promise<runtime.ApiResponse<PublisherDTO>> {
        if (requestParameters.publisherId === null || requestParameters.publisherId === undefined) {
            throw new runtime.RequiredError('publisherId','Required parameter requestParameters.publisherId was null or undefined when calling updatePublisher.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publisher/{publisherId}`.replace(`{${"publisherId"}}`, encodeURIComponent(String(requestParameters.publisherId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchDocument.map(PatchDocumentToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PublisherDTOFromJSON(jsonValue));
    }

    /**
     */
    async updatePublisher(requestParameters: UpdatePublisherRequest): Promise<PublisherDTO> {
        const response = await this.updatePublisherRaw(requestParameters);
        return await response.value();
    }

}
