// Components
import * as React from 'react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { LoaderBar } from '../../../BaseLayout/components/misc/LoaderBar';

// Types
import { FacetEntry, SearchFacet, SearchFacetValueMap } from '../../types';
import { Props } from './index';
import { PaginationProps, CounterProps, LimiterProps, Counter, Limiter, Pagination } from './parts';

import { Facet } from '../Facet';
import { Link } from 'react-router-dom';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export type FacetAreaProps = Pick<
  Props,
  | 'className'
  | 'availableFacetEntries'
  | 'activeFacetEntries'
  | 'facetOffsets'
  | 'onFacetOffsetUpdate'
  | 'onFacetEntriesSet'
  | 'dashboardType'
>;

export type ResultAreaProps = PaginationProps &
  CounterProps &
  LimiterProps &
  Pick<
    Props,
    'className' | 'searchResultCount' | 'searchResultsLoading' | 'searchResultsError' | 'facetsLoading' | 'embedded'
  >;

export const FacetArea: React.FC<FacetAreaProps> = (props) => {
  const {
    availableFacetEntries,
    activeFacetEntries,
    facetOffsets,
    onFacetOffsetUpdate,
    onFacetEntriesSet,
    dashboardType,
    className,
  } = props;

  const mapping = (arr: SearchFacet[], active: SearchFacetValueMap<FacetEntry[]>) =>
    arr
      .map((f, index) => {
        const activeEntries = active.hasOwnProperty(f.name) ? active[f.name] : [];
        const offset = facetOffsets.hasOwnProperty(f.name) ? facetOffsets[f.name] : 0;

        return {
          ...f,
          activeEntries,
          index,
          offset,
        };
      })
      .filter((e) => e !== undefined)
      .sort((a, b) => a.index - b.index);

  const facets = mapping(availableFacetEntries, activeFacetEntries);

  return (
    <div className={className} sx={{
      label: "facet-area",
      h2: {
        fontSize: "1.1em",
      }
    }}>
    {facets.length > 0 ? (
      <div key="facets">
        {facets.map((f) => (
          (f.entries.length > 0) ?
          <Facet
            key={f.name + dashboardType}
            {...f}
            dashboardType={dashboardType}
            page={Math.floor(f.offset / f.pageLimit) + 1}
            onOffsetUpdate={(v) => onFacetOffsetUpdate(f.name, v)}
            onEntriesSet={(e) => onFacetEntriesSet(f.name, e)}
          /> : <></>
        ))}
      </div>
    ) : (
      <></>
    )}
    </div>
  );
};

export const ResultArea: React.FC<ResultAreaProps> = (props) => {
  const {
    searchResultCount,
    searchResultsLoading,
    searchResultsError,
    facetsLoading,
    embedded,
    className,
    children,
  } = props;

  const childProps: ResultAreaProps = {
    ...props,
    className: null,
  };

  const elements: JSX.Element[] = [];
  elements.push(<LoaderBar key="loader-bar" hidden={!searchResultsLoading && !facetsLoading} />);
  if (!embedded) {
    elements.push(<Counter key="counter-top" {...childProps} />);
    elements.push(<Limiter key="limiter-top" {...childProps} />);
  }
  // elements.push(<React.Fragment key="facet-breadcrumb">{this.renderFacetBreadcrumb()}</React.Fragment>);

  if (searchResultsError !== null) {
    elements.push(
      <div key="error" className="error">
        <p>
          <FormattedMessage id="error.request" />
        </p>
        <small>{searchResultsError}</small>
      </div>
    );
  }

  if (!searchResultsLoading && !searchResultsError) {
    if (!embedded) {
      elements.push(<Pagination key="pagination-top" {...childProps} className="pagination" />);
    }
    if (searchResultCount > 0) {
      elements.push(<div key="results">{children}</div>);
    } else {
      elements.push(
        <p key="no-results" className="info">
          <FormattedMessage id="search.no_results" />
        </p>
      );
    }
    elements.push(<Pagination key="pagination-bottom" {...childProps} className="pagination" />);
    if (searchResultCount > 0 && embedded) {
      elements.push(<Counter key="counter-bottom" {...childProps} />);
      elements.push(<Limiter key="limiter-bottom" {...childProps} />);
    }
  }

  return <div className={className} sx={{
    pt: 5,
    px: 8,
    ".facet-breadcrumb": {
    "&:empty": {
      p: 0
    },
    pt: "0.5em",
    ">button": {
      "&.negated": {
        border: "1px solid",
        borderColor: "negative"
      },
      m: "0.1em 0.1em",
      cursor: "pointer",
      border: "1px solid",
      borderColor: 'secondary'
    }
  },
  ">.pagination": {
    mt: "1.5em",
    mb: "1em",
    position: "relative",
    ">.sorting": {
      flexShrink: 0,
      ">.dropdownList": {
        display: "inline-block",
        ".rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover": {
          backgroundColor: 'primary',
          borderColor: 'primary',
          color: "white"
        },
        ".rw-dropdown-list": {
          cursor: "pointer"
        },
        ".rw-dropdown-list-input": {
          maxWidth: "initial",
          color: 'darker',
          fontWeight: 'bold'
        },
        ".rw-input": {
          p: "0 0 0.25em 0.5em"
        },
        ".rw-btn-select": {
          pb: '0.1em',
          width: '1em'
        },
        ".rw-widget": {
          p: "0.15em 0"
        },
        ".rw-widget-input": {
          boxShadow: "none"
        },
        ".rw-widget-container": {
          background: "none",
          border: "none",
        },
        ".rw-widget-picker": {
          height: "auto",
          borderRadius: 0
        },
        ".rw-popup": {
          borderRadius: 0
        },
        ".rw-popup-container": {
          right: "auto"
        },
        ".rw-list-option": {
          p: "0.15em 0.5em",
          whiteSpace: "nowrap",
          textAlign: "left"
        }
      }
    }
  },
  ".result-list": {
    ".result": {
      backgroundColor: "white",
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'light',
      mt: "1em",
      pt: "0.5em",
      ">.content": {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        ">.right": {
          alignSelf: "flex-end",
          ">*": {
            textAlign: "right"
          }
        },
        ".employment-periods": {
          fontSize: '0.9em',
          color: 'darker',
          display: 'inline-flex',
          ".employment-periods-data": {
            display: 'inline-flex',
            flexWrap: 'wrap'
          }
        },
        ">.info": {
          p: "0 1em"
        },
        ">.main": {
          width: "100%"
        },
        ">.actions": {
          flexShrink: 0,
        },
        ">.info.right": {
          pt: '0.5em',
          pr: '1.25em',
          flexShrink: 0,
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start"
        },
        ".authors": {
          color: "", // ${themeColor('third', 'dashboard')};
          mt: "0.3em",
          fontSize: "0.8em",
          a: {
            color: 'primary'
          },
          ">.more-button, >.less-button": {
            display: "none",
            cursor: "pointer",
            color: "darkgrey",
            pl: "0.3em"
          },
          "&.expandable": {
            ">.more": {
              display: "none",
            },
            ">.more-button": {
              display: "inline",
            },
            "&.expanded": {
              ">.more": {
                display: "inline",
              },
              ">.more-button": {
                display: "none",
              },
              ">.less-button": {
                display: "inline",
              }
            },
            "&:not(.expanded) > .content::after": {
              content: '";…"',
            }
          }
        },

        ".main-title": {
          a: {
            textDecoration: "none",
            fontWeight: "bold",
            fontSize: '1.1em',
            color: "dashboard",
            "&:hover": {
              color: 'secondary',
            }
          }
        },
        ".sub-title": {
          fontSize: "0.9em",
          fontWeight: "bold",
          color: "rgb(95, 95, 95)",
          mb: "0.2em",
        },
        ".organisations": {
          mt: "0.3em",
          fontSize: "0.8em",
          zIndex: '99999',
          a: {
            color: "darker",
            textDecoration: "none",
            "&:hover": {
              color: 'secondary'
            }
          }
        },
        ".ids": {
          mt: '0.3em',
          ml:'-0.25em',
          fontSize: "0.8em",
          flexWrap: "wrap",
          listStyle: "none",
          img: {
            display: 'inline-flex',
            height: '1em',
            width: '1em',
            verticalAlign: 'middle',
            mr: '0.1em',
            mb: '0.2em'
          },
          span: {
            display: "inline-flex",
            whiteSpace: "unset",
          },
          ">*": {
            m: "0 0.2em",
            ">a": {
              color: "primary",
              fontWeight: 'bold',
              textDecoration: "none",
              "&:hover": {
                color: 'secondary'
              }
            }
          }
        },
        ".hierarchy": {
          fontSize: "0.9em",
          "a, span": {
            color: "darker",
            textDecoration: 'none'
          },
          "a:hover": {
            color: "secondary"
          }
        },
        ".groups": {
          mt: "0.3em",
          fontSize: "0.8em",
          a: {
            textDecoration: "none",
            color: "text"
          }
        },
        ".record": {
          fontSize: "0.9em",
          color: 'darker'
        }
      },
      ".index": {
        color: 'index',
        fontSize: '1.1em',
        fontWeight: 'bold'
      },
      ".actions": {
        width: "100%",
        height: "2.5em",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        position: "relative",
        ".path1:before, .path2:before, .path3:before, path4:before, .path5:before, .path6:before, .path7:before, .path8:before": {color: 'dashboard'},
        "a:hover": {
          color: 'secondary',
          ".path1:before, .path2:before, .path3:before, path4:before, .path5:before, .path6:before, .path7:before, .path8:before": {color: 'secondary'}
        },
        ">.index, >.cite": {
          color: "rgb(95, 95, 95)", // ${themeColor('second', 'dashboard')};
          textDecoration: "none",
          fontSize: "1.1em"
        },
        ">.index": {
          margin: "0 auto 0 0.9em",
        },
        ">.cite": {
          mt: '0.1em',
          color: 'dashboard',
          textDecoration: "none",
          fontSize: '1em',
          fontWeight: 'bold',
          cursor: "pointer",
          "&:hover": {
            color: "secondary",
            ".icon-zitieren_blau:before": {color: 'secondary'}
          }
        },
        ".div-option-list": {
          ".option-list": {
            position: "absolute",
            listStyle: "none",
            mt: "3em",
            backgroundColor: "white",
            boxShadow: "0 8px 6px hsla(0, 0%, 78.4%, 0.5)",
            zIndex: 900,
            display: "none",
            "&.active": {
              display: "block",
              mt: '0em',
              ml: '1.5em'
            },
            li: {
              display: "block"
            },
            a: {
              display: "block",
              color: "black",
              p: "0.5em",
              textDecoration: "none",
              "&:hover": {
                backgroundColor: "primary",
                color: 'white'
              },
              "&.invalid": {
                color: 'red'
              }
            }
          },
          ">.options": {
            color: 'dashboard',
            display: 'inline-flex',
            cursor: "pointer",
            fontSize: "1.5em",
            mr: "0.5em",
            mt: '0.15em',
            "&:hover": {
              color: 'secondary',
              ".edit": {color: 'secondary'}
            },
            ".edit": {
              color: 'dashboard',
              display: 'inline-flex',
              fontSize: '0.65em',
              mt: '0.3em',
              fontWeight: 'bold'
            },
            "&.invalid": {
              ".edit": {
                color: 'red'
              },
              color: 'red'
            },
          }
        }
      },
      ".citation-popup-container": {
        "&.hidden": {
          display: "none"
        }
      }
    }
  }}} >{elements}</div>;
};

export const WorkButton: React.FC<{id: any; workdashboard: boolean}> = ({id, workdashboard, children}): ReactElement => {
  return <Link to={{pathname:`/person/${id}`, state: workdashboard}} sx={{textDecoration: 'none', color: 'dashboard', fontWeight: 'bold', display: 'inline-flex'}}>
            <div sx={{mt: '0.2em'}}>
              <i sx={{mr: '0.2em'}} className="icon-werkeanzeigen">
                <span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span>
              </i>
            </div>
            <div sx={{mt: '0.15em', mr: '1em'}}>
              <FormattedMessage id='button.show_work'/>
            </div>
         </Link>;
};
