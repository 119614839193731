import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export interface Props {
  href: string;
  blank?: boolean;
  opener?: boolean;
  icon?: boolean;
}

const defaultProps: Partial<Props> = {
  blank: true,
  opener: false,
  icon: true,
};

export class OutLink extends React.PureComponent<Props> {
  public static readonly defaultProps = defaultProps;

  public render() {
    const { href, children, blank, opener, icon } = this.props;

    const aProps: any = { href };
    if (blank) {
      aProps.target = '_blank';
      aProps.rel = opener ? 'opener' : 'noopener noreferrer';
    }

    return (
      <>
        <a {...aProps}>
          {children}
          {icon ? (
            <>
              {''}
              <FontAwesomeIcon
                style={{ height: '0.9em', marginLeft: !!children ? '0.1em' : null }}
                icon={faExternalLinkAlt}
              />
            </>
          ) : (
            <></>
          )}
        </a>
      </>
    );
    // return <><a {...aProps}>{children}{icon ? <>{' '}<i className="fa fa-external-link" /></> : <></>}</a></>;
  }
}
