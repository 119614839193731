/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SolrProject
 */
export interface SolrProject {
    /**
     * 
     * @type {string}
     * @memberof SolrProject
     */
    id: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrProject
     */
    name?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrProject
     */
    hierarchy?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrProject
     */
    partner?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrProject
     */
    gnd?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof SolrProject
     */
    recordCreationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SolrProject
     */
    recordChangeDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SolrProject
     */
    editorialStatus?: string;
}

export function SolrProjectFromJSON(json: any): SolrProject {
    return SolrProjectFromJSONTyped(json, false);
}

export function SolrProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolrProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'hierarchy': !exists(json, 'hierarchy') ? undefined : json['hierarchy'],
        'partner': !exists(json, 'partner') ? undefined : json['partner'],
        'gnd': !exists(json, 'gnd') ? undefined : json['gnd'],
        'recordCreationDate': !exists(json, 'recordCreationDate') ? undefined : (new Date(json['recordCreationDate'])),
        'recordChangeDate': !exists(json, 'recordChangeDate') ? undefined : (new Date(json['recordChangeDate'])),
        'editorialStatus': !exists(json, 'editorialStatus') ? undefined : json['editorialStatus'],
    };
}

export function SolrProjectToJSON(value?: SolrProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'hierarchy': value.hierarchy,
        'partner': value.partner,
        'gnd': value.gnd,
        'recordCreationDate': value.recordCreationDate === undefined ? undefined : (value.recordCreationDate.toISOString()),
        'recordChangeDate': value.recordChangeDate === undefined ? undefined : (value.recordChangeDate.toISOString()),
        'editorialStatus': value.editorialStatus,
    };
}


