/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminDataWorkOrcidSyncDTO
 */
export interface AdminDataWorkOrcidSyncDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminDataWorkOrcidSyncDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkOrcidSyncDTO
     */
    orcidId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkOrcidSyncDTO
     */
    orcidPutCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkOrcidSyncDTO
     */
    orcidVisibility?: string;
}

export function AdminDataWorkOrcidSyncDTOFromJSON(json: any): AdminDataWorkOrcidSyncDTO {
    return AdminDataWorkOrcidSyncDTOFromJSONTyped(json, false);
}

export function AdminDataWorkOrcidSyncDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDataWorkOrcidSyncDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orcidId': !exists(json, 'orcidId') ? undefined : json['orcidId'],
        'orcidPutCode': !exists(json, 'orcidPutCode') ? undefined : json['orcidPutCode'],
        'orcidVisibility': !exists(json, 'orcidVisibility') ? undefined : json['orcidVisibility'],
    };
}

export function AdminDataWorkOrcidSyncDTOToJSON(value?: AdminDataWorkOrcidSyncDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'orcidId': value.orcidId,
        'orcidPutCode': value.orcidPutCode,
        'orcidVisibility': value.orcidVisibility,
    };
}


