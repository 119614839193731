/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface YearPeriod
 */
export interface YearPeriod {
    /**
     * 
     * @type {number}
     * @memberof YearPeriod
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof YearPeriod
     */
    end?: number;
}

export function YearPeriodFromJSON(json: any): YearPeriod {
    return YearPeriodFromJSONTyped(json, false);
}

export function YearPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): YearPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : json['start'],
        'end': !exists(json, 'end') ? undefined : json['end'],
    };
}

export function YearPeriodToJSON(value?: YearPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start,
        'end': value.end,
    };
}


