/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SearchRequest,
    SearchRequestFromJSON,
    SearchRequestToJSON,
    SolrResultSolrOrganisation,
    SolrResultSolrOrganisationFromJSON,
    SolrResultSolrOrganisationToJSON,
    SolrResultSolrPerson,
    SolrResultSolrPersonFromJSON,
    SolrResultSolrPersonToJSON,
    SolrResultSolrProject,
    SolrResultSolrProjectFromJSON,
    SolrResultSolrProjectToJSON,
    SolrResultSolrPublisher,
    SolrResultSolrPublisherFromJSON,
    SolrResultSolrPublisherToJSON,
    SolrResultSolrWork,
    SolrResultSolrWorkFromJSON,
    SolrResultSolrWorkToJSON,
} from '../models';

export interface SearchOrganisationRequest {
    searchRequest: SearchRequest;
}

export interface SearchPersonRequest {
    searchRequest: SearchRequest;
}

export interface SearchProjectRequest {
    searchRequest: SearchRequest;
}

export interface SearchPublisherRequest {
    searchRequest: SearchRequest;
}

export interface SearchWorkRequest {
    searchRequest: SearchRequest;
}

/**
 * 
 */
export class SearchApi extends runtime.BaseAPI {

    /**
     */
    async searchOrganisationRaw(requestParameters: SearchOrganisationRequest): Promise<runtime.ApiResponse<SolrResultSolrOrganisation>> {
        if (requestParameters.searchRequest === null || requestParameters.searchRequest === undefined) {
            throw new runtime.RequiredError('searchRequest','Required parameter requestParameters.searchRequest was null or undefined when calling searchOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/organisation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.searchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SolrResultSolrOrganisationFromJSON(jsonValue));
    }

    /**
     */
    async searchOrganisation(requestParameters: SearchOrganisationRequest): Promise<SolrResultSolrOrganisation> {
        const response = await this.searchOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchPersonRaw(requestParameters: SearchPersonRequest): Promise<runtime.ApiResponse<SolrResultSolrPerson>> {
        if (requestParameters.searchRequest === null || requestParameters.searchRequest === undefined) {
            throw new runtime.RequiredError('searchRequest','Required parameter requestParameters.searchRequest was null or undefined when calling searchPerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.searchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SolrResultSolrPersonFromJSON(jsonValue));
    }

    /**
     */
    async searchPerson(requestParameters: SearchPersonRequest): Promise<SolrResultSolrPerson> {
        const response = await this.searchPersonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchProjectRaw(requestParameters: SearchProjectRequest): Promise<runtime.ApiResponse<SolrResultSolrProject>> {
        if (requestParameters.searchRequest === null || requestParameters.searchRequest === undefined) {
            throw new runtime.RequiredError('searchRequest','Required parameter requestParameters.searchRequest was null or undefined when calling searchProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.searchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SolrResultSolrProjectFromJSON(jsonValue));
    }

    /**
     */
    async searchProject(requestParameters: SearchProjectRequest): Promise<SolrResultSolrProject> {
        const response = await this.searchProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchPublisherRaw(requestParameters: SearchPublisherRequest): Promise<runtime.ApiResponse<SolrResultSolrPublisher>> {
        if (requestParameters.searchRequest === null || requestParameters.searchRequest === undefined) {
            throw new runtime.RequiredError('searchRequest','Required parameter requestParameters.searchRequest was null or undefined when calling searchPublisher.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/publisher`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.searchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SolrResultSolrPublisherFromJSON(jsonValue));
    }

    /**
     */
    async searchPublisher(requestParameters: SearchPublisherRequest): Promise<SolrResultSolrPublisher> {
        const response = await this.searchPublisherRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async searchWorkRaw(requestParameters: SearchWorkRequest): Promise<runtime.ApiResponse<SolrResultSolrWork>> {
        if (requestParameters.searchRequest === null || requestParameters.searchRequest === undefined) {
            throw new runtime.RequiredError('searchRequest','Required parameter requestParameters.searchRequest was null or undefined when calling searchWork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/search/work`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRequestToJSON(requestParameters.searchRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SolrResultSolrWorkFromJSON(jsonValue));
    }

    /**
     */
    async searchWork(requestParameters: SearchWorkRequest): Promise<SolrResultSolrWork> {
        const response = await this.searchWorkRaw(requestParameters);
        return await response.value();
    }

}
