import * as React from 'react';
import { ReactElement } from 'react';
import { WorkIsPartOfDTO, WorkIsPartOfOtherDTO } from "api/core/models/";
import { Flex } from '@theme-ui/components';
import { Left, Right, Clear} from '../../components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export enum Pubtype {
  ABSTRACT = 'abstract',
  ARTICLE_INTEGRATING_RESOURCE = 'article_integrating_resource',
  ARTICLE_JOURNAL = 'article_journal',
  ARTICLE_NEWSPAPER = 'article_newspaper',
  ARTICLE_OVERVIEW = 'article_overview',
  AUDIO_VIDEO_DOCUMENT = 'audio_video_document',
  BLOG = 'blog',
  CHAPTER = 'chapter',
  CHAPTER_IN_CONFERENCE = 'chapter_in_conference',
  CHAPTER_IN_LEGAL_COMMENTARY = 'chapter_in_legal_commentary',
  COLLECTION = 'collection',
  COLLECTION_MVW = 'collection_mvw',
  CONFERENCE = 'conference',
  CONFERENCE_MVW = 'conference_mvw',
  DISSERTATION = 'dissertation',
  EDITION = 'edition',
  EDITION_MVW = 'edition_mvw',
  EDITORIAL = 'editorial',
  HABILITATION = 'habilitation',
  JOURNAL = 'journal',
  LECTURE = 'lecture',
  LEGAL_COMMENTARY = 'legal_commentary',
  LEXICON_ARTICLE = 'lexicon_article',
  MONOGRAPH = 'monograph',
  MONOGRAPH_MVW = 'monograph_mvw',
  MULTI_VOLUME_WORK = 'multi_volume_work',
  NEWSPAPER = 'newspaper',
  PATENT = 'patent',
  POSTER = 'poster',
  RADIO_TV = 'radio_tv',
  REPORT_COLLECTION = 'report_collection',
  REPORT_GREY_LITERATURE = 'report_grey_literature',
  RESEARCH_DATA = 'research_data',
  REVIEW = 'review',
  SERIES = 'series',
  SPECIAL_ISSUE = 'special_issue',
  STUDENT_THESIS = 'student_thesis',
  WEBSITE = 'website',
}

export enum Entity {
  AUDIO_VIDEO_OBJECT = 'audio_video_object',
  FRAGMENT = 'fragment',
  INTEGRATING_RESOURCE = 'integrating_resource',
  MULTI_VOLUME_WORK = 'multi_volume_work',
  PATENT = 'patent',
  PERIODICAL = 'periodical',
  RESEARCH_DATA_GROUP = 'research_data_group',
  SELF_CONTAINED = 'self_contained',
  SELF_CONTAINED_OR_FRAGMENT = 'self_contained_or_fragment',
  SERIES = 'series',
  THESIS = 'thesis',
}

/**
 * Maps the pubtype of a work to an entity
 *
 * References:
 * git project "data-model":
 * - Entities are specified in "entities.xlsx"
 * - Mapping is specified on page 8 in "mms_metadaten_dokumentation.pdf"
 *
 * @param pubtype
 */
export const pubtypeToEntity = (pubtype: Pubtype): Entity => {
  switch (pubtype) {
    case Pubtype.ABSTRACT:
    case Pubtype.ARTICLE_INTEGRATING_RESOURCE:
    case Pubtype.ARTICLE_JOURNAL:
    case Pubtype.ARTICLE_NEWSPAPER:
    case Pubtype.ARTICLE_OVERVIEW:
    case Pubtype.CHAPTER:
    case Pubtype.CHAPTER_IN_CONFERENCE:
    case Pubtype.CHAPTER_IN_LEGAL_COMMENTARY:
    case Pubtype.EDITORIAL:
    case Pubtype.LEXICON_ARTICLE:
    case Pubtype.REVIEW:
      return Entity.FRAGMENT;
    case Pubtype.LECTURE:
    case Pubtype.POSTER:
    case Pubtype.REPORT_GREY_LITERATURE:
      return Entity.SELF_CONTAINED_OR_FRAGMENT;
    case Pubtype.COLLECTION:
    case Pubtype.CONFERENCE:
    case Pubtype.EDITION:
    case Pubtype.LEGAL_COMMENTARY:
    case Pubtype.MONOGRAPH:
    case Pubtype.REPORT_COLLECTION:
    case Pubtype.SPECIAL_ISSUE:
      return Entity.SELF_CONTAINED;
    case Pubtype.COLLECTION_MVW:
    case Pubtype.CONFERENCE_MVW:
    case Pubtype.EDITION_MVW:
    case Pubtype.MONOGRAPH_MVW:
    case Pubtype.MULTI_VOLUME_WORK:
      return Entity.MULTI_VOLUME_WORK;
    case Pubtype.DISSERTATION:
    case Pubtype.HABILITATION:
    case Pubtype.STUDENT_THESIS:
      return Entity.THESIS;
    case Pubtype.PATENT:
      return Entity.PATENT;
    case Pubtype.AUDIO_VIDEO_DOCUMENT:
    case Pubtype.RADIO_TV:
      return Entity.AUDIO_VIDEO_OBJECT;
    case Pubtype.JOURNAL:
    case Pubtype.NEWSPAPER:
    case Pubtype.SERIES:
      return Entity.PERIODICAL;
    case Pubtype.RESEARCH_DATA:
      return Entity.RESEARCH_DATA_GROUP;
    case Pubtype.BLOG:
    case Pubtype.WEBSITE:
      return Entity.INTEGRATING_RESOURCE;
    default:
  }
};


/**
 * Sorts two WorkParts (WorkIsPartOf or WorkHasPart) or WorkIsPartOfOthers
 * by Volume > issue > articleId > pageFirst > referencedWork->title / name
 *
 * @param a
 * @param b
 * @return number
 *      =0 -> Reihenfolge unverändert,
 *      <0 -> a hoch, b runter,
 *      >0 -> a runter, b hoch
 */
export const sortWorks = (a: WorkIsPartOfDTO|WorkIsPartOfOtherDTO, b: WorkIsPartOfDTO|WorkIsPartOfOtherDTO): number => {
  const fs: [string, string][] = [
    [a.volume, b.volume],
    [a.issue, b.issue],
    [a.articleId, b.articleId],
    [a.pageFirst, b.pageFirst]
  ];
  let result: number = 0;
  for(const f of fs) {
    result = sortStringifiedNumbers(...f);
    if(result !== 0 ) {
      return result;
    }
  }
  const titleA = (a as WorkIsPartOfDTO).referencedWork?.title || (a as WorkIsPartOfOtherDTO).name;
  const titleB = (b as WorkIsPartOfDTO).referencedWork?.title || (b as WorkIsPartOfOtherDTO).name;
  return titleA.localeCompare(titleB);
};

const sortStringifiedNumbers = (a: string, b: string): number => {
  const ai = parseInt(a, 10);
  const bi = parseInt(b, 10);
  if(isNaN(ai) && !isNaN(bi)) return -1;
  if(!isNaN(ai) && isNaN(bi)) return 1;
  if(!isNaN(ai) && !isNaN(bi)) return ai - bi;
  return 0;
};


export const FlexRow: React.FC<{className: string; label: string|ReactElement}> = ({ children, className, label }) => {
  return (<Flex className={className} variant="layout.multiRow" sx={{
    borderBottom: '1.5px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'primary',
    img: {
      display: 'inline-block',
      height: '1em',
      width: '1em',
      verticalAlign: 'middle',
      mr: '0.2em',
      mb: '0.15em'
    }
  }}>
    <Left name="left-col">
      <h4 className={"head"+className}>
        {label}
      </h4>
    </Left>
    <Right name="right-col">{children}</Right>
    <Clear name="clear" />
  </Flex>);
};
