import * as React from 'react';
import { Provider } from 'unstated';
import { OAuth2Container } from '../containers/OAuth2Container';
import { oauth2Config } from '../data';
import getLogger from '../../log';

// Store
import store from 'store';
import storeEvents from 'store/plugins/events';
import { ReactNode } from 'react';

store.addPlugin(storeEvents);

const logger = getLogger('OAuth2/Provider');

export interface Props {}

interface State {
  stage: 'initial' | 'missing' | 'loading' | 'present' | 'saving';
}

/**
 * Provide the authentication container to child nodes and
 * manage persistence in the browser store
 */
export class OAuth2Provider extends React.Component<Props, State> {
  public container: OAuth2Container;

  private boundHandleStorageUpdate = this.handleStorageUpdate.bind(this);
  private handleStorageUpdateSubscription = undefined;

  private boundHandleStateUpdate = this.handleStateUpdate.bind(this);

  private key = 'auth';

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = { stage: 'initial' };

    this.container = new OAuth2Container(oauth2Config);
  }

  public render(): React.JSX.Element {
    return <Provider inject={[this.container]}>{this.renderChildren()}</Provider>;
  }

  public async componentDidMount(): Promise<void> {
    // Listen for changes in the storage
    this.handleStorageUpdateSubscription = (store as any).watch(this.key, this.boundHandleStorageUpdate);
    // Listen for changes in the container state
    this.container.subscribe(this.boundHandleStateUpdate);

    // Hydrate the container
    this.loadContainerState().then(() => this.saveContainerState());
  }

  public componentWillUnmount(): void {
    // Remove all event listeners set in componentDidMount
    if (undefined !== this.handleStorageUpdateSubscription) {
      (store as any).unwatch(this.handleStorageUpdateSubscription);
      this.handleStorageUpdateSubscription = undefined;
    }
    this.container.unsubscribe(this.boundHandleStateUpdate);

    // Persist the container
    this.saveContainerState();
  }

  private renderChildren(): ReactNode | undefined {
    const { stage } = this.state;

    if (stage === 'present' || stage === 'saving') {
      return this.props.children;
    } else {
      return 'Loading...';
    }
  }

  private handleStorageUpdate(): void {
    if (this.state.stage === 'saving') {
      // Skip save in own window
      return;
    }
    logger.log('Storage changed...');
    void this.loadContainerState();
  }

  private handleStateUpdate(): void {
    if (this.state.stage === 'loading') {
      // Skip load in own window
      return;
    }
    logger.log('State changed...', this.container.state, new Error());
    void this.saveContainerState();
  }

  private async loadContainerState(): Promise<void> {
    const setStateAsync = (updater: State) => new Promise((resolve) => this.setState(updater, resolve));
    await setStateAsync({ stage: 'loading' });

    const data = await this.storageGet();
    if (data != null) {
      await this.container.hydrate(data);
      await setStateAsync({ stage: 'present' });
      logger.log('Loaded', data, this.container.state);
    } else {
      await setStateAsync({ ...data, stage: 'missing' });
    }
  }

  private async saveContainerState() {
    const setStateAsync = (updater: State) => new Promise((resolve) => this.setState(updater, resolve));
    await setStateAsync({ stage: 'saving' });

    const data = this.container.state;
    await this.storageSet(data);
    logger.log('Saved', data);

    await setStateAsync({ stage: 'present' });
  }

  private async storageGet() {
    const { key } = this;

    return store.get(key);
  }

  private async storageSet(data: any) {
    const { key } = this;
    store.set(key, data);
  }
}
