import React, { useState } from 'react';
import { PersonStatusDTO } from '../../../api/core/models';
import { MultiInputControls } from '../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { getNotation } from '../../../Vocabulary/util';
import { DefaultPersonStatus } from '../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../BaseLayout/components/form/MultiInput/Buttons';
import { CheckPermissionFunction } from '../../../Auth/components/DataModelGate';
import { Styled } from 'theme-ui';
import { ValidationResult, ValidationResults } from '../../validation/interfaces';

const messages = defineMessages({
  status: { id: 'person.attr.status' },
  sStatus: { id: 'person.attr.status.attr.status' },
  sAffiliation: { id: 'person.attr.status.attr.affiliation' },
});

interface StatusesProps {
  checkPermission: CheckPermissionFunction;
  statuses: PersonStatusDTO[];
  statusList: string;
  affiliationList: string;
  onChange: (statuses: PersonStatusDTO[]) => void;
  validation: ValidationResult;
}

function InnerStatuses({
  checkPermission,
  statuses,
  statusList,
  affiliationList,
  onChange,
  intl,
  validation
}: StatusesProps & WrappedComponentProps) {
  return (
    <div className="form form-highlight">
      <Styled.h2>{intl.formatMessage(messages.status)}</Styled.h2>
      <MultiInputControls<PersonStatusDTO>
        defaultRow={DefaultPersonStatus}
        remove
        add
        lines={statuses}
        onChange={onChange}
        renderLines={(statusObject: PersonStatusDTO, onChange2: (newValues) => void, actions: { [p: string]: (e?: any) => void }, idx: number) => {
          return (
            <div className="flex-row">
              <StatusAffiliationConnect
                checkPermission={checkPermission}
                statusObject={statusObject}
                statusList={statusList}
                affiliationList={affiliationList}
                onChange={onChange2}
                validations={validation.children[idx]}
                // TODO validation={validation?.children[idx]}
              />
              {!!actions.delete && (
                <div className='deleteButton'>
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          );
        }}
        renderEnd={(actionAdd) => (
          <div className='addButton'>
            <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
          </div>
        )}
      />
    </div>
  );
}
export const Statuses = injectIntl(InnerStatuses);

/*
 * Connects Status and Affiliation
 * The Status select-list depends on the Affiliation selected
 * E.g. Affiliation RUB has another affiliations_list (Vocabulary) then TUDO
 */
interface StatusAffiliationConnectProps {
  checkPermission: CheckPermissionFunction;
  statusObject: PersonStatusDTO;
  statusList: string;
  affiliationList: string;
  onChange: (line: any) => void;
  validations: ValidationResults;
}

function InnerStatusAffiliationConnect(props: (StatusAffiliationConnectProps & WrappedComponentProps)) {
  const { checkPermission, statusObject, statusList, affiliationList, onChange, intl, validations } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [statusListPartition, setStatusListPartition] = useState<string>('rub');

  // Check if status is available for the affiliation
  // We do this by checking if the status_list vocabulary contains the key of the selected status
  const statusByAffiliationExists = (status: string, affiliation: string): boolean => {
    try {
      // Language is set to 'de' but it really shouldnt matter cause
      // the vocuabularies of different languages should contain the same entries/keys
      getNotation(props.statusList, status, 'de', affiliation);
    } catch (e) {
      // Key is not in Vocabulary
      return false;
    }
    return true;
  };

  return (
    <>
      <SelectInputP
        permissions={checkPermission('affiliation')}
        name={intl.formatMessage(messages.sAffiliation)}
        inputName={'affiliation'}
        value={statusObject.affiliation}
        list={affiliationList}
        listPartition={''}
        validation={validations.affiliation}
        onChange={(affiliation: string) => {
          // Set the status_list partition according to the affiliation seleceted (default 'rub')
          // E.g.: affiliation = tudo: person_status_list_rub => person_status_list_tudo
          setStatusListPartition(affiliation ? affiliation : 'rub');
          // Check if the status can be kept because its part of the new person_status_list
          // If the status selected is not part of the new person_status_list we set the status to ''
          if (statusByAffiliationExists(statusObject.status, affiliation)) {
            onChange({ ...statusObject, affiliation });
          } else {
            onChange({ ...statusObject, affiliation, status: '' });
          }
        }}
        autoFocus={true}
      />
      <SelectInputP
        name={intl.formatMessage(messages.sStatus)}
        inputName={'status'}
        value={statusObject.status}
        list={statusList}
        validation={validations.status}
        listPartition={statusObject.affiliation}
        disabled={!statusObject.affiliation}
        permissions={checkPermission('status')}
        onChange={(status: string) => onChange({ ...statusObject, status })}
      />
    </>
  );
}

const StatusAffiliationConnect = injectIntl(InnerStatusAffiliationConnect);
