import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { Subscribe } from 'unstated';
import React from 'react';
import { Button } from 'theme-ui';
import { Link as LinkRouter } from "react-router-dom";
/** @jsx jsx */
import { jsx } from 'theme-ui';

interface Props {
  disabled: boolean;
  onSubmit: () => void;
}

export const SubmitButton: React.FC<Props> = ({
  children,
  disabled,
  onSubmit,
}) => {
  return (
    <Subscribe to={[OAuth2Container]}>
      {(c: OAuth2Container) => (
        <Button
          disabled={disabled}
          onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
            e.preventDefault();
            onSubmit();
          }}
        >
          {children}
        </Button>
      )}
    </Subscribe>
  );
};

export const AbortButton: React.FC = ({children}) => {
  return (
    <div sx={{
      ".button": {
        borderRadius: 0,
        mx: 1,
        backgroundColor: "primary",
        textDecoration: "none",
        color: "white",
        p: '9px',
        display: "inline-block",
        cursor: 'pointer',
      }
    }}>
      <LinkRouter
        className='button'
        to="/"
        onClick={() => {
          document.documentElement.scrollTo({
            top: 0,
            left: 0,
          });}
        }
      >
        {children}
      </LinkRouter>
    </div>
  );
};
