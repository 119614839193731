/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventPlaceDTO,
    EventPlaceDTOFromJSON,
    EventPlaceDTOFromJSONTyped,
    EventPlaceDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface EventDTO
 */
export interface EventDTO {
    /**
     * 
     * @type {number}
     * @memberof EventDTO
     */
    id?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof EventDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    eventName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventDTO
     */
    eventNumbering?: string;
    /**
     * 
     * @type {Date}
     * @memberof EventDTO
     */
    startDate?: Date;
    /**
     * 
     * @type {Array<EventPlaceDTO>}
     * @memberof EventDTO
     */
    eventPlaces?: Array<EventPlaceDTO>;
}

export function EventDTOFromJSON(json: any): EventDTO {
    return EventDTOFromJSONTyped(json, false);
}

export function EventDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'eventName': !exists(json, 'eventName') ? undefined : json['eventName'],
        'eventNumbering': !exists(json, 'eventNumbering') ? undefined : json['eventNumbering'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'eventPlaces': !exists(json, 'eventPlaces') ? undefined : ((json['eventPlaces'] as Array<any>).map(EventPlaceDTOFromJSON)),
    };
}

export function EventDTOToJSON(value?: EventDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'eventName': value.eventName,
        'eventNumbering': value.eventNumbering,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'eventPlaces': value.eventPlaces === undefined ? undefined : ((value.eventPlaces as Array<any>).map(EventPlaceDTOToJSON)),
    };
}


