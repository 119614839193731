import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { WorkDTO } from '../../../../api/core';
import { SelectInputP } from '../../../../BaseLayout/components/form/generic/Select';
import { DataModelGate } from '../../../../Auth/components';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';


const messages = defineMessages({
  formSubject: { id: 'work.attr.formSubject' },
  formSubjectHelp: { id: 'work.attr.formSubject.help' },
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: string) => void;
  entity: string;
  pubtypeGroup: string;
  validation: ValidationResult;
}

export function FormSubject({modifiedModel, pubtypeGroup, entity, updateField, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate entity={entity} field="form_subject">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <SelectInputP
        listPartition={pubtypeGroup}
        onChange={(fs: string) => updateField('formSubject', fs)}
        name={intl.formatMessage(messages.formSubject)}
        help={intl.formatMessage(messages.formSubjectHelp)}
        list={'form_subject_list'}
        inputName={'formSubject'}
        value={modifiedModel.formSubject ? modifiedModel.formSubject : ''}
        permissions={checkPermission()}
        validation={validation}
      />
    )}
  </DataModelGate>;
}
