import { Button } from '@theme-ui/components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HierarchyApi } from '../../api/core/apis/HierarchyApi';
import { Hierarchy, OrganisationDTO, ReferencedOrganisationDTO, UrlDTO } from '../../api/core/models';
import { OAuth2Gate } from '../../Auth/components';
import { EmbeddedDashboard } from '../../Dashboard/components/EmbeddedDashboard';
import { DashboardType } from '../../Dashboard/types';
import { getDetailContextUrl } from '../../data';
import { DeleteOrga } from '../../Edit/components/DeleteOrga';
import { boolFilter, editStatusFilter } from '../../Vocabulary/data';
import { DetailViewDashboardType, DetailViewProps as Props, InnerDetailViewProps as InnerProps, ViewType } from '../types';
import { renderDate, renderDateTime, renderExternalIds, renderInstitutionalIds } from '../utils';
import { DetailView } from '../components/theme';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { History } from 'history';

export const DetailViewOrgs = (props: Props): JSX.Element => {
  return (
    <Subscribe to={[OAuth2Container]}>
      {(c: OAuth2Container) => {
        const innerProps = { ...props, authorizer: c.authorizer()};
        return <InnerDetailViewOrgs { ...innerProps } />;
      }}
    </Subscribe>
  );
};

function InnerDetailViewOrgs(props: InnerProps): JSX.Element {
  const history: History = useHistory();
  const [urlReduced, setUrlReduced] = useState(true);
  const [activeDashboard, setActiveDashboard] = useState<DetailViewDashboardType>(null);
  const [deleteInit, setDeleteInit] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  // Details are open by default for roles ADMIN and EDITOR
  const [detailsOpen, setDetailsOpen] = useState(props.authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN']));
  const [hierarchy, setHierarchy] = useState<Hierarchy[]>(null);
  const apiHierarchy = new HierarchyApi();

  const { id, viewLoading, viewError } = props;

  useEffect(() => {
    setHierarchy(null);
  }, [id]);

  const view: OrganisationDTO = props.view as OrganisationDTO;
  const isLoaded: boolean = !viewLoading && (viewError === null || viewError === undefined) && (view !== null && view !== undefined);

  const toggleUrlList = (): void => {
    setUrlReduced(!urlReduced);
  };

  const fetchHierarchy = async (organisationId: number): Promise<void> => {
    const organisationHierarchy: Hierarchy[] = await apiHierarchy.getOrganisationHierarchy({ organisationId });
    setHierarchy(organisationHierarchy);
  };

  if (viewError) {
    return <>{viewError}</>;
  }

  if (isLoaded && (hierarchy === null || hierarchy === undefined)) {
    void fetchHierarchy(view.id);
  }

  let urls: UrlDTO[] = [];
  // Relations
  let relSuccessor: ReferencedOrganisationDTO[] = [];
  let relPredecessor: ReferencedOrganisationDTO[] = [];
  let relChild: ReferencedOrganisationDTO[] = [];
  let relParent: ReferencedOrganisationDTO[] = [];
  if (view) {
    relSuccessor = view.successor;
    relPredecessor = view.predecessors;
    relChild = view.childs;
    relParent = view.parents;
    view.urls = view.urls ? view.urls : [];
    if (isLoaded) {
      urls = urlReduced ? view.urls.slice().splice(0, 2) : view.urls;
    }
  }

  return (
    <>
    {deleteInit &&
    <DeleteOrga
      orgaId={props.id}
      orgaName={view.name}
      isDeleteRequest={deleteRequest}
      onAbort={() => setDeleteInit(false)}
      onSuccess={() => {
        setDeleteInit(false);
        history.push("/search/organisations");
      }}
      onError={() => {
        setDeleteInit(false);
      }}
    />
    }
    <DetailView>
      <div className="top">
        {isLoaded && (
          <>
            <article>
              <div className="head">
                <div className="right">
                  <h1 className="head-name">{`${view.name}`}</h1>
                  {!!view.otherNames && (
                    <div className="sub-title">
                      <span className="sub-title-label">
                        <FormattedMessage id="entity.attr.otherName" />:&nbsp;
                      </span>
                      {view.otherNames.map((otherNameObj, i, arr) => {
                        const divider = i < arr.length - 1 && ',';
                        return (
                          <div className='organisation-otherNames' key={i}>
                            {otherNameObj.otherName}
                            {divider}&nbsp;
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="head-meta">
                    {!!view.startDate && (
                      <>
                        <b className="head-year orga">{renderDate(view.startDate)}</b>
                        <b>&nbsp;~&nbsp;</b>
                        <b className="head-year orga">
                          {view.endDate ? <>{renderDate(view.endDate)}</> : ''}
                        </b>
                      </>
                    )}
                    <div className="externalIds">
                      <span className="head-ids">
                        <FormattedMessage id="entity.attr.externalId" />:{' '}
                      </span>
                      {renderExternalIds(view.externalIds, 'orga')}
                    </div>
                    {view.organisationInstitutionalIds && (
                      <div className="externalIds">
                        <span className="head-ids">
                          <FormattedMessage id="entity.attr.institutionalId" />:
                        </span>
                        {renderInstitutionalIds(view.organisationInstitutionalIds)}
                      </div>
                    )}
                    {(hierarchy !== null && hierarchy !== undefined) && hierarchy.length > 1 && (
                      <div className="hierarchy">
                        <ol>
                          {hierarchy
                            .sort((org1, org2) => (org1.stage > org2.stage ? -1 : 1))
                            .map((org, i, arr) => {
                              const divider = i < arr.length - 1 && ' >';
                              return view.id === org.entityId ? (
                                <li>
                                  {org.name}
                                  {divider}
                                </li>
                              ) : (
                                <li>
                                  <Link to={getDetailContextUrl(ViewType.ORGS) + '/' + org.entityId.toString(10)}>{org.name}</Link>
                                  {divider}
                                </li>
                              );
                            })}
                        </ol>
                      </div>
                    )}
                    {relParent && relParent.length > 0 && (
                      <div className="parents">
                        <span className="head-parents">
                          <FormattedMessage id="edit.organisation.parents_head" />:{' '}
                        </span>
                        {relParent.map((relation, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <div key={i}>
                              <Link to={getDetailContextUrl(ViewType.ORGS) + '/' + relation.id.toString(10)}>{relation.name}</Link>
                              {divider}&nbsp;
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {relChild && relChild.length > 0 && (
                      <div className="children">
                        <span className="head-children">
                          <FormattedMessage id="edit.organisation.childs_head" />:{' '}
                        </span>
                        {relChild.map((relation, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <div key={i}>
                              <Link to={getDetailContextUrl(ViewType.ORGS) + '/' + relation.id.toString(10)}>{relation.name}</Link>
                              {divider}&nbsp;
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {view.projects && view.projects.length > 0 && (
                      <div className="projects">
                        <span className="head-projects">
                          <FormattedMessage id="edit.organisation.projects_head" />:{' '}
                        </span>
                        {view.projects.map((relation, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <div key={i}>
                              <Link key={i} to={getDetailContextUrl(ViewType.PROJECTS) + '/' + relation.id.toString(10)}>
                                {relation.name}
                              </Link>
                              {divider}&nbsp;
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {relPredecessor && relPredecessor.length > 0 && (
                      <div className="predecessor">
                        <span className="head-predecessor">
                          <FormattedMessage id="edit.organisation.predecessors_head" />:{' '}
                        </span>
                        {relPredecessor.map((relation, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <div key={i}>
                              <Link to={getDetailContextUrl(ViewType.ORGS) + '/' + relation.id.toString(10)}>{relation.name}</Link>
                              {divider}&nbsp;
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {relSuccessor && relSuccessor.length > 0 && (
                      <div className="successor">
                        <span className="head-successor">
                          <FormattedMessage id="edit.organisation.successor_head" />:{' '}
                        </span>
                        {relSuccessor.map((relation, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <div key={i}>
                              <Link to={getDetailContextUrl(ViewType.ORGS) + '/' + relation.id.toString(10)}>{relation.name}</Link>
                              {divider}&nbsp;
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {/* TODO urls und view.urls mixed up */}
                    {!!urls && view.urls.length > 0 && (
                      <div className="websites">
                        <span className="head-websites">
                          <FormattedMessage id="work.attr.urls" />:{' '}
                        </span>
                        <ul>
                          {urls.map((url, i) => {
                            return (
                              <li key={i}>
                                <a href={url.url}>{url.url}</a>
                                {!!url.type ? ` (${url.type})` : ''}
                              </li>
                            );
                          })}
                          {view.urls.length > 2 && (
                            <li key="more" className="websites-more" onClick={toggleUrlList}>
                              {urlReduced && <a href="#"><FormattedMessage id="button.more" /></a>}
                              {!urlReduced && <a href="#"><FormattedMessage id="button.less" /></a>}
                            </li>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {(view.adminDataOrganisation.hidden !== null && view.adminDataOrganisation.hidden !== undefined) && view.adminDataOrganisation.hidden && (
                <span className="stamp is-nope">
                  <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />
                </span>
              )}
              <div className="head-toolbar">
                <Button
                  onClick={() => setDetailsOpen(!detailsOpen)}
                >
                  <FormattedMessage id="button.Details" />
                </Button>
                <div className="edit">
                  <OAuth2Gate anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}>
                    <Button
                      onClick={() => window.location.href = `/organisation/${id}/edit`}
                    >
                      <FormattedMessage id="button.Edit" />
                    </Button>
                  </OAuth2Gate>
                  <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
                    <Button
                      onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        setDeleteInit(true);
                        setDeleteRequest(false);
                      }}
                    >
                      <FormattedMessage id="button.Delete" />
                    </Button>
                  </OAuth2Gate>
                  <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
                    <Button
                      onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
                        e.preventDefault();
                        setDeleteInit(true);
                        setDeleteRequest(true);
                      }}
                    >
                      <FormattedMessage id="button.DeleteRequest" />
                    </Button>
                  </OAuth2Gate>
                </div>
              </div>

              <div className={`body ${!detailsOpen ? 'hidden' : ''}`}>
                {!!view.organisationSubjects && view.organisationSubjects.length > 0 && (
                  <div className="info-group">
                    <div className="info-group-desc">
                      <h3>
                        <FormattedMessage id="additional_information" />
                      </h3>
                    </div>
                    <div className="info-group-content">
                      {!!view.organisationSubjects && view.organisationSubjects.length > 0 && (
                        <div className="subjects">
                          <h4 className="head-subjects">
                            <FormattedMessage id="edit.organisation.subjects_head" />:{' '}
                          </h4>
                          <ol>
                            {view.organisationSubjects.map((subj, i) => {
                              return (
                                <>
                                  {!!subj.label && !!subj.subjectId && !!subj.type && (
                                    <li key={i}>
                                      <div className="subject-id">
                                        <b className="head-subject-id">
                                          <FormattedMessage id="subject.attr.id" />:{' '}
                                        </b>
                                        {subj.subjectId}
                                      </div>
                                      <div className="subject-type">
                                        <b className="head-subject-type">
                                          <FormattedMessage id="subject.attr.type" />:{' '}
                                        </b>
                                        {subj.type}
                                      </div>
                                      <div className="subject-label">
                                        <b className="head-subject-label">
                                          <FormattedMessage id="subject.attr.label" />:{' '}
                                        </b>
                                        {subj.label}
                                      </div>
                                    </li>
                                  )}
                                </>
                              );
                            })}
                          </ol>
                          <div className="clear"></div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {!!view.note && (
                  <div className="info-group">
                    <div className="info-group-desc">
                      <h3>
                        <FormattedMessage id="work.attr.note" />
                      </h3>
                    </div>
                    <div className="info-group-content">
                      <div className="note">
                        {view.note}
                        <div className="clear"></div>
                      </div>
                    </div>
                  </div>
                )}
                {!!view.adminDataOrganisation && (
                  <div className="info-group">
                    <div className="info-group-desc">
                      <h3>
                        <FormattedMessage id="edit.organisation.admin_head" />
                      </h3>
                    </div>
                    <div className="info-group-content">
                      {!!view.adminDataOrganisation.id && (
                        <div className="id">
                          <h4 className="head-id">
                            <FormattedMessage id="work.attr.adminDataWork.attr.id" />:
                          </h4>
                          {view.adminDataOrganisation.id}
                        </div>
                      )}
                      {!!view.adminDataOrganisation.origin && (
                        <div className="origin">
                          <h4 className="head-origin">
                            <FormattedMessage id="work.attr.adminDataWork.attr.origin" />:
                          </h4>
                          {view.adminDataOrganisation.origin}
                        </div>
                      )}
                      {!!view.adminDataOrganisation.changed && (
                        <div className="changed">
                          <h4 className="head-changed">
                            <FormattedMessage id="work.attr.adminDataWork.attr.changed" />:
                          </h4>
                          {renderDateTime(view.adminDataOrganisation.changed)}
                        </div>
                      )}
                      {!!view.adminDataOrganisation.created && (
                        <div className="created">
                          <h4 className="head-created">
                            <FormattedMessage id="work.attr.adminDataWork.attr.created" />:
                          </h4>
                          {renderDateTime(view.adminDataOrganisation.created)}
                        </div>
                      )}
                      {!!view.adminDataOrganisation.deskman && (
                        <div className="deskman">
                          <h4 className="head-deskman">
                            <FormattedMessage id="work.attr.adminDataWork.attr.deskman" />:
                          </h4>
                          {view.adminDataOrganisation.deskman}
                        </div>
                      )}
                      {!!view.adminDataOrganisation.editorialStatus && (
                        <div className="editorialStatus">
                          <h4 className="head-editorialStatus">
                            <FormattedMessage id="work.attr.adminDataWork.attr.editorialStatus" />:
                          </h4>
                          {editStatusFilter(view.adminDataOrganisation.editorialStatus)}
                        </div>
                      )}
                      {(view.adminDataOrganisation.hidden !== null && view.adminDataOrganisation.hidden !== undefined) && (
                        <div className="hidden">
                          <h4 className="head-hidden">
                            <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />:
                          </h4>
                          {boolFilter(view.adminDataOrganisation.hidden)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </article>
          </>
        )}
      </div>
      <div className="dashboard-tabs">
        <div className="dashboard-tabs-inner">
          <button
            className={activeDashboard === DetailViewDashboardType.WORKS ? 'tab active' : 'tab'}
            onClick={(e) => {
              e.preventDefault();
              setActiveDashboard(DetailViewDashboardType.WORKS);
            }}
          >
            <FormattedMessage id="entity.works" />
          </button>

          <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST']}>
            <button
              className={
                activeDashboard === DetailViewDashboardType.WORKS_BY_PERSONS || activeDashboard === null
                  ? 'tab active'
                  : 'tab'
              }
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.WORKS_BY_PERSONS);
              }}
            >
              <FormattedMessage id="entity.works" /> <small>(<FormattedMessage id="about_people" />)</small>
            </button>
          </OAuth2Gate>
          <OAuth2Gate anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}>
            <button
              className={activeDashboard === DetailViewDashboardType.WORKS_BY_PERSONS ? 'tab active' : 'tab'}
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.WORKS_BY_PERSONS);
              }}
            >
              <FormattedMessage id="entity.works" /> <small>(<FormattedMessage id="about_people" />)</small>
            </button>
          </OAuth2Gate>
          <button
            className={activeDashboard === DetailViewDashboardType.WORKS_BY_PERSONS_HIERARCHY ? 'tab active' : 'tab'}
            onClick={(e) => {
              e.preventDefault();
              setActiveDashboard(DetailViewDashboardType.WORKS_BY_PERSONS_HIERARCHY);
            }}
          >
            <FormattedMessage id="entity.works" /> <small>(<FormattedMessage id="about_people_including_childs" />)</small>
          </button>
          <OAuth2Gate anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}>
            <button
              className={
                activeDashboard === DetailViewDashboardType.PEOPLE || activeDashboard === null ? 'tab active' : 'tab'
              }
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.PEOPLE);
              }}
            >
              <FormattedMessage id="entity.persons" />
            </button>
          </OAuth2Gate>
          <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST']}>
            <button
              className={activeDashboard === DetailViewDashboardType.PEOPLE ? 'tab active' : 'tab'}
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.PEOPLE);
              }}
            >
              <FormattedMessage id="entity.persons" />
            </button>
          </OAuth2Gate>
          <button
            className={activeDashboard === DetailViewDashboardType.PEOPLE_INCLUDING_CHILDS ? 'tab active' : 'tab'}
            onClick={(e) => {
              e.preventDefault();
              setActiveDashboard(DetailViewDashboardType.PEOPLE_INCLUDING_CHILDS);
            }}
          >
            <FormattedMessage id="entity.persons" /> <small>(<FormattedMessage id="including_childs" />)</small>
          </button>
        </div>
      </div>
      <div className="dasboard">
        <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
          {activeDashboard === DetailViewDashboardType.PEOPLE || activeDashboard === null ? (
            <EmbeddedDashboard
              entity={DashboardType.PEOPLE}
              searchQuery={{
                q: 'orga_id:' + id.toString(10),
              }}
            />
          ) : (
            ''
          )}
        </OAuth2Gate>
        <OAuth2Gate anyRoles={['ROLE_GUEST', 'ROLE_USER']}>
          {activeDashboard === DetailViewDashboardType.PEOPLE ? (
            <EmbeddedDashboard
              entity={DashboardType.PEOPLE}
              searchQuery={{
                q: 'orga_id:' + id.toString(10),
              }}
            />
          ) : (
            ''
          )}
        </OAuth2Gate>

        {activeDashboard === DetailViewDashboardType.PEOPLE_INCLUDING_CHILDS ? (
          <EmbeddedDashboard
            entity={DashboardType.PEOPLE}
            searchQuery={{
              q: 'orga_id_recursive:' + id.toString(10),
            }}
          />
        ) : (
          ''
        )}
        {activeDashboard === DetailViewDashboardType.WORKS ? (
          <EmbeddedDashboard
            entity={DashboardType.WORKS}
            searchQuery={{
              q: 'organisation_id_belongs_to:' + id.toString(10),
            }}
          />
        ) : (
          ''
        )}

        <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
          {activeDashboard === DetailViewDashboardType.WORKS_BY_PERSONS ? (
            <EmbeddedDashboard
              entity={DashboardType.WORKS}
              searchQuery={{
                q: 'organisation_id_persons:' + id.toString(10),
              }}
            />
          ) : (
            ''
          )}
        </OAuth2Gate>
        <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST']}>
          {activeDashboard === DetailViewDashboardType.WORKS_BY_PERSONS || activeDashboard === null ? (
            <EmbeddedDashboard
              entity={DashboardType.WORKS}
              searchQuery={{
                q: 'organisation_id_persons:' + id.toString(10),
              }}
            />
          ) : (
            ''
          )}
        </OAuth2Gate>
        {activeDashboard === DetailViewDashboardType.WORKS_BY_PERSONS_HIERARCHY ? (
          <EmbeddedDashboard
            entity={DashboardType.WORKS}
            searchQuery={{
              q: 'organisation_id_recursive:' + id.toString(10),
            }}
          />
        ) : (
          ''
        )}
      </div>
    </DetailView>
    </>
  );
}
