import { EmploymentPeriodDTO } from 'api/core/models';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import React, { ReactNode } from 'react';
import { DefaultEmploymentPeriod } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { CheckPermissionFunction } from '../../../../Auth/components/DataModelGate';
import { useIntl } from 'react-intl';
import { Flex } from '@theme-ui/components';
import { ValidationResult } from '../../../validation/interfaces';
import { DatePickerStyled } from 'BaseLayout/components/form/DatePickerDateRange/DatePicker';
import { DateObject } from 'react-multi-date-picker';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';

interface Props {
  checkPermission: CheckPermissionFunction;
  employmentPeriods: EmploymentPeriodDTO[];
  onChange: (po: EmploymentPeriodDTO[]) => void;
  validation?: ValidationResult;
}
export const EmploymentPeriods: React.FC<Props> = ({ checkPermission, employmentPeriods, onChange, validation }): React.JSX.Element => {
  return (
    <MultiInputControls<EmploymentPeriodDTO>
      remove={!!checkPermission().write}
      add={!!checkPermission().write}
      defaultRow={DefaultEmploymentPeriod}
      lines={employmentPeriods}
      onChange={onChange}
      renderLines={(employmentPeriod: EmploymentPeriodDTO, onChange2: (ep: EmploymentPeriodDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactNode => (
        <div>
          <Flex variant="multiRow" sx={{gap: '4px'}}>
            <DatePickerStyled
              label={'start_date'}
              validation={validation?.children[idx]?.start_date}
              onChange={(startDate: DateObject): void =>
                onChange2({ ...employmentPeriod, startDate: startDate === null ? undefined : new Date(startDate.format("YYYY-MM-DD")) })
              }
              required={isRequired(checkPermission('employment_periods.start_date'))}
              disabled={!checkPermission('employment_periods.start_date').write}
              value={employmentPeriod.startDate ? employmentPeriod.startDate : undefined}
            />
            <DatePickerStyled
              label={'end_date'}
              validation={validation?.children[idx]?.end_date}
              onChange={(endDate: DateObject): void =>
                onChange2({ ...employmentPeriod, endDate: endDate === null ? undefined : new Date(endDate.format("YYYY-MM-DD")) })
              }
              value={employmentPeriod.endDate ? employmentPeriod.endDate : undefined}
              required={isRequired(checkPermission('employment_periods.end_date'))}
              disabled={!checkPermission('employment_periods.start_date').write}
            />
          </Flex>
          <div className='deleteButton'>
            <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
          </div>
        </div>
      )}
      renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactNode => (
        <div sx={{mt: '-0.8em'}}>
          <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
        </div>
      )}
    />
  );
};
