import * as Model from '@mms-v3/data-model-js';
import { Authorizer } from './Authorizer';
import { SessionState } from '../containers/OAuth2Container';
import { em } from 'polished';

export function checkReadPermission(field: Model.Field, roles: string[], isOwn: boolean): boolean {
  if(!field) {
    console.warn('field is null or undefined', field, roles);
    return false;
  }
  return field ? field.readPermission(roles, isOwn) : false;
}

export function checkWriteObligation(field: Model.Field, roles: string[], isOwn: boolean): false | 'OPTIONAL' | 'RECOMMENDED' | 'MANDATORY' {
  if(!field) {
    console.warn('field is null or undefined', field, roles);
    return false;
  }
  const obligation = field ? field.writeObligation(roles, isOwn) : false;
  // Replace `NONE` with `false` for simple truthyness checks (e.g. `if (writable) ...`)
  return obligation === 'NONE' ? false : obligation;
}

/**
 * Checks whether current user has owner permissions:
 * - creating a new record
 * - has a session, has an email and email ist in emails
 * @param authorizer
 * @param isNew
 * @param emails
 */
export function checkIsOwnRecord(authorizer: Authorizer, isNew?: boolean, emails?: string[]): boolean {
  // New entry current user is owner
  if (isNew) {
    return true;
  }
  // If no emails to check, user cannot be identified as owner
  if(!emails || emails.length === 0) {
    return false;
  }
  const session: SessionState = authorizer.getSession();
  // User not logged in / without session cannot be owner
  if (!session) {
    return false;
  }
  const userEmail: string = session?.user_name?.toLowerCase();
  // User without email cannot be identified as owner
  if (userEmail == null || userEmail.length === 0) {
    return false;
  }
  // User with email in emails is owner, otherwise not owner
  return emails.findIndex((e: string) => e.toLowerCase() === userEmail) !== -1;
}
