import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from 'is-what';
import { OAuth2Gate } from 'Auth/components';
import { ViewType } from 'Detail/types';
import { Notation } from 'Vocabulary/components/Notation';
import { Link } from '@theme-ui/components';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { Authorizer } from 'Auth/utils/Authorizer';
import { SearchOrg } from 'Dashboard/types';
import { idHashFilter } from './Result';
import { renderDate, renderDateTime, renderExternalIds } from 'Detail/utils';
import { getDetailContextUrl } from 'data';
import { DeleteOrga } from 'Edit/components/DeleteOrga';
import OptionList from './OptionList';

export interface OrgaResultProps {
  index: number;
  result: SearchOrg;
  updateResults: () => {};
  personOrg?: any;
}

export const OrgaResult = (props: OrgaResultProps): JSX.Element => {
  return (
    <Subscribe to={[OAuth2Container]}>{(c: OAuth2Container) => {
      const innerProps = { ...props, authorizer: c.authorizer()};
      return <InnerOrgaResult {...innerProps} />;
    }}
    </Subscribe>
  );
};
interface InnerOrgaResultProps extends OrgaResultProps {
  authorizer: Authorizer;
}

const InnerOrgaResult = (props: InnerOrgaResultProps): JSX.Element => {
  const [deleteInit, setDeleteInit] = useState(false);
  const { index, result, personOrg, authorizer } = props;

  let employPeriods;
  let employmentPeriodsLength = 0;
  const reduce = (prev: JSX.Element[], curr: JSX.Element) => [...prev, ' > ', curr];

  if (personOrg != undefined) { // employStartDate or employStartDate&employEndDate is given
    employPeriods = personOrg.filter((_personOrg) => _personOrg.organisationId == result.id)[0].employmentPeriods;
    employmentPeriodsLength = employPeriods?.length;
  }

  const externalIds = renderExternalIds(
    isNullOrUndefined(result.gnd) ? [] : result.gnd.map((e) => ({ externalId: e, type: 'gnd' })),
    'orga'
  );

  const hierarchy = result.hierarchy ? result.hierarchy.slice(1) : [];
  hierarchy.reverse();
  const hierarchyEntries = hierarchy
    .map((e) => idHashFilter(e))
    .map((e) =>
      undefined !== e.id ? (
        <Link key={e.id} href={getDetailContextUrl(ViewType.ORGS, e.id)}>
          {e.name}
        </Link>
      ) : (
        <span key={e.name}>{e.name}</span>
      )
    )
    .reduce(reduce, [])
    .slice(1);

  const options = [];
  if (authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN'])) {
    options.push(
      <li key="edit">
        <Link href={`/organisation/${props.result.id}/edit`}>
          <FormattedMessage id="button.Edit" />
        </Link>
      </li>
    );
    options.push(
      <li key="delete">
        <a
          href="#"
          onClick={async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setDeleteInit(true);
          }}
        >
          {authorizer.hasRole('ROLE_ADMIN') &&
          <FormattedMessage id="button.Delete" />
          }
          {authorizer.hasRole('ROLE_EDITOR') &&
          <FormattedMessage id="button.DeleteRequest" />
          }
        </a>
      </li>
    );
  }

  return (
    <>
      {deleteInit && (
      <DeleteOrga
        orgaId={parseInt(props.result.id, 10)}
        orgaName={props.result.name.join('; ')}
        isDeleteRequest={!authorizer.hasRole('ROLE_ADMIN')}
        onAbort={() => setDeleteInit(false)}
        onSuccess={() => {
          setDeleteInit(false);
          props.updateResults();
        }}
        onError={() => {
          setDeleteInit(false);
        }}
      />
      )}
      <div className="result" key={result.id}>
        <div className="content">
          <div className="info main">
            <div className="main-title">
              <Link href={getDetailContextUrl(ViewType.ORGS, result.id)}>
                {result.name ? result.name.join('; ') : <i>Keine Angabe</i>}
              </Link>
            </div>
            {externalIds}
            {window.location.href.includes('person') && (employPeriods != undefined) && (
              <div className="employment-periods">
                <div className='employPeriod-label'><FormattedMessage id="employment_periods"/>:&nbsp;&nbsp;</div>
                <div className='allEmployPeriods'>
                  {employPeriods.map(period => (
                    <div key={period.id} className="employment-periods-data">
                        {renderDate(period.startDate)}&nbsp;~&nbsp;{(period.endDate != undefined) && (<>{renderDate(period.endDate)}</>)}
                        {(employPeriods[employmentPeriodsLength-1] != period) && (<>,&nbsp;&nbsp;</>)}
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="hierarchy">{hierarchyEntries}</div>
          </div>
          <div className="info right">
            <div className="record status">
              <FormattedMessage id="entity.attr.editorialStatus" />:{' '}
              <Notation v="editorial_status_list" k={result.editorialStatus} />
            </div>
            <div className="record creation">
              <FormattedMessage id="entity.attr.recordCreationDate" />: {renderDateTime(result.recordCreationDate)}
            </div>
            <div className="record change">
              <FormattedMessage id="entity.attr.recordChangeDate" />: {renderDateTime(result.recordChangeDate)}
            </div>
          </div>
        </div>
        <div className="actions">
          <span className="index">#{index + 1}</span>
          <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
            <OptionList id={result.id} options={options} updateResults={props.updateResults} />
          </OAuth2Gate>
        </div>
      </div>
    </>
  );
};

export default OrgaResult;
