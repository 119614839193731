import React from "react";
import {Grid} from "theme-ui";

/** @jsx jsx */
import { jsx } from 'theme-ui';

export const DetailEditGrid: React.FC = ({children})=> {
  return <Grid sx={{
      maxWidth: "1000px",
      display: "flex",
      flexWrap: "wrap",
      margin: "0 auto",
      justifyContent: "space-between",
      backgroundColor: "card_bg",
      padding: "1em",
      marginTop: "1em",
      borderRadius: "0.25em",
      border: "solid 1px #e2e2e2",
      ".col": {
        "h2, h3, h4, h5, h6": {
          textAlign: "center",
          marginBottom: "1em",
          marginTop: "0.5em"
        },
        width: "45%"
      },
      ".addButton": {mt: '-1em', mb: '1em'},
      ".deleteButton": {mt: '0.25em', mb: '1em'},
      ".fieldRow": {gap: '4px'},
      ".buttons": {display: 'inline-flex'}
    }}>{children}</Grid>;
};

export const Border: React.FC = ({children}) => {
  return <div sx={{
      border: "1px solid",
      borderColor: "light",
      px: 6,
      py: 5,
      mt: "0.25em",
      ".fieldRow": {
        display: 'inline-flex',
        gridGap: 5,
        width: '100%',
        mb: '0.5em',
        mt: '-0.5em',
        ".deleteButton": {mt: '1.7em'},
        ".controls": {mt: '1.6em'},
        ".thisWork": {mt: '1.6em', color: "form_label", fontWeight: "bold", paddingRight: "1em"}
      },
      ".fieldRowPlace": {
        display: 'inline-flex',
        gridGap: 5,
        width: '60%',
        mb: '0.5em',
        mt: '-0.5em',
        ".deleteButton": {mt: '1.7em'},
        ".controls": {mt: '1.6em'}
      },
      ".addButton": {mt: '-16px'},
      h3: {mt:'-0.3em', mb:'0.25em'},
      ".innerBorder": {mt: '-0.5em'}
    }}> {children} </div>;
};

export const BorderGeo: React.FC = ({children}) => {
  return <div sx={{
      border: "1px solid",
      borderColor: "light",
      px: 7,
      py: 6,
      mt: "0.25em",
      ".deleteButton": {mt: '1.7em'},
    }}> {children} </div>;
};

export const FieldRow: React.FC = ({children}) => {
  return <div sx={{
      display: 'inline-flex',
      gridGap: 5,
      width: '100%',
      mb: '0.5em',
      mt: '-0.5em',
      ".saveButton": {mt: '1.6em'},
      ".controls": {mt: '1.6em'},
    }}> {children} </div>;
};

