import * as React from 'react';
import { useCallback, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import Dropzone, { FileRejection } from 'react-dropzone';
import { SubmissionServiceApi } from 'api/submission';
import { connectOAuth2, OAuth2AwareProps } from 'Auth/utils/connect';
import { Main } from '../../BaseLayout/components/base-layout/main';
import { humanFileSize } from '../utils';
import { Link as LinkRouter } from 'react-router-dom';
/** @jsx jsx */
import { jsx, Button } from 'theme-ui';
import { useAlert, types } from 'react-alert';
import { VoidApiResponse } from 'api/core';
import upload from '../../BaseLayout/assets/img/misc/upload.png';

const maxFileSize = 16777216; // 16 MB

export interface Props { }

type InnerProps = OAuth2AwareProps<Props>;

export const InnerPubListUpload: React.FC<InnerProps> = (props) => {
  const [file, setFile] = useState(null);
  const api = props.apiWithAuth(new SubmissionServiceApi());
  const intl: IntlShape = useIntl();
  const alert = useAlert();

  const uploadFile = () => {
    api.uploadFileRaw({ file })
      .then((response: VoidApiResponse) => {
        alert.show(
          response.raw.status === 201 ? intl.formatMessage({ id: 'pubdata.upload.success' }) : intl.formatMessage({ id: 'pubdata.upload.error' }),
          {
            type: response.raw.status === 201 ? types.SUCCESS : types.ERROR,
          }
        );
        setFile(null);
      })
      .catch(e => console.log(e));
  };

  const onDrop = useCallback((acceptedFiles, fileRejections: FileRejection[]) => {
    fileRejections.forEach(fileRej => {
      fileRej.errors.forEach(err => {
        if (err.code === "file-too-large") {
          alert.error(intl.formatMessage({ id: 'pubdata.upload.error.maxFileSize' }) + humanFileSize(maxFileSize));
        }
      });
    });

    if (acceptedFiles.length > 0) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  return (
    <Main>
      <article sx={{
        pt: 8,
        px: 8,
      }}>
        <div sx={{ mb: '24px' }}>
          <i className="icon-links_aktiv" sx={{ fontSize: '9px', mr: '5px' }} />
          <LinkRouter sx={{color: "primary", fontWeight: '600'}} to="/works/add">
            <FormattedMessage id="work.upload.button.back" />
          </LinkRouter>
        </div>
        <h1 sx={{ mb: '20px', color: 'primary', fontWeight: 'bold', fontSize: '2em' }}>
          <FormattedMessage id="work.upload.headline" />
        </h1>
        <p>
          <FormattedHTMLMessage id="work.upload.description" />
        </p>

        <Dropzone
          multiple={false}
          maxSize={maxFileSize} // Maximum file size (in bytes)
          onDrop={(acceptedFiles, fileRejections) => onDrop(acceptedFiles, fileRejections)}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <section sx={{ mt: 6, mb: '30px' }}>
              <div {...getRootProps({
                sx: {
                  textAlign: "center",
                  display: "inline-block",
                  width: "33em",
                  height: "16em",
                  borderRadius: "8px",
                  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' 
                    xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2317365CFF' 
                    stroke-width='4' stroke-dasharray='15%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
                }
              })}>
                <input {...getInputProps()} />
                {file ? (
                  <div className="selected-file">
                    {file.name} ({humanFileSize(file.size)})
                  </div>
                ) :
                  isDragActive ? (
                    <FormattedMessage id="work.upload.dropzone.placeholder_drag" />
                  ) : (
                    <div sx={{
                      height: '100%',
                      '.hero': {
                        position: 'relative',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                      '.hero:before': {
                        content: '""',
                        backgroundImage: `url(${upload})`,
                        height: '100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '6em',
                        backgroundPosition: 'bottom',
                        backgroundPositionY: '48px',
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        bottom: '0px',
                        left: '0px',
                        opacity: '0.45',
                      }
                    }}>
                      <div className='hero'>
                        <div sx={{
                          px: '2em',
                          fontStyle: 'italic'
                        }}>
                          <FormattedMessage id="work.upload.dropzone.placeholder" />
                          <br />
                          <div sx={{ color: 'primary', fontWeight: 'bolder' }}>
                            (<FormattedMessage id="work.upload.dropzone.max_file_size" values={{ size: "16 MB" }} />)
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </section>
          )}
        </Dropzone>

        <Button
          disabled={!file}
          onClick={uploadFile}>
          <FormattedMessage id="work.upload.button" />
        </Button>
      </article>
    </Main>
  );
};

export const PubListUpload = connectOAuth2(InnerPubListUpload);

export default PubListUpload;
