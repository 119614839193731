import * as React from 'react';
import { FormattedNumber as FN } from 'react-intl';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface Props {
  className?: string;
  value: boolean | undefined;
  onChange?: (value?: boolean) => void;
  reverse?: any;
  tristate_number?: {pos: number; neg: number};
}


const triWidth = 135; // Set to value divisible by 3
const triHeight = 18;
const toggleWidth = triHeight - 4;

const fromLeft = (pos: number) => {
  return ((2 - pos) / 2) * (triWidth - 2 - toggleWidth);
};
function TriCheckBox (props: Props): React.JSX.Element {
  const {className, value, onChange, tristate_number} = props;

  const nextValue = (): void => {
    if (onChange) {
      const current: boolean = value;
      const next: boolean = current === true ? false : current === false ? undefined : true;
      onChange(next);
    }
  };

  const c: string[] = [className];
  if (value === true) {
    c.push('state-true');
  } else if (value === false) {
    c.push('state-false');
  } else {
    c.push('state-dc');
  }
  return (
    <div className={c.join(' ')} sx={{
      height: '22px',
      width: '135px',
      display: "flex",
      position: "relative",
      cursor: "pointer",
      margin: 0,
      transition: ".5s",
      alignItems: "stretch",
      flexDirection: "row-reverse",
      ".toggle": {
        // content: '" "';
        zIndex: 9,
        display: "block",
        width: '17px',
        height: '17px',
        mt: '2.5px',
        position: "absolute",
        borderRadius: "50%",
        transition: "margin 1s, .5s",
      },
      ".true, .false": {
        width: '68px',
        display: "flex",
        fontSize: '10.5px',
        justifyContent: "center",
        alignItems: "center",
      },

      ".false": {
        backgroundColor: '#a92e41',
        borderTopRightRadius: '9px',
        borderBottomRightRadius: '9px',
        color: 'white'
      },

      ".true": {
        backgroundColor: '#a6ce39',
        borderTopLeftRadius: '9px',
        borderBottomLeftRadius: '9px'
      },

      ".dc":{
        left: '15px',
        backgroundColor: '#dadada',
        width: '32px',
        display: "flex",
        fontSize: '10.5px',
        justifyContent: "center",
        alignItems: "center"
      },
      "&.state-true .toggle": {
        backgroundColor: 'white',
        left: `${fromLeft(1.65)}px`
      },
      "&.state-false .toggle": {
        backgroundColor: 'white',
        left: `${fromLeft(0.35)}px`
      },
      "&.state-dc .toggle": {
        backgroundColor: "white",
        left: `${fromLeft(1.005)}px`
      },
      ".number-neg": {
        ml: '-1px',
        fontSize: '13px'
      },
      ".number-pos": {
        mr: '-1px',
        fontSize: '13px'
      }
    }}>
      <span className="toggle" onClick={nextValue}></span>
      <div
        className="false"
        onClick={() => {
          if (onChange) {
            onChange(false);
          }
        }}
      >
        <div className='number-neg'>
          {(((value === true) || (value === undefined)) && tristate_number) && (
            <FN value={tristate_number?.neg} />
          )}
        </div>
      </div>
      <div
        className="dc"
        onClick={() => {
          if (onChange) {
            onChange(undefined);
          }
        }}
      ></div>
      <div
        className="true"
        onClick={() => {
          if (onChange) {
            onChange(true);
          }
        }}
      >
        <div className='number-pos'>
          {(((value === false) || (value === undefined)) && tristate_number) && (
            <FN value={tristate_number?.pos} />
          )}
        </div>
      </div>
    </div>
  );
}

export default TriCheckBox;
