import * as React from 'react';
/** @jsx jsx */
import { getMandantFromDomain, Mandant } from "OrcidAdminArea/utils";
import { PropsWithChildren, useContext } from "react";
import { jsx, useColorMode } from "theme-ui";
import { ThemeContext } from "./components";

export function injectTheme(Component) {
  const InjectedTheme = (props) =>{
    const [theme] = useColorMode();
    return <Component {...props} theme={theme} />;
  };
  return InjectedTheme;
}

export const ThemeGate: React.FC<PropsWithChildren<{themes: string[]}>> = (props) => {
  const theme = useContext(ThemeContext);

  if (props.themes.includes(theme)) {
      return <>{props.children}</>;
  }
  return <></>;
};

export const themeNames = ['base', 'contrast', 'rub', 'tudo', 'hhu', 'uwh'] as const;
export type ThemeName = typeof themeNames[number];

export function getThemeName(themeName: string, defaultTheme: ThemeName = null): ThemeName {
  if (!!themeName && -1 !== themeNames.findIndex((n: ThemeName): boolean => n === themeName)) {
    return themeName as ThemeName;
  }
  return defaultTheme;
}

export function getInstitutionByUsername(username: string): Mandant {
  let institution: Mandant;
  const at: number = !!username ? username.indexOf('@') : -1;
  if (at >= 0) {
    const domain: string = username.substr(at + 1);
    institution = getThemeNameForDomain(domain);
  }
  // default to rub
  return institution ?? 'rub';
}

export function isOrcidInstitution(institution: Mandant): boolean {
  switch (institution) {
    case 'rub':
    case 'tudo':
    case 'uwh':
      return true;
    case 'hhu':
      return false;
    default:
      return false;
  }
}

export function getThemeNameForDomain(domain: string): Mandant {
  return getMandantFromDomain(domain);
}
