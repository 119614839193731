
import * as React from 'react';
/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';

export const Pagination: React.FC<{className?: string}> = ({children, className})=>{
    return <Flex className={className} sx={{
        textAlign: 'center',
        "&.empty": {
          visibility: "hidden"
        },
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        ">.pages, >.pages-facet": {
          alignSelf: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
          "&.pages-mainview": {
            button: {
              fontSize: "1em !important"
            }
          },
          ">span": {
            margin: "0 0.1em",
            letterSpacing: "0.2ch"
          },
          ">button": {
            p: "0.2em",
            color: 'darker',
            cursor: "pointer",
            margin: "0 0.1em",
            border: "none",
            backgroundColor: "transparent",
            fontSize: "0.95em",
            "&.current": {
              textDecoration: 'underline',
              color: 'primary',
              fontWeight: 'bold'
            }
          },
        },
        ">.pages-facet": {
          mx: 'auto',
          pb: '1em',
          pt: '0.5em'
        },
        ">.goto": {
          color: 'darker',
          fontWeight: 'bold',
          flexShrink: 0,
          input: {
            ml: "1em",
            width: "20ch",
            height: '2.5em'
          },
          "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            m: 0,
          },
          "input[type='number']": {
            "-moz-appearance": "textfield"
          }
        }
    }}>
        {children}
    </Flex>;
};