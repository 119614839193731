import React, { ReactElement, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { PublisherDTO, WorkPublisherDTO, WorkPublisherPlaceDTO } from 'api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { PublisherSuggest } from '../../../auto-complete/PublisherSuggest';
import { CreatePublisherPopup } from './CreatePublisherPopup';
import { DefaultWorkPublisher, DefaultWorkPublisherPlace } from '../../../../data/emptyModels';
import { AddButton, DeleteButton } from 'BaseLayout/components/form/MultiInput/Buttons';
import { Button } from '@theme-ui/components';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { ValidationResult, ValidationResults } from '../../../../validation/interfaces';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { Authorizer } from 'Auth/utils/Authorizer';
import { InputLabel } from 'BaseLayout/components/form/generic/InputLabel';


const messages = defineMessages({
  name: { id: 'work.attr.publisher.attr.name' },
  nameHelp: { id: 'work.attr.publisher.attr.name.help' },
  places: { id: 'work.attr.publisher.attr.places' },
  placesHelp: { id: 'work.attr.publisher.attr.places.help' },
  place: { id: 'work.attr.publisher.attr.place' },
  create: {id: 'work.attr.publisher.attr.create'},
  placePlaceholder: { id: 'work.attr.publisher.attr.place.placeholder'}
});

interface InnerWorkPublishersProps {
  workPublishers: WorkPublisherDTO[];
  onChange: (workPublishers: WorkPublisherDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
  innerProps: {authorizer: Authorizer};
}


export function WorkPublishers({
  workPublishers,
  onChange,
  checkPermission,
  validation,
  innerProps
}: InnerWorkPublishersProps): ReactElement {
  const [publisherDefault, setPublisherDefault] = useState<boolean>(window.location.href.includes('add') && innerProps.authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN']) ? true : false);

  if ((workPublishers.length === 0) && publisherDefault) {
    workPublishers.push({
      publisher: null,
      workPublisherPlace: [],
    });
  }

  useEffect(()=>{
    setPublisherDefault(false);
  },[]);

  return (
    <Border>
      <Styled.h3>
        <FormattedMessage id="work.attr.publishers" />
      </Styled.h3>
      <MultiInputControls<any>
        lines={workPublishers ? workPublishers : []}
        onChange={(pps: WorkPublisherDTO[]): void => {
          onChange(pps);
        }}
        remove
        add
        defaultRow={DefaultWorkPublisher}
        renderLines={(
          pdi: WorkPublisherDTO,
          onChange2: (data: WorkPublisherPlaceDTO) => void,
          actions: {[p: string]: (e: any) => void},
          idx: number
        ): ReactElement => {
          return <div sx={{mt: '-0.25em', mb: '1.25em'}}>
              <WorkPublisherRow
                idx={idx}
                onChange={onChange2}
                actions={actions}
                pdi={pdi}
                checkPermission={checkPermission}
                validations={validation?.children[idx]}
                publisherDefault={publisherDefault}
              />
            </div>;
        }}
        renderEnd={(actionAdd: (e: any) => void|undefined) => (
          <div className="addButton">
            <AddButton onClick={(e): void => {
              e.preventDefault();
              workPublishers.push({
                publisher: null,
                workPublisherPlace: [],
              });
              onChange(workPublishers);
            }} />
          </div>
        )}
      />
    </Border>
  );
}


interface WorkPublisherProps {
  idx: number;
  pdi: WorkPublisherDTO;
  onChange: (data: WorkPublisherDTO) => void;
  actions: {[p: string]: (e: any) => void};
  checkPermission: CheckPermissionFunction;
  validations: ValidationResults;
  publisherDefault: boolean;
}

function WorkPublisherRow({idx, pdi, actions, onChange, checkPermission, validations, publisherDefault}: WorkPublisherProps): ReactElement {
  const [popup, setPopup]= useState(false);
  const intl: IntlShape = useIntl();
  return <Subscribe to={[OAuth2Container]}>
    {(c: OAuth2Container) => {
      const innerProps = { authorizer: c.authorizer()};
      return ( <div key={idx}>
        {checkPermission('publisher').read && (
          <>
            {popup && <CreatePublisherPopup
              onClose={ (): void => { setPopup(false); }}
              onCreated={(publisher: PublisherDTO): void => {
                pdi.publisher = {
                  id: publisher.id,
                  name: publisher.name,
                };
                onChange(pdi);
              }}
            />}
            <PublisherSuggest
              name={intl.formatMessage(messages.name)}
              help={intl.formatMessage(messages.nameHelp)}
              validation={validations?.publisher}
              single={true}
              canDelete={false}
              tags={pdi.publisher ? [pdi.publisher] : []}
              onChange={(referencedPublishers: PublisherDTO[]): void => {
                if(referencedPublishers.length > 0) {
                  pdi.publisher = {
                    id: referencedPublishers[0].id,
                    name: referencedPublishers[0].name,
                  };
                }
                else {
                  pdi.publisher = null;
                }
                onChange(pdi);
              }}
              disabled={!checkPermission('publisher').write}
              autoFocus={publisherDefault ? false : true}
            />
            {!popup && (
              <Button
                sx={{
                  backgroundColor: '#fff',
                  color: 'primary',
                  border: '1.5px',
                  borderColor: 'light',
                  borderStyle: 'solid',
                  p: 4,
                  mt: '0.25em',
                  mb: '0.7em',
                  fontWeight: 'bold',
                  fontSize: '0.85em'
                }}
                onClick={(e): void => {
                  e.preventDefault();
                  setPopup(!!checkPermission('publisher').write);
                }}
                disabled={!checkPermission('publisher').write}
              >
                {intl.formatMessage(messages.create)}
              </Button>
            )}
          </>
        )}

        {checkPermission('work_publisher_place').read && (
          <WorkPublisherPlaces
            innerProps={innerProps}
            workPublisherPlaces={pdi.workPublisherPlace ? pdi.workPublisherPlace : []}
            checkPermission={checkPermission}
            validation={validations?.work_publisher_place}
            onChange={(workPublisherPlace: WorkPublisherPlaceDTO[]): void => {
                pdi = { ...pdi, workPublisherPlace };
                onChange(pdi);
              }
            }
          />
        )}
        {!!actions.delete && (
          <div sx={{mt: '0.25em'}}>
            <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
          </div>
        )}
      </div>);
    }}
  </Subscribe>;
}

interface WorkPublisherPlacesProps {
  workPublisherPlaces: any[];
  onChange: (workPublisherPlaces: WorkPublisherPlaceDTO[]) => void;
  disabled?: boolean;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
  innerProps: {authorizer: Authorizer};
}

function WorkPublisherPlaces({
  workPublisherPlaces,
  onChange,
  checkPermission,
  validation,
  innerProps
}: WorkPublisherPlacesProps): ReactElement {
  const intl: IntlShape = useIntl();
  const [publisherPlaceDefault, setPublisherPlaceDefault] = useState<boolean>(window.location.href.includes('add') && innerProps.authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN']) ? true : false);

  if ((workPublisherPlaces.length === 0) && publisherPlaceDefault){
    workPublisherPlaces.push({place: undefined});
  }
  useEffect(()=>{
    setPublisherPlaceDefault(false);
  },[]);

  return (
    <Border>
      <InputLabel
        classes={["work-publisher-place"]}
        variant="labelY"
        label={intl.formatMessage(messages.places)}
        help={intl.formatMessage(messages.placesHelp)}
      />
      <MultiInputControls<WorkPublisherPlaceDTO>
        lines={workPublisherPlaces}
        onChange={onChange}
        add
        remove
        defaultRow={DefaultWorkPublisherPlace}
        renderLines={(
          workPublisherPlace: WorkPublisherPlaceDTO,
          onChange2: (place: WorkPublisherPlaceDTO) => void,
          actions: { [p: string]: (e: any) => void },
          idx: number
        ): ReactElement => (
          <div className="innerBorder">
            <div className="fieldRowPlace" key={idx}>
              <TextInputP
                placeholder={intl.formatMessage(messages.placePlaceholder)}
                value={workPublisherPlace && workPublisherPlace.place ? workPublisherPlace.place : ''}
                onChange={(place: string): void => onChange2({ place })}
                name={intl.formatMessage(messages.place)}
                permissions={checkPermission('work_publisher_place.place')}
                validation={validation?.children[idx]?.place}
                autoFocus={publisherPlaceDefault ? false : true}
              />
              {!!actions.delete && (
                <div className='deleteButton'>
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission('work_publisher_place').write} />
                </div>
              )}
            </div>
          </div>
        )}
        renderEnd={(actionAdd: (e: any) => void | undefined): ReactElement => (
          <div className="addButton">
            <AddButton onClick={actionAdd} disabled={!checkPermission('work_publisher_place').write}/>
          </div>
        )}
      />
    </Border>

  );
}
