import * as React from 'react';
import { Button, Popup } from '../popup/Popup';
import { IntlShape, useIntl } from 'react-intl';

const orcidResultType = ['success', 'error'] as const;
export type OrcidResultType = typeof orcidResultType[number];

const orcidMethod = ['push', 'pull'] as const;
export type OrcidMethod = typeof orcidMethod[number];

export interface OrcidResult {
  type: OrcidResultType;
  method?: OrcidMethod;
  message?: string;
}

export interface Props {
  onClose: () => void;
  result: OrcidResult;
}

export const OrcidPopup: React.FC<Props> = (props: Props) => {
  const intl: IntlShape = useIntl();
  const buttons: Button[] = [{
    label: intl.formatMessage({ id: "popup.button.confirm"}),
    onClick: props.onClose
  }];
  switch (true) {
    case (props.result.type === 'success' && props.result.method === 'pull'):
      return (
        <Popup buttons={buttons} open title={intl.formatMessage({ id: "popup.orcid.pull.success.title"})}>
          <p>{intl.formatMessage({id: "popup.orcid.pull.success.message"})}</p>
          {props.result.message && <p>{props.result.message}</p>}
        </Popup>
      );
    case (props.result.type === 'error' && props.result.method === 'pull'):
      return (
        <Popup  buttons={buttons} open title={intl.formatMessage({ id: "popup.orcid.pull.error.title"})}>
          <p>{intl.formatMessage({id: "popup.orcid.pull.error.message"})}</p>
          {props.result.message && <p>{props.result.message}</p>}
        </Popup>
      );
    case (props.result.type === 'success' && props.result.method === 'push'):
      return (
        <Popup buttons={buttons} open title={intl.formatMessage({ id: "popup.orcid.push.success.title"})}>
          <p>{intl.formatMessage({id: "popup.orcid.push.success.message"})}</p>
          {props.result.message && <p>{props.result.message}</p>}
        </Popup>
      );
    case (props.result.type === 'error' && props.result.method === 'push'):
      return (
        <Popup  buttons={buttons} open title={intl.formatMessage({ id: "popup.orcid.push.error.title"})}>
          <p>{intl.formatMessage({id: "popup.orcid.push.error.message"})}</p>
          {props.result.message && <p>{props.result.message}</p>}
        </Popup>
      );
    default:
      return <></>;
  }
};
