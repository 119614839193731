import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { UWHHomepageMobile } from './UWHMobile';
import { UWHHomepageDesktop } from './UWHDesktop';
import { Props } from 'HomePage/pages/Homepage';

export const UWHHomepage = (props: Props): React.JSX.Element => {
  return props.breakpoint <= 2 ? (
    <React.Fragment>
      <UWHHomepageMobile props={props} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <UWHHomepageDesktop props={props} />
    </React.Fragment>
  );
};
