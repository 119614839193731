import jwt_decode from 'jwt-decode';
import jsrsasign from 'jsrsasign';
import queryString from 'query-string';
import { Container } from 'unstated';
import getLogger from '../../log';
import { Authorizer } from '../utils/Authorizer';

const logger = getLogger('OAuth2/Container');

export interface OAuth2Config {
  authUrl: string;
  tokenUrl: string;
  clientId: string;
  clientSecret: string;
  flowType: 'code'; // Only supports auth code flow for now

  logoutUrl?: string;
}

export interface FlowState {
  state: string;
}

export interface TokenType {
  token: string;
  expires: number;
}
export interface TokenState {
  auth?: TokenType;
  refresh?: TokenType;
}

export interface SessionState {
  user_id: number;
  user_name: string;
  user_givenname: string;
  user_surname: string;
  user_displayname: string;
  roles: string[];
}

export interface OAuth2State {
  flow: FlowState;
  token: TokenState | undefined;
  session: SessionState | undefined;
}

export interface ResponseTokens {
  access_token?: string;
  token_type?: string;
  refresh_token?: string
}

const initialFlowState: FlowState = { state: null };
const initialTokenState: TokenState = { auth: null, refresh: null };
const initialSessionState: SessionState = null;

const initialState: OAuth2State = {
  flow: initialFlowState,
  token: initialTokenState,
  session: initialSessionState,
};

interface JWTAuthToken {
  exp: number;
  user_id: number;
  user_name: string;
  user_givenname: string;
  user_surname: string;
  authorities: string[];
  jti: string;
  client_id: string;
  scope: string[];
}

interface JWTRefreshToken {
  exp: number;
}

export class OAuth2Container extends Container<OAuth2State> {
  private config: OAuth2Config;

  public constructor(config: OAuth2Config) {
    super();
    this.config = config;
    this.state = initialState;
    this.state.flow.state = this.generateOAuth2State();
  }

  public async hydrate(data: OAuth2State): Promise<void> {
    const auth = data.token?.auth;
    const refresh = data.token?.refresh;
    const now = new Date().getTime();

    const token = {
      auth: auth != null && auth.expires >= now ? auth : null,
      // refresh: refresh != null && refresh.expires >= now ? refresh : null,
      refresh
    };
    const state = {
      flow: data.flow || initialFlowState,
      token,
      session: data.session || initialSessionState,
    };
    await this.setState(state);
  }

  public async setNewFlowState(): Promise<void> {
    const state = this.generateOAuth2State();
    await this.setState({ flow: { state } });
  }

  public async getTokenWithCode(authCode: string, authState: string): Promise<void> {
    logger.debug('getTokenWithCode()');
    if (authState !== this.state.flow.state) {
      throw new Error(`Invalid auth state: ${authState} !== ${this.state.flow.state}`);
    }
    await this.setNewFlowState();

    const request = {
      grant_type: 'authorization_code',
      code: authCode,
      client_id: this.config.clientId,
      client_secret: this.config.clientSecret,
      redirect_uri: window.location.protocol + '//' + window.location.host +  '/oauth',
    };

    try {
      const response = await fetch(this.config.tokenUrl, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: queryString.stringify(request),
      });

      if (!response.ok) {
        throw new Error(`Request error (${response.status}): ${response.statusText}`);
      }

      const data: ResponseTokens = await response.json();

      if (data.access_token === undefined || data.token_type?.toLowerCase() !== 'bearer') {
        throw new Error('Malformed token response');
      }

      const authToken: string = String(data.access_token);
      const refreshToken: string = data.refresh_token !== undefined ? String(data.refresh_token) : undefined;

      await this.installToken(authToken, refreshToken);
    } catch (ex) {
      logger.error('Error while getting auth token', ex);
      throw ex;
    }
  }

  public async installToken(authToken: string, refreshToken?: string): Promise<void> {
    logger.log('Install token...');
    const {
      expiration,
      user_id,
      user_name,
      user_givenname,
      user_surname,
      user_displayname,
      roles,
    } = this.parseAuthToken(authToken);

    const authTokenExpires = expiration.getTime();

    // Change RR: const refreshTokenExpires = refreshToken != null ? this.parseRefreshToken(refreshToken).expiration.getTime() : -1;
    // Expires information not included any longer in refresh token

    const now: number = new Date().getTime();
    const token: {auth: { token: string; expires: any; }, refresh: { token: string; expires: any; }} = {
      auth:
        authTokenExpires < now
          ? null
          : {
              token: authToken,
              expires: authTokenExpires,
            },
      refresh: {
        token: refreshToken,
        expires: null
      }
        // refreshTokenExpires < now
        //  ? null
        //  : {
        //      token: refreshToken,
        //      expires: refreshTokenExpires,
        //    },
    };

    const session = {
      user_id,
      user_name,
      user_givenname,
      user_surname,
      user_displayname,
      roles,
    };

    await this.removeToken();
    await this.setState({
      token,
      session,
      flow: { state: this.generateOAuth2State() },
    });
    logger.log('...installed');
  }


  private refreshPromise: Promise<boolean> = null;

  /** Refresh the access_token. If a refresh is already in flight, piggyback that */
  public async refreshToken(): Promise<boolean> {
    if (this.refreshPromise === null) {
      try {
        this.refreshPromise = this._doRefreshToken();
        return await this.refreshPromise;
      } finally {
        this.refreshPromise = null;
      }
    } else {
      return await this.refreshPromise;
    }
  }

  /** Never call this method directly. Use `refreshToken` instead. */
  private async _doRefreshToken(): Promise<boolean> {
    if (this.state.token == null || this.state.token.refresh == null) {
      return false;
    }

    const { token } = this.state.token.refresh;
    // if (token == null || expires < new Date().getTime()) {
    if (token == null) { // Change RR: We do not have expires information anymore on refresh token
      // Refresh token expired/unavailable, remove token
      // await this.removeToken();
      return false;
    }

    // TODO RR: We do not have expires information anymore on refresh token.
    // Perform refresh token call but consider negative response due to refresh token expiration
    logger.log('Refresh token ...');
    const request = {
      grant_type: 'refresh_token',
      refresh_token: token,
      client_id: this.config.clientId,
      client_secret: this.config.clientSecret,
    };

    try {
      const response = await fetch(this.config.tokenUrl, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: queryString.stringify(request),
      });

      if (!response.ok) {
        await this.logoutOnAuthServer();
        throw new Error(`Request error (${response.status}): ${response.statusText}`);
      }

      const data: ResponseTokens = await response.json();

      if (data.access_token === undefined || data.token_type?.toLowerCase() !== 'bearer') {
        throw new Error('Malformed token response');
      }

      const newAuthToken: string = String(data.access_token);
      const newRefreshToken: string = data.refresh_token !== undefined ? String(data.refresh_token) : token;

      await this.installToken(newAuthToken, newRefreshToken);
      logger.log('... refreshed');
      return true;
    } catch (ex) {
      logger.error('Error while getting auth token from refresh token', ex);
      await this.removeToken();
      throw ex;
    }
  }

  public async removeToken(): Promise<void> {
    logger.log('Remove token ...');
    // Remove the auth token and clear the session
    await this.setState({
      token: initialTokenState,
      session: initialSessionState,
    });
    logger.log('... removed');
  }

  public async logoutOnAuthServer() {
    const url = this.config.logoutUrl;
    if (url == null) {
      return;
    }

    await fetch(url, {
     credentials: 'include',
     redirect: 'follow',
    });
  }

  public getAuthUrl(): string {
    const { authUrl, clientId } = this.config;
    const q = {
      client_id: clientId,
      redirect_uri: window.location.protocol + '//' + window.location.host +  '/oauth',
      scope: 'read', // TODO
      response_type: 'code',
      response_mode: 'query',
      nonce: this.generateOAuth2Nonce(),
      state: this.state.flow.state,
    };
    const qs = queryString.stringify(q);
    return `${authUrl}?${qs}`;
  }

  public getAuthToken(raw: boolean = false): string | undefined {
    if (this.state.token == null || this.state.token.auth == null) {
      return undefined;
    }

    const { token, expires } = this.state.token.auth;

    if (expires < new Date().getTime()) {
      // Auth token expired
      return undefined;
    }

    return (raw ? '' : 'Bearer ') + token;
  }

  public async getAuthTokenOrRefresh(raw: boolean = false): Promise<string | undefined> {
    const authToken = this.getAuthToken(raw);
    if (authToken == null) {
      // Token is expired, refresh and return the new token
      await this.refreshToken();
      return this.getAuthToken(raw);
    }
    return authToken;
  }

  /** Helper method to easily get an authorizer  */
  public authorizer() {
    return new Authorizer(this);
  }

  protected generateOAuth2State(): string {
    return this.generateRandomString(32);
  }

  protected generateOAuth2Nonce(): string {
    return this.generateRandomString(32);
  }

  protected generateRandomString(length: number = 32): string {
    const dec2hex = (dec: number) => {
      return ('0' + dec.toString(16)).substr(-2);
    };

    const arr = new Uint8Array(length / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('');
  }

  private parseAuthToken(authToken: string) {
    const decoded = jwt_decode<JWTAuthToken>(authToken);

    if (!decoded.hasOwnProperty('client_id') || decoded.client_id !== this.config.clientId) {
      throw new Error('Invalid or missing clientId in token');
    }

    if (!decoded.hasOwnProperty('exp')) {
      throw new Error('No expiration date in token');
    }
    const expiration = new Date(Number(decoded.exp) * 1000);

    const result: any = {
      expiration,
      user_id: decoded.hasOwnProperty('user_id') ? Number(decoded.user_id) : null,
      user_name: decoded.hasOwnProperty('user_name') ? String(decoded.user_name) : '<unknown>',
      user_givenname: decoded.hasOwnProperty('user_givenname') ? String(decoded.user_givenname) : '<unknown>',
      user_surname: decoded.hasOwnProperty('user_surname') ? String(decoded.user_surname) : '<unknown>',
      roles: decoded.hasOwnProperty('authorities') ? decoded.authorities : [],
    };
    result.user_displayname =
      !!result.user_givenname && !!result.user_surname
        ? `${result.user_givenname} ${result.user_surname}`
        : result.user_name;
    return result;
  }

  private parseRefreshToken(refreshToken: string) {
    const decoded = jwt_decode<JWTRefreshToken>(refreshToken);
    if (!decoded.hasOwnProperty('exp')) {
      throw new Error('No expiration date in token');
    }
    const expiration = new Date(Number(decoded.exp) * 1000);

    return { expiration };
  }

  // @TODO: Remove this!
  /**
   * Login a demo user with a locally created token
   *
   * @deprecated
   */
  public async demologin(roles: string[], user_givenname: string, user_surname: string, email?: string): Promise<void> {
    const user_name = email ? email : user_givenname.toLowerCase() + '.' + user_surname.toLowerCase() + '@mms3.test';
    const token = {
      aud: ['mms3_core', 'mms3_admin', 'mms3_pubtype', 'mms3_duplicate'],
      user_givenname,
      user_surname,
      user_name,
      user_id: 1337,
      scope: ['read'],
      exp: Math.floor(Date.now() / 1000) + 60 * 60 * 24 * 1000, // Valid for 1000 days
      authorities: roles,
      jti: '0',
      client_id: 'frontend',
    };
    const signedToken = jsrsasign.KJUR.jws.JWS.sign(null, { alg: 'RS256' }, token,
      (process.env.REACT_APP_TOKEN_PK === null || process.env.REACT_APP_TOKEN_PK === undefined) ?
      `-----BEGIN PRIVATE KEY-----
      MIIJRAIBADANBgkqhkiG9w0BAQEFAASCCS4wggkqAgEAAoICAQCxA7KlTK+zc4ip
      g+LMR0JkMXRB5xy3e76a8PS1E0vW3XQJDQCrQdgs6OoaslRjh3BVW4ir1nw9LruK
      Uuq51JZ6mU3NkJ8/L0BNOx579B25l09sPsaZYhgUKpcgp/9oyp7xkNA2uA3f8M94
      lT0F4ZABoRDC2+aap+XKtfEIjsTK/Vj76zmotu1aOG0alH+PTrJXwUIae8GvcM8M
      BLi0NHvdE60BBYDy6Oxzo9Ubv3wz9q0CVwvHF1LNWXXlXZjmCYMdewpWSUrNUpfR
      39iZe4jopeKaRTPP6YZVzfyT79fLw6nNomDwkrZ9oRJxi1vZ3GA/h2ZWinza+uu3
      L19oAll8LRiAhQUyjkOiHq/pHNMKn8YnFuqK7LF4HHJgnZ3CG5BzHWxFLqd7AG0V
      I4vFrumeVSbnovW2Ts3ch1AntSTpeFFuBY6nWsucg+2j0BLxlAkztVUVw/5h/T2s
      9KrJp9vbf9JbfQwPgv2s4uU+Y6opuHbkEsP8J+pho7WknxhjzJANqcsbcYdcOYZE
      El6T2EjgZ0oPcMUEXhRWtOvCX7s87VuQQtsJLUwN9hnugA0AAg33fYq2OWp/UXdL
      PTEJ42xd2eUpjCKpjjlehiQ2gcya1zTxU7Ipfm4SxpBRZOuPSz7vDuAHa4/MgPLd
      LfO+JtPtEdigdudACZAlULbIW6kHOwIDAQABAoICAFDzgvhLWLK1bFMxiD+pevg9
      EZCt85kJk0JiXVaN64nono8hDiRDqWvoXF83HJajssCmaptDLHACdius/cFcMjEA
      +O7Aa8NGhZ6MIcXH/uwIAX9cOhCdJqvaSmDZ01kGCKgqdL4Bb/7wrsDTZyPQYr4L
      /ZFPZWdmzxTkHcWvHNpEADS8/xJkDanL0kFsleVgtPii3eskm4/zBAzX/+wRcG/K
      9rxX2v+7e57q1qOo71EIR1ihnDZBOVHFzr/FDQ5uXTLXOQP64qWBVwLY3sJaF2Nl
      sXqhmS9E+XU2DYNOFR8PbREoaDK8tY5AYiHg/HFmcfG7pxZ+oNi4FaIhnP0AzhDT
      /bdXky639/6u0vvoGDSAxZrCada3Wc39Z0iSkSbIibQtvvu90mpN3BaaXlmDDNr5
      xFnX4I2SUfVcXr1MT3CmExJIznzakyWNuVYyYmQ+Wo4SrQzl+RhPIPkdExZTbJ6Q
      yjXaa0iH3nsQlUb6ljmjkGpqN9MgOeexxlli4Fkrxxt7QiIPrxcQJ7nq8S5d6MGu
      UnnCNTsd4xgiI0de5tX6vDI9d1c0H8myIBEe7WKFQcuDXBiCpyEUHwa5Cfbrhwxf
      enrN/ecjirieZnvTGaHLuMnarp9RgslV0Ec0YhvBr+B+bqI90tlAM9xU05xiefZA
      wjCtPvm+Ziy1rWbzXipxAoIBAQDadJDLcHPy0S/QhdyblzL5k0JIVmEzjL66BAqI
      gfK6wKZv2TWgg3NgdzNrhzqPXij8hal2mKkHWl1SWky7wJGl8SYqkICDPZG51CgZ
      7OaScqJEsdeDzu6YowHvKAe0wm+Q/iOrvRnWm/I40/OxPMSq7G8A3nluKJYLJMhS
      Mn8GD3gAlbPcr7kwywsdRCIbtb51NQT5jL46y2hRc0u4ZxS8BxU6Ssosb9/6rbmK
      5ScVTVduOEGAZc9BqqZCoEmhjUSQ+BXzu4AXwAG8a5X0pr/v69+yLHi3HA9zE+fz
      2lmeQWQHLQIrINWQRtn/dD+B0EB/WsF/KrSfswS4qrjdtAqDAoIBAQDPb9jSiyq7
      4cWjfTHqk4RGYwnGWR0l/Adz7i2umEZu+mG7xOnywmPJSAWxLSSEql7D9e+hpQlc
      Drenyutsw/U9KCr8ZHCu+3elA00piCwFiT1WZ8e+l6fe3+dz70rMcMbLfFy+/n1h
      f155ciYL0mWpQOoVk257SscHaOJwmqivKg5b60couunknooJe5Fs1oMdNRRm+3sP
      fgIrPN1ODw56RhAG/KIXIvlOj1yotEAM7Qjw0sthGlE9SE+YstURp4uMvTCh+Nih
      QpV4C68EDSu4kQSvuQrmV6+YqLm6GotgEWaWyXQZvUGVqzsHewRhKDEUjyn56uZe
      pjz2OcltZNLpAoIBAQCrEoGZbzS9wGdLSqjaB2vZ2iIb99kiv2NU1HlTMUBeUyOD
      j3vZeVdPAcn4uOt4/mnmZt2wjBhCM9fE+AYDOehVJSWYS5T4Maz+8098DfL6WF8O
      oJ5/D/v0+CI5oQV33pPz3bEdenbTg0gKjKDX+RiiKYk8CvzY8Vue2m0tQXIL0u1M
      t+8kMVA7D6ZigsqbHJZLvvtoWXlXHZdRwZJYlBC+tmU/QvZUGkAyVP1p8c3Ldsey
      /sb8v72wVOziXBeNNc2uITk/p2PGNYymezHxZuwD/JkvNHhMHONUYRBR/HoV3mtw
      2yRJlerokzOAQw7An88CbJX++fh833ohC0C+DZH1AoIBAQDDzGr4sOuxVDZdTzri
      lENyvODpHAxrYi0WvGbaOlmhPy0pM4Ev51SRFS5qQpYJs1an2WhLR6BCCJJKCzuU
      +pJtG5EXkybZw/r3Atq+rQ4AW75N5L+hozyNHwM1Z1VPC9RZFhXlIgnvEW53a8uF
      tR7IV8GchADw13BuCg5TA2jdjfDnynjsdSF47jyVHtHxzbkMxKFxY0aRJPufHGA3
      4fZka+WM8sF83UI9aQypIRqkGMkrp4zZJyIvmiWnmIWNmHwcaCeTe3PfIx46payZ
      QmVWXWvzAdLMAVB55CkLBSsxjrsq8RRphz50q5+YPAGyQ2GwakHQ6GxyQq+gKoN6
      goShAoIBAQCPvZu6KsmAfySpQXN2s3zlDFOxKwLV4U2JIB8LMlzPQbUQb+1N7fao
      LQQyL7U16MMQ6iJ+ZJ8NK6IFKtg0m5efMk1ZMmXOFZIDumEZVWZ9xsOQMqzimSls
      YVaSc9zE88J5ZfEtU+OYRy9DTW2FXiQq8U1x/yHxe9ybPs2PgstenUbqxMp8T0iB
      QeYpZm0X3Lw+NuLhdu2qdrhAtK6D53ZilwVP0/ut8e+Y6uPPjKPBFsNvAzoTsbZU
      s29ROr/VAEd5mh/DFyHHGf05YEY16eV+kBtZzCBeMUcObkAAa5PXJL3tEEx4HYPj
      hCyrzpq5N8tV2IOVAVCiuymkIVAB3riF
      -----END PRIVATE KEY-----` : process.env.REACT_APP_TOKEN_PK);
    logger.find('/demologin').warn(user_name, `[${roles.join(',')}]`);
    await this.installToken(signedToken);
  }
}
