import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, FormattedNumber as FN } from 'react-intl';
import { isNullOrUndefined } from 'is-what';
import { OAuth2Gate } from 'Auth/components';
import { ViewType } from 'Detail/types';
import { Notation } from 'Vocabulary/components/Notation';
import { Link } from '@theme-ui/components';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { Authorizer } from 'Auth/utils/Authorizer';
import { SearchPerson, HierarchyEntity } from 'Dashboard/types';
import { idHashFilter } from './Result';
import { renderDateTime, renderExternalIds } from 'Detail/utils';
import { getDetailContextUrl } from 'data';
import { ConnectedHierarchyTooltip as HierarchyTooltip } from '../../container/HierarchyTooltip';
import { DeletePerson } from 'Edit/components/DeletePerson';
import { WorkButton } from '../Dashboard/areas';
import OptionList from './OptionList';

/** @jsx jsx */
import { jsx } from 'theme-ui';

interface PersonResultProps {
  index: number;
  result: SearchPerson;
  updateResults: () => {};
}

export const PersonResult = (props: PersonResultProps): JSX.Element => {
  return (
    <Subscribe to={[OAuth2Container]}>{(c: OAuth2Container) => {
      const innerProps = { ...props, authorizer: c.authorizer()};
      return <InnerPersonResult {...innerProps} />;
    }}
    </Subscribe>
  );
};

interface InnerPersonResultProps extends PersonResultProps {
  authorizer: Authorizer;
}

const InnerPersonResult = (props: InnerPersonResultProps): JSX.Element => {
  const [deleteInit, setDeleteInit] = useState(false);

  const { index, result, authorizer } = props;

  const reduce = (prev: JSX.Element[], curr: JSX.Element) => [...prev, ', ', curr];

  const externalIds = renderExternalIds(
    (!Array.isArray(result.orcid) ? [] : result.orcid.map((e) => ({ externalId: e, type: 'orcid' }))).concat(
      !Array.isArray(result.gnd) ? [] : result.gnd.map((e) => ({ externalId: e, type: 'gnd' }))
    ), 'person'
  );

  const orgaEntries =
    undefined === result.organisations
      ? []
      : result.organisations
          .map((e) => idHashFilter(e))
          .map(({ id, name }) =>
            id ? (
              <HierarchyTooltip
                entity={HierarchyEntity.ORGS}
                id={id}
                name={name}
                extraProps={{ direction: 'down' }}
                key={name}
              >
                <Link href={getDetailContextUrl(ViewType.ORGS, id)}>{name}</Link>
              </HierarchyTooltip>
            ) : (
              { name }
            )
          )
          .reduce(reduce, [])
          .slice(1);
  const projectCount = isNullOrUndefined(result.projects) ? 0 : result.projects.length;
  const projectEntries = isNullOrUndefined(result.projects)
    ? []
    : result.projects
        .map((e) => idHashFilter(e))
        .map(({ id, name }, i) => (
          <div key={i} sx={{display: 'contents'}}>
            {id ? <Link href={getDetailContextUrl(ViewType.PROJECTS, id)}>{name}</Link> : { name }}
          </div>
        ))
        .reduce(reduce, [])
        .slice(1);

  const options = [];
  if (authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN'])) {
    options.push(
      <li key="edit">
        <Link href={`/person/${props.result.id}/edit`}>
          <FormattedMessage id="button.Edit" />
        </Link>
      </li>
    );
    options.push(
      <li key="delete">
        <a
          href="#"
          onClick={async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setDeleteInit(true);
          }}
        >
          {authorizer.hasRole('ROLE_ADMIN') &&
          <FormattedMessage id="button.Delete" />
          }
          {authorizer.hasRole('ROLE_EDITOR') &&
          <FormattedMessage id="button.DeleteRequest" />
          }
        </a>
      </li>
    );
  }

  return (
    <>
      {deleteInit &&
      <DeletePerson
        personId={parseInt(props.result.id, 10)}
        isDeleteRequest={!authorizer.hasRole('ROLE_ADMIN')}
        onAbort={() => setDeleteInit(false)}
        onSuccess={() => {
          setDeleteInit(false);
          props.updateResults();
        }}
        onError={() => {
          alert('Fehler beim Löschen!');
          setDeleteInit(false);
        }}
      />
      }
      <div className="result" key={result.id}>
        <div className="content">
          <div className="info main">
            <div className="main-title">
              <div className="index">
                <FN value={index + 1} />.
              </div>
              <Link href={getDetailContextUrl(ViewType.PEOPLE, result.id)}>
                {result.name ? (
                  result.name.join('; ')
                ) : (
                  <i>
                    <FormattedMessage id="No_information" />
                  </i>
                )}
              </Link>
            </div>
            {externalIds}
            <div className="organisations">{orgaEntries.filter(item => item != ', ')}</div>
            <div className="projects">
              {projectCount > 0 ? (
                <>
                  <b>
                    <FormattedMessage id="person.attr.projects(count)" values={{ count: projectCount }} />:{' '}
                  </b>
                  {projectEntries}
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="info right">
            <div className="record status">
              <FormattedMessage id="entity.attr.editorialStatus" />:{' '}
              <Notation v="editorial_status_list" k={result.editorialStatus} />
            </div>
            <div className="record creation">
              <FormattedMessage id="entity.attr.recordCreationDate" />: {renderDateTime(result.recordCreationDate)}
            </div>
            <div className="record change">
              <FormattedMessage id="entity.attr.recordChangeDate" />: {renderDateTime(result.recordChangeDate)}
            </div>
          </div>
        </div>
        <div className="actions" sx={{mb: '-0.75em', pb: '2em', pr: '0.5em'}}>
          <WorkButton id={result.id} workdashboard={true}/>
          <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
            <OptionList id={result.id} options={options} updateResults={props.updateResults} />
          </OAuth2Gate>
        </div>
      </div>
    </>
  );
};

export default PersonResult;
