import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { WorkDTO, WorkProjectOtherDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { DataModelGate } from 'Auth/components/DataModelGate';
import { DefaultWorkProjectOther } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  others: { id: 'work.attr.workProjectOthers' },
  name: { id: 'work.attr.workProjectOthers.attr.othername' },
  nameHelp: { id: 'work.attr.workProjectOthers.attr.othername.help' },
  role: { id: 'work.attr.workProject.attr.role' },
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: WorkProjectOtherDTO[]) => void;
  entity: string;
  pubtypeGroup: string;
  validation: ValidationResult;
}

export function WorkProjectOthers({ modifiedModel, updateField, entity, pubtypeGroup,validation }: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <DataModelGate entity={entity} always field="work_project_others">
      {(writableOther, readableOther, checkPermission) => (
        <div className="col">
         {checkPermission().read && (
            <div>
              <Styled.h2> {intl.formatMessage(messages.others)} </Styled.h2>
              <Border>
                <MultiInputControls<WorkProjectOtherDTO>
                  lines={modifiedModel.workProjectOthers ? modifiedModel.workProjectOthers : []}
                  onChange={(data: WorkProjectOtherDTO[]): void => updateField('workProjectOthers', data)}
                  add
                  remove
                  defaultRow={DefaultWorkProjectOther}
                  renderLines={(
                    workProjectOther: WorkProjectOtherDTO,
                    onChangeGoo2: (data: WorkProjectOtherDTO) => void,
                    actions,
                    idx: number
                  ): ReactElement => (
                    <div className="fieldRow" key={idx}>
                      <TextInputP
                        name={intl.formatMessage(messages.name)}
                        help={intl.formatMessage(messages.nameHelp)}
                        inputName="workProjectOtherName"
                        value={workProjectOther.name}
                        onChange={(name: string): void => onChangeGoo2({ ...workProjectOther, name })}
                        permissions={checkPermission('name')}
                        validation={validation?.children[idx]?.name}
                        autoFocus={true}
                      />
                      <SelectInputP
                        listPartition={pubtypeGroup}
                        onChange={(role: string): void => onChangeGoo2({ ...workProjectOther, role })}
                        value={workProjectOther.role}
                        list={'corporation_role_list'}
                        name={intl.formatMessage(messages.role)}
                        inputName="role"
                        permissions={checkPermission('role')}
                        validation={validation?.children[idx]?.role}
                      />
                      {!!actions.delete && (
                        <div className="deleteButton">
                          <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                        </div>
                      )}
                    </div>
                  )}
                  renderEnd={(actionAdd) => (
                    <div className="addButton">
                      <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
                    </div>
                  )}
                />
              </Border>
            </div>
          )}
        </div>
      )}
    </DataModelGate>
  );
}
