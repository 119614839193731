import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import Popup from 'BaseLayout/components/popup/Popup';
import { useHistory } from "react-router-dom";

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface DeletePostNotificationProps {
  headline: string;
  exclusiveWorks: number[];
  onClose: () => void;
}

export const DeletePostNotification = (props: DeletePostNotificationProps): JSX.Element => {
  const intl: IntlShape = useIntl();
  const history = useHistory();

  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.close' }),
      onClick: (): void => {
        props.onClose();
        history.push('/search/persons');
      }
    }
  ];

  if (!!props.exclusiveWorks) {
    buttons.push(
      {
        label: intl.formatMessage({ id: 'button.openInSeparateTabs' }),
        onClick: () => {
          props.exclusiveWorks.forEach((id: number): Window =>
            window.open(`/work/${id}/edit`, '_blank', 'new_' + id)
          );
        }
      }
    );
  }

  return (
    <Popup
      title={props.headline}
      open={true}
      buttons={buttons}
    >
      {!!props.exclusiveWorks &&
        <>
        <p><FormattedMessage id="person.delete.postnotification.exclusive_works_description"/></p>
        <ul sx={{ listStyleType: "none", m: 5 }}>
          {props.exclusiveWorks.map((id: number): React.JSX.Element =>
          <li key={id}>
            <Link to={`/work/${id}/edit`} target="_blank">#{id}</Link>
          </li>
          )}
        </ul>
        </>
      }
    </Popup>
  );
};
