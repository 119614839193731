import { ValidationResult } from './interfaces';
import { PersonNameDTO, WorkGeoLocationPointDTO } from '../../api/core';

export function validateRequired(data: any, check: boolean): ValidationResult {
  if(check) {
    if(data === '') {
      return { valid: false, reason: 'validated:required:emptyString' };
    }
    if(data === null) {
      return { valid: false, reason: 'validated:required:null' };
    }
    if(data === undefined) {
      return { valid: false, reason: 'validated:required:undefined' };
    }
    if(data === false) {
      return { valid: false, reason: 'validated:required:false' };
    }
    if(data === []) {
      return { valid: false, reason: 'validated:required:emptyArray' };
    }
  }
  return { valid: true };
}

export function validateIssuedLength(data: number, check: boolean): ValidationResult {
  if(check) {
    const length = (data||'').toString().length;
    if (length > 0 && length !== 8) {
      return { valid: false, reason: 'validated:length' };
    }
  }
  return { valid: true };
}

export function validateOneMainName(data: PersonNameDTO[], check: boolean): ValidationResult {
  if(check) {
    const isMains: number = data.filter((names: PersonNameDTO): boolean => names.isMain).length;
    if(isMains === 1) {
      return { valid: true };
    }
    return { valid: false, reason: isMains < 1 ? 'validated:noMainName' : 'validated:toManyMainNames' };
  }
  return { valid: true };
}

export function validateMinGeoLocationPoints(data: WorkGeoLocationPointDTO[], check: boolean): ValidationResult {
  if(check) {
    if(data.length < 4) {
      return { valid: false, reason: 'validated:amount:geoLocationPoints'};
    }
  }
  return { valid: true };
}
