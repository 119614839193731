import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { messages } from '../data';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import { Validation } from '../validation/interfaces';
import { isValid } from '../validation/isValid';

export function WorkNavigation({ pages, id, pubtype, validation }: { pages: { [key: string]: Set<string> }; id?: number; pubtype: string; validation: Validation }) {
  const buttons: any[] = [];

  const intl: IntlShape = useIntl();
  const location = useLocation();

  for (const [key, fields] of Object.entries(pages)) {
    // Check if page is import, add or edit and select path
    const path = location.pathname.startsWith('/import') ? `import/${pubtype}` : !id ? `works/add/${pubtype}` : `work/${id}/edit`;
    const classes: string[] = [];
    const contentValid = isValid(validation, [...fields]);
    if(!contentValid) {
      classes.push('invalid');
    }
    buttons.push(
      <NavLink className={classes.join(" ")}
        sx={{
          width: "100%",
          display: "block",
          p: 4,
          backgroundColor: "white",
          textDecoration: "none",
          color: "work_navigation",
          textAlign: "left",
          fontWeight: "bold",
          border: "1px solid",
          borderColor: "lighter",
          "&.invalid": {
            color: "negative"
          },
          "&.active": {
            backgroundColor: "primary",
            color: "white"
          }
        }}
        key={key}
        to={{
          pathname: `/${path}/${key}`,
          state: location.state
        }}
      >
        {messages[key] ? intl.formatMessage(messages[key]) : `Transl. key error: ${key}`} <i>{contentValid ? '' : <FormattedMessage id={"edit.work.page.incomplete"}/>}</i>
      </NavLink>
    );
  }
  return <React.Fragment>{buttons}</React.Fragment>;
}

