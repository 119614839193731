import { createThemeableComponent, logoComponents } from 'BaseLayout/themeable-components';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { Flex } from 'theme-ui';
import { ErrorPagesContext } from 'Utils/ErrorPagesProvider';

export interface Props {}

export const GeneralError: React.FC<Props> = () => {
  const { setHideHeaderFooter, setGeneralError } = useContext(ErrorPagesContext);
  const Logo = createThemeableComponent(logoComponents);
  const history = useHistory();

  useEffect((): void =>{
    setGeneralError(true);
    setHideHeaderFooter(true);
  }, [setGeneralError, setHideHeaderFooter]);

  return (
    <>
      <Flex
          as="nav"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'baseline',
            pb: 8,
            pr: 8,
            pl: 8,
            mt: '56px',
            borderBottomWidth: "1px",
            borderBottomStyle:"solid",
            borderBottomColor: "light"
          }}
        >
          <Link to="/" onClick={() => { history.replace('/'); window.location.reload(); }}>
            <Logo sx={{width: "10em"}} />
          </Link>
        </Flex>
      <div>
        <h2><FormattedMessage id="error.general.title" /></h2>
        <p>
          <Link sx={{color: "primary"}}  to="/" onClick={() => { history.replace('/'); window.location.reload(); }}>
            <FormattedMessage id="error.link.toStartpage" />
          </Link>
        </p>
      </div>
    </>
  );
};
