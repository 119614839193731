// Components
import * as React from 'react';
import { defineMessages, useIntl, FormattedMessage, FormattedNumber as FN, IntlShape } from 'react-intl';
import { DropdownList } from 'react-widgets';
import { Radio } from '../../../BaseLayout/components/form';

// Types
import { DashboardType } from '../../types';
import { Props, State } from './index';

// Data
import { resultPaginationNeighbors, resultsPerPage } from '../../data';
import { Pagination as PaginationWrapper } from '../Pagination';
// Logger
import getLogger from '../../../log';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';

const logger = getLogger('Dashboard/component');

const messages = defineMessages({
  none: { id: 'sorting.none' },
  issued_date_asc: { id: 'sorting.publish_date_asc'},
  issued_date_desc: { id: 'sorting.publish_date_desc'},
  created_date_asc: { id: 'sorting.create_date_asc'},
  created_date_desc: { id: 'sorting.create_date_desc'},
  changed_date_asc : { id: 'sorting.changed_date_asc'},
  changed_date_desc : { id: 'sorting.changed_date_desc'},
  projects_checked_asc: { id: 'sorting.projects_checked_asc'},
  projects_checked_desc: { id: 'sorting.projects_checked_desc'},
});

// Props Types
export type SortingProps = Pick<Props, 'className' | 'dashboardType' | 'searchResultSort' | 'onSearchResultSortUpdate'>;

export type PaginationProps = SortingProps &
  Pick<
    Props,
    'className' | 'searchResultCount' | 'searchResultOffset' | 'searchResultLimit' | 'onSearchResultOffsetUpdate'
  > &
  Pick<State, 'gotoOffset'> & { handleGotoInputChange: (v: string) => void };

export type CounterProps = Pick<
  Props,
  'className' | 'searchResults' | 'searchResultCount' | 'searchResultLimit' | 'searchResultOffset'
>;

export type LimiterProps = Pick<Props, 'className' | 'searchResultLimit' | 'onSearchResultLimitUpdate' | 'key'>;

// Components
export const Sorting: React.FC<SortingProps> = (props) => {
  const intl: IntlShape = useIntl();
  const { dashboardType, searchResultSort, onSearchResultSortUpdate } = props;
  const sortOptionsAll: Map<string, {displayName: string, sortField: string, sortDirection: string}> = new Map([
    ['none', {
      displayName: intl.formatMessage(messages.none),
      sortField: '',
      sortDirection: '',
    }],
    ['issued_date_asc', {
      displayName: intl.formatMessage(messages.issued_date_asc),
      sortField: 'f_issued',
      sortDirection: 'asc',
    }],
    ['issued_date_desc', {
      displayName: intl.formatMessage(messages.issued_date_desc),
      sortField: 'f_issued',
      sortDirection: 'desc',
    }],
    ['created_date_asc', {
      displayName: intl.formatMessage(messages.created_date_asc),
      sortField: 'recordCreationDate',
      sortDirection: 'asc',
    }],
    ['created_date_desc', {
      displayName: intl.formatMessage(messages.created_date_desc),
      sortField: 'recordCreationDate',
      sortDirection: 'desc',
    }],
    ['changed_date_asc', {
      displayName: intl.formatMessage(messages.changed_date_asc),
      sortField: 'recordChangeDate',
      sortDirection: 'asc',
    }],
    ['changed_date_desc', {
      displayName: intl.formatMessage(messages.changed_date_desc),
      sortField: 'recordChangeDate',
      sortDirection: 'desc',
    }],
    ['projects_checked_asc', {
      displayName: intl.formatMessage(messages.projects_checked_asc),
      sortField: 'projectsChecked',
      sortDirection: 'asc',
    }],
    ['projects_checked_desc', {
      displayName: intl.formatMessage(messages.projects_checked_desc),
      sortField: 'projectsChecked',
      sortDirection: 'desc',
    }],
  ]);
  let fields: string[];
  if (dashboardType === DashboardType.WORKS) {
    fields = ['none', 'created_date_asc', 'created_date_desc', 'changed_date_asc', 'changed_date_desc'];
    if(window.location.pathname === '/user/profile') {
      fields.push('projects_checked_asc', 'projects_checked_desc');
    }
  } else {
    fields = ['none', 'issued_date_asc', 'issued_date_desc', 'created_date_asc', 'created_date_desc', 'changed_date_asc', 'changed_date_desc'];
  }
  let sortOptions: {displayName: string, sortField: string, sortDirection: string}[] = [];
  fields.forEach((field: string): number => sortOptions.push(sortOptionsAll.get(field)));

  const ListItem = ({ item }) => <div>{item.displayName}</div>;

  const selectedField = !!searchResultSort ? searchResultSort.field : '';
  const selectedDirection = !!searchResultSort ? searchResultSort.direction : '';

  return (
    <React.Fragment>
      <span sx={{color: 'darker', fontWeight: 'bold'}}><FormattedMessage id="sorting.label"/>:</span>
      <div className="dropdownList">
        <DropdownList
          data={sortOptions}
          value={sortOptions.find((e) => e.sortField === selectedField && e.sortDirection === selectedDirection)}
          textField="displayName"
          itemComponent={ListItem}
          onChange={(item) => {
            const v = item.sortField === null ? null : { field: item.sortField, direction: item.sortDirection };
            onSearchResultSortUpdate(v);
          }}
        />
      </div>
    </React.Fragment>
  );
};

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { searchResultCount, searchResultOffset, searchResultLimit, onSearchResultOffsetUpdate, gotoOffset } = props;
  const n = resultPaginationNeighbors;
  if (searchResultLimit === undefined) {
    // No pagination
    return <React.Fragment></React.Fragment>;
  }

  const page = Math.floor(searchResultOffset / searchResultLimit) + 1;
  const pageCount = Math.ceil(searchResultCount / searchResultLimit);

  const handleClick = (p: number) => {
    return () => {
      onSearchResultOffsetUpdate((p - 1) * searchResultLimit);
    };
  };

  const handleGotoInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.handleGotoInputChange(e.target.value);
  };

  const handleGotoFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (0 === gotoOffset.length) {
      return;
    }
    const value = parseInt(gotoOffset, 10);
    if (isNaN(value)) {
      logger.error('Invalid goto offset', value);
      return;
    }
    if (value < 1) {
      return;
    }
    onSearchResultOffsetUpdate(value - 1);
  };

  const pages = [];
  // First page
  if (page > 1) {
    pages.push(
      <button key={1} type="button" onClick={handleClick(1)}>
        <FN value={1} />
      </button>
    );
  }
  // Filler
  if (page > 1 + n + 1) {
    pages.push(<span key="fillLeft">...</span>);
  }
  // n pages before current
  for (let p = Math.max(page - n, 2); p < page; ++p) {
    pages.push(
      <button key={p} type="button" onClick={handleClick(p)}>
        <FN value={p} />
      </button>
    );
  }
  // Current page
  pages.push(
    <button key={page} type="button" className="current">
      <FN value={page} />
    </button>
  );
  // n pages after current
  for (let p = page + 1; p < Math.min(pageCount, page + n + 1); ++p) {
    pages.push(
      <button key={p} type="button" onClick={handleClick(p)}>
        <FN value={p} />
      </button>
    );
  }
  // Filler
  if (page < pageCount - n - 1) {
    pages.push(<span key="fillRight">...</span>);
  }
  // Last page
  if (page < pageCount) {
    pages.push(
      <button key={pageCount} type="button" onClick={handleClick(pageCount)}>
        <FN value={pageCount} />
      </button>
    );
  }

  let className = props.className || '';
  if (searchResultCount === 0) {
    className += ' empty';
  }

  if (pageCount < 2) {
    // Hide pagination if there's no or only one page
    pages.length = 0;
  }

  return (
    <PaginationWrapper className={className}>
      <div className="sorting">
        <Sorting {...props} className={null} />
      </div>
      <div className="pages pages-mainview">{pages}</div>
      <div className="goto">
        <form onSubmit={handleGotoFormSubmit}>
          <label>
            <FormattedMessage id="search.goto_entry" />:
            <input
              type="number"
              min="1"
              max={searchResultCount}
              step="1"
              className="input inline"
              value={gotoOffset}
              onChange={handleGotoInputChange}
            ></input>
          </label>
        </form>
      </div>
    </PaginationWrapper>
  );
};

export const Counter: React.FC<CounterProps> = (props) => {
  const { className, searchResults, searchResultCount, searchResultLimit, searchResultOffset } = props;

  const count = searchResults.length;
  const countText = (
    <FormattedMessage
      id="search.result_count(count,from,to)"
      values={{
        from: searchResultOffset + 1,
        to: searchResultOffset + count,
        count: searchResultCount,
        pages: Math.ceil(searchResultCount / searchResultLimit),
      }}
    />
  );

  return (
    <div>
      {window.location.href.includes('search') && (<Styled.h1 sx={{textAlign: 'left'}}><FormattedMessage id="search.result"/></Styled.h1>)}
      {window.location.href.includes('search') ?
        <p key="result-count" className={className} sx={{textAlign: 'right', color: 'darker', mt:'-3em'}}>
          {countText}
        </p>
        :
        <p key="result-count" className={className} sx={{textAlign: 'right', color: 'darker'}}>
          {countText}
        </p>
      }
    </div>

  );
};

export const Limiter: React.FC<LimiterProps> = (props) => {
  const { className, searchResultLimit, onSearchResultLimitUpdate } = props;

  const handleResultLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearchResultLimitUpdate(parseInt(e.target.value, 10));
  };

  return (
    <p key="result-limiter" className={className} sx={{mb: "1em", textAlign: 'right', color: 'darker'}}>
      <FormattedMessage id="search.results_per_page" />:
      {resultsPerPage.map((c) => (
        <Radio
          key={c}
          name="result-limit-radio"
          value={c}
          checked={c === searchResultLimit}
          onChange={handleResultLimitChange}
        >
          {c}
        </Radio>
      ))}
    </p>
  );
};
