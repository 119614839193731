import React, { useEffect, useState } from 'react';
import workReportImg from 'BaseLayout/assets/img/homepage/work-report.svg';
import orcidLinkImg from 'BaseLayout/assets/img/homepage/orcid-link.svg';
import publicationEmbedImg from 'BaseLayout/assets/img/homepage/publication-embed.svg';
/** @jsx jsx */
import { jsx, Grid, Card, Container, Flex, Input } from 'theme-ui';
import { defineMessages, FormattedHTMLMessage, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Link } from "theme-ui";
import { Link as LinkRouter } from "react-router-dom";
import { OAuth2Sender } from 'Auth/components';
import ConnectedStatistics from 'HomePage/container/Statistics';
import { HomepageDesktopStyling } from '../theme';
import { Props as HomepageProps } from 'HomePage/pages/Homepage';


const messages = defineMessages({ placeholder: { id: 'search.placeholder' } });

export const RubHomepageDesktop = (props: { props: HomepageProps }): React.JSX.Element => {
  const searchTerm: string = props.props.changedSearchTerm ? props.props.changedSearchTerm : props.props.searchTerm;
  const intl: IntlShape = useIntl();
  const [term, setTerm] = useState(searchTerm);

  const onChangeSearchHandler = (value: string): void => {
    const search: string = value ? value : "";
    setTerm(search);
  };

  useEffect((): void => {
    setTerm('');
  }, []);

  return (
    <React.Fragment>
      <HomepageDesktopStyling {...props}>
        <Container variant="banner" className='banner'>
          <h1>
            <FormattedMessage id="rub.homepage.title" />
          </h1>
          <Flex className='search'>
            <Input
              variant="inputFrontSearch"
              type="text"
              placeholder={intl.formatMessage(messages.placeholder)}
              value={term}
              onChange={(e): void => {
                onChangeSearchHandler(e.target.value);
              }}
              onKeyUp={(e): void => {
                if(e.keyCode === 13) {
                  props.props.onSearchSubmit((e.target as HTMLInputElement).value);
                }
              }}
            />
            <i onClick={() => { props.props.onSearchSubmit(term); }} className="icon-suche" />
            <i className="icon-schliessen_weiss" onClick={() => onChangeSearchHandler(null)} />
          </Flex>
        </Container>
        <Card>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div>
              <FormattedHTMLMessage id="rub.homepage.intro.leftColumn" />
            </div>
            <div>
              <FormattedHTMLMessage id="rub.homepage.intro.rightColumn" />
            </div>
          </Grid>
        </Card>
        <Card sx={{ backgroundColor: 'lighter' }}>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div>
              <h2>
                <FormattedMessage id="rub.homepage.teaser1.title" />
              </h2>
              <p>
                <FormattedHTMLMessage id="rub.homepage.teaser1.text" />
              </p>
              <br />
              <OAuth2Sender
                render={({ authUrl }) => (
                  <Link className='button' href={authUrl}>
                    <FormattedMessage id="rub.homepage.teaser1.linktext" />
                  </Link>
                )}
              />
            </div>
            <div>
              <img src={workReportImg} alt="" />
            </div>
          </Grid>
        </Card>
        <Card>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div>
              <img src={orcidLinkImg} alt="" />
            </div>
            <div>
              <h2>
                <FormattedMessage id="rub.homepage.teaser2.title" />
              </h2>
              <p>
                <FormattedHTMLMessage id="rub.homepage.teaser2.text" />
              </p>
              <br />
              <LinkRouter className='button' to="/user/settings/orcid">
                <FormattedMessage id="rub.homepage.teaser2.linktext" />
              </LinkRouter>
            </div>
          </Grid>
        </Card>
        <Card sx={{ backgroundColor: 'lighter' }}>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div>
              <h2>
                <FormattedMessage id="rub.homepage.teaser3.title" />
              </h2>
              <p>
                <FormattedHTMLMessage id="rub.homepage.teaser3.text" />
              </p>
              <br />
              <LinkRouter className='button' to="/publist/generator">
                <FormattedMessage id="rub.homepage.teaser3.linktext" />
              </LinkRouter>
            </div>
            <div>
              <img src={publicationEmbedImg} alt="" />
            </div>
          </Grid>
        </Card>
        <ConnectedStatistics />
      </HomepageDesktopStyling>
    </React.Fragment>
  );
};
