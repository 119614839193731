import * as React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import 'BaseLayout/assets/fonts/icons/icomoon/style.css';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { HeaderMobile } from './headerMobile';
import { HeaderDesktop } from './headerDesktop';

export interface Props {
  className?: string;
  searchTerm: string;
  topBarVisibility: boolean;
  onSearchSubmit: (value: string) => void;
}

export const Header = (props: Props): React.JSX.Element => {
  const index: number = useBreakpointIndex({ defaultIndex: 0 });

  return index <= 2 ? (
    <HeaderMobile />
  ) : (
    <HeaderDesktop />
  );
};
