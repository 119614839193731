import * as React from 'react';
import { useState } from 'react';
import { WorkDTO } from 'api/core/models';
import { WorkApi } from 'api/core/apis';
import { LoaderBar } from 'BaseLayout/components/misc/LoaderBar';
import { TextInput } from 'BaseLayout/components/form/generic/TextInput';
import { Link as LinkRouter } from 'react-router-dom';
import { defineMessages, useIntl, FormattedMessage, IntlShape } from 'react-intl';
/** @jsx jsx */
import { Flex, Label, Select, jsx, Button } from 'theme-ui';
import { types, useAlert } from 'react-alert';
import { Main } from 'BaseLayout/components/base-layout/main';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { getThemeNameFromAuth } from 'BaseLayout/components';

const messages = defineMessages({
  inputLabel: { id: "work.import.input.label" },
  inputPlaceholder: { id: "work.import.input.placeholder" },
  typeLabel: {id: "work.import.type.label"},
  noResultsAlert: {id: "work.import.alert.noResults"},
});
        // "ID im Format: <type>:<id>"

const api = new WorkApi();

interface Props {
  submitImport: (data: WorkDTO) => void;
}

export const Import: React.FC<Props> = ({ submitImport }) => {
  const [list, setList] = useState<WorkDTO[]>([]);
  const [id, setId] = useState<string>('');
  const [type, setType] = useState<string>('doi');
  // Timeout to prevent Api flooding
  const [fetchTimeout, setFetchTimeout] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const alert = useAlert();

  const intl: IntlShape = useIntl();

  const searchId = (id: string): void => {

    if (id === '') {
      return;
    }

    clearTimeout(fetchTimeout);
    setId(id);

    const newTimeout = setTimeout(() => {
      setLoading(true);
      api
        .findInExternalSources({
          id: type+ ':' +id,
        })
        .then(
          (list: WorkDTO[]) => {
            if (list.length === 0) {
              setList([]);
              alert.show(intl.formatMessage(messages.noResultsAlert), {type: types.INFO});
            }
            else {
              setList(list);
            }
          }
        ).catch(
          () => {setList([]); alert.show(intl.formatMessage(messages.noResultsAlert), {type: types.INFO});}
        )
        .finally(
          () => {setLoading(false);}
        );
    }, 1000);
    setFetchTimeout(newTimeout);
  };

  return (
    <Subscribe to={[OAuth2Container]}>
      {(container: OAuth2Container) => {
        const themeName =  getThemeNameFromAuth(container);
        return (
          <Main>
            <article sx={{
              pt: 8,
              px: 8,
            }}>
              <div sx={{ mb: '24px' }}>
                <i className="icon-links_aktiv" sx={{ fontSize: '9px', mr: '5px' }} />
                <LinkRouter sx={{color: "primary", fontWeight: '600'}} to="/works/add">
                  <FormattedMessage id="work.upload.button.back" />
                </LinkRouter>
              </div>
              <h1 sx={{ mb: '4px', color: 'primary', fontWeight: 'bold', fontSize: '2em' }}>
                <FormattedMessage id="work.import.headline" />
              </h1>
              <Flex sx={{
                gap: '6',
                alignItems: 'end'
                }}>
                <Label variant="labelYAutoWidth" sx={{ width: '4.5em' }}>
                  {intl.formatMessage(messages.typeLabel)}
                  <Select
                    id="option-import-type"
                    name="type_selector"
                    onChange={(e: any)=> {setType(e.target.value);}}
                  >
                    <option value="doi">DOI</option>
                    <option value="isbn">ISBN</option>
                  </Select>
                </Label>
                <div sx={{
                  input: {
                    width: '34em',
                    "::placeholder": {
                      fontStyle: 'italic',
                    }
                  }
                }}>
                  <TextInput
                    required={false}
                    value={id}
                    placeholder={intl.formatMessage(messages.inputPlaceholder)}
                    onChange={e => setId(e)}
                    autoFocus={true}
                    onEnter={searchId}
                  />
                </div>
                <div sx={{
                  button: {
                    px: '12px', py: '8px', mb: '5px'
                  }
                }}>
                  <Button
                    onClick={() => { searchId(id); }}
                  >
                    <FormattedMessage id="work.import.button.search" />
                  </Button>
                </div>
              </Flex>
              <LoaderBar hidden={!loading} />
              <ul sx={{
                borderStyle: 'solid',
                borderColor: '#d9d9d98c',
                width: 'fit-content',
                mt: '1.75em'
              }}>
                {list.map((work: WorkDTO) => (
                  <li sx={{
                      px: "1em",
                      py: "0.75em",
                      display: "flex",
                  }}>
                    <b sx={{ mt: themeName === 'uwh' ? '7px' : '4px' }} key={work.title}>{work.title}</b>
                    <div sx={{
                      button: {
                        ml: '1em', px: '12px', py: '8px'
                      }
                    }}>
                      <Button
                        onClick={() => {
                          submitImport(work);
                        }}
                      >
                        <FormattedMessage id="work.import.button.import.label" />
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            </article>
          </Main>
        );
      }}
    </Subscribe>
  );
};
