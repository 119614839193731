import React, { ReactElement, ReactNode } from 'react';
import { Vocabulary } from 'Vocabulary/components/Vocabulary';
import { Select } from 'theme-ui';
import { ReadWritePermissions } from '../../../../Auth/components/DataModelGate';
import { isRequired } from './utils';
import { ValidationResult } from '../../../../Edit/validation/interfaces';
import { InputLabel } from './InputLabel';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  name?: string;
  help?: string;
  inputName?: string;
  inputProps?: {[key: string]: any};
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  classes?: string[];
  validation?: ValidationResult;
  skipEmptyValue?: boolean;
  children: ReactNode[];
  autoFocus: boolean;
}

/**
 * SelectInput mit den Options als Parameter übergeben
 *
 * @param value
 * @param classes
 * @param onChange
 * @param name
 * @param inputName
 * @param disabled
 * @param placeholder
 * @param required
 * @param validation
 * @param skipEmptyValue
 * @param children
 * @param autoFocus
 * @param help
 * @constructor
 */
export const SelectInputF: React.FunctionComponent<Props> = ({ value, classes, onChange, name, inputName, disabled, placeholder, required, validation, skipEmptyValue, children, autoFocus, help }: Props) => {
  if(!validation) validation = {valid: true};
  if(required && !value) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }
  classes = classes ? classes : [];
  if (required){
    classes.push("required");
  }
  if(validation && !validation.valid) {
    classes.push("invalid");
  }
  return <InputLabel variant="labelY" label={name||''} required={required} invalid={validation && !validation.valid} help={help}>
    <Select
      name={inputName ?? undefined}
      className={classes.join(" ")}
      disabled={disabled !== undefined && disabled !== null ? disabled : false}
      value={value ? value : ''}
      placeholder={placeholder ? placeholder : ''}
      onChange={(e: React.FormEvent<HTMLSelectElement>) => onChange(e.currentTarget.value)}
      required={required ?? false}
      autoFocus={autoFocus}
    >
      {!skipEmptyValue && <option value="" />}
      {children}
    </Select>
  </InputLabel>;
};

interface PropsP extends Props {
  permissions: ReadWritePermissions;
}

/**
 * SelectInput mit integrierter Lese- und Schreibberechtigungsprüfung
 * und den Options als Parameter übergeben
 *
 * @param props
 * @constructor
 */
export function SelectInputPF(props: PropsP): ReactElement {
  if (!props.permissions.read) {
    return <></>;
  }
  return (
    <SelectInputF
      {...props}
      disabled={!props.permissions.write || props.disabled}
      required={isRequired(props.permissions)}
    >{props.children}</SelectInputF>
  );
}

interface ListProps {
  value?: string;
  listPartition: string;
  onChange: (value: string) => void;
  name?: string;
  help?: string;
  list: string;
  listSorted?: boolean;
  inputName?: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  classes?: string[];
  validation?: ValidationResult;
  skipEmptyValue?: boolean;
  autoFocus?: boolean;
}

/**
 * SelectInput mit einer Liste aus dem Vokabular
 *
 * @param value
 * @param classes
 * @param listPartition
 * @param onChange
 * @param placeholder
 * @param name
 * @param list
 * @param listSorted
 * @param inputName
 * @param disabled
 * @param required
 * @param validation
 * @param skipEmptyValue
 * @param autoFocus
 * @param help
 * @constructor
 */
export const SelectInput: React.FunctionComponent<ListProps> = ({
  value,
  classes,
  listPartition,
  onChange,
  placeholder,
  name,
  list,
  listSorted,
  inputName,
  disabled,
  required,
  validation,
  skipEmptyValue,
  autoFocus,
  help
}: ListProps): ReactElement => {
  classes = classes ? classes : [];
  if (required){
    classes.push("required");
  }
  if(validation && !validation.valid) {
    classes.push("invalid");
  }
  return (
    <Vocabulary p={listPartition} v={list} sort={listSorted}>
      {(notations: Map<string, string>): ReactNode => (
        <SelectInputF inputName={inputName} autoFocus={autoFocus || false} placeholder={placeholder} name={name} required={required} disabled={disabled} classes={classes} validation={validation} onChange={onChange} value={value} skipEmptyValue={skipEmptyValue} help={help}>
          {Array.from(notations.keys()).map(
            (key: string, index: number): ReactNode => (
              <option value={key} key={index}>
                {notations.get(key)}
              </option>
            )
          )}
        </SelectInputF>
      )}
    </Vocabulary>
  );
};

interface ListPropsP extends ListProps {
  permissions: ReadWritePermissions;
}

/**
 * SelectInput mit integrierter Lese- und Schreibberechtigungsprüfung
 * und einer Liste aus dem Vokabular
 *
 * @param props
 * @constructor
 */
export function SelectInputP(props: ListPropsP): ReactElement {
  if (!props.permissions.read) {
    return <></>;
  }
  return (
    <SelectInput
      {...props}
      disabled={!props.permissions.write || props.disabled}
      required={isRequired(props.permissions)}
    />
  );
}
