import * as React from 'react';

// Components
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Subscribe } from 'unstated';
import { OAuth2Gate, OAuth2Sender } from '../../../../Auth/components';
import { OAuth2Container } from '../../../../Auth/containers/OAuth2Container';
import { createThemeableComponent, logoComponents } from '../../../themeable-components';

import { Flex, useColorMode } from 'theme-ui';
/** @jsx jsx */
import { jsx } from 'theme-ui';

import 'BaseLayout/assets/fonts/icons/icomoon/style.css';

import { NavItem, NavItemDropdown } from './theme';
import { getInstitutionByUsername, isOrcidInstitution } from '../../../utils';
import { ThemeContext } from '../..';

import { ConnectedSearchbar } from 'BaseLayout/container/Searchbar';
import { Link } from "react-router-dom";
import { getLanguageFromLocale, languages } from 'Intl/utils';
import { localeKey } from 'App';
import { useBreakpointIndex } from '@theme-ui/match-media';

export const HeaderDesktop = (): React.JSX.Element => {
  const intl: IntlShape = useIntl();
  const breakpoint: number = useBreakpointIndex({ defaultIndex: 0 });

  const Logo = createThemeableComponent(logoComponents);
  const [colorMode, setColorMode] = useColorMode();

  const themeName: string = React.useContext(ThemeContext);

  React.useEffect((): void =>{
    if (colorMode !== themeName) {
      setColorMode(themeName);
    }
  }, [themeName, colorMode, setColorMode]);

  return (
    <Flex
      as="nav"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'baseline',
        pb: breakpoint <= 3 ? '29px' : 8,
        px: breakpoint <= 3 ? '18px' : 8,
        borderBottomWidth: "1px",
        borderBottomStyle:"solid",
        borderBottomColor: "light",
        li: {
          fontSize: breakpoint <= 3 ? '18px' : '20px'
        },
        input: {
          height: breakpoint <= 3 ? '25px' : ''
        },
        img: {
          width: breakpoint <= 3 ? '10em' : '',
          mb: breakpoint <= 3 && themeName === 'hhu' ? '-16px' : breakpoint <= 3 && themeName === 'uwh' ? '-18px' : '',
          ml: breakpoint <= 3 && themeName === 'hhu' ? '-20px' : ''
        },
        ".icon-suche": {
          ml: breakpoint <= 3 ? '-35px' : '',
          fontSize: breakpoint <= 3 ? '15px' : ''
        },
        ".icon-schliessen_weiss": {
          fontSize: breakpoint <= 3 ? '22px' : ''
        }
      }}
    >
      <Link to="/">
        <Logo sx={{width: "10em"}} />
      </Link>
      <ul
        sx={{
          display: 'flex',
          flexDirection: 'row',
          ml: breakpoint <= 3 ? '1vw' : '5vw'
        }}
      >
        <NavItem to="/">
          <FormattedMessage id="header.homepage" />
        </NavItem>

        <Subscribe to={[OAuth2Container]}>
          {(c: OAuth2Container) => {
            return <NavItemDropdown
              items={[
                {
                  icon: '',
                  name: intl.formatMessage({ id: 'header.publist.generator' }),
                  path: '/publist/generator',
                },
                ...c.authorizer().isNotAnonymous() ? [{
                  icon: '',
                  name: intl.formatMessage({ id: 'header.publist.user_publications' }),
                  path: '/user/profile/publications',
                }] : [],
                {
                  icon: '',
                  name: intl.formatMessage({ id: 'header.publist.documentation' }),
                  path: '/publist/documentation',
                },
              ]}
            >
              <FormattedMessage id="header.publistgenerator" />
            </NavItemDropdown>;
          }}
        </Subscribe>

        <NavItemDropdown
          items={[
            {
              icon: 'icon-werkedurchsuchen',
              name: intl.formatMessage({ id: 'entity.work' }),
              path: '/search/works',
            },
            {
              icon: 'icon-personendurchsuchen',
              name: intl.formatMessage({ id: 'entity.person' }),
              path: '/search/persons',
            },
            {
              icon: 'icon-organisationdurchsuchen',
              name: intl.formatMessage({ id: 'entity.organisation' }),
              path: '/search/organisations',
            },
            {
              icon: 'icon-gruppendurchsuchen',
              name: intl.formatMessage({ id: 'entity.project' }),
              path: '/search/projects',
            },
          ]}
        >
          <FormattedMessage id="header.search" />
        </NavItemDropdown>

        <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN', 'ROLE_USER']}>
          <Subscribe to={[OAuth2Container]}>
            {(c: OAuth2Container) => {
              return <NavItemDropdown
                items={[
                  {
                    icon: 'icon-werkanlegen',
                    name: intl.formatMessage({ id: 'entity.work' }),
                    path: '/works/add',
                  },
                  (c.authorizer().hasRole("ROLE_ADMIN") || c.authorizer().hasRole("ROLE_EDITOR")) &&
                  {
                    icon: 'icon-personenanlegen',
                    name: intl.formatMessage({ id: 'entity.person' }),
                    path: '/person/add',
                  },
                  (c.authorizer().hasRole("ROLE_ADMIN") || c.authorizer().hasRole("ROLE_EDITOR")) &&
                  {
                    icon: 'icon-organisationanlegen',
                    name: intl.formatMessage({ id: 'entity.organisation' }),
                    path: '/organisation/add',
                  },
                  {
                    icon: 'icon-gruppenanlegen',
                    name: intl.formatMessage({ id: 'entity.project' }),
                    path: '/project/add',
                  },
                ]}
              >
                {(c.authorizer().hasRole("ROLE_ADMIN") || c.authorizer().hasRole("ROLE_EDITOR")) && <FormattedMessage id="header.create" />}
                {(c.authorizer().hasRole("ROLE_GUEST") || c.authorizer().hasRole("ROLE_USER")) && <FormattedMessage id="header.report" />}
          </NavItemDropdown>;
            }}
          </Subscribe>
        </OAuth2Gate>
        {(process.env.REACT_APP_DEMO_LOGIN === 'true') && (
          <Subscribe to={[OAuth2Container]}>
            {(c: OAuth2Container) => {
              const logins = [
                [
                  ['ROLE_USER', 'RUB', 'User', 'user@rub.test'],
                  ['ROLE_USER', 'TUDO', 'User', 'user@tudo.test'],
                  ['ROLE_USER', 'HHU', 'User', 'user@hhu.test'],
                  ['ROLE_USER', 'UWH', 'User', 'user@uwh.test'],
                  ['ROLE_USER', 'Other', 'User', 'user@other.test'],
                ],
                [
                  ['ROLE_EDITOR', 'RUB', 'Editor', 'editor@rub.test'],
                  ['ROLE_EDITOR', 'TUDO', 'Editor', 'editor@tudo.test'],
                  ['ROLE_EDITOR', 'HHU', 'Editor', 'editor@hhu.test'],
                  ['ROLE_EDITOR', 'UWH', 'Editor', 'editor@uwh.test']
                ],
                [
                  ['ROLE_ADMIN', 'RUB', 'Admin', 'admin@rub.test'],
                  ['ROLE_ADMIN', 'TUDO', 'Admin', 'admin@tudo.test'],
                  ['ROLE_ADMIN', 'HHU', 'Admin', 'admin@hhu.test'],
                  ['ROLE_ADMIN', 'UWH', 'Admin', 'admin@uwh.test']
                ],
              ];

              const items = [
                {
                  path: '/',
                  name: 'GUEST',
                  onClick: (): void => {
                    void c.removeToken();
                  },
                },
              ];
              logins.forEach((project: string[][]): void => {
                project.forEach((l: string[]): void => {
                  items.push({
                    path: '/',
                    name: l[1] + ' ' + l[2],
                    onClick: () => {
                      c.demologin([l[0]], l[1], l[2], l[3]);
                    },
                  });
                });
              });

              return <NavItemDropdown items={items}>Login</NavItemDropdown>;
            }}
          </Subscribe>
        )}
      </ul>
      <ConnectedSearchbar />
      <OAuth2Gate isNotAnonymous>
        <OAuth2Sender
          render={({ authorizer }) => (
            <ul>
              <Subscribe to={[OAuth2Container]}>
                {(c: OAuth2Container) => {
                  return (
                    <NavItemDropdown
                      items={[
                        {
                          path: '/user/profile',
                          name: intl.formatMessage({ id: 'userMenu.profile' }),
                        },
                        ...(c.authorizer().hasRole("ROLE_ADMIN") ? [
                          {
                            path: '/user-admin',
                            name: intl.formatMessage({ id: "userMenu.userManagement" }),
                          },
                          ...(isOrcidInstitution((getInstitutionByUsername(c.authorizer().getSession().user_name)))) && (themeName !== 'hhu') ? [{ // themeName!=='hhu' entfernen, wenn Orcid eingerichtet
                            path: '/orcid-admin',
                            name: intl.formatMessage({ id: "userMenu.orcidManagement" }),
                          }] : [],
                        ] : []),
                        {
                          path: '/user/settings/profile',
                          name: intl.formatMessage({ id: "userMenu.settings" }),
                        },
                        {
                          path: '/logout',
                          name: intl.formatMessage({ id: "userMenu.logout" }),
                        },
                        ...(process.env.NODE_ENV === 'development' ? [
                          {
                            path: '/errorBoundary',
                            name: 'Test Error Boundary',
                          },
                        ] : []),
                      ]}
                    >
                      {authorizer.getSession().user_displayname}
                    </NavItemDropdown>
                  );
                }}
              </Subscribe>
            </ul>
          )}
        />
      </OAuth2Gate>

      <OAuth2Gate isAnonymous>
        <OAuth2Sender
          render={({ authUrl }) => (
            <ul>
              <NavItem to={authUrl}>
                <FormattedMessage id="header.login" />
              </NavItem>
            </ul>
          )}
        />
      </OAuth2Gate>

      <div className="language-selection">
        {languages.map((lang, idx, arr) => {
          const isLastEntry = idx === (arr.length - 1);
          const isActiveLang = lang === getLanguageFromLocale(intl.locale);
          const langColor = isActiveLang ? "primary" : "light";

          return (
              <>
              <span className="lang"
                sx={{
                  color: langColor,
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  fontSize: breakpoint <= 3 ? '18px' : 3,
                }}
                onClick={() => {
                  window.localStorage.setItem(localeKey, lang);
                  window.dispatchEvent(new Event("applanguagechange"));
                }}
              >{lang.toUpperCase()}</span>

              {!isLastEntry &&
              <span className="delimeter"
                sx={{
                  color: 'primary',
                  fontWeight: 'bold',
                  fontSize: breakpoint <= 3 ? '18px' : 3,
                  mx: 3
                }}
              >|</span>
              }
              </>
            );
        })}
      </div>
    </Flex>
  );
};
