/** @jsx jsx */
import { jsx, Grid, Card, Flex } from 'theme-ui';
import { FormattedMessage } from 'react-intl';
import { useBreakpointIndex } from '@theme-ui/match-media';

export interface StatisticsDesktopProps {
  tiles: JSX.Element[];
}

export function StatisticsDesktop(props: StatisticsDesktopProps): JSX.Element {
  const breakpoint = useBreakpointIndex({ defaultIndex: 0 });

  return (
      <Flex
        sx={{
          justifyContent: 'center',
          my: breakpoint <= 3 ? '50px' : 10,
        }}
      >
        <Card
          p={4}
          pb={breakpoint <= 3 ? 4 : 7}
          sx={{
            color: 'primary',
            maxWidth: '80em'
          }}
          backgroundColor={'lighter'}
        >
          <h2 sx={{ mb: 3, fontWeight: 'bold', textAlign: 'center', fontSize: breakpoint <= 3 ? '29px' : 5 }}>
            <FormattedMessage id="statistics.header" />
          </h2>
          <Grid
            columns={[2, null, props.tiles.length]}
            p={breakpoint <= 3 ? '20px' : 8}
            gap={6}
            sx={{
              b: {
                fontSize: breakpoint <= 3 ? '35px' : 6,
              },
              span: {
                fontWeight: 'bold',
                fontSize: breakpoint <= 3 ? '18px' : 3,
              },
            }}
          >
            {props.tiles}
          </Grid>
        </Card>
      </Flex>
  );
}
