import * as React from 'react';
import { Container, Subscribe, SubscribeProps } from 'unstated';
import { HierarchyApi } from '../../api/core/apis/HierarchyApi';
import { HierarchyTooltip, Props as HTProps, TooltipProps } from '../components/HierarchyTooltip';
import { HierarchyEntity, HierarchyMapEntry } from '../types';

import getLogger from '../../log';

const logger = getLogger('HierarchyTooltip/container');

interface State {
  state: 'loading' | 'present' | 'missing' | 'error';
  hierarchy?: HierarchyMapEntry[];
  request: number | null;
}

const initialState: State = {
  state: 'missing',
  hierarchy: undefined,
  request: null,
};

class HierarchyTooltipContainer extends Container<State> {
  private static api: HierarchyApi = new HierarchyApi();

  // Do an actual fetch request
  private static async fetchHierarchy(entity: HierarchyEntity, id: number): Promise<HierarchyMapEntry[]> {
    const request = { id };

    // Send request
    const response = await ((req) => {
      switch (entity) {
        case HierarchyEntity.ORGS:
          return HierarchyTooltipContainer.api.getOrganisationHierarchy({organisationId: req.id});
        case HierarchyEntity.PROJECTS:
          return HierarchyTooltipContainer.api.getProjectHierarchy({projectId: req.id});
        default:
          logger.error('Illegal entity type', entity);
          return [];
      }
    })(request);

    // Transform API response to proper format and sort it
    return response.sort((a, b) => b.stage - a.stage).map((e) => ({ id: e.entityId, name: e.name }));
  }

  public state: State = initialState;

  public entity: HierarchyEntity;
  public id: number;

  constructor(entity: HierarchyEntity, id: number) {
    super();

    this.entity = entity;
    this.id = id;
  }

  // Trigger something to load the hierarchy into the state
  public async loadHierarchy() {
    // Generate a unique identifier for the request
    const request = Date.now();

    // Set state to loading
    await this.setState((prevState) => {
      if (prevState.state !== 'missing') {
        return null;
      } // Bail out if hierarchy is not missing
      return { state: 'loading', request };
    });

    if (this.state.request !== request) {
      return;
    } // Bail out if this request is not the most recent

    // Fire off the actual request
    try {
      const hierarchy = await HierarchyTooltipContainer.fetchHierarchy(this.entity, this.id);
      void this.setState((prevState) => {
        if (prevState.request !== request) {
          return null;
        } // Bail out if this request is not the most recent

        return { state: 'present', hierarchy, request: null };
      });
    } catch (ex) {
      // On error, update state
      void this.setState((prevState) => {
        if (prevState.request !== request) {
          return null;
        } // Bail out if this request is not the most recent

        return { state: 'error', hierarchy: undefined, request: null };
      });
    }
  }

  // Reset the state
  public reset() {
    void this.setState({ state: 'missing', request: null, hierarchy: undefined });
  }
}

export const hierarchyContainerMap: {
  [entity in HierarchyEntity]: Map<number, HierarchyTooltipContainer>;
} = {
  [HierarchyEntity.ORGS]: new Map(),
  [HierarchyEntity.PROJECTS]: new Map(),
};

interface Props {
  entity: HierarchyEntity;
  id: number;
  name: string;
  extraProps?: Partial<TooltipProps>;
  children?: any;
  key?: string;
}

export function ConnectedHierarchyTooltip(props: Props): React.CElement<SubscribeProps, Subscribe> {
  const getRenderProps = (container: HierarchyTooltipContainer): HTProps & { children?: any } => {
    // Forward some props
    const { entity, id, name, extraProps, children } = props;

    // Take some from the state container
    const state = container.state.state;
    const hierarchy = container.state.hierarchy;
    const loadHierarchy = container.loadHierarchy.bind(container);

    return {
      entity,
      id,
      name,
      extraProps,
      children,
      state,
      hierarchy,
      loadHierarchy,
    };
  };

  // Get the state container for this item
  const getContainer = (): HierarchyTooltipContainer => {
    if (!hierarchyContainerMap[props.entity].has(props.id)) {
      // Create new container if none exists yet
      const container = new HierarchyTooltipContainer(props.entity, props.id);
      hierarchyContainerMap[props.entity].set(props.id, container);

      return container;
    }

    return hierarchyContainerMap[props.entity].get(props.id);
  };

  const container = getContainer();

  // Return the stateless tooltip component wrapped in a Subscriber
  return React.createElement(Subscribe, {
    to: [container],
    children: (c: HierarchyTooltipContainer) => {
      // Get the render props using the state container
      const _props = getRenderProps(c);

      return React.createElement(HierarchyTooltip, _props);
    },
  });
}
