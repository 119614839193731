import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { DataModelGate } from '../../../../Auth/components';
import { TextInputP } from '../../../../BaseLayout/components/form/generic/TextInput';
import { WorkDTO } from '../../../../api/core';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';


const messages = defineMessages({
  dataVersion: { id: 'work.attr.dataVersion' },
  dataVersionHelp: { id: 'work.attr.dataVersion.help' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: string) => void;
  entity: string;
  className?: string;
  validation: ValidationResult;
}

export function DataVersion({modifiedModel, entity, updateField, className, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate always={false} entity={entity} field="data_version">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <TextInputP
        name={intl.formatMessage(messages.dataVersion)}
        help={intl.formatMessage(messages.dataVersionHelp)}
        classes={[className]}
        inputName="Data Version"
        permissions={checkPermission()}
        value={modifiedModel.dataVersion ? modifiedModel.dataVersion : ''}
        onChange={(dv: string): void => updateField('dataVersion', dv)}
        validation={validation}
      />
    )}
  </DataModelGate>;
}
