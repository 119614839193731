/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Share
 */
export interface Share {
    /**
     * 
     * @type {number}
     * @memberof Share
     */
    ownerId?: number;
    /**
     * 
     * @type {number}
     * @memberof Share
     */
    viewId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Share
     */
    sharedWith?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Share
     */
    sharedBy?: number;
}

export function ShareFromJSON(json: any): Share {
    return ShareFromJSONTyped(json, false);
}

export function ShareFromJSONTyped(json: any, ignoreDiscriminator: boolean): Share {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ownerId': !exists(json, 'ownerId') ? undefined : json['ownerId'],
        'viewId': !exists(json, 'viewId') ? undefined : json['viewId'],
        'sharedWith': !exists(json, 'sharedWith') ? undefined : json['sharedWith'],
        'sharedBy': !exists(json, 'sharedBy') ? undefined : json['sharedBy'],
    };
}

export function ShareToJSON(value?: Share | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ownerId': value.ownerId,
        'viewId': value.viewId,
        'sharedWith': value.sharedWith,
        'sharedBy': value.sharedBy,
    };
}


