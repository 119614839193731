import ReactTagsInput from 'react-tagsinput';
import React from 'react';
import { defaultRenderTag } from "./MultiAutocomplete";
import { ValidationResult } from '../../../Edit/validation/interfaces';

export interface Props {
    required: boolean;
    validation?: ValidationResult;
}

export const TagsInput = (props: ReactTagsInput.ReactTagsInputProps & Props): React.JSX.Element =>{

    const classes: string[] = props.className ? props.className.split(" ") : [];
    if (props.required){
      classes.push("required");
    }
    if(props.validation && !props.validation.valid) {
        classes.push("invalid");
    }

    return <ReactTagsInput
      {...props}
      inputProps={{...props.inputProps, className: classes.join(" ")}}
      className={classes.join(" ")}
      renderTag={props.renderTag ? props.renderTag : defaultRenderTag}
    />;
};
