import React, { useState } from 'react';
import { Pagination } from 'UserArea/profile/components/Publist/Pagination';
/** @jsx jsx */
import { jsx } from 'theme-ui';

interface PaginatedListProps<T> {
  list: T[];
  pageNumber?: number;
  pageSize: number;
  pageSizeOptions?: number[];
  children: (item: T, list: T[], pageIdx: number, listIdx: number) => JSX.Element;
}

export function PaginatedList<T>(props: PaginatedListProps<T>): JSX.Element {
  const { list, pageSizeOptions, children } = props;

  const [pageNumber, setPageNumber] = useState<number>(props.pageNumber ?? 0);
  const [pageSize, setPageSize] = useState<number>(props.pageSize);

  const firstPageNumber = 0;
  const lastPageNumber = Math.ceil(list.length / pageSize) - 1;

  if (pageNumber > lastPageNumber) setPageNumber(lastPageNumber);

  const page = list.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);

  return(
    <>
      <ul className="list"  sx={{ listStyleType: "none" }}>
      {page.map((item, pageIdx) => <li>{children(item, list, pageIdx, pageNumber * pageSize + pageIdx)}</li>)}
      </ul>
      <Pagination
        onPrevious={() => setPageNumber(pageNumber => pageNumber > firstPageNumber ? pageNumber - 1 : firstPageNumber)}
        onNext={() => setPageNumber(pageNumber => pageNumber < lastPageNumber ? pageNumber + 1 : lastPageNumber)}
        onFirst={() => setPageNumber(firstPageNumber)}
        onLast={() => setPageNumber(lastPageNumber)}
        first={pageNumber == firstPageNumber}
        last={pageNumber == lastPageNumber}
        page={pageNumber}
        totalEntries={list.length}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        onChangePageSize={(newPageSize) => setPageSize(newPageSize)}
      />
    </>
  );
}