import * as React from 'react';
import { injectIntl, WrappedComponentProps as IntlProps } from 'react-intl';
import { defineMessages } from 'react-intl';
import { getContextTitleWithValue } from '../../../data';
import { SearchContext } from '../../../types';

/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';

// Logger
import getLogger from '../../../log';
import { Input } from 'theme-ui';
import { useState } from 'react';

const logger = getLogger('Search/Searchbar');

const messages = defineMessages({ placeholder: { id: 'search.placeholder' } });

export interface Props {
  searchTerm: string;
  changedSearchTerm: string;
  topBarVisibility: boolean;
  searchContext: SearchContext;
  dropDownVisibility: boolean;
  setDropDownVisibility: (visible: boolean) => void;
  onSearchSubmit: (value: string) => void;
  onSearchContextSelect: (context: SearchContext) => void;
  setSearchTerm: (value: string) => void;
}

function InnerSearchbar (props: (Props & IntlProps)) {
  const {changedSearchTerm, topBarVisibility, searchContext, dropDownVisibility, setDropDownVisibility, onSearchSubmit, onSearchContextSelect, setSearchTerm} = props;

  const [term, setTerm] = useState(changedSearchTerm);

  const { intl } = props;

  let searchTerm = changedSearchTerm ? changedSearchTerm : props.searchTerm;
  const order = [SearchContext.WORKS, SearchContext.PEOPLE, SearchContext.ORGS, SearchContext.PROJECTS];

  const nextContext = (current: SearchContext): SearchContext => {
    const idx = order.indexOf(current);

    return order[(idx + 1) % order.length];
  };
  const prevContext = (current: SearchContext): SearchContext => {
    const idx = order.indexOf(current);

    return order[(idx - 1 + order.length) % order.length];
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    searchTerm = e.target.value;
    setSearchTerm(e.target.value);
  };

  const handleFocus = (e: React.FocusEvent) => {
    setDropDownVisibility(true);
    setSearchTerm(searchTerm);
  };

  const handleBlur = (e: React.FocusEvent) => {
    setDropDownVisibility(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    switch (e.key) {
      case 'ArrowUp': {
        const c = prevContext(searchContext);
        onSearchContextSelect(c);
        break;
      }
      case 'ArrowDown': {
        const c = nextContext(searchContext);
        onSearchContextSelect(c);
        break;
      }
      case 'Enter': {
        onSearchSubmit(searchTerm);
        // e.currentTarget.blur();
        // (document.activeElement as HTMLElement).blur();
        setDropDownVisibility(false);
        break;
      }
      default:
        if (dropDownVisibility == false) {
          setDropDownVisibility(true);
        }
        break;
    }
  };

  return (
    <Flex sx={{
      flexDirection: "column",
      flexGrow: 1,
      position: "relative"
    }}>
      <Flex sx={{
        alignItems: "center",
        i: {
          "&:hover": { cursor: 'pointer' }
        }
      }}>
        <Input
          variant="searchBar"
          type="text"
          placeholder={intl.formatMessage(messages.placeholder)}
          value={term}
          onChange={(e) => {
            setTerm(e.currentTarget.value);
            handleChange(e);
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
        <i
          className="icon-suche"
          sx={{
            ml: '-2.2em',
            fontSize: '1.4em',
          }}
          onClick={() => { onSearchSubmit(searchTerm); }}
        />
        <i className="icon-schliessen_weiss"
          sx={{
            "::before": { color: "primary" },
            fontSize: "1.6em",
            cursor: "pointer",
          }}
          onClick={() => {
            setTerm("");
            setSearchTerm("");
          }}
        />
      </Flex>
      {dropDownVisibility && <div>
        <ul sx={{
          listStyleType: "none",
          position: "absolute",
          zIndex: 100,
          width: "100%",
          cursor: "pointer",
          li: {
            p: 4,
            backgroundColor: "header",
            "&.active": {
              color: "secondary"
            }
          }
        }}>
          {[
            order.map((type) => (
              <li
                sx={{
                  p: 5,
                  alignItems: 'center',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  color: 'primary',
                  whiteSpace: 'nowrap',
                  fontSize: 3,
                }}
                key={type}
                className={
                  searchContext === type ||
                    (typeof searchContext === 'undefined' && searchContext === type)
                    ? 'active'
                    : ''
                }
                onMouseOver={() => {
                  if (searchContext === type) {
                    return;
                  }
                  onSearchContextSelect(type);
                }}
                onMouseDown={() => {
                  onSearchSubmit(searchTerm);
                  (document.activeElement as HTMLElement).blur();
                }}
              >
                {getContextTitleWithValue(
                  type,
                  typeof changedSearchTerm === 'undefined' || changedSearchTerm.length < 7
                    ? changedSearchTerm
                    : changedSearchTerm.slice(0, 7) + '...'
                )}
              </li>
            )),
          ]}
        </ul>
      </div>}
    </Flex>
  );
}

export const Searchbar = injectIntl(InnerSearchbar);
