import * as React from 'react';
import { Subscribe } from 'unstated';
import { OAuth2Container } from '../containers/OAuth2Container';

export interface Props {
  // Specific level of authentication
  isActive?: boolean;
  isIdle?: boolean;
  isNotAnonymous?: boolean;
  isAnonymous?: boolean;

  // Authorization
  anyRoles?: string[];

  // Render options
  renderError?: (error: any) => JSX.Element;
  throw?: boolean;
  children?: any;
}

/**
 * Conditionally render child nodes based on access policy (roles and authentication status).
 *
 * All requirements specified in props must be fulfilled (AND).
 * Failure can be rendered with `renderError` prop or thrown with `throw` prop.
 */
export function OAuth2Gate(props: Props): React.JSX.Element {
  const isActive = props.isActive ? props.isActive : false;
  const isIdle = props.isIdle ? props.isIdle : false;
  const isNotAnonymous = props.isNotAnonymous ? props.isNotAnonymous : false;
  const isAnonymous = props.isAnonymous ? props.isAnonymous : false;
  const anyRoles = props.anyRoles;

  const fail = (error: string) => {
    if (undefined !== props.renderError) {
      return props.renderError(error);
    }
    if (props.throw) {
      throw new Error(error);
    }
    return <></>;
  };

  return (
    <Subscribe to={[OAuth2Container]}>
      {(container: OAuth2Container) => {
        const a = container.authorizer();
        const must = (assertion: boolean, message: string = '') => {
          if (!assertion) {
            throw new Error(message);
          }
        };
        try {
          if (isActive) {
            must(a.isActive());
          }
          if (isIdle) {
            must(a.isIdle());
          }
          if (isNotAnonymous) {
            must(a.isNotAnonymous());
          }
          if (isAnonymous) {
            must(a.isAnonymous());
          }

          if (undefined !== anyRoles) {
            must(a.hasAnyOfRoles(anyRoles), 'Roles are missing');
          }
        } catch (ex) {
          return fail(String(ex));
        }

        return props.children;
      }}
    </Subscribe>
  );
}
