import equal from 'deep-equal';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useStatusCode } from 'Utils/StatusCodeHandler';
import { SearchApi } from '../../api/core/apis/SearchApi';
import { SearchRequestOnlyEnum } from '../../api/core/models';
import { connectOAuth2, OAuth2AwareProps } from '../../Auth/utils/connect';
import { Props as DashboardProps } from '../components/Dashboard';
import { Dashboard } from '../components/Dashboard';
import {
  DashboardType,
  defaultSearchQuery,
  FacetEntry,
  SearchFacet,
  SearchFacetValueMap,
  SearchQuery,
  SearchResultType,
  SearchSort,
} from '../types';
import { mapApiFacets, mapFacetEntriesToQuery } from '../utils';

// Logger
// import getLogger from '../../log';
// const logger = getLogger('Dashboard/container');

export interface Props {
  entity: DashboardType;

  // Search Params
  searchQuery: Partial<SearchQuery>;
  extraSearchQuery?: Partial<SearchQuery>; // Override search term
  initialSearchTerm?: string; // Set initial search term

  initialSearchResultOffset?: number;
  initialSearchResultLimit?: number;

  onSearchQueryUpdate?: (value: SearchQuery, initial: boolean) => void;
  onDidMount?: () => void;
  embedded: boolean;
  hideSidebar?: boolean;
  personOrg?: any;

  key?: DashboardType;
}

type InnerProps = OAuth2AwareProps<Props>;

interface RequestInfo {
  time: number;
  query: SearchQuery;
  facetOffsets: SearchFacetValueMap<number>;
  withResults: boolean;
  withFacets: boolean;
}

/**
 * Factory for a connected dashboard with a desired entity type
 *
 * @param entity The desired entity type
 */
function ConnectedInnerDashboard(props: InnerProps) {
  const [error, setError] = useState<string|null>(null);
  const [resultsLoading, setResultsLoading] = useState<boolean>(false);
  const [results, setResults] = useState<SearchResultType[]|null>(null);
  const [resultCount, setResultCount] = useState<number>(0);
  const [lastRequest, setLastRequest] = useState<RequestInfo|null>(null);
  const [facetsLoading, setFacetsLoading] = useState<boolean>(false);
  const [availableFacetEntries, setAvailableFacetEntries] = useState<SearchFacet[]>([]);
  const [facetOffsets, setFacetOffsets] = useState<SearchFacetValueMap<number>>({});
  const searchQuery = props.searchQuery ? props.searchQuery : {};
  const hideSidebar = props.hideSidebar ? props.hideSidebar : false;
  const api = props.apiWithAuth(new SearchApi());
  const { setStatusCode } = useStatusCode();

  useEffect(()=>{
    if (undefined !== props.onDidMount) {
      props.onDidMount();
    }

    // On first mount, update search query
    if (undefined !== props.onSearchQueryUpdate) {
      props.onSearchQueryUpdate(getSearchQuery(), true);
    }
  },[]);

  useEffect(() => {
    submitSearchIfNeeded();
  });

  const mergeSearchQuery = (o: SearchQuery) => {
    const { extraSearchQuery: e } = props;
    if (!e) {
      return o;
    }

    const f = {};
    for (const k in o.f) {
      if (!o.f.hasOwnProperty(k)) {
        continue;
      }
      f[k] = [...o.f[k]];
    }
    if (undefined !== e.f) {
      for (const k in e.f) {
        if (!e.f.hasOwnProperty(k)) {
          continue;
        }
        const oldFK = undefined !== f[k] ? f[k] : [];
        f[k] = [...oldFK, ...e.f[k]];
      }
    }

    const newQuery: SearchQuery = {
      q: undefined !== e.q ? e.q : o.q,
      offset: undefined !== e.offset ? e.offset : o.offset,
      limit: undefined !== e.limit ? e.limit : o.limit,
      sort: undefined !== e.sort ? e.sort : o.sort,
      f,
    };
    return newQuery;
  };

  const getRenderProps = (): DashboardProps => {
    const { entity } = props;
    const query = mergeSearchQuery(getSearchQuery());

    return {
      dashboardType: entity,
      // Search Params - forwarded from props
      searchTerm: query.q,
      searchResultOffset: query.offset,
      searchResultLimit: query.limit,
      searchResultSort: query.sort
        ? query.sort
        : entity === DashboardType.WORKS
        ? { direction: 'desc', field: 'f_issued' }
        : undefined,
      searchOnMount: false, // TODO: Remove from child component

      // Search Param Actions
      onSearchTermUpdate: boundUpdateSearchTerm,
      onSearchResultOffsetUpdate: boundUpdateSearchResultOffset,
      onSearchResultLimitUpdate: boundUpdateSearchResultLimit,
      onSearchResultSortUpdate: boundUpdateSearchResultSort,

      // Search Results Data
      searchResults: null !== results ? results : [],
      searchResultCount: resultCount,
      searchResultsLoading: resultsLoading,
      searchResultsError: error,

      // Facets Data
      facetsLoading,
      availableFacetEntries,
      activeFacetEntries: query.f,
      facetOffsets,

      // Facets Actions
      onFacetEntriesSet: boundSetFacetEntries,
      onFacetOffsetUpdate: boundUpdateFacetOffset,

      // Events
      onDidMount: () => {},
      onWillUnmount: () => {},
      onRefresh: () => {
        submitSearch(true, true);
      },
      embedded: props.embedded,
      hideSidebar,
      personOrg: props.personOrg
    };
  };

  const getSearchQuery = (): SearchQuery => {
    return {
      ...defaultSearchQuery,
      ...searchQuery,
    };
  };

  const getMergedSearchQuery = (): SearchQuery => {
    return mergeSearchQuery(getSearchQuery());
  };

  const boundUpdateSearchTerm = (value: string) => {
    if (undefined !== props.onSearchQueryUpdate) {
      const searchQuery = { ...getSearchQuery(), q: value };

      props.onSearchQueryUpdate(searchQuery, false);
    }
  };

  const boundUpdateSearchResultOffset = (value: number) => {
    if (undefined !== props.onSearchQueryUpdate) {
      const oldQuery = getSearchQuery();

      const limit = oldQuery.limit;
      const offset = Math.floor(value / limit) * limit;

      const searchQuery = { ...oldQuery, offset };
      props.onSearchQueryUpdate(searchQuery, false);
    }
  };

  const boundUpdateSearchResultLimit = (value: number) => {
    if (undefined !== props.onSearchQueryUpdate) {
      const oldQuery = getSearchQuery();

      const oldOffset = oldQuery.offset;
      const offset = Math.floor(oldOffset / value) * value;
      const searchQuery = { ...oldQuery, limit: value, offset };
      props.onSearchQueryUpdate(searchQuery, false);
    }
  };

  const boundUpdateSearchResultSort = (sort?: SearchSort) => {
    if (undefined !== props.onSearchQueryUpdate) {
      const oldQuery = getSearchQuery();

      const searchQuery = { ...oldQuery, sort, offset: 0 };
      props.onSearchQueryUpdate(searchQuery, false);
    }
  };

  const boundSetFacetEntries = (facet: string, entries: FacetEntry[]) => {
    if (undefined !== props.onSearchQueryUpdate) {
      const oldQuery = getSearchQuery();

      const f = { ...oldQuery.f, [facet]: entries };

      const searchQuery = {
        ...oldQuery,
        f,
        offset: 0, // Reset results offset
      };
      props.onSearchQueryUpdate(searchQuery, false);
      setFacetOffsets({}); // Reset all facet offsets
    }
  };

  const boundUpdateFacetOffset = (facet: string, value: number) => {
    setFacetOffsets({...facetOffsets, [facet]: value});
  };

  const shouldSubmitSearch = (): { results: boolean; facets: boolean } => {
    if (null === lastRequest) {
      // No request has been fired yet
      return { results: true, facets: true };
    }

    const oldQuery = lastRequest.query;
    const newQuery = getMergedSearchQuery();
    const oldFoffset = lastRequest.facetOffsets;
    const newFoffset = facetOffsets;

    let results = false;
    let facets = false;

    if (newQuery !== oldQuery) {
      // NOTE: Shallow reference comparison
      if (newQuery.q !== oldQuery.q) {
        // Search term changed, submit full search
        return { results: true, facets: true };
      }
      if (!equal(newQuery.f, oldQuery.f, { strict: true })) {
        // Active facets changed, submit full search
        return { results: true, facets: true };
      }
      if (newQuery.offset !== oldQuery.offset || newQuery.limit !== oldQuery.limit) {
        // Result offset or limit changed, refresh results
        results = true;
      }
      if (newQuery.sort !== oldQuery.sort) {
        // Result sortField or sortDirection changed, refresh results
        if (!!newQuery.sort && !!oldQuery.sort) {
          if (newQuery.sort.field !== oldQuery.sort.field || newQuery.sort.direction !== oldQuery.sort.direction) {
            results = true;
          }
        } else {
          results = true;
        }
      }
    }

    if (newFoffset !== oldFoffset) {
      // NOTE: Shallow reference comparison
      if (!equal(newFoffset, oldFoffset, { strict: true })) {
        // Deep comparison
        // Facet offsets changed, refresh facets
        facets = true;
      }
    }

    return { results, facets };
  };

  const submitSearchIfNeeded = async () => {
    const { results, facets } = shouldSubmitSearch();
    await submitSearch(results, facets);
  };

  const submitSearch = async (withResults: boolean, withFacets: boolean) => {
    // const _logger = logger.find('submitSearch');
    if (!withResults && !withFacets) {
      return;
    }

    const { entity } = props;
    const query = getMergedSearchQuery();
    const time = Date.now();
    const info: RequestInfo = {
      time,
      query,
      facetOffsets,
      withResults,
      withFacets,
    };
    // _logger.debug('Request', info);

    searchLoading(info);

    const only: SearchRequestOnlyEnum[] = withResults && withFacets ? undefined : [];
    if (!withResults || !withFacets) {
      if (withResults) {
        only.push(SearchRequestOnlyEnum.Results);
      }
      if (withFacets) {
        only.push(SearchRequestOnlyEnum.Facets);
      }
    }

    const request = {
      q: query.q,
      offset: Math.floor(query.offset / query.limit) * query.limit,
      limit: query.limit,
      sort: query.sort,
      only,
      fq: undefined,
      foffset: facetOffsets,
    };

    const fq = mapFacetEntriesToQuery(query.f);
    if (fq.length > 0) {
      request.fq = fq;
    }

    try {
      // Load and await response
      const response = await ((req) => {
        req.q = req.q === undefined ? '' : req.q;
        req.fq = req.fq === undefined ? [] : req.fq;
        req.sort = req.sort && req.sort.field === '' ? undefined : req.sort;

        switch (entity) {
          case DashboardType.WORKS:
            /* Default sorting */
            req.sort =
              (req.sort === null || req.sort === undefined)
                ? {
                    field: 'f_issued',
                    direction: 'desc',
                  }
                : req.sort;
            return api.searchWork({ searchRequest: req });
          case DashboardType.PEOPLE:
            return api.searchPerson({ searchRequest: req });
          case DashboardType.ORGS:
            return api.searchOrganisation({ searchRequest: req });
          case DashboardType.PROJECTS:
            return api.searchProject({ searchRequest: req });
          default:
            throw new Error(`Illegal entity type ${entity}`);
        }
      })(request);

      const facets: SearchFacet[] = mapApiFacets(entity, response.facets, response.numberResults);

      searchSuccess(info, response.results, response.numberResults, facets);
    } catch (error) {
      setStatusCode({status: error.status});
      if (error instanceof TypeError) {
        searchFailed(info, error.message);
      } else if (typeof error === 'string') {
        searchFailed(info, error);
      } else {
        searchFailed(info, 'Unknown error');
      }
    }
  };

  // Update state when the search request is starting
  const searchLoading = (info: RequestInfo) => {
    setResultsLoading(info.withResults);
    setFacetsLoading(info.withFacets);
    setError(null);
    setLastRequest(info);
  };

  // Update sate when the search request failed
  const searchFailed = (info: RequestInfo, error: string) => {
    if (null !== lastRequest && info.time < lastRequest.time) {
      setResultsLoading(null);
      setFacetsLoading(null);
      setError(null);
    }
    else {
      setResultsLoading(info.withResults ? false : resultsLoading);
      setFacetsLoading(info.withFacets ? false : facetsLoading);
      setError(error);
    }
  };

  // Update state when the search request succeeded
  const searchSuccess = (
    info: RequestInfo,
    _results: SearchResultType[],
    _resultCount: number,
    _availableFacetEntries: SearchFacet[]
  ) => {
    if (null !== lastRequest && info.time < lastRequest.time) {
      setResultsLoading(null);
      setResults(null);
      setResultCount(null);
      setFacetsLoading(null);
      setAvailableFacetEntries(null);
    }
    else {
      setError(null);
      setResultsLoading(info.withResults ? false : null);
      setResults(info.withResults ? _results : results);
      setResultCount(info.withResults ? _resultCount : resultCount);
      setFacetsLoading(info.withFacets ? false : null);
      setAvailableFacetEntries(info.withFacets ? _availableFacetEntries : availableFacetEntries);
    }
  };

  // Render the actual Dashboard
  const _props = getRenderProps();

  return React.createElement(Dashboard, _props);
}

export const ConnectedDashboard = connectOAuth2(ConnectedInnerDashboard);

export default ConnectedDashboard;
