import React from 'react';
import { ProjectInstitutionalIdDTO, OrganisationInstitutionalIdDTO, PersonInstitutionalIdDTO } from '../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from '../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connectOAuth2 } from '../../../Auth/utils/connect';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultInstitutionalId } from '../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../BaseLayout/components/form/MultiInput/Buttons';
import { Flex } from '@theme-ui/components';
import { Styled } from 'theme-ui';
import { ValidationResult } from '../../validation/interfaces';

const messages = defineMessages({
  institution: { id: 'entity.attr.institutionalId.attr.institution' },
  value: { id: 'entity.attr.institutionalId.attr.value' },
});

export type InstitutionalId = ProjectInstitutionalIdDTO | OrganisationInstitutionalIdDTO | PersonInstitutionalIdDTO;

interface InstitutionalIdsProps<T> {
  checkPermission?: CheckPermissionFunction;
  institutionalIds: T[];
  onChange: (subject: T[]) => void;
  pubtypeGroup?: string;
  typesList: string;
  validation?: ValidationResult;
}

export function InnerInstitutionalIds<T>({
  checkPermission,
  institutionalIds,
  onChange,
  pubtypeGroup,
  typesList,
  intl,
  validation
}: InstitutionalIdsProps<T> & WrappedComponentProps): React.JSX.Element {
  return (
    <div className="form form-highlight">
      <Styled.h2>
        <FormattedMessage id="entity.attr.institutionalId" />
      </Styled.h2>
      <MultiInputControls<InstitutionalId>
        lines={institutionalIds}
        defaultRow={DefaultInstitutionalId}
        onChange={onChange}
        remove
        renderLines={(iId: InstitutionalId, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number) => {
          return (
            <div>
              <Flex variant="layout.multiRow">
                <SelectInputP
                  permissions={checkPermission('institution')}
                  onChange={(institution: string) => onChange2({ ...iId, institution })}
                  value={iId.institution}
                  name={intl.formatMessage(messages.institution)}
                  inputName={'institution'}
                  list={typesList}
                  listPartition={pubtypeGroup}
                  validation={validation?.children[idx]?.institution}
                  autoFocus={true}
                />
                <TextInputP
                  permissions={checkPermission('value')}
                  value={iId.value}
                  name={intl.formatMessage(messages.value)}
                  onChange={(value: string) => {
                    onChange2({
                      ...iId,
                      value,
                    });
                  }}
                  validation={validation?.children[idx]?.value}
                />
              </Flex>
              {!!actions.delete && (
                <div className='deleteButton'>
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          );
        }}
        renderEnd={(actionAdd) => (
          <div className='addButton'>
            <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
          </div>
        )}
      />
    </div>
  );
}

export const InstitutionalIds = connectOAuth2(injectIntl(InnerInstitutionalIds));
