import * as React from 'react';
import { Subscribe } from 'unstated';
import { Middleware, RequestContext, ResponseContext } from '../../api/core/runtime';

import { OAuth2Container } from '../containers/OAuth2Container';

interface API {
  withMiddleware(...middlewares: Middleware[]): this;
}
interface OAuth2Props {
  /** Add authentication middleware to an API */
  apiWithAuth?: <T extends API>(api: T) => T;
}

export type OAuth2AwareProps<Props> = Props & OAuth2Props;

const setHeader = (headers: HeadersInit, key: string, value: string): Headers => {
  const newHeaders = new Headers(headers);
  newHeaders.set(key, value);
  return newHeaders;
};

const oauth2ApiMiddleware = (c: OAuth2Container) => ({
  pre: async (ctx: RequestContext): Promise<RequestContext> => {
    const token = await c.getAuthTokenOrRefresh(false);
    if (!!token) {
      ctx.init.headers = setHeader(ctx.init.headers || null, 'Authorization', token);
    }
    return ctx;
  },
  post: async ({ fetch, url, init, response }: ResponseContext): Promise<Response> => {
    if (response.status === 401) {
      const resJson = await response.json().catch((_): null => null);
      if (!resJson || !resJson.error || resJson.error === 'invalid_token') {
        // The access_token probably expired, try again once with a new one
        const token = await c.getAuthTokenOrRefresh(false);
        if (!!token) {
          init.headers = setHeader(init.headers || null, 'Authorization', token);
          return await fetch(url, init);
        }
      }
    }
    return response;
  },
});

/**
 * Connect a component to OAuth2Container to provide the API access token.
 * The wrapped component receives the `apiWithAuth` prop to enrich an API with authentication middleware.
 *
 * @param Component The component to connect
 */
export const connectOAuth2 = <Props>(Component: React.ComponentType<OAuth2AwareProps<Props>>): React.FunctionComponent<Props> => {
  const OAuth2AwareComponent: React.FunctionComponent<Props> = (props: Props) => {
    const children = (c: OAuth2Container) =>
      React.createElement(Component, {
        ...props,
        apiWithAuth: (api) => api.withMiddleware(oauth2ApiMiddleware(c)),
      });

    return React.createElement(Subscribe, {
      to: [OAuth2Container],
      children,
    });
  };

  return OAuth2AwareComponent;
};
