import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { WorkDTO } from 'api/core/models/';
import { OAuth2Gate } from 'Auth/components/OAuth2Gate';
import { DuplicatePopup } from '../DuplicatePopup';
import { Button, Link } from '@theme-ui/components';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface ToolbarProps {
  work: WorkDTO;
  open: boolean;
  citePopupOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCitePopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteInit: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteRequest: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Toolbar: React.FC<ToolbarProps> = (props) => {
  const {
    work,
    setOpen,
    citePopupOpen,
    setCitePopupOpen,
    setDeleteInit,
    setDeleteRequest
  } = props;

  return (
    <div className="head-toolbar" sx={{
      mb: '3em',
      ":first-child": {
        ml: '0em'
      }
    }}>
      <Button onClick={() => setOpen(open_ => !open_)}>
        <FormattedMessage id="button.Details" />
      </Button>
      <Button onClick={() => setCitePopupOpen(!citePopupOpen)}>
        <FormattedMessage id="button.Cite" />
      </Button>
      <div className="edit" sx={{float: 'right'}}>
        {work.canEdit && (
          <Link href={`/work/${work.id}/edit`}>
            <Button><FormattedMessage id="button.Edit" /></Button>
          </Link>
        )}

        <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
          <Button
            onClick={(e: React.FormEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setDeleteInit(true);
              setDeleteRequest(false);
            }}
          >
            <FormattedMessage id="button.Delete" />
          </Button>
        </OAuth2Gate>

        <OAuth2Gate anyRoles={['ROLE_USER']}>
          {work.canDelete && (
            <Button
              onClick={(e: React.FormEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setDeleteInit(true);
                setDeleteRequest(false);
              }}
            >
              <FormattedMessage id="button.Delete" />
            </Button>
          )}
          {work.canRequestDelete && (
            <Button
              onClick={(e: React.FormEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setDeleteInit(true);
                setDeleteRequest(true);
              }}
            >
              <FormattedMessage id="button.DeleteRequest" />
            </Button>
          )}
        </OAuth2Gate>

        <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
          {work.adminDataWork.editorialStatus === 'new' ? (
            <Button
              onClick={(e: React.FormEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setDeleteInit(true);
                setDeleteRequest(false);
              }}
            >
              <FormattedMessage id="button.Delete" />
            </Button>
          ) : (
            <Button
              onClick={(e: React.FormEvent<HTMLButtonElement>) => {
                e.preventDefault();
                setDeleteInit(true);
                setDeleteRequest(true);
              }}
            >
              <FormattedMessage id="button.DeleteRequest" />
            </Button>
          )}
        </OAuth2Gate>

        <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
          {/* TODO/FIXME: ich weiß, dass window reload nicht toll ist, allerdings habe ich keine
          Ahnung, wie man bei einer Klassenkomponente das Neuladen der Daten triggert. */}
          <DuplicatePopup unicumId={work.id} onSuccess={() => {window.location.reload();}}/>
        </OAuth2Gate>
      </div>
    </div>
  );
};

export default Toolbar;