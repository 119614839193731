import React from 'react';
import { Label, Checkbox as TCheckbox } from 'theme-ui';
import { ReadWritePermissions } from '../../../../Auth/components/DataModelGate';
import { isRequired } from './utils';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../../Edit/validation/interfaces';
import { InputLabel } from './InputLabel';
import { IntlShape, useIntl } from 'react-intl';

interface Props {
  checked: boolean;
  value?: string;
  onChange: (value: boolean) => void;
  name?: string;
  help?: string;
  inputName?: string;
  disabled?: boolean;
  required?: boolean;
  validation?: ValidationResult;
  classes?: string[];
}

export function Checkbox({ checked, help, value, classes, onChange, name, inputName, disabled, required, validation }: Props) {
  const intl: IntlShape = useIntl();
  if(!validation) validation = {valid: true};
  if(required && !checked) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }
  classes = classes ? classes : [];
  if (required){
    classes.push("required");
  }
  if(validation && !validation.valid) {
    classes.push("invalid");
  }
  return (
    <Label variant="labelX" sx={{ width: 'max-content', display: 'flex', ".helper": { p: 0}  }} className={classes.join(" ")}>
      <TCheckbox
        name={inputName ?? undefined}
        value={value}
        checked={checked}
        className={classes.join(" ")}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          onChange(e.currentTarget.checked);
        }}
        disabled={disabled ?? false}
        required={required ?? false}
        sx={{ pointerEvents: 'initial' }}
      />
      {help ?
        <InputLabel
          classes={["helper"]}
          variant="labelY"
          label={name}
          help={help}
        />
        :
        <div>{name || ''}</div>
      }
    </Label>
  );
}

interface PropsP extends Props {
  permissions: ReadWritePermissions;
}

/**
 * Textarea mit integrierter Lese- und Schreibberechtigungsprüfung
 *
 * @param props
 * @constructor
 */
export function CheckboxP(props: PropsP) {
  if (!props.permissions.read) {
    return <></>;
  }
  return (
    <Checkbox
      {...props}
      disabled={!props.permissions.write || props.disabled}
      name={props.name}
      required={isRequired(props.permissions)}
    />
  );
}
