import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Props } from 'HomePage/pages/Homepage';

export const HomepageMobileStyling: React.FC = ({ children }) => {
  return (
    <div
      sx={{
        ".banner": {
            height: '18em'
        },
        h1: {
           textAlign: 'center',
           color: 'primary',
           fontSize: '28px',
           fontWeight: 'bold',
           mt: '1em',
           px: '21px'
        },
        ".search": {
            justifyContent: 'center',
            width: '100%',
            fontSize: '1.4em',
            paddingTop: '35px',
            alignItems: 'center',
            pb: '0.75em',
            input: {
                width: '100%',
                borderStyle: 'solid',
                borderColor: 'light',
                height: '30px',
                mx: '21px',
                fontSize: '16px',
                borderWidth: '1.5px'
            },
            i: {
                mr: '32px',
                ml: '-50px',
                fontSize: '20px',
            }
        },
        ".leftColumn": {
            p: '21px'
        },
        ".rightColumn": {
            px: '21px',
            pb: '42px'
        },
        ".image": {
            textAlign: 'center',
            pt: '2em',
            pb: '1.25em',
            img: {
                width: '60%',
            },
        },
        ".text": {
            px: '21px',
            h2: {
                fontSize: '1em',
                fontWeight: 'bold',
                color: 'headline'
            },
            a: {
                variant: 'styles.a'
            },
            ".button": {
                variant:"buttons.primary",
                fontSize: '14px',
                fontWeight: 'bold',
                px: '15px',
                py: '5px',
                mt: '2em',
                mb: '2.5em'
            }
        }
      }}
    >
        {children}
    </div>
  );
};

export const HomepageDesktopStyling: React.FC = (props: {props: Props; children: any}) => {
    return (
      <div
        sx={{
            ".banner": {
                height: props.props.breakpoint <= 3 ? '23em' : '',
                input: {
                    height: props.props.breakpoint <= 3 ? '40px' : ''
                },
            },
            h1: {
                fontSize: props.props.breakpoint <= 3 ? '35px !important' : ''
            },
            ".search": {
                justifyContent: 'center',
                width: '100%',
                fontSize: '1.4em',
                paddingTop: '2em',
                alignItems: 'center',
                input: {
                    width: '800px',
                },
                i: {
                    ":hover": { cursor: 'pointer' }
                },
                ".icon-suche": {
                    ml: '-2.2em',
                    fontSize: props.props.breakpoint <= 3 ? '23px' : '1.4em',
                },
                ".icon-schliessen_weiss": {
                    "::before": { color: "primary" },
                fontSize: "1.4em",
                cursor: "pointer",
                }
            },
            ".card-grid": {
                maxWidth: '1400px',
                mx: 'auto',
                fontSize: props.props.breakpoint <= 3 ? '18px' : 3,
                div: {
                px: props.props.breakpoint <= 3 ? '60px' : 12,
                py: props.props.breakpoint <= 3 ? '35px' : 9,
                },
                img: {
                px: 10,
                width: '100%',
                },
                a: {
                    variant: 'styles.a'
                },
                ".button": {
                    variant:"buttons.primary",
                }
            }
        }}
      >
          {props.children}
      </div>
    );
  };