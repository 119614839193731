import * as React from 'react';
import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { PatchDocumentFromJSON, ReferencedPersonNameDTO } from '../../api/core/models';
import { connectOAuth2, OAuth2AwareProps } from 'Auth/utils/connect';
import { PersonApi } from '../../api/core/apis';
import { DeletePostNotification } from './DeletePostNotification';
import Popup from 'BaseLayout/components/popup/Popup';

import { useStatusCode } from 'Utils/StatusCodeHandler';
import { handleStatusCode422 } from 'Utils/StatusCodeUtils';
import { ApiResponse } from '../../api/vocabulary';

const messages = defineMessages({
  deleteRequestSuccess: { id: 'person.deleteRequest.success'},
  deleteSuccess: { id: 'person.delete.success'},
});

export interface DeletePersonProps {
  personId: number;
  isDeleteRequest?: boolean;
  onAbort?: () => void;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

type InnerProps = OAuth2AwareProps<DeletePersonProps>;

const InnerDeletePerson = (props: InnerProps): JSX.Element => {
  const intl: IntlShape = useIntl();
  const [exclusiveWorks, setExclusiveWorks] = useState<number[]>(null);
  const [deleteInit, setDeleteInit] = useState<boolean>(true);
  const [deleteDone, setDeleteDone] = useState<boolean>(false);
  const [personName, setPersonName] = useState<ReferencedPersonNameDTO>(null);
  const personApi = props.apiWithAuth(new PersonApi());
  const { setStatusCode } = useStatusCode();

  useEffect((): void => {
    personApi
      .getPersonNames({ personId: props.personId })
      .then((personNames: ReferencedPersonNameDTO[]): void => setPersonName(personNames.find(pName => pName.isMain)));
  }, []);

  const submitDelete = async (): Promise<void> => {
    if (!!props.isDeleteRequest) {
      try {
        await personApi.updatePerson({
          personId: props.personId,
          patchDocument: [PatchDocumentFromJSON({
            op: 'replace',
            path: '/adminDataPerson/editorialStatus',
            value: 'deleted',
          })]
        });
        setDeleteInit(false);
        props.onSuccess();
      } catch (error) {
        props.onError(error);
      }
    } else {
      try {
        const exclWorks: number[] = await personApi.getExclusiveWorksOfPerson({ personId: props.personId });
        setExclusiveWorks(exclWorks);
        try {
          const response: ApiResponse<void> = await personApi.deletePersonRaw({ personId: props.personId });
          await setStatusCode({status: response.raw.status, action: 'delete', data: {entity: 'people', name: personName.givenname + ' ' + personName.surname, id: props.personId}});
          setDeleteInit(false);
          setDeleteDone(true);
          // If there is no exclusive works we dont need to show a post notification
          // and call the success callback function here
          if (!exclusiveWorks || exclusiveWorks.length < 1) props.onSuccess();
        } catch (error) {
          if (error.status === 422) return handleStatusCode422(error, setStatusCode);
          setStatusCode({status: error.status});
        }
      } catch (error) {
        props.onError(error);
      }
    }
  };

  const title: string = props.isDeleteRequest ?
    intl.formatMessage({ id: 'person.deleteRequest.popup.title' }) :
    intl.formatMessage({ id: 'person.delete.popup.title' });

  return (
    <>
    {deleteInit &&
    <Popup
      open={true}
      title={title}
      onClose={() => props.onAbort()}
      buttons={[
        {
          label: intl.formatMessage({ id: 'popup.button.confirm' }),
          onClick: submitDelete
        },
        {
          label: intl.formatMessage({ id: 'popup.button.abort' }),
          onClick: props.onAbort
        }
      ]}
    >
      {props.isDeleteRequest &&
      <FormattedMessage
        id="person.deleteRequest.popup.message(surname, givenname)"
        values={{
          surname: personName?.surname,
          givenname: personName?.givenname
        }}
      />
      }
      {!props.isDeleteRequest &&
      <FormattedMessage
        id="person.delete.popup.message(surname, givenname)"
        values={{
          surname: personName?.surname,
          givenname: personName?.givenname
        }}
      />
      }
    </Popup>
    }
    {deleteDone && exclusiveWorks && exclusiveWorks.length > 0 && (
      <DeletePostNotification
        headline={props.isDeleteRequest ? intl.formatMessage(messages.deleteRequestSuccess) : intl.formatMessage(messages.deleteSuccess)}
        exclusiveWorks={exclusiveWorks}
        onClose={() => {
          setDeleteDone(false);
          // For the lack of finding a better solution:
          // The onSuccess callback function gets called when the user closes the notification window, even though the work got deleted beforehand
          props.onSuccess();
        }}
      />
    )}
    </>
  );
};

export const DeletePerson = connectOAuth2(InnerDeletePerson);

export default DeletePerson;
