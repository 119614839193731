import { connectOAuth2 } from 'Auth/utils/connect';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import React, { ReactElement } from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { ProjectOtherPartnerDTO } from '../../../api/core/models';
import { MultiInputControls } from '../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { DefaultProjectOtherPartner } from '../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../BaseLayout/components/form/MultiInput/Buttons';
import { CheckPermissionFunction } from '../../../Auth/components/DataModelGate';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../validation/interfaces';

const messages = defineMessages({
  otherPartner: { id: 'project.attr.projectOtherPartner.attr.otherPartner' }
});

interface Props {
  otherPartners: ProjectOtherPartnerDTO[];
  onChange: (op: ProjectOtherPartnerDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

export function InnerOtherPartners({ otherPartners, onChange, checkPermission, intl, validation }: Props & WrappedComponentProps): React.JSX.Element {
  return (
    <MultiInputControls<ProjectOtherPartnerDTO>
      lines={otherPartners ? otherPartners : []}
      onChange={onChange}
      add
      defaultRow={DefaultProjectOtherPartner}
      remove
      renderLines={(gop: ProjectOtherPartnerDTO, onChange2: (value) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
        <div className="fieldRow">
          <TextInputP
            value={gop.otherPartner}
            name={intl.formatMessage(messages.otherPartner)}
            permissions={checkPermission('other_partner')}
            onChange={(otherPartner: string): void => {
              onChange2({ ...gop, otherPartner });
            }}
            validation={validation?.children[idx]?.other_partner}
            autoFocus={true}
          />
          {!!actions.delete && (
            <div className='deleteButton'>
              <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
            </div>
          )}
        </div>
      )}
      renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
        <div className='addButton'>
          <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
        </div>
      )}
    />
  );
}

export const OtherPartners = connectOAuth2(injectIntl(InnerOtherPartners));
