/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PublisherDTO,
    PublisherDTOFromJSON,
    PublisherDTOFromJSONTyped,
    PublisherDTOToJSON,
    WorkPublisherPlaceDTO,
    WorkPublisherPlaceDTOFromJSON,
    WorkPublisherPlaceDTOFromJSONTyped,
    WorkPublisherPlaceDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkPublisherDTO
 */
export interface WorkPublisherDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkPublisherDTO
     */
    id?: number | null;
    /**
     * 
     * @type {PublisherDTO}
     * @memberof WorkPublisherDTO
     */
    publisher?: PublisherDTO;
    /**
     * 
     * @type {Array<WorkPublisherPlaceDTO>}
     * @memberof WorkPublisherDTO
     */
    workPublisherPlace?: Array<WorkPublisherPlaceDTO>;
}

export function WorkPublisherDTOFromJSON(json: any): WorkPublisherDTO {
    return WorkPublisherDTOFromJSONTyped(json, false);
}

export function WorkPublisherDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkPublisherDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'publisher': !exists(json, 'publisher') ? undefined : PublisherDTOFromJSON(json['publisher']),
        'workPublisherPlace': !exists(json, 'workPublisherPlace') ? undefined : ((json['workPublisherPlace'] as Array<any>).map(WorkPublisherPlaceDTOFromJSON)),
    };
}

export function WorkPublisherDTOToJSON(value?: WorkPublisherDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'publisher': PublisherDTOToJSON(value.publisher),
        'workPublisherPlace': value.workPublisherPlace === undefined ? undefined : ((value.workPublisherPlace as Array<any>).map(WorkPublisherPlaceDTOToJSON)),
    };
}


