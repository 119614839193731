/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PubListConfig,
    PubListConfigFromJSON,
    PubListConfigToJSON,
} from '../models';

export interface DeletePublicationListRequest {
    apiKey: string;
}

export interface DeletePublicationListsRequest {
    apiKeys: Array<string>;
}

export interface GetPublicationListRequest {
    apiKey: string;
}

export interface GetSingleEntryRequest {
    workId: number;
    style?: string;
    locale?: string;
}

export interface PreviewRequest {
    pubListConfig: PubListConfig;
}

export interface StoreRequest {
    apiKey: string;
    pubListConfig: PubListConfig;
}

export interface StoreNewRequest {
    pubListConfig: PubListConfig;
}

/**
 * 
 */
export class PublistServiceApi extends runtime.BaseAPI {

    /**
     */
    async deletePublicationListRaw(requestParameters: DeletePublicationListRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.apiKey === null || requestParameters.apiKey === undefined) {
            throw new runtime.RequiredError('apiKey','Required parameter requestParameters.apiKey was null or undefined when calling deletePublicationList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publist/{apiKey}`.replace(`{${"apiKey"}}`, encodeURIComponent(String(requestParameters.apiKey))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePublicationList(requestParameters: DeletePublicationListRequest): Promise<void> {
        await this.deletePublicationListRaw(requestParameters);
    }

    /**
     */
    async deletePublicationListsRaw(requestParameters: DeletePublicationListsRequest): Promise<runtime.ApiResponse<{ [key: string]: Array<string>; }>> {
        if (requestParameters.apiKeys === null || requestParameters.apiKeys === undefined) {
            throw new runtime.RequiredError('apiKeys','Required parameter requestParameters.apiKeys was null or undefined when calling deletePublicationLists.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publist/bulk/{apiKeys}`.replace(`{${"apiKeys"}}`, encodeURIComponent(String(requestParameters.apiKeys))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async deletePublicationLists(requestParameters: DeletePublicationListsRequest): Promise<{ [key: string]: Array<string>; }> {
        const response = await this.deletePublicationListsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPublicationListRaw(requestParameters: GetPublicationListRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.apiKey === null || requestParameters.apiKey === undefined) {
            throw new runtime.RequiredError('apiKey','Required parameter requestParameters.apiKey was null or undefined when calling getPublicationList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publist/{apiKey}`.replace(`{${"apiKey"}}`, encodeURIComponent(String(requestParameters.apiKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getPublicationList(requestParameters: GetPublicationListRequest): Promise<string> {
        const response = await this.getPublicationListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSingleEntryRaw(requestParameters: GetSingleEntryRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling getSingleEntry.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.style !== undefined) {
            queryParameters['style'] = requestParameters.style;
        }

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publist/single/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters.workId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getSingleEntry(requestParameters: GetSingleEntryRequest): Promise<string> {
        const response = await this.getSingleEntryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async previewRaw(requestParameters: PreviewRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.pubListConfig === null || requestParameters.pubListConfig === undefined) {
            throw new runtime.RequiredError('pubListConfig','Required parameter requestParameters.pubListConfig was null or undefined when calling preview.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publist/preview`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PubListConfigToJSON(requestParameters.pubListConfig),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async preview(requestParameters: PreviewRequest): Promise<string> {
        const response = await this.previewRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async storeRaw(requestParameters: StoreRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.apiKey === null || requestParameters.apiKey === undefined) {
            throw new runtime.RequiredError('apiKey','Required parameter requestParameters.apiKey was null or undefined when calling store.');
        }

        if (requestParameters.pubListConfig === null || requestParameters.pubListConfig === undefined) {
            throw new runtime.RequiredError('pubListConfig','Required parameter requestParameters.pubListConfig was null or undefined when calling store.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publist/store/{apiKey}`.replace(`{${"apiKey"}}`, encodeURIComponent(String(requestParameters.apiKey))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PubListConfigToJSON(requestParameters.pubListConfig),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async store(requestParameters: StoreRequest): Promise<string> {
        const response = await this.storeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async storeNewRaw(requestParameters: StoreNewRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.pubListConfig === null || requestParameters.pubListConfig === undefined) {
            throw new runtime.RequiredError('pubListConfig','Required parameter requestParameters.pubListConfig was null or undefined when calling storeNew.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/publist/store`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PubListConfigToJSON(requestParameters.pubListConfig),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async storeNew(requestParameters: StoreNewRequest): Promise<string> {
        const response = await this.storeNewRaw(requestParameters);
        return await response.value();
    }

}
