import * as React from 'react';
import { ExternalIdDTO } from '../api/core/models';
import { OutLink } from '../BaseLayout/components/misc/OutLink';
import {
  ExternalId,
  ExternalIdList,
  externalIdsOrganisation,
  externalIdsPerson,
  externalIdsProject, externalIdsProjectFunder, externalIdsWork,
  affiliationIcons, institutionalIdIcons, Icon,
} from './data';
import { ReactElement } from 'react';
import { OAuth2Container } from '../Auth/containers/OAuth2Container';
import { OrcidMethod, OrcidResult } from '../BaseLayout/components/orcid/OrcidPopup';
import rubLogo from '../BaseLayout/assets/img/logos/logo_rub.svg';
import tudoLogo from '../BaseLayout/assets/img/logos/logo_tudo.svg';
import hhuLogo from '../BaseLayout/assets/img/logos/logo_hhu_small.svg';
import uwhLogo from '../BaseLayout/assets/img/logos/logo_uwh.svg';

/** @jsx jsx */
import { jsx, SxStyleProp } from 'theme-ui';
import { InstitutionalId } from '../Edit/components/fields/InstitutionalIds';
import { ExternalIdType, TypedId } from './types';

export const digitDisplay = (digit: number): string => {
  if (digit > 9 ) {
    return digit.toString();
  }
  return '0' + digit.toString();
};

export const renderDate = (dt: Date): string => {
  if (dt === undefined) return '';
  return dt.getFullYear().toString() + '-' + digitDisplay(dt.getMonth()+1) + '-' + digitDisplay(dt.getDate());

};

export const renderDateTime = (dt: Date): string => {
  if (dt === undefined) return '';
  return renderDate(dt) + ' ' + digitDisplay(dt.getHours()) + ':' + digitDisplay(dt.getMinutes());
};

export const renderInstitutionalIds = (institutionalIds: InstitutionalId[]): ReactElement => {
  institutionalIds = institutionalIds || [];
  return renderIds(
    institutionalIds.map<TypedId>((ids: InstitutionalId): TypedId => ({name: ids.institution, value: ids.value})),
    institutionalIdIcons
  );
};

export const renderExternalIds = (externalIds: ExternalIdDTO[], type: ExternalIdType): ReactElement => {
  let iconLinks: ExternalIdList;
  externalIds = externalIds || [];
  switch (type) {
    case 'orga':          iconLinks = externalIdsOrganisation;   break;
    case 'project':       iconLinks = externalIdsProject;        break;
    case 'person':        iconLinks = externalIdsPerson;         break;
    case 'work':          iconLinks = externalIdsWork;           break;
    case 'funder':        iconLinks = externalIdsProjectFunder;  break;
    default:              iconLinks = {};
  }
  return renderIds(
    externalIds.map<TypedId>((ids: ExternalIdDTO): TypedId => ({name: ids.type, value: ids.externalId})),
    iconLinks
  );
};

const renderIds = (ids: TypedId[], iconLinks: ExternalIdList): ReactElement => {
  const results: React.JSX.Element[] = [];
  ids = ids || [];
  if(ids) {
    ids.forEach((id: TypedId): void => {
      const iconLink: ExternalId = iconLinks[id.name];

      const name: string = iconLink?.name || id.name;

      const rawId: string = id.value.trim(); // Trimmed, raw ID
      let renderedId: any = rawId;
      if (iconLink?.link && !iconLink?.disabled) {
        const idLink: string = iconLink.link
          // Trimmed ID, URL-encoded
          .replace(':id', encodeURIComponent(rawId))
          // Trimmed ID
          .replace(':rawid', rawId)
          // Trimmed ID with only alpha-numeric characters
          .replace(':alnumid', rawId.replace(/[^A-Za-z0-9]/g, ''));
        renderedId = <OutLink href={idLink}>{rawId}</OutLink>;
      }

      results.push(
        <li key={id.name+rawId} title={name}>
          <>
            {iconLink?.icon ? <img src={iconLink.icon} className="external-id" alt={name}/> : `${name}: `}
            {renderedId}
          </>
        </li>
      );
    });
  }
  return <ul className="ids">{results}</ul>;
};

export const getAffiliationIcons = (affiliations: string[]): React.JSX.Element[] => {
  affiliations = affiliations || [];
  const nodes: React.JSX.Element[] = [];
  for (const affiliation of affiliations) {
    let icon: Icon;
    let sx: SxStyleProp;
    switch (affiliation) {
      case 'Ruhr-Universität Bochum':
        icon = affiliationIcons.rub_icon;
        sx = {width: '2.1em', mt: '-0.4em', ml: '-1px', mr: '-1px'};
        break;
      case 'Technische Universität Dortmund':
        icon = affiliationIcons.tudo_icon;
        sx = {width: '4.75em', mt: '-0.5em', ml: '2px', mr: '1px'};
        break;
      case 'Heinrich-Heine-Universität Düsseldorf':
        icon = affiliationIcons.hhu_icon;
        sx = {width: '2em', mt: '-0.4em', ml: '2px', mr: '1px'};
        break;
      case 'Universität Witten/Herdecke':
        icon = affiliationIcons.uwh_icon;
        sx = {width: '3.2em', mx: '2px', mt: '-0.4em'};
        break;
      default:
        continue;
    }
    nodes.push(<div sx={{display: 'inline-flex', verticalAlign: 'middle'}}>
      <img sx={sx} src={icon.icon} className="external-id" alt={icon.name}/>
    </div>);
  }
  return nodes;
};

export const getUnstyledAffiliationIcon = (affiliation: string, sx: SxStyleProp, entity?: string): React.JSX.Element => {
  let logo: string;
  switch (affiliation) {
    case 'Ruhr-Universität Bochum':
      logo = rubLogo; break;
    case 'Technische Universität Dortmund':
      logo = tudoLogo; break;
    case 'Heinrich-Heine-Universität Düsseldorf':
      logo = hhuLogo; break;
    case 'Universität Witten/Herdecke':
      sx = entity === 'persons' ? {height: '1em', mt: '4px', mb: '-3px'} : {height: '1.3em'};
      logo = uwhLogo; break;
    default:
      return <></>;
  }
  return <img sx={sx} src={logo} alt={affiliation}/>;
};

export const getUnstyledExternalIdIcon = (eid: ExternalIdDTO, sx: SxStyleProp, type: ExternalIdType): React.JSX.Element => {
  let idList: ExternalIdList;
  switch (type) {
    case 'orga':    idList = externalIdsOrganisation;   break;
    case 'project': idList = externalIdsProject;        break;
    case 'person':  idList = externalIdsPerson;         break;
    case 'work':    idList = externalIdsWork;           break;
    case 'funder':  idList = externalIdsProjectFunder;  break;
    default:        idList = {};
  }

  if (!idList.hasOwnProperty(eid.type)) {
    return <></>;
  }

  const id: ExternalId = idList[eid.type];
  return <img sx={sx} src={id.icon} className="external-id-icon" alt={id.name} />;
};

export const getExternalPersonIdImage = (eid: ExternalIdDTO): React.JSX.Element => {
  if (!externalIdsPerson.hasOwnProperty(eid.type)) {
    return <></>;
  }
  const id: ExternalId = externalIdsPerson[eid.type];
  if (id.link) {
    return (
      <OutLink icon={false} href={id.link.replace(':id', eid.externalId)}>
        <img sx={{mr: '1px'}} src={id.icon} className="external-id" alt={id.name} />
      </OutLink>
    );
  } else {
    return <img sx={{mr: '1px'}} src={id.icon} className="external-id" alt={id.name}/>;
  }
};

export const getExternalPersonIdImages = (externalIdDTOS: ExternalIdDTO[]): React.JSX.Element[] => {
  const nodes: React.JSX.Element[] = [];

  externalIdDTOS = externalIdDTOS || [];

  for (const externalIdDTO of externalIdDTOS) {
    if(!externalIdsPerson[externalIdDTO.type]) continue;
    nodes.push(getExternalPersonIdImage(externalIdDTO));
  }

  return nodes;
};

export const renderDateApi = (date: Date): string => {
  return date.toISOString().slice(0, 19).replace('T', ' ');
};

export const renderDateOnlyApi = (date: Date): string => {
  return date.toISOString().slice(0, 10).replace('T', ' ');
};

export const renderIssued = (issued: number): string => {
  const issuedString: string = issued.toString();
  if (issuedString.length <= 4) {
    return issuedString;
  }
  if (issuedString.length <= 6) {
    return issuedString.substring(0, 4) + '-' + issuedString.substring(4, issuedString.length);
  }
  return issuedString.substring(0, 4) + '-' + issuedString.substring(4, 6) + '-' + issuedString.substring(6, issuedString.length);
};

export const orcidRequest = async (orcidId: string, method: OrcidMethod, container: OAuth2Container): Promise<OrcidResult> => {
  const token: string | undefined = await container.getAuthTokenOrRefresh(true);
  if (!token) {
    return { type: 'error', method, message: 'Missing, expired or invalid sso token'};
  }
  if (!orcidId) {
    return { type: 'error', method, message: 'Missing Orcid ID'};
  }
  const params: {[key: string]:  { method: string; url: string }} = {
    'push': {  method: "POST", url: `https://${window.location.hostname}/mms3-orcid/orcid/core/connect/${orcidId}/works?access_token=${token}` },
    'pull': {  method: "GET", url: `https://${window.location.hostname}/mms3-orcid/orcid/core/collect/${orcidId}/works?access_token=${token}` }
  };
  if (!params[method]) {
    return { type: 'error', method, message: 'Invalid Method:' + method};
  }
  const result: Response = await fetch(params[method].url, { method: params[method].method, });
  if (!result.ok) {
    return { type: 'error', method, message: result.statusText};
  }
  return { type: 'success', method };
};
