// React
import * as React from 'react';
import * as ReactDOM from 'react-dom';

// Router
import { history } from './historyUtil';

// App
import { App } from './App';

// Logger
import getLogger from './log';

// Moment
import Moment from 'react-moment';

// eslint-disable-line
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
// Debug
import UNSTATED from 'unstated-debug';
UNSTATED.isEnabled = false;

const logger = getLogger();

if (process.env.NODE_ENV === 'development') {
  UNSTATED.isEnabled = true;
  UNSTATED.logStateChanges = false;
}
if (process.env.NODE_ENV === 'production') {
  const refSlug = process.env.REACT_APP_VCS_REF_SLUG;
  const refName = process.env.REACT_APP_VCS_REF_NAME;
  const revision = process.env.REACT_APP_VCS_COMMIT;
  const env = process.env.NODE_ENV;
  logger.info(`Version ${refSlug}/${refName} (${revision}) [${env}]`);
}

Moment.startPooledTimer(30000);

// Helper method to test locale setting in localStorage
(window as any).appSetLocale = (value: string = null) => {
  window.localStorage.setItem('locale', value);
};

// Helper method to access the root logger
(window as any).appLogger = getLogger();

ReactDOM.render(<App history={history} />, document.getElementById('root'));