/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PatchDocument,
    PatchDocumentFromJSON,
    PatchDocumentToJSON,
    ProjectDTO,
    ProjectDTOFromJSON,
    ProjectDTOToJSON,
} from '../models';

export interface CreateProjectRequest {
    projectDTO: ProjectDTO;
}

export interface DeleteProjectRequest {
    projectId: number;
}

export interface GetPersonsInProjectRequest {
    projectId: number;
}

export interface GetPersonsIncludingChildProjectsRequest {
    projectId: number;
}

export interface GetProjectReduced1Request {
    projectId: number;
    accept?: string;
}

export interface GetWorksByProjectMembersRequest {
    projectId: number;
}

export interface GetWorksOfProjectRequest {
    projectId: number;
}

export interface UpdateProjectRequest {
    projectId: number;
    patchDocument?: Array<PatchDocument>;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     */
    async createProjectRaw(requestParameters: CreateProjectRequest): Promise<runtime.ApiResponse<ProjectDTO>> {
        if (requestParameters.projectDTO === null || requestParameters.projectDTO === undefined) {
            throw new runtime.RequiredError('projectDTO','Required parameter requestParameters.projectDTO was null or undefined when calling createProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectDTOToJSON(requestParameters.projectDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectDTOFromJSON(jsonValue));
    }

    /**
     */
    async createProject(requestParameters: CreateProjectRequest): Promise<ProjectDTO> {
        const response = await this.createProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteProjectRaw(requestParameters: DeleteProjectRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteProject(requestParameters: DeleteProjectRequest): Promise<void> {
        await this.deleteProjectRaw(requestParameters);
    }

    /**
     */
    async getPersonsInProjectRaw(requestParameters: GetPersonsInProjectRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getPersonsInProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project/{projectId}/persons`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getPersonsInProject(requestParameters: GetPersonsInProjectRequest): Promise<Array<number>> {
        const response = await this.getPersonsInProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonsIncludingChildProjectsRaw(requestParameters: GetPersonsIncludingChildProjectsRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getPersonsIncludingChildProjects.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project/{projectId}/persons/childs`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getPersonsIncludingChildProjects(requestParameters: GetPersonsIncludingChildProjectsRequest): Promise<Array<number>> {
        const response = await this.getPersonsIncludingChildProjectsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProjectReduced1Raw(requestParameters: GetProjectReduced1Request): Promise<runtime.ApiResponse<ProjectDTO>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectReduced1.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectDTOFromJSON(jsonValue));
    }

    /**
     */
    async getProjectReduced1(requestParameters: GetProjectReduced1Request): Promise<ProjectDTO> {
        const response = await this.getProjectReduced1Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWorksByProjectMembersRaw(requestParameters: GetWorksByProjectMembersRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getWorksByProjectMembers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project/{projectId}/works/persons`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getWorksByProjectMembers(requestParameters: GetWorksByProjectMembersRequest): Promise<Array<number>> {
        const response = await this.getWorksByProjectMembersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWorksOfProjectRaw(requestParameters: GetWorksOfProjectRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getWorksOfProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project/{projectId}/works`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getWorksOfProject(requestParameters: GetWorksOfProjectRequest): Promise<Array<number>> {
        const response = await this.getWorksOfProjectRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateProjectRaw(requestParameters: UpdateProjectRequest): Promise<runtime.ApiResponse<ProjectDTO>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProject.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchDocument.map(PatchDocumentToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateProject(requestParameters: UpdateProjectRequest): Promise<ProjectDTO> {
        const response = await this.updateProjectRaw(requestParameters);
        return await response.value();
    }

}
