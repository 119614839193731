import React from 'react';
import { FormattedNumber as FN } from 'react-intl';
import { Flex } from 'theme-ui';

import { Props } from './index';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Pagination } from '../Pagination';

type FacetPaginationProps = Pick<Props, 'className' | 'count' | 'page' | 'pageLimit' | 'onOffsetUpdate'>;
export const FacetPagination: React.FC<FacetPaginationProps> = (props) => {
  const { count, page, pageLimit, onOffsetUpdate, className } = props;
  const n = 3;
  if (pageLimit === undefined || count <= pageLimit) {
    // No pagination
    return <React.Fragment></React.Fragment>;
  }

  const pageCount = Math.ceil(count / pageLimit);

  const handleClick = (p: number) => {
    return () => {
      onOffsetUpdate((p - 1) * pageLimit);
    };
  };

  const pages = [];
  // First page
  if (page > 1) {
    pages.push(
      <button key={1} type="button" onClick={handleClick(1)}>
        <FN value={1} />
      </button>
    );
  }
  // Filler
  if (page > 1 + n + 1) {
    pages.push(<span key="fillLeft">...</span>);
  }
  // n pages before current
  for (let p = Math.max(page - n, 2); p < page; ++p) {
    pages.push(
      <button key={p} type="button" onClick={handleClick(p)}>
        <FN value={p} />
      </button>
    );
  }
  // Current page
  pages.push(
    <button key={page} type="button" className="current">
      <FN value={page} />
    </button>
  );
  // n pages after current
  for (let p = page + 1; p < Math.min(pageCount, page + n + 1); ++p) {
    pages.push(
      <button key={p} type="button" onClick={handleClick(p)}>
        <FN value={p} />
      </button>
    );
  }
  // Filler
  if (page < pageCount - n - 1) {
    pages.push(<span key="fillRight">...</span>);
  }
  // Last page
  if (page < pageCount) {
    pages.push(
      <button key={pageCount} type="button" onClick={handleClick(pageCount)}>
        <FN value={pageCount} />
      </button>
    );
  }
  return (
    <Pagination>
      <div className="pages-facet">{pages}</div>
    </Pagination>
  );
};
