import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  surname: { id: 'work.attr.workPersonOther.attr.surname' },
  surnameHelp: { id: 'work.attr.workPersonOther.attr.surname.help' },
  givenname: { id: 'work.attr.workPersonOther.attr.givenname' },
  role: { id: 'work.attr.workPersonAndWorkPersonOther.attr.role' },
  people: { id: 'work.attr.workPersonsAndWorkPersonOthers' },
  addPerson: { id: 'work.button.addPerson' },
  addPersonOther: { id: 'work.button.addPersonOther' },
  person: { id: 'work.attr.workPersonAndWorkPersonOther' },
  personHelp: { id: 'work.attr.workPersonAndWorkPersonOther.help' },
  id: { id: 'work.attr.externalId.attr.externalId' },
  type: { id: 'work.attr.externalId.attr.type' },
  externalId: { id: 'entity.attr.externalId'},
  name_variant: { id: 'work.attr.workPersonAndWorkPersonOther.name_variant' }
});
