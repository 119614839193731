import React, { ReactElement, FocusEvent, useEffect } from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { CheckPermissionFunction } from '../../../../Auth/components/DataModelGate';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  issued: { id: 'work.attr.issued' },
  issuedPlaceholder: { id: 'work.attr.issued.placeholder' },
});

interface IssuedProps {
  value?: number;
  inputName?: string;
  onChange: (data: number) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

function InnerWorkIssued({ value, onChange, intl, checkPermission, validation }: IssuedProps & WrappedComponentProps): ReactElement {
  useEffect(() => {
    if (isNaN(value) || value == null) {
      onChange(null);
    }
    else if(value.toString().length === 4) {
      onChange(parseInt(value.toString()+'0000', 10));
    }
  }, []);

  return (
    <TextInputP
      name={intl.formatMessage(messages.issued)}
      onChange={(issued: string): void => {
        if (isNaN(parseInt(issued, 10))) {
          onChange(null);
        } else {
          onChange(parseInt(issued, 10));
        }
      }}
      inputName={'issued'}
      value={value ? value.toString() : ''}
      placeholder={intl.formatMessage(messages.issuedPlaceholder)}
      validation={validation}
      inputProps={{
        maxLength: 8,
        minLength: 8,
        onBlur: (event: FocusEvent<HTMLInputElement>) => {
          if(event.target.value.length === 4) {
            onChange(parseInt(event.target.value+'0000', 10));
            return;
          }
        }
      }}
      permissions={checkPermission()}
    />
  );
}

export const WorkIssued = injectIntl(InnerWorkIssued);
