/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminDataWorkOpenAccessDTO,
    AdminDataWorkOpenAccessDTOFromJSON,
    AdminDataWorkOpenAccessDTOFromJSONTyped,
    AdminDataWorkOpenAccessDTOToJSON,
    AdminDataWorkOrcidSyncDTO,
    AdminDataWorkOrcidSyncDTOFromJSON,
    AdminDataWorkOrcidSyncDTOFromJSONTyped,
    AdminDataWorkOrcidSyncDTOToJSON,
    ReferencedOrganisationDTO,
    ReferencedOrganisationDTOFromJSON,
    ReferencedOrganisationDTOFromJSONTyped,
    ReferencedOrganisationDTOToJSON,
    ReferencedProjectDTO,
    ReferencedProjectDTOFromJSON,
    ReferencedProjectDTOFromJSONTyped,
    ReferencedProjectDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminDataWorkDTO
 */
export interface AdminDataWorkDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminDataWorkDTO
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof AdminDataWorkDTO
     */
    changed?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminDataWorkDTO
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkDTO
     */
    deskman?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkDTO
     */
    editorialStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDataWorkDTO
     */
    hidden?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDataWorkDTO
     */
    obligatoryDelivery?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkDTO
     */
    origin?: string;
    /**
     * 
     * @type {Array<ReferencedOrganisationDTO>}
     * @memberof AdminDataWorkDTO
     */
    organisations?: Array<ReferencedOrganisationDTO>;
    /**
     * 
     * @type {Array<ReferencedProjectDTO>}
     * @memberof AdminDataWorkDTO
     */
    projects?: Array<ReferencedProjectDTO>;
    /**
     * 
     * @type {AdminDataWorkOpenAccessDTO}
     * @memberof AdminDataWorkDTO
     */
    adminDataWorkOpenAccess?: AdminDataWorkOpenAccessDTO;
    /**
     * 
     * @type {Array<AdminDataWorkOrcidSyncDTO>}
     * @memberof AdminDataWorkDTO
     */
    adminDataWorkOrcidSyncs?: Array<AdminDataWorkOrcidSyncDTO>;
}

export function AdminDataWorkDTOFromJSON(json: any): AdminDataWorkDTO {
    return AdminDataWorkDTOFromJSONTyped(json, false);
}

export function AdminDataWorkDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDataWorkDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'changed': !exists(json, 'changed') ? undefined : (new Date(json['changed'])),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'deskman': !exists(json, 'deskman') ? undefined : json['deskman'],
        'editorialStatus': !exists(json, 'editorialStatus') ? undefined : json['editorialStatus'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'obligatoryDelivery': !exists(json, 'obligatoryDelivery') ? undefined : json['obligatoryDelivery'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'organisations': !exists(json, 'organisations') ? undefined : ((json['organisations'] as Array<any>).map(ReferencedOrganisationDTOFromJSON)),
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ReferencedProjectDTOFromJSON)),
        'adminDataWorkOpenAccess': !exists(json, 'adminDataWorkOpenAccess') ? undefined : AdminDataWorkOpenAccessDTOFromJSON(json['adminDataWorkOpenAccess']),
        'adminDataWorkOrcidSyncs': !exists(json, 'adminDataWorkOrcidSyncs') ? undefined : ((json['adminDataWorkOrcidSyncs'] as Array<any>).map(AdminDataWorkOrcidSyncDTOFromJSON)),
    };
}

export function AdminDataWorkDTOToJSON(value?: AdminDataWorkDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'changed': value.changed === undefined ? undefined : (value.changed.toISOString()),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'deskman': value.deskman,
        'editorialStatus': value.editorialStatus,
        'hidden': value.hidden,
        'obligatoryDelivery': value.obligatoryDelivery,
        'origin': value.origin,
        'organisations': value.organisations === undefined ? undefined : ((value.organisations as Array<any>).map(ReferencedOrganisationDTOToJSON)),
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ReferencedProjectDTOToJSON)),
        'adminDataWorkOpenAccess': AdminDataWorkOpenAccessDTOToJSON(value.adminDataWorkOpenAccess),
        'adminDataWorkOrcidSyncs': value.adminDataWorkOrcidSyncs === undefined ? undefined : ((value.adminDataWorkOrcidSyncs as Array<any>).map(AdminDataWorkOrcidSyncDTOToJSON)),
    };
}


