import React, { ReactNode, ReactElement } from 'react';
import { WorkIsPartOfDTO as WorkIsPartOfModel } from '../../../../../api/core/models';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkPart } from '../../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../../BaseLayout/components/form/MultiInput/Buttons';
import { WorkPart } from './WorkPart';
import { PubtypeServiceApi } from '../../../../../api/pubtype';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../../validation/interfaces';


interface WorkIsPartOfProps {
  api: PubtypeServiceApi;
  checkPermission: CheckPermissionFunction;
  isPartOf: WorkIsPartOfModel[];
  onChange: (ipo: WorkIsPartOfModel[]) => void;
  pubtype: string;
  validation: ValidationResult;
}

function InnerWorkIsPartOf({
  api,
  isPartOf,
  onChange,
  intl,
  pubtype,
  checkPermission,
  validation
}: WorkIsPartOfProps & WrappedComponentProps): ReactElement {
  return (
    <div className="col">
      <Styled.h2>
        <FormattedMessage id="work.attr.isPartOf" />
      </Styled.h2>
      <Border>
        <MultiInputControls<WorkIsPartOfModel>
          lines={isPartOf}
          onChange={onChange}
          add
          remove
          defaultRow={DefaultWorkPart}
          renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
            <div className="addButton">
              <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
            </div>
          )}
          renderLines={(parent: WorkIsPartOfModel, onLineChange: (newValues: WorkIsPartOfModel) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactNode => (
            <div>
              <WorkPart
                api={api}
                part={parent}
                pubtypes={{part: pubtype, parent: parent?.referencedWork?.pubtype}}
                checkPermission={checkPermission}
                validations={validation?.children[idx]}
                onChange={onLineChange}
                intl={intl}
                actions={actions}/>
              {!!actions.delete && (
                <div sx={{mb: "1em"}}>
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          )}
        />
      </Border>
    </div>
  );
}

export const WorkIsPartOfComponent = injectIntl(InnerWorkIsPartOf);
