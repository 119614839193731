import * as React from 'react';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import {
  ProjectDTO,
  ProjectFundingReferenceDTO,
  ProjectInstitutionalIdDTO,
  ProjectOtherPartnerDTO, OrganisationToPersonDTO,
  SubjectDTO,
  UrlDTO,
  ProjectOtherNameDTO,
} from 'api/core/models';
import { DataModelGate, OAuth2Gate } from '../../Auth/components';
import { CheckboxP } from '../../BaseLayout/components/form/generic/Checkbox';
import { Main } from '../../BaseLayout/components/base-layout/main';
import { DetailEditProps as Props, EditMode } from '../types';
import { ProjectSuggest } from '../components/auto-complete/ProjectSuggest';
import { OrganisationSuggest } from '../components/auto-complete/OrganisationSuggest';
import { DeleteProject } from '../components/DeleteProject';
import { BorderlessExternalId } from '../components/fields/ExternalIds';
import { ProjectFundingRefs } from '../components/fields/FundingReference';
import { OtherPartners } from '../components/fields/OtherPartner';
import { Subjects } from '../components/fields/Subjects';
import { Urls } from '../components/fields/Urls';
import { InstitutionalIds } from '../components/fields/InstitutionalIds';
import { DetailEditGrid } from '../components/theme';
import { AbortButton, SubmitButton } from 'Edit/components/SubmitAbortButton';
import { TextInputP } from '../../BaseLayout/components/form/generic/TextInput';
import { CheckPermissionFunction, ReadWritePermissions, WritableParam } from '../../Auth/components/DataModelGate';
import { ReactElement, useState } from 'react';
import { ProjectPersons } from '../components/fields/project/ProjectPersons';
import { isRequired } from '../../BaseLayout/components/form/generic/utils';
import { Button } from '@theme-ui/components';
import { TextAreaP } from 'BaseLayout/components/form/generic/Textarea';
import { Styled } from 'theme-ui';
import { useHistory } from "react-router-dom";
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { OAuth2Container } from '../../Auth/containers/OAuth2Container';
import { Validation } from '../validation/interfaces';
import { validate } from '../validation/validate';
import { Subscribe } from 'unstated';
import { isValid } from '../validation/isValid';
import { DatePickerStyled } from 'BaseLayout/components/form/DatePickerDateRange/DatePicker';
import { DateObject } from 'react-multi-date-picker';
import { InputLabel } from 'BaseLayout/components/form/generic/InputLabel';
import { ProjectOtherNames } from 'Edit/components/fields/project/ProjectOtherNames';
/** @jsx jsx */
import { jsx } from 'theme-ui';

const messages = defineMessages({
  name: { id: 'edit.project.name_label' },
  nameHelp: { id: 'edit.project.name_label.help' },
  otherName: { id: 'edit.project.other_Name_label' }
});

export const DetailEditProject: React.FC<Props<ProjectDTO>> = (props) => {
  const history = useHistory();
  const intl: IntlShape = useIntl();
  const [deleteInit, setDeleteInit] = useState<boolean>(false);
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false);

  const {
    redirect,
    modifiedModel,
    updateField,
    updateAdminField,
    submitActive,
    delete: _delete,
  } = props;

  const {
    id,
    name,
    otherNames,
    note,
    projectSubjects,
    partner,
    projectInstitutionalIds,
    urls,
    projectOtherPartners,
    projectFundingReferences,
    adminDataProject,
    childs,
    predecessors,
    successor,
    parents,
    projectPersons,
    startDate,
    endDate,
  } = props.modifiedModel;

  const { hidden } = adminDataProject;

  if (!modifiedModel) return <FormattedMessage id="edit.loadling_text" />;
  if (redirect) return <Redirect to={redirect} />;

  return (
    <Subscribe to={[OAuth2Container]}>
      {(container: OAuth2Container) => {
        const validation: Validation = validate(modifiedModel, adminDataProject, container.authorizer(), props.mode === EditMode.INSERT, "project");
        return (
          <>
          {deleteInit &&
          <DeleteProject
            projectId={props.id}
            projectName={name}
            isDeleteRequest={deleteRequest}
            onAbort={() => setDeleteInit(false)}
            onSuccess={() => history.push("/search/projects")}
            onError={(e) => {
              setDeleteInit(false);
            }}
          />
          }
          <Main className="detail-edit">
            <form>
              <DetailEditGrid>
                <div className="col">
                  <Styled.h2>
                    <FormattedMessage id="edit.project.general_head" />
                  </Styled.h2>
                  <DataModelGate always={false} entity="project" field="name">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form">
                          <TextInputP
                            value={name}
                            onChange={(value: string): void => updateField('name', value)}
                            name={intl.formatMessage(messages.name)}
                            help={intl.formatMessage(messages.nameHelp)}
                            inputName={'name'}
                            permissions={checkPermission()}
                            validation={validation?.root?.name}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate entity="project" always={false} field="other_names">
                    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction ) => (
                      <ProjectOtherNames
                        projectOtherNames={otherNames}
                        onChange={(_projectOtherNames: ProjectOtherNameDTO[]): void => updateField('otherNames', _projectOtherNames)}
                        checkPermission={checkPermission}
                        validation={validation?.root?.other_names}
                      />
                    )}
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="urls">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form">
                          <Urls
                            checkPermission={checkPermission}
                            urls={urls ? urls : []}
                            typelist={'url_list'}
                            onChange={(u: UrlDTO[]): void => updateField('urls', u)}
                            validation={validation?.root?.urls}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                </div>
                <div className="col">
                  <BorderlessExternalId modifiedModel={modifiedModel}
                                updateField={updateField}
                                entity={'project'}
                                list={'external_unit_id_list'}
                                validation={validation?.root?.external_ids}/>
                  <DataModelGate always={false} entity="project" field="project_institutional_ids">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form">
                          <InstitutionalIds
                            checkPermission={checkPermission}
                            typesList={'affiliations_list'}
                            institutionalIds={projectInstitutionalIds ? projectInstitutionalIds : []}
                            onChange={(iId: ProjectInstitutionalIdDTO[]): void => updateField('projectInstitutionalIds', iId)}
                            validation={validation?.root?.project_institutional_ids}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                </div>
                <div className="col">
                  <Styled.h2>
                    <FormattedMessage id="edit.admin_head" />
                  </Styled.h2>
                  <DataModelGate always={false} entity="project" field="id">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <TextInputP
                          name={intl.formatMessage({id: "edit.admin.id_label"})}
                          value={String(id || '')}
                          disabled={!writable}
                          validation={validation?.root?.id}
                          permissions={checkPermission()}/>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project_admin_data" field="hidden">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                          <CheckboxP
                            name={intl.formatMessage({id: "edit.admin.hidden_label"})}
                            checked={hidden}
                            disabled={!checkPermission().write}
                            onChange={(value: boolean): void => updateAdminField('hidden', value)}
                            validation={validation?.admin?.hidden}
                            permissions={checkPermission()}/>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="note">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <TextAreaP
                            name={intl.formatMessage({id: "edit.admin.note_label"})}
                            onChange={(value): void => updateField('note', value)}
                            inputName={'workProject'}
                            permissions={checkPermission()}
                            validation={validation?.root?.note}
                            value={note}
                        />
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project_admin_data" field="editorial_status">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      <SelectInputP
                        name={intl.formatMessage({id: 'edit.admin.editorial_status_label'})}
                        value={modifiedModel.adminDataProject.editorialStatus ? modifiedModel.adminDataProject.editorialStatus : ''}
                        onChange={(_editorialStatus: string) => updateField('adminDataProject', {
                          ...modifiedModel.adminDataProject,
                          editorialStatus: _editorialStatus,
                        })}
                        list={'editorial_status_list'}
                        listPartition={'*'}
                        inputName={'editorialStatus'}
                        permissions={checkPermission()}
                        validation={validation?.admin?.editorial_status}
                      />
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="start_date">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <DatePickerStyled
                          label={'edit.admin.start_date_label'}
                          disabled={!writable}
                          value={startDate ? startDate : undefined}
                          required={isRequired(checkPermission())}
                          onChange={(date: DateObject): void =>
                            updateField('startDate', date === null ? undefined : new Date(date.format("YYYY-MM-DD")))
                          }
                          validation={validation?.root?.start_date}
                        />
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="end_date">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <DatePickerStyled
                          label={'edit.admin.end_date_label'}
                          disabled={!writable}
                          required={isRequired(checkPermission())}
                          value={endDate ? endDate : undefined}
                          onChange={(date: DateObject): void =>
                            updateField('endDate', date === null ? undefined : new Date(date.format("YYYY-MM-DD")))
                          }
                          validation={validation?.root?.end_date}
                        />
                      )
                    }
                  </DataModelGate>
                  <div className='buttons'>
                    <SubmitButton
                      disabled={!submitActive || !isValid(validation)}
                      onSubmit={() => props.submit()}
                    >
                      <FormattedMessage id="edit.submit_btn" />
                    </SubmitButton>

                    {props.mode === EditMode.INSERT &&
                      <AbortButton>
                        <FormattedMessage id="edit.abort_btn" />
                      </AbortButton>
                    }

                    {props.mode === EditMode.UPDATE &&
                      <>
                        <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
                          <Button
                            disabled={!props.deleteActive}
                            onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                              e.preventDefault();
                              setDeleteInit(true);
                              setDeleteRequest(false);
                            }}
                          >
                            <FormattedMessage id="button.Delete" />
                          </Button>
                        </OAuth2Gate>

                        <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
                          <Button
                            disabled={!props.deleteActive}
                            onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                              e.preventDefault();
                              setDeleteInit(true);
                              setDeleteRequest(true);
                            }}
                          >
                            <FormattedMessage id="button.Delete.Request" />
                          </Button>
                        </OAuth2Gate>
                      </>
                    }
                  </div>
                </div>
                <div className="col">
                  <Styled.h2>
                    <FormattedMessage id="edit.project.linkings_head" />
                  </Styled.h2>
                  <DataModelGate always={false} entity="project" field="project_subjects">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.subjects_head" />
                          </Styled.h2>
                          <Subjects
                            checkPermission={checkPermission}
                            onChange={(subjects: SubjectDTO[]): void => updateField('projectSubjects', subjects)}
                            subjects={projectSubjects ? projectSubjects : []}
                            validation={validation?.root?.project_subjects}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="partner">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form" sx={{".project-orgas": {
                          fontSize: '24px',
                          ml: '11px',
                          alignItems: 'center',
                          mb: '1em',
                          mt: '0.5em',
                          i: {
                            fontSize: '16px',
                            verticalAlign: 'super'
                          },
                          "div > div": {
                            fontSize: '16px'
                          }
                        }}}>
                          <InputLabel
                            classes={["project-orgas"]}
                            variant="labelY"
                            label={intl.formatMessage({id: 'edit.project.partner_head'})}
                            help={intl.formatMessage({id: 'edit.project.partner_head.help'})}
                          />
                          <OrganisationSuggest
                            required={isRequired(checkPermission())}
                            tags={partner ? partner : []}
                            disabled={!checkPermission().write}
                            onChange={(tags: any[]): void => updateField('partner', tags)}
                            validation={validation?.root?.partner}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="project_other_partners">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form form-highlight">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.other_partner_head" />
                          </Styled.h2>
                          <OtherPartners
                            checkPermission={checkPermission}
                            otherPartners={projectOtherPartners ? projectOtherPartners : []}
                            onChange={(op: ProjectOtherPartnerDTO[]): void => updateField('projectOtherPartners', op)}
                            validation={validation?.root?.project_other_partners}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="project_funding_references">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form form-highlight">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.funding_ref_head" />
                          </Styled.h2>
                          <ProjectFundingRefs
                            checkPermission={checkPermission}
                            onChange={(gfr: ProjectFundingReferenceDTO[]): void => updateField('projectFundingReferences', gfr)}
                            fundingRefs={projectFundingReferences ? projectFundingReferences : []}
                            validation={validation?.root?.project_funding_references}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always entity="project" field="project_persons">
                    {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.person_head" />
                          </Styled.h2>
                          <ProjectPersons
                            checkPermission={checkPermission}
                            projectPersons={projectPersons ? projectPersons : []}
                            onChange={(ops: OrganisationToPersonDTO[]): void => updateField('projectPersons', ops)}
                            validation={validation?.root?.project_persons}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="childs">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                      checkPermission().read && (
                        <div className="form">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.childs_head" />
                          </Styled.h2>
                          <ProjectSuggest
                            required={isRequired(checkPermission())}
                            tags={childs ? childs : []}
                            disabled={!checkPermission().write}
                            onChange={(tags: any[]): void => updateField('childs', tags)}
                            validation={validation?.root?.childs}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="parents">
                    {(writable, readablePerson, checkPermission) =>
                      checkPermission().read && (
                        <div className="form">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.parents_head" />
                          </Styled.h2>
                          <ProjectSuggest
                            required={isRequired(checkPermission())}
                            tags={parents ? parents : []}
                            disabled={!checkPermission().write}
                            onChange={(tags: any[]): void => {
                              updateField('parents', tags);
                            }}
                            validation={validation?.root?.parents}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="successor">
                    {(writable, readablePerson, checkPermission) =>
                      checkPermission().read && (
                        <div className="form">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.successor_head" />
                          </Styled.h2>
                          <ProjectSuggest
                            required={isRequired(checkPermission())}
                            tags={successor ? successor : []}
                            disabled={!checkPermission().write}
                            onChange={(tags: any[]): void => updateField('successor', tags)}
                            validation={validation?.root?.successor}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                  <DataModelGate always={false} entity="project" field="predecessors">
                    {(writable, readablePerson, checkPermission) =>
                      checkPermission().read && (
                        <div className="form">
                          <Styled.h2>
                            <FormattedMessage id="edit.project.predecessors_head" />
                          </Styled.h2>
                          <ProjectSuggest
                            required={isRequired(checkPermission())}
                            tags={predecessors ? predecessors : []}
                            disabled={!checkPermission().write}
                            onChange={(tags: any[]): void => updateField('predecessors', tags)}
                            validation={validation?.root?.predecessors}
                          />
                        </div>
                      )
                    }
                  </DataModelGate>
                </div>
              </DetailEditGrid>
            </form>
          </Main>
          </>
        );
      }}
    </Subscribe>
  );
};
