/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Name
 */
export interface Name {
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    family?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    given?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    dropping_particle?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    non_dropping_particle?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    comma_suffix?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    static_ordering?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    literal?: string;
    /**
     * 
     * @type {string}
     * @memberof Name
     */
    parse_names?: string;
}

export function NameFromJSON(json: any): Name {
    return NameFromJSONTyped(json, false);
}

export function NameFromJSONTyped(json: any, ignoreDiscriminator: boolean): Name {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'family': !exists(json, 'family') ? undefined : json['family'],
        'given': !exists(json, 'given') ? undefined : json['given'],
        'dropping_particle': !exists(json, 'dropping-particle') ? undefined : json['dropping-particle'],
        'non_dropping_particle': !exists(json, 'non-dropping-particle') ? undefined : json['non-dropping-particle'],
        'suffix': !exists(json, 'suffix') ? undefined : json['suffix'],
        'comma_suffix': !exists(json, 'comma-suffix') ? undefined : json['comma-suffix'],
        'static_ordering': !exists(json, 'static-ordering') ? undefined : json['static-ordering'],
        'literal': !exists(json, 'literal') ? undefined : json['literal'],
        'parse_names': !exists(json, 'parse-names') ? undefined : json['parse-names'],
    };
}

export function NameToJSON(value?: Name | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'family': value.family,
        'given': value.given,
        'dropping-particle': value.dropping_particle,
        'non-dropping-particle': value.non_dropping_particle,
        'suffix': value.suffix,
        'comma-suffix': value.comma_suffix,
        'static-ordering': value.static_ordering,
        'literal': value.literal,
        'parse-names': value.parse_names,
    };
}


