/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SolrDocument
 */
export interface SolrDocument {
    [key: string]: object | any;
    /**
     * 
     * @type {boolean}
     * @memberof SolrDocument
     */
    empty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SolrDocument
     */
    childDocumentCount?: number;
    /**
     * 
     * @type {{ [key: string]: Array<object>; }}
     * @memberof SolrDocument
     */
    fieldValuesMap?: { [key: string]: Array<object>; };
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof SolrDocument
     */
    fieldValueMap?: { [key: string]: object; };
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrDocument
     */
    fieldNames?: Array<string>;
}

export function SolrDocumentFromJSON(json: any): SolrDocument {
    return SolrDocumentFromJSONTyped(json, false);
}

export function SolrDocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolrDocument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
            ...json,
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
        'childDocumentCount': !exists(json, 'childDocumentCount') ? undefined : json['childDocumentCount'],
        'fieldValuesMap': !exists(json, 'fieldValuesMap') ? undefined : json['fieldValuesMap'],
        'fieldValueMap': !exists(json, 'fieldValueMap') ? undefined : json['fieldValueMap'],
        'fieldNames': !exists(json, 'fieldNames') ? undefined : json['fieldNames'],
    };
}

export function SolrDocumentToJSON(value?: SolrDocument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
            ...value,
        'empty': value.empty,
        'childDocumentCount': value.childDocumentCount,
        'fieldValuesMap': value.fieldValuesMap,
        'fieldValueMap': value.fieldValueMap,
        'fieldNames': value.fieldNames,
    };
}


