/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfigResponse,
    ConfigResponseFromJSON,
    ConfigResponseToJSON,
    ShareRequestBody,
    ShareRequestBodyFromJSON,
    ShareRequestBodyToJSON,
} from '../models';

export interface AddShareWithRequest {
    shareRequestBody: ShareRequestBody;
}

export interface DeleteShareByRequest {
    requestBody: { [key: string]: number; };
}

export interface DeleteShareWithRequest {
    shareRequestBody: ShareRequestBody;
}

export interface GetUsersSharedByHierarchyRequest {
    apiKey: string;
}

export interface GetUsersSharedWithHierarchyRequest {
    apiKey: string;
}

/**
 * 
 */
export class ShareServiceApi extends runtime.BaseAPI {

    /**
     */
    async addShareWithRaw(requestParameters: AddShareWithRequest): Promise<runtime.ApiResponse<Array<ConfigResponse>>> {
        if (requestParameters.shareRequestBody === null || requestParameters.shareRequestBody === undefined) {
            throw new runtime.RequiredError('shareRequestBody','Required parameter requestParameters.shareRequestBody was null or undefined when calling addShareWith.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/share/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShareRequestBodyToJSON(requestParameters.shareRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConfigResponseFromJSON));
    }

    /**
     */
    async addShareWith(requestParameters: AddShareWithRequest): Promise<Array<ConfigResponse>> {
        const response = await this.addShareWithRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteShareByRaw(requestParameters: DeleteShareByRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteShareBy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/share/by`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteShareBy(requestParameters: DeleteShareByRequest): Promise<void> {
        await this.deleteShareByRaw(requestParameters);
    }

    /**
     */
    async deleteShareWithRaw(requestParameters: DeleteShareWithRequest): Promise<runtime.ApiResponse<Array<ConfigResponse>>> {
        if (requestParameters.shareRequestBody === null || requestParameters.shareRequestBody === undefined) {
            throw new runtime.RequiredError('shareRequestBody','Required parameter requestParameters.shareRequestBody was null or undefined when calling deleteShareWith.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/share/with`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ShareRequestBodyToJSON(requestParameters.shareRequestBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConfigResponseFromJSON));
    }

    /**
     */
    async deleteShareWith(requestParameters: DeleteShareWithRequest): Promise<Array<ConfigResponse>> {
        const response = await this.deleteShareWithRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUsersSharedByHierarchyRaw(requestParameters: GetUsersSharedByHierarchyRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.apiKey === null || requestParameters.apiKey === undefined) {
            throw new runtime.RequiredError('apiKey','Required parameter requestParameters.apiKey was null or undefined when calling getUsersSharedByHierarchy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/share/{apiKey}/by/hierarchy`.replace(`{${"apiKey"}}`, encodeURIComponent(String(requestParameters.apiKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getUsersSharedByHierarchy(requestParameters: GetUsersSharedByHierarchyRequest): Promise<Array<number>> {
        const response = await this.getUsersSharedByHierarchyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getUsersSharedWithHierarchyRaw(requestParameters: GetUsersSharedWithHierarchyRequest): Promise<runtime.ApiResponse<Array<Array<number>>>> {
        if (requestParameters.apiKey === null || requestParameters.apiKey === undefined) {
            throw new runtime.RequiredError('apiKey','Required parameter requestParameters.apiKey was null or undefined when calling getUsersSharedWithHierarchy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/share/{apiKey}/with/hierarchy`.replace(`{${"apiKey"}}`, encodeURIComponent(String(requestParameters.apiKey))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getUsersSharedWithHierarchy(requestParameters: GetUsersSharedWithHierarchyRequest): Promise<Array<Array<number>>> {
        const response = await this.getUsersSharedWithHierarchyRaw(requestParameters);
        return await response.value();
    }

}
