import React, { ReactElement } from 'react';
import { WorkIsPartOfOtherDTO as WorkIsPartOfOtherModel } from '../../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkIsPartOfOtherModel } from '../../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../../validation/interfaces';

const messages = defineMessages({
  articleId: { id: 'work.attr.isPartOfOther.attr.articleId' },
  articleIdPlaceholder: { id: 'work.attr.isPartOfOther.attr.articleId.placeholder' },
  issue: { id: 'work.attr.isPartOfOther.attr.issue' },
  pageFirst: { id: 'work.attr.isPartOfOther.attr.pageFirst' },
  pageLast: { id: 'work.attr.isPartOfOther.attr.pageLast' },
  name: { id: 'work.attr.isPartOfOther.attr.name' },
  volume: { id: 'work.attr.isPartOfOther.attr.volume' },
});

interface IsPartOfOtherProps {
  checkPermission: CheckPermissionFunction;
  isPartOf: WorkIsPartOfOtherModel[];
  onChange: (ipo: WorkIsPartOfOtherModel[]) => void;
  validation: ValidationResult;
}

function InnerWorkIsPartOfOther({
  isPartOf,
  onChange,
  intl,
  checkPermission,
  validation
}: IsPartOfOtherProps & WrappedComponentProps): ReactElement {
  return (
    <div className="col">
      <Styled.h2 >
        <FormattedMessage id="work.attr.isPartOfOther" />
      </Styled.h2>
      <Border>
        <MultiInputControls<WorkIsPartOfOtherModel>
          lines={isPartOf}
          onChange={onChange}
          add
          remove
          defaultRow={DefaultWorkIsPartOfOtherModel}
          renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
            <div className="addButton">
              <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
            </div>
          )}
          renderLines={(
            ipo: WorkIsPartOfOtherModel,
            onFieldChange: (data: WorkIsPartOfOtherModel) => void,
            actions: { [p: string]: (e: any) => void },
            idx: number
          ): ReactElement => (
            <div key={idx}>
              <React.Fragment>
                <TextInputP
                  name={intl.formatMessage(messages.name)}
                  value={ipo.name}
                  onChange={(name: string): void => onFieldChange({ ...ipo, name })}
                  permissions={checkPermission('name')}
                  validation={validation?.children[idx]?.name}
                  autoFocus={true}
                />
                <TextInputP
                  name={intl.formatMessage(messages.volume)}
                  value={ipo.volume}
                  onChange={(volume: string): void => onFieldChange({ ...ipo, volume })}
                  permissions={checkPermission('volume')}
                  validation={validation?.children[idx]?.volume}
                />
                <TextInputP
                  name={intl.formatMessage(messages.issue)}
                  value={ipo.issue}
                  onChange={(issue: string): void => onFieldChange({ ...ipo, issue })}
                  permissions={checkPermission('issue')}
                  validation={validation?.children[idx]?.issue}
                />
                <TextInputP
                  name={intl.formatMessage(messages.pageFirst)}
                  value={ipo.pageFirst}
                  onChange={(pageFirst: string): void => onFieldChange({ ...ipo, pageFirst })}
                  permissions={checkPermission('page_first')}
                  validation={validation?.children[idx]?.page_first}
                />
                <TextInputP
                  name={intl.formatMessage(messages.pageLast)}
                  value={ipo.pageLast}
                  onChange={(pageLast: string): void => onFieldChange({ ...ipo, pageLast })}
                  permissions={checkPermission('page_last')}
                  validation={validation?.children[idx]?.page_last}
                />
                <TextInputP
                  name={intl.formatMessage(messages.articleId)}
                  placeholder={intl.formatMessage(messages.articleIdPlaceholder)}
                  value={ipo.articleId}
                  onChange={(articleId: string): void => onFieldChange({ ...ipo, articleId })}
                  permissions={checkPermission('article_id')}
                  validation={validation?.children[idx]?.article_id}
                />
                {!!actions.delete && (
                  <div sx={{mb: "1em"}}>
                    <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                  </div>
                )}
              </React.Fragment>
            </div>
          )}
        />
      </Border>
    </div>
  );
}

export const WorkIsPartOfOtherComponent = injectIntl(InnerWorkIsPartOfOther);
