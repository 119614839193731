/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Hierarchy,
    HierarchyFromJSON,
    HierarchyToJSON,
} from '../models';

export interface GetOrganisationChildsRequest {
    organisationId: number;
}

export interface GetOrganisationHierarchyRequest {
    organisationId: number;
}

export interface GetOrganisationTopOrgaRequest {
    organisationId: number;
}

export interface GetPersonTopOrgaRequest {
    personId: number;
}

export interface GetProjectChildsRequest {
    projectId: number;
}

export interface GetProjectHierarchyRequest {
    projectId: number;
}

export interface GetProjectTopOrgaRequest {
    projectId: number;
}

/**
 * 
 */
export class HierarchyApi extends runtime.BaseAPI {

    /**
     */
    async getOrganisationChildsRaw(requestParameters: GetOrganisationChildsRequest): Promise<runtime.ApiResponse<Array<Hierarchy>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisationChilds.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/hierarchy/organisation/{organisationId}/childs`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HierarchyFromJSON));
    }

    /**
     */
    async getOrganisationChilds(requestParameters: GetOrganisationChildsRequest): Promise<Array<Hierarchy>> {
        const response = await this.getOrganisationChildsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOrganisationHierarchyRaw(requestParameters: GetOrganisationHierarchyRequest): Promise<runtime.ApiResponse<Array<Hierarchy>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisationHierarchy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/hierarchy/organisation/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HierarchyFromJSON));
    }

    /**
     */
    async getOrganisationHierarchy(requestParameters: GetOrganisationHierarchyRequest): Promise<Array<Hierarchy>> {
        const response = await this.getOrganisationHierarchyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOrganisationTopOrgaRaw(requestParameters: GetOrganisationTopOrgaRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisationTopOrga.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/hierarchy/topOrga/organisation/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getOrganisationTopOrga(requestParameters: GetOrganisationTopOrgaRequest): Promise<Array<string>> {
        const response = await this.getOrganisationTopOrgaRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonTopOrgaRaw(requestParameters: GetPersonTopOrgaRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonTopOrga.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/hierarchy/topOrga/person/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getPersonTopOrga(requestParameters: GetPersonTopOrgaRequest): Promise<Array<string>> {
        const response = await this.getPersonTopOrgaRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProjectChildsRaw(requestParameters: GetProjectChildsRequest): Promise<runtime.ApiResponse<Array<Hierarchy>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectChilds.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/hierarchy/project/{projectId}/childs`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HierarchyFromJSON));
    }

    /**
     */
    async getProjectChilds(requestParameters: GetProjectChildsRequest): Promise<Array<Hierarchy>> {
        const response = await this.getProjectChildsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProjectHierarchyRaw(requestParameters: GetProjectHierarchyRequest): Promise<runtime.ApiResponse<Array<Hierarchy>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectHierarchy.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/hierarchy/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HierarchyFromJSON));
    }

    /**
     */
    async getProjectHierarchy(requestParameters: GetProjectHierarchyRequest): Promise<Array<Hierarchy>> {
        const response = await this.getProjectHierarchyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProjectTopOrgaRaw(requestParameters: GetProjectTopOrgaRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectTopOrga.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/hierarchy/topOrga/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getProjectTopOrga(requestParameters: GetProjectTopOrgaRequest): Promise<Array<string>> {
        const response = await this.getProjectTopOrgaRaw(requestParameters);
        return await response.value();
    }

}
