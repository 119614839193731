import React, { useEffect, useState } from 'react';
import workReportImg from 'BaseLayout/assets/img/homepage/work-report.svg';
import orcidLinkImg from 'BaseLayout/assets/img/homepage/orcid-link.svg';
import publicationEmbedImg from 'BaseLayout/assets/img/homepage/publication-embed.svg';
/** @jsx jsx */
import { jsx, Container, Flex, Input } from 'theme-ui';
import { defineMessages, FormattedHTMLMessage, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Link } from "theme-ui";
import { Link as LinkRouter } from "react-router-dom";
import { OAuth2Sender } from 'Auth/components';
import ConnectedStatistics from 'HomePage/container/Statistics';
import { HomepageMobileStyling } from '../theme';
import { Props as HomepageProps } from 'HomePage/pages/Homepage';


const messages = defineMessages({ placeholder: { id: 'search.placeholder' } });

export const RubHomepageMobile = (props: { props: HomepageProps }): React.JSX.Element => {
  const searchTerm: string = props.props.changedSearchTerm ? props.props.changedSearchTerm : props.props.searchTerm;
  const intl: IntlShape = useIntl();
  const [term, setTerm] = useState(searchTerm);

  const onChangeSearchHandler = (value: string): void => {
    const search: string = value ? value : "";
    setTerm(search);
  };

  useEffect((): void => {
    setTerm('');
  }, []);

  return (
    <React.Fragment>
        <HomepageMobileStyling>
            <Container variant="banner" className="banner"></Container>
            <h1>
                <FormattedMessage id="rub.homepage.title" />
            </h1>
            <Flex className='search'>
                <Input
                    variant="inputFrontSearch"
                    type="text"
                    placeholder={intl.formatMessage(messages.placeholder)}
                    value={term}
                    onChange={(e): void => {
                    onChangeSearchHandler(e.target.value);
                    }}
                    onKeyUp={(e): void => {
                    if(e.keyCode === 13) {
                        props.props.onSearchSubmit((e.target as HTMLInputElement).value);
                    }
                    }}
                />
                <i className="icon-suche" onClick={() => { props.props.onSearchSubmit(term); }} />
            </Flex>

            <div className='leftColumn'>
                <FormattedHTMLMessage id="rub.homepage.intro.leftColumn" />
            </div>
            <div className='rightColumn'>
                <FormattedHTMLMessage id="rub.homepage.intro.rightColumn" />
            </div>

            <div sx={{ backgroundColor: 'lighter' }}>
                <div className='image'>
                    <img src={workReportImg} alt="" />
                </div>
                <div className='text'>
                    <h2>
                        <FormattedMessage id="rub.homepage.teaser1.title" />
                    </h2>
                    <p>
                        <FormattedHTMLMessage id="rub.homepage.teaser1.text" />
                    </p>
                    <OAuth2Sender
                        render={({ authUrl }) => (
                            <Link className='button' href={authUrl}>
                                <FormattedMessage id="rub.homepage.teaser1.linktext" />
                            </Link>
                        )}
                    />
                </div>
            </div>

            <div>
                <div className='image'>
                    <img src={orcidLinkImg} alt="" />
                </div>
                <div className='text'>
                    <h2>
                        <FormattedMessage id="rub.homepage.teaser2.title" />
                    </h2>
                    <p>
                        <FormattedHTMLMessage id="rub.homepage.teaser2.text" />
                    </p>
                    <LinkRouter className='button' to="/user/settings/orcid">
                        <FormattedMessage id="rub.homepage.teaser2.linktext" />
                    </LinkRouter>
                </div>
            </div>

            <div sx={{ backgroundColor: 'lighter' }}>
                <div className='image'>
                    <img src={publicationEmbedImg} alt="" />
                </div>
                <div className='text'>
                    <h2>
                        <FormattedMessage id="rub.homepage.teaser3.title" />
                    </h2>
                    <p>
                        <FormattedHTMLMessage id="rub.homepage.teaser3.text" />
                    </p>
                    <LinkRouter className='button' to="/publist/generator">
                        <FormattedMessage id="rub.homepage.teaser3.linktext" />
                    </LinkRouter>
                </div>
            </div>

            <ConnectedStatistics />
        </HomepageMobileStyling>
    </React.Fragment>
  );
};
