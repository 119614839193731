import * as React from 'react';
import { ReactElement, useState } from 'react';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import {
  OrganisationDTO,
  OrganisationInstitutionalIdDTO,
  OrganisationOtherNameDTO,
  OrganisationToPersonDTO,
  SubjectDTO,
  UrlDTO,
} from '../../api/core/models';
import { OrganisationOtherNames } from 'Edit/components/fields/organisation/OrganisationOtherNames';
import { DataModelGate, OAuth2Gate } from '../../Auth/components';
import { Checkbox } from '../../BaseLayout/components/form/generic/Checkbox';
import { Main } from '../../BaseLayout/components/base-layout/main';
import { DetailEditProps as Props, EditMode } from '../types';
import { ProjectSuggest } from '../components/auto-complete/ProjectSuggest';
import { OrganisationSuggest } from '../components/auto-complete/OrganisationSuggest';
import { DeleteOrga } from '../components/DeleteOrga';
import { BorderlessExternalId } from '../components/fields/ExternalIds';
import { Subjects } from '../components/fields/Subjects';
import { InstitutionalIds } from '../components/fields/InstitutionalIds';
import { DetailEditGrid } from '../components/theme';
import { OrganisationPersons } from 'Edit/components/fields/organisation/OrganisationPersons';
import { Urls } from 'Edit/components/fields/Urls';
import { AbortButton, SubmitButton } from 'Edit/components/SubmitAbortButton';
import { CheckPermissionFunction, ReadWritePermissions, WritableParam } from '../../Auth/components/DataModelGate';
import { TextInput, TextInputP } from '../../BaseLayout/components/form/generic/TextInput';
import { isRequired } from '../../BaseLayout/components/form/generic/utils';
import { Button, Label } from '@theme-ui/components';
import { TextAreaP } from 'BaseLayout/components/form/generic/Textarea';
import { Styled } from 'theme-ui';
import { useHistory } from "react-router-dom";
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { Validation } from '../validation/interfaces';
import { validate } from '../validation/validate';
import { OAuth2Container } from '../../Auth/containers/OAuth2Container';
import { Subscribe } from 'unstated';
import { isValid } from '../validation/isValid';
import { DatePickerStyled } from 'BaseLayout/components/form/DatePickerDateRange/DatePicker';
import { DateObject } from 'react-multi-date-picker';
import { InputLabel } from 'BaseLayout/components/form/generic/InputLabel';
/** @jsx jsx */
import { jsx } from 'theme-ui';

const messages = defineMessages({
  name: { id: 'edit.organisation.name_label' },
  origin: { id: 'edit.organisation.origin' },
  deskman: { id: 'edit.organisation.deskman' },
  other_name: {id: 'edit.organisation.other_name_label'}
});

export const DetailEditOrg: React.FC<Props<OrganisationDTO>> = (props) => {
  const history = useHistory();
  const intl: IntlShape = useIntl();
  const [deleteInit, setDeleteInit] = useState<boolean>(false);
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false);

  const { redirect, modifiedModel, updateField, updateAdminField } = props;

  if (!modifiedModel) return <FormattedMessage id="edit.loadling_text" />;
  if (redirect) return <Redirect to={redirect} />;

  const {
    id,
    name,
    otherNames,
    note,
    projects,
    childs,
    predecessors,
    successor,
    parents,
    urls,
    adminDataOrganisation,
    organisationInstitutionalIds,
    organisationSubjects,
    organisationPersons,
    startDate,
    endDate,
  } = modifiedModel;

  const {
    hidden,
    changed,
    created,
    origin,
    deskman
  } = adminDataOrganisation;

  return (
    <Subscribe to={[OAuth2Container]}>
      {(container: OAuth2Container) => {
        const validation: Validation = validate(modifiedModel, adminDataOrganisation, container.authorizer(), props.mode === EditMode.INSERT, "organisation");
        return (
          <>
            {deleteInit && <DeleteOrga
              orgaId={props.id}
              orgaName={name}
              isDeleteRequest={deleteRequest}
              onAbort={() => setDeleteInit(false)}
              onSuccess={() => history.push("/search/organisations")}
              onError={() => {
                setDeleteInit(false);
              }}
            />
            }
            <Main className="detail-edit">
              <form>
                <DetailEditGrid>
                  <div className="col">
                    <Styled.h2>
                      <FormattedMessage id="edit.organisation.general_head" />
                    </Styled.h2>
                    <DataModelGate entity="organisation" field="name" always={false}>
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <div className="form">
                            <TextInputP
                              value={name ? name : ''}
                              onChange={(_name: string): void => updateField('name', _name)}
                              name={intl.formatMessage(messages.name)}
                              inputName={'name'}
                              permissions={checkPermission()}
                              validation={validation?.root?.name}
                            />
                        </div>
                      )}
                    </DataModelGate>
                    <DataModelGate entity="organisation" always={false} field="other_names">
                    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
                      <OrganisationOtherNames
                        organisationOtherNames={otherNames}
                        onChange={(_organisationOtherNames: OrganisationOtherNameDTO[]): void => updateField('otherNames', _organisationOtherNames)}
                        checkPermission={checkPermission}
                        validation={validation?.root?.other_names}
                      />
                    )}
                  </DataModelGate>
                    <DataModelGate entity="organisation" field="urls" always={false}>
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <div className="form">
                            <Urls
                              checkPermission={checkPermission}
                              urls={urls ? urls : []}
                              typelist={'url_list'}
                              onChange={(_urls: UrlDTO[]): void => updateField('urls', _urls)}
                              validation={validation?.root?.urls}
                            />
                          </div>
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="note">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <TextAreaP
                            name={intl.formatMessage({id: "edit.admin.note_label"})}
                            onChange={(_note): void => updateField('note', _note)}
                            inputName={'organisationNote'}
                            value={note ? note : ''}
                            permissions={checkPermission()}
                            validation={validation?.root?.note}
                          />
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="start_date">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <DatePickerStyled
                            label={'edit.admin.start_date_label'}
                            disabled={!checkPermission().write}
                            value={startDate ? startDate : undefined}
                            required={isRequired(checkPermission())}
                            onChange={(_startDate: DateObject): void =>
                              updateField('startDate', _startDate === null ? undefined : new Date(_startDate.format("YYYY-MM-DD")))}
                            validation={validation?.root?.start_date}
                          />
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="end_date">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <DatePickerStyled
                            label={'edit.admin.end_date_label'}
                            disabled={!checkPermission().write}
                            value={endDate ? endDate : undefined}
                            required={isRequired(checkPermission())}
                            onChange={(_endDate: DateObject): void =>
                              updateField('endDate', _endDate === null ? undefined : new Date(_endDate.format("YYYY-MM-DD")))}
                            validation={validation?.root?.end_date}
                          />
                        )
                      }
                    </DataModelGate>
                  </div>

                  <div className="col">
                    <BorderlessExternalId modifiedModel={modifiedModel}
                                  updateField={updateField}
                                  entity={'organisation'}
                                  list={'external_unit_id_list'}
                                 validation={validation?.root?.external_ids}
                    />
                    <DataModelGate entity="organisation" field="organisation_institutional_ids" always={false}>
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <div className="form">
                            <InstitutionalIds
                              checkPermission={checkPermission}
                              typesList={'affiliations_list'}
                              institutionalIds={organisationInstitutionalIds ? organisationInstitutionalIds : []}
                              onChange={(_institutionalIds: OrganisationInstitutionalIdDTO[]): void => updateField('organisationInstitutionalIds', _institutionalIds)}
                              validation={validation?.root?.organisation_institutional_ids}
                            />
                          </div>
                        )
                      }
                    </DataModelGate>
                  </div>

                  <div className="col">
                    <Styled.h2>
                      <FormattedMessage id="edit.organisation.admin_head" />
                    </Styled.h2>
                    <DataModelGate always={false} entity="organisation" field="id">
                        {(writable) => (
                        <TextInput name={intl.formatMessage({id: "edit.admin.id_label"})} value={String(id || '')}  disabled={!writable}  />
                      )}
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation_admin_data" field="hidden">
                    {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                            <Checkbox
                              name={intl.formatMessage({id: "edit.admin.hidden_label"})}
                              checked={hidden}
                              disabled={!checkPermission().write}
                              onChange={(_hidden: boolean): void => updateAdminField('hidden', _hidden)}
                              validation={validation?.admin?.hidden}
                            />
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation_admin_data" field="editorial_status">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        <SelectInputP
                          name={intl.formatMessage({id: 'edit.admin.editorial_status_label'})}
                          value={modifiedModel.adminDataOrganisation.editorialStatus ? modifiedModel.adminDataOrganisation.editorialStatus : ''}
                          onChange={(_editorialStatus: string) => updateField('adminDataOrganisation', {
                            ...modifiedModel.adminDataOrganisation,
                            editorialStatus: _editorialStatus,
                          })}
                          list={'editorial_status_list'}
                          listPartition={'*'}
                          inputName={'editorialStatus'}
                          permissions={checkPermission()}
                          validation={validation?.admin?.editorial_status}
                        />
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation_admin_data" field="created">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <DatePickerStyled
                            label={'edit.admin.created'}
                            disabled={!checkPermission().write}
                            value={created ? created : undefined}
                            required={isRequired(checkPermission())}
                            onChange={(_createdDate: DateObject): void =>
                              updateAdminField('created', _createdDate === null ? undefined : new Date(_createdDate.format("YYYY-MM-DD")))
                            }
                          />
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation_admin_data" field="changed">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <DatePickerStyled
                            required={isRequired(checkPermission())}
                            label={'edit.admin.changed'}
                            disabled={!checkPermission().write}
                            value={changed ? changed : undefined}
                            onChange={(_changedDate: DateObject): void =>
                              updateAdminField('changed', _changedDate === null ? undefined : new Date(_changedDate.format("YYYY-MM-DD")))
                            }
                          />
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation_admin_data" field="origin">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (

                          <TextInputP
                            name={intl.formatMessage(messages.origin)}
                            value={origin}
                            onChange={(_origin: string): void => updateAdminField('origin', _origin)}
                            permissions={checkPermission()}
                            validation={validation?.admin?.origin}
                          />
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation_admin_data" field="deskman">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <TextInputP
                            name={intl.formatMessage(messages.deskman)}
                            value={deskman}
                            onChange={(_deskman: string): void => updateAdminField('deskman', _deskman)}
                            permissions={checkPermission()}
                            validation={validation?.admin?.deskman}
                          />
                        )
                      }
                    </DataModelGate>

                    <div className='buttons'>
                      <SubmitButton
                        disabled={!props.submitActive || !isValid(validation)}
                        onSubmit={() => props.submit()}
                      >
                        <FormattedMessage id="edit.submit_btn" />
                      </SubmitButton>

                      {props.mode === EditMode.INSERT &&
                        <AbortButton>
                          <FormattedMessage id="edit.abort_btn" />
                        </AbortButton>
                      }

                      {props.mode === EditMode.UPDATE &&
                        <>
                          <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
                            <Button
                              disabled={!props.deleteActive}
                              onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                                e.preventDefault();
                                setDeleteInit(true);
                                setDeleteRequest(false);
                              }}
                            >
                              <FormattedMessage id="button.Delete" />
                            </Button>
                          </OAuth2Gate>

                          <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
                            <Button
                              disabled={!props.deleteActive}
                              onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                                e.preventDefault();
                                setDeleteInit(true);
                                setDeleteRequest(true);
                              }}
                            >
                              <FormattedMessage id="button.Delete.Request" />
                            </Button>
                          </OAuth2Gate>
                        </>
                      }
                    </div>
                  </div>
                  <div className="col">
                    <DataModelGate always={false} entity="organisation" field="projects">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <div className="form" sx={{".organisation-projects": {
                            fontSize: '24px',
                            ml: '11px',
                            alignItems: 'center',
                            mb: '1em',
                            mt: '0.5em',
                            i: {
                              fontSize: '16px',
                              verticalAlign: 'super'
                            },
                            "div > div": {
                              fontSize: '16px'
                            }
                          }}}>
                            <InputLabel
                              classes={["organisation-projects"]}
                              variant="labelY"
                              label={intl.formatMessage({id: 'edit.organisation.projects_head'})}
                              help={intl.formatMessage({id: 'edit.organisation.projects_head.help'})}
                            />
                            <ProjectSuggest
                              required={isRequired(checkPermission())}
                              tags={projects ? projects : []}
                              onChange={(_projects: any[]): void => updateField('projects', _projects)}
                              disabled={!checkPermission().write}
                              validation={validation?.root?.projects}
                            />
                          </div>
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always entity="organisation" field="organisation_persons">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <div className="form">
                            <Styled.h2>
                              <FormattedMessage id="edit.organisation.person_head" />
                            </Styled.h2>
                            <OrganisationPersons
                              checkPermission={checkPermission}
                              orgPersons={organisationPersons ? organisationPersons : []}
                              onChange={(_orgPersons: OrganisationToPersonDTO[]): void => updateField('organisationPersons', _orgPersons)}
                              validation={validation?.root?.organisation_persons}
                            />
                          </div>
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="organisation_subjects">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <div className="form">
                            <Styled.h2>
                              <FormattedMessage id="edit.organisation.subjects_head" />
                            </Styled.h2>
                            <Subjects
                              checkPermission={checkPermission}
                              subjects={organisationSubjects ? organisationSubjects : []}
                              onChange={(_subjects: SubjectDTO[]): void => updateField('organisationSubjects', _subjects)}
                              validation={validation?.root?.organisation_subjects}
                            />
                          </div>
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="childs">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <Label variant="labelY">
                            <FormattedMessage id="edit.organisation.childs_head" />
                            <OrganisationSuggest
                              required={isRequired(checkPermission())}
                              tags={childs ? childs : []}
                              disabled={!checkPermission().write}
                              onChange={(_childs: any[]): void => updateField('childs', _childs)}
                              validation={validation?.root?.childs}
                            />
                          </Label>
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="parents">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <Label variant="labelY">
                            <FormattedMessage id="edit.organisation.parents_head" />
                            <OrganisationSuggest
                              required={isRequired(checkPermission())}
                              tags={parents ? parents : []}
                              disabled={!checkPermission().write}
                              onChange={(_parents: any[]): void => updateField('parents', _parents)}
                              validation={validation?.root?.parents}
                            />
                          </Label>
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="successor">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <Label variant="labelY">
                            <FormattedMessage id="edit.organisation.successor_head" />
                            <OrganisationSuggest
                              required={isRequired(checkPermission())}
                              tags={successor ? successor : []}
                              disabled={!checkPermission().write}
                              onChange={(_successor: any[]): void => updateField('successor', _successor)}
                              validation={validation?.root?.successor}
                            />
                          </Label>
                        )
                      }
                    </DataModelGate>
                    <DataModelGate always={false} entity="organisation" field="predecessors">
                      {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement =>
                        checkPermission().read && (
                          <Label variant="labelY">
                            <FormattedMessage id="edit.organisation.predecessors_head" />
                            <OrganisationSuggest
                              required={isRequired(checkPermission())}
                              tags={predecessors ? predecessors : []}
                              disabled={!checkPermission().write}
                              onChange={(_predecessors: any[]): void => updateField('predecessors', _predecessors)}
                              validation={validation?.root?.predecessors}
                            />
                          </Label>
                        )
                      }
                    </DataModelGate>
                  </div>
                </DetailEditGrid>
              </form>
            </Main>
          </>
        );
      }}
    </Subscribe>
  );
};
