import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Flex } from '@theme-ui/components';
import { DeleteButton } from 'BaseLayout/components/form/MultiInput/Buttons';
import { deleteByIdx, moveDown, moveUp } from '../utils';
import { WorkPersonDTO, WorkPersonOtherDTO } from 'api/core';
import { FieldRow } from 'Edit/components/theme';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { ValidationResults } from '../../../../../validation/interfaces';
import { PaginatedList } from './PaginatedList';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface WorkPersonAny {
  type: 'workPerson'|'workPersonOther';
  person: WorkPersonDTO | WorkPersonOtherDTO;
  validation?: ValidationResults;
}

interface WorkPersonListProps {
  list: WorkPersonAny[];
  children?: (item: WorkPersonAny, active: boolean, onChange: (wpa: WorkPersonAny) => void) => ReactElement;
  onChange: (wpas: WorkPersonAny[]) => void;
}

interface WorkPersonControlProps {
  list: WorkPersonAny[];
  idx: number;
  item?: WorkPersonAny;
  onChange: (wpas: WorkPersonAny[]) => void;
  onSetActiveItemIdx: (activeItemIdx: number) => void;
}

const messages = defineMessages({
  buttonTextAddPersonIntern: { id: 'work.button.text.addPersonIntern' },
  buttonTextAddPersonExtern: { id: 'work.button.text.addPersonExtern' },
  ttAddPersonIntern: { id: 'work.button.tooltip.addPersonIntern' },
  ttAddPersonExtern: { id: 'work.button.tooltip.addPersonExtern' },
});

export const Controls: React.FC<WorkPersonControlProps> = ({
  list,
  idx,
  item,
  onChange,
  onSetActiveItemIdx
}: WorkPersonControlProps): ReactElement => {
  const intl: IntlShape = useIntl();
  return (
    <Flex sx={{ gap: 4, alignItems: 'flex-end' }}>
      {!!item && (
        <div>
          <DeleteButton
            onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
              e.preventDefault();
              // const newArray: WorkPersonAny[] = [...list];
              deleteByIdx(list, idx);
              onChange(list);
              onSetActiveItemIdx(idx - 1); // TODO: doesnt work
            }}
          />
        </div>
      )}{' '}
      <div>
        <Button
          variant="icon"
          sx={{
            backgroundColor: 'secondary',
            mt: '-1px',
          }}
          onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
            e.preventDefault();
            const sequence = item?.person ? item.person.sequence + 1 : 1;
            for (const wpa of list) {
              if (wpa.person.sequence >= sequence) {
                wpa.person.sequence++;
              }
            }
            list.push({ type: 'workPerson', person: { sequence, personName: undefined } });
            onChange(list);
            onSetActiveItemIdx(sequence - 1);
          }}
          title={intl.formatMessage(messages.ttAddPersonIntern)}
        >
          {intl.formatMessage(messages.buttonTextAddPersonIntern)}
        </Button>
      </div>
      <div>
        <Button
          variant="icon"
          sx={{
            backgroundColor: 'secondary',
            mt: '-1px',
          }}
          onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
            e.preventDefault();
            const sequence = item?.person ? item.person.sequence + 1 : 1;
            for (const wpa of list) {
              if (wpa.person.sequence >= sequence) {
                wpa.person.sequence++;
              }
            }
            list.push({
              type: 'workPersonOther',
              person: {
                sequence,
                givenname: '',
                surname: '',
              }
            });
            onChange(list);
            onSetActiveItemIdx(sequence - 1);
          }}
          title={intl.formatMessage(messages.ttAddPersonExtern)}
        >
          {intl.formatMessage(messages.buttonTextAddPersonExtern)}
        </Button>
      </div>
      {
        !!item?.person && (
          <div>
            <Button
              variant="icon"
              disabled={item.person.sequence === 1}
              sx={{
                backgroundColor: 'white',
                color: 'primary',
                boxShadow: 'inset 0px 0px 0px 3px lightgrey',
                "&:disabled": {
                  color: '#E5E5E5',
                  boxShadow: 'inset 0px 0px 0px 3px #E5E5E5',
                }
              }}
              onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
                e.preventDefault();
                const newArray: WorkPersonAny[] = [...list];
                moveUp(newArray, idx);
                onChange(newArray);
                onSetActiveItemIdx(idx - 1 >= 0 ? idx - 1 : 0);
              }}
            >
              <span className="icon-icon-pfeil_oben"/>
            </Button>
          </div>
        ) /* only show if not first*/
      }
      {
        !!item?.person && (
          <div>
            <Button
              variant="icon"
              disabled={item.person.sequence === list[list.length - 1].person.sequence}
              sx={{
                backgroundColor: 'white',
                color: 'primary',
                boxShadow: 'inset 0px 0px 0px 3px lightgrey',
                "&:disabled": {
                  color: '#E5E5E5',
                  boxShadow: 'inset 0px 0px 0px 3px #E5E5E5',
                }
              }}
              onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
                e.preventDefault();
                const newArray: WorkPersonAny[] = [...list];
                moveDown(newArray, idx);
                onChange(newArray);
                onSetActiveItemIdx(idx + 1 < list.length ? idx + 1 : list.length - 1);
              }}
            >
              <span className="icon-icon-pfeil_unten"/>
            </Button>
          </div>
        ) /* only show if not last*/
      }
    </Flex>
  );
};

export const WorkPersonList: React.FC<WorkPersonListProps> = ({ list, children, onChange }: WorkPersonListProps) => {
  const defaultPageSize = 100;

  const [activeItemIdx, setActiveItemIdx] = useState<number>(0);

  if (list.length === 0) {
    return (
      <Controls
        list={list}
        idx={0}
        onChange={(wpa) => {onChange(wpa);setActiveItemIdx(2);}}
        onSetActiveItemIdx={idx => setActiveItemIdx(idx)}
      />
    );
  }

  return (
    <PaginatedList
      list={list}
      pageNumber={0}
      pageSize={defaultPageSize}
      pageSizeOptions={[10, 20, 50, 100, 200]}
    >
      {(item, list, pageIdx, listIdx) => {
        const active = pageIdx === activeItemIdx;
        return (
          <Flex sx={{ gap: 3 }} key={item.person.sequence}>
            <FieldRow>
              {children(item, active, (wpa: WorkPersonAny): void => {
                const newArray: WorkPersonAny[] = [...list];
                newArray[listIdx] = wpa;
                onChange(newArray);
              })}
              <div className="controls">
                <Controls
                  list={list}
                  idx={listIdx}
                  item={item}
                  onChange={onChange}
                  onSetActiveItemIdx={idx => setActiveItemIdx(idx)}
                />
              </div>
            </FieldRow>
          </Flex>
        );
      }}
    </PaginatedList>
  );
};
