import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isNullOrUndefined } from 'is-what';
import { OAuth2Gate } from 'Auth/components';
import { DeleteProject } from 'Edit/components/DeleteProject';
import { ViewType } from 'Detail/types';
import { Notation } from 'Vocabulary/components/Notation';
import { Link } from '@theme-ui/components';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { Authorizer } from 'Auth/utils/Authorizer';
import { SearchProject } from 'Dashboard/types';
import { idHashFilter } from './Result';
import { renderDateTime, renderExternalIds } from 'Detail/utils';
import { getDetailContextUrl } from 'data';
import OptionList from './OptionList';

export interface ProjectResultProps {
  index: number;
  result: SearchProject;
  updateResults: () => {};
}

export const ProjectResult = (props: ProjectResultProps): JSX.Element => {
  return (
    <Subscribe to={[OAuth2Container]}>{(c: OAuth2Container) => {
      const innerProps = { ...props, authorizer: c.authorizer()};
      return <InnerProjectResult {...innerProps} />;
    }}
    </Subscribe>
  );
};

interface InnerProjectResultProps extends ProjectResultProps {
  authorizer: Authorizer;
}

const InnerProjectResult = (props: InnerProjectResultProps): JSX.Element => {
  const [deleteInit, setDeleteInit] = useState(false);
  const { index, result, authorizer } = props;

  const reduce = (prev: JSX.Element[], curr: JSX.Element) => [...prev, ' > ', curr];

  const externalIds = renderExternalIds(
    isNullOrUndefined(result.gnd) ? [] : result.gnd.map((e) => ({ externalId: e, type: 'gnd' })),
    'project',
  );

  const hierarchy = result.hierarchy ? result.hierarchy.slice(1) : [];
  hierarchy.reverse();

  const hierarchyEntries = hierarchy
    .map((e) => idHashFilter(e))
    .map((e) =>
      undefined !== e.id ? (
        <Link key={e.id} href={getDetailContextUrl(ViewType.PROJECTS, e.id)}>
          {e.name}
        </Link>
      ) : (
        <span key={e.name}>{e.name}</span>
      )
    )
    .reduce(reduce, [])
    .slice(1);

  const options = [];
  if (authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN'])) {
    options.push(
      <li key="edit">
        <Link href={`/project/${props.result.id}/edit`}>
          <FormattedMessage id="button.Edit" />
        </Link>
      </li>
    );
    options.push(
      <li key="delete">
        <a
          href="#"
          onClick={async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setDeleteInit(true);
          }}
        >
          {authorizer.hasRole('ROLE_ADMIN') &&
          <FormattedMessage id="button.Delete" />
          }
          {authorizer.hasRole('ROLE_EDITOR') &&
          <FormattedMessage id="button.DeleteRequest" />
          }
        </a>
      </li>
    );
  }

  return (
    <>
    {deleteInit &&
    <DeleteProject
      projectId={parseInt(result.id, 10)}
      projectName={result.name.join('; ')}
      isDeleteRequest={!authorizer.hasRole('ROLE_ADMIN')}
      onAbort={() => setDeleteInit(false)}
      onSuccess={() => {
        setDeleteInit(false);
        props.updateResults();
      }}
      onError={() => {
        setDeleteInit(false);
      }}
    />
    }
    <div className="result" key={result.id}>
      <div className="content">
        <div className="info main">
          <div className="main-title">
            <Link href={getDetailContextUrl(ViewType.PROJECTS, result.id)}>
              {result.name ? (
                result.name.join('; ')
              ) : (
                <i>
                  <FormattedMessage id="No_information" />
                </i>
              )}
            </Link>
          </div>
          {externalIds}
          <div className="hierarchy">{hierarchyEntries}</div>
        </div>
        <div className="info right">
          <div className="record status">
            <FormattedMessage id="entity.attr.editorialStatus" />:{' '}
            <Notation v="editorial_status_list" k={result.editorialStatus} />
          </div>
          <div className="record creation">
            <FormattedMessage id="entity.attr.recordCreationDate" />: {renderDateTime(result.recordCreationDate)}
          </div>
          <div className="record change">
            <FormattedMessage id="entity.attr.recordChangeDate" />: {renderDateTime(result.recordChangeDate)}
          </div>
        </div>
      </div>
      <div className="actions">
        <span className="index">#{index + 1}</span>
        <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
          <OptionList id={result.id} options={options} updateResults={props.updateResults} />
        </OAuth2Gate>
      </div>
    </div>
    </>
  );
};

export default ProjectResult;
