import { checkReadPermission } from 'Auth/utils/DataModel';
import { any } from 'prop-types';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ConnectedUserProfile } from 'UserArea';

export interface Props {
  checked: boolean;
  disabled?: boolean;
  value?: string | number;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  titleText?: string;
  children?: any;
}

function Checkbox (props: Props) {
  const onChange = props.onChange ? props.onChange : () => {};
  const className = props.className ? props.className : '';
  const value = props.value ? props.value : '';
  const checked = props.checked ? props.checked : false;
  const disabled = props.disabled ? props.disabled : false;
  const name = props.name ? props.name : '';
  const titleText = props.titleText ? props.titleText : '';
  const {children} = props;

  return (
    <label className="checkbox-label" title={titleText}>
      <input
        name={name}
        type="checkbox"
        className={'checkbox' + (!!className ? ' ' + className : '')}
        onChange={(e) => {onChange(e);}}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <span className={'helper' + (!!disabled ? ' disabled' : '')}></span>
      {children}
    </label>
  );
}

export default Checkbox;