/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Csl,
    CslFromJSON,
    CslToJSON,
    PubListConfig,
    PubListConfigFromJSON,
    PubListConfigToJSON,
} from '../models';

export interface GetCslRequest {
    pubListConfig: PubListConfig;
}

export interface GetSingleCslRequest {
    workId: number;
    locale?: string;
}

/**
 * 
 */
export class CslServiceApi extends runtime.BaseAPI {

    /**
     */
    async getCslRaw(requestParameters: GetCslRequest): Promise<runtime.ApiResponse<Array<Csl>>> {
        if (requestParameters.pubListConfig === null || requestParameters.pubListConfig === undefined) {
            throw new runtime.RequiredError('pubListConfig','Required parameter requestParameters.pubListConfig was null or undefined when calling getCsl.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/csl`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PubListConfigToJSON(requestParameters.pubListConfig),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CslFromJSON));
    }

    /**
     */
    async getCsl(requestParameters: GetCslRequest): Promise<Array<Csl>> {
        const response = await this.getCslRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getSingleCslRaw(requestParameters: GetSingleCslRequest): Promise<runtime.ApiResponse<Csl>> {
        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling getSingleCsl.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.locale !== undefined) {
            queryParameters['locale'] = requestParameters.locale;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/csl/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters.workId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CslFromJSON(jsonValue));
    }

    /**
     */
    async getSingleCsl(requestParameters: GetSingleCslRequest): Promise<Csl> {
        const response = await this.getSingleCslRaw(requestParameters);
        return await response.value();
    }

}
