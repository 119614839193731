import { EmploymentPeriodDTO, PersonToOrganisationDTO, ReferencedOrganisationDTO } from 'api/core/models';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { OrganisationSuggest } from 'Edit/components/auto-complete/OrganisationSuggest';
import React, { ReactElement, ReactNode } from 'react';
import { EmploymentPeriods } from './EmploymentPeriods';
import { DefaultPersonOrganisation } from '../../../data/emptyModels';
import { CheckPermissionFunction } from '../../../../Auth/components/DataModelGate';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';
import { FormattedMessage } from 'react-intl';
import { Card, Label } from '@theme-ui/components';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

interface Props {
  checkPermission: CheckPermissionFunction;
  organisations: PersonToOrganisationDTO[];
  onChange: (po: PersonToOrganisationDTO[]) => void;
  validation: ValidationResult;
}

export const PersonOrganisations: React.FC<Props> = ({ checkPermission, organisations, onChange, validation }: Props): ReactElement => {
  return (
    <div className="form form-highlight">
      <MultiInputControls<PersonToOrganisationDTO>
        add
        remove
        defaultRow={DefaultPersonOrganisation}
        lines={organisations}
        onChange={onChange}
        renderLines={(personOrganisation: PersonToOrganisationDTO, onChange2: (po: PersonToOrganisationDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
            <div>
              {checkPermission('organisation').read &&
              <div className="form">
                <Label variant="labelY">
                  <FormattedMessage id="entity.organisation" />:
                </Label>
                <OrganisationSuggest
                  required={isRequired(checkPermission())}
                  single
                  canDelete={false}
                  disabled={!checkPermission('organisation').write}
                  tags={!!personOrganisation.organisation ? [personOrganisation.organisation] : []}
                  validation={validation?.children[idx]?.organisation}
                  onChange={(org: ReferencedOrganisationDTO[]) => {
                    onChange2({ ...personOrganisation, organisation: org[0] });
                  }}
                  autoFocus={true}
                />
              </div>
              }
              <Card variant="form">
              {checkPermission('employment_periods').read &&
              <div className="form form-highlight">
                <Label variant="labelY">
                  <FormattedMessage id="employment_periods" />:
                </Label>
                <EmploymentPeriods
                  checkPermission={checkPermission}
                  employmentPeriods={personOrganisation.employmentPeriods}
                  validation={validation?.children[idx]?.employment_periods}
                  onChange={(employmentPeriods: EmploymentPeriodDTO[]) => {
                    onChange2({ ...personOrganisation, employmentPeriods });
                  }}
                />
              </div>
              }
              </Card>
              <div className='deleteButton'>
                <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
              </div>
            </div>
        )}
        renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactNode => (
          <div className='addButton'>
            <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
          </div>
        )}
      />
    </div>
  );
};
