import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { TudoHomepageMobile } from './TudoMobile';
import { TudoHomepageDesktop } from './TudoDesktop';
import { Props } from 'HomePage/pages/Homepage';

export const TudoHomepage = (props: Props): React.JSX.Element => {
  return props.breakpoint <= 2 ? (
    <React.Fragment>
      <TudoHomepageMobile props={props} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <TudoHomepageDesktop props={props} />
    </React.Fragment>
  );
};
