/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkOtherTitleDTO
 */
export interface WorkOtherTitleDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkOtherTitleDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkOtherTitleDTO
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkOtherTitleDTO
     */
    otherTitle?: string;
}

export function WorkOtherTitleDTOFromJSON(json: any): WorkOtherTitleDTO {
    return WorkOtherTitleDTOFromJSONTyped(json, false);
}

export function WorkOtherTitleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOtherTitleDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'otherTitle': !exists(json, 'otherTitle') ? undefined : json['otherTitle'],
    };
}

export function WorkOtherTitleDTOToJSON(value?: WorkOtherTitleDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'language': value.language,
        'otherTitle': value.otherTitle,
    };
}


