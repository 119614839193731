import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { HHUHomepageMobile } from './HHUMobile';
import { HHUHomepageDesktop } from './HHUDesktop';
import { Props } from 'HomePage/pages/Homepage';

export const HHUHomepage = (props: Props): React.JSX.Element => {
  return props.breakpoint <= 2 ? (
    <React.Fragment>
      <HHUHomepageMobile props={props} />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <HHUHomepageDesktop props={props} />
    </React.Fragment>
  );
};
