/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminDataWorkOpenAccessFundedDTO,
    AdminDataWorkOpenAccessFundedDTOFromJSON,
    AdminDataWorkOpenAccessFundedDTOFromJSONTyped,
    AdminDataWorkOpenAccessFundedDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdminDataWorkOpenAccessDTO
 */
export interface AdminDataWorkOpenAccessDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminDataWorkOpenAccessDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkOpenAccessDTO
     */
    accessLevel?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkOpenAccessDTO
     */
    articleProcessingCharge?: string;
    /**
     * 
     * @type {Array<AdminDataWorkOpenAccessFundedDTO>}
     * @memberof AdminDataWorkOpenAccessDTO
     */
    adminDataWorkOpenAccessFundeds?: Array<AdminDataWorkOpenAccessFundedDTO>;
}

export function AdminDataWorkOpenAccessDTOFromJSON(json: any): AdminDataWorkOpenAccessDTO {
    return AdminDataWorkOpenAccessDTOFromJSONTyped(json, false);
}

export function AdminDataWorkOpenAccessDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDataWorkOpenAccessDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'accessLevel': !exists(json, 'accessLevel') ? undefined : json['accessLevel'],
        'articleProcessingCharge': !exists(json, 'articleProcessingCharge') ? undefined : json['articleProcessingCharge'],
        'adminDataWorkOpenAccessFundeds': !exists(json, 'adminDataWorkOpenAccessFundeds') ? undefined : ((json['adminDataWorkOpenAccessFundeds'] as Array<any>).map(AdminDataWorkOpenAccessFundedDTOFromJSON)),
    };
}

export function AdminDataWorkOpenAccessDTOToJSON(value?: AdminDataWorkOpenAccessDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'accessLevel': value.accessLevel,
        'articleProcessingCharge': value.articleProcessingCharge,
        'adminDataWorkOpenAccessFundeds': value.adminDataWorkOpenAccessFundeds === undefined ? undefined : ((value.adminDataWorkOpenAccessFundeds as Array<any>).map(AdminDataWorkOpenAccessFundedDTOToJSON)),
    };
}


