import React, { ReactElement, useEffect, useState } from 'react';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { WorkLanguageDTO } from 'api/core/models';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkLanguage } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';
import { Authorizer } from 'Auth/utils/Authorizer';

interface WorkLanguagesProps {
  workLanguages?: WorkLanguageDTO[];
  onChange: (data: WorkLanguageDTO[]) => void;
  listPartition: string;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
  innerProps: {authorizer: Authorizer};
}

const messages = defineMessages({
  languages: { id: 'work.attr.languages' },
  languagesHelp: { id: 'work.attr.languages.help' },
  language: { id: 'work.attr.language' },
});

export function WorkLanguages<T>({
  workLanguages,
  onChange,
  listPartition,
  checkPermission,
  validation,
  innerProps
}: WorkLanguagesProps): ReactElement {
  const [languageDefault, setLanguageDefault] = useState<boolean>(window.location.href.includes('add') && innerProps.authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN'])? true : false);
  const intl: IntlShape = useIntl();

  if ((workLanguages.length === 0) && languageDefault) {
    workLanguages.push({language: undefined});
  }

  useEffect(()=>{
    setLanguageDefault(false);
  },[]);

  return (
    <Border>
      <Styled.h3>{intl.formatMessage(messages.languages)}</Styled.h3>
      <MultiInputControls<WorkLanguageDTO>
        lines={workLanguages}
        onChange={(lines: WorkLanguageDTO[]): void => onChange(lines)}
        add
        defaultRow={DefaultWorkLanguage}
        remove={true}
        renderLines={(wl: WorkLanguageDTO, onLineChange: (wl: WorkLanguageDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
          <div className="innerBorder">
            <div className="fieldRow">
              <SelectInputP
                listPartition={listPartition}
                onChange={(language: string): void => onLineChange({ language })}
                name={intl.formatMessage(messages.languages)}
                help={intl.formatMessage(messages.languagesHelp)}
                list={'language_list'}
                inputName={'language'}
                value={wl.language ? wl.language : ''}
                permissions={checkPermission('language')}
                validation={validation?.children[idx]?.language}
                autoFocus={languageDefault ? false : true}
              />
              {!!actions.delete && (
                <div className="deleteButton">
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          </div>
        )}
        renderEnd={(actionAdd) => (
          <div className="addButton">
            <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
          </div>
        )}
      />
    </Border>
  );
}
