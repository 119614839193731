import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Flex, Box, Styled } from 'theme-ui';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const FooterMobile = (): React.JSX.Element => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        backgroundColor: 'primary',
        color: 'white',
        mt: 'auto'
      }}
    >
      <Box px={'15px'} py={'14px'} sx={{ width: '100%', fontSize: '14px' }}>
        <Flex
          sx={{
            justifyContent: 'space-between',
            a: {
              textDecoration: 'underline',
              color: 'white',
              '&:hover': {
                cursor: 'pointer'
              }
            },
          }}
        >
          <FormattedMessage id="footer.copyright"/>
          <div>
            <span className="icon-icon-pfeil_oben"/>
            <Styled.a onClick={ () => { window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); } }>
              <FormattedMessage id="footer.goUp"/>
            </Styled.a>
          </div>
        </Flex>
      </Box>
    </Flex>
  );
};
