/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchFacet,
    SearchFacetFromJSON,
    SearchFacetFromJSONTyped,
    SearchFacetToJSON,
    SolrProject,
    SolrProjectFromJSON,
    SolrProjectFromJSONTyped,
    SolrProjectToJSON,
} from './';

/**
 * 
 * @export
 * @interface SolrResultSolrProject
 */
export interface SolrResultSolrProject {
    /**
     * 
     * @type {number}
     * @memberof SolrResultSolrProject
     */
    numberResults?: number;
    /**
     * 
     * @type {Array<SolrProject>}
     * @memberof SolrResultSolrProject
     */
    results?: Array<SolrProject>;
    /**
     * 
     * @type {Array<SearchFacet>}
     * @memberof SolrResultSolrProject
     */
    facets?: Array<SearchFacet>;
}

export function SolrResultSolrProjectFromJSON(json: any): SolrResultSolrProject {
    return SolrResultSolrProjectFromJSONTyped(json, false);
}

export function SolrResultSolrProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolrResultSolrProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberResults': !exists(json, 'numberResults') ? undefined : json['numberResults'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(SolrProjectFromJSON)),
        'facets': !exists(json, 'facets') ? undefined : ((json['facets'] as Array<any>).map(SearchFacetFromJSON)),
    };
}

export function SolrResultSolrProjectToJSON(value?: SolrResultSolrProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberResults': value.numberResults,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(SolrProjectToJSON)),
        'facets': value.facets === undefined ? undefined : ((value.facets as Array<any>).map(SearchFacetToJSON)),
    };
}


