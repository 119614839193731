import React, { ReactElement } from 'react';
import { DataModelGate } from '../../../../../Auth/components';
import { AdminDataWorkDTO, WorkDTO } from '../../../../../api/core';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { TextInputP } from '../../../../../BaseLayout/components/form/generic/TextInput';
import { ValidationResult } from '../../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../../Auth/components/DataModelGate';


const messages = defineMessages({
  articleProcessingCharge: { id: 'work.attr.adminDataWork.attr.adminDataWorkOpenAccess.attr.articleProcessingCharge' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  className?: string;
  validation: ValidationResult;
}

export function ArticleProcessingCharge({modifiedModel, entity, updateField, className, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate always={false} entity={entity} field="admin_data_work_open_access">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <div>
        <TextInputP
          classes={[className]}
          value={
            modifiedModel.adminDataWork.adminDataWorkOpenAccess?.articleProcessingCharge
              ? modifiedModel.adminDataWork.adminDataWorkOpenAccess.articleProcessingCharge
              : ''
          }
          inputName="articleProcessingCharge"
          permissions={checkPermission("article_processing_charge")}
          name={intl.formatMessage(messages.articleProcessingCharge)}
          validation={validation?.child?.article_processing_charge}
          onChange={(articleProcessingCharge: string): void =>
            updateField('adminDataWork', {
              ...modifiedModel.adminDataWork,
              adminDataWorkOpenAccess: modifiedModel.adminDataWork.adminDataWorkOpenAccess ? {
                ...modifiedModel.adminDataWork.adminDataWorkOpenAccess,
                articleProcessingCharge,
              } : { articleProcessingCharge },
            })
          }
        />
      </div>
    )}
  </DataModelGate>;
}
