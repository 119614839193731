import * as React from 'react';
import { ReactElement, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Redirect } from 'react-router';
import {
  PersonDTO,
  PersonEmailDTO,
  PersonInstitutionalIdDTO,
  PersonNameDTO,
  PersonStatusDTO,
  UrlDTO,
} from '../../api/core/models';
import { OAuth2Gate } from '../../Auth/components';
import { Checkbox } from '../../BaseLayout/components/form/generic/Checkbox';
import { Main } from '../../BaseLayout/components/base-layout/main';
import { DetailEditProps as Props, EditMode } from '../types';
import { ProjectSuggest } from '../components/auto-complete/ProjectSuggest';
import { DeletePerson } from '../components/DeletePerson';
import { BorderlessExternalId } from '../components/fields/ExternalIds';
import { InstitutionalIds } from '../components/fields/InstitutionalIds';
import { Urls } from '../components/fields/Urls';
import { Statuses } from '../components/fields/Statuses';
import { DetailEditGrid } from '../components/theme';
import { PersonOrganisations } from 'Edit/components/fields/person/PersonOrganisations';
import { PersonNames } from 'Edit/components/fields/person/PersonNames';
import { Emails } from '../components/fields/person/Emails';
import { DataModelGate } from 'Auth/components';
import { AbortButton, SubmitButton } from 'Edit/components/SubmitAbortButton';
import { isRequired } from '../../BaseLayout/components/form/generic/utils';
import { Button } from '@theme-ui/components';
import { TextInput } from 'BaseLayout/components/form/generic/TextInput';
import { Styled } from 'theme-ui';
import { useHistory } from "react-router-dom";
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { OAuth2Container } from '../../Auth/containers/OAuth2Container';
import { Validation } from '../validation/interfaces';
import { validate } from '../validation/validate';
import { Subscribe } from 'unstated';
import { TextAreaP } from '../../BaseLayout/components/form/generic/Textarea';
import { isValid } from '../validation/isValid';
import { DateObject } from "react-multi-date-picker";
import { DatePickerStyled } from 'BaseLayout/components/form/DatePickerDateRange/DatePicker';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CheckPermissionFunction, ReadWritePermissions, WritableParam } from '../../Auth/components/DataModelGate';

export const DetailEditPeople: React.FC<Props<PersonDTO>> = (props) => {
  const intl: IntlShape = useIntl();
  const history = useHistory();
  const [deleteInit, setDeleteInit] = useState<boolean>(false);
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false);

  const { redirect, modifiedModel, updateField, updateAdminField } = props;

  if (!modifiedModel) return <FormattedMessage id="edit.loadling_text" />;
  if (redirect) return <Redirect to={redirect} />;

  const {
    id,
    personNames,
    personStatuses,
    urls,
    personEmails,
    note,
    externalIds,
    personInstitutionalIds,
    adminDataPerson,
    projects,
    personOrganisations,
  } = modifiedModel;

  const {
    dataLastSupplied,
    personChecked,
    editorialStatus,
    hidden
  } = adminDataPerson;

  return (
    <Subscribe to={[OAuth2Container]}>
      {(container: OAuth2Container) => {
        const validation: Validation = validate(modifiedModel, adminDataPerson, container.authorizer(), props.mode === EditMode.INSERT, "person");
        return (
          <>
          {deleteInit &&
          <DeletePerson
            personId={props.id}
            isDeleteRequest={deleteRequest}
            onAbort={() => setDeleteInit(false)}
            onSuccess={() => history.push("/search/persons")}
            onError={(e) => {
              alert("Fehler beim Löschen!");
            }}
          />
          }
          <Main className="detail-edit">
            <form>
              <DetailEditGrid>
                <div className="col">
                  <div className="form">
                    <DataModelGate entity="person" always={false} field="person_names">
                      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
                        <PersonNames
                          personNames={personNames}
                          onChange={(_personNames: PersonNameDTO[]): void => updateField('personNames', _personNames)}
                          checkPermission={checkPermission}
                          validation={validation?.root?.person_names}
                        />
                      )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate entity="person" field="person_statuses">
                      {(writable, readable, checkPermission) =>
                        readable && (
                          <Statuses
                            checkPermission={checkPermission}
                            statuses={personStatuses}
                            statusList={'person_status_list'}
                            affiliationList={'affiliations_list'}
                            validation={validation?.root?.person_statuses}
                            onChange={(statuses: PersonStatusDTO[]) => {
                              updateField('personStatuses', statuses);
                            }}
                          />
                        )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate entity="person" always={false} field="urls">
                      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
                        <Urls
                          checkPermission={checkPermission}
                          urls={urls}
                          typelist={'url_list'}
                          onChange={(u: UrlDTO[]) => updateField('urls', u)}
                          validation={validation?.root?.urls}
                        />
                      )}
                    </DataModelGate>
                  </div>
                  <DataModelGate entity="person" field="person_emails" always>
                    {(writable_email, readable_email, checkPermission) => (
                      <Emails
                        checkPermission={checkPermission}
                        emails={personEmails}
                        onChange={(pe: PersonEmailDTO[]) => updateField('personEmails', pe)}
                        validation={validation?.root?.person_emails}
                      />
                    )}
                  </DataModelGate>
                </div>
                <div className="col">
                  <div className="form">
                    <BorderlessExternalId
                      modifiedModel={props.modifiedModel}
                      updateField={updateField}
                      entity={'person'}
                      list={'external_person_id_list'}
                      validation={validation?.root?.external_ids}
                    />
                  </div>
                  <div className="form">
                    <DataModelGate entity="person" always={false} field="person_institutional_ids">
                      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
                        <InstitutionalIds
                          checkPermission={checkPermission}
                          typesList={'affiliations_list'}
                          institutionalIds={personInstitutionalIds}
                          validation={validation?.root?.person_institutional_ids}
                          onChange={(iId: PersonInstitutionalIdDTO[]) => {
                            updateField('personInstitutionalIds', iId);
                          }}
                        />
                      )}
                    </DataModelGate>
                  </div>
                </div>
                <div className="col">
                  <Styled.h2>
                    <FormattedMessage id="edit.admin_head" />
                  </Styled.h2>
                  <div className="form">
                    <DataModelGate always={false} entity="person" field="id">
                      {(writable) => (
                          <TextInput name={intl.formatMessage({id: "edit.admin.id_label"})} value={String(id || '')}  disabled={!writable} validation={validation?.root?.id}  />
                      )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate always={false} entity="person_admin_data" field="hidden">
                      {(writable) => (
                        <Checkbox
                          name={intl.formatMessage({id: "edit.admin.hidden_label"})}
                          disabled={!writable}
                          checked={hidden}
                          validation={validation?.admin?.hidden}
                          onChange={(_hidden: boolean): void => updateField('adminDataPerson', {
                            ...modifiedModel.adminDataPerson,
                            hidden: _hidden
                          })}
                        />
                      )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate always={false} entity="person_admin_data" field="data_last_supplied">
                      {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement => (
                        <DatePickerStyled
                          label={"edit.admin.last_supplied_label"}
                          disabled={!writable}
                          value={dataLastSupplied ? dataLastSupplied : undefined}
                          onChange={(date: DateObject): void => updateField('adminDataPerson', {
                            ...modifiedModel.adminDataPerson,
                            dataLastSupplied: date ? new Date(date.format("YYYY-MM-DD")) : undefined,
                          })}
                          required={isRequired(checkPermission())}
                          validation={validation?.admin?.data_last_supplied}
                        />
                      )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate always={false} entity="person_admin_data" field="person_checked">
                      {(writable: WritableParam, readablePerson: boolean, checkPermission: (child?: string) => ReadWritePermissions): ReactElement => (
                        <DatePickerStyled
                          label={"edit.admin.person_checked_label"}
                          disabled={!writable}
                          value={personChecked ? personChecked : undefined}
                          required={isRequired(checkPermission())}
                          onChange={(date: DateObject) => updateField('adminDataPerson', {
                            ...modifiedModel.adminDataPerson,
                            personChecked: date ? new Date(date.format("YYYY-MM-DD")) : undefined,
                          })}
                          validation={validation?.admin?.person_checked}
                        />
                      )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate always={false} entity="person" field="note">
                      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
                        <TextAreaP
                          name={intl.formatMessage({ id: "edit.admin.note_label" })}
                          disabled={!writable}
                          validation={validation?.root?.note}
                          onChange={(_note: string): void => {
                            updateField('note', _note);
                          }}
                          value={note}
                          inputName={'note'}
                         permissions={checkPermission()}></TextAreaP>
                      )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate entity="person_admin_data" field="editorial_status">
                      {(writablePerson, readablePerson, checkPermission) => (
                        <SelectInputP
                          name={intl.formatMessage({id: 'edit.admin.editorial_status_label'})}
                          value={modifiedModel.adminDataPerson.editorialStatus ? modifiedModel.adminDataPerson.editorialStatus : ''}
                          validation={validation?.admin?.editorial_status}
                          onChange={(_editorialStatus: string) => updateField('adminDataPerson', {
                            ...modifiedModel.adminDataPerson,
                            editorialStatus: _editorialStatus,
                          })}
                          list={'editorial_status_list'}
                          listPartition={'*'}
                          inputName={'editorialStatus'}
                          permissions={checkPermission()}
                        />
                        )
                      }
                    </DataModelGate>
                  </div>

                  <div className='buttons'>
                    <SubmitButton
                      disabled={!props.submitActive || !isValid(validation)}
                      onSubmit={() => props.submit()}
                    >
                      <FormattedMessage id="edit.submit_btn" />
                    </SubmitButton>

                    {props.mode === EditMode.INSERT &&
                      <AbortButton>
                        <FormattedMessage id="edit.abort_btn" />
                      </AbortButton>
                    }

                    {props.mode === EditMode.UPDATE &&
                      <>
                        <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
                          <Button
                            disabled={!props.deleteActive}
                            onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                              e.preventDefault();
                              setDeleteInit(true);
                              setDeleteRequest(false);
                            }}
                          >
                            <FormattedMessage id="button.Delete" />
                          </Button>
                        </OAuth2Gate>

                        <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
                          <Button
                            disabled={!props.deleteActive}
                            onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                              e.preventDefault();
                              setDeleteInit(true);
                              setDeleteRequest(true);
                            }}
                          >
                            <FormattedMessage id="button.Delete.Request" />
                          </Button>
                        </OAuth2Gate>
                      </>
                    }
                  </div>
                </div>
                <div className="col">
                  <Styled.h2>
                    <FormattedMessage id="edit.project.linking_head" />
                  </Styled.h2>
                  <div className="form">
                    <DataModelGate always={false} entity="person" field="person_organisations">
                      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
                        <>
                          <Styled.h3>
                            <FormattedMessage id="edit.project.organisation_label" />
                          </Styled.h3>
                          <PersonOrganisations
                            checkPermission={checkPermission}
                            organisations={personOrganisations ? personOrganisations : []}
                            validation={validation?.root?.person_organisations}
                            onChange={(orgas: any) => {
                              updateField('personOrganisations', orgas);
                            }}
                          />
                        </>
                      )}
                    </DataModelGate>
                  </div>
                  <div className="form">
                    <DataModelGate always={false} entity="person" field="projects">
                      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
                        <>
                          <Styled.h3>
                            <FormattedMessage id="edit.project.project_label" />
                          </Styled.h3>
                          <ProjectSuggest
                            required={isRequired(checkPermission())}
                            disabled={!writable}
                            validation={validation?.root?.projects}
                            tags={projects ? projects : []}
                            onChange={(grps: any) => {
                              updateField('projects', grps);
                            }}
                          />
                        </>
                      )}
                    </DataModelGate>
                  </div>
                </div>
              </DetailEditGrid>
            </form>
          </Main>
          </>
        );
      }}
    </Subscribe>
  );
};
