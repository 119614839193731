/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DatePeriod,
    DatePeriodFromJSON,
    DatePeriodFromJSONTyped,
    DatePeriodToJSON,
    PubListConfigReplacements,
    PubListConfigReplacementsFromJSON,
    PubListConfigReplacementsFromJSONTyped,
    PubListConfigReplacementsToJSON,
    PublistConfigProjectOrganisation,
    PublistConfigProjectOrganisationFromJSON,
    PublistConfigProjectOrganisationFromJSONTyped,
    PublistConfigProjectOrganisationToJSON,
    YearPeriod,
    YearPeriodFromJSON,
    YearPeriodFromJSONTyped,
    YearPeriodToJSON,
} from './';

/**
 * 
 * @export
 * @interface PubListConfig
 */
export interface PubListConfig {
    /**
     * 
     * @type {string}
     * @memberof PubListConfig
     */
    listType?: PubListConfigListTypeEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof PubListConfig
     */
    personIds?: Array<number>;
    /**
     * 
     * @type {PublistConfigProjectOrganisation}
     * @memberof PubListConfig
     */
    projectOrganisationConfig?: PublistConfigProjectOrganisation;
    /**
     * 
     * @type {Array<number>}
     * @memberof PubListConfig
     */
    filterIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PubListConfig
     */
    filterPersons?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PubListConfig
     */
    keyIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PubListConfig
     */
    useMainName?: boolean;
    /**
     * 
     * @type {Array<DatePeriod>}
     * @memberof PubListConfig
     */
    filterByCreationDate?: Array<DatePeriod>;
    /**
     * 
     * @type {Array<YearPeriod>}
     * @memberof PubListConfig
     */
    filterByYear?: Array<YearPeriod>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PubListConfig
     */
    filterByType?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PubListConfig
     */
    excludeByType?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PubListConfig
     */
    filterByPr?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PubListConfig
     */
    filterByLang?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PubListConfig
     */
    filterByRole?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PubListConfig
     */
    filterByRoleExcl?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PubListConfig
     */
    filterPersonByRole?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PubListConfig
     */
    groupByYear?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PubListConfig
     */
    groupByType?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PubListConfig
     */
    pubsort?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PubListConfig
     */
    toc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PubListConfig
     */
    locale?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PubListConfig
     */
    linking?: Array<string>;
    /**
     * 
     * @type {Array<PubListConfigReplacements>}
     * @memberof PubListConfig
     */
    replacements?: Array<PubListConfigReplacements>;
    /**
     * 
     * @type {string}
     * @memberof PubListConfig
     */
    style?: string;
    /**
     * 
     * @type {string}
     * @memberof PubListConfig
     */
    name?: string;
}

export function PubListConfigFromJSON(json: any): PubListConfig {
    return PubListConfigFromJSONTyped(json, false);
}

export function PubListConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PubListConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'listType': !exists(json, 'listType') ? undefined : json['listType'],
        'personIds': !exists(json, 'personIds') ? undefined : json['personIds'],
        'projectOrganisationConfig': !exists(json, 'projectOrganisationConfig') ? undefined : PublistConfigProjectOrganisationFromJSON(json['projectOrganisationConfig']),
        'filterIds': !exists(json, 'filterIds') ? undefined : json['filterIds'],
        'filterPersons': !exists(json, 'filterPersons') ? undefined : json['filterPersons'],
        'keyIds': !exists(json, 'keyIds') ? undefined : json['keyIds'],
        'useMainName': !exists(json, 'useMainName') ? undefined : json['useMainName'],
        'filterByCreationDate': !exists(json, 'filterByCreationDate') ? undefined : ((json['filterByCreationDate'] as Array<any>).map(DatePeriodFromJSON)),
        'filterByYear': !exists(json, 'filterByYear') ? undefined : ((json['filterByYear'] as Array<any>).map(YearPeriodFromJSON)),
        'filterByType': !exists(json, 'filterByType') ? undefined : json['filterByType'],
        'excludeByType': !exists(json, 'excludeByType') ? undefined : json['excludeByType'],
        'filterByPr': !exists(json, 'filterByPr') ? undefined : json['filterByPr'],
        'filterByLang': !exists(json, 'filterByLang') ? undefined : json['filterByLang'],
        'filterByRole': !exists(json, 'filterByRole') ? undefined : json['filterByRole'],
        'filterByRoleExcl': !exists(json, 'filterByRoleExcl') ? undefined : json['filterByRoleExcl'],
        'filterPersonByRole': !exists(json, 'filterPersonByRole') ? undefined : json['filterPersonByRole'],
        'groupByYear': !exists(json, 'groupByYear') ? undefined : json['groupByYear'],
        'groupByType': !exists(json, 'groupByType') ? undefined : json['groupByType'],
        'pubsort': !exists(json, 'pubsort') ? undefined : json['pubsort'],
        'toc': !exists(json, 'toc') ? undefined : json['toc'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
        'linking': !exists(json, 'linking') ? undefined : json['linking'],
        'replacements': !exists(json, 'replacements') ? undefined : ((json['replacements'] as Array<any>).map(PubListConfigReplacementsFromJSON)),
        'style': !exists(json, 'style') ? undefined : json['style'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function PubListConfigToJSON(value?: PubListConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'listType': value.listType,
        'personIds': value.personIds,
        'projectOrganisationConfig': PublistConfigProjectOrganisationToJSON(value.projectOrganisationConfig),
        'filterIds': value.filterIds,
        'filterPersons': value.filterPersons,
        'keyIds': value.keyIds,
        'useMainName': value.useMainName,
        'filterByCreationDate': value.filterByCreationDate === undefined ? undefined : ((value.filterByCreationDate as Array<any>).map(DatePeriodToJSON)),
        'filterByYear': value.filterByYear === undefined ? undefined : ((value.filterByYear as Array<any>).map(YearPeriodToJSON)),
        'filterByType': value.filterByType,
        'excludeByType': value.excludeByType,
        'filterByPr': value.filterByPr,
        'filterByLang': value.filterByLang,
        'filterByRole': value.filterByRole,
        'filterByRoleExcl': value.filterByRoleExcl,
        'filterPersonByRole': value.filterPersonByRole,
        'groupByYear': value.groupByYear,
        'groupByType': value.groupByType,
        'pubsort': value.pubsort,
        'toc': value.toc,
        'locale': value.locale,
        'linking': value.linking,
        'replacements': value.replacements === undefined ? undefined : ((value.replacements as Array<any>).map(PubListConfigReplacementsToJSON)),
        'style': value.style,
        'name': value.name,
    };
}

/**
* @export
* @enum {string}
*/
export enum PubListConfigListTypeEnum {
    RESEARCHDATA = 'RESEARCH_DATA',
    PUBLICATION = 'PUBLICATION',
    ALL = 'ALL'
}


