import React, { ReactElement } from 'react';
import { SelectInputP } from '../../../../../BaseLayout/components/form/generic/Select';
import { DataModelGate } from '../../../../../Auth/components';
import { AdminDataWorkDTO, WorkDTO } from '../../../../../api/core';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { ValidationResult } from '../../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../../Auth/components/DataModelGate';

const messages = defineMessages({
  accessLevel: { id: 'work.attr.adminDataWork.attr.adminDataWorkOpenAccess.attr.accessLevel' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  pubtypeGroup: string;
  className?: string;
  validation: ValidationResult;
}

export function AccessLevel({modifiedModel, pubtypeGroup, entity, updateField, className, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate always={false} entity={entity} field="admin_data_work_open_access.access_level">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <SelectInputP
        classes={[className]}
        value={
          modifiedModel.adminDataWork.adminDataWorkOpenAccess?.accessLevel
            ? modifiedModel.adminDataWork.adminDataWorkOpenAccess.accessLevel
            : ''
        }
        inputName="accessLevel"
        permissions={checkPermission()}
        validation={validation?.child?.access_level}
        name={intl.formatMessage(messages.accessLevel)}
        onChange={(accessLevel: string): void => {
          accessLevel = accessLevel?.length > 0 ? accessLevel : null;
          updateField('adminDataWork', {
            ...modifiedModel.adminDataWork,
            adminDataWorkOpenAccess: modifiedModel.adminDataWork.adminDataWorkOpenAccess ? {
              ...modifiedModel.adminDataWork.adminDataWorkOpenAccess,
              accessLevel,
            } : { accessLevel },
          });
        }
        }
        listPartition={pubtypeGroup}
        list={'access_level'}
      />
    )}
  </DataModelGate>;
}
