/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferencedOrganisationDTO,
    ReferencedOrganisationDTOFromJSON,
    ReferencedOrganisationDTOFromJSONTyped,
    ReferencedOrganisationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkOrganisationDTO
 */
export interface WorkOrganisationDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkOrganisationDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkOrganisationDTO
     */
    role?: string;
    /**
     * 
     * @type {ReferencedOrganisationDTO}
     * @memberof WorkOrganisationDTO
     */
    organisation?: ReferencedOrganisationDTO;
}

export function WorkOrganisationDTOFromJSON(json: any): WorkOrganisationDTO {
    return WorkOrganisationDTOFromJSONTyped(json, false);
}

export function WorkOrganisationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkOrganisationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'organisation': !exists(json, 'organisation') ? undefined : ReferencedOrganisationDTOFromJSON(json['organisation']),
    };
}

export function WorkOrganisationDTOToJSON(value?: WorkOrganisationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': value.role,
        'organisation': ReferencedOrganisationDTOToJSON(value.organisation),
    };
}


