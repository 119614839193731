/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminDataProjectDTO
 */
export interface AdminDataProjectDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminDataProjectDTO
     */
    id?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof AdminDataProjectDTO
     */
    changed?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminDataProjectDTO
     */
    created?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminDataProjectDTO
     */
    deskman?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDataProjectDTO
     */
    editorialStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdminDataProjectDTO
     */
    hidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AdminDataProjectDTO
     */
    origin?: string;
}

export function AdminDataProjectDTOFromJSON(json: any): AdminDataProjectDTO {
    return AdminDataProjectDTOFromJSONTyped(json, false);
}

export function AdminDataProjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDataProjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'changed': !exists(json, 'changed') ? undefined : (new Date(json['changed'])),
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'deskman': !exists(json, 'deskman') ? undefined : json['deskman'],
        'editorialStatus': !exists(json, 'editorialStatus') ? undefined : json['editorialStatus'],
        'hidden': !exists(json, 'hidden') ? undefined : json['hidden'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
    };
}

export function AdminDataProjectDTOToJSON(value?: AdminDataProjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'changed': value.changed === undefined ? undefined : (value.changed.toISOString()),
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'deskman': value.deskman,
        'editorialStatus': value.editorialStatus,
        'hidden': value.hidden,
        'origin': value.origin,
    };
}


