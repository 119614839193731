import React, { ReactElement } from 'react';
import { MultiInput } from '.';
import MultiInputControlsProps from './MultiInputControlsProps';

// TODO defaultRow, lines etc als generic parametrisieren
export function MultiInputControls<T>({ lines, onChange, renderLines, renderEmpty, renderEnd, order, remove, add, orderCallback, defaultRow }: MultiInputControlsProps<T>): ReactElement {
  const saveLines = lines ? lines : []; // lines could be undefined or null
  const addEnd = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    const originalLines = [...saveLines];
    saveLines.push({ ...defaultRow, _new: true });
    if (onChange) {
      onChange(saveLines, [saveLines.length - 1], originalLines);
    }
  };

  return (
    <>
      {/* TODO shadowed variable "lines" */}
      <MultiInput<T> lines={saveLines} onChange={onChange} defaultLine={defaultRow}>
        {(line: T & { _new?: boolean }, onChange2: (newValues) => void,   idx: number, lines: T[], onChangeAll: (newValues: any[], changedValues: any[], originals?: any[]) => void): ReactElement => {
          // remove all lines with null
          const filteredLines = lines.filter((l) => l !== null);
          // check, if is not first line
          const canUp: boolean = filteredLines.indexOf(line) > 0;
          // check, if is not last line
          const canDown: boolean = filteredLines.indexOf(line) < filteredLines.length - 1;

          const toggleElements = (down: number, up: number) => {
            const dElement = filteredLines[down];
            const uElement = filteredLines[up];
            filteredLines[down] = uElement;
            filteredLines[up] = dElement;
            orderCallback(dElement, uElement);
            onChangeAll(lines, [dElement, uElement]);
          };
          // control actions
          const actions = {
            delete:
              idx !== lines.length && remove
                ? (e: React.MouseEvent<any>): void => {
                  e.preventDefault();
                  lines.splice(idx, 1);
                  onChange(lines, [idx]);
                }
                : undefined,
            moveUp:
              canUp && order
                ? (e: React.MouseEvent<HTMLButtonElement>): void => {
                  e.preventDefault();
                  toggleElements(filteredLines.indexOf(line), filteredLines.indexOf(line) - 1);
                }
                : undefined,
            moveDown:
              canDown && order
                ? (e: React.MouseEvent<HTMLButtonElement>): void => {
                  e.preventDefault();
                  toggleElements(filteredLines.indexOf(line) + 1, filteredLines.indexOf(line));
                }
                : undefined,
            addEnd: !!add ? addEnd : undefined,
          };
          return (
            <div key={idx}>
              {!!filteredLines.length && renderLines(line, onChange2, actions, idx, lines, onChangeAll)}
              {!filteredLines.length && !!renderEmpty && renderEmpty(actions.addEnd)}
            </div>
          );
        }}
      </MultiInput>
      {!!renderEnd && renderEnd(addEnd)}
    </>
  );
}
