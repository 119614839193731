/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Sort,
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './';

/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
}

export function PageableFromJSON(json: any): Pageable {
    return PageableFromJSONTyped(json, false);
}

export function PageableFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pageable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'paged': !exists(json, 'paged') ? undefined : json['paged'],
        'unpaged': !exists(json, 'unpaged') ? undefined : json['unpaged'],
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
    };
}

export function PageableToJSON(value?: Pageable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'sort': SortToJSON(value.sort),
        'paged': value.paged,
        'unpaged': value.unpaged,
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
    };
}


