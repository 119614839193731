import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { AdminDataWorkDTO, WorkDTO, ReferencedOrganisationDTO } from 'api/core';
import { DataModelGate } from 'Auth/components';
import { OrganisationSuggest } from '../../auto-complete/OrganisationSuggest';
import { isRequired } from 'BaseLayout/components/form/generic/utils';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';

const messages = defineMessages({
  belongToOrganisation: { id: 'work.attr.adminDataWork.attr.belongToOrganisation' },
  belongToOrganisationHelp: { id: 'work.attr.adminDataWork.attr.belongToOrganisation.help' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  validation: ValidationResult;
}

export function BelongToOrganisations({modifiedModel, entity, updateField, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate always={false} entity={entity} field="organisations">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <OrganisationSuggest
        name={intl.formatMessage(messages.belongToOrganisation)}
        help={intl.formatMessage(messages.belongToOrganisationHelp)}
        required={isRequired(checkPermission())}
        validation={validation}
        disabled={!writable}
        tags={modifiedModel.adminDataWork.organisations ? modifiedModel.adminDataWork.organisations : []}
        onChange={(organisations: ReferencedOrganisationDTO[]): void =>
          updateField('adminDataWork', {
            ...modifiedModel.adminDataWork,
            organisations,
          })
        }
      />
    )}
  </DataModelGate>;
}
