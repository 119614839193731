import React, { ReactElement } from 'react';
import { WorkDTO, EventDTO, EventPlaceDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultEvent, DefaultEventPlace } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Border } from 'Edit/components/theme';
import { ValidationResult } from '../../../validation/interfaces';
import { DatePickerStyled } from 'BaseLayout/components/form/DatePickerDateRange/DatePicker';
import { DateObject } from 'react-multi-date-picker';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';

const messages = defineMessages({
  numbering: { id: 'work.attr.event.attr.numbering' },
  name: { id: 'work.attr.event.attr.name' },
  eventPlace: { id: 'work.attr.event.attr.event.place'},
  startDate: { id: 'work.attr.event.attr.startDate' },
  endDate: { id: 'work.attr.event.attr.endDate' },
});

interface EventsProps {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: EventDTO[]) => void;
  entity: string;
  validation: ValidationResult;
}

export function Events({ modifiedModel, updateField, entity, validation }: EventsProps): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <DataModelGate entity={entity} field="events">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        <div className="col">
          <Styled.h2>
            <FormattedMessage id="work.attr.events" />
          </Styled.h2>
          <Border>
            <MultiInputControls<EventDTO>
              lines={modifiedModel.events}
              onChange={(e: EventDTO[]) => updateField('events', e)}
              remove
              add
              defaultRow={DefaultEvent}
              renderLines={(event: EventDTO, onChangeEvent: (newValues: EventDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
                <>
                  <div>
                    <TextInputP
                      name={intl.formatMessage(messages.name)}
                      value={event.eventName}
                      onChange={(eventName: string): void => onChangeEvent({...event, eventName})}
                      permissions={checkPermission('event_name')}
                      inputName={'eventName'}
                      validation={validation?.children[idx]?.event_name}
                      autoFocus={true}
                    />
                  </div>
                  {checkPermission('event_places').read &&(
                    <EventPlaces
                      checkPermission={checkPermission}
                      onChange={(eventPlaces: EventPlaceDTO[]): void => onChangeEvent({...event, eventPlaces})}
                      eventPlaces={event.eventPlaces}
                      validation={validation?.children[idx]?.event_places}
                    />
                  )}
                  <div sx={{mt: '0.25em'}}>
                    <TextInputP
                      name={intl.formatMessage(messages.numbering)}
                      value={event.eventNumbering}
                      onChange={(eventNumbering: string): void => onChangeEvent({...event, eventNumbering})}
                      permissions={checkPermission('event_numbering')}
                      inputName={'eventNumbering'}
                      validation={validation?.children[idx]?.event_numbering}
                    />
                  </div>
                  <div className="fieldRow">
                    <DatePickerStyled
                      label={messages.startDate.id}
                      validation={validation?.children[idx]?.start_date}
                      onChange={(startDate: DateObject): void =>
                        onChangeEvent({ ...event, startDate: startDate === null ? undefined : new Date(startDate.format("YYYY-MM-DD")) })}
                      value={event.startDate ? event.startDate : undefined}
                      required={isRequired(checkPermission('start_date'))}
                      disabled={!checkPermission('start_date').write}
                    />
                    <DatePickerStyled
                      label={messages.endDate.id}
                      validation={validation?.children[idx]?.end_date}
                      onChange={(endDate: DateObject): void =>
                        onChangeEvent({ ...event, endDate: endDate === null ? undefined : new Date(endDate.format("YYYY-MM-DD")) })}
                      value={event.endDate ? event.endDate : undefined}
                      required={isRequired(checkPermission('end_date'))}
                      disabled={!checkPermission('end_date').write}
                    />
                  </div>
                  {!!actions.delete && (
                      <div sx={{mt: '-0.5em', mb: '1em'}}>
                        <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                      </div>
                  )}
                </>
              )}
              renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
                <div className="addButton">
                  <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
                </div>
              )}
            />
          </Border>
        </div>
      )}
    </DataModelGate>
  );
}

interface EventPlacesProps {
  checkPermission: CheckPermissionFunction;
  eventPlaces: EventPlaceDTO[];
  onChange: (eventPlaces: EventPlaceDTO[]) => void;
  validation: ValidationResult;
}

function EventPlaces({ checkPermission, eventPlaces, onChange, validation }: EventPlacesProps): ReactElement {
  const intl: IntlShape = useIntl();
  return <Border>
      <Styled.h3 sx={{fontSize: '1em'}}>
        <FormattedMessage id="work.attr.event.attr.places" />
      </Styled.h3>
      <MultiInputControls<EventPlaceDTO>
        defaultRow={DefaultEventPlace}
        lines={eventPlaces ? eventPlaces : []}
        remove={!!checkPermission('event_places').write}
        add={!!checkPermission('event_places').write}
        onChange={(eps: EventPlaceDTO[]) => onChange(eps)}
        renderLines={({ eventPlace }: EventPlaceDTO, onChangeEventPlace: (data: EventPlaceDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => {
          return<div className="fieldRow">
            <TextInputP
              name={intl.formatMessage(messages.eventPlace)}
              value={eventPlace}
              onChange={(place: string): void => onChangeEventPlace({ eventPlace: place })}
              permissions={checkPermission('event_places.event_place')}
              inputName={'eventPlace'}
              validation={validation?.children[idx]?.event_place}
              autoFocus={true}
            />
            {!!actions.delete && (
              <div className='deleteButton'>
                <DeleteButton onClick={actions.delete} disabled={!checkPermission('event_places').write} />
              </div>
            )}
          </div>;
        }}
        renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
          <div className="addButton">
            <AddButton onClick={actionAdd} disabled={!checkPermission('event_places').write}/>
          </div>
        )}
      />
    </Border>
    ;
}
