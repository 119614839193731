/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubjectDTO
 */
export interface SubjectDTO {
    /**
     * 
     * @type {number}
     * @memberof SubjectDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectDTO
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectDTO
     */
    subjectId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubjectDTO
     */
    type?: string;
}

export function SubjectDTOFromJSON(json: any): SubjectDTO {
    return SubjectDTOFromJSONTyped(json, false);
}

export function SubjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'subjectId': !exists(json, 'subjectId') ? undefined : json['subjectId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function SubjectDTOToJSON(value?: SubjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'label': value.label,
        'subjectId': value.subjectId,
        'type': value.type,
    };
}


