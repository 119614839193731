import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { WorkDTO } from '../../../../api/core';
import { DataModelGate } from '../../../../Auth/components';
import { TextInputP } from '../../../../BaseLayout/components/form/generic/TextInput';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';

const messages = defineMessages({
  edition: { id: 'work.attr.edition' },
  editionHelp: { id: 'work.attr.edition.help' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: string) => void;
  entity: string;
  className?: string;
  validation: ValidationResult;
}

export function Edition({modifiedModel, entity, updateField, className, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate always={false} entity={entity} field="edition">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <TextInputP
        value={modifiedModel.edition ? modifiedModel.edition : ''}
        inputName="edition"
        name={intl.formatMessage(messages.edition)}
        help={intl.formatMessage(messages.editionHelp)}
        onChange={(edition: string) => updateField('edition', edition)}
        permissions={checkPermission()}
        classes={[className]}
        validation={validation}
      />
    )}
  </DataModelGate>;
}
