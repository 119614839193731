/* tslint:disable */
/* eslint-disable */
/**
 * Pubtype Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetDifferenceRequest {
    one: GetDifferenceOneEnum;
    two: GetDifferenceTwoEnum;
}

export interface GetFieldsRequest {
    group?: GetFieldsGroupEnum;
    type?: GetFieldsTypeEnum;
}

export interface GetGroupsRequest {
    type?: GetGroupsTypeEnum;
    field?: GetGroupsFieldEnum;
}

export interface GetIntersectionRequest {
    one: GetIntersectionOneEnum;
    two: GetIntersectionTwoEnum;
}

export interface GetIsPartOfFieldsRequest {
    type: GetIsPartOfFieldsTypeEnum;
    ipoType: GetIsPartOfFieldsIpoTypeEnum;
}

export interface GetTypesRequest {
    group?: GetTypesGroupEnum;
    field?: GetTypesFieldEnum;
}

/**
 * 
 */
export class PubtypeServiceApi extends runtime.BaseAPI {

    /**
     */
    async getDifferenceRaw(requestParameters: GetDifferenceRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.one === null || requestParameters.one === undefined) {
            throw new runtime.RequiredError('one','Required parameter requestParameters.one was null or undefined when calling getDifference.');
        }

        if (requestParameters.two === null || requestParameters.two === undefined) {
            throw new runtime.RequiredError('two','Required parameter requestParameters.two was null or undefined when calling getDifference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.one !== undefined) {
            queryParameters['one'] = requestParameters.one;
        }

        if (requestParameters.two !== undefined) {
            queryParameters['two'] = requestParameters.two;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/difference`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getDifference(requestParameters: GetDifferenceRequest): Promise<Array<string>> {
        const response = await this.getDifferenceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getFieldsRaw(requestParameters: GetFieldsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.group !== undefined) {
            queryParameters['group'] = requestParameters.group;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/fields`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getFields(requestParameters: GetFieldsRequest): Promise<Array<string>> {
        const response = await this.getFieldsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getGroupsRaw(requestParameters: GetGroupsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getGroups(requestParameters: GetGroupsRequest): Promise<Array<string>> {
        const response = await this.getGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getIntersectionRaw(requestParameters: GetIntersectionRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.one === null || requestParameters.one === undefined) {
            throw new runtime.RequiredError('one','Required parameter requestParameters.one was null or undefined when calling getIntersection.');
        }

        if (requestParameters.two === null || requestParameters.two === undefined) {
            throw new runtime.RequiredError('two','Required parameter requestParameters.two was null or undefined when calling getIntersection.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.one !== undefined) {
            queryParameters['one'] = requestParameters.one;
        }

        if (requestParameters.two !== undefined) {
            queryParameters['two'] = requestParameters.two;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/intersection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getIntersection(requestParameters: GetIntersectionRequest): Promise<Array<string>> {
        const response = await this.getIntersectionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getIsPartOfFieldsRaw(requestParameters: GetIsPartOfFieldsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getIsPartOfFields.');
        }

        if (requestParameters.ipoType === null || requestParameters.ipoType === undefined) {
            throw new runtime.RequiredError('ipoType','Required parameter requestParameters.ipoType was null or undefined when calling getIsPartOfFields.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.ipoType !== undefined) {
            queryParameters['ipoType'] = requestParameters.ipoType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/ipo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getIsPartOfFields(requestParameters: GetIsPartOfFieldsRequest): Promise<Array<string>> {
        const response = await this.getIsPartOfFieldsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getTypesRaw(requestParameters: GetTypesRequest): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.group !== undefined) {
            queryParameters['group'] = requestParameters.group;
        }

        if (requestParameters.field !== undefined) {
            queryParameters['field'] = requestParameters.field;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getTypes(requestParameters: GetTypesRequest): Promise<Array<string>> {
        const response = await this.getTypesRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetDifferenceOneEnum {
    SELFCONTAINED = 'SELF_CONTAINED',
    SELFCONTAINEDORFRAGMENT = 'SELF_CONTAINED_OR_FRAGMENT',
    FRAGMENT = 'FRAGMENT',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    SERIES = 'SERIES',
    INTEGRATINGRESOURCE = 'INTEGRATING_RESOURCE',
    PERIODICAL = 'PERIODICAL',
    RESEARCHDATAGROUP = 'RESEARCH_DATA_GROUP',
    AUDIOVIDEOOBJECT = 'AUDIO_VIDEO_OBJECT',
    PATENT = 'PATENT',
    THESIS = 'THESIS',
    NONE = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetDifferenceTwoEnum {
    SELFCONTAINED = 'SELF_CONTAINED',
    SELFCONTAINEDORFRAGMENT = 'SELF_CONTAINED_OR_FRAGMENT',
    FRAGMENT = 'FRAGMENT',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    SERIES = 'SERIES',
    INTEGRATINGRESOURCE = 'INTEGRATING_RESOURCE',
    PERIODICAL = 'PERIODICAL',
    RESEARCHDATAGROUP = 'RESEARCH_DATA_GROUP',
    AUDIOVIDEOOBJECT = 'AUDIO_VIDEO_OBJECT',
    PATENT = 'PATENT',
    THESIS = 'THESIS',
    NONE = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetFieldsGroupEnum {
    SELFCONTAINED = 'SELF_CONTAINED',
    SELFCONTAINEDORFRAGMENT = 'SELF_CONTAINED_OR_FRAGMENT',
    FRAGMENT = 'FRAGMENT',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    SERIES = 'SERIES',
    INTEGRATINGRESOURCE = 'INTEGRATING_RESOURCE',
    PERIODICAL = 'PERIODICAL',
    RESEARCHDATAGROUP = 'RESEARCH_DATA_GROUP',
    AUDIOVIDEOOBJECT = 'AUDIO_VIDEO_OBJECT',
    PATENT = 'PATENT',
    THESIS = 'THESIS',
    NONE = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetFieldsTypeEnum {
    AUDIOVIDEODOCUMENT = 'AUDIO_VIDEO_DOCUMENT',
    RADIOTV = 'RADIO_TV',
    RESEARCHDATA = 'RESEARCH_DATA',
    PATENT = 'PATENT',
    NEWSPAPER = 'NEWSPAPER',
    JOURNAL = 'JOURNAL',
    CHAPTER = 'CHAPTER',
    CHAPTERINCONFERENCE = 'CHAPTER_IN_CONFERENCE',
    CHAPTERINLEGALCOMMENTARY = 'CHAPTER_IN_LEGAL_COMMENTARY',
    ARTICLENEWSPAPER = 'ARTICLE_NEWSPAPER',
    ARTICLEJOURNAL = 'ARTICLE_JOURNAL',
    ARTICLEINTEGRATINGRESOURCE = 'ARTICLE_INTEGRATING_RESOURCE',
    LEXICONARTICLE = 'LEXICON_ARTICLE',
    EDITORIAL = 'EDITORIAL',
    ARTICLEOVERVIEW = 'ARTICLE_OVERVIEW',
    BLOG = 'BLOG',
    WEBSITE = 'WEBSITE',
    MONOGRAPH = 'MONOGRAPH',
    COLLECTION = 'COLLECTION',
    EDITION = 'EDITION',
    CONFERENCE = 'CONFERENCE',
    LEGALCOMMENTARY = 'LEGAL_COMMENTARY',
    SPECIALISSUE = 'SPECIAL_ISSUE',
    SERIES = 'SERIES',
    STUDENTTHESIS = 'STUDENT_THESIS',
    DISSERTATION = 'DISSERTATION',
    HABILITATION = 'HABILITATION',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    MONOGRAPHMVW = 'MONOGRAPH_MVW',
    COLLECTIONMVW = 'COLLECTION_MVW',
    EDITIONMVW = 'EDITION_MVW',
    CONFERENCEMVW = 'CONFERENCE_MVW',
    REPORTGREYLITERATURE = 'REPORT_GREY_LITERATURE',
    REVIEW = 'REVIEW',
    INTERVIEW = 'INTERVIEW',
    LECTURE = 'LECTURE',
    POSTER = 'POSTER',
    ABSTRACT = 'ABSTRACT',
    REPORTCOLLECTION = 'REPORT_COLLECTION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGroupsTypeEnum {
    AUDIOVIDEODOCUMENT = 'AUDIO_VIDEO_DOCUMENT',
    RADIOTV = 'RADIO_TV',
    RESEARCHDATA = 'RESEARCH_DATA',
    PATENT = 'PATENT',
    NEWSPAPER = 'NEWSPAPER',
    JOURNAL = 'JOURNAL',
    CHAPTER = 'CHAPTER',
    CHAPTERINCONFERENCE = 'CHAPTER_IN_CONFERENCE',
    CHAPTERINLEGALCOMMENTARY = 'CHAPTER_IN_LEGAL_COMMENTARY',
    ARTICLENEWSPAPER = 'ARTICLE_NEWSPAPER',
    ARTICLEJOURNAL = 'ARTICLE_JOURNAL',
    ARTICLEINTEGRATINGRESOURCE = 'ARTICLE_INTEGRATING_RESOURCE',
    LEXICONARTICLE = 'LEXICON_ARTICLE',
    EDITORIAL = 'EDITORIAL',
    ARTICLEOVERVIEW = 'ARTICLE_OVERVIEW',
    BLOG = 'BLOG',
    WEBSITE = 'WEBSITE',
    MONOGRAPH = 'MONOGRAPH',
    COLLECTION = 'COLLECTION',
    EDITION = 'EDITION',
    CONFERENCE = 'CONFERENCE',
    LEGALCOMMENTARY = 'LEGAL_COMMENTARY',
    SPECIALISSUE = 'SPECIAL_ISSUE',
    SERIES = 'SERIES',
    STUDENTTHESIS = 'STUDENT_THESIS',
    DISSERTATION = 'DISSERTATION',
    HABILITATION = 'HABILITATION',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    MONOGRAPHMVW = 'MONOGRAPH_MVW',
    COLLECTIONMVW = 'COLLECTION_MVW',
    EDITIONMVW = 'EDITION_MVW',
    CONFERENCEMVW = 'CONFERENCE_MVW',
    REPORTGREYLITERATURE = 'REPORT_GREY_LITERATURE',
    REVIEW = 'REVIEW',
    INTERVIEW = 'INTERVIEW',
    LECTURE = 'LECTURE',
    POSTER = 'POSTER',
    ABSTRACT = 'ABSTRACT',
    REPORTCOLLECTION = 'REPORT_COLLECTION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGroupsFieldEnum {
    ID = 'ID',
    TITLE = 'TITLE',
    SUBTITLE = 'SUBTITLE',
    OTHERTITLE = 'OTHER_TITLE',
    TITLESUPPLEMENT = 'TITLE_SUPPLEMENT',
    PERSON = 'PERSON',
    PERSONOTHER = 'PERSON_OTHER',
    ORGANISATION = 'ORGANISATION',
    ORGANISATIONOTHER = 'ORGANISATION_OTHER',
    PROJECT = 'PROJECT',
    PROJECTOTHER = 'PROJECT_OTHER',
    DATE = 'DATE',
    ISSUED = 'ISSUED',
    PUBLICATIONSTATUS = 'PUBLICATION_STATUS',
    URL = 'URL',
    EXTERNALID = 'EXTERNAL_ID',
    INSTITUTIONALID = 'INSTITUTIONAL_ID',
    LANGUAGE = 'LANGUAGE',
    DESCRIPTION = 'DESCRIPTION',
    SUBJECT = 'SUBJECT',
    KEYWORD = 'KEYWORD',
    NOTE = 'NOTE',
    FORMAT = 'FORMAT',
    MEDIUM = 'MEDIUM',
    RELATEDIDENTIFIER = 'RELATED_IDENTIFIER',
    LICENSE = 'LICENSE',
    LICENSETEXT = 'LICENSE_TEXT',
    DATECHANGED = 'DATE_CHANGED',
    DATECREATED = 'DATE_CREATED',
    ORIGIN = 'ORIGIN',
    DESKMAN = 'DESKMAN',
    ORCIDSYNC = 'ORCID_SYNC',
    HIDDEN = 'HIDDEN',
    EDITORIALSTATUS = 'EDITORIAL_STATUS',
    BELONGTOORGANISATION = 'BELONG_TO_ORGANISATION',
    BELONGTOPROJECT = 'BELONG_TO_PROJECT',
    FUNDED = 'FUNDED',
    ARTICLEPROCESSINGCHARGE = 'ARTICLE_PROCESSING_CHARGE',
    ACCESSLEVEL = 'ACCESS_LEVEL',
    LICENSEMANAGEMENTSTATUS = 'LICENSE_MANAGEMENT_STATUS',
    OBLIGATORYDELIVERY = 'OBLIGATORY_DELIVERY',
    ISPARTOF = 'IS_PART_OF',
    ISPARTOFOTHER = 'IS_PART_OF_OTHER',
    PUBTYPE = 'PUBTYPE',
    FORMSUBJECT = 'FORM_SUBJECT',
    VERSION = 'VERSION',
    NUMBEROFPAGES = 'NUMBER_OF_PAGES',
    PEERREVIEWED = 'PEER_REVIEWED',
    PLACE = 'PLACE',
    EDITION = 'EDITION',
    NUMBER = 'NUMBER',
    EVENTSTARTDATE = 'EVENT_START_DATE',
    EVENTENDDATE = 'EVENT_END_DATE',
    EVENTNUMBERING = 'EVENT_NUMBERING',
    EVENTNAME = 'EVENT_NAME',
    EVENTPLACE = 'EVENT_PLACE',
    VOLUME = 'VOLUME',
    HASPART = 'HAS_PART',
    PUBLISHER = 'PUBLISHER',
    NUMBEROFVOLUMES = 'NUMBER_OF_VOLUMES',
    PATENTID = 'PATENT_ID',
    PUBLICATIONNUMBER = 'PUBLICATION_NUMBER',
    PRIORITYNUMBER = 'PRIORITY_NUMBER',
    PRIORITYPATENTAPPLICATION = 'PRIORITY_PATENT_APPLICATION',
    PRIORITYCOUNTRY = 'PRIORITY_COUNTRY',
    APPLICATIONNUMBER = 'APPLICATION_NUMBER',
    APPLICATIONCOUNTRY = 'APPLICATION_COUNTRY',
    GRANTNUMBER = 'GRANT_NUMBER',
    PATENTFAMILY = 'PATENT_FAMILY',
    TITLEABBREVIATION = 'TITLE_ABBREVIATION',
    SUBSERIESTITLE = 'SUBSERIES_TITLE',
    RESOURCETYPE = 'RESOURCE_TYPE',
    SIZE = 'SIZE',
    DATAVERSION = 'DATAVERSION',
    GEOLOCATION = 'GEO_LOCATION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetIntersectionOneEnum {
    SELFCONTAINED = 'SELF_CONTAINED',
    SELFCONTAINEDORFRAGMENT = 'SELF_CONTAINED_OR_FRAGMENT',
    FRAGMENT = 'FRAGMENT',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    SERIES = 'SERIES',
    INTEGRATINGRESOURCE = 'INTEGRATING_RESOURCE',
    PERIODICAL = 'PERIODICAL',
    RESEARCHDATAGROUP = 'RESEARCH_DATA_GROUP',
    AUDIOVIDEOOBJECT = 'AUDIO_VIDEO_OBJECT',
    PATENT = 'PATENT',
    THESIS = 'THESIS',
    NONE = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetIntersectionTwoEnum {
    SELFCONTAINED = 'SELF_CONTAINED',
    SELFCONTAINEDORFRAGMENT = 'SELF_CONTAINED_OR_FRAGMENT',
    FRAGMENT = 'FRAGMENT',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    SERIES = 'SERIES',
    INTEGRATINGRESOURCE = 'INTEGRATING_RESOURCE',
    PERIODICAL = 'PERIODICAL',
    RESEARCHDATAGROUP = 'RESEARCH_DATA_GROUP',
    AUDIOVIDEOOBJECT = 'AUDIO_VIDEO_OBJECT',
    PATENT = 'PATENT',
    THESIS = 'THESIS',
    NONE = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetIsPartOfFieldsTypeEnum {
    AUDIOVIDEODOCUMENT = 'AUDIO_VIDEO_DOCUMENT',
    RADIOTV = 'RADIO_TV',
    RESEARCHDATA = 'RESEARCH_DATA',
    PATENT = 'PATENT',
    NEWSPAPER = 'NEWSPAPER',
    JOURNAL = 'JOURNAL',
    CHAPTER = 'CHAPTER',
    CHAPTERINCONFERENCE = 'CHAPTER_IN_CONFERENCE',
    CHAPTERINLEGALCOMMENTARY = 'CHAPTER_IN_LEGAL_COMMENTARY',
    ARTICLENEWSPAPER = 'ARTICLE_NEWSPAPER',
    ARTICLEJOURNAL = 'ARTICLE_JOURNAL',
    ARTICLEINTEGRATINGRESOURCE = 'ARTICLE_INTEGRATING_RESOURCE',
    LEXICONARTICLE = 'LEXICON_ARTICLE',
    EDITORIAL = 'EDITORIAL',
    ARTICLEOVERVIEW = 'ARTICLE_OVERVIEW',
    BLOG = 'BLOG',
    WEBSITE = 'WEBSITE',
    MONOGRAPH = 'MONOGRAPH',
    COLLECTION = 'COLLECTION',
    EDITION = 'EDITION',
    CONFERENCE = 'CONFERENCE',
    LEGALCOMMENTARY = 'LEGAL_COMMENTARY',
    SPECIALISSUE = 'SPECIAL_ISSUE',
    SERIES = 'SERIES',
    STUDENTTHESIS = 'STUDENT_THESIS',
    DISSERTATION = 'DISSERTATION',
    HABILITATION = 'HABILITATION',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    MONOGRAPHMVW = 'MONOGRAPH_MVW',
    COLLECTIONMVW = 'COLLECTION_MVW',
    EDITIONMVW = 'EDITION_MVW',
    CONFERENCEMVW = 'CONFERENCE_MVW',
    REPORTGREYLITERATURE = 'REPORT_GREY_LITERATURE',
    REVIEW = 'REVIEW',
    INTERVIEW = 'INTERVIEW',
    LECTURE = 'LECTURE',
    POSTER = 'POSTER',
    ABSTRACT = 'ABSTRACT',
    REPORTCOLLECTION = 'REPORT_COLLECTION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetIsPartOfFieldsIpoTypeEnum {
    AUDIOVIDEODOCUMENT = 'AUDIO_VIDEO_DOCUMENT',
    RADIOTV = 'RADIO_TV',
    RESEARCHDATA = 'RESEARCH_DATA',
    PATENT = 'PATENT',
    NEWSPAPER = 'NEWSPAPER',
    JOURNAL = 'JOURNAL',
    CHAPTER = 'CHAPTER',
    CHAPTERINCONFERENCE = 'CHAPTER_IN_CONFERENCE',
    CHAPTERINLEGALCOMMENTARY = 'CHAPTER_IN_LEGAL_COMMENTARY',
    ARTICLENEWSPAPER = 'ARTICLE_NEWSPAPER',
    ARTICLEJOURNAL = 'ARTICLE_JOURNAL',
    ARTICLEINTEGRATINGRESOURCE = 'ARTICLE_INTEGRATING_RESOURCE',
    LEXICONARTICLE = 'LEXICON_ARTICLE',
    EDITORIAL = 'EDITORIAL',
    ARTICLEOVERVIEW = 'ARTICLE_OVERVIEW',
    BLOG = 'BLOG',
    WEBSITE = 'WEBSITE',
    MONOGRAPH = 'MONOGRAPH',
    COLLECTION = 'COLLECTION',
    EDITION = 'EDITION',
    CONFERENCE = 'CONFERENCE',
    LEGALCOMMENTARY = 'LEGAL_COMMENTARY',
    SPECIALISSUE = 'SPECIAL_ISSUE',
    SERIES = 'SERIES',
    STUDENTTHESIS = 'STUDENT_THESIS',
    DISSERTATION = 'DISSERTATION',
    HABILITATION = 'HABILITATION',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    MONOGRAPHMVW = 'MONOGRAPH_MVW',
    COLLECTIONMVW = 'COLLECTION_MVW',
    EDITIONMVW = 'EDITION_MVW',
    CONFERENCEMVW = 'CONFERENCE_MVW',
    REPORTGREYLITERATURE = 'REPORT_GREY_LITERATURE',
    REVIEW = 'REVIEW',
    INTERVIEW = 'INTERVIEW',
    LECTURE = 'LECTURE',
    POSTER = 'POSTER',
    ABSTRACT = 'ABSTRACT',
    REPORTCOLLECTION = 'REPORT_COLLECTION'
}
/**
    * @export
    * @enum {string}
    */
export enum GetTypesGroupEnum {
    SELFCONTAINED = 'SELF_CONTAINED',
    SELFCONTAINEDORFRAGMENT = 'SELF_CONTAINED_OR_FRAGMENT',
    FRAGMENT = 'FRAGMENT',
    MULTIVOLUMEWORK = 'MULTI_VOLUME_WORK',
    SERIES = 'SERIES',
    INTEGRATINGRESOURCE = 'INTEGRATING_RESOURCE',
    PERIODICAL = 'PERIODICAL',
    RESEARCHDATAGROUP = 'RESEARCH_DATA_GROUP',
    AUDIOVIDEOOBJECT = 'AUDIO_VIDEO_OBJECT',
    PATENT = 'PATENT',
    THESIS = 'THESIS',
    NONE = 'NONE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetTypesFieldEnum {
    ID = 'ID',
    TITLE = 'TITLE',
    SUBTITLE = 'SUBTITLE',
    OTHERTITLE = 'OTHER_TITLE',
    TITLESUPPLEMENT = 'TITLE_SUPPLEMENT',
    PERSON = 'PERSON',
    PERSONOTHER = 'PERSON_OTHER',
    ORGANISATION = 'ORGANISATION',
    ORGANISATIONOTHER = 'ORGANISATION_OTHER',
    PROJECT = 'PROJECT',
    PROJECTOTHER = 'PROJECT_OTHER',
    DATE = 'DATE',
    ISSUED = 'ISSUED',
    PUBLICATIONSTATUS = 'PUBLICATION_STATUS',
    URL = 'URL',
    EXTERNALID = 'EXTERNAL_ID',
    INSTITUTIONALID = 'INSTITUTIONAL_ID',
    LANGUAGE = 'LANGUAGE',
    DESCRIPTION = 'DESCRIPTION',
    SUBJECT = 'SUBJECT',
    KEYWORD = 'KEYWORD',
    NOTE = 'NOTE',
    FORMAT = 'FORMAT',
    MEDIUM = 'MEDIUM',
    RELATEDIDENTIFIER = 'RELATED_IDENTIFIER',
    LICENSE = 'LICENSE',
    LICENSETEXT = 'LICENSE_TEXT',
    DATECHANGED = 'DATE_CHANGED',
    DATECREATED = 'DATE_CREATED',
    ORIGIN = 'ORIGIN',
    DESKMAN = 'DESKMAN',
    ORCIDSYNC = 'ORCID_SYNC',
    HIDDEN = 'HIDDEN',
    EDITORIALSTATUS = 'EDITORIAL_STATUS',
    BELONGTOORGANISATION = 'BELONG_TO_ORGANISATION',
    BELONGTOPROJECT = 'BELONG_TO_PROJECT',
    FUNDED = 'FUNDED',
    ARTICLEPROCESSINGCHARGE = 'ARTICLE_PROCESSING_CHARGE',
    ACCESSLEVEL = 'ACCESS_LEVEL',
    LICENSEMANAGEMENTSTATUS = 'LICENSE_MANAGEMENT_STATUS',
    OBLIGATORYDELIVERY = 'OBLIGATORY_DELIVERY',
    ISPARTOF = 'IS_PART_OF',
    ISPARTOFOTHER = 'IS_PART_OF_OTHER',
    PUBTYPE = 'PUBTYPE',
    FORMSUBJECT = 'FORM_SUBJECT',
    VERSION = 'VERSION',
    NUMBEROFPAGES = 'NUMBER_OF_PAGES',
    PEERREVIEWED = 'PEER_REVIEWED',
    PLACE = 'PLACE',
    EDITION = 'EDITION',
    NUMBER = 'NUMBER',
    EVENTSTARTDATE = 'EVENT_START_DATE',
    EVENTENDDATE = 'EVENT_END_DATE',
    EVENTNUMBERING = 'EVENT_NUMBERING',
    EVENTNAME = 'EVENT_NAME',
    EVENTPLACE = 'EVENT_PLACE',
    VOLUME = 'VOLUME',
    HASPART = 'HAS_PART',
    PUBLISHER = 'PUBLISHER',
    NUMBEROFVOLUMES = 'NUMBER_OF_VOLUMES',
    PATENTID = 'PATENT_ID',
    PUBLICATIONNUMBER = 'PUBLICATION_NUMBER',
    PRIORITYNUMBER = 'PRIORITY_NUMBER',
    PRIORITYPATENTAPPLICATION = 'PRIORITY_PATENT_APPLICATION',
    PRIORITYCOUNTRY = 'PRIORITY_COUNTRY',
    APPLICATIONNUMBER = 'APPLICATION_NUMBER',
    APPLICATIONCOUNTRY = 'APPLICATION_COUNTRY',
    GRANTNUMBER = 'GRANT_NUMBER',
    PATENTFAMILY = 'PATENT_FAMILY',
    TITLEABBREVIATION = 'TITLE_ABBREVIATION',
    SUBSERIESTITLE = 'SUBSERIES_TITLE',
    RESOURCETYPE = 'RESOURCE_TYPE',
    SIZE = 'SIZE',
    DATAVERSION = 'DATAVERSION',
    GEOLOCATION = 'GEO_LOCATION'
}
