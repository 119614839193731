import * as React from 'react';
import DatePicker from 'react-datepicker';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber as FN,
  injectIntl, IntlShape,
  useIntl,
  WrappedComponentProps as IntlProps,
} from 'react-intl';
import { Checkbox } from '../../../BaseLayout/components/form';
import  TriCheckBox  from 'BaseLayout/components/form/TriCheckBox';
import { defaultFacetPageCount } from '../../data';
import { DashboardType, DateFacetEntry, FacetEntry, FacetLabel, FacetType, SelectFacetEntry } from '../../types';
import { FacetPagination } from './parts';
import EllipsisToolTip from "ellipsis-tooltip-react-chan";
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';

const messages = defineMessages({
  Reset: { id: 'button.Reset' },
  dateStart: { id: 'date.start' },
  dateEnd: { id: 'date.end' },
});

export interface Props {
  className?: string;
  dashboardType: DashboardType;
  type: FacetType;
  name: string;
  label: string;
  single: boolean;
  tristate: boolean;
  entries?: FacetEntry[];
  activeEntries?: FacetEntry[];
  count?: number;
  page: number;
  pageLimit?: number;
  onOffsetUpdate: (value: number) => void;
  onEntriesSet: (entries: FacetEntry[]) => void;
}

export function InnerFacet(props: (Props & IntlProps)): React.JSX.Element {
  const [active, setActive] = useState<boolean>(true);
  const intl: IntlShape = useIntl();
  const type: FacetType = props.type;

  const renderSelect = (): React.JSX.Element => {
    const page: number = props.page ? props.page : 1;
    const pageLimit: number = props.pageLimit ? props.pageLimit : defaultFacetPageCount;
    const activeEntries: FacetEntry[] = props.activeEntries ? props.activeEntries : [];
    const { type, count, className } = props;

    const isSelected: number = activeEntries && activeEntries.length;
    const isEmpty: boolean = !count;

    const classes: string[] = ['facet'];
    if (!!className) {
      classes.push(className);
    }

    if (isSelected) {
      classes.push('selected');
    }
    if (isEmpty) {
      classes.push('empty');
    } else if (active) {
      classes.push('active');
    }

    const start: number = type !== FacetType.DYNAMIC_SELECT && pageLimit !== undefined ? (page - 1) * pageLimit : 0;
    const end: number = type !== FacetType.DYNAMIC_SELECT && pageLimit !== undefined ? page * pageLimit : undefined;
    const renderedEntries: JSX.Element = renderEntries(start, end);

    const childProps = { ...props, className: null };

    return (
      <div className={classes.join(' ')} sx={{
        label: "facet",
        "&.active": {
          ">.list": {
            display: "block"
          },
          ">.date": {
            display: "flex"
          }
        },
        "&.empty": {
          ">.heading": {
            cursor: "default",
            ">span.indicator, >button.expand": {
              visibility: "hidden"
            }
          }
        },
      }}>
        {renderHeading()}
        <div className="list" sx={{
          display: "none",
          px: "1em",
          mt: '-0.5em',
          ">.entries": {
            width: "100%",
            mb: "0.5em",
            "span label": {
              ".helper": {
                mt: '0.23em'
              },
              overflow: "hidden",
              width: "100%",
              padding: "0.1em 0",
              cursor: "pointer",
              fontWeight: "normal",
              display: "flex",
              opacity: 0.85,
              ".f-count": {
                // font-weight: bold;
                textAlign: "right",
                fontSize: "1em",
                ">.pos": {
                  color: '#6C6C6C',
                  display: 'inline-flex'
                },
                ">.pos-tricheck": {
                  color: '#8DAE10',
                  display: 'inline-flex'
                },
                ">.neg": {
                  width: '5.5em',
                  display: 'inline-block',
                  ml: 'auto',
                  mr: '0',
                  color: '#6C6C6C'
                }
              },
              ".facet-text": {
                color: 'facet_label',
                fontWeight: 'bolder',
                whiteSpace: "nowrap",
                maxHeight: "2em",
                overflow: "hidden",
                display: "inline-block",
                textOverflow: "ellipsis",
                flexGrow: 1,
                pr: "1.2em",
                pl: "0.5em"
              },
              "&:hover": {
                textDecoration: "underline",
                ".neg": {textDecoration: 'underline'}
              },
              ".OuterDivStylesEllipsisToolTipChan": {
                color:'facet_label',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                maxHeight: '2em',
                overflow: "hidden",
                display: "inline-block",
                textOverflow: "ellipsis",
                flexGrow: 1,
                pr: "1.2em",
                pl: "0.5em",
                width: 'auto',
                maxWidth: '390px',
              }
            }
          }
        }}>
          {renderedEntries}
          <FacetPagination {...childProps} />
        </div>
      </div>
    );
  };

  const renderEntries = (start: number = 0, end?: number): JSX.Element => {
    const entries: SelectFacetEntry[] = props.entries ? (props.entries.slice(start, end) as SelectFacetEntry[]) : [];
    const activeEntries: SelectFacetEntry[] = props.activeEntries ? props.activeEntries as SelectFacetEntry[] : [];
    const tristate: boolean = props.tristate;
    const dashboardType: DashboardType = props.dashboardType;

    const handleChange = (entry: SelectFacetEntry, checked: boolean): void => {
      // Filter out existing entries with the same value
      const active: SelectFacetEntry[] = props.activeEntries !== undefined ? activeEntries.filter((e: SelectFacetEntry): boolean => e.value !== entry.value) : [];
      if (checked) {
        props.onEntriesSet([...active, entry]);
      } else {
        props.onEntriesSet(active);
      }
    };

    const tooltipOptions = {
      effect: "solid",
      place: "right",
      multiline: true,
      backgroundColor: 'black',
      textColor: 'white'
    };

    return (
      <div className="entries">
        {entries.map((e: SelectFacetEntry, i: number): React.JSX.Element => {
          if (e.negate) {
            return <span key={i}></span>;
          }
          const negatedEntry: SelectFacetEntry = entries.find((x: SelectFacetEntry) => e.value === x.value && x.negate === true);

          const yesChecked: boolean = undefined !== activeEntries.find((x: SelectFacetEntry) => e.value === x.value && x.negate === false);
          const noChecked: boolean =
            tristate && undefined !== activeEntries.find((x: SelectFacetEntry) => e.value === x.value && x.negate === true);
          const tristateValue: boolean = yesChecked ? true : noChecked ? false : undefined;
          const yesCount: number|"-" = noChecked ? '-' : e.count;

          return (
            <div key={i}>
              {renderFacetLabel(dashboardType, e.label, (name: React.ReactNode): React.JSX.Element => {
                const label: React.JSX.Element = (
                  <>
                    <EllipsisToolTip style={{opacity:'0.9'}} options={tooltipOptions}>{name}</EllipsisToolTip>
                    {yesCount !== null ? (
                        <span className="f-count">
                          {!tristate ? (
                            <div className="pos">{!noChecked ? <FN value={e.count} /> : '-'}</div>
                          ) : (
                            <></>
                          )}
                        </span>
                      ) : (
                      ''
                      )
                    }
                  </>
                );

                return (
                  <span key={String(e.value)}>
                    {tristate ? (
                      <label sx={{ mt: '0.1em' }}>
                        <TriCheckBox
                          reverse
                          value={tristateValue}
                          onChange={(value: boolean): void => {
                            if (undefined === value || null === value) {
                              handleChange(e, false); // Remove pos/neg entry
                            } else if (value) {
                              handleChange(e, true); // Add positive entry
                            } else {
                              handleChange({ ...e, negate: true }, true); // Add negative entry
                            }
                          }}
                          tristate_number={{pos: e.count, neg: negatedEntry.count}}
                        />
                        {label}
                      </label>
                    ) : (
                      <Checkbox
                        value={String(e.value)}
                        checked={yesChecked}
                        onChange={(event) => handleChange(e, event.target.checked)}
                      >
                        {label}
                      </Checkbox>
                    )}
                  </span>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const renderFacetLabel = (
    _dashboard: DashboardType,
    label: FacetLabel,
    child: (name: React.ReactNode) => React.ReactNode
  ) => {
    if (typeof label === 'function') {
      return label(child);
    }

    return child(label);
  };

  const renderDateRange = () => {
    const activeEntries: DateFacetEntry[] = props.activeEntries ? props.activeEntries as DateFacetEntry[] : [];
    const { onEntriesSet, intl, className } = props;

    const isSelected: number = activeEntries && activeEntries.length;

    const classes: string[] = ['facet'];
    if (!!className) {
      classes.push(className);
    }

    if (isSelected) {
      classes.push('selected');
    }
    if (active) {
      classes.push('active');
    }

    const { start: startValue, end: endValue } = activeEntries.length
      ? activeEntries[0]
      : { start: undefined, end: undefined };

    const handleStartChange = (date: Date): void => {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      onEntriesSet([
        {
          start: date,
          end: endValue,
        },
      ]);
    };
    const handleEndChange = (date: Date): void => {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      onEntriesSet([
        {
          start: startValue,
          end: date,
        },
      ]);
    };

    const handleClear = (): void => {
      onEntriesSet([]);
    };

    return (
      <div className={classes.join(' ')} sx={{
        label: "facet",
        "&.active": {
          ">.list": {
            display: "block"
          },
          ">.date": {
            display: "flex",
            ml: '1.5em'
          }
        },
        "&.empty": {
          ">.heading": {
            cursor: "default",
            ">span.indicator, >button.expand": {
              visibility: "hidden"
            }
          }
        },
        input: {
          p: '8px 1px',
          border: '2px solid #D8D8D8',
          "::placeholder": {
            color: '#CBCBCB',
            pl: '0.5em'
          }
        }
      }}>
        {renderHeading()}
        <div className="date" sx={{
            display: "none",
            padding: "0.3em 0",
            justifyItems: "stretch",
            alignItems: "center",
            ">.middle": {
              opacity: 0.85,
              p: "0 0.5em",
              color: 'dashboard'
            },
            ">.clear": {
              cursor: "pointer",
              opacity: 0.85,
              pl: "0.5em",
              color: 'dashboard'
            }
        }}>
          <div className='startDate'>
            <DatePicker wrapperClassName='startDate'
              selected={startValue}
              onChange={handleStartChange}
              selectsStart
              startDate={startValue}
              endDate={endValue}
              maxDate={endValue}
              placeholderText={intl.formatMessage(messages.dateStart)}
              dateFormatCalendar=" "
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <span className="middle">
            <FormattedMessage id="date.until-label" />
          </span>
          <div className='endDate'>
            <DatePicker
              selected={endValue}
              onChange={handleEndChange}
              selectsEnd
              startDate={startValue}
              endDate={endValue}
              minDate={startValue}
              placeholderText={intl.formatMessage(messages.dateEnd)}
              dateFormatCalendar=" "
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>
          <span className="clear rub-icons cancel" title={intl.formatMessage(messages.Reset)} onClick={handleClear} />
        </div>
      </div>
    );
  };

  const renderHeading = (): React.JSX.Element => {
    const activeEntries: FacetEntry[] = props.activeEntries ? props.activeEntries : [];
    const label: string = props.label;

    const isSelected: number = activeEntries && activeEntries.length;
    const icon: React.JSX.Element = active ? <i className="icon-pfeil_suche_aufgeklappt" sx={{fontSize: '0.85em', "&:before": {color:'dashboard'}}}/> : <i className="icon-pfeil_suche_zugeklappt" sx={{ float: 'right', "&:before": {color:'dashboard'} }} />;

    return (
      <div
        className="heading"
        sx={{
          px: "0.4em",
          py: '0.8em',
          borderTop: "1px solid lightgray",
          fontWeight: "bold",
          fontSize: "1.1em",
          display: "flex",
          justifyContent: "center",
          alignItems: "baseline",
          cursor: "pointer",
          "&:hover": {
            color: 'secondary',
            ".icon-pfeil_suche_aufgeklappt:before, .icon-pfeil_suche_zugeklappt:before, >h3": {color: 'secondary'}
          },
          ">h3": {
            color: "dashboard",
            width: "100%",
            textAlign: "left",
            fontSize: "inherit",
            fontWeight: 'bold'
          },
          ">span.indicator, >button.expand": {
            cursor: "pointer",
            lineHeight: "1em",
            fontSize: "1.1em",
            width: "1.5em",
            textAlign: "center",
          },
          ">span.indicator": {
            fontWeight: "bold",
            color: 'secondary',
          }
        }}
        onClick={(e): void => {
          setActive(!active);
        }}
      >
        <span className="indicator">{isSelected ? '•' : ''}</span>
        <h3>{intl.formatMessage({id: label})}</h3>
        {icon}
      </div>
    );
  };

  if ([FacetType.SELECT, FacetType.DYNAMIC_SELECT, FacetType.BOOLEAN].includes(type)) {
    return renderSelect();
  } else if (type === FacetType.DATE_RANGE) {
    return renderDateRange();
  }
}

export const Facet = injectIntl(InnerFacet);
