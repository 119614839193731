import * as React from 'react';
import { TooltipProps as TTP } from 'react-tooltip-lite';
import { TopOrgaEntity } from '../types';
import { createTheme, MuiThemeProvider, Tooltip } from '@material-ui/core';
import Fade from "@material-ui/core/Fade";

export type TooltipProps = TTP;

export interface Props {
  entity: TopOrgaEntity;
  id: number;
  name: string;
  state: 'loading' | 'present' | 'missing' | 'error';
  topOrga?: string[];
  children?: any;

  loadTopOrga: () => void;
}

const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        color: "#fff",
        backgroundColor: "#333",
        borderRadius: 0,
        fontFamily: 'Titillium,Arial,Helvetica,sans-serif'
      },
      arrow: {
        color: "#333",
        fontSize: '1em'
      }
    }
  }
});

export function TopOrgaTooltip (props: Props): React.JSX.Element {
  const { loadTopOrga, state, children } = props;

  const renderTopOrga = (): string => {
    const { topOrga, state } = props;

    if ('missing' === state || undefined === topOrga || 0 === topOrga.length) {
      // Empty hierarchy
      return '';
    }
    // TODO map überflüssig?
    return topOrga.map((e: string): string => e).join(', ');
  };

  const handleMouseOver = (): void => {
    if ('missing' === state) {
      loadTopOrga();
    }
  };

  const classes: string[] = ['topOrga-tooltip', state];

  return (
    <span className={classes.join(' ')} onMouseOver={handleMouseOver}>
      <MuiThemeProvider theme={theme}>
        <Tooltip
          title={renderTopOrga()}
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 0 }}
        >
          {children}
        </Tooltip>
      </MuiThemeProvider>
    </span>
  );
}
