import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { RubFooterDesktop } from './RubDesktop';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { FooterMobile } from './FooterMobile';

export const RubFooter = (): React.JSX.Element => {
  const index: number = useBreakpointIndex({ defaultIndex: 0 });

  return index <= 2 ? (
    <FooterMobile />
  ) : (
    <RubFooterDesktop />
  );
};
