import * as React from 'react';
import { useState } from 'react';
import { ConnectedDashboard, Props as DashboardProps } from '../container/Dashboard';
import { DashboardType, defaultSearchQuery, SearchQuery } from '../types';
import { OAuth2AwareProps } from '../../Auth/utils/connect';

export interface Props {
  entity: DashboardType;
  searchQuery?: Partial<SearchQuery>;
  hideSidebar?: boolean;
  personOrg? : any;
}

export function EmbeddedDashboard(props: Props): React.FunctionComponentElement<Props & OAuth2AwareProps<any>> {
  const [searchQuery, setSearchQuery] = useState<SearchQuery>(defaultSearchQuery);

  const getRenderProps = (): DashboardProps => {
    const { entity, hideSidebar, personOrg } = props;
    return {
      entity,
      searchQuery,
      extraSearchQuery: props.searchQuery,
      onSearchQueryUpdate: boundHandleSearchQueryUpdate,
      onDidMount: () => {},
      embedded: true,
      hideSidebar,
      personOrg,
    };
  };

  const boundHandleSearchQueryUpdate = (value: SearchQuery, initial: boolean) => {
    setSearchQuery(value);
  };

  const _props = getRenderProps();

  return React.createElement(ConnectedDashboard, _props);
}

export default EmbeddedDashboard;
