/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferencedWorkDTO
 */
export interface ReferencedWorkDTO {
    /**
     * 
     * @type {number}
     * @memberof ReferencedWorkDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReferencedWorkDTO
     */
    oldId?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferencedWorkDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferencedWorkDTO
     */
    subseriesTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferencedWorkDTO
     */
    pubtype?: string;
}

export function ReferencedWorkDTOFromJSON(json: any): ReferencedWorkDTO {
    return ReferencedWorkDTOFromJSONTyped(json, false);
}

export function ReferencedWorkDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferencedWorkDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'oldId': !exists(json, 'oldId') ? undefined : json['oldId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'subseriesTitle': !exists(json, 'subseriesTitle') ? undefined : json['subseriesTitle'],
        'pubtype': !exists(json, 'pubtype') ? undefined : json['pubtype'],
    };
}

export function ReferencedWorkDTOToJSON(value?: ReferencedWorkDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'oldId': value.oldId,
        'title': value.title,
        'subseriesTitle': value.subseriesTitle,
        'pubtype': value.pubtype,
    };
}


