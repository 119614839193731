/** @jsx jsx */
import { jsx, Grid, Card, Flex } from 'theme-ui';
import { FormattedMessage } from 'react-intl';

export interface StatisticsMobileProps {
  tiles: JSX.Element[];
}

export function StatisticsMobile(props: StatisticsMobileProps): JSX.Element {
  return (
    <div
      sx={{
        justifyContent: 'center',
        mt: '35px',
        mb: '45px'
      }}>
        <h2 sx={{ mb: '12px', fontWeight: 'bold', textAlign: 'center', fontSize: '28px', color: 'primary' }}>
          <FormattedMessage id="statistics.header" />
        </h2>
      <Flex
        sx={{
          justifyContent: 'center',
        }}
      >
        <Card
          sx={{
            color: 'primary',
            maxWidth: '80em',
            mx: '21px'
          }}
          backgroundColor={'lighter'}
        >
          <Grid
            columns={[2, null, props.tiles.length]}
            p={'15px'}
            gap={6}
            sx={{
              b: {
                fontSize: '32px',
              },
              span: {
                fontWeight: 'bold',
                fontSize: '16px',
                lineHeight: '21px',
                px: '9px',
                a: {
                  textDecoration: 'none'
                },
                mb: '10px'
              },
            }}
          >
            {props.tiles}
          </Grid>
        </Card>
      </Flex>
    </div>
  );
}
