import * as M from '../api/core/models';

export enum FacetType {
  SELECT = 'select',
  BOOLEAN = 'boolean',
  DYNAMIC_SELECT = 'dynamic-select',
  DATE_RANGE = 'date-range',
}

export type FacetValue = string | number;

export interface SelectFacetEntry {
  label: FacetLabel;
  value: FacetValue;
  count: number | null;
  negate: boolean;
}

export interface DateFacetEntry {
  start?: Date;
  end?: Date;
}

export type FacetEntry = SelectFacetEntry | DateFacetEntry;

type FacetLabelFactory = (child: (name: React.ReactNode) => React.ReactNode) => React.ReactNode;
export type FacetLabel = React.ReactNode | FacetLabelFactory;

export interface SearchFacet {
  name: string;
  label: string;
  type: FacetType;
  count: number;
  entries: FacetEntry[];
  single: boolean;
  tristate: boolean;
  pageLimit?: number;
}

export interface SearchSort {
  field: string;
  direction: 'asc' | 'desc' | '';
}

// export type SearchFacet = SearchFacet;
export type SearchWork = M.SolrWork;
export type SearchPerson = M.SolrPerson;
export type SearchOrg = M.SolrOrganisation;
export type SearchProject = M.SolrProject;
export type SearchResultType = SearchWork | SearchPerson | SearchOrg | SearchProject;

export interface SearchFacetValueMap<T> {
  [key: string]: T;
}

export enum DashboardType {
  WORKS = 'works',
  PEOPLE = 'people',
  ORGS = 'orgs',
  PROJECTS = 'projects',
}

export enum HierarchyEntity {
  ORGS = 'orgs',
  PROJECTS = 'projects',
}

export interface SearchQuery {
  q?: string;
  offset: number;
  limit: number;
  sort?: SearchSort;
  f: SearchFacetValueMap<FacetEntry[]>;
}

export const defaultSearchQuery: SearchQuery = {
  offset: 0,
  limit: 10,
  f: Object.create(null),
};

export interface HierarchyMapEntry {
  name: string;
  id: number;
}
