import * as React from 'react';
import Autosuggest from 'react-autosuggest';
import { RenderInputProps, RenderTagProps } from 'react-tagsinput';
import { TagsInput } from 'BaseLayout/components/form/TagsInput';
import { Input } from 'theme-ui';
/** @jsx jsx */
import { jsx } from 'theme-ui';

import getLogger from '../../../log';
import { ValidationResult } from '../../../Edit/validation/interfaces';
/* eslint-disable @typescript-eslint/no-unused-vars */
const logger = getLogger('BaseLayout/form/MultiAutocomplete');
/* eslint-enable @typescript-eslint/no-unused-vars */

function defaultRenderInput (inputProps) {
  return <Input {...inputProps} />;
}
export interface Props {
  onChange: (e: any[]) => void;
  single: boolean;
  allwaysSuggest: boolean;
  inputProps: any;
  options: any[];
  remote: boolean;
  onSuggestionsClearRequested: () => any;
  onSuggestionsFetchRequested: (e: any) => any;
  renderSuggestions: (suggestion: any) => React.ReactNode;
  tags: any[];
  onSuggestionSelected?: (event: any, suggestion: any) => void;
  renderTag?: (props: RenderTagProps) => React.ReactNode; // TODO it's better to make a function, wich gets the text and returns a string for display name, otherwise design inconsitencies for renderTag are possible
  deleteStrategy: DeleteStrategy;
  canDelete: boolean;
  disabled: boolean;
  required: boolean;
  onlyUnique: boolean;
  loseFocusOnSuggestionClick: boolean;
  validation?: ValidationResult;
  autoFocus?: boolean;
}

export enum DeleteStrategy {
  SPLICE = 'splice',
  NULL = 'null',
}

class MultiAutocomplete extends React.Component<Props> {
  public static defaultProps: Props = {
    tags: [],
    onChange: () => {},
    single: false,
    allwaysSuggest: false,
    inputProps: {},
    options: [],
    remote: false,
    onSuggestionsClearRequested: () => {},
    onSuggestionsFetchRequested: (e: any) => {},
    renderSuggestions: (suggestion: any) => <span>{suggestion.name}</span>,
    deleteStrategy: DeleteStrategy.SPLICE,
    canDelete: true,
    disabled: false,
    required: false,
    onlyUnique: true,
    loseFocusOnSuggestionClick: false,
    validation: {valid: true},
    autoFocus: false
  };

  public render(): React.JSX.Element {
    const { allwaysSuggest, options, single, canDelete, disabled, renderTag, tags, required, onlyUnique, loseFocusOnSuggestionClick, validation, autoFocus } = this.props;
    const autocompleteRenderInput = ({ addTag, ...props }: RenderInputProps) => {
      const inputValue = (props.value && props.value?.trim().toLowerCase()) || '';
      const inputLength = inputValue.length;
      let suggestions;
      if (this.props.remote) {
        suggestions = options;
      } else {
        suggestions = options.filter((option) => {
          // prevent duplicates
          // TODO: very ugly solution, this has to be more generic
          if (!option.name) {
            return true;
          }
          return option.name.toLowerCase().slice(0, inputLength) === inputValue && !tags.includes(option.abbr);
        });
      }

      const storeInputReference = (autosuggest: any) => {
        if (autosuggest !== null) {
          props.ref(autosuggest.input);
        }
      };

      return (
        <Autosuggest
          ref={storeInputReference}
          suggestions={suggestions as any[]}
          shouldRenderSuggestions={(value) => allwaysSuggest || value.trim().length > 0}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderSuggestion={this.props.renderSuggestions}
          renderInputComponent={defaultRenderInput}
          focusInputOnSuggestionClick={!loseFocusOnSuggestionClick}
          inputProps={{
            ...props,
            onChange: (e: React.ChangeEvent<{ readonly value: string }>, { newValue, method }) => {
              if (method === 'enter') {
                e.preventDefault();
              } else {
                props.onChange(e);
              }
            },
            autoFocus
          }}
          onSuggestionSelected={(e, { suggestion }) => {
            suggestion = this.props.onSuggestionSelected
              ? this.props.onSuggestionSelected(e, { suggestion })
              : suggestion;
            addTag(suggestion);
          }}
          onSuggestionsClearRequested={this.props.onSuggestionsClearRequested}
          onSuggestionsFetchRequested={this.props.onSuggestionsFetchRequested}
        />
      );
    };
    const classes: string[] = ['react-tagsinput'];
    if(single) {
      classes.push('single');
    }
    if(!canDelete) {
      classes.push('no-delete');
    }
    if (this.props.inputProps.placeholder === undefined && this.props.tags[0] === undefined) {
      this.props.inputProps.placeholder = '';
    }

    return (
      <TagsInput
        required={required}
        disabled={disabled}
        renderTag={renderTag}
        renderInput={autocompleteRenderInput}
        value={this.props.tags}
        onChange={this.handleChange.bind(this)}
        inputProps={this.props.inputProps}
        className={classes.join(" ")}
        onlyUnique={onlyUnique}
        validation={validation}
      />
    );
  }

  private handleChange(tags: any[], changed, idx) {
    // null set strategy
    if (tags.length < this.props.tags.length && this.props.deleteStrategy === DeleteStrategy.NULL) {
      tags.splice(idx, 0, null);
    }

    // fiter everything whats not comming from the autosuggester
    if (this.props.remote) {
      tags = tags.filter((tag) => {
        return !(typeof tag === 'string');
      });
    }

    if (this.props.single && tags.length > 1) {
      tags = tags.slice(-1);
    }

    if (this.props.onlyUnique) {
      // only unique tags are allowed
      // generic solution removing duplicated by comparing them
      // via JSON.stringify. Thus order of properties is relevant
      tags = tags.filter((tag, index) => {
        const _value = JSON.stringify(tag);
        return index === tags.findIndex(t => {
          return JSON.stringify(t) === _value;
        });
      });
    }

    if (this.props.onChange) {
      this.props.onChange(tags);
    }
  }
}

export const defaultRenderTag = (props) => {
  const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
  // Null Strategy
  if (tag === null) {
    return <></>;
  }
  return (
    <span key={key} {...other} sx={{
      display: "inline-flex",
      backgroundColor: "darker",
      color: "white",
      px: 4,
      py: 2,
      mb: 2
    }}>
      {getTagDisplayValue(tag && tag.name ? tag.name : typeof tag == 'string' ? tag : '')}
      {!disabled && <a  sx={{
      cursor: "pointer",
      color: "white",
      pl: 3
    }} onClick={(e) => onRemove(key)} >x</a>}
    </span>
  );
};

export default MultiAutocomplete;
