import * as React from 'react';
import { ConnectedHierarchyTooltip as HierarchyTooltip } from './container/HierarchyTooltip';
import {
  affiliationFilter,
  boolFilter,
  editStatusFilter,
  externalIdentifierFilter,
  formSubjectFilter,
  accessLevelFilter,
  fundedTypeFilter,
  peerReviewStatusFilter,
  personIdentifierFilter,
  unitIdentifierFilter,
  personStatusFilter,
  pubStatusFilter,
  pubtypeFilter,
  languageFilter,
} from '../Vocabulary/data';
import { DashboardType, FacetEntry, FacetLabel, FacetType, HierarchyEntity, SelectFacetEntry } from './types';

export interface FacetConfiguration {
  name: string;
  label: string;
  type: FacetType;
  single?: boolean;
  tristate?: boolean;
  pageLimit?: number;
  entryLabelFilter?: (label: string) => FacetLabel;
  entryLabelFilter_2?: (label: string) => FacetLabel;
  postSort?: (a: FacetEntry, b: FacetEntry) => number;
}

export const resultsPerPage = [10, 20, 50, 100, 200];
export const resultPaginationNeighbors = 3;

const idHashFilter = (dashboard: DashboardType, entity?: HierarchyEntity) => (label: string): FacetLabel => {
  const idx = label.indexOf('#');
  if (idx === -1) {
    return label;
  } else {
    const id = parseInt(label.slice(0, idx), 10);
    const name = label.slice(idx + 1);

    if (undefined !== entity && id > 0) {
      return (c: (n) => React.ReactNode) => React.createElement(HierarchyTooltip, { entity, id, name }, c(name));
    } else {
      return name;
    }
  }
};

const issuedYearPostSort = (a: FacetEntry, b: FacetEntry): number => {
  const A: number = (a as SelectFacetEntry).value as number;
  const B: number = (b as SelectFacetEntry).value as number;
  return B - A;
};

export const defaultResultPageLimit = 10;
export const defaultFacetPageCount = 10;

// eslint-disable max-len
export const facetConfiguration: {
  [key in DashboardType]: FacetConfiguration[];
} = {
  [DashboardType.WORKS]: [
    {
      name: 'f_top_orga',
      label: 'search.facet.work.top_orga',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.WORKS, HierarchyEntity.ORGS),
    },
    {
      name: 'f_editorial_status',
      label: 'search.facet.work.editorial_status',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: editStatusFilter,
    },
    {
      name: 'f_publication_status',
      label: 'search.facet.work.publication_status',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: pubStatusFilter,
    },
    {
      name: 'f_publication_type',
      label: 'search.facet.work.publication_type',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: pubtypeFilter,
    },
    {
      name: 'f_form_subject',
      label: 'search.facet.work.form_subject',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: formSubjectFilter,
    },
    {
      name: 'f_issued',
      label: 'search.facet.work.issued',
      type: FacetType.DYNAMIC_SELECT,
      postSort: issuedYearPostSort,
    },
    { name: 'f_person', label: 'search.facet.work.person', type: FacetType.DYNAMIC_SELECT },
    {
      name: 'f_organisation',
      label: 'search.facet.work.organisation_person',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.WORKS, HierarchyEntity.ORGS),
    },
    {
      name: 'f_project',
      label: 'search.facet.work.project_person',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.WORKS, HierarchyEntity.PROJECTS),
    },
    {
      name: 'f_belongs_to_organisation',
      label: 'search.facet.work.organisation_work',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.WORKS, HierarchyEntity.ORGS),
    },
    {
      name: 'f_belongs_to_project',
      label: 'search.facet.work.project_work',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.WORKS, HierarchyEntity.PROJECTS),
    },
    {
      name: 'f_access_level',
      label: 'search.facet.work.access_level',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: accessLevelFilter
    },
    {
      name: 'f_oa',
      label: 'search.facet.work.oa',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: fundedTypeFilter,
      entryLabelFilter_2: affiliationFilter,
    },
    {
      name: 'f_language',
      label: 'search.facet.work.language',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: languageFilter,
    },
    //    { name: '', label: 'Meine Datensätze', type: FacetType.DYNAMIC_SELECT },
    {
      name: 'f_peer_reviewed',
      label: 'search.facet.work.peer_reviewed',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: peerReviewStatusFilter
    },
    {
      name: 'f_hidden',
      label: 'search.facet.work.hidden',
      type: FacetType.BOOLEAN,
      entryLabelFilter: boolFilter,
    },
    {
      name: 'f_external_identifier',
      label: 'search.facet.work.external_identifier',
      type: FacetType.DYNAMIC_SELECT,
      tristate: true,
      entryLabelFilter: externalIdentifierFilter,
    },
    { name: 'f_origin', label: 'search.facet.work.origin', type: FacetType.DYNAMIC_SELECT },
  ],
  [DashboardType.PEOPLE]: [
    {
      name: 'f_top_orga',
      label: 'search.facet.person.top_orga',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.PEOPLE, HierarchyEntity.ORGS),
    },
    {
      name: 'f_person_status',
      label: 'search.facet.person.person_status',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: personStatusFilter,
    },
    {
      name: 'f_organisation',
      label: 'search.facet.person.organisation',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.PEOPLE, HierarchyEntity.ORGS),
    },
    {
      name: 'f_project',
      label: 'search.facet.person.project',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.PEOPLE, HierarchyEntity.PROJECTS),
    },
    {
      name: 'f_external_identifier',
      label: 'search.facet.person.external_identifier',
      type: FacetType.DYNAMIC_SELECT,
      tristate: true,
      entryLabelFilter: personIdentifierFilter,
    },
    {
      name: 'f_editorial_status',
      label: 'search.facet.person.editorial_status',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: editStatusFilter,
    },
    {
      name: 'f_data_last_supplied',
      label: 'search.facet.person.data_last_supplied',
      type: FacetType.DATE_RANGE,
    },
  ],
  [DashboardType.ORGS]: [
    {
      name: 'f_top_orga',
      label: 'search.facet.organisation.top_orga',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.ORGS, HierarchyEntity.ORGS),
    },
    {
      name: 'f_organisation',
      label: 'search.facet.organisation.organisation',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.ORGS, HierarchyEntity.ORGS),
    },
    {
      name: 'f_project',
      label: 'search.facet.organisation.project',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.ORGS, HierarchyEntity.PROJECTS),
    },
    {
      name: 'f_external_identifier',
      label: 'search.facet.organisation.external_identifier',
      type: FacetType.DYNAMIC_SELECT,
      tristate: true,
      entryLabelFilter: unitIdentifierFilter,
    },
    {
      name: 'f_editorial_status',
      label: 'search.facet.organisation.editorial_status',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: editStatusFilter,
    },
  ],
  [DashboardType.PROJECTS]: [
    {
      name: 'f_top_orga',
      label: 'search.facet.project.top_orga',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.PROJECTS, HierarchyEntity.ORGS),
    },
    {
      name: 'f_project',
      label: 'search.facet.project.project',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.PROJECTS, HierarchyEntity.PROJECTS),
    },
    {
      name: 'f_partner',
      label: 'search.facet.project.partner',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: idHashFilter(DashboardType.PROJECTS, HierarchyEntity.ORGS),
    },
    {
      name: 'f_funder',
      label: 'search.facet.project.funder',
      type: FacetType.DYNAMIC_SELECT,
    },
    {
      name: 'f_external_identifier',
      label: 'search.facet.project.external_identifier',
      type: FacetType.DYNAMIC_SELECT,
      tristate: true,
      entryLabelFilter: unitIdentifierFilter,
    },
    {
      name: 'f_editorial_status',
      label: 'search.facet.project.editorial_status',
      type: FacetType.DYNAMIC_SELECT,
      entryLabelFilter: editStatusFilter,
    },
  ],
};
// eslint-enable  max-len
