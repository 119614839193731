import queryString from 'query-string';
import * as React from 'react';
import { Subscribe } from 'unstated';
import { OAuth2Container } from '../containers/OAuth2Container';

import getLogger from '../../log';
const logger = getLogger('Auth/OAuth2Receiver');

export interface Props {
  children?: any;
}

class InnerReceiver extends React.Component<{ container: OAuth2Container }> {
  public render() {
    return [];
  }

  public componentDidMount() {
    const { container } = this.props;

    const params = queryString.parse(document.location.search);
    if (params.code !== undefined && params.state !== undefined) {
      const authCode = String(params.code);
      const authState = String(params.state);
      try {
        container
          .getTokenWithCode(authCode, authState)
          .then(() => {
            logger.debug('token acquired with code');
          })
          .catch((ex) => {
            logger.warn(ex);
          });
      } catch (ex) {
        logger.error(ex);
      }
    }
  }
}

/**
 * On mount, process a successful return from the auth service by parsing the query string and
 * redeeming the auth code for an access_token and refresh_token.
 */
export function OAuth2Receiver(props: Props): React.JSX.Element  {
  return (
    <>
      <Subscribe to={[OAuth2Container]}>
        {(container: OAuth2Container) => {
          return <InnerReceiver container={container} />;
        }}
      </Subscribe>
      {props.children}
    </>
  );
}
