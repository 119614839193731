import { ProjectDTO, OrganisationDTO, PersonDTO, WorkDTO, PersonNameDTO } from '../api/core/models';

export enum EditMode {
  UPDATE = 'update',
  INSERT = 'insert',
  DELETE = 'delete',
}

export type BaseDetailModel = {
  id: number;
  name?: string;
  title?: string;
  personNames?: PersonNameDTO[];
};

export type DetailEditModels = PersonDTO | OrganisationDTO | ProjectDTO | WorkDTO;

export type DetailEditProps<Model extends BaseDetailModel> = {
  id?: number;
  mode: EditMode;
  modifiedModel: Model;
  currentModel?: Model;
  updateField: (name: string, data: any) => void;
  updateAdminField: (name: string, data: any) => void;
  submit: () => Promise<void>;
  delete: () => Promise<void>;
  deleteAbort: () => Promise<void>;
  redirect: string | null;
  deleteInit: boolean;
  deleteActive: boolean;
  submitActive: boolean;
};

export enum TopOrgaEntity {
  ORGS = 'orgs',
  PROJECTS = 'projects',
  PERSONS = 'persons'
}
