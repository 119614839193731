import React, { useState } from 'react';

export const ErrorPagesContext: React.Context<any> = React.createContext(null);

export const ErrorPagesProvider = ({ children }): React.JSX.Element => {
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  return (
    <ErrorPagesContext.Provider value={{ hideHeaderFooter, setHideHeaderFooter, generalError, setGeneralError }}>
      {children}
    </ErrorPagesContext.Provider>
  );
};
