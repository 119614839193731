import * as React from 'react';
import { No, Yes } from './components/Messages';
import { Notation } from './components/Notation';
import { names, vocabularies, VocabularyName as VN } from './gen_vocabulary';
import { getVocabulary } from './util';
import { ReactElement } from 'react';
export { names, vocabularies };
export type VocabularyName = VN;

export const editorialStatusKeys = Array.from(getVocabulary('editorial_status_list', 'de', '*').keys());
export const pubtypeKeys = Array.from(getVocabulary('pubtype_list', 'de', '*').keys());

const defaultEntryLabels = {
  boolean: new Map<string | boolean, React.ReactNode>([
    [true, Yes],
    ['true', Yes],
    [false, No],
    ['false', No],
  ]),
};

export const labelMapFilter = <T>(map: Map<T, React.ReactNode>) => (value: T): React.ReactNode => {
  const result = map.get(value);
  return undefined === result ? String(value) : result;
};
export const labelVocabFilter = (v: string, p: string = '') => (value: string): ReactElement => {
  return React.createElement(Notation, { v, p, k: value });
};

export const boolFilter = labelMapFilter(defaultEntryLabels.boolean);
export const editStatusFilter = labelVocabFilter('editorial_status_list', '*');
export const pubStatusFilter = labelVocabFilter('publication_status_list', '*');
export const personStatusFilter = labelVocabFilter('person_status_list', '*');
export const formSubjectFilter = labelVocabFilter('form_subject_list', '*');
export const externalIdentifierFilter = labelVocabFilter('external_id_list', '*');
export const personIdentifierFilter = labelVocabFilter('external_person_id_list', '*');
export const unitIdentifierFilter = labelVocabFilter('external_unit_id_list', '*');
export const accessLevelFilter = labelVocabFilter('access_level', '*');
export const pubtypeFilter = labelVocabFilter('pubtype_list', '*');
export const resourceTypeFilter = labelVocabFilter('resource_type_list', '*');
export const affiliationFilter = labelVocabFilter('affiliations_list', '*');
export const fundedTypeFilter = labelVocabFilter('funded_list', '*');
export const peerReviewStatusFilter = labelVocabFilter('peer_review_status_list', '*');
export const languageFilter = labelVocabFilter('language_list', '*');
