/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferencedWorkDTO,
    ReferencedWorkDTOFromJSON,
    ReferencedWorkDTOFromJSONTyped,
    ReferencedWorkDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkHasPartDTO
 */
export interface WorkHasPartDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkHasPartDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkHasPartDTO
     */
    articleId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkHasPartDTO
     */
    issue?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkHasPartDTO
     */
    pageFirst?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkHasPartDTO
     */
    pageLast?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkHasPartDTO
     */
    volume?: string;
    /**
     * 
     * @type {ReferencedWorkDTO}
     * @memberof WorkHasPartDTO
     */
    referencedWork?: ReferencedWorkDTO;
}

export function WorkHasPartDTOFromJSON(json: any): WorkHasPartDTO {
    return WorkHasPartDTOFromJSONTyped(json, false);
}

export function WorkHasPartDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkHasPartDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'pageFirst': !exists(json, 'pageFirst') ? undefined : json['pageFirst'],
        'pageLast': !exists(json, 'pageLast') ? undefined : json['pageLast'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'referencedWork': !exists(json, 'referencedWork') ? undefined : ReferencedWorkDTOFromJSON(json['referencedWork']),
    };
}

export function WorkHasPartDTOToJSON(value?: WorkHasPartDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'articleId': value.articleId,
        'issue': value.issue,
        'pageFirst': value.pageFirst,
        'pageLast': value.pageLast,
        'volume': value.volume,
        'referencedWork': ReferencedWorkDTOToJSON(value.referencedWork),
    };
}


