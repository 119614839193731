import React, { ReactElement } from 'react';
import { Button } from 'theme-ui';
import { FormattedMessage } from 'react-intl';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const AddButton: React.FC<{onClick: (e: any) => void; disabled?: boolean}> = ({onClick, disabled, children}): ReactElement => {
  return <Button
    sx={{backgroundColor: "success", p: 4, px: 5, fontWeight: "bold", mt: 6 }}
    disabled={disabled}
    onClick={onClick}>
    {children ? children : "+"} <FormattedMessage id="button.add" />
  </Button>;
};

export const DeleteButton: React.FC<{onClick: (e: any) => void; disabled?: boolean}> = ({onClick, disabled}): ReactElement => {
  return <Button
    sx={{backgroundColor: "danger", p: 4, fontWeight: "bold", mt: 2, color: "white" }}
    disabled={disabled}
    onClick={onClick}>
    <i className="icon icon-papierkorb_blau" sx={{"&:before": {color: "white"}}}></i>
  </Button>;
};
