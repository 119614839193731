/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferencedPersonNameDTO
 */
export interface ReferencedPersonNameDTO {
    /**
     * 
     * @type {number}
     * @memberof ReferencedPersonNameDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReferencedPersonNameDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferencedPersonNameDTO
     */
    givenname?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferencedPersonNameDTO
     */
    salutation?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferencedPersonNameDTO
     */
    isMain?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferencedPersonNameDTO
     */
    personId?: number;
}

export function ReferencedPersonNameDTOFromJSON(json: any): ReferencedPersonNameDTO {
    return ReferencedPersonNameDTOFromJSONTyped(json, false);
}

export function ReferencedPersonNameDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferencedPersonNameDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'givenname': !exists(json, 'givenname') ? undefined : json['givenname'],
        'salutation': !exists(json, 'salutation') ? undefined : json['salutation'],
        'isMain': !exists(json, 'isMain') ? undefined : json['isMain'],
        'personId': !exists(json, 'personId') ? undefined : json['personId'],
    };
}

export function ReferencedPersonNameDTOToJSON(value?: ReferencedPersonNameDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'surname': value.surname,
        'givenname': value.givenname,
        'salutation': value.salutation,
        'isMain': value.isMain,
        'personId': value.personId,
    };
}


