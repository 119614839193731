/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchFacet,
    SearchFacetFromJSON,
    SearchFacetFromJSONTyped,
    SearchFacetToJSON,
    SolrOrganisation,
    SolrOrganisationFromJSON,
    SolrOrganisationFromJSONTyped,
    SolrOrganisationToJSON,
} from './';

/**
 * 
 * @export
 * @interface SolrResultSolrOrganisation
 */
export interface SolrResultSolrOrganisation {
    /**
     * 
     * @type {number}
     * @memberof SolrResultSolrOrganisation
     */
    numberResults?: number;
    /**
     * 
     * @type {Array<SolrOrganisation>}
     * @memberof SolrResultSolrOrganisation
     */
    results?: Array<SolrOrganisation>;
    /**
     * 
     * @type {Array<SearchFacet>}
     * @memberof SolrResultSolrOrganisation
     */
    facets?: Array<SearchFacet>;
}

export function SolrResultSolrOrganisationFromJSON(json: any): SolrResultSolrOrganisation {
    return SolrResultSolrOrganisationFromJSONTyped(json, false);
}

export function SolrResultSolrOrganisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolrResultSolrOrganisation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberResults': !exists(json, 'numberResults') ? undefined : json['numberResults'],
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(SolrOrganisationFromJSON)),
        'facets': !exists(json, 'facets') ? undefined : ((json['facets'] as Array<any>).map(SearchFacetFromJSON)),
    };
}

export function SolrResultSolrOrganisationToJSON(value?: SolrResultSolrOrganisation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberResults': value.numberResults,
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(SolrOrganisationToJSON)),
        'facets': value.facets === undefined ? undefined : ((value.facets as Array<any>).map(SearchFacetToJSON)),
    };
}


