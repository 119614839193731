/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmploymentPeriodDTO,
    EmploymentPeriodDTOFromJSON,
    EmploymentPeriodDTOFromJSONTyped,
    EmploymentPeriodDTOToJSON,
    ReferencedOrganisationDTO,
    ReferencedOrganisationDTOFromJSON,
    ReferencedOrganisationDTOFromJSONTyped,
    ReferencedOrganisationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersonToOrganisationDTO
 */
export interface PersonToOrganisationDTO {
    /**
     * 
     * @type {number}
     * @memberof PersonToOrganisationDTO
     */
    id?: number | null;
    /**
     * 
     * @type {ReferencedOrganisationDTO}
     * @memberof PersonToOrganisationDTO
     */
    organisation?: ReferencedOrganisationDTO;
    /**
     * 
     * @type {Array<EmploymentPeriodDTO>}
     * @memberof PersonToOrganisationDTO
     */
    employmentPeriods?: Array<EmploymentPeriodDTO>;
}

export function PersonToOrganisationDTOFromJSON(json: any): PersonToOrganisationDTO {
    return PersonToOrganisationDTOFromJSONTyped(json, false);
}

export function PersonToOrganisationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonToOrganisationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'organisation': !exists(json, 'organisation') ? undefined : ReferencedOrganisationDTOFromJSON(json['organisation']),
        'employmentPeriods': !exists(json, 'employmentPeriods') ? undefined : ((json['employmentPeriods'] as Array<any>).map(EmploymentPeriodDTOFromJSON)),
    };
}

export function PersonToOrganisationDTOToJSON(value?: PersonToOrganisationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organisation': ReferencedOrganisationDTOToJSON(value.organisation),
        'employmentPeriods': value.employmentPeriods === undefined ? undefined : ((value.employmentPeriods as Array<any>).map(EmploymentPeriodDTOToJSON)),
    };
}


