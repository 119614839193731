import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { WorkDTO, ReferencedProjectDTO, WorkProjectDTO } from 'api/core/models';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultWorkProject } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from 'BaseLayout/components/form/MultiInput/Buttons';
import { isRequired } from 'BaseLayout/components/form/generic/utils';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';
import { ProjectSuggest } from 'Edit/components/auto-complete/ProjectSuggest';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';


const messages = defineMessages({
  projects: { id: 'work.attr.workProjects' },
  project: { id: 'work.attr.workProjectOthers.attr.project' },
  projectHelp: { id: 'work.attr.workProjectOthers.attr.project.help' },
  role: { id: 'work.attr.workProject.attr.role' },
});

interface WorkProjectsProps {
  modifiedModel: WorkDTO;
  updateField: (field: string, wg: WorkProjectDTO[]) => void;
  entity: string;
  pubtypeGroup: string;
  validation: ValidationResult;
}

export function WorkProjects({
  modifiedModel,
  updateField,
  entity,
  pubtypeGroup,
  validation
}: WorkProjectsProps): ReactElement {
  const intl: IntlShape = useIntl();

  const {workProjects: projects} = modifiedModel;
  return (
    <DataModelGate entity={entity} always field="work_projects">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        <div className="col">
          {checkPermission().read && (
            <div>
              <Styled.h2> {intl.formatMessage(messages.projects)} </Styled.h2>
              <Border>
                <MultiInputControls<any>
                  lines={projects ? projects : []}
                  onChange={(gps: WorkProjectDTO[]): void => {
                    updateField("workProjects", [...gps]);
                  }}
                  remove
                  add
                  defaultRow={DefaultWorkProject}
                  renderLines={(
                    project: WorkProjectDTO,
                    onChangeGo2: (data: WorkProjectDTO) => void,
                    actions,
                    idx: number
                  ): ReactElement => {
                    return (
                      <div className="fieldRow" key={idx}>
                        {checkPermission('project').read && (
                          <ProjectSuggest
                            name={intl.formatMessage(messages.project)}
                            help={intl.formatMessage(messages.projectHelp)}
                            required={isRequired(checkPermission('project'))}
                            single={true}
                            canDelete={false}
                            tags={project.project ? [project.project] : []}
                            onChange={(referencedProjects: ReferencedProjectDTO[]): void => {
                              project.project = {
                                id: referencedProjects[0].id,
                                name: referencedProjects[0].name
                              };
                              return onChangeGo2(project);
                            }}
                            disabled={!checkPermission('project').write}
                            validation={validation?.children[idx]?.project}
                            autoFocus={true}
                          />
                        )}
                        <SelectInputP
                          listPartition={pubtypeGroup}
                          onChange={(role: string): void => {
                            project.role = role;
                            onChangeGo2(project);
                          }}
                          value={project.role}
                          list={'corporation_role_list'}
                          name={intl.formatMessage(messages.role)}
                          inputName="role"
                          permissions={checkPermission('role')}
                          validation={validation?.children[idx]?.role}
                        />
                        {!!actions.delete && (
                          <div className="deleteButton">
                            <DeleteButton onClick={actions.delete}/>
                          </div>
                        )}
                      </div>
                    );
                  }}
                  renderEnd={(actionAdd: (e: any) => void|undefined): ReactElement => (
                    <div className="addButton">
                      <AddButton onClick={actionAdd} />
                    </div>
                  )}
                />
              </Border>
            </div>
          )}
        </div>
      )}
    </DataModelGate>
  );
}
