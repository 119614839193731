import React, { useRef, useState } from "react";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import DatePicker, { DateObject } from "react-multi-date-picker";
import { Box, Button } from 'theme-ui';
import { de_calender, en_calender } from "./data";
import isMatch from 'date-fns/isMatch';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { InputLabel } from '../generic/InputLabel';
import { ValidationResult } from '../../../../Edit/validation/interfaces';

interface DatePickerProps {
  onChange: (date: DateObject) => void;
  value?: Date;
  disabled?: boolean;
  label: string;
  help?: string;
  required?: boolean;
  classes?: string[];
  validation?: ValidationResult;
}

export const DatePickerStyled: React.FC<DatePickerProps> = ({onChange, value, validation, disabled, label, help, required, classes}) => {
  const intl: IntlShape = useIntl();
  const locale = intl.locale;
  const shouldCloseCalendar = true;
  const datePickerRef = useRef<any>();
  const [inputValue, setInputValue] = useState<string>('');
  const [visibilityOnTab, setVisibilityOnTab] = useState<boolean | null>(null);

  if(!validation) validation = {valid: true};
  if(required && !value) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }
  classes = classes ? classes : [];
  if (required){
    classes.push("required");
  }
  if(validation && !validation.valid) {
    classes.push("invalid");
  }

  const validateInput = (valueToValidate: string) => {
    if (valueToValidate !== '' && isMatch(valueToValidate, 'yyyy-MM-dd') === false) {
      onChange(null);
    }
  };

  return (
    <Box className={"date-picker"}
      sx={{
        minWidth: '205px',
        width: '100%',
        ".rmdp-container": {
          minWidth: '205px',
          width: '100%',
          padding: 0
        },
        ".rmdp-input": {
          variant: 'forms.input',
          height: 'inherit',
          width: '100%',
          fontSize: 'inherit',
          borderColor: validation.valid  ? 'light2' : validation.valid === undefined ? 'light2' : 'negative'
        },
        ".rmdp-range": {
          backgroundColor: 'primary'
        },
        ".rmdp-today .sd, .rmdp-day.rmdp-today span": {
          backgroundColor: 'secondary',
        },
        ".rmdp-day.rmdp-selected span:not(.highlight)": {
          backgroundColor: 'primary',
        },
        ".rmdp-week-day": {
          color: 'primary',
        },
        ".rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span.sd:hover": {
          backgroundColor: 'darker',
        },
        ".rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
          backgroundColor: 'darker'
        },
        ".rmdp-arrow": {
          borderColor: 'primary'
        },
        ".rmdp-arrow-container:hover": {
          backgroundColor: 'darker',
          boxShadow: 'none'
        },
        ".label": {
          color: validation.valid ? 'primary' : validation.valid === undefined ? 'primary' : 'negative',
          pb: '0px',
          mb: '-1px'
        },
        ".rmdp-wrapper": {
          display: visibilityOnTab === null ? '' : visibilityOnTab ? '' : 'none',
        },
        ".rmdp-ep-arrow": {
          display: visibilityOnTab === null ? '' : visibilityOnTab ? '' : 'none',
        }
    }}>
      <InputLabel variant="labelY" classes={classes} label={intl.formatMessage({id: label})||''} help={help} required={required}>
        <div
          onChange={(e) => setInputValue(e.currentTarget.children[0].children[0].attributes[3].value)}
          onSelect={(e) => setInputValue(e.currentTarget.children[0].children[0].attributes[3].value)}
          onKeyDown={(e) => {
            if (e.key === 'Tab') {
              setVisibilityOnTab(false);
              validateInput(inputValue);
            }
          }}
          onClick={() => setVisibilityOnTab(true)}
        >
          <DatePicker
            editable={true}
            weekStartDayIndex={2}
            value={value ? value : new Date('')}
            format={"YYYY-MM-DD"}
            onChange={onChange}
            onClose={() => {
              validateInput(inputValue);
              return shouldCloseCalendar;
            }}
            disabled={disabled}
            ref={datePickerRef}
            months={locale.startsWith('de') ? de_calender.months : gregorian_en.months}
            weekDays={locale.startsWith('de') ? de_calender.weekDays : en_calender.weekDays}
          >
            <Button
              sx={{ mb: "5px", mt: "-20px" }}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                onChange(new DateObject());
                datePickerRef.current.closeCalendar();
              }}
            >
              <FormattedMessage id="button.today" />
            </Button>
          </DatePicker>
        </div>
      </InputLabel>
    </Box>
  );
};
