import * as React from 'react';
import { Main } from '../../BaseLayout/components/base-layout/main';
import { Card } from 'theme-ui';
import { Import as ImportComponent } from '../components/Import';
import { WorkDTO } from '../../api/core/models';
import ConnectedEdit from '../../Edit/container/DetailEdit';
import { DashboardType } from '../../Dashboard/types';
import { EditMode } from '../../Edit/types';
import { useState } from 'react';

interface Props {}

export function Import(props: Props) {
  const [importModel, setImportModel] = useState<WorkDTO>();

  const boundSetImportModel = (importModel?: WorkDTO) => {
    setImportModel(importModel);
  };

  if (importModel) {
    return (
      <ConnectedEdit
        entity={DashboardType.WORKS}
        mode={EditMode.INSERT}
        newModelOverwrite={{ ...importModel, id: undefined }}
      />
    );
  }

  return (
    <Main>
      <Card>
        <ImportComponent submitImport={boundSetImportModel} />
      </Card>
    </Main>
  );
}
