import * as React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { PatchDocumentFromJSON } from '../../api/core/models';
import { connectOAuth2, OAuth2AwareProps } from 'Auth/utils/connect';
import Popup from 'BaseLayout/components/popup/Popup';
import { OrganisationApi } from 'api/core/apis/OrganisationApi';
import { useStatusCode } from 'Utils/StatusCodeHandler';
import { handleStatusCode422 } from 'Utils/StatusCodeUtils';

export interface DeleteOrgaProps {
  orgaId: number;
  orgaName: string;
  isDeleteRequest?: boolean;
  onAbort?: () => void;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

type InnerProps = OAuth2AwareProps<DeleteOrgaProps>;

const InnerDeleteOrga = (props: InnerProps): JSX.Element => {
  const intl: IntlShape = useIntl();
  const orgaApi = props.apiWithAuth(new OrganisationApi());
  const { setStatusCode } = useStatusCode();

  const submitDelete = async (): Promise<void> => {
    if (!!props.isDeleteRequest) {
      try {
        await orgaApi.updateOrganisation({
          organisationId: props.orgaId,
          patchDocument: [PatchDocumentFromJSON({
              op: 'replace',
              path: '/adminDataOrganisation/editorialStatus',
              value: 'deleted',
            })]
        });
        props.onSuccess();
      } catch(error) {
        props.onError(error);
      }
    } else {
      try {
        const response = await orgaApi.deleteOrganisationRaw({ organisationId: props.orgaId });
        await setStatusCode({status: response.raw.status, action: 'delete', data: {entity: 'orgs', name: props.orgaName, id: props.orgaId}});
        props.onSuccess();
      } catch (error) {
        if (error.status === 422) {
          props.onError(error);
          return handleStatusCode422(error, setStatusCode);
        }
        setStatusCode({status: error.status});
      }
    }
  };

  const title = props.isDeleteRequest ?
    intl.formatMessage({ id: 'orga.deleteRequest.popup.title' }) :
    intl.formatMessage({ id: 'orga.delete.popup.title' });

  return (
    <Popup
      open={true}
      title={title}
      onClose={props.onAbort}
      buttons={[
        {
          label: intl.formatMessage({ id: 'popup.button.confirm' }),
          onClick: submitDelete
        },
        {
          label: intl.formatMessage({ id: 'popup.button.abort' }),
          onClick: props.onAbort
        }
      ]}
    >
      {props.isDeleteRequest &&
      <FormattedMessage id="orga.deleteRequest.popup.message(orgaName)" values={{ orgaName: props.orgaName }} />
      }
      {!props.isDeleteRequest &&
      <FormattedMessage id="orga.delete.popup.message(orgaName)" values={{ orgaName: props.orgaName }} />
      }
    </Popup>
  );
};

export const DeleteOrga = connectOAuth2(InnerDeleteOrga);

export default DeleteOrga;
