import * as React from 'react';
import { Subscribe } from 'unstated';
// Components
import { ConnectedBaseLayout as BaseLayout } from './BaseLayout/container/BaseLayout';
import './Detail/data';

// Unstated
import { Provider as UnstatedProvider } from 'unstated';

// Data
import { DashboardType } from './Dashboard/types';
import { getCreateContextUrl, getDetailContextUrl, getSearchContextUrl } from './data';
import { ViewType } from './Detail/types';
import { EditMode } from './Edit/types';

// Router
import { History } from 'history';
import { Redirect, Route, Router, Switch } from 'react-router-dom'; // react-router v5
import { RedirectComputedPath } from 'Utils/RedirectComputedPath';

// Authentication
import { OAuth2Gate, OAuth2Logout, OAuth2Provider, OAuth2Receiver, OAuth2RequireAuth } from './Auth/components';
import { OAuth2Container } from './Auth/containers/OAuth2Container';

// I18n
import { IntlProvider } from 'react-intl';
import Moment from 'react-moment';
import { getMessages } from './Intl/data';
import { getLanguageFromLocale, pickLocale } from './Intl/utils';
import { Import } from './Import/pages/Import';
import PubListUpload from 'PubDataUpload/pages/PubDataUpload';
import { useEffect, useState } from 'react';
import { UserSettingsTabs } from 'UserArea/settings/types';
import { UserProfileTabs } from 'UserArea/profile/types';

// AlertProvider
import { Provider as AlertProvider} from 'react-alert';
import { AlertTemplate } from 'BaseLayout/components/base-layout/AltertTemplate/AlertTemplate';

import { StatusCodeHandler } from 'Utils/StatusCodeHandler';
import { ErrorPagesProvider } from 'Utils/ErrorPagesProvider';
import { ResourceNotFoundError } from 'BaseLayout/components/error/resource-not-found-error';
import { GeneralError } from 'BaseLayout/components/error/general-error';
import { ErrorBoundary } from 'react-error-boundary';
import { Bomb } from 'Utils/ErrorBoundaryTest';
import { MathJaxContext } from 'better-react-mathjax';
// Logger
import getLogger from './log';
import { getThemeNameFromOrigin } from 'BaseLayout/components';
import { ThemeName } from './BaseLayout/utils';
const logger = getLogger('App');

// Lazy-loaded page components
const Homepage = React.lazy(() => import('./HomePage/container/Homepage'));
const Dashboard = React.lazy(() => import('./Dashboard/pages/Dashboard'));
const DetailView = React.lazy(() => import('./Detail/container/DetailView'));
const DetailEdit = React.lazy(() => import('./Edit/container/DetailEdit'));
const PubListGenerator = React.lazy(() => import('./PubListGenerator/container/PubListGenerator'));
const PubListDoc = React.lazy(() => import('./PubListDocumentation/container/PubListDocumentation'));
const UserAdminArea = React.lazy(() => import('./UserAdminArea/components/UserAdminArea'));
const ConnectedOrcidAdminArea = React.lazy(() => import('./OrcidAdminArea/containers/OrcidAdminArea'));
const ConnectedUserProfile = React.lazy(() => import('./UserArea/profile/containers/Profile'));
const ConnectedUserSettings = React.lazy(() => import('./UserArea/settings/containers/UserSettings'));

export interface Props {
  history: History;
}

export const localeKey = 'locale';

const getPreferredLocales = () => {
  const l = [...(navigator.languages || [navigator.language])];
  const storedLocale = window.localStorage.getItem(localeKey);
  if (storedLocale !== null) {
    l.unshift(storedLocale);
  }
  return l;
};

const selectLocale = () => {
  const preferred = getPreferredLocales();
  const locale = pickLocale(preferred);
  logger.debug(`Chosen Locale: ${locale} Preferred: ${preferred}`);
  return locale;
};

export const App: React.FC<Props> = ({ history }) => {
  const [locale, setLocale] = useState<string>(selectLocale());
  const themeName: ThemeName = getThemeNameFromOrigin();

  useEffect(() => {
    const handleLocaleChange = () => {
      setLocale(selectLocale());
    };

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key !== localeKey) {
        return;
      }
      handleLocaleChange();
    };

    window.addEventListener('languagechange', handleLocaleChange);
    window.addEventListener('applanguagechange', handleLocaleChange);
    window.addEventListener('storage', handleStorageChange);

    if(themeName === 'hhu') {
      const script = document.createElement("script");
      script.text = "var _mtm = window._mtm = window._mtm || [];\n"
        + "_mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});\n"
        + "var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];\n"
        + "g.async=true; g.src='https://piwik.ub.uni-duesseldorf.de/piwik/js/container_eudl0gSm.js'; s.parentNode.insertBefore(g,s);";
      document.head.appendChild(script);
    }

    return () => {
      window.removeEventListener('languagechange', handleLocaleChange);
      window.removeEventListener('applanguagechange', handleLocaleChange);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const messages = getMessages(getLanguageFromLocale(locale));
  Moment.globalLocale = locale;

  return (
    <UnstatedProvider>
      <IntlProvider
        locale={locale}
        defaultLocale="de-DE"
        onError={(e) => {
          // logger.find('/Intl').warn(e);
        }}
        messages={messages}
      >
        <OAuth2Provider>
          <Router history={history}>
            <ErrorPagesProvider>
              <BaseLayout>
                <ErrorBoundary FallbackComponent={GeneralError}>
                  <MathJaxContext version={3}>
                    <AlertProvider template={AlertTemplate} timeout={6000}>
                      <StatusCodeHandler>
                        <React.Suspense fallback={<div>Loading...</div>}>
                          <Switch>
                            {/* Homepage */}
                            <Route exact path="/" render={() => <Homepage />} />

                            {/* Search / Dashboard */}
                            <Route
                              exact
                              path="/search"
                              render={() => <Redirect to={getSearchContextUrl(DashboardType.WORKS)} />}
                            />
                            <Route
                              exact
                              path={getSearchContextUrl(DashboardType.WORKS)}
                              render={() => <Dashboard entity={DashboardType.WORKS} />}
                            />
                            <Route
                              exact
                              path={getSearchContextUrl(DashboardType.PEOPLE)}
                              render={() => <Dashboard entity={DashboardType.PEOPLE} />}
                            />
                            <Route
                              exact
                              path={getSearchContextUrl(DashboardType.ORGS)}
                              render={() => <Dashboard entity={DashboardType.ORGS} />}
                            />
                            <Route
                              exact
                              path={getSearchContextUrl(DashboardType.PROJECTS)}
                              render={() => <Dashboard entity={DashboardType.PROJECTS} />}
                            />

                            {/* Detail View */}
                            <Route
                              exact
                              path={getDetailContextUrl(ViewType.WORKS) + '/:id(\\d+)'}
                              render={({ match }) => <DetailView entity={ViewType.WORKS} id={Number(match.params?.id)} />}
                            />
                            <Route
                              exact
                              path={getDetailContextUrl(ViewType.PEOPLE) + '/:id(\\d+)'}
                              render={({ match }) => <DetailView entity={ViewType.PEOPLE} id={Number(match.params?.id)} />}
                            />
                            <Route
                              exact
                              path={getDetailContextUrl(ViewType.ORGS) + '/:id(\\d+)'}
                              render={({ match }) => <DetailView entity={ViewType.ORGS} id={Number(match.params?.id)} />}
                            />
                            <Route
                              exact
                              path={getDetailContextUrl(ViewType.PROJECTS) + '/:id(\\d+)'}
                              render={({ match }) => <DetailView entity={ViewType.PROJECTS} id={Number(match.params?.id)} />}
                            />

                            {/* Detail Edit */}
                            <Route
                              path={getCreateContextUrl(ViewType.WORKS)+"/:pubtype?"}
                              render={({ match }) => <DetailEdit entity={ViewType.WORKS} mode={EditMode.INSERT} />}
                              // render={({ match }) => <DetailEdit entity={ViewType.WORKS} mode={EditMode.INSERT} newModelOverwrite={{...emptyModelWork, pubtype: match.params.pt } as WorkDTO} />}
                            />
                            <Route
                              path="/work/:id(\d+)/edit"
                              render={({ match }) => (
                                <DetailEdit entity={ViewType.WORKS} mode={EditMode.UPDATE} id={Number(match.params.id)} />
                              )}
                            />
                            <Route
                              exact
                              path={getCreateContextUrl(ViewType.PEOPLE)}
                              render={({ match }) => (
                                <OAuth2Gate
                                  anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}
                                  renderError={(err) => (
                                    <OAuth2Gate>
                                      <Redirect to="/" />
                                    </OAuth2Gate>
                                  )}>
                                  <DetailEdit entity={ViewType.PEOPLE} mode={EditMode.INSERT} />
                                </OAuth2Gate>
                              )}
                            />
                            <Route
                              exact
                              path="/person/:id(\d+)/edit"
                              render={({ match }) => (
                                <OAuth2Gate
                                  anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}
                                  renderError={(err) => (
                                    <OAuth2Gate>
                                      <Redirect to={'/person/' + match.params.id} />
                                    </OAuth2Gate>
                                  )}>
                                  <DetailEdit entity={ViewType.PEOPLE} mode={EditMode.UPDATE} id={Number(match.params.id)} />
                                </OAuth2Gate>
                              )}
                            />
                            <Route
                              exact
                              path={getCreateContextUrl(ViewType.ORGS)}
                              render={({ match }) => (
                                <OAuth2Gate
                                  anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}
                                  renderError={(err) => (
                                    <OAuth2Gate>
                                      <Redirect to="/" />
                                    </OAuth2Gate>
                                  )}>
                                  <DetailEdit entity={ViewType.ORGS} mode={EditMode.INSERT} />
                                </OAuth2Gate>
                                )}
                            />
                            <Route
                              exact
                              path="/organisation/:id(\d+)/edit"
                              render={({ match }) => (
                                <OAuth2Gate
                                  anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}
                                  renderError={(err) => (
                                    <OAuth2Gate>
                                      <Redirect to={'/organisation/' + match.params.id} />
                                    </OAuth2Gate>
                                  )}>
                                  <DetailEdit entity={ViewType.ORGS} mode={EditMode.UPDATE} id={Number(match.params.id)} />
                                </OAuth2Gate>
                              )}
                            />
                            <Route
                              exact
                              path={getCreateContextUrl(ViewType.PROJECTS)}
                              render={({ match }) => (<DetailEdit entity={ViewType.PROJECTS} mode={EditMode.INSERT} />)}
                            />
                            <Route
                              exact
                              path="/project/:id(\d+)/edit"
                              render={({ match }) => (
                                <DetailEdit entity={ViewType.PROJECTS} mode={EditMode.UPDATE} id={Number(match.params.id)} />
                              )}
                            />
                            <Route path="/import" render={({ match }) => <Import />} />
                            <Route path="/upload" render={({ match }) => <PubListUpload />} />

                            {/* Publication List Generator */}
                            <Route
                              exact
                              path="/publist/generator"
                              render={({ location }: { location: { state: any } }) => {
                                const config = location.state?.config ?? null;
                                const apiKey = location.state?.apiKey ?? null;
                                return <PubListGenerator config={config} apiKey={apiKey} />;
                              }}
                            />
                            <Route
                              exact
                              path="/publist/documentation"
                              render={() => {
                                return <PubListDoc />;
                              }}
                            />

                            {/* User Admin Area */}
                            <Route
                              exact
                              path="/user-admin"
                              render={() => (
                                <>
                                  <OAuth2Gate
                                    anyRoles={['ROLE_ADMIN']}
                                    renderError={(err) => (
                                      <OAuth2Gate>
                                        <Redirect to="/" />
                                      </OAuth2Gate>
                                    )}
                                  >
                                    <UserAdminArea rowsPerPage={10} pageSizeOptions={[10, 15, 20, 30, 50]} />
                                  </OAuth2Gate>
                                </>
                              )}
                            />

                            {/* Orcid Admin Area */}
                            <Route
                              exact
                              path="/orcid-admin"
                              render={() => (
                                <>
                                  <OAuth2Gate
                                    anyRoles={['ROLE_ADMIN']}
                                    renderError={(err) => (
                                      <OAuth2Gate>
                                        <Redirect to="/" />
                                      </OAuth2Gate>
                                    )}
                                  >
                                    <Subscribe to={[OAuth2Container]}>
                                      {(container: OAuth2Container) => {
                                        return (
                                          <ConnectedOrcidAdminArea userName={container.authorizer().getSession().user_name} />
                                        );
                                      }}
                                    </Subscribe>
                                  </OAuth2Gate>
                                </>
                              )}
                            />

                            {/* User areas */}
                            <Route
                              exact
                              path="/user/profile"
                              render={(props) => (
                                <OAuth2RequireAuth
                                  preAuth={() => sessionStorage.setItem('target_path', props.location.pathname)}
                                >
                                  <Subscribe to={[OAuth2Container]}>
                                    {(container: OAuth2Container) => {
                                      return <ConnectedUserProfile userId={container.authorizer().getSession().user_id} />;
                                    }}
                                  </Subscribe>
                                </OAuth2RequireAuth>
                              )}
                            />
                            <Route
                              exact
                              path="/user/profile/publications"
                              render={(props) => (
                                <OAuth2RequireAuth
                                  preAuth={() => sessionStorage.setItem('target_path', props.location.pathname)}
                                >
                                  <Subscribe to={[OAuth2Container]}>
                                    {(container: OAuth2Container) => {
                                      return <ConnectedUserProfile userId={container.authorizer().getSession().user_id} initialTab={UserProfileTabs.PUBLIST} />;
                                    }}
                                  </Subscribe>
                                </OAuth2RequireAuth>
                              )}
                            />
                            <Route
                              exact
                              path="/user/settings/profile"
                              render={(props) => (
                                <OAuth2RequireAuth
                                  preAuth={() => sessionStorage.setItem('target_path', props.location.pathname)}
                                >
                                  <Subscribe to={[OAuth2Container]}>
                                    {(container: OAuth2Container) => {
                                      return <ConnectedUserSettings activeTab={UserSettingsTabs.PROFILE} userId={container.authorizer().getSession().user_id} />;
                                    }}
                                  </Subscribe>
                                </OAuth2RequireAuth>
                              )}
                            />
                            <Route
                              exact
                              path="/user/settings/orcid"
                              render={(props) => (
                                <OAuth2RequireAuth
                                  preAuth={() => sessionStorage.setItem('target_path', props.location.pathname)}
                                >
                                  <Subscribe to={[OAuth2Container]}>
                                    {(container: OAuth2Container) => {
                                      // TODO Remove bearer unneccessary?
                                      return <ConnectedUserSettings activeTab={UserSettingsTabs.ORCID} userId={container.authorizer().getSession().user_id} />;
                                    }}
                                  </Subscribe>
                                </OAuth2RequireAuth>
                              )}
                            />

                            {/* Authentication */}
                            <Route
                              exact
                              path="/oauth"
                              render={() => (
                                <OAuth2Receiver>
                                  <OAuth2Gate isActive>
                                    <RedirectComputedPath
                                      computePathFunc={() => {
                                        const target_path = sessionStorage.getItem('target_path');
                                        if (target_path) sessionStorage.removeItem('target_path');
                                        return target_path ?? "/";
                                      }}
                                    />
                                  </OAuth2Gate>
                                </OAuth2Receiver>
                              )}
                            />
                            <Route
                              exact
                              path="/logout"
                              render={() => (
                                <OAuth2Logout>
                                  <Redirect to="/" />
                                </OAuth2Logout>
                              )}
                            />

                            {/* Test Error Boundary */}
                            {(process.env.NODE_ENV === 'development') && (
                              <Route
                                exact
                                path="/errorBoundary"
                                render={() => (
                                  <Bomb />
                                )}
                              />
                            )}

                            {/* Error Pages */}
                            <Route
                              render={() => (
                                <ResourceNotFoundError />
                              )}
                            />
                          </Switch>
                        </React.Suspense>
                      </StatusCodeHandler>
                    </AlertProvider>
                  </MathJaxContext>
                </ErrorBoundary>
              </BaseLayout>
            </ErrorPagesProvider>
          </Router>
        </OAuth2Provider>
      </IntlProvider>
    </UnstatedProvider>
  );
};
