import React, { useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Button } from 'theme-ui';
import { ReferencedWorkDTO } from '../../api/core';
import { WorkSuggest } from 'Edit/components/auto-complete/WorkSuggest';
import { MergeServiceApi } from '../../api/duplicate/apis';
import { connectOAuth2, OAuth2AwareProps} from '../../Auth/utils/connect';
import Popup from 'BaseLayout/components/popup/Popup';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface DuplicateProps {
  unicumId: number;
  onSuccess?: () => void;
}

type AwareProps = OAuth2AwareProps<DuplicateProps>;

const InnerDuplicatePopup: React.FC<AwareProps> = ({apiWithAuth, unicumId, onSuccess}) => {

  const [open, setOpen] = useState(false);
  const [duplicates, setDuplicates] = useState<ReferencedWorkDTO[]>([]);
  const intl: IntlShape = useIntl();
  const api = apiWithAuth(new MergeServiceApi());

  let successState = true;

  const mergeDuplicates = async (duplicateIds: number[]): Promise<void> => {
    successState = true;
    await api.mergeDuplicates({ unicumId, requestBody: duplicateIds }).catch(async (e) => {
      let message = "";
      if (e.status && e.status === 404) {
        message = "Entity not found";
      }
      successState = false;
      alert('Could not merge ' + duplicateIds.toString() + "\n" + message);
    });
  };

  const onClose = () => {
    setOpen(false);
    setDuplicates([]);
  };

  return (
    <>
      <Button onClick={() => setOpen(true)}>{intl.formatMessage({id: "work.detail.button.SetAsOriginal"})}</Button>
      <Popup
        open={open}
        title={intl.formatMessage({ id: 'work.detail.duplicatePopup.SetAsOriginal' })}
        onClose={onClose}
        buttons={[]}
        maxWidth='35em'
      >
        <WorkSuggest
          required={false}
          canDelete={true}
          tags={duplicates}
          onChange={(referencedWorks: ReferencedWorkDTO[]): void => setDuplicates(referencedWorks)}
          disabled={false}
          renderId={true}
          name={intl.formatMessage({ id: 'work.detail.duplicatePopup.placeholder.workId' })}
        />
        <Button p={'10px'} mt={'1em'} onClick={onClose}>{intl.formatMessage({ id: 'button.Cancel' })}</Button>
        <Button
          p={'10px'}
          mt={'1em'}
          disabled={duplicates.length < 1}
          onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
            e.preventDefault();
            await mergeDuplicates(duplicates.map((refWork: ReferencedWorkDTO): number => refWork.id));
            setOpen(false);
            setDuplicates([]);
            if (successState && onSuccess) {
              onSuccess();
            }
          }}
        > {intl.formatMessage({ id: 'work.detail.duplicatePopup.button.SetAsOriginal' })}
        </Button>
      </Popup>
    </>
  );
};

export const DuplicatePopup = connectOAuth2(InnerDuplicatePopup);
