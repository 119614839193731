import { LangMessages, Language, languages, Messages } from './utils';

export function getMessages(language: Language) {
  return messages[language];
}

const _messages: Record<string, LangMessages> = {};
languages.forEach((lang) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const m = require(`../../translation/${lang}.json`);
    _messages[lang] = m as LangMessages;
  } catch (e) {}
});

export const messages = _messages as Messages;
