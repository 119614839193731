/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferencedProjectDTO,
    ReferencedProjectDTOFromJSON,
    ReferencedProjectDTOFromJSONTyped,
    ReferencedProjectDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkProjectDTO
 */
export interface WorkProjectDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkProjectDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkProjectDTO
     */
    role?: string;
    /**
     * 
     * @type {ReferencedProjectDTO}
     * @memberof WorkProjectDTO
     */
    project?: ReferencedProjectDTO;
}

export function WorkProjectDTOFromJSON(json: any): WorkProjectDTO {
    return WorkProjectDTOFromJSONTyped(json, false);
}

export function WorkProjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkProjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'project': !exists(json, 'project') ? undefined : ReferencedProjectDTOFromJSON(json['project']),
    };
}

export function WorkProjectDTOToJSON(value?: WorkProjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'role': value.role,
        'project': ReferencedProjectDTOToJSON(value.project),
    };
}


