/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageConfigResponse,
    PageConfigResponseFromJSON,
    PageConfigResponseToJSON,
} from '../models';

export interface SearchConfigsByUserIdRequest {
    searchTerm: string;
    page?: number;
    size?: number;
    nameSortDir?: SearchConfigsByUserIdNameSortDirEnum;
}

/**
 * 
 */
export class ConfigServiceApi extends runtime.BaseAPI {

    /**
     */
    async searchConfigsByUserIdRaw(requestParameters: SearchConfigsByUserIdRequest): Promise<runtime.ApiResponse<PageConfigResponse>> {
        if (requestParameters.searchTerm === null || requestParameters.searchTerm === undefined) {
            throw new runtime.RequiredError('searchTerm','Required parameter requestParameters.searchTerm was null or undefined when calling searchConfigsByUserId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.searchTerm !== undefined) {
            queryParameters['searchTerm'] = requestParameters.searchTerm;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.nameSortDir !== undefined) {
            queryParameters['nameSortDir'] = requestParameters.nameSortDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/config/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageConfigResponseFromJSON(jsonValue));
    }

    /**
     */
    async searchConfigsByUserId(requestParameters: SearchConfigsByUserIdRequest): Promise<PageConfigResponse> {
        const response = await this.searchConfigsByUserIdRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum SearchConfigsByUserIdNameSortDirEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
