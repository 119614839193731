import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FooterMobile } from './FooterMobile';
import { HHUFooterDesktop } from './HHUDesktop';
import { useBreakpointIndex } from '@theme-ui/match-media';

export const HHUFooter = (): React.JSX.Element => {
  const index = useBreakpointIndex({ defaultIndex: 0 });

  return index <= 2 ? (
    <FooterMobile />
  ) : (
    <HHUFooterDesktop />
  );
};
