import React, { ReactElement, ReactNode } from 'react';

export interface Props<T> {
  children?: (
    values: T,
    onChange: (newValues) => void,
    idx: number,
    lines: T[],
    onChangeAll: (newValues: any[], changedValues: any[], originals?: T[]) => void,
    customActions?: { [key: string]: (e: any) => void }
  ) => ReactNode;
  lines: any[];
  onChange?: (lines: T[], changedLines: number[], originals?: T[]) => void;
  defaultLine: any;
}

export function MultiInput<T>({ children, lines, onChange }: Props<T>) {
  lines = lines ? lines : [];
  return (
    <>
      {lines.map((line, idx) => {
        if (line === null || line === undefined) {
          return <></>;
        }
        const onChangeLineCallback = (changedLine: any) => {
          const org = [...lines];
          lines[idx] = changedLine;
          if (onChange) {
            onChange(lines, [idx], org);
          }
        };
        return children(line, onChangeLineCallback, idx, lines, onChange);
      })}
    </>
  );
}
