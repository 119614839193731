/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SolrPerson
 */
export interface SolrPerson {
    /**
     * 
     * @type {string}
     * @memberof SolrPerson
     */
    id: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrPerson
     */
    name?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrPerson
     */
    gnd?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrPerson
     */
    orcid?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrPerson
     */
    organisations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolrPerson
     */
    projects?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof SolrPerson
     */
    recordCreationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SolrPerson
     */
    recordChangeDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SolrPerson
     */
    editorialStatus?: string;
}

export function SolrPersonFromJSON(json: any): SolrPerson {
    return SolrPersonFromJSONTyped(json, false);
}

export function SolrPersonFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolrPerson {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gnd': !exists(json, 'gnd') ? undefined : json['gnd'],
        'orcid': !exists(json, 'orcid') ? undefined : json['orcid'],
        'organisations': !exists(json, 'organisations') ? undefined : json['organisations'],
        'projects': !exists(json, 'projects') ? undefined : json['projects'],
        'recordCreationDate': !exists(json, 'recordCreationDate') ? undefined : (new Date(json['recordCreationDate'])),
        'recordChangeDate': !exists(json, 'recordChangeDate') ? undefined : (new Date(json['recordChangeDate'])),
        'editorialStatus': !exists(json, 'editorialStatus') ? undefined : json['editorialStatus'],
    };
}

export function SolrPersonToJSON(value?: SolrPerson | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'gnd': value.gnd,
        'orcid': value.orcid,
        'organisations': value.organisations,
        'projects': value.projects,
        'recordCreationDate': value.recordCreationDate === undefined ? undefined : (value.recordCreationDate.toISOString()),
        'recordChangeDate': value.recordChangeDate === undefined ? undefined : (value.recordChangeDate.toISOString()),
        'editorialStatus': value.editorialStatus,
    };
}


