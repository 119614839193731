import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { AdminDataWorkDTO, WorkDTO } from '../../../../api/core';
import { DataModelGate } from '../../../../Auth/components';
import { SelectInputP } from '../../../../BaseLayout/components/form/generic/Select';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';

const messages = defineMessages({
  editorialStatus: { id: 'work.attr.adminDataWork.attr.editorialStatus' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  pubtypeGroup: string;
  className?: string;
  validation: ValidationResult;
}

export function EditorialStatus({modifiedModel, entity, updateField, pubtypeGroup, className, validation }: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate entity={entity} field="editorial_status">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <SelectInputP
        listPartition={pubtypeGroup}
        value={modifiedModel.adminDataWork.editorialStatus ? modifiedModel.adminDataWork.editorialStatus : ''}
        onChange={(data: string): void =>
          updateField('adminDataWork', {
            ...modifiedModel.adminDataWork,
            editorialStatus: data,
          })
        }
        name={intl.formatMessage(messages.editorialStatus)}
        inputName="editorialStatus"
        list="editorial_status_list"
        permissions={checkPermission()}
        classes={[className]}
        validation={validation}
      />
    )}
  </DataModelGate>;
}
