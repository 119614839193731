import { InputLabel } from 'BaseLayout/components/form/generic/InputLabel';
import { TagsInput } from 'BaseLayout/components/form/TagsInput';
import React, { ReactElement } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Input } from 'theme-ui';
import { WorkTitleAbbreviationDTO } from '../../../../api/core/models';
import { CheckPermissionFunction } from '../../../../Auth/components/DataModelGate';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';
import { ValidationResult } from '../../../validation/interfaces';

interface Props {
  titleAbbreviations?: WorkTitleAbbreviationDTO[];
  onChange: (titleAbbreviation: WorkTitleAbbreviationDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

export function TitleAbbreviation({ titleAbbreviations, onChange, checkPermission, validation }: Props): ReactElement {
  const abbrevationData: string[] = titleAbbreviations.map((abbr: WorkTitleAbbreviationDTO) => abbr ? abbr.abbreviation : null);
  const intl: IntlShape = useIntl();
  return (
    <div className="col">
      <InputLabel
        classes={["work-title-abbr"]}
        variant="labelY"
        label={intl.formatMessage({ id: "work.attr.titleAbbreviations" })}
        help={intl.formatMessage({ id: "work.attr.titleAbbreviations.help" })}
      />
      {checkPermission().read && (
        <TagsInput
          inputProps={{placeholder: ''}}
          required={isRequired(checkPermission())}
          value={abbrevationData}
          renderInput={inputProps => <Input {...inputProps} placeholder={intl.formatMessage({ id: 'tagsinput.placeholder' })} />}
          disabled={!checkPermission().write}
          validation={validation}
          onChange={(values: string[], elements: string[], pos: number[]): void => {
            onChange(
              values.map(
                (abbreviation: string, idx: number): WorkTitleAbbreviationDTO => (
                  { id: titleAbbreviations[idx] ? titleAbbreviations[idx].id : undefined,
                    abbreviation,
                  }
                )
              )
            );
          }}
        />
      )}
    </div>
  );
}
