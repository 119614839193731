import * as React from 'react';
import { Subscribe, SubscribeProps } from 'unstated';
import { SearchContainer } from './SearchContainer';

import { BaseLayout, Props as BaseLayoutProps } from '../components';
import { CElement } from 'react';

export function ConnectedBaseLayout(props: { children?: any }): CElement<SubscribeProps, Subscribe> {
  const getRenderProps = (container: SearchContainer): BaseLayoutProps & { children?: any } => {
    return {
      searchTerm: container.state.visibleSearchTerm,
      searchContext: container.state.context,
      topBarVisibility: container.state.visible,

      onSearchSubmit: (value: string): void => {
        void container.setVisibleSearchTerm(value);
        void container.setSearchTerm(value);
        container.submitSearch();
      },

      children: props.children,
    };
  };

  // Return the stateless BaseLayout component wrapped in a Subscriber
  return React.createElement(Subscribe, {
    to: [SearchContainer],
    children: (c: SearchContainer): React.ReactNode => {
      // Get the render props using the state container
      const _props = getRenderProps(c);
      return React.createElement(BaseLayout, _props);
    },
  });
}
