import React from 'react';
import { UrlDTO } from '../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from '../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultUrl } from '../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../BaseLayout/components/form/MultiInput/Buttons';
import { Flex } from '@theme-ui/components';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { ValidationResult } from '../../validation/interfaces';

const messages = defineMessages({
  urls: { id: 'work.attr.urls' },
  url: { id: 'work.attr.url.attr.url' },
  urlHelp: { id: 'work.attr.url.attr.url.help' },
  type: { id: 'work.attr.url.attr.type' },
});

interface UrlsProps {
  checkPermission?: CheckPermissionFunction;
  urls: UrlDTO[];
  onChange: (url: UrlDTO[]) => void;
  typelist: string;
  validation?: ValidationResult;
}

// TODO größtenteils dublicated code je typelist / PRÜFEN: kann der Sonderfall für work einfacher abgefangen werden
function InnerUrls({ checkPermission, urls, onChange, typelist, intl, validation }: UrlsProps & WrappedComponentProps) {
  return (
    <div className="form form-highlight">
      {typelist !== 'url_list' && (
        <Border>
          <Styled.h3>{intl.formatMessage(messages.urls)}</Styled.h3>
          <MultiInputControls<UrlDTO>
            remove
            add
            defaultRow={DefaultUrl}
            lines={urls}
            onChange={onChange}
            renderLines={(urlObject: UrlDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number) => {
              return (
                <div className="innerBorder">
                  <div className="fieldRow">
                    <TextInputP
                      permissions={checkPermission('url')}
                      name={intl.formatMessage(messages.url)}
                      value={urlObject.url}
                      onChange={(url: string) => onChange2({ ...urlObject, url })}
                      validation={validation?.children[idx]?.url}
                      autoFocus={true}
                    />
                    <SelectInputP
                      permissions={checkPermission('type')}
                      listPartition={''}
                      onChange={(type: string) => onChange2({ ...urlObject, type })}
                      name={intl.formatMessage(messages.type)}
                      list={typelist}
                      value={urlObject.type}
                      inputName={'type'}
                      validation={validation?.children[idx]?.type}
                    />
                    {!!actions.delete && (
                      <div className="deleteButton">
                        <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
            renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
              <div className="addButton">
                <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
              </div>
            )}
          />
          </Border>
          )
        }

        {typelist === 'url_list' && (
          <div>
            <Styled.h2>{intl.formatMessage(messages.urls)}</Styled.h2>
            <MultiInputControls<UrlDTO>
              remove
              add
              defaultRow={DefaultUrl}
              lines={urls}
              onChange={onChange}
              renderLines={(urlObject: UrlDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number) => {
                return (
                  <div>
                    <Flex variant="layout.multiRow">
                      <TextInputP
                        permissions={checkPermission('url')}
                        name={intl.formatMessage(messages.url)}
                        help={intl.formatMessage(messages.urlHelp)}
                        value={urlObject.url}
                        onChange={(url: string) => onChange2({ ...urlObject, url })}
                        validation={validation?.children[idx]?.url}
                        autoFocus={true}
                      />
                      <SelectInputP
                        permissions={checkPermission('type')}
                        listPartition={''}
                        onChange={(type: string) => onChange2({ ...urlObject, type })}
                        name={intl.formatMessage(messages.type)}
                        list={typelist}
                        value={urlObject.type}
                        inputName={'type'}
                        validation={validation?.children[idx]?.type}
                      />
                    </Flex>
                    {!!actions.delete && (
                      <div className='deleteButton'>
                        <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                      </div>
                    )}
                  </div>
                );
              }}
              renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
                <div className='addButton'>
                  <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
                </div>
              )}
            />
          </div>
        )}
    </div>
  );
}

export const Urls = injectIntl(InnerUrls);
