import React, { ReactElement, useState } from 'react';
import { WorkDTO, WorkGeoLocationDTO, WorkGeoLocationPolygonDTO } from 'api/core/models';
import { GeoLocationPolygon } from './GeoLocationPolygon';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultWorkGeoLocation, getDefaultWorkGeoLocation } from '../../../../data/emptyModels';
import { AddButton, DeleteButton } from 'BaseLayout/components/form/MultiInput/Buttons';
import { Flex, Styled } from 'theme-ui';
import { BorderGeo, FieldRow } from 'Edit/components/theme';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../../validation/interfaces';

const messages = defineMessages({
  id: { id: 'work.attr.geoLocation.attr.id' },
  pointLatitude: { id: 'work.attr.geoLocation.attr.pointLatitude' },
  pointLongitude: { id: 'work.attr.geoLocation.attr.pointLongitude' },
  boxWestBoundLongitude: {
    id: 'work.attr.geoLocation.attr.boxWestBoundLongitude',
  },
  boxEastBoundLongitude: {
    id: 'work.attr.geoLocation.attr.boxEastBoundLongitude',
  },
  boxNorthBoundLatitude: {
    id: 'work.attr.geoLocation.attr.boxNorthBoundLatitude',
  },
  boxSouthBoundLatitude: {
    id: 'work.attr.geoLocation.attr.boxSouthBoundLatitude',
  },
  place: { id: 'work.attr.geoLocation.attr.place' },
});

interface GeoLocationsProps {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: WorkGeoLocationDTO[]) => void;
  entity: string;
  validation: ValidationResult;
}

export function GeoLocations({ modifiedModel, updateField, entity, validation }: GeoLocationsProps) {
  const intl: IntlShape = useIntl();
  const [details, setDetails] = useState(null);
  let counter: number = 0;
  modifiedModel.workGeoLocations = modifiedModel.workGeoLocations ? modifiedModel.workGeoLocations : [];
  return (
    <DataModelGate entity={entity} always field="work_geo_locations">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (<>
          <Styled.h1 sx={{textAlign: "left", mb:'-0.5em'}}>
            <FormattedMessage id="work.attr.geoLocations" />
          </Styled.h1>
          <MultiInputControls<WorkGeoLocationDTO>
            lines={modifiedModel.workGeoLocations}
            remove
            onChange={(lines: WorkGeoLocationDTO[]) => updateField('workGeoLocations', lines)}
            defaultRow={getDefaultWorkGeoLocation()}
            renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
              <div sx={{mt:'-0.5em'}}>
                <AddButton onClick={(e: any): void => {
                  setDetails(modifiedModel.workGeoLocations.length);
                  actionAdd(e);
                }} ><FormattedMessage id="work.attr.geoLocation.add" /></AddButton>
              </div>
            )}
            renderLines={(geo: WorkGeoLocationDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => {
              counter++;
              return (
                <div>
                  <Styled.b sx={{cursor: "pointer"}} onClick={()=>{
                      setDetails(details == idx ? null : idx);
                  }}>
                    <Flex variant="layout.multiRow">
                      <div>{counter}. <FormattedMessage id="work.attr.geoLocation" /></div> {" "}
                      {idx === details && <i sx={{mt: '0.5em', fontSize:'0.85em', "&:before": {color: 'primary'}}} className="icon icon-pfeil_suche_aufgeklappt"></i>}
                      {idx !== details && <i sx={{mt:'0.3em', "&:before": {color: 'primary'}}} className="icon icon-pfeil_suche_zugeklappt"></i>}
                    </Flex>
                  </Styled.b>
                  {idx === details && (
                    <BorderGeo>
                      <React.Fragment>
                        <FieldRow>
                          <TextInputP
                              name={intl.formatMessage(messages.pointLatitude)}
                              value={geo.pointLatitude}
                              onChange={(pointLatitude: string): void => onChange2({ ...geo, pointLatitude })}
                              permissions={checkPermission('point_latitude')}
                              validation={validation?.children[idx]?.point_latitude}
                              autoFocus={true}
                            />
                          <TextInputP
                            name={intl.formatMessage(messages.pointLongitude)}
                            value={geo.pointLongitude}
                            onChange={(pointLongitude: string): void => onChange2({ ...geo, pointLongitude })}
                            permissions={checkPermission('point_longitude')}
                            validation={validation?.children[idx]?.point_longitude}
                          />
                        </FieldRow>
                        <Styled.h2>
                          <FormattedMessage id="work.attr.geoLocation.attr.geoLocationBox" />
                        </Styled.h2>
                        <FieldRow>
                          <TextInputP
                              name={intl.formatMessage(messages.boxWestBoundLongitude)}
                              value={geo.boxWestBoundLongitude}
                              onChange={(boxWestBoundLongitude: string): void =>
                                onChange2({
                                  ...geo,
                                  boxWestBoundLongitude,
                                })
                              }
                              permissions={checkPermission('box_west_bound_longitude')}
                              validation={validation?.children[idx]?.box_west_bound_longitude}
                            />
                            <TextInputP
                              name={intl.formatMessage(messages.boxEastBoundLongitude)}
                              value={geo.boxEastBoundLongitude}
                              onChange={(boxEastBoundLongitude: string): void =>
                                onChange2({
                                  ...geo,
                                  boxEastBoundLongitude,
                                })
                              }
                              permissions={checkPermission('box_east_bound_longitude')}
                              validation={validation?.children[idx]?.box_east_bound_longitude}
                            />
                            <TextInputP
                              name={intl.formatMessage(messages.boxNorthBoundLatitude)}
                              value={geo.boxNorthBoundLatitude}
                              onChange={(boxNorthBoundLatitude: string): void =>
                                onChange2({
                                  ...geo,
                                  boxNorthBoundLatitude,
                                })
                              }
                              permissions={checkPermission('box_north_bound_latitude')}
                              validation={validation?.children[idx]?.box_north_bound_latitude}
                            />
                          <TextInputP
                            name={intl.formatMessage(messages.boxSouthBoundLatitude)}
                            value={geo.boxSouthBoundLatitude}
                            onChange={(boxSouthBoundLatitude: string): void =>
                              onChange2({
                                ...geo,
                                boxSouthBoundLatitude,
                              })
                            }
                            permissions={checkPermission('box_south_bound_latitude')}
                            validation={validation?.children[idx]?.box_south_bound_latitude}
                          />
                        </FieldRow>
                          <TextInputP
                            name={intl.formatMessage({id: "work.attr.geoLocation.attr.place"})}
                            value={geo.place}
                            onChange={(place: string): void => onChange2({ ...geo, place })}
                            permissions={checkPermission('place')}
                            validation={validation?.children[idx]?.place}
                          />
                        <Styled.h2 sx={{mt: 4}}>
                          <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon" />
                        </Styled.h2>
                        <GeoLocationPolygon
                          checkPermission={checkPermission}
                          validation={validation?.children[idx]?.work_geo_location_polygon}
                          geoLocationPolygon={geo.workGeoLocationPolygon ? geo.workGeoLocationPolygon : {}}
                          onChange={(workGeoLocationPolygon: WorkGeoLocationPolygonDTO): void =>
                            onChange2({
                              ...geo,
                              workGeoLocationPolygon,
                            })
                          }
                        />
                      </React.Fragment>
                    </BorderGeo>
                  )}
                {!!actions.delete && (
                <div sx={{mt: '0.5em'}}>
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
                )}
              </div>
            );
            }}
          /></>
        )}
    </DataModelGate>
  );
}
