import {
  PersonDTO,
  EmploymentPeriodDTO,
  OrganisationToPersonDTO,
  ReferencedPersonNameDTO,
} from 'api/core/models';
import { ReadWritePermissions } from 'Auth/components/DataModelGate';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { PersonSuggest } from 'Edit/components/auto-complete/PersonSuggest';
import React, { ReactNode } from 'react';
import { EmploymentPeriods } from '../person/EmploymentPeriods';
import { DefaultOrganisationPerson } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Label, Card } from '@theme-ui/components';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

interface Props {
  checkPermission?: (child?: string) => ReadWritePermissions;
  orgPersons: OrganisationToPersonDTO[];
  onChange: (po: OrganisationToPersonDTO[]) => void;
  validation?: ValidationResult;
}

const messages = defineMessages({
  person: { id: 'edit.organisation.person' },
});

export const OrganisationPersons: React.FC<Props> = ({ checkPermission, orgPersons, onChange, validation }) => {
  const intl: IntlShape = useIntl();
  return (
    <div className="form form-highlight">
      <MultiInputControls<OrganisationToPersonDTO>
        add
        remove
        defaultRow={DefaultOrganisationPerson}
        lines={orgPersons}
        onChange={onChange}
        renderLines={(organisationPerson: OrganisationToPersonDTO, onChange2: (po: OrganisationToPersonDTO) => void, actions: { [p: string]: (e?: any) => void }, idx: number) => (
          <div>
            {checkPermission('person').read && (
              <div className="form">
                <PersonSuggest
                  name={intl.formatMessage(messages.person)}
                  validation={validation?.children[idx]?.person}
                  required={isRequired(checkPermission('person'))}
                  canDelete={false}
                  disabled={!checkPermission('person').write}
                  single
                  tags={!!organisationPerson.person ? [organisationPerson.person].map((orgPerson: PersonDTO): ReferencedPersonNameDTO => ({
                    personId: orgPerson.id,
                    givenname: orgPerson.personNames[0].givenname,
                    surname: orgPerson.personNames[0].surname
                  })) : []}
                  onChange={(ref: ReferencedPersonNameDTO[]) => {
                    onChange2({ ...organisationPerson, person: {
                        id: ref[0].id,
                        personNames: [{
                          personId: ref[0].id,
                          givenname: ref[0].givenname,
                          surname: ref[0].surname,
                        }]
                      }
                    });
                  }}
                  autoFocus={true}
                />
              </div>
            )}
            <Card variant="form">
              {checkPermission('employment_periods').read && (
                <div className="form form-highlight">
                  <Label variant='labelY'>
                    <FormattedMessage id="employment_periods" />:
                  </Label>
                  <EmploymentPeriods
                    validation={validation?.children[idx]?.employment_periods}
                    checkPermission={checkPermission}
                    employmentPeriods={organisationPerson.employmentPeriods}
                    onChange={(employmentPeriods: EmploymentPeriodDTO[]) => {
                      onChange2({ ...organisationPerson, employmentPeriods });
                    }}
                  />
                </div>
              )}
            </Card>
            <div className='deleteButton'>
              <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
            </div>
          </div>
        )}
        renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactNode => (
          <div className='addButton'>
            <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
          </div>
        )}
      />
    </div>
  );
};
