import * as React from 'react';
import { useState } from 'react';
import { WorkDTO } from '../../api/core/models';
import { DetailViewProps } from '../types';
import { OAuth2Gate } from '../../Auth/components';
import { DeleteWork } from '../../Edit/components/DeleteWork';
import { CitationPopup } from '../components/CitationPopup';
import Header from '../components/work/Header';
import DetailEditorAdmin from '../components/work/DetailEditorAdmin';
import DetailGuestUser from '../components/work/DetailGuestUser';
import Toolbar from 'Detail/components/work/Toolbar';
import { useHistory } from "react-router-dom";

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { History } from 'history';

export const DetailViewWorks: React.FC<DetailViewProps> = (props) => {
  const history: History = useHistory();
  const [open, setOpen] = useState(true);
  const [deleteInit, setDeleteInit] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [citePopupOpen, setCitePopupOpen] = useState(false);

  const { id, viewLoading, viewError } = props;
  const view = props.view as WorkDTO;
  const isLoaded = !viewLoading && !viewError && !!view;

  if (viewError) return <>{viewError}</>;

  return (
    <>
    {deleteInit &&
    <DeleteWork
      workId={props.id}
      workTitle={view.title}
      isDeleteRequest={deleteRequest}
      onAbort={(): void => setDeleteInit(false)}
      onSuccess={(): void => {
        setDeleteInit(false);
        history.push("/search/works");
      }}
      onError={(e): void => {
        switch (e.status) {
          case 400:
            alert("Fehler beim Löschen: Es sind weitere Werke mit dieser Entität verknüpft!");
            break;
          default:
            alert("Fehler beim Löschen!");
            break;
        }
        setDeleteInit(false);
      }}
    />
    }
    <div sx={{
      pt: '0%',
      pb: '0%',
      pl: '3%',
      pr: '3%'
    }}>
      {citePopupOpen && <CitationPopup id={id} onClose={() => setCitePopupOpen(false)} />}
      <div className="top" sx={{
        minHeight: '15em',
        mt: '0em',
        mb: '0em',
        ml: 'auto',
        mr: 'auto',
        display: 'flex',
        td: {
          color: '#646464',
          pt: '0.2em',
          pb: '0.2em',
          pl: '0.5em',
          pr: '0.5em'
        },
        th: {
          color: '#1B1B1B',
          textAlign: 'left',
          pt: '0.2em',
          pb: '0.2em',
          pl: '0.5em',
          pr: '0.5em'
        }
      }}>
        {isLoaded &&
        <article sx={{pt: '3em', pb: '3em', pl: '0em', pr: '0em', flexGrow: 1}}>
          <Header work={view} />
          <Toolbar
            work={view}
            open
            citePopupOpen={citePopupOpen}
            setOpen={setOpen}
            setCitePopupOpen={setCitePopupOpen}
            setDeleteInit={setDeleteInit}
            setDeleteRequest={setDeleteRequest}
          />
          {open &&
          <>
            {/* Detailview for Editor/Admin */}
            <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
              <DetailEditorAdmin work={view} />
            </OAuth2Gate>

            {/* Detailview for Guest/User */}
            <OAuth2Gate anyRoles={['ROLE_GUEST', 'ROLE_USER']}>
              <DetailGuestUser work={view} />
            </OAuth2Gate>
          </>}
        </article>}
      </div>
    </div>
    </>
  );
};

export default DetailViewWorks;
