import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { WorkLanguages } from './components/fields/work/WorkLanguages';
import { WorkOtherTitles } from './components/fields/work/WorkOtherTitles';
import { WorkDateTypes } from './components/fields/work/WorkDateTypes';
import { WorkIssued } from './components/fields/work/WorkIssued';
import { Medium } from './components/fields/work/WorkMedium';
import { TextAreaP } from 'BaseLayout/components/form/generic/Textarea';
import { WorkPersons } from './components/fields/work/WorkPersons/components';
import { WorkOrganisations } from './components/fields/work/WorkOrganisations';
import { WorkProjects } from './components/fields/work/WorkProjects';
import { Urls } from './components/fields/Urls';
import { BorderedExternalIds } from './components/fields/ExternalIds';
import { WorkSubjects } from './components/fields/work/WorkSubject';
import { Keywords } from './components/fields/work/WorkKeywords';
import { Format } from './components/fields/work/WorkFormat';
import { RelatedIdentifiersInternal } from './components/fields/work/WorkRelatedIdentifiersInternal';
import { RelatedIdentifiersExternal } from './components/fields/work/WorkRelatedIdentifiersExternal';
import { WorkIsPartOfComponent } from './components/fields/work/WorkParts/WorkIsPartOf';
import { WorkIsPartOfOtherComponent } from './components/fields/work/WorkParts/WorkIsPartOfOther';
import { WorkPublishers } from './components/fields/work/workPublisher/WorkPublishers';
import { CheckboxP } from 'BaseLayout/components/form/generic/Checkbox';
import { Place } from './components/fields/work/Place';
import { GeoLocations } from './components/fields/work/GeoLocation';
import { WorkOrcidSyncs } from './components/fields/work/WorkOrcidSync';
import { Funds } from './components/fields/work/Funds';
import { HasPart } from './components/fields/work/WorkParts/HasPart';
import { TitleAbbreviation } from './components/fields/work/TitleAbbreviation';
import {
  AdminDataWorkOrcidSyncDTO,
  WorkDTO,
  SubjectDTO,
  UrlDTO,
  WorkIsPartOfDTO as WorkIsPartOf,
  WorkIsPartOfOtherDTO,
  WorkKeywordDTO,
  WorkLanguageDTO,
  WorkOrganisationOtherDTO,
  WorkOtherTitleDTO,
  WorkPlaceDTO,
  WorkRelatedIdentifierDTO,
  WorkRelatedIdentifierInternalDTO,
  WorkTitleAbbreviationDTO,
  WorkOrganisationDTO,
  WorkPublisherDTO,
} from 'api/core/models';
import React, { ReactElement } from 'react';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, IntlShape } from 'react-intl';
import { DataModelGate } from 'Auth/components';
import { WorkProjectOthers } from './components/fields/work/WorkProjectOthers';
import { AccessLevel } from './components/fields/work/openAccess/AccessLevel';
import { ApplicationCountry } from './components/fields/work/ApplicationCountry';
import { ApplicationNumber } from './components/fields/work/ApplicationNumber';
import { ArticleProcessingCharge } from './components/fields/work/openAccess/ArticleProcessingCharge';
import { BelongToProjects } from './components/fields/work/BelongToProjects';
import { BelongToOrganisations } from './components/fields/work/BelongToOrganisations';
import { DataVersion } from './components/fields/work/DataVersion';
import { DateChanged } from './components/fields/work/DateChanged';
import { DateCreated } from './components/fields/work/DateCreated';
import { Deskman } from './components/fields/work/Deskman';
import { Edition } from './components/fields/work/Edition';
import { EditorialStatus } from './components/fields/work/EditorialStatus';
import { Descriptions } from './components/fields/work/WorkDescriptions';
import { Events } from './components/fields/work/Events';
import { FormSubject } from './components/fields/work/FormSubject';
import { PubtypeServiceApi } from '../api/pubtype';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Validation } from './validation/interfaces';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { WritableParam } from '../Auth/components/DataModelGate';
import { ReadWritePermissions } from '../types';

export const pubtypeGroupToEntityName = (pubtypeGroup: string) =>
  `work_${pubtypeGroup.toLowerCase().replaceAll('_', '-')}`;

const messages = defineMessages({
  grantNumber: { id: 'work.attr.grantNumber' },
  hidden: { id: 'work.attr.adminDataWork.attr.hidden' },
  id: { id: 'work.attr.adminDataWork.attr.id' },
  issued: { id: 'work.attr.issued' },
  license: { id: 'work.attr.license' },
  licenseManagementStatus: { id: 'work.attr.licenseManagementStatus' },
  licenseManagementStatusHelp: { id: 'work.attr.licenseManagementStatus.help' },
  licenseText: { id: 'work.attr.licenseText' },
  licenseTextHelp: { id: 'work.attr.licenseText.help' },
  note: { id: 'work.attr.note' },
  number: { id: 'work.attr.number' },
  numberHelp: { id: 'work.attr.number.help' },
  numberOfPages: { id: 'work.attr.numberOfPages' },
  numberOfPagesPlaceholder: { id: 'work.attr.numberOfPages.placeholder' },
  numberOfVolumes: { id: 'work.attr.numberOfVolumes' },
  obligatoryDelivery: { id: 'work.attr.adminDataWork.attr.obligatoryDelivery' },
  obligatoryDeliveryHelp: { id: 'work.attr.adminDataWork.attr.obligatoryDelivery.help' },
  origin: { id: 'work.attr.adminDataWork.attr.origin' },
  patentId: { id: 'work.attr.patentId' },
  patentFamily: { id: 'work.attr.patentFamily' },
  patentType: { id: 'work.attr.patentType' },
  peerReviewed: { id: 'work.attr.peerReviewed' },
  priorityNumber: { id: 'work.attr.priorityNumber' },
  priorityPatentApplication: { id: 'work.attr.priorityPatentApplication' },
  priorityCountry: { id: 'work.attr.priorityCountry' },
  publicationNumber: { id: 'work.attr.publicationNumber' },
  publicationStatus: { id: 'work.attr.publicationStatus' },
  pubtype: { id: 'work.attr.pubtype' },
  resourceType: { id: 'work.attr.resourceType' },
  size: { id: 'work.attr.size' },
  sizeHelp: { id: 'work.attr.size.help' },
  subseriesTitle: { id: 'work.attr.subseriesTitle.attr.title' },
  subtitle: { id: 'work.attr.subtitle' },
  subtitleHelp: { id: 'work.attr.subtitle.help' },
  title: { id: 'work.attr.title' },
  titleHelp: { id: 'work.attr.title.help' },
  titleSupplement: { id: 'work.attr.titleSupplement' },
  titleSupplementHelp: { id: 'work.attr.titleSupplement.help' },
  version: { id: 'work.attr.version' },
});

export function getWorkComponent(
  api: PubtypeServiceApi,
  key: string,
  modifiedModel: WorkDTO,
  pubtypeGroup: string,
  intl: IntlShape,
  updateField: (field: keyof WorkDTO, value: any) => void,
  validation: Validation
): ReactElement {
  const entity: string = pubtypeGroupToEntityName(pubtypeGroup);
  const adminData: string = 'work_admin_data';
  const generic: string = 'work_generic';
  const {
    adminDataWork,
    grantNumber,
    issued,
    medium,
    note,
    number,
    numberOfPages,
    numberOfVolumes,
    patentFamily,
    patentId,
    patentType,
    peerReviewed,
    priorityCountry,
    priorityNumber,
    priorityPatentApplication,
    publicationNumber,
    publicationStatus,
    resourceType,
    size,
    subseriesTitle,
    subtitle,
    title,
    titleSupplement,
    urls,
    version,
    workOrganisationOthers,
    workOrganisations,
    workIsPartOfOthers,
    workIsPartOfs,
    workKeywords,
    workLanguages,
    workOtherTitles,
    workPersonOthers,
    workPersons,
    workPlaces,
    workPublishers,
    externalRelatedIdentifiers,
    internalRelatedIdentifiersSource,
    internalRelatedIdentifiersTarget,
    workSubjects,
    workTitleAbbreviations,
  } = modifiedModel;
  switch (key) {
    case 'ACCESS_LEVEL':
      return <AccessLevel modifiedModel={modifiedModel}
                          updateField={updateField}
                          entity={adminData}
                          pubtypeGroup={pubtypeGroup}
                          validation={validation?.admin?.admin_data_work_open_access}/>;
    case 'APPLICATION_COUNTRY':
      return <ApplicationCountry modifiedModel={modifiedModel}
                                updateField={updateField}
                                entity={entity}
                                pubtypeGroup={pubtypeGroup}
                                validation={validation?.root?.application_country}/>;
    case 'APPLICATION_NUMBER':
      return <ApplicationNumber modifiedModel={modifiedModel}
                                updateField={updateField}
                                entity={entity}
                                validation={validation?.root?.application_number}/>;
    case 'ARTICLE_PROCESSING_CHARGE':
      return <ArticleProcessingCharge modifiedModel={modifiedModel}
                                      updateField={updateField}
                                      entity={adminData}
                                      validation={validation?.admin?.admin_data_work_open_access}/>;
    case 'BELONG_TO_PROJECT':
      return <BelongToProjects modifiedModel={modifiedModel}
                              updateField={updateField}
                              entity={adminData}
                              validation={validation?.admin?.projects}
                              validation2={validation?.admin?.projects_checked}/>;
    case 'BELONG_TO_ORGANISATION':
      return <BelongToOrganisations modifiedModel={modifiedModel}
                                    updateField={updateField}
                                    entity={adminData}
                                    validation={validation?.admin?.organisations}/>;
    case 'DATAVERSION':
      return <DataVersion modifiedModel={modifiedModel}
                          updateField={updateField}
                          entity={entity}
                          validation={validation?.root?.data_version}/>;
    case 'DATE':
      return <WorkDateTypes modifiedModel={modifiedModel}
                            updateField={updateField}
                            pubtypeGroup={pubtypeGroup}
                            entity={generic}
                            validation={validation?.root?.work_date_types}/>;
    case 'DATE_CHANGED':
      return <div sx={{ gridColumn: "span 1" }}><DateChanged modifiedModel={modifiedModel}
                          updateField={updateField}
                          entity={adminData}
                          validation={validation?.admin?.date_changed}/></div>;
    case 'DATE_CREATED':
      return <div sx={{ gridColumn: "span 1" }}><DateCreated modifiedModel={modifiedModel}
                  updateField={updateField} entity={adminData} validation={validation?.admin?.date_created}/></div>;
    case 'DESCRIPTION':
      return <Descriptions modifiedModel={modifiedModel}
                           updateField={updateField}
                           entity={generic}
                           pubtypeGroup={pubtypeGroup}
                           validation={validation?.root?.work_descriptions}/>;
    case 'DESKMAN':
      return <Deskman modifiedModel={modifiedModel}
                      updateField={updateField}
                      entity={adminData}
                      validation={validation?.admin?.deskman}/>;
    case 'EDITION':
      return <Edition modifiedModel={modifiedModel}
                      updateField={updateField}
                      entity={entity}
                      validation={validation?.root?.edition}/>;
    case 'EDITORIAL_STATUS':
      return <EditorialStatus modifiedModel={modifiedModel}
                              updateField={updateField}
                              entity={adminData}
                              pubtypeGroup={pubtypeGroup}
                              validation={validation?.admin?.editorial_status}/>;
    case 'EVENT_NAME' /* TODO JUST EVENT? */:
      return <Events modifiedModel={modifiedModel}
                     updateField={updateField}
                     entity={entity}
                     validation={validation?.root?.events}/>;
    case 'EXTERNAL_ID':
      return <BorderedExternalIds modifiedModel={modifiedModel}
                          updateField={updateField}
                          entity={generic}
                          pubtypeGroup={pubtypeGroup}
                          list={'external_id_list'}
                          validation={validation?.root?.external_ids}/>;
    case 'FORM_SUBJECT':
      return <FormSubject modifiedModel={modifiedModel}
                          updateField={updateField}
                          entity={entity}
                          pubtypeGroup={pubtypeGroup}
                          validation={validation?.root?.form_subject}/>;
    case 'FORMAT':
      return <Format modifiedModel={modifiedModel}
                     updateField={updateField}
                     entity={generic}
                     pubtypeGroup={pubtypeGroup}
                     validation={validation?.root?.format}/>;

    case 'FUNDED':
      return <Funds modifiedModel={modifiedModel}
                    updateField={updateField}
                    entity={adminData}
                    pubtypeGroup={pubtypeGroup}
                    validation={validation?.admin?.admin_data_work_open_access}/>;
    case 'GEO_LOCATION':
      return <GeoLocations modifiedModel={modifiedModel}
                           updateField={updateField}
                           entity={entity}
                           validation={validation?.root?.work_geo_locations}/>;
    case 'PROJECT':
      return <WorkProjects
                         modifiedModel={modifiedModel}
                         updateField={updateField}
                         entity={generic}
                         pubtypeGroup={pubtypeGroup}
                         validation={validation?.root?.work_projects}/>;
    case 'PROJECT_OTHER':
      return <WorkProjectOthers modifiedModel={modifiedModel}
                              updateField={updateField}
                              entity={generic}
                              pubtypeGroup={pubtypeGroup}
                              validation={validation?.root?.work_project_others}/>;
    case 'GRANT_NUMBER':
      return (
        <DataModelGate entity={entity} field="grant_number">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              name={intl.formatMessage(messages.grantNumber)}
              inputName="grantNumber"
              value={grantNumber ? grantNumber : ''}
              onChange={(gn: string): void => updateField('grantNumber', gn)}
              permissions={checkPermission()}
              validation={validation?.root?.grant_number}
            />
          )}
        </DataModelGate>
      );
    case 'HAS_PART':
      return <HasPart api={api}
                      modifiedModel={modifiedModel}
                      updateField={updateField}
                      entity={entity}
                      pubtype={modifiedModel.pubtype}
                      validation={validation?.root?.work_has_parts}
      />;
    case 'HIDDEN':
      return (
        // @TODO: pass in `new` and `emails` props to handle owner permissions, use different values of `writable`
        <DataModelGate entity={adminData} field="hidden">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <CheckboxP
              checked={adminDataWork.hidden ? adminDataWork.hidden : false}
              inputName="hidden"
              name={intl.formatMessage(messages.hidden)}
              onChange={(hidden: boolean): void => updateField('adminDataWork', { ...adminDataWork, hidden })}
              permissions={checkPermission()}
              validation={validation?.admin?.hidden}
            />
          )}
        </DataModelGate>
      );
    case 'ID':
      return (
        <DataModelGate entity={generic} field="id">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions) => (
            <TextInputP
              value={adminDataWork.id ? String(adminDataWork.id) : ''}
              inputName={'id'}
              permissions={checkPermission()}
              disabled={true}
              onChange={(id: string): void => {
                console.error('ID Change not possible', id);
                // updateField('id', parseInt(id, 10));
              }}
              name={intl.formatMessage(messages.id)}
              validation={validation?.admin?.id}
            />
          )}
        </DataModelGate>
      );
    case 'IS_PART_OF':
      return (
        <DataModelGate entity={entity} field="work_is_part_ofs">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <WorkIsPartOfComponent
              api={api}
              checkPermission={checkPermission}
              onChange={(ipo: WorkIsPartOf[]): void => updateField('workIsPartOfs', ipo)}
              isPartOf={workIsPartOfs ? workIsPartOfs : []}
              pubtype={modifiedModel.pubtype}
              validation={validation?.root?.work_is_part_ofs}
            />
          )}
        </DataModelGate>
      );
    case 'IS_PART_OF_OTHER':
      return (
        <DataModelGate entity={entity} field="work_is_part_of_others">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <WorkIsPartOfOtherComponent
              onChange={(ipoo: WorkIsPartOfOtherDTO[]): void => updateField('workIsPartOfOthers', ipoo)}
              isPartOf={workIsPartOfOthers ? workIsPartOfOthers : []}
              checkPermission={checkPermission}
              validation={validation?.root?.work_is_part_of_others}
            />
          )}
        </DataModelGate>
      );
    case 'ISSUED':
      return (
        <DataModelGate entity={entity} field="issued">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <WorkIssued
              value={issued ? issued : null}
              inputName="issued"
              onChange={(data: number): void => updateField('issued', data)}
              checkPermission={checkPermission}
              validation={validation?.root?.issued}
            />
          )}
        </DataModelGate>
      );
    case 'KEYWORD':
      return (
        <DataModelGate entity={generic} field="work_keywords">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <Keywords
              checkPermission={checkPermission}
              workKeywords={workKeywords ? workKeywords : []}
              onChange={(data: WorkKeywordDTO[]): void => updateField('workKeywords', data)}
              validation={validation?.root?.work_keywords}
            />
          )}
        </DataModelGate>
      );
    case 'LANGUAGE':
      return (
        <DataModelGate entity={generic} field="work_languages">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <Subscribe to={[OAuth2Container]}>
              {(c: OAuth2Container) => {
                const innerProps = { authorizer: c.authorizer()};
                return <WorkLanguages
                  innerProps={innerProps}
                  checkPermission={checkPermission}
                  listPartition={pubtypeGroup}
                  workLanguages={workLanguages ? workLanguages : []}
                  onChange={(wls: WorkLanguageDTO[]): void => updateField('workLanguages', wls)}
                  validation={validation?.root?.work_languages}
                />;
              }}
            </Subscribe>
          )}
        </DataModelGate>
      );
    case 'LICENSE':
      return (
        <DataModelGate entity={adminData} field="license">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <SelectInputP
              listPartition={pubtypeGroup}
              value={adminDataWork.license ? adminDataWork.license : ''}
              onChange={(license: string): void => updateField('adminDataWork', { ...adminDataWork, license })}
              name={intl.formatMessage(messages.license)}
              list="license_list"
              listSorted={true}
              permissions={checkPermission()}
              validation={validation?.admin?.license}
            />
          )}
        </DataModelGate>
      );
    case 'LICENSE_MANAGEMENT_STATUS':
      return (
        <DataModelGate entity={adminData} field="license_management_status">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <SelectInputP
              listPartition={pubtypeGroup}
              value={adminDataWork.licenseManagementStatus ? adminDataWork.licenseManagementStatus : ''}
              inputName="licenseManagementStatus"
              name={intl.formatMessage(messages.licenseManagementStatus)}
              help={intl.formatMessage(messages.licenseManagementStatusHelp)}
              onChange={(licenseManagementStatus: string): void => updateField('adminDataWork', { ...adminDataWork, licenseManagementStatus })}
              list="license_management_status_list"
              permissions={checkPermission()}
              validation={validation?.admin?.license_management_status}
            />
          )}
        </DataModelGate>
      );
    case 'LICENSE_TEXT':
      return (
        <DataModelGate entity={adminData} field="license_text">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextAreaP
              value={adminDataWork.licenseText ? adminDataWork.licenseText : ''}
              onChange={(licenseText: string): void => updateField('adminDataWork', { ...adminDataWork, licenseText })}
              inputName={'workLicenceText'}
              name={intl.formatMessage(messages.licenseText)}
              help={intl.formatMessage(messages.licenseTextHelp)}
              permissions={checkPermission()}
              validation={validation?.admin?.licence_text}
            />
          )}
        </DataModelGate>
      );
    case 'MEDIUM':
      return (
        <DataModelGate entity={generic} field="medium">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <Medium
              listPartition={pubtypeGroup}
              medium={medium ? medium : ''}
              onChange={(data: string): void => updateField('medium', data)}
              checkPermission={checkPermission}
              validation={validation?.root?.medium}
            />
          )}
        </DataModelGate>
      );
    case 'NOTE':
      return (
        <DataModelGate entity={generic} field="note">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextAreaP
              value={note ? note : ''}
              onChange={(data: string): void => updateField('note', data)}
              inputName={'workNote'}
              name={intl.formatMessage(messages.note)}
              permissions={checkPermission()}
              validation={validation?.root?.note}
            />
          )}
        </DataModelGate>
      );
    case 'NUMBER':
      return (
        <DataModelGate entity={entity} field="number">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              value={number ? number : ''}
              inputName="number"
              name={intl.formatMessage(messages.number)}
              help={intl.formatMessage(messages.numberHelp)}
              onChange={(no: string): void => updateField('number', no)}
              permissions={checkPermission()}
              validation={validation?.root?.number}
            />
          )}
        </DataModelGate>
      );
    case 'NUMBER_OF_PAGES':
      return (
        <DataModelGate entity={entity} field="number_of_pages">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              value={numberOfPages ? numberOfPages : ''}
              inputName="numberOfPages"
              name={intl.formatMessage(messages.numberOfPages)}
              placeholder={intl.formatMessage(messages.numberOfPagesPlaceholder)}
              onChange={(nop: string): void => updateField('numberOfPages', nop)}
              permissions={checkPermission()}
              validation={validation?.root?.number_of_pages}
            />
          )}
        </DataModelGate>
      );
    case 'NUMBER_OF_VOLUMES':
      return (
        <DataModelGate entity={entity} field="number_of_volumes">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              value={numberOfVolumes ? numberOfVolumes : ''}
              inputName="numberOfVolumes"
              name={intl.formatMessage(messages.numberOfVolumes)}
              onChange={(nov: string): void => updateField('numberOfVolumes', nov)}
              permissions={checkPermission()}
              validation={validation?.root?.number_of_volumes}
            />
          )}
        </DataModelGate>
      );
    case 'OBLIGATORY_DELIVERY':
      return (
        <DataModelGate entity={adminData} field="obligatory_delivery">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <CheckboxP
              checked={adminDataWork.obligatoryDelivery ? adminDataWork.obligatoryDelivery : false}
              inputName="obligatoryDelivery"
              name={intl.formatMessage(messages.obligatoryDelivery)}
              help={intl.formatMessage(messages.obligatoryDeliveryHelp)}
              onChange={(data: boolean): void =>
                updateField('adminDataWork', {
                  ...adminDataWork,
                  obligatoryDelivery: data,
                })
              }
              permissions={checkPermission()}
              validation={validation?.admin?.obligatory_delivery}
            />
          )}
        </DataModelGate>
      );
    case 'ORCID_SYNC':
      return (
        <DataModelGate entity={adminData} field="admin_data_work_orcid_syncs">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <WorkOrcidSyncs
              orcidSyncs={adminDataWork.adminDataWorkOrcidSyncs ? adminDataWork.adminDataWorkOrcidSyncs : []}
              onChange={(data: AdminDataWorkOrcidSyncDTO[]): void =>
                updateField('adminDataWork', {
                  ...adminDataWork,
                  adminDataWorkOrcidSyncs: data,
                })
              }
              checkPermission={checkPermission}
              validation={validation?.admin?.admin_data_work_orcid_syncs}
            />
          )}
        </DataModelGate>
      );
    case 'ORGANISATION':
      return (
        <DataModelGate entity={generic} field="work_organisations">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <DataModelGate entity={generic} field="work_organisation_others">
              {(writableOther, readableOther, checkPermissionOthers) => (
                <WorkOrganisations
                  listPartition={pubtypeGroup}
                  workOrganisations={workOrganisations ? workOrganisations : []}
                  workOrganisationOthers={workOrganisationOthers ? workOrganisationOthers : []}
                  onChangeWo={(data: WorkOrganisationDTO[]): void => updateField('workOrganisations', data)}
                  onChangeWoo={(data: WorkOrganisationOtherDTO[]): void => updateField('workOrganisationOthers', data)}
                  checkPermission={checkPermission}
                  checkPermissionOthers={checkPermissionOthers}
                  validations={{work_organisations: validation.root.work_organisations, work_organisation_others: validation.root.work_organisation_others}}
                />
              )}
            </DataModelGate>
          )}
        </DataModelGate>
      );
    case 'ORGANISATION_OTHER':
      return <React.Fragment></React.Fragment>;
    case 'ORIGIN':
      return (
        <DataModelGate entity={adminData} field="origin">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              name={intl.formatMessage(messages.origin)}
              value={adminDataWork.origin ? adminDataWork.origin : ''}
              inputName="origin"
              inputProps={{ disabled: true }}
              onChange={(data: string): void => updateField('adminDataWork', { ...adminDataWork, origin: data })}
              permissions={checkPermission()}
              validation={validation?.admin?.origin}
            />
          )}
        </DataModelGate>
      );
    case 'OTHER_TITLE':
      return (
        <DataModelGate entity={generic} field="work_other_titles">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <WorkOtherTitles
              listPartition={pubtypeGroup}
              workOtherTitles={workOtherTitles ? workOtherTitles : []}
              onChange={(data: WorkOtherTitleDTO[]): void => updateField('workOtherTitles', data)}
              checkPermission={checkPermission}
              validation={validation?.root?.work_other_titles}
            />
          )}
        </DataModelGate>
      );
    case 'PATENT_ID':
      return (
        <React.Fragment>
          <DataModelGate entity={entity} field="patent_id">
            {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
              <TextInputP
                name={intl.formatMessage(messages.patentId)}
                inputName="patentId"
                value={patentId ? patentId : ''}
                onChange={(pid: string): void => updateField('patentId', pid)}
                permissions={checkPermission()}
                validation={validation?.root?.patent_id}
              />
            )}
          </DataModelGate>
          <DataModelGate entity={entity} field="patent_type">
            {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
              <SelectInputP
                listPartition={pubtypeGroup}
                inputName="patentType"
                name={intl.formatMessage(messages.patentType)}
                list={'affiliations_list'}
                value={patentType ? patentType : ''}
                onChange={(pt: string): void => updateField('patentType', pt)}
                permissions={checkPermission()}
                validation={validation?.root?.patent_type}
              />
            )}
          </DataModelGate>
        </React.Fragment>
      );
    case 'PATENT_FAMILY':
      return (
        <DataModelGate entity={entity} field="patent_family">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              name={intl.formatMessage(messages.patentFamily)}
              inputName="patentFamily"
              value={patentFamily ? patentFamily : ''}
              onChange={(pf: string): void => updateField('patentFamily', pf)}
              permissions={checkPermission()}
              validation={validation?.root?.patent_family}
            />
          )}
        </DataModelGate>
      );
    case 'PEER_REVIEWED':
      return (
        <DataModelGate entity={entity} field="peer_reviewed">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <SelectInputP
              listPartition={pubtypeGroup}
              inputName="peerReviewed"
              name={intl.formatMessage(messages.peerReviewed)}
              list={'peer_review_status_list'}
              value={peerReviewed ? peerReviewed : 'no_value'}
              onChange={(pr: string): void => updateField('peerReviewed', pr)}
              permissions={checkPermission()}
              validation={validation?.root?.peerReviewed}
              skipEmptyValue={true}
            />
          )}
        </DataModelGate>
      );
    case 'PERSON':
      return (
        <DataModelGate always entity={generic} field="work_persons">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <DataModelGate always entity={generic} field="work_person_others">
              {(writableOther, readableOther, checkPermissionOther) => (
                <WorkPersons
                  workPersons={workPersons ? workPersons : []}
                  workPersonOthers={workPersonOthers ? workPersonOthers : []}
                  onChangePerson={(wp) => {
                    updateField("workPersons", wp);
                  }}
                  onChangePersonOther={(wpo) => {
                    updateField("workPersonOthers", wpo);
                  }}
                  checkPermission={checkPermission}
                  checkPermissionOther={checkPermissionOther}
                  validations={{work_persons: validation.root.work_persons, work_person_others: validation.root.work_person_others}}
                />
              )}
            </DataModelGate>
          )}
        </DataModelGate>
      );
    case 'PERSON_OTHER':
      return <React.Fragment></React.Fragment>;
    case 'PLACE':
      return (
        <DataModelGate entity={entity} field="work_places">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <Place
              workPlaces={workPlaces ? workPlaces : []}
              onChange={(p: WorkPlaceDTO[]): void => updateField('workPlaces', p)}
              checkPermission={checkPermission}
              validation={validation?.root?.work_places}
            />
          )}
        </DataModelGate>
      );
    case 'PRIORITY_NUMBER':
      return (
        <DataModelGate entity={entity} field="priority_number">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              name={intl.formatMessage(messages.priorityNumber)}
              inputName="priorityNumber"
              value={priorityNumber ? priorityNumber : ''}
              onChange={(pn: string): void => updateField('priorityNumber', pn)}
              permissions={checkPermission()}
              validation={validation?.root?.priority_number}
            />
          )}
        </DataModelGate>
      );
    case 'PRIORITY_PATENT_APPLICATION':
      return (
        <DataModelGate entity={entity} field="priority_patent_application">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <CheckboxP
              name={intl.formatMessage(messages.priorityPatentApplication)}
              inputName="priorityPatentApplication"
              checked={priorityPatentApplication ? priorityPatentApplication : false}
              onChange={(ppa: boolean): void => updateField('priorityPatentApplication', ppa)}
              permissions={checkPermission()}
              validation={validation?.root?.priority_patent_application}
            />
          )}
        </DataModelGate>
      );
    case 'PRIORITY_COUNTRY':
      return (
        <DataModelGate entity={entity} field="priority_country">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <SelectInputP
              listPartition={pubtypeGroup}
              inputName="priorityCountry"
              name={intl.formatMessage(messages.priorityCountry)}
              list={'country_list'}
              value={priorityCountry ? priorityCountry : ''}
              onChange={(pc: string): void => updateField('priorityCountry', pc)}
              permissions={checkPermission()}
              validation={validation?.root?.priority_country}
            />
          )}
        </DataModelGate>
      );
    case 'PUBLICATION_NUMBER':
      return (
        <DataModelGate entity={entity} field="publication_number">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              name={intl.formatMessage(messages.publicationNumber)}
              inputName="publicationNumber"
              value={publicationNumber ? publicationNumber : ''}
              onChange={(pn: string): void => updateField('publicationNumber', pn)}
              permissions={checkPermission()}
              validation={validation?.root?.publication_number}
            />
          )}
        </DataModelGate>
      );
    case 'PUBLICATION_STATUS':
      return (
        <DataModelGate entity={generic} field="publication_status">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <div sx={{ gridColumn: "span 1" }}>
              <SelectInputP
                listPartition={pubtypeGroup}
                value={publicationStatus ? publicationStatus : ''}
                onChange={(data: string): void => updateField('publicationStatus', data)}
                name={intl.formatMessage(messages.publicationStatus)}
                list={'publication_status_list'}
                permissions={checkPermission()}
                validation={validation?.root?.publication_status}
              />
            </div>
          )}
        </DataModelGate>
      );
    case 'PUBLISHER':
      return (
        <DataModelGate entity={entity} field="work_publishers">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <Subscribe to={[OAuth2Container]}>
              {(c: OAuth2Container) => {
                const innerProps = { authorizer: c.authorizer()};
                return <WorkPublishers
                  innerProps={innerProps}
                  workPublishers={workPublishers ? workPublishers : []}
                  onChange={(p: WorkPublisherDTO[]) => updateField("workPublishers", p)}
                  checkPermission={checkPermission}
                  validation={validation?.root?.work_publishers}
                />;
              }}
            </Subscribe>
          )}
        </DataModelGate>
      );
    case 'RELATED_IDENTIFIER':
      return (
        <React.Fragment>
          <DataModelGate entity={generic} field="internal_related_identifiers_source">
            {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
              <RelatedIdentifiersInternal
                listPartition={pubtypeGroup}
                internals={internalRelatedIdentifiersSource ? internalRelatedIdentifiersSource : []}
                onChange={(data: WorkRelatedIdentifierInternalDTO[]): void =>
                  updateField('internalRelatedIdentifiersSource', data)
                }
                checkPermission={checkPermission}
                validation={validation?.root?.internal_related_identifiers_source}
                source={true}
              />
            )}
          </DataModelGate>
          <DataModelGate entity={generic} field="internal_related_identifiers_target">
            {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
              <RelatedIdentifiersInternal
                listPartition={pubtypeGroup}
                internals={internalRelatedIdentifiersTarget ? internalRelatedIdentifiersTarget : []}
                onChange={(data: WorkRelatedIdentifierInternalDTO[]): void =>
                  updateField('internalRelatedIdentifiersTarget', data)
                }
                checkPermission={checkPermission}
                validation={validation?.root?.internal_related_identifiers_target}
                source={false}
              />
            )}
          </DataModelGate>
          <DataModelGate entity={generic} field="external_related_identifiers">
            {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
              <RelatedIdentifiersExternal
                listPartition={pubtypeGroup}
                externals={externalRelatedIdentifiers ? externalRelatedIdentifiers : []}
                onChange={(data: WorkRelatedIdentifierDTO[]): void => updateField('externalRelatedIdentifiers', data)}
                checkPermission={checkPermission}
                validation={validation?.root?.external_related_identifiers}
              />
            )}
          </DataModelGate>
        </React.Fragment>
      );
    case 'RESOURCE_TYPE':
      return (
        <DataModelGate entity={entity} field="resource_type">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <SelectInputP
              listPartition={pubtypeGroup}
              value={resourceType ? resourceType : ''}
              onChange={(ts: string): void => updateField('resourceType', ts)}
              name={intl.formatMessage(messages.resourceType)}
              inputName="resourceType"
              list="resource_type_list"
              permissions={checkPermission()}
              validation={validation?.root?.resource_type}
            />
          )}
        </DataModelGate>
      );
    case 'SIZE':
      return (
        <DataModelGate entity={entity} field="size">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextInputP
              name={intl.formatMessage(messages.size)}
              help={intl.formatMessage(messages.sizeHelp)}
              value={size ? size : ''}
              inputName="size"
              onChange={(s: string): void => updateField('size', s)}
              permissions={checkPermission()}
              validation={validation?.root?.size}
            />
          )}
        </DataModelGate>
      );
    case 'SUBJECT':
      return (
        <DataModelGate entity={generic} field="work_subjects">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <WorkSubjects
              listPartition={pubtypeGroup}
              workSubjects={workSubjects ? workSubjects : []}
              onChange={(data: SubjectDTO[]): void => updateField('workSubjects', data)}
              checkPermission={checkPermission}
              validation={validation?.root?.work_subjects}
            />
          )}
        </DataModelGate>
      );
    case 'SUBSERIES_TITLE':
      return (
        <DataModelGate entity={entity} field="subseries_title">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
              <TextInputP
                value={subseriesTitle ? subseriesTitle : ''}
                inputName="subseriesTitle"
                name={intl.formatMessage(messages.subseriesTitle)}
                onChange={(data: string): void => updateField('subseriesTitle', data)}
                permissions={checkPermission()}
                validation={validation?.root?.subseries_title}
              />
          )}
        </DataModelGate>
      );
    case 'SUBTITLE':
      return (
        <DataModelGate entity={generic} field="subtitle">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextAreaP
              value={subtitle ? subtitle : ''}
              inputProps={{ 'rows': 1 }}
              inputName="subtitle"
              classes={["flat"]}
              name={intl.formatMessage(messages.subtitle)}
              help={intl.formatMessage(messages.subtitleHelp)}
              onChange={(data: string): void => updateField('subtitle', data)}
              permissions={checkPermission()}
              validation={validation?.root?.subtitle}
            />
          )}
        </DataModelGate>
      );
    case 'SUBTITLE_SORT':
      return <React.Fragment></React.Fragment>;
    case 'TITLE':
      return (
        <DataModelGate entity={generic} field="title">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextAreaP
              value={title ? title : ''}
              inputProps={{ 'rows': 1 }}
              inputName="title"
              classes={["flat"]}
              name={intl.formatMessage(messages.title)}
              help={intl.formatMessage(messages.titleHelp)}
              onChange={(data: string): void => updateField('title', data)}
              permissions={checkPermission()}
              validation={validation?.root?.title}
            />
          )}
        </DataModelGate>
      );
    case 'TITLE_ABBREVIATION':
      return (
        <DataModelGate entity={entity} field="work_title_abbreviations">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TitleAbbreviation
              titleAbbreviations={workTitleAbbreviations ? workTitleAbbreviations : []}
              onChange={(abbr: WorkTitleAbbreviationDTO[]) => {
                updateField('workTitleAbbreviations', abbr);
              }}
              checkPermission={checkPermission}
              validation={validation?.root?.work_title_abbrevations}
            />
          )}
        </DataModelGate>
      );
    case 'TITLE_SUPPLEMENT':
      return (
        <DataModelGate entity={generic} field="title_supplement">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <TextAreaP
              value={titleSupplement ? titleSupplement : ''}
              inputName="titleSupplement"
              inputProps={{ 'rows': 1 }}
              classes={["flat"]}
              name={intl.formatMessage(messages.titleSupplement)}
              help={intl.formatMessage(messages.titleSupplementHelp)}
              onChange={(data: string): void => updateField('titleSupplement', data)}
              permissions={checkPermission()}
              validation={validation?.root?.title_supplement}
            />
          )}
        </DataModelGate>
      );
    case 'URL':
      return (
        <DataModelGate entity={generic} field="urls">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <Urls
              urls={urls ? urls : []}
              typelist={'url_list_work'}
              onChange={(u: UrlDTO[]): void => updateField('urls', u)}
              checkPermission={checkPermission}
              validation={validation?.root?.urls}
            />
          )}
        </DataModelGate>
      );
    case 'VERSION':
      return (
        <DataModelGate entity={entity} field="version">
          {(writable: WritableParam, readable: boolean, checkPermission: (child?: string) => ReadWritePermissions): React.JSX.Element => (
            <div sx={{ gridColumn: "span 1" }}>
              <SelectInputP
                listPartition={pubtypeGroup}
                value={version ? version : ''}
                onChange={(data: string): void => updateField('version', data)}
                name={intl.formatMessage(messages.version)}
                list={'version_list'}
                permissions={checkPermission()}
                validation={validation?.root?.version}
              />
            </div>
          )}
        </DataModelGate>
      );
    default:
      return <React.Fragment>Missing Component for {key}</React.Fragment>;
  }
}
