export const mandant = ['rub', 'tudo', 'hhu', 'uwh'] as const;
export type Mandant = typeof mandant[number];

export interface Order {
  column: string;
  direction: 'asc'|'desc';
}

export interface MandantSettings {
  institution: string;
  usesOrcid: boolean;
}

//

const domainMandantMapping: Map<string, Mandant> = new Map([
  ['tu-dortmund.de', 'tudo'],
  ['tudo.test', 'tudo'],
  ['ruhr-uni-bochum.de', 'rub'],
  ['rub.de', 'rub'],
  ['rub.test', 'rub'],
  ['hhu.de', 'hhu'],
  ['uni-duesseldorf.de', 'hhu'],
  ['hhu.test', 'hhu'],
  ['uni-wh.de', 'uwh'],
  ['uwh.test', 'uwh']
]);

const mandantSettings: Map<Mandant, MandantSettings> = new Map([
  ['tudo', {
    institution: 'APP-OFZR6297G4QVO8B6',
    usesOrcid: true
  }],
  ['rub', {
    institution: 'APP-OZVG6PSQT2L2OBUK',
    usesOrcid: true
  }],
  ['hhu', {
    institution: '',
    usesOrcid: false
  }],
  ['uwh', {
    institution: 'APP-R7XYRUP253K4348R',
    usesOrcid: true
  }]
]);

// Getter

export const getInstitutionFromUserName = (userName: string): string => {
  const defaultValue: string = 'APP-OZVG6PSQT2L2OBUK';
  try {
    // https://dev.ub.rub.de/gitlab/mms-v3/user-stories/-/issues/48
    const domainName = userName.split('@')[1];
    const mndt = getMandantFromDomain(domainName);
    if(!mndt || !mandantSettings.has(mndt)) {
      return defaultValue;
    }
    return mandantSettings.get(mndt).institution;
  } catch (e) {
    return defaultValue;
  }
};

export const getMandantFromUserName = (userName: string): Mandant => {
  const domainName = userName.split('@')[1];
  return getMandantFromDomain(domainName);
};


export const mandantUsesOrcid = (mndt: Mandant): boolean => {
  const defaultValue: boolean = false;
  try {
    if(!mandantSettings.has(mndt)) {
      return defaultValue;
    }
    return mandantSettings.get(mndt).usesOrcid;
  } catch (e) {
    return defaultValue;
  }

};

export function getMandantFromDomain(domain: string): Mandant {
  if(!domainMandantMapping.has(domain)) {
    return null;
  }
  return domainMandantMapping.get(domain);
}
