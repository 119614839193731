import * as React from 'react';

// Components
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Subscribe } from 'unstated';
import { OAuth2Gate, OAuth2Sender } from '../../../../Auth/components';
import { OAuth2Container } from '../../../../Auth/containers/OAuth2Container';

import { Button, useColorMode } from 'theme-ui';
/** @jsx jsx */
import { jsx } from 'theme-ui';

import 'BaseLayout/assets/fonts/icons/icomoon/style.css';

import { NavItem, NavItemDropdownMobile } from './theme';
import { getInstitutionByUsername, isOrcidInstitution } from '../../../utils';
import { useEffect, useState } from 'react';
import { ThemeContext } from '../..';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { fallDown as Menu } from 'react-burger-menu';
import { createThemeableComponent, logoComponents } from 'BaseLayout/themeable-components';

export const HeaderMobile = () => {
    const intl: IntlShape = useIntl();
    const breakpoint: number = useBreakpointIndex({ defaultIndex: 0 });

    const [colorMode, setColorMode] = useColorMode();

    const themeName: string = React.useContext(ThemeContext);
    const Logo = createThemeableComponent(logoComponents);

    React.useEffect((): void =>{
        if (colorMode !== themeName) {
        setColorMode(themeName);
        }
    }, [themeName, colorMode, setColorMode]);

    const messages = defineMessages({
        dataProtection: { id: themeName + '.footer.dataProtection_link' },
        imprint: { id: themeName + '.footer.imprint_link'},
    });

    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [publigOpen, setPubligOpen] = useState<boolean>(false);
    const [searchOpen, setSearchOpen] = useState<boolean>(false);
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [loginOpen, setLoginOpen] = useState<boolean>(false);
    const [profileOpen, setProfileOpen] = useState<boolean>(false);

    useEffect(() => {
        setMenuOpen(false);
    }, [menuOpen]);

    const isMenuOpen = function(state) {
        if(!state.isOpen) {
            setPubligOpen(false);
            setSearchOpen(false); setCreateOpen(false);
            setLoginOpen(false);
            setProfileOpen(false);
        }
    };

    return (
        <div sx={{
        pb: '49px',
        ".bm-burger-button": {
            position: 'absolute',
            width: '30px',
            height: '24px',
            left: '12px',
            top: '12px'
        },
        ".bm-burger-bars": {
            backgroundColor: 'primary',
        },
        ".bm-cross-button": {
            height: '24px',
            width: '24px',
            right: 'auto !important',
            top: '0 !important',
            ml: '9px',
            mt: '3px'
        },
        ".bm-cross": {
            backgroundColor: 'primary',
            width: '4px !important',
            height: '25px !important',
        },
        ".bm-overlay": {
            background: 'none !important',
            height: 'auto !important',
            transition: 'all 0s cubic-bezier(0.4, 0, 0.2, 1) 0s !important',
            transform: 'translate3d(0px, -100%, 0px) !important'
        },
        ".bm-menu-wrap": {
            mb: '-49px !important',
            pb: '1.25em',
            boxShadow: "0 3px 4px rgba(200, 200, 200, 0.5)",
        },
        ".menuLogo": {
            img: {
                width: themeName === 'hhu' ? '9.5em' : themeName === 'tudo' ? '9em' : themeName === 'uwh' ? '6em' : '8em',
                float: 'right',
                mt: themeName === 'hhu' ? '-5px' : themeName === 'uwh' ? '5px' : '12px',
                mb: themeName === 'hhu' ? '-56px' : themeName === 'uwh' ? '-44px' : '-37px',
                mr: themeName === 'hhu' ? '-5px' : '12px'
            }
        }
        }}>
            <div className='menuLogo'>
                <Logo />
            </div>
            <Menu
                onStateChange={ isMenuOpen }
                isOpen={ menuOpen }
                sx={{
                    position: 'relative !important',
                    width: '100% !important',
                    background: 'white'
                }}
            >
                <NavItem to="/" onClick={ ()=>{ setMenuOpen(true); } }>
                    <FormattedMessage id="header.homepage" />
                </NavItem>
                <Subscribe to={[OAuth2Container]}>
                    {(c: OAuth2Container) => {
                    return <NavItemDropdownMobile
                        open={publigOpen}
                        onClick={() => { setPubligOpen(!publigOpen); setSearchOpen(false); setCreateOpen(false); setLoginOpen(false); setProfileOpen(false); }}
                        onCloseMenu={ ()=>{ setMenuOpen(true); } }
                        items={[
                        {
                            icon: '',
                            name: intl.formatMessage({ id: 'header.publist.generator' }),
                            path: '/publist/generator',
                        },
                        ...c.authorizer().isNotAnonymous() ? [{
                            icon: '',
                            name: intl.formatMessage({ id: 'header.publist.user_publications' }),
                            path: '/user/profile/publications',
                        }] : [],
                        {
                            icon: '',
                            name: intl.formatMessage({ id: 'header.publist.documentation' }),
                            path: '/publist/documentation',
                        },
                        ]}
                    >
                        <FormattedMessage id="header.publistgenerator" />
                    </NavItemDropdownMobile>;
                    }}
                </Subscribe>
                <NavItemDropdownMobile
                    open={searchOpen}
                    onClick={() => { setPubligOpen(false); setSearchOpen(!searchOpen); setCreateOpen(false); setLoginOpen(false); setProfileOpen(false); }}
                    onCloseMenu={ ()=>{ setMenuOpen(true); } }
                    items={[
                    {
                        icon: 'icon-werkedurchsuchen',
                        name: intl.formatMessage({ id: 'entity.work' }),
                        path: '/search/works',
                    },
                    {
                        icon: 'icon-personendurchsuchen',
                        name: intl.formatMessage({ id: 'entity.person' }),
                        path: '/search/persons',
                    },
                    {
                        icon: 'icon-organisationdurchsuchen',
                        name: intl.formatMessage({ id: 'entity.organisation' }),
                        path: '/search/organisations',
                    },
                    {
                        icon: 'icon-gruppendurchsuchen',
                        name: intl.formatMessage({ id: 'entity.project' }),
                        path: '/search/projects',
                    },
                    ]}
                >
                    <FormattedMessage id="header.search" />
                </NavItemDropdownMobile>
                <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN', 'ROLE_USER']}>
                    <Subscribe to={[OAuth2Container]}>
                    {(c: OAuth2Container) => {
                        return <NavItemDropdownMobile
                        open={createOpen}
                        onClick={() => { setPubligOpen(false); setSearchOpen(false); setCreateOpen(!createOpen); setLoginOpen(false); setProfileOpen(false); }}
                        onCloseMenu={ ()=>{ setMenuOpen(true); } }
                        items={[
                            {
                            icon: 'icon-werkanlegen',
                            name: intl.formatMessage({ id: 'entity.work' }),
                            path: '/works/add',
                            },
                            (c.authorizer().hasRole("ROLE_ADMIN") || c.authorizer().hasRole("ROLE_EDITOR")) &&
                            {
                            icon: 'icon-personenanlegen',
                            name: intl.formatMessage({ id: 'entity.person' }),
                            path: '/person/add',
                            },
                            (c.authorizer().hasRole("ROLE_ADMIN") || c.authorizer().hasRole("ROLE_EDITOR")) &&
                            {
                            icon: 'icon-organisationanlegen',
                            name: intl.formatMessage({ id: 'entity.organisation' }),
                            path: '/organisation/add',
                            },
                            {
                            icon: 'icon-gruppenanlegen',
                            name: intl.formatMessage({ id: 'entity.project' }),
                            path: '/project/add',
                            },
                        ]}
                        >
                        {(c.authorizer().hasRole("ROLE_ADMIN") || c.authorizer().hasRole("ROLE_EDITOR")) && <FormattedMessage id="header.create" />}
                        {(c.authorizer().hasRole("ROLE_GUEST") || c.authorizer().hasRole("ROLE_USER")) && <FormattedMessage id="header.report" />}
                    </NavItemDropdownMobile>;
                    }}
                    </Subscribe>
                </OAuth2Gate>
                {(process.env.REACT_APP_DEMO_LOGIN === 'true') && (
                    <Subscribe to={[OAuth2Container]}>
                    {(c: OAuth2Container) => {
                        const logins = [
                        [
                            ['ROLE_USER', 'RUB', 'User', 'user@rub.test'],
                            ['ROLE_USER', 'TUDO', 'User', 'user@tudo.test'],
                            ['ROLE_USER', 'HHU', 'User', 'user@hhu.test'],
                            ['ROLE_USER', 'UWH', 'User', 'user@uwh.test'],
                            ['ROLE_USER', 'Other', 'User', 'user@other.test'],
                        ],
                        [
                            ['ROLE_EDITOR', 'RUB', 'Editor', 'editor@rub.test'],
                            ['ROLE_EDITOR', 'TUDO', 'Editor', 'editor@tudo.test'],
                            ['ROLE_EDITOR', 'HHU', 'Editor', 'editor@hhu.test'],
                            ['ROLE_EDITOR', 'UWH', 'Editor', 'editor@uwh.test']
                        ],
                        [
                            ['ROLE_ADMIN', 'RUB', 'Admin', 'admin@rub.test'],
                            ['ROLE_ADMIN', 'TUDO', 'Admin', 'admin@tudo.test'],
                            ['ROLE_ADMIN', 'HHU', 'Admin', 'admin@hhu.test'],
                            ['ROLE_ADMIN', 'UWH', 'Admin', 'admin@uwh.test']
                        ],
                        ];

                        const items = [
                        {
                            path: '/',
                            name: 'GUEST',
                            onClick: () => {
                            c.removeToken();
                            },
                        },
                        ];
                        logins.forEach((project, i) => {
                        project.forEach((l, j) => {
                            items.push({
                            path: '/',
                            name: l[1] + ' ' + l[2],
                            onClick: () => {
                                c.demologin([l[0]], l[1], l[2], l[3]);
                            },
                            });
                        });
                        });

                        return <NavItemDropdownMobile
                        open={loginOpen}
                        onClick={() => { setPubligOpen(false); setSearchOpen(false); setCreateOpen(false); setLoginOpen(!loginOpen); setProfileOpen(false); }}
                        onCloseMenu={ ()=>{ setMenuOpen(true); } }
                        items={items}>Login</NavItemDropdownMobile>;
                    }}
                    </Subscribe>
                )}
                {(breakpoint <= 2) && (
                    <div sx={{
                    li: {
                        mt: 0,
                        borderTopStyle: 'none'
                    }
                    }}>
                    <NavItem to={intl.formatMessage(messages.dataProtection)}>
                        <FormattedMessage id="footer.dataProtection" />
                    </NavItem>
                    <NavItem to={intl.formatMessage(messages.imprint)}>
                        <FormattedMessage id="footer.imprint" />
                    </NavItem>
                    </div>
                )}
                <OAuth2Gate isNotAnonymous>
                    <OAuth2Sender
                    render={({ authorizer }) => (
                        <ul>
                        <Subscribe to={[OAuth2Container]}>
                            {(c: OAuth2Container) => {
                            return (
                                <NavItemDropdownMobile
                                open={profileOpen}
                                onClick={() => { setPubligOpen(false); setSearchOpen(false); setCreateOpen(false); setLoginOpen(false); setProfileOpen(!profileOpen); }}
                                onCloseMenu={ ()=>{ setMenuOpen(true); } }
                                items={[
                                    {
                                    path: '/user/profile',
                                    name: intl.formatMessage({ id: 'userMenu.profile' }),
                                    },
                                    ...(c.authorizer().hasRole("ROLE_ADMIN") ? [
                                    {
                                        path: '/user-admin',
                                        name: intl.formatMessage({ id: "userMenu.userManagement" }),
                                    },
                                    ...(isOrcidInstitution((getInstitutionByUsername(c.authorizer().getSession().user_name)))) && (themeName !== 'hhu') ? [{ // themeName!=='hhu' entfernen, wenn Orcid eingerichtet
                                        path: '/orcid-admin',
                                        name: intl.formatMessage({ id: "userMenu.orcidManagement" }),
                                    }] : [],
                                    ] : []),
                                    {
                                    path: '/user/settings/profile',
                                    name: intl.formatMessage({ id: "userMenu.settings" }),
                                    },
                                    {
                                    path: '/logout',
                                    name: intl.formatMessage({ id: "userMenu.logout" }),
                                    },
                                    ...(process.env.NODE_ENV === 'development' ? [
                                    {
                                        path: '/errorBoundary',
                                        name: 'Test Error Boundary',
                                    },
                                    ] : []),
                                ]}
                                >
                                {authorizer.getSession().user_displayname}
                                </NavItemDropdownMobile>
                            );
                            }}
                        </Subscribe>
                        </ul>
                    )}
                    />
                </OAuth2Gate>

                <OAuth2Gate isAnonymous>
                    <OAuth2Sender
                    render={({ authUrl }) => (
                        <ul sx={{
                        ".icon-anmelden:before": {
                            color: 'white'
                        },
                        mt: '20px',
                        ml: '16px'
                        }}>
                        <a sx={{color: "white", fontWeight: '600'}} href={authUrl}>
                            <Button sx={{p: '7px 0', border: '3px solid', borderColor: 'primary'}}>
                            <div sx={{ px: '5px'}}>
                                <span sx={{ mr: '0.5em' }} className="icon-anmelden"></span>
                                <FormattedMessage id="header.login" />
                            </div>
                            </Button>
                        </a>
                        </ul>
                    )}
                    />
                </OAuth2Gate>
            </Menu>
        </div>
    );
};
