/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminDataWorkOpenAccessFundedDTO
 */
export interface AdminDataWorkOpenAccessFundedDTO {
    /**
     * 
     * @type {number}
     * @memberof AdminDataWorkOpenAccessFundedDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkOpenAccessFundedDTO
     */
    affiliation?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminDataWorkOpenAccessFundedDTO
     */
    type?: string;
}

export function AdminDataWorkOpenAccessFundedDTOFromJSON(json: any): AdminDataWorkOpenAccessFundedDTO {
    return AdminDataWorkOpenAccessFundedDTOFromJSONTyped(json, false);
}

export function AdminDataWorkOpenAccessFundedDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDataWorkOpenAccessFundedDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'affiliation': !exists(json, 'affiliation') ? undefined : json['affiliation'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function AdminDataWorkOpenAccessFundedDTOToJSON(value?: AdminDataWorkOpenAccessFundedDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'affiliation': value.affiliation,
        'type': value.type,
    };
}


