/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ModelDate,
    ModelDateFromJSON,
    ModelDateFromJSONTyped,
    ModelDateToJSON,
    Name,
    NameFromJSON,
    NameFromJSONTyped,
    NameToJSON,
} from './';

/**
 * 
 * @export
 * @interface Csl
 */
export interface Csl {
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    type?: CslTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    mmsType?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Csl
     */
    categories?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    journalAbbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    shortTitle?: string;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    author?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    editor?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    collection_editor?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    composer?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    container_author?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    director?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    interviewer?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    illustrator?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    original_author?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    recipient?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    reviewed_author?: Array<Name>;
    /**
     * 
     * @type {Array<Name>}
     * @memberof Csl
     */
    translator?: Array<Name>;
    /**
     * 
     * @type {ModelDate}
     * @memberof Csl
     */
    accessed?: ModelDate;
    /**
     * 
     * @type {ModelDate}
     * @memberof Csl
     */
    container?: ModelDate;
    /**
     * 
     * @type {ModelDate}
     * @memberof Csl
     */
    event_date?: ModelDate;
    /**
     * 
     * @type {ModelDate}
     * @memberof Csl
     */
    issued?: ModelDate;
    /**
     * 
     * @type {ModelDate}
     * @memberof Csl
     */
    original_date?: ModelDate;
    /**
     * 
     * @type {ModelDate}
     * @memberof Csl
     */
    submitted?: ModelDate;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    _abstract?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    annote?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    archive?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    archive_location?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    archive_place?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    authority?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    call_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    chapter_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    citation_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    citation_label?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    collection_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    collection_title?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    container_title?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    container_title_short?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    dimensions?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    DOI?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    edition?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    event?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    event_place?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    first_reference_note_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    genre?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    ISBN?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    ISSN?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    issue?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    jurisdiction?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    keyword?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    locator?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    number_of_pages?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    number_of_volumes?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    original_publisher?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    original_publisher_place?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    original_title?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    page?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    page_first?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    PMCID?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    PMID?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    publisher?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    publisher_place?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    references?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    reviewed_title?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    scale?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    source?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    title_short?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    URL?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    volume?: string;
    /**
     * 
     * @type {string}
     * @memberof Csl
     */
    year_suffix?: string;
}

export function CslFromJSON(json: any): Csl {
    return CslFromJSONTyped(json, false);
}

export function CslFromJSONTyped(json: any, ignoreDiscriminator: boolean): Csl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'mmsType': !exists(json, 'mmsType') ? undefined : json['mmsType'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'journalAbbreviation': !exists(json, 'journalAbbreviation') ? undefined : json['journalAbbreviation'],
        'shortTitle': !exists(json, 'shortTitle') ? undefined : json['shortTitle'],
        'author': !exists(json, 'author') ? undefined : ((json['author'] as Array<any>).map(NameFromJSON)),
        'editor': !exists(json, 'editor') ? undefined : ((json['editor'] as Array<any>).map(NameFromJSON)),
        'collection_editor': !exists(json, 'collection-editor') ? undefined : ((json['collection-editor'] as Array<any>).map(NameFromJSON)),
        'composer': !exists(json, 'composer') ? undefined : ((json['composer'] as Array<any>).map(NameFromJSON)),
        'container_author': !exists(json, 'container-author') ? undefined : ((json['container-author'] as Array<any>).map(NameFromJSON)),
        'director': !exists(json, 'director') ? undefined : ((json['director'] as Array<any>).map(NameFromJSON)),
        'interviewer': !exists(json, 'interviewer') ? undefined : ((json['interviewer'] as Array<any>).map(NameFromJSON)),
        'illustrator': !exists(json, 'illustrator') ? undefined : ((json['illustrator'] as Array<any>).map(NameFromJSON)),
        'original_author': !exists(json, 'original-author') ? undefined : ((json['original-author'] as Array<any>).map(NameFromJSON)),
        'recipient': !exists(json, 'recipient') ? undefined : ((json['recipient'] as Array<any>).map(NameFromJSON)),
        'reviewed_author': !exists(json, 'reviewed-author') ? undefined : ((json['reviewed-author'] as Array<any>).map(NameFromJSON)),
        'translator': !exists(json, 'translator') ? undefined : ((json['translator'] as Array<any>).map(NameFromJSON)),
        'accessed': !exists(json, 'accessed') ? undefined : ModelDateFromJSON(json['accessed']),
        'container': !exists(json, 'container') ? undefined : ModelDateFromJSON(json['container']),
        'event_date': !exists(json, 'event-date') ? undefined : ModelDateFromJSON(json['event-date']),
        'issued': !exists(json, 'issued') ? undefined : ModelDateFromJSON(json['issued']),
        'original_date': !exists(json, 'original-date') ? undefined : ModelDateFromJSON(json['original-date']),
        'submitted': !exists(json, 'submitted') ? undefined : ModelDateFromJSON(json['submitted']),
        '_abstract': !exists(json, 'abstract') ? undefined : json['abstract'],
        'annote': !exists(json, 'annote') ? undefined : json['annote'],
        'archive': !exists(json, 'archive') ? undefined : json['archive'],
        'archive_location': !exists(json, 'archive_location') ? undefined : json['archive_location'],
        'archive_place': !exists(json, 'archive-place') ? undefined : json['archive-place'],
        'authority': !exists(json, 'authority') ? undefined : json['authority'],
        'call_number': !exists(json, 'call-number') ? undefined : json['call-number'],
        'chapter_number': !exists(json, 'chapter-number') ? undefined : json['chapter-number'],
        'citation_number': !exists(json, 'citation-number') ? undefined : json['citation-number'],
        'citation_label': !exists(json, 'citation-label') ? undefined : json['citation-label'],
        'collection_number': !exists(json, 'collection-number') ? undefined : json['collection-number'],
        'collection_title': !exists(json, 'collection-title') ? undefined : json['collection-title'],
        'container_title': !exists(json, 'container-title') ? undefined : json['container-title'],
        'container_title_short': !exists(json, 'container-title-short') ? undefined : json['container-title-short'],
        'dimensions': !exists(json, 'dimensions') ? undefined : json['dimensions'],
        'DOI': !exists(json, 'DOI') ? undefined : json['DOI'],
        'edition': !exists(json, 'edition') ? undefined : json['edition'],
        'event': !exists(json, 'event') ? undefined : json['event'],
        'event_place': !exists(json, 'event-place') ? undefined : json['event-place'],
        'first_reference_note_number': !exists(json, 'first-reference-note-number') ? undefined : json['first-reference-note-number'],
        'genre': !exists(json, 'genre') ? undefined : json['genre'],
        'ISBN': !exists(json, 'ISBN') ? undefined : json['ISBN'],
        'ISSN': !exists(json, 'ISSN') ? undefined : json['ISSN'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'jurisdiction': !exists(json, 'jurisdiction') ? undefined : json['jurisdiction'],
        'keyword': !exists(json, 'keyword') ? undefined : json['keyword'],
        'locator': !exists(json, 'locator') ? undefined : json['locator'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'number_of_pages': !exists(json, 'number-of-pages') ? undefined : json['number-of-pages'],
        'number_of_volumes': !exists(json, 'number-of-volumes') ? undefined : json['number-of-volumes'],
        'original_publisher': !exists(json, 'original-publisher') ? undefined : json['original-publisher'],
        'original_publisher_place': !exists(json, 'original-publisher-place') ? undefined : json['original-publisher-place'],
        'original_title': !exists(json, 'original-title') ? undefined : json['original-title'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'page_first': !exists(json, 'page-first') ? undefined : json['page-first'],
        'PMCID': !exists(json, 'PMCID') ? undefined : json['PMCID'],
        'PMID': !exists(json, 'PMID') ? undefined : json['PMID'],
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'publisher_place': !exists(json, 'publisher-place') ? undefined : json['publisher-place'],
        'references': !exists(json, 'references') ? undefined : json['references'],
        'reviewed_title': !exists(json, 'reviewed-title') ? undefined : json['reviewed-title'],
        'scale': !exists(json, 'scale') ? undefined : json['scale'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'title_short': !exists(json, 'title-short') ? undefined : json['title-short'],
        'URL': !exists(json, 'URL') ? undefined : json['URL'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'year_suffix': !exists(json, 'year-suffix') ? undefined : json['year-suffix'],
    };
}

export function CslToJSON(value?: Csl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'mmsType': value.mmsType,
        'id': value.id,
        'categories': value.categories,
        'language': value.language,
        'journalAbbreviation': value.journalAbbreviation,
        'shortTitle': value.shortTitle,
        'author': value.author === undefined ? undefined : ((value.author as Array<any>).map(NameToJSON)),
        'editor': value.editor === undefined ? undefined : ((value.editor as Array<any>).map(NameToJSON)),
        'collection-editor': value.collection_editor === undefined ? undefined : ((value.collection_editor as Array<any>).map(NameToJSON)),
        'composer': value.composer === undefined ? undefined : ((value.composer as Array<any>).map(NameToJSON)),
        'container-author': value.container_author === undefined ? undefined : ((value.container_author as Array<any>).map(NameToJSON)),
        'director': value.director === undefined ? undefined : ((value.director as Array<any>).map(NameToJSON)),
        'interviewer': value.interviewer === undefined ? undefined : ((value.interviewer as Array<any>).map(NameToJSON)),
        'illustrator': value.illustrator === undefined ? undefined : ((value.illustrator as Array<any>).map(NameToJSON)),
        'original-author': value.original_author === undefined ? undefined : ((value.original_author as Array<any>).map(NameToJSON)),
        'recipient': value.recipient === undefined ? undefined : ((value.recipient as Array<any>).map(NameToJSON)),
        'reviewed-author': value.reviewed_author === undefined ? undefined : ((value.reviewed_author as Array<any>).map(NameToJSON)),
        'translator': value.translator === undefined ? undefined : ((value.translator as Array<any>).map(NameToJSON)),
        'accessed': ModelDateToJSON(value.accessed),
        'container': ModelDateToJSON(value.container),
        'event-date': ModelDateToJSON(value.event_date),
        'issued': ModelDateToJSON(value.issued),
        'original-date': ModelDateToJSON(value.original_date),
        'submitted': ModelDateToJSON(value.submitted),
        'abstract': value._abstract,
        'annote': value.annote,
        'archive': value.archive,
        'archive_location': value.archive_location,
        'archive-place': value.archive_place,
        'authority': value.authority,
        'call-number': value.call_number,
        'chapter-number': value.chapter_number,
        'citation-number': value.citation_number,
        'citation-label': value.citation_label,
        'collection-number': value.collection_number,
        'collection-title': value.collection_title,
        'container-title': value.container_title,
        'container-title-short': value.container_title_short,
        'dimensions': value.dimensions,
        'DOI': value.DOI,
        'edition': value.edition,
        'event': value.event,
        'event-place': value.event_place,
        'first-reference-note-number': value.first_reference_note_number,
        'genre': value.genre,
        'ISBN': value.ISBN,
        'ISSN': value.ISSN,
        'issue': value.issue,
        'jurisdiction': value.jurisdiction,
        'keyword': value.keyword,
        'locator': value.locator,
        'medium': value.medium,
        'note': value.note,
        'number': value.number,
        'number-of-pages': value.number_of_pages,
        'number-of-volumes': value.number_of_volumes,
        'original-publisher': value.original_publisher,
        'original-publisher-place': value.original_publisher_place,
        'original-title': value.original_title,
        'page': value.page,
        'page-first': value.page_first,
        'PMCID': value.PMCID,
        'PMID': value.PMID,
        'publisher': value.publisher,
        'publisher-place': value.publisher_place,
        'references': value.references,
        'reviewed-title': value.reviewed_title,
        'scale': value.scale,
        'section': value.section,
        'source': value.source,
        'status': value.status,
        'title': value.title,
        'title-short': value.title_short,
        'URL': value.URL,
        'version': value.version,
        'volume': value.volume,
        'year-suffix': value.year_suffix,
    };
}

/**
* @export
* @enum {string}
*/
export enum CslTypeEnum {
    Article = 'article',
    ArticleJournal = 'article-journal',
    ArticleMagazine = 'article-magazine',
    ArticleNewspaper = 'article-newspaper',
    Bill = 'bill',
    Book = 'book',
    Broadcast = 'broadcast',
    Chapter = 'chapter',
    Dataset = 'dataset',
    Entry = 'entry',
    EntryDictionary = 'entry-dictionary',
    EntryEncyclopedia = 'entry-encyclopedia',
    Figure = 'figure',
    Graphic = 'graphic',
    Interview = 'interview',
    LegalCase = 'legal_case',
    Legislation = 'legislation',
    Manuscript = 'manuscript',
    Map = 'map',
    MotionPicture = 'motion_picture',
    MusicalScore = 'musical_score',
    Pamphlet = 'pamphlet',
    PaperConference = 'paper-conference',
    Patent = 'patent',
    PersonalCommunication = 'personal_communication',
    Post = 'post',
    PostWeblog = 'post-weblog',
    Report = 'report',
    Review = 'review',
    ReviewBook = 'review-book',
    Song = 'song',
    Speech = 'speech',
    Thesis = 'thesis',
    Treaty = 'treaty',
    Webpage = 'webpage'
}


