/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageWorkDTO,
    PageWorkDTOFromJSON,
    PageWorkDTOToJSON,
    PatchDocument,
    PatchDocumentFromJSON,
    PatchDocumentToJSON,
    WorkDTO,
    WorkDTOFromJSON,
    WorkDTOToJSON,
} from '../models';

export interface CreateWorkRequest {
    workDTO: WorkDTO;
}

export interface DeleteWorkRequest {
    workId: number;
}

export interface ExportWorksRequest {
    requestBody: Array<number>;
    size?: number;
    pageNumber?: number;
}

export interface FindByOrcidPutcodeRequest {
    putcode: number;
}

export interface FindInExternalSourcesRequest {
    id: string;
}

export interface GetWorkRequest {
    workId: number;
}

export interface RequestDeleteRequest {
    workId: number;
    patchDocument?: Array<PatchDocument>;
}

export interface UpdateWorkRequest {
    workId: number;
    patchDocument?: Array<PatchDocument>;
}

/**
 * 
 */
export class WorkApi extends runtime.BaseAPI {

    /**
     */
    async createWorkRaw(requestParameters: CreateWorkRequest): Promise<runtime.ApiResponse<WorkDTO>> {
        if (requestParameters.workDTO === null || requestParameters.workDTO === undefined) {
            throw new runtime.RequiredError('workDTO','Required parameter requestParameters.workDTO was null or undefined when calling createWork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WorkDTOToJSON(requestParameters.workDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkDTOFromJSON(jsonValue));
    }

    /**
     */
    async createWork(requestParameters: CreateWorkRequest): Promise<WorkDTO> {
        const response = await this.createWorkRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteWorkRaw(requestParameters: DeleteWorkRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling deleteWork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters.workId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteWork(requestParameters: DeleteWorkRequest): Promise<void> {
        await this.deleteWorkRaw(requestParameters);
    }

    /**
     */
    async exportWorksRaw(requestParameters: ExportWorksRequest): Promise<runtime.ApiResponse<PageWorkDTO>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling exportWorks.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.pageNumber !== undefined) {
            queryParameters['pageNumber'] = requestParameters.pageNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PageWorkDTOFromJSON(jsonValue));
    }

    /**
     */
    async exportWorks(requestParameters: ExportWorksRequest): Promise<PageWorkDTO> {
        const response = await this.exportWorksRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findByOrcidPutcodeRaw(requestParameters: FindByOrcidPutcodeRequest): Promise<runtime.ApiResponse<WorkDTO>> {
        if (requestParameters.putcode === null || requestParameters.putcode === undefined) {
            throw new runtime.RequiredError('putcode','Required parameter requestParameters.putcode was null or undefined when calling findByOrcidPutcode.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.putcode !== undefined) {
            queryParameters['putcode'] = requestParameters.putcode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work/findByOrcidPutcode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkDTOFromJSON(jsonValue));
    }

    /**
     */
    async findByOrcidPutcode(requestParameters: FindByOrcidPutcodeRequest): Promise<WorkDTO> {
        const response = await this.findByOrcidPutcodeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async findInExternalSourcesRaw(requestParameters: FindInExternalSourcesRequest): Promise<runtime.ApiResponse<Array<WorkDTO>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findInExternalSources.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work/findInExternalSources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkDTOFromJSON));
    }

    /**
     */
    async findInExternalSources(requestParameters: FindInExternalSourcesRequest): Promise<Array<WorkDTO>> {
        const response = await this.findInExternalSourcesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWorkRaw(requestParameters: GetWorkRequest): Promise<runtime.ApiResponse<WorkDTO>> {
        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling getWork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters.workId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkDTOFromJSON(jsonValue));
    }

    /**
     */
    async getWork(requestParameters: GetWorkRequest): Promise<WorkDTO> {
        const response = await this.getWorkRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async requestDeleteRaw(requestParameters: RequestDeleteRequest): Promise<runtime.ApiResponse<WorkDTO>> {
        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling requestDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work/{workId}/requestDelete`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters.workId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchDocument.map(PatchDocumentToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkDTOFromJSON(jsonValue));
    }

    /**
     */
    async requestDelete(requestParameters: RequestDeleteRequest): Promise<WorkDTO> {
        const response = await this.requestDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateWorkRaw(requestParameters: UpdateWorkRequest): Promise<runtime.ApiResponse<WorkDTO>> {
        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling updateWork.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/work/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters.workId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchDocument.map(PatchDocumentToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateWork(requestParameters: UpdateWorkRequest): Promise<WorkDTO> {
        const response = await this.updateWorkRaw(requestParameters);
        return await response.value();
    }

}
