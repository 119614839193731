import { Theme } from 'theme-ui';
import { withModalTheme } from '@mattjennings/react-modal';
import bannerImg from './assets/img/homepage/banner_startseite.svg';
import bannerPublist from './assets/img/publist/banner_publig.svg';
import bannerWorkEdit from './assets/img/edit/work.svg';
import bannerPersonEdit from './assets/img/edit/person.svg';
import bannerProjectEdit from './assets/img/edit/project.svg';
import bannerOrganisationEdit from './assets/img/edit/organisation.svg';

import bannerWorkSearch from './assets/img/search/work.svg';
import bannerPersonSearch from './assets/img/search/person.svg';
import bannerProjectSearch from './assets/img/search/project.svg';
import bannerOrganisationSearch from './assets/img/search/organisation.svg';

const theme: Theme = withModalTheme({
  breakpoints: [
    '500px', '800px', '1000px', '1200px', '1400px',
  ],
  space: [0, 1, 2, 4, 8, 12, 16, 24, 32, 48, 64, 80, 96],
  fonts: {
    body: 'Titillium',
  },
  fontWeights: {
    regular: 400,
    emph: 700
  },
  colors: {
    header: '#fafafa',
    lighter: '#f3f3f3',
    light: "#dbdbdb",
    light2: "#d9d9d9",
    darker: "#777777",
    success: "#8fb017",
    danger: "#ad0f0f",
    info: "#00b3c8",
    background: "white",
    body_bg: "#f2f2f2",
    card_bg: "#fff",
    transparent_bg: "#ababab33",
    primary: "#848484",
    secondary: "#d3d3d3",
    secondary_hover: "#dddddd",
    secondary_active: "#dddddd",
    negative: "#da0000",
    header_bg: "#848484",
    header_bg_hover: "#a0a0a0",
    header_more_bg: "#a0a0a0",
    header_search_bg: "#a0a0a0",
    footer: "#f2f2f2",
    footer_bg: "#848484",
    text: "black",
    text_inverted: "white",
    index: "#5f5f5f",
    shadow: "silver",
    modes: {
      rub: {
        negative: "#da0000",
        header: '#fafafa',
        primary: '#17365c',
        secondary: '#8dae10',
        "dashboard":'#17365c',
        "headline": '#17365c',
        "anchor": '#17365c',
        "bold": '#17365c',
        "form_label": '#17365c',
        "category_toggle": '#17365c',
        "form_field_link": '#17365c',
        "work_navigation": '#17365c',
        "suggestion_bg": '#f2f5ff',
        "facet_label": '#17365c',
        "body_bg": "#fafafa",
        "secondary_hover": "#9dc219",
        "secondary_active": "#86964c",
        "header_bg": "white",
        "header_bg_hover": "#2e445e",
        "header_more_bg": "#2e445e",
        "header_search_bg": "#455e7d",
        "footer": "white",
        "footer_bg": "#17365c",
        "icon_color": '#17365c',
        "input_focus": 'rgba(0, 53, 96, .2)',
        "spinner_2": 'rgba(23, 54, 92, .6)',
        "spinner_3": 'rgba(23, 54, 92, .3)'
      },
      tudo: {
        header: '#fafafa',
        primary: '#639A00',
        secondary: '#CA7406',
        "dashboard":'#000000',
        "headline": '#000000',
        "anchor": '#699A00',
        "bold": '#000000',
        "form_label": '#000000',
        "category_toggle": '#000000',
        "form_field_link": '#000000',
        "work_navigation": '#000000',
        "suggestion_fg": 'white',
        "suggestion_bg": '#639A00',
        "facet_label": '#000000',
        "body_bg": "#fafafa",
        "secondary_hover": "#86acf3",
        "secondary_active": "#4877cf",
        "negative": "#da0000",
        "header_hover": "#fff",
        "header_bg": "#fff",
        "header_bg_hover": "#639a00",
        "header_more": "#fff",
        "header_more_bg": "#616161",
        "header_search_bg": "#616161",
        "footer": "#fff",
        "footer_bg": "#616161",
        "icon_color": "#000000",
        "input_focus": 'rgba(99, 154, 0, .2)',
        "spinner_2": 'rgba(99, 154, 0, .6)',
        "spinner_3": 'rgba(99, 154, 0, .3)'
      },
      hhu: {
        primary: '#006ab3',
        secondary: '#8cb110',
        "anchor": '#006ab3',
        "facet_label": '#006ab3',
        "dashboard": '#006ab3',
        "headline": '#006ab3',
        "form_label": '#006ab3',
        "category_toggle": '#006ab3',
        "icon_color": '#006ab3',
        "work_navigation": '#006ab3',
        "suggestion_bg": '#f2f5ff',
        "input_focus": 'rgba(0, 106, 179, .2)',
        "spinner_2": 'rgba(0, 106, 179, .6)',
        "spinner_3": 'rgba(0, 106, 179, .3)'
      },
      uwh: {
        primary: '#004F9F',
        secondary: '#706F6F',
        "yellow": "#FCEA10",

        "anchor": '#004F9F',
        "facet_label": '#004F9F',
        "dashboard": '#004F9F',
        "headline": '#004F9F',
        "form_label": '#004F9F',
        "category_toggle": '#004F9F',
        "icon_color": '#004F9F',
        "work_navigation": '#004F9F',
        "suggestion_bg": '#f2f5ff',
        "input_focus": 'rgba(0, 79, 159, .2)',
        "spinner_2": 'rgba(0, 79, 159, .6)',
        "spinner_3": 'rgba(0, 79, 159, .3)',
      }
    },
  },
  shadows: {
    default: "0.4em 0.8em 0.5em silver"
  },
  borders: {
    default: "1px solid",
    thick: "2px solid",
    thicker: "3px solid"
  },
  alerts: {
    primary: {
      color: 'darker',
      bg: 'lighter',
      pointerEvents: 'all',
      display: 'inline-table',
      padding: 0,
      border: 'default',
      borderColor: 'light',
      borderRadius: '0.5em',
      width: '70vw',
      position: 'relative',
      fontWeight: 'regular',
      marginTop: "3em",
      boxShadow: 'default',
      ".alert-icon": {
        minWidth: '4em',
        display: 'table-cell',
        textAlign: 'center',
        verticalAlign: 'middle',
        borderRadius: '0.5em 0 0 0.5em'
      },
      ".alert-message": {
        width: '100%',
        padding: '0.7em 0.7em 0.7em 1.4em',
        display: 'table-cell'
      },
      ".alert-message-title": {
        fontWeight: 'emph',
        marginBottom: '0.15em'
      },
      ".alert-close": {
        minWidth: '4em',
        display: 'table-cell',
        verticalAlign: 'middle',
        textAlign: 'center'
      },
      ".alert-close-icon": {
        fontSize: '2em',
        cursor: 'pointer'
      },
      ".alert-severity-icon": {
        color: 'text_inverted',
        fontSize: '1.5em'
      }
    },
    success: {
      variant: "alerts.primary",
      ".alert-severity": {
        bg: 'success',
        border: 'default',
        borderColor: 'success'
      }
    },
    info: {
      variant: "alerts.primary",
      ".alert-severity": {
        bg: 'info',
        border: 'default',
        borderColor: 'info'
      }
    },
    error: {
      variant: "alerts.primary",
      ".alert-severity": {
        bg: 'danger',
        border: 'default',
        borderColor: 'danger'
      }
    }
  },
  styles: {
    h1: {
      textAlign: 'center',
      color: 'headline',
      fontSize: 5,
      fontWeight: 'bold',
    },
    h2: {
      color: 'headline',
      fontSize: 4,
      fontWeight: 'bold',
    },
    h3: {
      color: 'headline',
      fontSize: 3,
      fontWeight: 'bold',
    },
    h4: {
      color: 'headline',
      fontSize: 2,
      fontWeight: 'bold',
    },
    a: {
      color: 'anchor',
    },
    b: {
      color: 'bold',
    },
  },
  cards: {
    form: {
      p: 5, borderWidth: 1, borderColor: "light", borderStyle: "solid"
    }
  },
  modals: {
    outlined: {
      position: "fixed",
      width: "60%",
      zIndex: 100,
      left: 0,
      right: 0,
      top: "50%",
      transform: "translateY(-50%) !important",
      margin: 8,
      mx: "auto",
      boxShadow: "0 3px 4px rgba(200, 200, 200, 0.5)",
      padding: 8,
      color: 'primary',
      borderLeft: "5px solid",
      borderColor:'primary',
      backgroundColor: "white",
      overflow: "visible"
    }
  },
  buttons: {
    primary: {
      borderRadius: 0,
      mx: 1,
      backgroundColor: "primary",
      textDecoration: "none",
      color: "white",
      p: 5,
      display: "inline-block",
      cursor: 'pointer',
      '&:focus,&:active': {
        outline: 'none'
      },
      '&:disabled': {
        opacity: 0.65,
        cursor: 'not-allowed'
      }
    },
    secondary: {
      variant: "buttons.primary",
      backgroundColor: "secondary",
    },
    blank: {
      variant: "buttons.primary",
      backgroundColor: "white",
      color: "primary"
    },
    icon: {
      variant: "buttons.primary",
      p: 4,
      fontWeight: "bold",
      mt: 2,
      color: "white"
    },
    blank_icon: {
      variant: "buttons.icon",
      backgroundColor: "white",
      color: "primary",
      border: 'default',
      borderColor: "light"
    },
    close: {
      variant: "buttons.primary",
      color: "red",
      bg: "transparent",
      fontSize: "2em",
      p: 0
    },
    none: {
      variant: "buttons.primary",
      bg: "transparent",
      p: 0
    }
  },
  forms: {
    label: {
      color: "form_label",
      fontWeight: "bold",
      display: "flex",
      py: 3
    },
    labelX: {
      variant: "forms.label",
      flexDirection: "row",
      color: "form_label",
      "> input": {
        color: "darker"
      },
      "&.invalid": {
        color: "negative",
        "> *": {
          color: "negative"
        },
      },
      "> div > div" : {
        color: "form_label",
        width: '30em',
        zIndex: 10,
        backgroundColor: 'lighter',
        p: 5,
      }
    },
    labelXBaseline: {
      variant: "forms.label",
      flexDirection: "row",
      alignItems: "baseline"
    },
    labelY: {
      variant: "forms.label",
      flexDirection: "column",
      color: "form_label",
      "> input": {
        color: "darker"
      },
      "&.invalid": {
        color: "negative",
        "> *": {
          color: "negative"
        }
      },
      "> div > div" : {
        color: "form_label",
        width: 'fit-content',
        maxWidth: '30em',
        zIndex: 10,
        backgroundColor: 'lighter',
        p: 5,
      }
    },
    labelYAutoWidth: {
      variant: "forms.labelY",
      width: 'auto',
      flexShrink: 0
    },
    input: {
      borderColor: "light2",
      color: "darker",
      borderRadius: 0,
      p: 4,
      outline: "none !important",
      borderWidth: "1px",
      "&:disabled": {
        backgroundColor: "lighter",
        cursor: "not-allowed"
      },
      "&.invalid": {
        borderColor: "negative"
      },
      fontFamily: 'sans-serif',
      "::placeholder": {
        opacity: '0.5',
      },
      "&:focus": {
        backgroundColor: "input_focus"
      }
    },
    inputFrontSearch: {
      variant: "forms.input",
      padding: '0.5em',
      backgroundColor: 'white',
      border: 'none',
      '::placeholder': {
        color: 'lightgray',
        opacity: 1,
      },
      '&:focus': {
        outline: 'none',
      },
    },
    select: {
      variant: "forms.input",
      paddingRight: "1.5em",
      "&.invalid": {
        borderColor: "negative"
      },
      fontFamily: 'sans-serif'
    },
    textarea: {
      variant: "forms.input",
      resize: "vertical",
      "&.invalid": {
        borderColor: "negative"
      },
      "&.flat": {
        height: "35px",
        fontSize: "16px"
      },
      fontFamily: "sans-serif"
    },
    searchBar: {
      variant: "forms.input",
      width: "100%",
      paddingRight: "2em",
      fontSize: 1,
      border: "1px solid",
      borderColor: "light"
    }
  },
  layout: {
    banner: {
      h1: {
        color: 'white',
        backgroundColor: 'primary',
        py: 1,
        px: 6,
        fontWeight: 'bold',
        fontSize: '3em',
      },
      width: '100%',
      height: '30em',
      backgroundImage: `url(${bannerImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    bannerPublist: {
      variant: 'layout.banner',
      backgroundImage: `url(${bannerPublist})`,
      height: '10em',
      h1: {
        color: 'primary',
        backgroundColor: 'white',
        fontSize: 4,
        px: 3,
      },
      h2: {
        color: 'white',
        backgroundColor: 'primary',
        fontWeight: 'bold',
        fontSize: 4,
        px: 2,
      },
    },
    bannerEditWork: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerWorkEdit})`,
      height: '10em',
    },
    bannerEditPerson: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerPersonEdit})`,
      height: '10em',
    },
    bannerEditProject: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerProjectEdit})`,
      height: '10em',
    },
    bannerEditOrganisation: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerOrganisationEdit})`,
      height: '10em',
    },
    bannerSearchWork: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerWorkSearch})`,
      height: '10em',
    },
    bannerSearchPerson: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerPersonSearch})`,
      height: '10em',
    },
    bannerSearchProject: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerProjectSearch})`,
      height: '10em',
    },
    bannerSearchOrganisation: {
      variant: 'layout.bannerPublist',
      backgroundImage: `url(${bannerOrganisationSearch})`,
      height: '10em',
    },
    multiRow: {
      gap: 3,
      width: "100%"
    },
  },
});

export default theme;
