export const de_calender = {
    name: "de",
    months: [
      ["Januar", "Jan"],
      ["Februar", "Feb"],
      ["März", "Mär"],
      ["April", "Apr"],
      ["Mai", "Mai"],
      ["Juni", "Jun"],
      ["Juli", "Jul"],
      ["August", "Aug"],
      ["September", "Sep"],
      ["Oktober", "Okt"],
      ["November", "Nov"],
      ["Dezember", "Dez"],
    ],
    weekDays: [
      ["Sonntag", "So"],
      ["Montag", "Mo"],
      ["Dienstag", "Di"],
      ["Mittwoch", "Mi"],
      ["Donnerstag", "Do"],
      ["Freitag", "Fr"],
      ["Samstag", "Sa"]
    ],
    digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
    meridiems: [],
  };

  export const en_calender = {
    name: "en",
    weekDays: [
      ["Sunday", "Sun"],
      ["Monday", "Mon"],
      ["Tuesday", "Tue"],
      ["Wednesday", "Wed"],
      ["Thursday", "Thu"],
      ["Friday", "Fri"],
      ["Saturday", "Sat"]
    ],
  };