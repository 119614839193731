import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import React from 'react';
import tudoLogo from '../../assets/img/logos/logo_tudo.svg';
import orcidLogo from '../../assets/img/logos/logo_orcid.svg';
import { FormattedHTMLMessage, FormattedMessage, useIntl, defineMessages, IntlShape } from 'react-intl';
import { Flex, Box, Styled, Link } from 'theme-ui';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FooterDesktopStyling } from './theme';

const messages = defineMessages({ dataProtection: { id: 'tudo.footer.dataProtection_link' },
                                  imprint: { id: 'tudo.footer.imprint_link'},
                                  email: { id: 'tudo.footer.email'},
                                  logoLink: { id: 'tudo.logo_link'},
                                  orcidLink: { id: 'orcid.logo_link'},
                                  guidelines: { id: 'tudo.footer.guidelines_link'}
                                });

export const TudoFooterDesktop = (): React.JSX.Element => {
  const intl: IntlShape = useIntl();
  return (
    <FooterDesktopStyling>
      <Flex className='footer'>
        <Flex className='logos'>
        <a href={intl.formatMessage(messages.logoLink)}>
          <img src={tudoLogo} />
        </a>
        <a href={intl.formatMessage(messages.orcidLink)}>
          <img src={orcidLogo} />
        </a>
        </Flex>
        <Box px={8} py={6} className='info'>
          <Link href={`mailto:${intl.formatMessage(messages.email)}`}>
            <FontAwesomeIcon icon={faAt} /> <FormattedMessage id="tudo.footer.email" />
          </Link>
          <br />
          <br />
          <p>
            <FormattedHTMLMessage id="tudo.footer.infotext" />
          </p>
          <Flex className='links'>
            <Styled.a href={intl.formatMessage(messages.guidelines)}><FormattedMessage id="footer.guidelines" /></Styled.a>
            <Styled.a href={intl.formatMessage(messages.dataProtection)}><FormattedMessage id="footer.dataProtection" /></Styled.a>
            <Styled.a href={intl.formatMessage(messages.imprint)}><FormattedMessage id="footer.imprint" /></Styled.a>
          </Flex>
        </Box>
      </Flex>
    </FooterDesktopStyling>
  );
};
