import React, { useEffect, useState } from 'react';
import { ReferencedWorkDTO, WorkIsPartOfDTO as Part } from '../../../../../api/core';
import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';
import { WorkSuggest } from '../../../auto-complete/WorkSuggest';
import { TextInputP } from '../../../../../BaseLayout/components/form/generic/TextInput';
import { CheckPermissionFunction } from '../../../../../Auth/components/DataModelGate';
import { PubtypeServiceApi } from '../../../../../api/pubtype';
import { getWorkPartFields, FieldSettings } from '../../../../utils';
import { isRequired } from '../../../../../BaseLayout/components/form/generic/utils';
import { ValidationResults } from '../../../../validation/interfaces';

const messages = defineMessages({
  articleId: { id: 'work.attr.workPart.attr.articleId' },
  articleIdPlaceholder: { id: 'work.attr.workPart.attr.articleId.placeholder' },
  issue: { id: 'work.attr.workPart.attr.issue' },
  pageFirst: { id: 'work.attr.workPart.attr.pageFirst' },
  pageLast: { id: 'work.attr.workPart.attr.pageLast' },
  volume: { id: 'work.attr.workPart.attr.volume' },
  referencedWork: { id: 'work.attr.workPart.attr.referencedWork'},
  referencedWorkHelp: { id: 'work.attr.workPart.attr.referencedWork.help'}
});

interface WorkPartProps {
  api: PubtypeServiceApi;
  part: Part;
  checkPermission: CheckPermissionFunction;
  onChange: (part: Part) => void;
  intl: IntlShape;
  actions: any;
  pubtypes?: {
    parent: string;
    part: string;
  };
  validations: ValidationResults;
}

export function WorkPart({api, part, checkPermission, onChange, intl, pubtypes, validations}: WorkPartProps) {
  const [fieldSettings, setFieldSettings] = useState({ issue: false, volume: false, pageLast: false, pageFirst: false, articleId: false });

  useEffect((): void => {
    if(pubtypes?.part && pubtypes?.parent) {
      getWorkPartFields(api, pubtypes)
        .then((fs: FieldSettings): void => {
          if(
            fieldSettings.issue !== fs.issue ||
            fieldSettings.volume !== fs.volume ||
            fieldSettings.pageFirst !== fs.pageFirst ||
            fieldSettings.pageLast !== fs.pageLast ||
            fieldSettings.articleId !== fs.articleId
          ) {
            setFieldSettings({...fs});
          }
        });
    }
  });
  return <>
    {checkPermission('referenced_work').read && (

        <WorkSuggest
          required={isRequired(checkPermission('referenced_work'))}
          single
          canDelete={false}
          tags={part.referencedWork ? [part.referencedWork] : []}
          onChange={(referencedWorks: ReferencedWorkDTO[]): void =>
            onChange({
              ...part,
              referencedWork: referencedWorks[0],
            })
          }
          disabled={!checkPermission('referenced_work').write}
          validation={validations?.referenced_work}
          name={intl.formatMessage(messages.referencedWork)}
          help={window.location.pathname.includes('ispartof') ? undefined : intl.formatMessage(messages.referencedWorkHelp)}
          autoFocus={true}
        />

    )}
    {(!fieldSettings || fieldSettings.volume) && <TextInputP
      name={intl.formatMessage(messages.volume)}
      inputName={'volume'}
      value={part.volume}
      onChange={(volume: string) => onChange({ ...part, volume })}
      permissions={checkPermission('volume')}
      validation={validations.volume}
      autoFocus={true}
    />}
    {(!fieldSettings || fieldSettings.issue) && <TextInputP
      name={intl.formatMessage(messages.issue)}
      inputName={'issue'}
      value={part.issue}
      onChange={(issue: string): void => onChange({ ...part, issue })}
      permissions={checkPermission('issue')}
      validation={validations.issue}
    />}
    {(!fieldSettings || fieldSettings.pageFirst) && <TextInputP
      name={intl.formatMessage(messages.pageFirst)}
      inputName={'pageFirst'}
      value={part.pageFirst}
      onChange={(pageFirst: string): void => onChange({ ...part, pageFirst })}
      permissions={checkPermission('page_first')}
      validation={validations.page_first}
    />}
    {(!fieldSettings || fieldSettings.pageLast) && <TextInputP
      name={intl.formatMessage(messages.pageLast)}
      inputName={'pageLast'}
      value={part.pageLast}
      onChange={(pageLast: string): void => onChange({ ...part, pageLast })}
      permissions={checkPermission('page_last')}
      validation={validations.page_last}
    />}
    {(!fieldSettings || fieldSettings.articleId) && <TextInputP
      name={intl.formatMessage(messages.articleId)}
      placeholder={window.location.pathname.includes('hasPart') ? undefined : intl.formatMessage(messages.articleIdPlaceholder)}
      inputName={'articleId'}
      value={part.articleId}
      onChange={(articleId: string): void => onChange({ ...part, articleId })}
      permissions={checkPermission('article_id')}
      validation={validations.article_id}
    />}
  </>;
}
