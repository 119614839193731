import * as React from 'react';
import { keyframes } from '@emotion/react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
export interface Props {
  className?: string;
  hidden: boolean;
  key?: string;
}

const load = keyframes`
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
`;


export const LoaderBar = ({ className = '', hidden = false }: Props) => (
  <div className={[className, hidden ? 'hidden' : null].join(' ')} sx={{
    "&.hidden": {
      visibility: "hidden"
    },
    height: "4px",
    width: "100%",
    position: "relative",
    overflow: "hidden",
    backgroundColor: 'secondary',
    "&:before": {
      display: "block",
      position: "absolute",
      content: '""',
      left: "-200px",
      width: "200px",
      height: "4px",
      backgroundColor: 'primary',
      animation: `${load} 2.5s linear infinite`,
    }
  }} />
);
