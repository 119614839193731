import {
  PersonDTO,
  ProjectToPersonDTO,
  ReferencedPersonNameDTO,
} from 'api/core/models';
import { ReadWritePermissions } from 'Auth/components/DataModelGate';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { PersonSuggest } from 'Edit/components/auto-complete/PersonSuggest';
import React, { ReactNode } from 'react';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { DefaultProjectPerson } from '../../../data/emptyModels';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';
import { ValidationResult } from '../../../validation/interfaces';
import { defineMessages, IntlShape, useIntl } from 'react-intl';

interface Props {
  checkPermission?: (child?: string) => ReadWritePermissions;
  projectPersons: ProjectToPersonDTO[];
  onChange: (po: ProjectToPersonDTO[]) => void;
  validation: ValidationResult;
}

const messages = defineMessages({
  person: { id: 'edit.project.person' },
});

export const ProjectPersons: React.FC<Props> = ({ checkPermission, projectPersons, onChange, validation }: Props) => {
  const intl: IntlShape = useIntl();
  return (
    <div className="form form-highlight">
      <MultiInputControls<ProjectToPersonDTO>
        add
        remove
        defaultRow={DefaultProjectPerson}
        lines={projectPersons}
        onChange={onChange}
        renderLines={(projectPerson: ProjectToPersonDTO, onChange2: (po: ProjectToPersonDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number) => (
          <div>
            {checkPermission('person').read && (
              <div className="form">
                <PersonSuggest
                  autoFocus={true}
                  name={intl.formatMessage(messages.person)}
                  required={isRequired(checkPermission('person'))}
                  canDelete={false}
                  disabled={!checkPermission('person').write}
                  single
                  validation={validation?.children[idx]?.person}
                  tags={!!projectPerson.person ? [projectPerson.person].map((projPerson: PersonDTO): ReferencedPersonNameDTO => ({
                    personId: projPerson.id,
                    givenname: projPerson.personNames[0].givenname,
                    surname: projPerson.personNames[0].surname
                  })) : []}
                  onChange={(ref: ReferencedPersonNameDTO[]) => {
                    onChange2({ ...projectPerson, person: ref[0] ? {
                        id: ref[0].id,
                        personNames: [{
                          personId: ref[0].id,
                          givenname: ref[0].givenname,
                          surname: ref[0].surname,
                        }]
                      } : null
                    });
                  }}
                />
              </div>
            )}
            {!!actions.delete && (
              <div className='deleteButton'>
                <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
              </div>
            )}
          </div>
        )}
        renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactNode => (
          <div className='addButton'>
            <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
          </div>
        )}
      />
    </div>
  );
};
