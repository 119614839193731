import * as React from 'react';
import { useEffect, useState } from 'react';
import { connectOAuth2 } from 'Auth/utils/connect';
import { StatsApi } from 'api/core';
import { Statistics, StatisticsProps } from 'HomePage/components/Statistics';
import { useColorMode } from 'theme-ui';
import getLogger from '../../log';

const logger = getLogger('HomePage/Statistics/container');

const theme2topOrga = {
  "rub": "\"Ruhr-Universität Bochum\"",
  "tudo": "\"Technische Universität Dortmund\"",
  "hhu": "\"Heinrich-Heine-Universität Düsseldorf\"",
  "uwh": "\"Universität Witten/Herdecke\""
};

function InnerStatistics(props) {

  const [workCount, setWorkCount] = useState(null);
  const [publicationsWosCount, setPublicationsWosCount] = useState(null);
  const [publicationsScopusCount, setPublicationsScopusCount] = useState(null);
  const [publicationsPmidCount, setPublicationsPmidCount] = useState(null);
  const [viewLoading, setViewLoading] = useState(false);
  const [viewError, setViewError] = useState(null);

  const statsApi = props.apiWithAuth(new StatsApi());
  const [theme] = useColorMode();
  const topOrga = theme2topOrga[theme];
  const orga = theme;

  useEffect(() => {
    setViewLoading(true);
    Promise.all([
      statsApi.countTotalWorks({ topOrga }),
      statsApi.countTotalWorks({ topOrga, externalIdentifier: 'wos' }),
      statsApi.countTotalWorks({ topOrga, externalIdentifier: 'scopus' }),
      statsApi.countTotalWorks({ topOrga, externalIdentifier: 'pmid' }),
    ])
    .then((results: [number, number, number, number]): void => {
      setWorkCount(results[0]);
      setPublicationsWosCount(results[1]);
      setPublicationsScopusCount(results[2]);
      setPublicationsPmidCount(results[3]);
    })
    .catch((e: Error): void => {
      logger.error(e);
      setViewError(e.message);
    })
    .finally((): void => {
      setViewLoading(false);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderProps: StatisticsProps = {
    workCount,
    publicationsWosCount,
    publicationsScopusCount,
    publicationsPmidCount,
    viewLoading,
    viewError,
    orga
  };

  return React.createElement(Statistics, renderProps);
}

export const ConnectedStatistics = connectOAuth2(InnerStatistics);

export default ConnectedStatistics;
