import * as React from 'react';
import Tooltip, { TooltipProps as TTP } from 'react-tooltip-lite';
import { HierarchyEntity, HierarchyMapEntry } from '../types';

export type TooltipProps = TTP;

export interface Props {
  entity: HierarchyEntity;
  id: number;
  name: string;
  state: 'loading' | 'present' | 'missing' | 'error';
  hierarchy?: HierarchyMapEntry[];
  children?: any;

  loadHierarchy: () => void;

  extraProps?: Partial<TooltipProps>;
}

export function HierarchyTooltip (props: Props) {
  const { loadHierarchy, state, children, extraProps } = props;

  const renderHierarchy = (): string => {
    const { name, hierarchy, state } = props;

    if ('missing' === state || undefined === hierarchy || 0 === hierarchy.length) {
      // Empty hierarchy
      return name;
    }

    return hierarchy.map((e) => e.name).join(' > ');
  };

  const handleMouseOver = () => {
    if ('missing' === state) {
      loadHierarchy();
    }
  };

  const classes = ['hierarchy-tooltip', state];

  // TODO: Insert nicer loading indicator
  const loader = 'loading' === state ? <span className="">!</span> : <></>;

  return (
    <span className={classes.join(' ')} onMouseOver={handleMouseOver}>
      <Tooltip
        content={
          <>
            {renderHierarchy()}
            {loader}
          </>
        }
        direction="up"
        padding="5px"
        hoverDelay={100}
        useDefaultStyles={true}
        {...extraProps}
      >
          {children}
      </Tooltip>
    </span>
  );
}
