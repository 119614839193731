import React, { ReactElement } from 'react';
import { ProjectDTO, WorkDTO, ExternalIdDTO } from '../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultExternalId } from '../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../BaseLayout/components/form/MultiInput/Buttons';
import { Flex } from '@theme-ui/components';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../validation/interfaces';

const messages = defineMessages({
  id: { id: 'work.attr.externalId.attr.externalId' },
  idHelpPerson: { id: 'person.attr.externalId.attr.externalId.help' },
  idHelpOrga: { id: 'orga.attr.externalId.attr.externalId.help' },
  idHelpProject: { id: 'project.attr.externalId.attr.externalId.help' },
  idHelpWork: { id: 'work.attr.externalId.attr.externalId.help' },
  type: { id: 'work.attr.externalId.attr.type' },
});

interface ExternalIdsProps {
  modifiedModel: WorkDTO | ProjectDTO;
  updateField: (key: string, value: ExternalIdDTO[]) => void;
  entity: string;
  pubtypeGroup?: string;
  list: string;
  validation: ValidationResult;
}

function ExternalIds({ modifiedModel, updateField, entity, pubtypeGroup, list, validation }: ExternalIdsProps) {
  const intl: IntlShape = useIntl();
  const idHelp: string = window.location.pathname.includes('works') ? intl.formatMessage(messages.idHelpWork) :
  window.location.pathname.includes('person') ? intl.formatMessage(messages.idHelpPerson) :
  window.location.pathname.includes('project') ? intl.formatMessage(messages.idHelpProject) :
  intl.formatMessage(messages.idHelpOrga);

  return (
    <DataModelGate entity={entity} field="external_ids">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        <MultiInputControls<ExternalIdDTO>
          lines={modifiedModel.externalIds}
          onChange={(externalIds: ExternalIdDTO[]): void => updateField('externalIds', externalIds)}
          defaultRow={DefaultExternalId}
          remove
          add
          renderLines={(eid: ExternalIdDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
            <div className='fieldRow'>
              <Flex variant="layout.multiRow" sx={{gap: '12px'}}>
                <SelectInputP
                  permissions={checkPermission('type')}
                  onChange={(value: string) => onChange2({ ...eid, type: value })}
                  value={eid.type}
                  name={intl.formatMessage(messages.type)}
                  inputName={'type'}
                  list={list}
                  listPartition={pubtypeGroup}
                  validation={validation?.children[idx]?.type}
                  autoFocus={true}
                />
                <TextInputP
                  name={intl.formatMessage(messages.id)}
                  help={idHelp}
                  disabled={!eid.type}
                  permissions={checkPermission('external_id')}
                  // TODO Pattern in validation checken?
                  pattern={eid.type && eid.type.toLowerCase() === 'isbn' ? '^(97(8|9))?[0-9]{9}([0-9]|X)$' : undefined}
                  value={eid.externalId}
                  onChange={(externalId: string) => onChange2({ ...eid, externalId: eid.type.toLowerCase() === 'isbn' ? externalId.replace(/[^0-9xX]+/g, '').replace(/[x]{1}/g, 'X') : externalId })}
                  validation={validation?.children[idx]?.external_id}
                />
              </Flex>
              {!!actions.delete && (
                <div className='deleteButton'>
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          )}
          renderEnd={(actionAdd) => (
            <div className='addButton'>
              <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
            </div>
          )}
        />
      )}
    </DataModelGate>
  );
}

export function BorderlessExternalId({ modifiedModel, updateField, entity, pubtypeGroup, list, validation }: ExternalIdsProps): React.JSX.Element {
  return (
    <div>
      <Styled.h2>
        <FormattedMessage id="entity.attr.externalId" />
      </Styled.h2>
      <ExternalIds modifiedModel={modifiedModel} updateField={updateField} entity={entity} list={list} validation={validation} pubtypeGroup={pubtypeGroup}></ExternalIds>
    </div>
  );
}

export function BorderedExternalIds({ modifiedModel, updateField, entity, pubtypeGroup, list, validation }: ExternalIdsProps): React.JSX.Element {
  return (
    <div>
      <Styled.h2>
        <FormattedMessage id="entity.attr.externalId" />
      </Styled.h2>
      <Border>
        <ExternalIds modifiedModel={modifiedModel} updateField={updateField} entity={entity} list={list} validation={validation} pubtypeGroup={pubtypeGroup}></ExternalIds>
      </Border>
    </div>
  );
}
