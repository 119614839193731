import React, { ReactElement } from 'react';
import { WorkGeoLocationPointDTO, WorkGeoLocationPolygonDTO } from '../../../../../api/core/models';
import { GeoLocationPoints } from './GeoLocationPoints';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { Styled } from 'theme-ui';
import { FieldRow } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../../validation/interfaces';

const messages = defineMessages({
  latitude: { id: 'work.attr.geoLocationPolygon.attr.latitude' },
  longitude: { id: 'work.attr.geoLocationPolygon.attr.longitude' },
});

interface GeoLocationPolygonProps {
  geoLocationPolygon?: WorkGeoLocationPolygonDTO;
  onChange?: (geo: WorkGeoLocationPolygonDTO) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

function InnerGeoLocationPolygon({
  geoLocationPolygon,
  onChange,
  intl,
  checkPermission,
  validation
}: GeoLocationPolygonProps & WrappedComponentProps): ReactElement {

  return (
    <div sx={{mt:'-0.25em'}}>
      <Styled.h3>
        <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon.attr.geoLocationInPolygonPoints" />
      </Styled.h3>
      <FieldRow>
        <TextInputP
            name={intl.formatMessage(messages.latitude)}
            value={geoLocationPolygon.inLatitude ? geoLocationPolygon.inLatitude : ''}
            inputName="latitude"
            onChange={(inLatitude: string): void => onChange({ ...geoLocationPolygon, inLatitude })}
            permissions={checkPermission('work_geo_location_polygon.in_latitude')}
            validation={validation?.child?.in_latitude}
        />
        <TextInputP
          name={intl.formatMessage(messages.longitude)}
          value={geoLocationPolygon.inLongitude ? geoLocationPolygon.inLongitude : ''}
          inputName="longitude"
          onChange={(inLongitude: string): void => onChange({ ...geoLocationPolygon, inLongitude })}
          permissions={checkPermission('work_geo_location_polygon.in_longitude')}
          validation={validation?.child?.in_longitude}
        />
      </FieldRow>

      <Styled.h3>
        <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon.attr.geoLocationPolygonPoint" />
      </Styled.h3>
      <GeoLocationPoints
        onChange={(workGeoLocationPoints: WorkGeoLocationPointDTO[]) =>
          onChange({
            ...geoLocationPolygon,
            workGeoLocationPoints,
          })
        }
        geoLocationPoints={geoLocationPolygon.workGeoLocationPoints ? geoLocationPolygon.workGeoLocationPoints : []}
        checkPermission={checkPermission}
        validation={validation?.child?.work_geo_location_points}

      />
    </div>
  );
}

export const GeoLocationPolygon = injectIntl(InnerGeoLocationPolygon);
