import { useBreakpointIndex } from '@theme-ui/match-media';
import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const FooterDesktopStyling: React.FC = ({ children }) => {
    const breakpoint = useBreakpointIndex({ defaultIndex: 0 });
    return (
        <div
        sx={{
            ".footer": {
                pt: 4,
                pr: 4,
                borderTopWidth: "1px",
                borderTopColor: "light",
                borderTopStyle: "solid",
                fontSize: 3,
                alignItems: 'center',
                ".logos": {
                    flexDirection: 'column',
                    width: breakpoint <= 3 ? '25em' : '30em',
                    img: {
                        mx: breakpoint <= 3 ? '50px' : 10,
                        my: 6,
                        width: breakpoint <= 3 ? '224px' : '300px',
                    },
                },
                ".info": {
                    width: '100%',
                    fontWeight: 'bold',
                    color: 'headline',
                    fontSize: breakpoint <= 3 ? '18px' : 3,
                    pl: breakpoint <= 3 ? 0 : ''
                },
                ".links": {
                    justifyContent: 'space-between',
                    mt: 3,
                    fontWeight: 'bold',
                }
            }
        }}
        >
            {children}
        </div>
    );
};