import React, { ReactElement, ReactNode } from 'react';
import {
  WorkDTO,
  WorkIsPartOfDTO as Part,
} from '../../../../../api/core/models';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { MultiInputControls } from '../../../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { DefaultWorkPart } from '../../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../../BaseLayout/components/form/MultiInput/Buttons';
import { WorkPart } from './WorkPart';
import { PubtypeServiceApi } from '../../../../../api/pubtype';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../../validation/interfaces';

interface HasPartProps {
  api: PubtypeServiceApi;
  modifiedModel: WorkDTO;
  updateField: (key: string, value: Part[]) => void;
  entity: string;
  pubtype: string;
  validation: ValidationResult;
}

export function HasPart({ api, modifiedModel, updateField, entity, pubtype, validation }: HasPartProps): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <DataModelGate entity={entity} field="work_has_parts">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        checkPermission().read &&
        <div className="col">
          <Styled.h2>
            <FormattedMessage id="work.attr.hasPart" />
          </Styled.h2>
          <Border>
            <MultiInputControls<Part>
              lines={modifiedModel.workHasParts ? modifiedModel.workHasParts : []}
              onChange={(hp: Part[]): void => updateField('workHasParts', hp)}
              add
              remove
              defaultRow={DefaultWorkPart}
              renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
                <div className="addButton">
                  <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
                </div>
              )}
              renderLines={(part: Part, onLineChange: (part: Part) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactNode => (
                <div>
                  <WorkPart
                    api={api}
                    part={part}
                    pubtypes={{ part: part?.referencedWork?.pubtype, parent: pubtype }}
                    checkPermission={checkPermission}
                    validations={validation?.children[idx]}
                    onChange={onLineChange}
                    intl={intl}
                    actions={actions}
                  />
                  <div sx={{mb: "1em"}}>
                    <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                  </div>
                </div>
            )}
            />
          </Border>
        </div>
      )}
    </DataModelGate>
  );
}
