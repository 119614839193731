import queryString from 'query-string';
import { SearchFacet as ApiSearchFacet } from '../api/core/models';
import { defaultFacetPageCount, defaultResultPageLimit, facetConfiguration } from './data';
import {
  DashboardType,
  defaultSearchQuery,
  FacetEntry,
  FacetLabel,
  SearchFacet,
  SearchFacetValueMap,
  SearchQuery,
  SearchSort,
} from './types';
import { escape } from 'lucene-escape-query';
import getLogger from '../log';

const logger = getLogger('Dashboard/util');

export const mapApiFacets = (
  dashboard: DashboardType,
  facets: ApiSearchFacet[],
  resultCount: number
): SearchFacet[] => {
  return facetConfiguration[dashboard].map((config) => {
    const single = config.single !== undefined ? config.single : false;
    const tristate = config.tristate !== undefined ? config.tristate : false;
    const pageLimit = config.pageLimit !== undefined ? config.pageLimit : defaultFacetPageCount;

    const facet = facets.find((f) => f.name === config.name);

    const entries: FacetEntry[] = [];
    if (facet !== undefined) {
      for (const value in facet.entries) {
        if (!!facet.entries.hasOwnProperty(value)) {
          let label: FacetLabel | FacetLabel[];
          if (config.name === 'f_oa') {
            const _value = value.split(' - ');
            label = [config.entryLabelFilter(_value[0]), ' (', config.entryLabelFilter_2(_value[1]), ')'];
          } else {
            label = config.entryLabelFilter !== undefined ? config.entryLabelFilter(value) : value;
          }
          const eCount = facet.entries[value];
          entries.push({
            value,
            label,
            count: eCount,
            negate: false,
          });

          if (tristate) {
            entries.push({
              value,
              label,
              count: resultCount - eCount,
              negate: true,
            });
          }
        }
      }
    }
    const count = facet !== undefined ? facet.count : 0;

    if (undefined !== config.postSort) {
      entries.sort(config.postSort); // Sorts in-place!
    }

    return {
      name: config.name,
      label: config.label,
      type: config.type,
      count,
      entries,
      single,
      tristate,
      pageLimit,
    };
  });
};

export const mapFacetEntriesToQuery = (facets: SearchFacetValueMap<FacetEntry[]>): string[] => {
  const formatDate = (date?: Date) => {
    if (undefined === date) {
      return '*';
    }
    date.setUTCMilliseconds(0);
    return date.toISOString().replace('.000Z', 'Z');
  };

  const fq: string[] = [];
  for (const facet in facets) {
    if (!facets.hasOwnProperty(facet)) {
      continue;
    }

    const entries = facets[facet];
    const fqParams = entries.map((entry) => {
      const key = ('negate' in entry && entry.negate ? '-' : '') + facet;
      let value = '';
      if ('start' in entry || 'end' in entry) {
        const start = formatDate(entry.start);
        const end = formatDate(entry.end);
        value = `[${start} TO ${end}]`;
      } else if ('value' in entry) {
        value = String(entry.value);
      } else {
        logger.find('mapFacetEntriesToQuery').error('Unrecognized entry type', entry, 'for facet', facet);
      }
      return `${key}:"${value}"`;
    });

    fq.push(...fqParams);
  }
  return fq;
};

export const mapQueryToFacetEntries = (fq: string[]): SearchFacetValueMap<FacetEntry[]> => {
  const parseDate = (date: string): Date | undefined => {
    if (date === '*') {
      return undefined;
    }
    return new Date(date);
  };

  // eslint-disable-line max-len
  const dateRangeRegexp = /^\[(\*|\d{1,4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}Z) TO (\*|\d{1,4}-\d{1,2}-\d{1,2}T\d{1,2}:\d{1,2}:\d{1,2}Z)\]$/;
  const f: SearchFacetValueMap<FacetEntry[]> = {};

  fq.forEach((e) => {
    if (undefined === e) {
      return;
    }
    const idx = e.indexOf(':');
    if (idx < 1 || idx + 1 >= e.length) {
      return;
    }
    const k = e.slice(0, idx);
    const v = e.slice(idx + 1);
    if (v.length > 0) {
      const matches = dateRangeRegexp.exec(v);
      if (null !== matches) {
        const start = parseDate(matches[1]);
        const end = parseDate(matches[2]);
        const old = f.hasOwnProperty(k) ? f[k] : [];
        f[k] = [...old, { start, end }];
      } else {
        const negate = k.startsWith('-');
        const facet = negate ? k.slice(1) : k;
        const value = v.slice(1, -1);
        const old = f.hasOwnProperty(facet) ? f[facet] : [];
        f[facet] = [...old, { value, count: 0, label: '', negate }];
      }
    }
  });

  return f;
};

export const mapFacetOffsetsToQuery = (offsets: SearchFacetValueMap<number>): string => {
  const fo: string[] = [];

  for (const facet in offsets) {
    if (!offsets.hasOwnProperty(facet)) {
      continue;
    }

    const offset = offsets[facet];
    if (offset === 0) {
      continue;
    }
    fo.push(`foffset[${encodeURIComponent(facet)}]=${offset}`);
  }

  return fo.join('&');
};

export const mapQueryToQuerystring = (query: Partial<SearchQuery>): string => {
  // Stringify query
  return queryString.stringify({
    q: query.q === defaultSearchQuery.q ? undefined : escape(query.q),
    limit: query.limit === defaultSearchQuery.limit ? undefined : query.limit,
    offset: query.offset === defaultSearchQuery.offset ? undefined : query.offset,
    sort: !!query.sort ? `${query.sort.field}~${query.sort.direction}` : undefined,
    fq: mapFacetEntriesToQuery(query.f),
  });
};

export const mapQuerystringToQuery = (qs: string): SearchQuery => {
  const parsed = queryString.parse(qs);

  const getField = (index: string): string => {
    if (Array.isArray(parsed[index])) {
      return parsed[index].length ? parsed[index][0] : undefined;
    }
    return parsed[index] as string;
  };

  // Return default query if querystring cannot be parsed
  if (!parsed) {
    return {
      q: undefined,
      offset: 0,
      limit: defaultResultPageLimit,
      f: {},
    };
  }
  // Extract query term parameter
  const q = getField('q');
  // Parse limit parameter or use default
  const limit = parsed.limit ? parseInt(String(parsed.limit), 10) : defaultResultPageLimit;
  // Parse offset parameter and clamp to multiple of limit, or use default
  const offset = Math.floor((parsed.offset ? parseInt(String(parsed.offset), 10) : 0) / limit) * limit;
  const rawSort = getField('sort');
  let sort: SearchSort = null;
  if (!!rawSort) {
    const [field, dir] = rawSort.split('~', 2);
    if (field.length === 0) {
      sort = {
        field: '',
        direction: '',
      };
    } else {
      sort = {
        field,
        direction: dir === 'asc' || dir === 'desc' ? dir : 'asc',
      };
    }
  }

  // Take the first fq parameter in query
  /*
  const fq: string = isArray(parsed.fq) ? parsed.fq.length ? parsed.fq[0] : undefined : parsed.fq;
  // Parse fq parameter as JSON
  const f: SearchFacetValueMap<FacetEntry[]> = parsed.fq ? JSON.parse(fq) : [];
  // Parse Date entries
  for (const k in f) {
    if (!f.hasOwnProperty(k)) { continue; }
    for (const entry of f[k]) {
      if ((entry as DateFacetEntry).start !== undefined) {
        (entry as DateFacetEntry).start = new Date((entry as DateFacetEntry).start);
      }
      if ((entry as DateFacetEntry).end !== undefined) {
        (entry as DateFacetEntry).end = new Date((entry as DateFacetEntry).end);
      }
    }
  }
  */
  const f = mapQueryToFacetEntries(Array.isArray(parsed.fq) ? parsed.fq : [parsed.fq]);

  return {
    q,
    offset,
    limit,
    sort,
    f,
  };
};
