import React, { ReactElement } from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { WorkRelatedIdentifierDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkRelatedIdentifier } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';
import { Entity } from 'Detail/components/work/utils';

const messages = defineMessages({
  relationType: { id: 'work.attr.relatedIdentifierExternal.attr.type' },
  relatedIdentifier: {
    id: 'work.attr.relatedIdentifierExternal.attr.relatedIdentifier',
  },
  relatedIdentifierHelp: {
    id: 'work.attr.relatedIdentifierExternal.attr.relatedIdentifier.help',
  },
});

interface RelatedIdentifiersExternalProps {
  externals?: WorkRelatedIdentifierDTO[];
  listPartition: string;
  onChange: (data: WorkRelatedIdentifierDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

function InnerRelatedIdentifiersExternal({
  externals,
  listPartition,
  onChange,
  intl,
  checkPermission,
  validation
}: RelatedIdentifiersExternalProps & WrappedComponentProps): ReactElement {
  return (
    <div className="col">
      <Styled.h2>
        <FormattedMessage id="work.attr.relatedIdentifiersExternal" />
      </Styled.h2>
      <Border>
        <MultiInputControls<WorkRelatedIdentifierDTO>
          lines={externals}
          onChange={onChange}
          add
          remove
          defaultRow={DefaultWorkRelatedIdentifier}
          renderLines={(
            external: WorkRelatedIdentifierDTO,
            onChange2: (data: WorkRelatedIdentifierDTO) => void,
            actions: { [p: string]: (e: any) => void },
            idx: number
          ): ReactElement => (
            <div className="fieldRow" key={idx}>
              <TextInputP
                name={intl.formatMessage(messages.relatedIdentifier)}
                help={intl.formatMessage(messages.relatedIdentifierHelp)}
                value={external.relatedIdentifier}
                onChange={(relatedIdentifier: string): void => onChange2({ ...external, relatedIdentifier })}
                permissions={checkPermission('related_identifier')}
                validation={validation?.children[idx]?.related_identifier}
                autoFocus={true}
              />
              <SelectInputP
                onChange={(relationType: string) => onChange2({ ...external, relationType })}
                value={external.relationType}
                list={'relation_type_list'}
                listPartition={listPartition.toLowerCase() == Entity.RESEARCH_DATA_GROUP ? 'research_data_group_external' : 'generic_external'}
                name={intl.formatMessage(messages.relationType)}
                inputName="role"
                permissions={checkPermission('relation_type')}
                validation={validation?.children[idx]?.relation_type}
              />
              {!!actions.delete && (
                <div className="deleteButton">
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          )}
          renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
            <div className="addButton">
              <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
            </div>
          )}
        />
      </Border>
    </div>
  );
}

export const RelatedIdentifiersExternal = injectIntl(InnerRelatedIdentifiersExternal);
