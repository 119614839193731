/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchRequestSort,
    SearchRequestSortFromJSON,
    SearchRequestSortFromJSONTyped,
    SearchRequestSortToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchRequest
 */
export interface SearchRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchRequest
     */
    q?: string;
    /**
     * 
     * @type {SearchRequestSort}
     * @memberof SearchRequest
     */
    sort?: SearchRequestSort;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    fq?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SearchRequest
     */
    foffset?: { [key: string]: number; };
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchRequest
     */
    only?: Array<SearchRequestOnlyEnum>;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchRequest
     */
    limit?: number;
}

export function SearchRequestFromJSON(json: any): SearchRequest {
    return SearchRequestFromJSONTyped(json, false);
}

export function SearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'q': !exists(json, 'q') ? undefined : json['q'],
        'sort': !exists(json, 'sort') ? undefined : SearchRequestSortFromJSON(json['sort']),
        'fq': !exists(json, 'fq') ? undefined : json['fq'],
        'foffset': !exists(json, 'foffset') ? undefined : json['foffset'],
        'only': !exists(json, 'only') ? undefined : json['only'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function SearchRequestToJSON(value?: SearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'q': value.q,
        'sort': SearchRequestSortToJSON(value.sort),
        'fq': value.fq,
        'foffset': value.foffset,
        'only': value.only,
        'offset': value.offset,
        'limit': value.limit,
    };
}

/**
* @export
* @enum {string}
*/
export enum SearchRequestOnlyEnum {
    Results = 'results',
    Facets = 'facets'
}


