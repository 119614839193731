import { Container } from 'unstated';
import { DashboardType, SearchQuery } from '../../Dashboard/types';
import { mapQueryToQuerystring } from '../../Dashboard/utils';
import { getSearchContextUrl } from '../../data';
import { history } from '../../historyUtil';
import { SearchContext } from '../../types';

// Logger
// import getLogger from '../../log';
// const logger = getLogger('Search/container');

export const navigateWithQuery = (
  dashboard: DashboardType,
  query: Partial<SearchQuery>,
  replaceHistory: boolean = false
): void => {
  const qs: string = mapQueryToQuerystring(query);
  // logger.find('navigateWithQuery').debug('changing to', qs);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  (replaceHistory ? history.replace : history.push)({
    pathname: getSearchContextUrl(dashboard),
    search: qs,
  });
};

export interface SearchState {
  context: SearchContext;
  term: string;
  visible: boolean;
  dropDownVisible: boolean;
  visibleSearchTerm: string;
}

const initialState: SearchState = {
  context: DashboardType.WORKS,
  term: '',
  visible: true,
  dropDownVisible: false,
  visibleSearchTerm: '',
};

export class SearchContainer extends Container<SearchState> {
  public state: SearchState = initialState;

  public submitSearch(): void {
    // Wenn der Suchstring oder eine Teilstring bei einer Suche mit AND oder OR mit "isbn:" beginnt, werden
    // aus der nachfolgenden ISBN alle "-" entfernt und der neue String als Suchstring gesetzt.
    void this.setSearchTerm(
      this.state.term
        .split(' AND ')
        .map((at: string): string =>
          at
            .split(' OR ')
            .map((ot: string): string => {
              const kv = ot.split(':');
              if (kv[0].trim().toUpperCase().startsWith('ISBN')) {
                while (kv[1].includes('-')) {
                  kv[1] = kv[1].replace('-', '');
                }
              }
              return kv.join(':');
            })
            .join(' OR ')
        )
        .join(' AND ')
    ).then(() => navigateWithQuery(this.state.context, { q: this.state.term }, false));
  }

  public async setSearchTerm(term: string): Promise<void> {
    await this.setState({ term });
  }

  public async setContext(context: SearchContext): Promise<void> {
    await this.setState({ context });
  }

  public async setVisible(visible: boolean): Promise<void> {
    await this.setState({ visible });
  }

  public async resetVisible(): Promise<void> {
    await this.setState({ visible: initialState.visible });
  }

  public async setDropDownVisible(dropDownVisible: boolean): Promise<void> {
    await this.setState({ dropDownVisible });
  }

  public async setVisibleSearchTerm(visibleSearchTerm: string): Promise<void> {
    await this.setState({ visibleSearchTerm });
  }
}
