import {
  AdminDataWorkOpenAccessFundedDTO, AdminDataWorkOrcidSyncDTO,
  ProjectDTO,
  OrganisationDTO,
  PersonDTO,
  WorkDTO,
  EmploymentPeriodDTO,
  EventDTO,
  EventPlaceDTO,
  ExternalIdDTO,
  ProjectFundingReferenceDTO,
  ProjectOtherPartnerDTO, ProjectToPersonDTO, ProjectOtherNameDTO,
  OrganisationToPersonDTO,
  PersonEmailDTO,
  PersonNameDTO,
  PersonToOrganisationDTO,
  PersonStatusDTO,
  SubjectDTO,
  UrlDTO,
  WorkDateTypeDTO,
  WorkDescriptionDTO,
  WorkGeoLocationDTO,
  WorkGeoLocationPointDTO, WorkProjectDTO,
  WorkProjectOtherDTO,
  WorkIsPartOfOtherDTO as WorkIsPartOfOtherModel,
  WorkKeywordDTO, WorkLanguageDTO, WorkOrganisationDTO, WorkOrganisationOtherDTO,
  WorkOtherTitleDTO,
  WorkPersonDTO,
  WorkPersonOtherDTO,
  WorkPlaceDTO,
  WorkPublisherDTO,
  WorkPublisherPlaceDTO,
  WorkRelatedIdentifierDTO,
  WorkRelatedIdentifierInternalDTO, WorkIsPartOfDTO, ProjectFunderDTO, WorkGeoLocationPolygonDTO, OrganisationOtherNameDTO,
} from '../../api/core';
import { InstitutionalId } from '../components/fields/InstitutionalIds';

export const emptyModelPerson: PersonDTO = {
  id: null,
  note: null,
  personEmails: [],
  personNames: [],
  personStatuses: [],
  urls: [],
  externalIds: [],
  personInstitutionalIds: [],
  personOrganisations: [],
  projects: [],
  adminDataPerson: {
    editorialStatus: null,
    hidden: null,
    dataLastSupplied: undefined,
    personChecked: undefined,
  },
};

export const emptyModelProject: ProjectDTO = {
  id: null,
  endDate: undefined,
  name: null,
  otherNames: [],
  note: null,
  startDate: undefined,
  parents: [],
  childs: [],
  successor: [],
  predecessors: [],
  partner: [],
  projectPersons: [],
  externalIds: [],
  projectInstitutionalIds: [],
  urls: [],
  projectOtherPartners: [],
  projectSubjects: [],
  projectFundingReferences: [],
  adminDataProject: {
    editorialStatus: null,
    hidden: null,
  },
};

export const emptyModelOrg: OrganisationDTO = {
  id: null,
  endDate: undefined,
  name: null,
  otherNames: [],
  note: null,
  startDate: undefined,
  projects: [],
  parents: [],
  childs: [],
  successor: [],
  predecessors: [],
  organisationPersons: [],
  organisationSubjects: [],
  adminDataOrganisation: {
    editorialStatus: null,
    hidden: null
  },
  externalIds: [],
  organisationInstitutionalIds: [],
  urls: [],
};

export const emptyModelWork: WorkDTO = {
  id: null,
  applicationCountry: null,
  applicationNumber: null,
  edition: null,
  formSubject: null,
  format: null,
  grantNumber: null,
  issued: null,
  medium: null,
  note: null,
  number: null,
  numberOfPages: null,
  numberOfVolumes: null,
  patentFamily: null,
  patentId: null,
  patentType: null,
  peerReviewed: 'no_value',
  priorityCountry: null,
  priorityNumber: null,
  priorityPatentApplication: false,
  publicationNumber: null,
  publicationStatus: null,
  pubtype: null,
  resourceType: null,
  size: null,
  subseriesTitle: null,
  subtitle: null,
  title: null,
  titleSupplement: null,
  version: null,
  dataVersion: null,
  adminDataWork: {
    editorialStatus: null,
    hidden: null,
    obligatoryDelivery: false,
    projectsChecked: false,
    organisations: [],
    projects: [],
    adminDataWorkOpenAccess: null,
    adminDataWorkOrcidSyncs: [],
    license: null,
    licenseManagementStatus: null,
    licenseText: null
  },
  events: [],
  externalIds: [],
  urls: [],
  workKeywords: [],
  workPublishers: [],
  workPersons: [],
  workOrganisations: [],
  workOrganisationOthers: [],
  workProjects: [],
  workProjectOthers: [],
  workDescriptions: [],
  workDateTypes: [],
  workIsPartOfs: [],
  workIsPartOfOthers: [],
  workLanguages: [],
  workOtherTitles: [],
  workPersonOthers: [],
  workPlaces: [],
  externalRelatedIdentifiers: [],
  internalRelatedIdentifiersSource: [],
  internalRelatedIdentifiersTarget: [],
  workSubjects: [],
  workTitleAbbreviations: [],
  workHasParts: [],
  workGeoLocations: [],
};

export const DefaultWorkPerson: WorkPersonDTO = {
  role: '',
  sequence: null
};
export const DefaultWorkPersonOther: WorkPersonOtherDTO = {
  role: '',
  sequence: null,
  surname: '',
  givenname: ''
};

export const DefaultExternalId: ExternalIdDTO = {
  type: null,
  externalId: null
};

export const DefaultProjectFundingRef: ProjectFundingReferenceDTO = {};

export const DefaultProjectFunder: ProjectFunderDTO = {
  funderIdentifier: '',
  funderIdentifierType: null
};

export const DefaultInstitutionalId: InstitutionalId = {};

export const DefaultSubject: SubjectDTO = {};

export const DefaultProjectOtherPartner: ProjectOtherPartnerDTO = {
  otherPartner: null
};

export const DefaultPersonStatus: PersonStatusDTO = {
  affiliation: null,
  status: null
};

export const DefaultUrl: UrlDTO = {
  url: null,
  type: null
};

export const DefaultOrganisationPerson: OrganisationToPersonDTO = {};

export const DefaultOrganisationOtherName: OrganisationOtherNameDTO = {
  id: undefined,
  otherName: ''
};

export const DefaultProjectPerson: ProjectToPersonDTO = {};

export const DefaultProjectOtherName: ProjectOtherNameDTO = {
  id: undefined,
  otherName: ''
};

export const DefaultPersonEmail: PersonEmailDTO = {
  id: null,
  email: null,
  type: null
};

export const DefaultEmploymentPeriod: EmploymentPeriodDTO = {};

export const DefaultPersonName: PersonNameDTO = {
  id: undefined,
  givenname: '',
  surname: '',
  isMain: false,
  salutation: ''
};

export const DefaultPersonOrganisation: PersonToOrganisationDTO = {};

export const DefaultEventPlace: EventPlaceDTO = {};

export const DefaultAdminDataWorkOpenAccessFunded: AdminDataWorkOpenAccessFundedDTO = {};

export const DefaultWorkGeoLocationPoint: WorkGeoLocationPointDTO = {
  latitude: '',
  longitude: ''
};

export const DefaultWorkGeoLocation: WorkGeoLocationDTO = {};
export const getDefaultWorkGeoLocation = (): WorkGeoLocationDTO => {
  const defaultWorkGeoLocation: WorkGeoLocationDTO = {...DefaultWorkGeoLocation};
  defaultWorkGeoLocation.workGeoLocationPolygon =  getDefaultWorkGeoLocationPolygon();
  return defaultWorkGeoLocation;
};

export const DefaultWorkGeoLocationPolygon: WorkGeoLocationPolygonDTO = {};
export const getDefaultWorkGeoLocationPolygon = (): WorkGeoLocationPolygonDTO => {
  const defaultWorkGeoLocationPolygon: WorkGeoLocationPolygonDTO = {...DefaultWorkGeoLocationPolygon};
  defaultWorkGeoLocationPolygon.workGeoLocationPoints = [{...DefaultWorkGeoLocationPoint},{...DefaultWorkGeoLocationPoint},{...DefaultWorkGeoLocationPoint},{...DefaultWorkGeoLocationPoint}];
  return defaultWorkGeoLocationPolygon;
};

export const DefaultWorkPlace: WorkPlaceDTO = {};

export const DefaultWorkDateType: WorkDateTypeDTO = {};

export const DefaultWorkDescription: WorkDescriptionDTO = {};

export const DefaultWorkPublisher: WorkPublisherDTO = {};

export const DefaultWorkPublisherPlace: WorkPublisherPlaceDTO = {};

export const DefaultWorkRelatedIdentifierInternal: WorkRelatedIdentifierInternalDTO = {};

export const DefaultWorkRelatedIdentifier: WorkRelatedIdentifierDTO = {};

export const DefaultWorkOtherTitle: WorkOtherTitleDTO = {};

export const DefaultWorkProjectOther: WorkProjectOtherDTO = {};

export const DefaultWorkIsPartOfOtherModel: WorkIsPartOfOtherModel = {};

export const DefaultWorkPart: WorkIsPartOfDTO = {};

export const DefaultWorkKeyword: WorkKeywordDTO = {};

export const DefaultWorkLanguage: WorkLanguageDTO = {};

export const DefaultAdminDataWorkOrcidSync: AdminDataWorkOrcidSyncDTO = {};

export const DefaultWorkOrganisation: WorkOrganisationDTO = {};

export const DefaultWorkOrganisationOther: WorkOrganisationOtherDTO = {};

export const DefaultWorkProject: WorkProjectDTO = {};

export const DefaultEvent: EventDTO = {};
