import React, { ReactElement } from 'react';
import { IntlShape, useIntl, WrappedComponentProps } from 'react-intl';
import { WorkPersonDTO, WorkPersonOtherDTO } from 'api/core/models';
import { WorkPersonAny, WorkPersonList } from './WorkPersonList';
import { WorkPersonAnyForm } from './WorkPersonForm';
import { messages } from '../data';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { Styled } from 'theme-ui';
import { ValidationResult } from '../../../../../validation/interfaces';

/** @jsx jsx */
import { jsx } from 'theme-ui';

interface WorkPersonsProps {
  workPersons: WorkPersonDTO[];
  workPersonOthers: WorkPersonOtherDTO[];
  onChangePerson: (patch: WorkPersonDTO[]) => void;
  onChangePersonOther: (patch: WorkPersonOtherDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  checkPermissionOther: CheckPermissionFunction;
  validations: {work_persons: ValidationResult; work_person_others: ValidationResult};
}

export const WorkPersons: React.FC<WorkPersonsProps> = ({
  workPersons,
  workPersonOthers,
  onChangePerson,
  onChangePersonOther,
  checkPermissionOther,
  checkPermission,
  validations
}): ReactElement => {
  const intl: IntlShape = useIntl();

  const wpas: WorkPersonAny[] = workPersons.map((person, idx): WorkPersonAny => ({type: 'workPerson', person, validation: validations.work_persons.children[idx]}))
    .concat(workPersonOthers.map((person, idx): WorkPersonAny => ({type: 'workPersonOther', person, validation: validations.work_person_others.children[idx]})))
    .sort((w1: WorkPersonAny, w2: WorkPersonAny): number => w1.person.sequence - w2.person.sequence);

  return (
    <div className="col"
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <Styled.h1
        sx={{
          textAlign: "left",
          mb:'0.25em',
          flex: "0 1 auto"
        }}
      >{intl.formatMessage(messages.people)}</Styled.h1>
      <div sx={{ flex: "1 1 auto" }}>
        <WorkPersonList
          list={wpas}
          onChange={(ws: WorkPersonAny[]): void => {
            const wp: WorkPersonDTO[] = [];
            const wpo: WorkPersonOtherDTO[] = [];
            for (const w of ws){
              if (w.type === 'workPerson'){
                wp.push(w.person);
              }
              if (w.type === 'workPersonOther'){
                wpo.push(w.person);
              }
            }
            onChangePerson(wp);
            onChangePersonOther(wpo);
          }}
        >
          {(item: WorkPersonAny, active: boolean, onChange): ReactElement => {
            return (
            <WorkPersonAnyForm
              key={item.person.id + (!!(item.person as WorkPersonDTO).personName ? "-internal" : "-other")}
              wpa={item}
              onChange={onChange}
              checkPermissionOther={checkPermissionOther}
              checkPermission={checkPermission}
              autoFocus={active}
            />
          );}
        }
        </WorkPersonList>
      </div>
    </div>
  );
};
