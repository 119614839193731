import React, { ReactElement, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import {
  ExternalIdDTO,
  PersonNameDTO,
  ReferencedPersonDTO,
  WorkPersonDTO,
  WorkPersonOtherDTO,
} from 'api/core/models';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { PersonSuggest } from '../../../../auto-complete/PersonSuggest';
import { messages } from '../data';
import { PersonNameSuggest } from 'Edit/components/auto-complete/PersonName';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { isRequired } from 'BaseLayout/components/form/generic/utils';
import { Label } from '@theme-ui/components';
import { Button, Flex } from 'theme-ui';
import { WorkPersonAny } from './WorkPersonList';
import { ValidationResults } from '../../../../../validation/interfaces';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { DefaultExternalId } from 'Edit/data/emptyModels';
import { AddButton, DeleteButton } from 'BaseLayout/components/form/MultiInput/Buttons';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { auto } from '@popperjs/core';

interface WorkPersonFormProps {
  wp: WorkPersonDTO;
  onChange: (x: WorkPersonDTO) => void;
  checkPermission: CheckPermissionFunction;
  validations: ValidationResults;
  autoFocus?: boolean;
}

function WorkPersonForm({
  wp,
  onChange,
  checkPermission,
  validations,
  autoFocus
}: WorkPersonFormProps): ReactElement {
  const intl: IntlShape = useIntl();
  const { read: readPersonName, write: writePersonName } = checkPermission('person_name');
  return (
    <React.Fragment>
      <Flex sx={{width: '100%', gap: '16px', pr: '43px'}}>
        {readPersonName && (
          <>
            <PersonSuggest
              name={wp.sequence+'. '+intl.formatMessage(messages.person)}
              help={intl.formatMessage(messages.personHelp)}
              required={isRequired(checkPermission('person_name'))}
              validation={validations?.person_name}
              onChange={(referencedPeople: ReferencedPersonDTO[]): void => onChange({ ...wp, personName: { ...referencedPeople[0], id: undefined, personId: referencedPeople[0]?.id } })}
              canDelete={false}
              tags={wp.personName ? [wp.personName] : []}
              single
              disabled={!writePersonName}
              autoFocus={autoFocus}
            />
            {wp.personName && wp.personName.personId && (
              <PersonNameSuggest
                name={intl.formatMessage(messages.name_variant)}
                onChange={(personName: PersonNameDTO): void => {
                  onChange({ ...wp, personName });
                }}
                required={isRequired(checkPermission('person_name'))}
                validation={validations.person_name}
                value={wp.personName?.id}
                personId={wp.personName.personId}
                disabled={!writePersonName || !wp.personName}
                />
            )}
          </>
        )}
        <SelectInputP
          listPartition={''}
          onChange={(role: string): void => onChange({ ...wp, role })}
          name={intl.formatMessage(messages.role)}
          list={'person_role_list'}
          value={wp.role}
          inputName="role"
          validation={validations?.role}
          permissions={checkPermission('role')}
        />
      </Flex>
    </React.Fragment>
  );
}

interface WorkPersonOtherFormProps {
  wpo: WorkPersonOtherDTO;
  onChange: (x: WorkPersonOtherDTO) => void;
  checkPermission: CheckPermissionFunction;
  validations: ValidationResults;
  autoFocus?: boolean;
}

function WorkPersonOtherForm({
  wpo,
  onChange,
  checkPermission,
  validations,
  autoFocus
}: WorkPersonOtherFormProps): ReactElement {
  const [openExternalId, setOpenExternalId] = useState<boolean>(false);
  const intl: IntlShape = useIntl();
  const { read: readExternalIdPersonOther, write: writeExternalIdPersonOther } = checkPermission('external_ids');
  return (
    <React.Fragment>
      <Flex sx={{width: "100%", gap: 6, alignItems: "center"}}>
        <TextInputP
          permissions={checkPermission('surname')}
          validation={validations.surname}
          name={wpo.sequence + ". "+ intl.formatMessage(messages.surname)}
          help={intl.formatMessage(messages.surnameHelp)}
          value={wpo.surname}
          onChange={(surname: string): void =>
            onChange({ ...wpo, surname })
          }
          autoFocus={autoFocus}
        />
        <TextInputP
          permissions={checkPermission('givenname')}
          validation={validations.givenname}
          name={intl.formatMessage(messages.givenname)}
          value={wpo.givenname}
          onChange={(givenname: string): void =>
            onChange({ ...wpo, givenname })
          }
        />
        <SelectInputP
          listPartition={''}
          onChange={(role: string) => onChange({ ...wpo, role })}
          name={intl.formatMessage(messages.role)}
          list="person_role_list"
          inputName="role"
          value={wpo.role}
          permissions={checkPermission('role')}
          validation={validations.role}
        />
        {readExternalIdPersonOther && writeExternalIdPersonOther && (
          <>
            <div>
              <Button
                sx={{
                  backgroundColor: 'white',
                  p: '0px',
                  mt: '1.5em'
                }}
                onClick={(e: React.FormEvent<HTMLButtonElement>): void => {
                  e.preventDefault();
                  setOpenExternalId(!openExternalId);
                }}
              >
                {!openExternalId && <i className="icon-menuepunkt_aufgeklappt" sx={{ "&:before": {fontSize: '30px'} }}/>}
                {openExternalId && <i className="icon-menuepunkt_zugeklappt" sx={{ "&:before": {fontSize: '30px'} }}/>}
              </Button>
            </div>
          </>
        )}
      </Flex>
      {readExternalIdPersonOther && writeExternalIdPersonOther && (
        <>
          {!!openExternalId && (
            <div sx={{ml: '1em'}}>
              <Label sx={{fontSize: '1.1em', mb: '-0.4em'}}>
                {intl.formatMessage(messages.externalId)}
              </Label>
              <MultiInputControls<ExternalIdDTO>
                lines={wpo.externalIds}
                onChange={(externalIds: ExternalIdDTO[]): void => onChange({ ...wpo, externalIds })}
                defaultRow={DefaultExternalId}
                remove
                add
                renderLines={(eid: ExternalIdDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
                  <div>
                    <Flex variant="layout.multiRow" sx={{gap: '12px'}}>
                      <SelectInputP
                        permissions={checkPermission('external_ids.type')}
                        onChange={(value: string) => onChange2({ ...eid, type: value })}
                        value={eid.type}
                        name={intl.formatMessage(messages.type)}
                        inputName={'type'}
                        list={'external_person_id_list'}
                        listPartition={undefined}
                        validation={validations?.external_ids.children[idx]?.type}
                        autoFocus={true}
                      />
                      <TextInputP
                        name={intl.formatMessage(messages.id)}
                        disabled={!eid.type}
                        permissions={checkPermission('external_ids.external_id')}
                        value={eid.externalId}
                        onChange={(externalId: string) => onChange2({ ...eid, externalId })}
                        validation={validations?.external_ids.children[idx]?.external_id}
                      />
                      {!!actions.delete && (
                        <div sx={{mt: '1.7em'}}>
                          <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                        </div>
                      )}
                    </Flex>
                  </div>
                )}
                renderEnd={(actionAdd) => (
                  <div sx={{mt: '-0.5em'}}>
                    <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
                  </div>
                )}
              />
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
}

interface WorkPersonAnyFormProps {
  wpa: WorkPersonAny;
  onChange: (x: WorkPersonAny) => void;
  checkPermission: CheckPermissionFunction;
  checkPermissionOther: CheckPermissionFunction;
  autoFocus?: boolean;
  key?: string;
}

export const WorkPersonAnyForm: React.FC<WorkPersonAnyFormProps> = ({
  wpa,
  onChange,
  checkPermission,
  checkPermissionOther,
  autoFocus
}) => {
  return (
    <div sx={{width: "100%", gap: 6, alignItems: "center"}}>
      {wpa.type === 'workPerson' && (
        <WorkPersonForm
          wp={wpa.person}
          validations={wpa.validation}
          onChange={(wp: WorkPersonDTO): void => onChange({ ...wpa, person: wp })}
          checkPermission={checkPermission}
          autoFocus={autoFocus}
        />
      )}
      {wpa.type === 'workPersonOther' && (
        <WorkPersonOtherForm
          wpo={wpa.person}
          validations={wpa.validation}
          onChange={(wpo: WorkPersonOtherDTO): void => onChange({ ...wpa, person: wpo })}
          checkPermission={checkPermissionOther}
          autoFocus={autoFocus}
        />
      )}
    </div>
  );
};
