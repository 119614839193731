/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PatchDocument,
    PatchDocumentFromJSON,
    PatchDocumentToJSON,
    PersonDTO,
    PersonDTOFromJSON,
    PersonDTOToJSON,
    ReferencedPersonNameDTO,
    ReferencedPersonNameDTOFromJSON,
    ReferencedPersonNameDTOToJSON,
} from '../models';

export interface CreatePersonRequest {
    personDTO: PersonDTO;
}

export interface DeletePersonRequest {
    personId: number;
}

export interface GetExclusiveWorksOfPersonRequest {
    personId: number;
}

export interface GetOrganisationsOfPersonRequest {
    personId: number;
}

export interface GetPersonRequest {
    personId: number;
}

export interface GetPersonIdRequest {
    email: string;
}

export interface GetPersonNameByIdRequest {
    personId: number;
    nameId: number;
}

export interface GetPersonNamesRequest {
    personId: number;
}

export interface GetProjectsOfPersonRequest {
    personId: number;
}

export interface GetWorksByPersonNameRequest {
    nameId: number;
}

export interface GetWorksOfPersonRequest {
    personId: number;
}

export interface UpdatePersonRequest {
    personId: number;
    patchDocument?: Array<PatchDocument>;
}

/**
 * 
 */
export class PersonApi extends runtime.BaseAPI {

    /**
     */
    async createPersonRaw(requestParameters: CreatePersonRequest): Promise<runtime.ApiResponse<PersonDTO>> {
        if (requestParameters.personDTO === null || requestParameters.personDTO === undefined) {
            throw new runtime.RequiredError('personDTO','Required parameter requestParameters.personDTO was null or undefined when calling createPerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PersonDTOToJSON(requestParameters.personDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDTOFromJSON(jsonValue));
    }

    /**
     */
    async createPerson(requestParameters: CreatePersonRequest): Promise<PersonDTO> {
        const response = await this.createPersonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deletePersonRaw(requestParameters: DeletePersonRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling deletePerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePerson(requestParameters: DeletePersonRequest): Promise<void> {
        await this.deletePersonRaw(requestParameters);
    }

    /**
     */
    async getExclusiveWorksOfPersonRaw(requestParameters: GetExclusiveWorksOfPersonRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getExclusiveWorksOfPerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}/exclusiveWorks`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getExclusiveWorksOfPerson(requestParameters: GetExclusiveWorksOfPersonRequest): Promise<Array<number>> {
        const response = await this.getExclusiveWorksOfPersonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getOrganisationsOfPersonRaw(requestParameters: GetOrganisationsOfPersonRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getOrganisationsOfPerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}/organisations`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getOrganisationsOfPerson(requestParameters: GetOrganisationsOfPersonRequest): Promise<Array<number>> {
        const response = await this.getOrganisationsOfPersonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonRaw(requestParameters: GetPersonRequest): Promise<runtime.ApiResponse<PersonDTO>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDTOFromJSON(jsonValue));
    }

    /**
     */
    async getPerson(requestParameters: GetPersonRequest): Promise<PersonDTO> {
        const response = await this.getPersonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonIdRaw(requestParameters: GetPersonIdRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling getPersonId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async getPersonId(requestParameters: GetPersonIdRequest): Promise<number> {
        const response = await this.getPersonIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonNameByIdRaw(requestParameters: GetPersonNameByIdRequest): Promise<runtime.ApiResponse<ReferencedPersonNameDTO>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonNameById.');
        }

        if (requestParameters.nameId === null || requestParameters.nameId === undefined) {
            throw new runtime.RequiredError('nameId','Required parameter requestParameters.nameId was null or undefined when calling getPersonNameById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}/name/{nameId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))).replace(`{${"nameId"}}`, encodeURIComponent(String(requestParameters.nameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReferencedPersonNameDTOFromJSON(jsonValue));
    }

    /**
     */
    async getPersonNameById(requestParameters: GetPersonNameByIdRequest): Promise<ReferencedPersonNameDTO> {
        const response = await this.getPersonNameByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonNamesRaw(requestParameters: GetPersonNamesRequest): Promise<runtime.ApiResponse<Array<ReferencedPersonNameDTO>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getPersonNames.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}/name`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ReferencedPersonNameDTOFromJSON));
    }

    /**
     */
    async getPersonNames(requestParameters: GetPersonNamesRequest): Promise<Array<ReferencedPersonNameDTO>> {
        const response = await this.getPersonNamesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getProjectsOfPersonRaw(requestParameters: GetProjectsOfPersonRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getProjectsOfPerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}/projects`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getProjectsOfPerson(requestParameters: GetProjectsOfPersonRequest): Promise<Array<number>> {
        const response = await this.getProjectsOfPersonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWorksByPersonNameRaw(requestParameters: GetWorksByPersonNameRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.nameId === null || requestParameters.nameId === undefined) {
            throw new runtime.RequiredError('nameId','Required parameter requestParameters.nameId was null or undefined when calling getWorksByPersonName.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/name/{nameId}/works`.replace(`{${"nameId"}}`, encodeURIComponent(String(requestParameters.nameId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getWorksByPersonName(requestParameters: GetWorksByPersonNameRequest): Promise<Array<number>> {
        const response = await this.getWorksByPersonNameRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWorksOfPersonRaw(requestParameters: GetWorksOfPersonRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getWorksOfPerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}/works`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getWorksOfPerson(requestParameters: GetWorksOfPersonRequest): Promise<Array<number>> {
        const response = await this.getWorksOfPersonRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePersonRaw(requestParameters: UpdatePersonRequest): Promise<runtime.ApiResponse<PersonDTO>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling updatePerson.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/person/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchDocument.map(PatchDocumentToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PersonDTOFromJSON(jsonValue));
    }

    /**
     */
    async updatePerson(requestParameters: UpdatePersonRequest): Promise<PersonDTO> {
        const response = await this.updatePersonRaw(requestParameters);
        return await response.value();
    }

}
