import { defineMessages } from 'react-intl';
import { ViewType } from 'Detail/types';
import { DetailEditProject } from '../pages/Project';
import { DetailEditOrg } from '../pages/Organisation';
import { DetailEditPeople } from '../pages/Person';
import { DetailEditWork } from '../pages/Work';
import { BaseDetailModel, DetailEditProps } from '../types';

/**
 * Übersetzungen für Seiten Buttons
 */
export const messages = defineMessages({
  basic: { id: 'edit.work.page.button.basic' },
  specific: { id: 'edit.work.page.button.specific' },
  ids: { id: 'edit.work.page.button.ids' },
  persons: { id: 'edit.work.page.button.persons' },
  ispartof: { id: 'edit.work.page.button.ispartof' },
  hasPart: { id: 'edit.work.page.button.haspart' },
  organisations: { id: 'edit.work.page.button.organisations' },
  projects: { id: 'edit.work.page.button.projects' },
  events: { id: 'edit.work.page.button.events' },
  content: { id: 'edit.work.page.button.content' },
  administrative: { id: 'edit.work.page.button.administrative' }
});


/**
 * Zuordnung welche Seite welche Properties anzeigt
 */
// Felder im HB2 die noch kein Feld im neuen Formular haben

/*
    { key: 'SEITE': [
        { key: 'FELD1', fields: [validations] },
        { key: 'FELD2', fields: [validations] },
]
*/
export const pages: { [page: string]: {key: string; fields: string[]}[] } = {
  basic: [
    { key: 'FORM_SUBJECT', fields: ['form_subject'] }, // Formschlagwort
    { key: 'PUBLICATION_STATUS', fields: ['publication_status'] }, // Angaben zum Publikationsstatus
    { key: 'VERSION', fields: ['version'] }, // Publikationsversion
    { key: 'RESOURCE_TYPE', fields: ['resource_type'] }, // Art der Ressource
    { key: 'TITLE', fields: ['title'] }, // Titel
    { key: 'SUBTITLE', fields: ['subtitle'] }, // Untertitel
    { key: 'LANGUAGE', fields: ['work_languages'] }, // Sprache
    { key: 'TITLE_SUPPLEMENT', fields: ['title_supplement'] }, // Ergänzungen zum Titel
    { key: 'OTHER_TITLE', fields: ['work_other_titles'] }, // Anderer Titel
    { key: 'SUBSERIES_TITLE', fields: ['subseries_title'] }, // Unterreihe
    { key: 'TITLE_ABBREVIATION', fields: ['work_title_abbreviations'] }, // Titelabkürzung
    { key: 'EDITION', fields: ['edition'] }, // Auflage/Ausgabe
    { key: 'DATAVERSION', fields: ['data_version'] }, // Version
    { key: 'NUMBER_OF_VOLUMES', fields: ['numberOfVolumes'] }, // Anzahl Bände
    { key: 'ISSUED', fields: ['issued'] }, // Erscheinungszeitpunkt
    { key: 'DATE', fields: ['work_date_types'] }, // Datum
    { key: 'PLACE', fields: ['workPlaces'] }, // Ort
    { key: 'PUBLISHER', fields: ['work_publishers'] }, // Verlag
    { key: 'NUMBER_OF_PAGES', fields: ['numberOfPages'] }, // Gesamtseitenzahl
    { key: 'SIZE', fields: ['size'] }, // Größe/Umfang
    { key: 'NUMBER', fields: ['number'] }, // Nummer
    { key: 'URL', fields: ['urls'] }, // URL
    { key: 'FORMAT', fields: ['format'] }, // Format
    { key: 'MEDIUM', fields: ['medium'] }, // Medium
    { key: 'NOTE', fields: ['note'] }, // Notiz/Bemerkung
    { key: 'PEER_REVIEWED', fields: ['peer_reviewed'] }, // Peer reviewed
  ],
  specific: [
    { key: 'PUBLICATION_NUMBER', fields: ['publication_number'] },
    { key: 'APPLICATION_NUMBER', fields: ['application_number'] },
    { key: 'APPLICATION_COUNTRY', fields: ['application_country'] },
    { key: 'PRIORITY_PATENT_APPLICATION', fields: ['priority_patent_application'] },
    { key: 'PRIORITY_COUNTRY', fields: ['priority_country'] },
    { key: 'PRIORITY_NUMBER', fields: ['priority_number'] },
    { key: 'GRANT_NUMBER', fields: ['grant_number'] },
    { key: 'PATENT_FAMILY', fields: ['patent_family'] },
    { key: 'PATENT_ID', fields: ['patent_id', 'patentType'] },
    { key: 'GEO_LOCATION', fields: ['work_geo_locations'] },
  ],
  ids: [
    { key: 'EXTERNAL_ID', fields: ['external_ids'] },
    { key: 'RELATED_IDENTIFIER', fields: ['internal_related_identifiers', 'external_related_identifiers'] }
  ],
  persons: [
    { key: 'PERSON', fields: ['work_persons', 'work_person_others'] },
  ],
  ispartof: [
    { key: 'IS_PART_OF', fields: ['work_is_part_ofs'] },
    { key: 'IS_PART_OF_OTHER', fields: ['work_is_part_of_others'] }
  ],
  hasPart: [
    { key: 'HAS_PART', fields: ['has_part'] }
  ],
  organisations: [
    { key: 'ORGANISATION', fields: ['work_organisations', 'work_organisation_others'] },
  ],
  projects: [
    { key: 'PROJECT', fields: ['work_projects'] },
    { key: 'PROJECT_OTHER', fields: ['work_project_others'] }
  ],
  events: [
    { key: 'EVENT_NAME', fields: ['events'] }
  ],
  content: [
    { key: 'KEYWORD', fields: ['work_keywords'] },
    { key: 'SUBJECT', fields: ['work_subjects'] },
    { key: 'DESCRIPTION', fields: ['work_descriptions'] }
  ],
  administrative: [
    { key: 'ID', fields: ['id'] },
    { key: 'DATE_CHANGED', fields: ['date_changed'] },
    { key: 'DATE_CREATED', fields: ['date_created'] },
    { key: 'ORIGIN', fields: ['origin'] },
    { key: 'DESKMAN', fields: ['deskman'] },
    { key: 'HIDDEN', fields: ['hidden'] },
    { key: 'EDITORIAL_STATUS', fields: ['editorial_status'] },
    { key: 'BELONG_TO_ORGANISATION', fields: ['organisations'] },
    { key: 'BELONG_TO_PROJECT', fields: ['projects'] },
    { key: 'FUNDED', fields: ['admin_data_work_open_access'] },
    { key: 'ACCESS_LEVEL', fields: ['admin_data_work_open_access'] },
    { key: 'LICENSE', fields: ['license'] }, // Lizenz
    { key: 'LICENSE_TEXT', fields: ['licenseText'] }, // Urheberrechtliche und nutzungsrechtliche Informationen
    { key: 'LICENSE_MANAGEMENT_STATUS', fields: ['license_management_status'] },
    { key: 'ARTICLE_PROCESSING_CHARGE', fields: ['admin_data_work_open_access'] },
    { key: 'OBLIGATORY_DELIVERY', fields: ['obligatory_delivery'] },
    { key: 'ORCID_SYNC', fields: ['admin_data_work_orcid_syncs'] },
  ],
};

export const pubtypeEntityMapping = {
  audio_video_document: 'audio-video-object',
  radio_tv: 'audio-video-object',

  blog: 'integrating-resource',
  website: 'integrating-resource',

  chapter: 'fragment',
  chapter_in_conference: 'fragment',
  chapter_in_legal_commentary: 'fragment',
  article_newspaper: 'fragment',
  article_journal: 'fragment',
  article_integrating_resource: 'fragment',
  lexicon_article: 'fragment',
  editorial: 'fragment',
  article_overview: 'fragment',
  review: 'fragment',
  abstract: 'fragment',

  monograph: 'self-contained',
  collection: 'self-contained',
  edition: 'self-contained',
  conference: 'self-contained',
  legal_commentary: 'self-contained',
  special_issue: 'self-contained',
  report_collection: 'self-contained',

  report_grey_literature: 'self-contained-or-fragment',
  interview: 'self-contained-or-fragment',
  lecture: 'self-contained-or-fragment',
  poster: 'self-contained-or-fragment',

  multi_volume_work: 'multi-volume-work',
  monograph_mvw: 'multi-volume-work',
  collection_mvw: 'multi-volume-work',
  edition_mvw: 'multi-volume-work',
  conference_mvw: 'multi-volume-work',

  series: 'series',

  newspaper: 'periodical',
  journal: 'periodical',

  research_data: 'research-data-group',

  patent: 'patent',

  student_thesis: 'thesis',
  dissertation: 'thesis',
  habilitation: 'thesis',
};



const editClassMap: {
  [key in ViewType]: typeof DetailEditPeople | typeof DetailEditOrg | typeof DetailEditProject | typeof DetailEditWork;
} = {
  [ViewType.WORKS]: DetailEditWork,
  [ViewType.PEOPLE]: DetailEditPeople,
  [ViewType.ORGS]: DetailEditOrg,
  [ViewType.PROJECTS]: DetailEditProject,
};

export const Edit = <Model extends BaseDetailModel>(type: ViewType): React.ComponentType<DetailEditProps<Model>> => {
  // @FIXME: Remove the type coercion; we have to trust the caller to use the correct type
  return editClassMap[type] as React.ComponentType<DetailEditProps<Model>>;
};
