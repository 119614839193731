import * as React from 'react';
import { ReactElement } from 'react';
import { Button } from '@theme-ui/components';
import Popup from 'BaseLayout/components/popup/Popup';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface ConfirmDialogProps {
  headline?: string;
  text: string;
  buttons: {
    label: string;
    onClick: () => void;
  }[];
  onClose?: (e: React.MouseEvent) => void;
}

/**
 * Displays a popup message
 *
 * @param props Props:
 * - headline: optional headline of popup message
 * - text: text to display in popup message
 * - buttons: array of labels and onClickFunctions for buttons.
 * - If no buttons is provided, a default button is displayed.
 * @constructor
 */

export function ConfirmDialog(props: ConfirmDialogProps): ReactElement {
  const buttons = [];
  if (props.buttons) {
    for (const [index, pbutton] of props.buttons.entries()) {
      buttons.push(
        <Button
          p={'10px'}
          mt={'1em'}
          key={index}
          onClick={(e: React.FormEvent<HTMLButtonElement>): void => pbutton.onClick()}
        >
          {pbutton.label}
        </Button>
      );
    }
  }
  return (
    <Popup
      open={true}
      title={props.headline}
      onClose={() => props.onClose}
      buttons={[]}
      maxWidth='40em'
    >
      {props.text && <p>{props.text}</p>}
      {buttons}
    </Popup>
  );
}
