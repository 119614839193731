import * as Model from '@mms-v3/data-model-js';
import { Field } from '@mms-v3/data-model-js';
import { snakeToCamel } from '../utils';
import { Validation, ValidationResult, ValidationResults } from './interfaces';
import {
  validateIssuedLength,
  validateMinGeoLocationPoints,
  validateOneMainName,
  validateRequired,
} from './validators';
import { Authorizer } from '../../Auth/utils/Authorizer';
import { checkIsOwnRecord } from '../../Auth/utils/DataModel';

export const validate = (rootData: any, adminData, authorizer: Authorizer, isNew: boolean, entity: string): Validation => {
  const Entity: Model.Entity = Model.DataModel.for(entity);

  if (!Entity) {
    throw new Error(`Entity ${entity} not found`);
  }
  let rootFields: Model.Field[];
  let adminFields: Model.Field[];
  if (entity.startsWith('work')) {
    const EntityGeneric: Model.Entity = Model.DataModel.for('work_generic');
    rootFields = [...EntityGeneric.fields, ...Entity.fields];
    adminFields = Model.DataModel.for('work_admin_data').fields;
  } else {
    rootFields = Entity.fields;
    adminFields = Model.DataModel.for(`${entity}_admin_data`).fields;
  }

  const isOwnRecord: boolean = authorizer.getSession() ? checkIsOwnRecord(authorizer, isNew, [authorizer.getSession().user_displayname]) : false;
  const roles: string[] = authorizer.getSession()?.roles || [];

  return {
    root: validateFields(rootFields, rootData, '', { roles, isOwnRecord }),
    admin: validateFields(adminFields, adminData, '', { roles, isOwnRecord })
  };
};

export const validateFields = (fields: Field[], dataObject: object, path: string = '', config: {roles: string[]; isOwnRecord: boolean}): ValidationResults => {
  const results: ValidationResults = {};
  fields.forEach((field: Field): void => {
    // get data
    const data: any = dataObject ? dataObject[snakeToCamel(field.name)] : undefined;
    // VALIDATION
    let result: ValidationResult = { valid: true, reason: '' };

    const validations: ((value) => ValidationResult)[] = [
      (value) => validateRequired(value, field.mustWrite(config.roles, config.isOwnRecord)),
      (value) => validateIssuedLength(value, field.name === 'issued'),
      (value) => validateOneMainName(value, field.name === 'person_names'),
      (value) => validateMinGeoLocationPoints(value, field.name === 'work_geo_location_points')
    ];
    for(const validation of validations) {
      result = validation(data);
      if(!result.valid) {
        break;
      }
    }
    // validate children
    if(field.fields) {
      if(Array.isArray(data)) {
        result.children = (data||[]).map((childData, index) => validateFields(field.fields, childData, `${path}.${field.name}.${index}`, config));
      } else {
        result.child = data ? validateFields(field.fields, data, `${path}.${field.name}`, config) : undefined;
      }
    }
    // persist result
    results[field.name] = result;
  });
  return results;
};
