import React, { ReactElement, useState } from 'react';
import { PersonNameDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { connectOAuth2, OAuth2AwareProps } from '../../../../Auth/utils/connect';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultPersonName } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { CheckboxP } from '../../../../BaseLayout/components/form/generic/Checkbox';
import { PersonApi } from 'api/core/apis';
import { Link } from 'react-router-dom';
import { getDetailContextUrl } from 'data';
import { ViewType } from 'Detail/types';
import { Flex, Styled, Link as ThemeLink } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';
import Popup from 'BaseLayout/components/popup/Popup';

/** @jsx jsx */
import { jsx } from 'theme-ui';

const messages = defineMessages({
  personNameSalutation: { id: 'edit.person.salutation_label' },
  personNameGivenname: { id: 'edit.person.givenname_label' },
  personNameSurname: { id: 'edit.person.surname_label' },
  personNameSurnameHelp: { id: 'edit.person.surname_label.help' },
  isMain: { id: 'edit.person.isMain_label' }
});

interface PersonNamesProps {
  checkPermission?: CheckPermissionFunction;
  personNames: PersonNameDTO[];
  onChange: (personNames: PersonNameDTO[]) => void;
  validation: ValidationResult;
}
type InnerProps = OAuth2AwareProps<PersonNamesProps & WrappedComponentProps>;

type personNameWorksType = {
  personName: PersonNameDTO;
  workIds: number[];
};

function InnerPersonNames(props: InnerProps): React.JSX.Element {
  const [personNameWorks, setPersonNameWorks] = useState<personNameWorksType>();
  const { checkPermission, personNames, onChange, intl, validation } = props;
  const api: PersonApi = props.apiWithAuth(new PersonApi());

  const deletePersonName = (personName: PersonNameDTO, deleteAction: (triggeringEvent: any) => void, event: any): void => {
    event.preventDefault();
    // juggling-check um null und undefined abzufangen
    if(personName.id == null) {
      return deleteAction(event);
    }
    api.getWorksByPersonName({ nameId: personName.id })
      .then((workIds: number[]): void => {
        if (workIds.length === 0) {
          // personName is not linked to a work: we can safely delete it
          return deleteAction(event);
        }
        // personName is linked to at least one work: display the linked works
        setPersonNameWorks({personName, workIds});
      })
      .catch(async (err): Promise<void> => {
        const errorData = err.message ? err : await err.json();
        alert(errorData.message);
      });
  };

  const isMainSet: boolean = personNames.some((personName: PersonNameDTO): boolean => personName.isMain === true);
  console.log(isMainSet);

  return (
    <>
      {personNameWorks &&
        <Popup
          open={true}
          title={intl.formatMessage({id: "error.delete"})}
          onClose={() => setPersonNameWorks(null)}
          buttons={[
            {
              label: intl.formatMessage({id: "button.close"}),
              onClick: () => setPersonNameWorks(null)
            },
          ]}
          maxWidth='40em'
        >
          <p sx={{ fontWeight: 'bold' }}>
            <FormattedMessage id="message.personName_is_linked_to_works" />
          </p>
          <Styled.ul sx={{pl: 7, mt: 4, maxHeight: "20em", overflowY: "auto"}}>
            {personNameWorks.workIds.map((workId: number, idx: number): React.JSX.Element => (
              <li key={idx}>
                <Link
                  to={getDetailContextUrl(ViewType.WORKS, workId)}
                  component={ThemeLink}
                  >{getDetailContextUrl(ViewType.WORKS, workId)}</Link>
              </li>
            ))}
          </Styled.ul>
        </Popup>
      }
      <div className="form form-highlight">
        <Styled.h2>
          <FormattedMessage id="entity.attr.personNames" />
        </Styled.h2>
        <MultiInputControls<PersonNameDTO>
          lines={personNames}
          onChange={onChange}
          remove
          add
          renderLines={(personName: PersonNameDTO, onChange2: (changedPersonNames: PersonNameDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
            <>
              <Flex sx={{gap: 3}}>
                <SelectInputP
                  permissions={checkPermission('salutation')}
                  onChange={(salutation: string): void => onChange2({ ...personName, salutation })}
                  value={personName.salutation}
                  name={intl.formatMessage(messages.personNameSalutation)}
                  inputName={'salutation'}
                  list={'salutation_list'}
                  listPartition={'*'}
                  validation={validation?.children[idx]?.salutation}
                  autoFocus={true}
                />
                <TextInputP
                  name={intl.formatMessage(messages.personNameGivenname)}
                  permissions={checkPermission('givenname')}
                  value={personName.givenname}
                  onChange={(givenname: string): void => onChange2({ ...personName, givenname })}
                  validation={validation?.children[idx]?.givenname}
                />
                <TextInputP
                  name={intl.formatMessage(messages.personNameSurname)}
                  help={intl.formatMessage(messages.personNameSurnameHelp)}
                  permissions={checkPermission('surname')}
                  value={personName.surname}
                  onChange={(surname: string): void => onChange2({ ...personName, surname })}
                  validation={validation?.children[idx]?.surname}
                />
              </Flex>
              <CheckboxP
                name={intl.formatMessage(messages.isMain)}
                checked={personName.isMain}
                permissions={{ ...checkPermission('is_main'), write: isMainSet ? checkPermission('is_main').write : 'MANDATORY' }}
                validation={validation?.reason === 'validated:noMainName' || validation?.reason === 'validated:toManyMainNames' ? validation : validation?.children[idx].is_main}
                onChange={(value: boolean) => {
                  // Cant uncheck itself because isMain has to be set
                  if (personName.isMain) {
                    value = true;
                    return;
                  }
                  // Remove isMain from all other names
                  personNames.map((name: PersonNameDTO): boolean => (name.isMain = false));
                  onChange({ ...personNames });
                  // Set isMain for this name
                  onChange2({ ...personName, isMain: value });
                }}
              />
              {!!actions.delete && !personName.isMain && (
                <div className='deleteButton' sx={{mt: '-0.1em !important'}}>
                  <DeleteButton
                    disabled={!checkPermission().write}
                    onClick={(event) => deletePersonName(personName, actions.delete, event) }
                  />
                </div>
              )}
            </>
          )}
          renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
            <div className='addButton'>
              <AddButton onClick={actionAdd} disabled={!checkPermission().write} />
            </div>
          )}
        defaultRow={DefaultPersonName}/>
      </div>
    </>
  );
}

export const PersonNames = connectOAuth2(injectIntl(InnerPersonNames));
