import React from 'react';
import hhuLogoFooter from '../../assets/img/logos/logo_hhu_footer.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { FormattedHTMLMessage, FormattedMessage, useIntl, defineMessages, IntlShape } from 'react-intl';

import { Flex, Box, Styled } from 'theme-ui';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FooterDesktopStyling } from './theme';

const messages = defineMessages({ dataProtection: { id: 'hhu.footer.dataProtection_link' },
                                  imprint: { id: 'hhu.footer.imprint_link'},
                                  email: { id: 'hhu.footer.email'},
                                  logoLink: { id: 'hhu.logo_link'},
                                  orcidLink: { id: 'orcid.logo_link'}
                                });

export const HHUFooterDesktop = (): React.JSX.Element => {
  const intl: IntlShape = useIntl();
  return (
    <FooterDesktopStyling>
      <Flex className='footer'>
        <Flex className='logos'
          sx={{
            ".orcidLogo": {
              mx: 10,
              my: 6,
            },
            ".hhuLogo": {
              width: '350px',
              ml: '25px',
              mt: '-23.5px',
              mb: '-19px'
            },
          }}
        >
          <a href={intl.formatMessage(messages.logoLink)}>
            <img className="hhuLogo" src={hhuLogoFooter} />
          </a>
          {/* ---> wenn orcid eingerichtet wurde
          <a href={intl.formatMessage(messages.orcidLink)}>
            <img className="orcidLogo" src={orcidLogo} />
          </a>
          */}
        </Flex>
        <Box px={8} py={6} className='info'>
          <p>
            <Styled.a href={`mailto:${intl.formatMessage(messages.email)}`}>
              <FontAwesomeIcon icon={faAt} sx={{verticalAlign: '-0.15em'}}/> <FormattedMessage id="hhu.footer.email" />
            </Styled.a>
          </p>
          {/*
          <p>
            <FontAwesomeIcon icon={faPhone} sx={{verticalAlign: '-0.15em'}} /> <FormattedMessage id="hhu.footer.telephone" />
          </p>
          */}
          <br />
          <p>
            <FormattedHTMLMessage id="hhu.footer.infotext" />
          </p>
          <Flex className='links'>
            <Styled.a href={intl.formatMessage(messages.dataProtection)}><FormattedMessage id="footer.dataProtection" /></Styled.a>
            <Styled.a href={intl.formatMessage(messages.imprint)}><FormattedMessage id="footer.imprint" /></Styled.a>
          </Flex>
        </Box>
      </Flex>
    </FooterDesktopStyling>
  );
};
