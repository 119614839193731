/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkIsPartOfOtherDTO
 */
export interface WorkIsPartOfOtherDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkIsPartOfOtherDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkIsPartOfOtherDTO
     */
    articleId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkIsPartOfOtherDTO
     */
    issue?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkIsPartOfOtherDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkIsPartOfOtherDTO
     */
    pageFirst?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkIsPartOfOtherDTO
     */
    pageLast?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkIsPartOfOtherDTO
     */
    volume?: string;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof WorkIsPartOfOtherDTO
     */
    externalIds?: Array<ExternalIdDTO>;
}

export function WorkIsPartOfOtherDTOFromJSON(json: any): WorkIsPartOfOtherDTO {
    return WorkIsPartOfOtherDTOFromJSONTyped(json, false);
}

export function WorkIsPartOfOtherDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkIsPartOfOtherDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'articleId': !exists(json, 'articleId') ? undefined : json['articleId'],
        'issue': !exists(json, 'issue') ? undefined : json['issue'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'pageFirst': !exists(json, 'pageFirst') ? undefined : json['pageFirst'],
        'pageLast': !exists(json, 'pageLast') ? undefined : json['pageLast'],
        'volume': !exists(json, 'volume') ? undefined : json['volume'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
    };
}

export function WorkIsPartOfOtherDTOToJSON(value?: WorkIsPartOfOtherDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'articleId': value.articleId,
        'issue': value.issue,
        'name': value.name,
        'pageFirst': value.pageFirst,
        'pageLast': value.pageLast,
        'volume': value.volume,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
    };
}


