import React from 'react';
import { RubFooter } from './Footer/Rub';
import { TudoFooter } from './Footer/Tudo';
import { HHUFooter } from './Footer/HHU';
import { UWHFooter } from './Footer/UWH';
import { RubHomepage } from './Homepage/Rub/Rub';
import { TudoHomepage } from './Homepage/Tudo/Tudo';
import { HHUHomepage } from './Homepage/HHU/HHU';
import { UWHHomepage } from './Homepage/UWH/UWH';
import { useColorMode } from 'theme-ui';
import { RubLogo } from './Logo/Rub';
import { TudoLogo } from './Logo/Tudo';
import { HHULogo } from './Logo/HHU';
import { UWHLogo } from './Logo/UWH';

interface ComponentMap {
  [theme: string]: React.ComponentType;
}

export const logoComponents: ComponentMap = {
  rub: RubLogo,
  tudo: TudoLogo,
  hhu: HHULogo,
  uwh: UWHLogo
};

export const footerComponents: ComponentMap = {
  rub: RubFooter,
  tudo: TudoFooter,
  hhu: HHUFooter,
  uwh: UWHFooter
};

export const homepageComponents: ComponentMap = {
  rub: RubHomepage,
  tudo: TudoHomepage,
  hhu: HHUHomepage,
  uwh: UWHHomepage
};

export const createThemeableComponent: (components: ComponentMap) => any = (components: ComponentMap) => {
  const Comp: React.FC = (props: any): React.JSX.Element => {
    const [theme] = useColorMode();

    const C = components[theme];
    if (!C) {
      return <></>;
    }
    return <C {...props}/>;
  };
  return Comp;
};
