import { Button } from '@theme-ui/components';
import * as React from 'react';
import { useState } from 'react';
import Avatar, { ReactAvatarProps } from 'react-avatar';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import {
  EmploymentPeriodDTO,
  ExternalIdDTO,
  PersonDTO,
  PersonEmailDTO,
  PersonNameDTO,
  PersonStatusDTO,
  PersonToOrganisationDTO,
  UrlDTO,
} from '../../api/core/models';
import { OAuth2Gate } from '../../Auth/components';
import { EmbeddedDashboard } from '../../Dashboard/components/EmbeddedDashboard';
import { DashboardType } from '../../Dashboard/types';
import { DeletePerson } from '../../Edit/components/DeletePerson';
import { Notation } from '../../Vocabulary/components/Notation';
import { boolFilter, editStatusFilter } from '../../Vocabulary/data';
import { PersonsDashboards, InnerDetailViewProps as InnerProps, DetailViewProps } from '../types';
import { orcidRequest, renderDate, renderDateTime, renderExternalIds, renderInstitutionalIds } from '../utils';
import { DetailView } from '../components/theme';
import { useHistory } from 'react-router-dom';
import { Subscribe } from 'unstated';
import { OAuth2Container } from '../../Auth/containers/OAuth2Container';
import { OrcidPopup, OrcidResult } from '../../BaseLayout/components/orcid/OrcidPopup';
import { History, Location } from 'history';

export const DetailViewPeople = (props: DetailViewProps): JSX.Element => {
  return (
    <Subscribe to={[OAuth2Container]}>{(c: OAuth2Container) => {
      const innerProps = { ...props, authorizer: c.authorizer()};
      return <InnerDetailViewPeople {...innerProps} />;
    }}
    </Subscribe>
  );
};

function InnerDetailViewPeople(props: InnerProps): JSX.Element {
  const location: Location = useLocation();
  const history: History = useHistory();
  const [activeDashboard, setActiveDashboard] = useState<DashboardType>(props.authorizer.hasAnyOfRoles(["ROLE_GUEST", "ROLE_USER"]) ? DashboardType.WORKS : DashboardType.ORGS);
  const [deleteInit, setDeleteInit] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  // Details are open by default for roles ADMIN and EDITOR
  const [detailsOpen, setDetailsOpen] = useState(props.authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN']));
  const [orcidResult, setOrcidResult] = useState<OrcidResult | undefined>(undefined);

  if (location.state) {
    // button for 'Werke anzeigen' was clicked
    location.state = false;
    setActiveDashboard(DashboardType.WORKS);
  }

  const validateEmail = (email) => {
    // eslint-disable-line max-len
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const { avatarURL, id, viewLoading, viewError, dashboardLoading } = props;
  const dashboardData = props.dashboardData as PersonsDashboards;
  const view = props.view as PersonDTO;
  const isLoaded = !viewLoading && !viewError && view;
  const personOrg = view?.personOrganisations?.map(
    (
      personOrganisation: PersonToOrganisationDTO
    ): { organisationId?: number; employmentPeriods?: EmploymentPeriodDTO[] } => {
      return {
        organisationId: personOrganisation.organisation.id,
        employmentPeriods: personOrganisation.employmentPeriods,
      };
    }
  );

  if (viewError) return <>{viewError}</>;

  const avatarProps: ReactAvatarProps = {
    round: true,
    size: '150px',
    className: 'avatar',
  };
  if (avatarURL) {
    avatarProps.src = avatarURL;
  }

  let mainName: PersonNameDTO = null;
  let orcidId: string | undefined;
  if (isLoaded) {
    // Sollte kein MainName gefunden werden, wird der erste Name verwendet
    mainName = view.personNames.find((name: PersonNameDTO): boolean => !!name.isMain) ?? view.personNames[0];
    avatarProps.name = `${mainName.givenname} ${mainName.surname}`;
    orcidId = view.externalIds?.find((externalId: ExternalIdDTO): boolean => externalId.type === 'orcid')?.externalId;
  }

  return (
    <>
      {orcidResult && (
        <OrcidPopup
          onClose={() => {
            setOrcidResult(undefined);
          }}
          result={orcidResult}
        />
      )}
      {deleteInit && (
        <DeletePerson
          personId={props.id}
          isDeleteRequest={deleteRequest}
          onAbort={() => setDeleteInit(false)}
          onSuccess={() => {
            setDeleteInit(false);
            history.push('/search/persons');
          }}
          onError={() => {
            alert('Fehler beim Löschen!');
            setDeleteInit(false);
          }}
        />
      )}
      <DetailView>
        <div className="top">
          {isLoaded && (
            <>
              <article>
                <div className="head">
                  <div className="right">
                    {mainName && (
                      <h1 className="head-name">
                        <Notation v="salutation_list" k={mainName.salutation} /> {mainName.givenname} {mainName.surname}
                      </h1>
                    )}
                    <div className="head-meta">
                      {view.personNames && view.personNames.length > 0 && (
                        <div className="names">
                          <span className="head-names">
                            <FormattedMessage id="entity.attr.personNames" />:{' '}
                          </span>
                          {view.personNames.map((name: PersonNameDTO, i: number, arr: PersonNameDTO[]) => {
                            const divider = i < arr.length - 1 && ' | ';
                            return (
                              <span key={i}>
                                {name.givenname} {name.surname}
                                {divider}
                              </span>
                            );
                          })}
                        </div>
                      )}
                      {view.externalIds && view.externalIds.length > 0 && (
                        <div className="externalIds">
                          <span className="head-ids">
                            <FormattedMessage id="entity.attr.externalId" />:{' '}
                          </span>
                          {renderExternalIds(view.externalIds, 'person')}
                        </div>
                      )}
                      {view.personInstitutionalIds && view.personInstitutionalIds.length > 0 && (
                        <div className="externalIds">
                          <span className="head-ids">
                            <FormattedMessage id="entity.attr.institutionalId" />:{' '}
                          </span>
                          {renderInstitutionalIds(view.personInstitutionalIds)}
                        </div>
                      )}
                      {view.personStatuses && view.personStatuses.length > 0 && (
                        <div className="status">
                          <span className="head-status">
                            <FormattedMessage id="person.attr.status" />:{' '}
                          </span>
                          {view.personStatuses.map((status: PersonStatusDTO, i: number, arr: PersonStatusDTO[]) => {
                            const divider = i < arr.length - 1 && ', ';
                            return (
                              <span key={i}>
                                {!!status.status && <Notation v="person_status_list" k={status.status} />}
                                {!!status.affiliation && (
                                  <>
                                    {status.status ? ' (' : ''}
                                    <Notation v="affiliations_list" k={status.affiliation} />
                                    {status.status ? ')' : ''}
                                  </>
                                )}
                                {divider}
                              </span>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  {React.createElement(Avatar, avatarProps)}
                </div>
                <div className="clear"></div>

                {view.adminDataPerson.hidden && (
                  <span className="stamp is-nope">
                    <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />
                  </span>
                )}
                <div className="head-toolbar">
                  <Button onClick={() => setDetailsOpen(!detailsOpen)}>
                    <FormattedMessage id="button.Details" />
                  </Button>
                  <div className="edit">
                    {orcidId && (
                      <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
                        <Subscribe to={[OAuth2Container]}>
                          {(container: OAuth2Container) => (
                            <>
                              <Button
                                onClick={ async (): Promise<void> => {
                                  const result: OrcidResult = await orcidRequest(orcidId, 'push', container);
                                  if(result.message) {
                                    console.error(result.message);
                                  }
                                  setOrcidResult(result);
                                }}
                              >
                                <FormattedMessage id="button.orcid.push" />
                              </Button>
                              <Button
                                onClick={ async (): Promise<void> => {
                                  const result: OrcidResult = await orcidRequest(orcidId, 'pull', container);
                                  if(result.message) {
                                    console.error(result.message);
                                  }
                                  setOrcidResult(result);
                                }}
                              >
                                <FormattedMessage id="button.orcid.pull" />
                              </Button>
                            </>
                          )}
                        </Subscribe>
                      </OAuth2Gate>
                    )}
                    <OAuth2Gate anyRoles={['ROLE_ADMIN', 'ROLE_EDITOR']}>
                      <Button onClick={() => (window.location.href = `/person/${id}/edit`)}>
                        <FormattedMessage id="button.Edit" />
                      </Button>
                    </OAuth2Gate>
                    <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
                      <Button
                        onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          setDeleteInit(true);
                          setDeleteRequest(false);
                        }}
                      >
                        <FormattedMessage id="button.Delete" />
                      </Button>
                    </OAuth2Gate>
                    <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
                      <Button
                        onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          setDeleteInit(true);
                          setDeleteRequest(true);
                        }}
                      >
                        <FormattedMessage id="button.DeleteRequest" />
                      </Button>
                    </OAuth2Gate>
                  </div>
                  <div className="clear"></div>
                </div>

                <div className={`body ${!detailsOpen ? 'hidden' : ''}`}>
                  {((view.personEmails && view.personEmails.length > 0) || (view.urls && view.urls.length > 0)) && (
                    <div className="info-group">
                      <div className="info-group-desc">
                        <h3>
                          <FormattedMessage id="informations" />
                        </h3>
                      </div>
                      <div className="info-group-content">
                        {view.personEmails && view.personEmails.length > 0 && (
                          <div className="emails">
                            <h4 className="head-emails">
                              <FormattedMessage id="person.attr.personEmails" />:{' '}
                            </h4>
                            <ol>
                              {view.personEmails
                                .sort((mail1: PersonEmailDTO, mail2: PersonEmailDTO) => {
                                  if (mail1.type === 'campus') return -1;
                                  if (mail2.type === 'campus') return 1;
                                  return mail1.type.localeCompare(mail2.type);
                                })
                                .map((mail: PersonEmailDTO, i: number) => {
                                  let link = mail.email;
                                  if (validateEmail(link)) {
                                    link = 'mailto:' + link;
                                  }
                                  return (
                                    <li key={i}>
                                      <a href={link}>{mail.email}</a>
                                      {' (' + mail.type + ')'}
                                    </li>
                                  );
                                })}
                            </ol>
                            <div className="clear"></div>
                          </div>
                        )}
                        {view.urls && view.urls.length > 0 && (
                          <div className="websites">
                            <h4 className="head-websites">
                              <FormattedMessage id="work.attr.urls" />:{' '}
                            </h4>
                            <ol>
                              {view.urls.map((url: UrlDTO, i: number) => {
                                return (
                                  <li key={i}>
                                    <a href={url.url}>{url.url}</a> ({url.type})
                                  </li>
                                );
                              })}
                            </ol>
                            <div className="clear"></div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {!!view.note && (
                    <div className="info-group">
                      <div className="info-group-desc">
                        <h3>
                          <FormattedMessage id="work.attr.note" />
                        </h3>
                      </div>
                      <div className="info-group-content">
                        <div className="note">
                          {view.note}
                          <div className="clear"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!!view.adminDataPerson && (
                    <div className="info-group">
                      <div className="info-group-desc">
                        <h3>
                          <FormattedMessage id="work.detail.admin_data" />
                        </h3>
                      </div>
                      <div className="info-group-content">
                        {!!view.adminDataPerson.id && (
                          <div className="id">
                            <h4 className="head-id">
                              <FormattedMessage id="work.attr.adminDataWork.attr.id" />:
                            </h4>
                            {view.adminDataPerson.id}
                          </div>
                        )}
                        {!!view.adminDataPerson.origin && (
                          <div className="origin">
                            <h4 className="head-origin">
                              <FormattedMessage id="work.attr.adminDataWork.attr.origin" />:
                            </h4>
                            {view.adminDataPerson.origin}
                          </div>
                        )}
                        {!!view.adminDataPerson.changed && (
                          <div className="changed">
                            <h4 className="head-changed">
                              <FormattedMessage id="work.attr.adminDataWork.attr.changed" />:
                            </h4>
                            {renderDateTime(view.adminDataPerson.changed)}
                          </div>
                        )}
                        {!!view.adminDataPerson.created && (
                          <div className="created">
                            <h4 className="head-created">
                              <FormattedMessage id="work.attr.adminDataWork.attr.created" />:
                            </h4>
                            {renderDateTime(view.adminDataPerson.created)}
                          </div>
                        )}
                        {!!view.adminDataPerson.deskman && (
                          <div className="deskman">
                            <h4 className="head-deskman">
                              <FormattedMessage id="work.attr.adminDataWork.attr.deskman" />:
                            </h4>
                            {view.adminDataPerson.deskman}
                          </div>
                        )}
                        {!!view.adminDataPerson.editorialStatus && (
                          <div className="editorialStatus">
                            <h4 className="head-editorialStatus">
                              <FormattedMessage id="work.attr.adminDataWork.attr.editorialStatus" />:
                            </h4>
                            {editStatusFilter(view.adminDataPerson.editorialStatus)}
                          </div>
                        )}
                        {!!view.adminDataPerson.personChecked && (
                          <div className="personChecked">
                            <h4 className="head-personChecked">
                              <FormattedMessage id="edit.admin.person_checked_label" />:
                            </h4>
                            {renderDate(new Date(view.adminDataPerson.personChecked))}
                            <div className="clear"></div>
                          </div>
                        )}
                        {!!view.adminDataPerson.dataLastSupplied && (
                          <div className="dataLastSupplied">
                            <h4 className="head-dataLastSupplied">
                              <FormattedMessage id="edit.admin.last_supplied_label" />:
                            </h4>
                            {renderDate(new Date(view.adminDataPerson.dataLastSupplied))}
                            <div className="clear"></div>
                          </div>
                        )}
                        {view.adminDataPerson.hidden !== null && view.adminDataPerson.hidden !== undefined && (
                          <div className="hidden">
                            <h4 className="head-hidden">
                              <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />:
                            </h4>
                            {boolFilter(view.adminDataPerson.hidden)}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </article>
            </>
          )}
        </div>

        <div className="dashboard-tabs">
          <div className="dashboard-tabs-inner">
            <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST', 'ROLE_ADMIN', 'ROLE_EDITOR']}>
              <button
                className={activeDashboard === DashboardType.WORKS || activeDashboard === null ? 'tab active' : 'tab'}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveDashboard(DashboardType.WORKS);
                }}
              >
                <FormattedMessage id="entity.works" />
              </button>
              <button
                className={activeDashboard === DashboardType.ORGS ? 'tab active' : 'tab'}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveDashboard(DashboardType.ORGS);
                }}
              >
                <FormattedMessage id="entity.organisations" />
              </button>
            </OAuth2Gate>

            <button
              className={activeDashboard === DashboardType.PROJECTS ? 'tab active' : 'tab'}
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DashboardType.PROJECTS);
              }}
            >
              <FormattedMessage id="entity.projects" />
            </button>
          </div>
        </div>
        <div className="dasboard">
          {!!dashboardData && (
            <>
              <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST', 'ROLE_ADMIN', 'ROLE_EDITOR']}>
                {activeDashboard === DashboardType.ORGS && !dashboardLoading && dashboardData.organisations ? (
                  <EmbeddedDashboard
                    personOrg={personOrg}
                    entity={DashboardType.ORGS}
                    searchQuery={{
                      q:
                        'id:' +
                        (dashboardData.organisations.length ? dashboardData.organisations.join(' OR id:') : '0'),
                    }}
                  />
                ) : (
                  ''
                )}
                {(activeDashboard === DashboardType.WORKS || activeDashboard === null) && !dashboardLoading ? (
                  <EmbeddedDashboard
                    entity={DashboardType.WORKS}
                    searchQuery={{
                      q: 'person_id:' + id.toString(10),
                    }}
                  />
                ) : (
                  ''
                )}
              </OAuth2Gate>
              {activeDashboard === DashboardType.PROJECTS && !dashboardLoading && dashboardData.projects ? (
                <EmbeddedDashboard
                  entity={DashboardType.PROJECTS}
                  searchQuery={{
                    q: 'id:' + (dashboardData.projects.length ? dashboardData.projects.join(' OR id:') : '0'),
                  }}
                />
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </DetailView>
    </>
  );
}
