/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminDataWorkDTO,
    AdminDataWorkDTOFromJSON,
    AdminDataWorkDTOFromJSONTyped,
    AdminDataWorkDTOToJSON,
    EventDTO,
    EventDTOFromJSON,
    EventDTOFromJSONTyped,
    EventDTOToJSON,
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
    SubjectDTO,
    SubjectDTOFromJSON,
    SubjectDTOFromJSONTyped,
    SubjectDTOToJSON,
    UrlDTO,
    UrlDTOFromJSON,
    UrlDTOFromJSONTyped,
    UrlDTOToJSON,
    WorkDateTypeDTO,
    WorkDateTypeDTOFromJSON,
    WorkDateTypeDTOFromJSONTyped,
    WorkDateTypeDTOToJSON,
    WorkDescriptionDTO,
    WorkDescriptionDTOFromJSON,
    WorkDescriptionDTOFromJSONTyped,
    WorkDescriptionDTOToJSON,
    WorkGeoLocationDTO,
    WorkGeoLocationDTOFromJSON,
    WorkGeoLocationDTOFromJSONTyped,
    WorkGeoLocationDTOToJSON,
    WorkHasPartDTO,
    WorkHasPartDTOFromJSON,
    WorkHasPartDTOFromJSONTyped,
    WorkHasPartDTOToJSON,
    WorkIsPartOfDTO,
    WorkIsPartOfDTOFromJSON,
    WorkIsPartOfDTOFromJSONTyped,
    WorkIsPartOfDTOToJSON,
    WorkIsPartOfOtherDTO,
    WorkIsPartOfOtherDTOFromJSON,
    WorkIsPartOfOtherDTOFromJSONTyped,
    WorkIsPartOfOtherDTOToJSON,
    WorkKeywordDTO,
    WorkKeywordDTOFromJSON,
    WorkKeywordDTOFromJSONTyped,
    WorkKeywordDTOToJSON,
    WorkLanguageDTO,
    WorkLanguageDTOFromJSON,
    WorkLanguageDTOFromJSONTyped,
    WorkLanguageDTOToJSON,
    WorkOrganisationDTO,
    WorkOrganisationDTOFromJSON,
    WorkOrganisationDTOFromJSONTyped,
    WorkOrganisationDTOToJSON,
    WorkOrganisationOtherDTO,
    WorkOrganisationOtherDTOFromJSON,
    WorkOrganisationOtherDTOFromJSONTyped,
    WorkOrganisationOtherDTOToJSON,
    WorkOtherTitleDTO,
    WorkOtherTitleDTOFromJSON,
    WorkOtherTitleDTOFromJSONTyped,
    WorkOtherTitleDTOToJSON,
    WorkPersonDTO,
    WorkPersonDTOFromJSON,
    WorkPersonDTOFromJSONTyped,
    WorkPersonDTOToJSON,
    WorkPersonOtherDTO,
    WorkPersonOtherDTOFromJSON,
    WorkPersonOtherDTOFromJSONTyped,
    WorkPersonOtherDTOToJSON,
    WorkPlaceDTO,
    WorkPlaceDTOFromJSON,
    WorkPlaceDTOFromJSONTyped,
    WorkPlaceDTOToJSON,
    WorkProjectDTO,
    WorkProjectDTOFromJSON,
    WorkProjectDTOFromJSONTyped,
    WorkProjectDTOToJSON,
    WorkProjectOtherDTO,
    WorkProjectOtherDTOFromJSON,
    WorkProjectOtherDTOFromJSONTyped,
    WorkProjectOtherDTOToJSON,
    WorkPublisherDTO,
    WorkPublisherDTOFromJSON,
    WorkPublisherDTOFromJSONTyped,
    WorkPublisherDTOToJSON,
    WorkRelatedIdentifierDTO,
    WorkRelatedIdentifierDTOFromJSON,
    WorkRelatedIdentifierDTOFromJSONTyped,
    WorkRelatedIdentifierDTOToJSON,
    WorkRelatedIdentifierInternalDTO,
    WorkRelatedIdentifierInternalDTOFromJSON,
    WorkRelatedIdentifierInternalDTOFromJSONTyped,
    WorkRelatedIdentifierInternalDTOToJSON,
    WorkTitleAbbreviationDTO,
    WorkTitleAbbreviationDTOFromJSON,
    WorkTitleAbbreviationDTOFromJSONTyped,
    WorkTitleAbbreviationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkDTO
 */
export interface WorkDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkDTO
     */
    id: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WorkDTO
     */
    canEdit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkDTO
     */
    canDelete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WorkDTO
     */
    canRequestDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    applicationCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    applicationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    edition?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    formSubject?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    format?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    grantNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkDTO
     */
    issued?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    medium?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    number?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    numberOfPages?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    numberOfVolumes?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    oldId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    patentFamily?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    patentId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    patentType?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    peerReviewed?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    priorityCountry?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    priorityNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkDTO
     */
    priorityPatentApplication?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    publicationNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    publicationStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    pubtype?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    resourceType?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    subseriesTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    subtitle?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    titleSupplement?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    version?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkDTO
     */
    dataVersion?: string;
    /**
     * 
     * @type {AdminDataWorkDTO}
     * @memberof WorkDTO
     */
    adminDataWork?: AdminDataWorkDTO;
    /**
     * 
     * @type {Array<EventDTO>}
     * @memberof WorkDTO
     */
    events?: Array<EventDTO>;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof WorkDTO
     */
    externalIds?: Array<ExternalIdDTO>;
    /**
     * 
     * @type {Array<UrlDTO>}
     * @memberof WorkDTO
     */
    urls?: Array<UrlDTO>;
    /**
     * 
     * @type {Array<WorkKeywordDTO>}
     * @memberof WorkDTO
     */
    workKeywords?: Array<WorkKeywordDTO>;
    /**
     * 
     * @type {Array<WorkPublisherDTO>}
     * @memberof WorkDTO
     */
    workPublishers?: Array<WorkPublisherDTO>;
    /**
     * 
     * @type {Array<WorkPersonDTO>}
     * @memberof WorkDTO
     */
    workPersons?: Array<WorkPersonDTO>;
    /**
     * 
     * @type {Array<WorkOrganisationDTO>}
     * @memberof WorkDTO
     */
    workOrganisations?: Array<WorkOrganisationDTO>;
    /**
     * 
     * @type {Array<WorkOrganisationOtherDTO>}
     * @memberof WorkDTO
     */
    workOrganisationOthers?: Array<WorkOrganisationOtherDTO>;
    /**
     * 
     * @type {Array<WorkProjectDTO>}
     * @memberof WorkDTO
     */
    workProjects?: Array<WorkProjectDTO>;
    /**
     * 
     * @type {Array<WorkProjectOtherDTO>}
     * @memberof WorkDTO
     */
    workProjectOthers?: Array<WorkProjectOtherDTO>;
    /**
     * 
     * @type {Array<WorkDescriptionDTO>}
     * @memberof WorkDTO
     */
    workDescriptions?: Array<WorkDescriptionDTO>;
    /**
     * 
     * @type {Array<WorkDateTypeDTO>}
     * @memberof WorkDTO
     */
    workDateTypes?: Array<WorkDateTypeDTO>;
    /**
     * 
     * @type {Array<WorkIsPartOfDTO>}
     * @memberof WorkDTO
     */
    workIsPartOfs?: Array<WorkIsPartOfDTO>;
    /**
     * 
     * @type {Array<WorkIsPartOfOtherDTO>}
     * @memberof WorkDTO
     */
    workIsPartOfOthers?: Array<WorkIsPartOfOtherDTO>;
    /**
     * 
     * @type {Array<WorkLanguageDTO>}
     * @memberof WorkDTO
     */
    workLanguages?: Array<WorkLanguageDTO>;
    /**
     * 
     * @type {Array<WorkOtherTitleDTO>}
     * @memberof WorkDTO
     */
    workOtherTitles?: Array<WorkOtherTitleDTO>;
    /**
     * 
     * @type {Array<WorkPersonOtherDTO>}
     * @memberof WorkDTO
     */
    workPersonOthers?: Array<WorkPersonOtherDTO>;
    /**
     * 
     * @type {Array<WorkPlaceDTO>}
     * @memberof WorkDTO
     */
    workPlaces?: Array<WorkPlaceDTO>;
    /**
     * 
     * @type {Array<WorkRelatedIdentifierDTO>}
     * @memberof WorkDTO
     */
    externalRelatedIdentifiers?: Array<WorkRelatedIdentifierDTO>;
    /**
     * 
     * @type {Array<WorkRelatedIdentifierInternalDTO>}
     * @memberof WorkDTO
     */
    internalRelatedIdentifiersSource?: Array<WorkRelatedIdentifierInternalDTO>;
    /**
     * 
     * @type {Array<WorkRelatedIdentifierInternalDTO>}
     * @memberof WorkDTO
     */
    internalRelatedIdentifiersTarget?: Array<WorkRelatedIdentifierInternalDTO>;
    /**
     * 
     * @type {Array<SubjectDTO>}
     * @memberof WorkDTO
     */
    workSubjects?: Array<SubjectDTO>;
    /**
     * 
     * @type {Array<WorkTitleAbbreviationDTO>}
     * @memberof WorkDTO
     */
    workTitleAbbreviations?: Array<WorkTitleAbbreviationDTO>;
    /**
     * 
     * @type {Array<WorkHasPartDTO>}
     * @memberof WorkDTO
     */
    workHasParts?: Array<WorkHasPartDTO>;
    /**
     * 
     * @type {Array<WorkGeoLocationDTO>}
     * @memberof WorkDTO
     */
    workGeoLocations?: Array<WorkGeoLocationDTO>;
}

export function WorkDTOFromJSON(json: any): WorkDTO {
    return WorkDTOFromJSONTyped(json, false);
}

export function WorkDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
        'canDelete': !exists(json, 'canDelete') ? undefined : json['canDelete'],
        'canRequestDelete': !exists(json, 'canRequestDelete') ? undefined : json['canRequestDelete'],
        'applicationCountry': !exists(json, 'applicationCountry') ? undefined : json['applicationCountry'],
        'applicationNumber': !exists(json, 'applicationNumber') ? undefined : json['applicationNumber'],
        'edition': !exists(json, 'edition') ? undefined : json['edition'],
        'formSubject': !exists(json, 'formSubject') ? undefined : json['formSubject'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'grantNumber': !exists(json, 'grantNumber') ? undefined : json['grantNumber'],
        'issued': !exists(json, 'issued') ? undefined : json['issued'],
        'medium': !exists(json, 'medium') ? undefined : json['medium'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'number': !exists(json, 'number') ? undefined : json['number'],
        'numberOfPages': !exists(json, 'numberOfPages') ? undefined : json['numberOfPages'],
        'numberOfVolumes': !exists(json, 'numberOfVolumes') ? undefined : json['numberOfVolumes'],
        'oldId': !exists(json, 'oldId') ? undefined : json['oldId'],
        'patentFamily': !exists(json, 'patentFamily') ? undefined : json['patentFamily'],
        'patentId': !exists(json, 'patentId') ? undefined : json['patentId'],
        'patentType': !exists(json, 'patentType') ? undefined : json['patentType'],
        'peerReviewed': !exists(json, 'peerReviewed') ? undefined : json['peerReviewed'],
        'priorityCountry': !exists(json, 'priorityCountry') ? undefined : json['priorityCountry'],
        'priorityNumber': !exists(json, 'priorityNumber') ? undefined : json['priorityNumber'],
        'priorityPatentApplication': !exists(json, 'priorityPatentApplication') ? undefined : json['priorityPatentApplication'],
        'publicationNumber': !exists(json, 'publicationNumber') ? undefined : json['publicationNumber'],
        'publicationStatus': !exists(json, 'publicationStatus') ? undefined : json['publicationStatus'],
        'pubtype': !exists(json, 'pubtype') ? undefined : json['pubtype'],
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'subseriesTitle': !exists(json, 'subseriesTitle') ? undefined : json['subseriesTitle'],
        'subtitle': !exists(json, 'subtitle') ? undefined : json['subtitle'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'titleSupplement': !exists(json, 'titleSupplement') ? undefined : json['titleSupplement'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'dataVersion': !exists(json, 'dataVersion') ? undefined : json['dataVersion'],
        'adminDataWork': !exists(json, 'adminDataWork') ? undefined : AdminDataWorkDTOFromJSON(json['adminDataWork']),
        'events': !exists(json, 'events') ? undefined : ((json['events'] as Array<any>).map(EventDTOFromJSON)),
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
        'urls': !exists(json, 'urls') ? undefined : ((json['urls'] as Array<any>).map(UrlDTOFromJSON)),
        'workKeywords': !exists(json, 'workKeywords') ? undefined : ((json['workKeywords'] as Array<any>).map(WorkKeywordDTOFromJSON)),
        'workPublishers': !exists(json, 'workPublishers') ? undefined : ((json['workPublishers'] as Array<any>).map(WorkPublisherDTOFromJSON)),
        'workPersons': !exists(json, 'workPersons') ? undefined : ((json['workPersons'] as Array<any>).map(WorkPersonDTOFromJSON)),
        'workOrganisations': !exists(json, 'workOrganisations') ? undefined : ((json['workOrganisations'] as Array<any>).map(WorkOrganisationDTOFromJSON)),
        'workOrganisationOthers': !exists(json, 'workOrganisationOthers') ? undefined : ((json['workOrganisationOthers'] as Array<any>).map(WorkOrganisationOtherDTOFromJSON)),
        'workProjects': !exists(json, 'workProjects') ? undefined : ((json['workProjects'] as Array<any>).map(WorkProjectDTOFromJSON)),
        'workProjectOthers': !exists(json, 'workProjectOthers') ? undefined : ((json['workProjectOthers'] as Array<any>).map(WorkProjectOtherDTOFromJSON)),
        'workDescriptions': !exists(json, 'workDescriptions') ? undefined : ((json['workDescriptions'] as Array<any>).map(WorkDescriptionDTOFromJSON)),
        'workDateTypes': !exists(json, 'workDateTypes') ? undefined : ((json['workDateTypes'] as Array<any>).map(WorkDateTypeDTOFromJSON)),
        'workIsPartOfs': !exists(json, 'workIsPartOfs') ? undefined : ((json['workIsPartOfs'] as Array<any>).map(WorkIsPartOfDTOFromJSON)),
        'workIsPartOfOthers': !exists(json, 'workIsPartOfOthers') ? undefined : ((json['workIsPartOfOthers'] as Array<any>).map(WorkIsPartOfOtherDTOFromJSON)),
        'workLanguages': !exists(json, 'workLanguages') ? undefined : ((json['workLanguages'] as Array<any>).map(WorkLanguageDTOFromJSON)),
        'workOtherTitles': !exists(json, 'workOtherTitles') ? undefined : ((json['workOtherTitles'] as Array<any>).map(WorkOtherTitleDTOFromJSON)),
        'workPersonOthers': !exists(json, 'workPersonOthers') ? undefined : ((json['workPersonOthers'] as Array<any>).map(WorkPersonOtherDTOFromJSON)),
        'workPlaces': !exists(json, 'workPlaces') ? undefined : ((json['workPlaces'] as Array<any>).map(WorkPlaceDTOFromJSON)),
        'externalRelatedIdentifiers': !exists(json, 'externalRelatedIdentifiers') ? undefined : ((json['externalRelatedIdentifiers'] as Array<any>).map(WorkRelatedIdentifierDTOFromJSON)),
        'internalRelatedIdentifiersSource': !exists(json, 'internalRelatedIdentifiersSource') ? undefined : ((json['internalRelatedIdentifiersSource'] as Array<any>).map(WorkRelatedIdentifierInternalDTOFromJSON)),
        'internalRelatedIdentifiersTarget': !exists(json, 'internalRelatedIdentifiersTarget') ? undefined : ((json['internalRelatedIdentifiersTarget'] as Array<any>).map(WorkRelatedIdentifierInternalDTOFromJSON)),
        'workSubjects': !exists(json, 'workSubjects') ? undefined : ((json['workSubjects'] as Array<any>).map(SubjectDTOFromJSON)),
        'workTitleAbbreviations': !exists(json, 'workTitleAbbreviations') ? undefined : ((json['workTitleAbbreviations'] as Array<any>).map(WorkTitleAbbreviationDTOFromJSON)),
        'workHasParts': !exists(json, 'workHasParts') ? undefined : ((json['workHasParts'] as Array<any>).map(WorkHasPartDTOFromJSON)),
        'workGeoLocations': !exists(json, 'workGeoLocations') ? undefined : ((json['workGeoLocations'] as Array<any>).map(WorkGeoLocationDTOFromJSON)),
    };
}

export function WorkDTOToJSON(value?: WorkDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'canEdit': value.canEdit,
        'canDelete': value.canDelete,
        'canRequestDelete': value.canRequestDelete,
        'applicationCountry': value.applicationCountry,
        'applicationNumber': value.applicationNumber,
        'edition': value.edition,
        'formSubject': value.formSubject,
        'format': value.format,
        'grantNumber': value.grantNumber,
        'issued': value.issued,
        'medium': value.medium,
        'note': value.note,
        'number': value.number,
        'numberOfPages': value.numberOfPages,
        'numberOfVolumes': value.numberOfVolumes,
        'oldId': value.oldId,
        'patentFamily': value.patentFamily,
        'patentId': value.patentId,
        'patentType': value.patentType,
        'peerReviewed': value.peerReviewed,
        'priorityCountry': value.priorityCountry,
        'priorityNumber': value.priorityNumber,
        'priorityPatentApplication': value.priorityPatentApplication,
        'publicationNumber': value.publicationNumber,
        'publicationStatus': value.publicationStatus,
        'pubtype': value.pubtype,
        'resourceType': value.resourceType,
        'size': value.size,
        'subseriesTitle': value.subseriesTitle,
        'subtitle': value.subtitle,
        'title': value.title,
        'titleSupplement': value.titleSupplement,
        'version': value.version,
        'dataVersion': value.dataVersion,
        'adminDataWork': AdminDataWorkDTOToJSON(value.adminDataWork),
        'events': value.events === undefined ? undefined : ((value.events as Array<any>).map(EventDTOToJSON)),
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
        'urls': value.urls === undefined ? undefined : ((value.urls as Array<any>).map(UrlDTOToJSON)),
        'workKeywords': value.workKeywords === undefined ? undefined : ((value.workKeywords as Array<any>).map(WorkKeywordDTOToJSON)),
        'workPublishers': value.workPublishers === undefined ? undefined : ((value.workPublishers as Array<any>).map(WorkPublisherDTOToJSON)),
        'workPersons': value.workPersons === undefined ? undefined : ((value.workPersons as Array<any>).map(WorkPersonDTOToJSON)),
        'workOrganisations': value.workOrganisations === undefined ? undefined : ((value.workOrganisations as Array<any>).map(WorkOrganisationDTOToJSON)),
        'workOrganisationOthers': value.workOrganisationOthers === undefined ? undefined : ((value.workOrganisationOthers as Array<any>).map(WorkOrganisationOtherDTOToJSON)),
        'workProjects': value.workProjects === undefined ? undefined : ((value.workProjects as Array<any>).map(WorkProjectDTOToJSON)),
        'workProjectOthers': value.workProjectOthers === undefined ? undefined : ((value.workProjectOthers as Array<any>).map(WorkProjectOtherDTOToJSON)),
        'workDescriptions': value.workDescriptions === undefined ? undefined : ((value.workDescriptions as Array<any>).map(WorkDescriptionDTOToJSON)),
        'workDateTypes': value.workDateTypes === undefined ? undefined : ((value.workDateTypes as Array<any>).map(WorkDateTypeDTOToJSON)),
        'workIsPartOfs': value.workIsPartOfs === undefined ? undefined : ((value.workIsPartOfs as Array<any>).map(WorkIsPartOfDTOToJSON)),
        'workIsPartOfOthers': value.workIsPartOfOthers === undefined ? undefined : ((value.workIsPartOfOthers as Array<any>).map(WorkIsPartOfOtherDTOToJSON)),
        'workLanguages': value.workLanguages === undefined ? undefined : ((value.workLanguages as Array<any>).map(WorkLanguageDTOToJSON)),
        'workOtherTitles': value.workOtherTitles === undefined ? undefined : ((value.workOtherTitles as Array<any>).map(WorkOtherTitleDTOToJSON)),
        'workPersonOthers': value.workPersonOthers === undefined ? undefined : ((value.workPersonOthers as Array<any>).map(WorkPersonOtherDTOToJSON)),
        'workPlaces': value.workPlaces === undefined ? undefined : ((value.workPlaces as Array<any>).map(WorkPlaceDTOToJSON)),
        'externalRelatedIdentifiers': value.externalRelatedIdentifiers === undefined ? undefined : ((value.externalRelatedIdentifiers as Array<any>).map(WorkRelatedIdentifierDTOToJSON)),
        'internalRelatedIdentifiersSource': value.internalRelatedIdentifiersSource === undefined ? undefined : ((value.internalRelatedIdentifiersSource as Array<any>).map(WorkRelatedIdentifierInternalDTOToJSON)),
        'internalRelatedIdentifiersTarget': value.internalRelatedIdentifiersTarget === undefined ? undefined : ((value.internalRelatedIdentifiersTarget as Array<any>).map(WorkRelatedIdentifierInternalDTOToJSON)),
        'workSubjects': value.workSubjects === undefined ? undefined : ((value.workSubjects as Array<any>).map(SubjectDTOToJSON)),
        'workTitleAbbreviations': value.workTitleAbbreviations === undefined ? undefined : ((value.workTitleAbbreviations as Array<any>).map(WorkTitleAbbreviationDTOToJSON)),
        'workHasParts': value.workHasParts === undefined ? undefined : ((value.workHasParts as Array<any>).map(WorkHasPartDTOToJSON)),
        'workGeoLocations': value.workGeoLocations === undefined ? undefined : ((value.workGeoLocations as Array<any>).map(WorkGeoLocationDTOToJSON)),
    };
}


