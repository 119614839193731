import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { history } from 'historyUtil';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export const NavItem: React.FC<{ to: string; onClick?: () => void }> = ({ onClick, to, children }) => {

  return (
    <li
      onClick={onClick}
      sx={{
        mx: [0, 0, 0, '10px', '1em', '1em'],
        mt: ['50px', '50px', '50px', '50px', 11, 11],
        display: ['list-item', 'list-item', 'list-item', 'inline-block', 'inline-block', 'inline-block'],
        fontSize: ['18px', '18px', '18px', 3, 3, 3],
        listStyle: 'none',
        py: ['8px', '8px', '8px', 0, 0, 0],
        pl: ['18px', '18px', '18px', 0, 0, 0],
        borderTopStyle: ['solid', 'solid', 'solid', 'none', 'none', 'none'],
        borderBottomStyle: ['solid', 'solid', 'solid', 'none', 'none', 'none'],
        borderTopColor: 'light',
        borderBottomColor: 'light',
        borderWidth: ['1.5px', '1.5px', '1.5px', 'none', 'none', 'none']
      }}
    >
      {to.startsWith('http') && (
        <a
          href={to}
          sx={{
            fontWeight: 'bold',
            textDecoration: 'none',
            color: 'primary',
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </a>
      )}
      {!to.startsWith('http') && (
        <NavLink
          to={to}
          sx={{
            fontWeight: 'bold',
            textDecoration: 'none',
            color: 'primary',
            whiteSpace: 'nowrap',
          }}
        >
          {children}
        </NavLink>
      )}
    </li>
  );
};

interface Props {
  items: {
    icon?: string | ReactNode;
    onClick?: () => void;
    name: string;
    path: string;
  }[];
  open?: boolean;
  onClick?: () => void;
  onCloseMenu?: () => void;
}

// TODO: Dropdown extends page on the edges when open, use PopperJS to fix this.
export const NavItemDropdown: React.FC<Props> = ({ items, children }) => {
  // TODO: warum ist das Dropdown selbst ein ul mit einem li

  const refresh = (path) => {
    if(path === window.location.pathname && window.location.search === ""){
      history.go(0);
    }
    else if(path === '/works/add' && window.location.pathname.includes('/works/add/')){
      history.push('/works/add');
      history.go(0);
    }
  };

  return (
    <li key={'label'}
      sx={{
        mx: [0, 0, 0, '10px', '1em', '1em'],
        mt: ['50px', '50px', '50px', '50px', 11, 11],
        display: 'inline-block',
        color: 'primary',
        fontWeight: 'bold',
        cursor: 'pointer',
        fontSize: 3,
        whiteSpace: "nowrap",
        ul: {
          display: 'none',
        },
        '&:hover ul': {
          display: 'block',
        },
      }}
    >
      {children}{' '}
      <i
        className="icon-pfeil_suche_aufgeklappt"
        sx={{
          fontSize: 0,
          '&:before': {
            color: 'primary',
          },
        }}
      />
      <ul
        sx={{
          listStyleType: 'none',
          position: 'absolute',
          backgroundColor: 'header',
          zIndex: 2,
        }}
      >
        {items.map((item, idx) => {
          if (!item.name){
            return <div key={idx} />;
          }
          return (
            <li key={idx}
              sx={{
                p: 5,
              }}
            >
              <NavLink
                to={item.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  color: 'primary',
                  whiteSpace: 'nowrap',
                  i: {
                    width: '45px',
                    textAlign: 'center',
                  },
                  'i:before': {
                    color: 'primary',
                  },
                  '&:hover': {
                    color: 'secondary',
                    'i:before': {
                      color: 'secondary',
                    },
                  },
                }}
                onClick={(e) => {
                  if (item.onClick) {
                    e.preventDefault();
                    item.onClick();
                  }
                  if (window.location.href.includes('?q=')) {
                    history.push(item.path);
                    history.go(0);
                  } else {
                    refresh(item.path);
                  }
                }}
              >
                {item.icon && typeof item.icon == "string" && (
                  <i
                    className={item.icon}
                    sx={{
                      mr: 2,
                    }}
                  />

                )}
                {item.icon && typeof item.icon == "object" && (
                  item.icon
                  )}{' '}
                {item.name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export const CategoryToggle: React.FC<{ open?: boolean; onClick: () => void }> = ({ children, open, onClick }) => {
  return (
    <div
      sx={{
        py: '8px',
        pl: '18px',
        cursor: 'pointer',
        color: open ? 'white' : 'primary',
        backgroundColor: open ? 'primary' : 'transparent',
        justifyContent: 'space-between',
        fontWeight: 'bold',
      }}
      onClick={onClick}
    >
      <span>{children}</span>
      {!open && <i className="icon-pfeil_suche_zugeklappt icon-4x" sx={{ ml: '10px', "&:before": {color: 'primary', fontSize: '0.65em'}, mt: '3px' }} />}{' '}
      {open && <i className="icon-pfeil_suche_aufgeklappt" sx={{ "&:before": {color: open ? 'white' : 'category_toggle', fontSize: '0.55em'}, mt: '3px', ml:'5px' }}/>}
    </div>
  );
};

export const NavItemDropdownMobile: React.FC<Props> = ({ items, children, open, onClick, onCloseMenu }) => {
  // TODO: warum ist das Dropdown selbst ein ul mit einem li

  const refresh = (path): void => {
    if(path === window.location.pathname){
      window.location.assign(path);
    }
    else if(window.location.pathname.includes(path)){
      window.location.assign(path);
    }
  };

  return (
    <li key={'label'}
      sx={{
        display: 'list-item',
        listStyle: 'none',
        borderBottomStyle: 'solid',
        borderBottomColor: 'light',
        borderWidth: '1.5px',
        color: 'primary',
        fontWeight: 'bold',
        cursor: 'pointer',
        fontSize: '18px',
        whiteSpace: "nowrap",
      }}
    >
      <CategoryToggle open={open} onClick={onClick}>
        {children}
      </CategoryToggle>
      {open && (
        <div
          sx={{
            "> div:last-child": {
              borderBottomStyle: 'none',
            },
          }}
        >
        {items.map((item, idx) => {
          if (!item.name){
            return <div key={idx} />;
          }
          return (
            <div key={idx}
              sx={{
                py: '8px',
                pl: '18px',
                borderBottomStyle: 'solid',
                borderBottomColor: 'light',
                borderWidth: '1.5px',
              }}
              onClick={onCloseMenu}
            >
              <NavLink
                to={item.path}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  textDecoration: 'none',
                  color: 'primary',
                  whiteSpace: 'nowrap',
                  i: {
                    width: '45px',
                    textAlign: 'center',
                  },
                  'i:before': {
                    color: 'primary',
                  },
                  '&:hover': {
                    color: 'secondary',
                    'i:before': {
                      color: 'secondary',
                    },
                  },
                }}
                onClick={(e) => {
                  if (item.onClick) {
                    e.preventDefault();
                    item.onClick();
                  }
                  if (window.location.href.includes('?q=')) {
                    window.location.assign(item.path);
                  } else {
                    refresh(item.path);
                  }
                }}
              >
                {item.icon && typeof item.icon == "string" && (
                  <i
                    className={item.icon}
                    sx={{
                      mr: 2,
                    }}
                  />

                )}
                {item.icon && typeof item.icon == "object" && (
                  item.icon
                  )}{' '}
                {item.name}
              </NavLink>
            </div>
          );
        })}
        </div>
      )}
    </li>
  );
};

