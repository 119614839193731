/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrganisationDTO,
    OrganisationDTOFromJSON,
    OrganisationDTOToJSON,
    PatchDocument,
    PatchDocumentFromJSON,
    PatchDocumentToJSON,
} from '../models';

export interface CreateOrganisationRequest {
    organisationDTO: OrganisationDTO;
}

export interface CreateOrganisationsRequest {
    organisationDTO: Array<OrganisationDTO>;
}

export interface DeleteOrganisationRequest {
    organisationId: number;
}

export interface GetPersonsInOrganisationRequest {
    organisationId: number;
}

export interface GetPersonsIncludingChildOrgasRequest {
    organisationId: number;
}

export interface GetReducedOrganisation1Request {
    organisationId: number;
    accept?: string;
}

export interface GetWorksByOrganisationMembersRequest {
    organisationId: number;
}

export interface GetWorksOfOrganisationRequest {
    organisationId: number;
}

export interface UpdateOrganisationRequest {
    organisationId: number;
    patchDocument?: Array<PatchDocument>;
}

/**
 * 
 */
export class OrganisationApi extends runtime.BaseAPI {

    /**
     */
    async createOrganisationRaw(requestParameters: CreateOrganisationRequest): Promise<runtime.ApiResponse<OrganisationDTO>> {
        if (requestParameters.organisationDTO === null || requestParameters.organisationDTO === undefined) {
            throw new runtime.RequiredError('organisationDTO','Required parameter requestParameters.organisationDTO was null or undefined when calling createOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganisationDTOToJSON(requestParameters.organisationDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationDTOFromJSON(jsonValue));
    }

    /**
     */
    async createOrganisation(requestParameters: CreateOrganisationRequest): Promise<OrganisationDTO> {
        const response = await this.createOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createOrganisationsRaw(requestParameters: CreateOrganisationsRequest): Promise<runtime.ApiResponse<Array<OrganisationDTO>>> {
        if (requestParameters.organisationDTO === null || requestParameters.organisationDTO === undefined) {
            throw new runtime.RequiredError('organisationDTO','Required parameter requestParameters.organisationDTO was null or undefined when calling createOrganisations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.organisationDTO.map(OrganisationDTOToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganisationDTOFromJSON));
    }

    /**
     */
    async createOrganisations(requestParameters: CreateOrganisationsRequest): Promise<Array<OrganisationDTO>> {
        const response = await this.createOrganisationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteOrganisationRaw(requestParameters: DeleteOrganisationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling deleteOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrganisation(requestParameters: DeleteOrganisationRequest): Promise<void> {
        await this.deleteOrganisationRaw(requestParameters);
    }

    /**
     */
    async getPersonsInOrganisationRaw(requestParameters: GetPersonsInOrganisationRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getPersonsInOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/{organisationId}/persons`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getPersonsInOrganisation(requestParameters: GetPersonsInOrganisationRequest): Promise<Array<number>> {
        const response = await this.getPersonsInOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPersonsIncludingChildOrgasRaw(requestParameters: GetPersonsIncludingChildOrgasRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getPersonsIncludingChildOrgas.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/{organisationId}/persons/childs`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getPersonsIncludingChildOrgas(requestParameters: GetPersonsIncludingChildOrgasRequest): Promise<Array<number>> {
        const response = await this.getPersonsIncludingChildOrgasRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getReducedOrganisation1Raw(requestParameters: GetReducedOrganisation1Request): Promise<runtime.ApiResponse<OrganisationDTO>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getReducedOrganisation1.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.accept !== undefined && requestParameters.accept !== null) {
            headerParameters['Accept'] = String(requestParameters.accept);
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationDTOFromJSON(jsonValue));
    }

    /**
     */
    async getReducedOrganisation1(requestParameters: GetReducedOrganisation1Request): Promise<OrganisationDTO> {
        const response = await this.getReducedOrganisation1Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWorksByOrganisationMembersRaw(requestParameters: GetWorksByOrganisationMembersRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getWorksByOrganisationMembers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/{organisationId}/works/persons`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getWorksByOrganisationMembers(requestParameters: GetWorksByOrganisationMembersRequest): Promise<Array<number>> {
        const response = await this.getWorksByOrganisationMembersRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getWorksOfOrganisationRaw(requestParameters: GetWorksOfOrganisationRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getWorksOfOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/{organisationId}/works`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getWorksOfOrganisation(requestParameters: GetWorksOfOrganisationRequest): Promise<Array<number>> {
        const response = await this.getWorksOfOrganisationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateOrganisationRaw(requestParameters: UpdateOrganisationRequest): Promise<runtime.ApiResponse<OrganisationDTO>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling updateOrganisation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/organisation/{organisationId}`.replace(`{${"organisationId"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.patchDocument.map(PatchDocumentToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationDTOFromJSON(jsonValue));
    }

    /**
     */
    async updateOrganisation(requestParameters: UpdateOrganisationRequest): Promise<OrganisationDTO> {
        const response = await this.updateOrganisationRaw(requestParameters);
        return await response.value();
    }

}
