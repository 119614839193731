import React, { ReactElement, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl, WrappedComponentProps as IntlProps, IntlShape } from 'react-intl';
import { WorkDTO } from 'api/core/models';
import { Main } from 'BaseLayout/components/base-layout/main';
import { DetailEditProps, EditMode } from '../types';
import { SelectInput } from 'BaseLayout/components/form/generic/Select';
import { WorkNavigation } from '../components/WorkNavigation';
import { pages, pubtypeEntityMapping } from '../data';
import { GetFieldsTypeEnum, GetGroupsTypeEnum, PubtypeServiceApi } from 'api/pubtype';
import { connectOAuth2, OAuth2AwareProps } from '../../Auth/utils/connect';
import { getWorkComponent } from '../workComponents';
import { Redirect, Route } from 'react-router';
import { ConfirmDialog } from '../components/ConfirmDialog';
import { DeleteWork } from '../components/DeleteWork';
import { Link } from "theme-ui";
import { Link as LinkRouter, useParams } from 'react-router-dom';
import { OAuth2Gate } from '../../Auth/components';
import { getLogger } from '../../log';
import { AbortButton, SubmitButton } from 'Edit/components/SubmitAbortButton';
import { Flex, Grid, Styled } from 'theme-ui';
import { apiLostFields, pubtypeToEnumKey } from '../utils';
import { useHistory } from "react-router-dom";
import { ThemeGate } from 'BaseLayout/utils';
import { Button } from '@theme-ui/components';
import ImportDoiIsbn from 'BaseLayout/assets/img/misc/doi_isbn_importieren.png';
import UploadPublist from 'BaseLayout/assets/img/misc/publikationsliste_hochladen.png';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { OAuth2Container } from '../../Auth/containers/OAuth2Container';
import { Validation } from '../validation/interfaces';
import { validate } from '../validation/validate';
import { Subscribe } from 'unstated';
import { isValid } from '../validation/isValid';
import { getThemeNameFromAuth } from 'BaseLayout/components';
import { PeerReviewPopup } from 'Edit/components/PeerReviewPopup';

const logger = getLogger('Edit/pages/Work');

const messages = defineMessages({
  pubtype: { id: 'work.attr.pubtype' },
  popupHeadline: { id: 'popup.warningFieldsDeletedOnPubtype.headline' },
  popupText: { id: 'popup.warningFieldsDeletedOnPubtype.text' },
  popupAbort: { id: 'popup.button.abort' },
  popupConfirm: { id: 'popup.button.confirm' },
  validationDateIssuedInvalid: { id: 'validation.dateIssued.invalid' },
});


const createPubtypePopup = (
  oldPubtype: string,
  newPubtype: string,
  differences: string[],
  updateField: (arg0: string, arg1: any) => void,
  intl: IntlShape,
  setPopup: (value) => void,
): Popup => {
  return {
    headline: intl.formatMessage(messages.popupHeadline),
    text: `${intl.formatMessage(messages.popupText)}: ${differences.join(', ').toLowerCase()}`,
    buttons: [
      {
        label: intl.formatMessage(messages.popupAbort),
        onClick: () => {
          updateField('pubtype', oldPubtype);
          setPopup(null);
        },
      },
      {
        label: intl.formatMessage(messages.popupConfirm),
        onClick: () => {
          updateField('pubtype', newPubtype);
          setPopup(null);
        },
      },
    ],
  };
};


export type Props = OAuth2AwareProps<DetailEditProps<WorkDTO> & IntlProps>;

interface Popup {
  headline: string;
  text: string;
  buttons: {
    label: string;
    onClick: () => void;
  }[];
}

const InnerDetailEditWork: React.FC<Props> = (props) => {
  const history = useHistory();
  const intl: IntlShape = useIntl();
  const params: any = useParams();

  const api = props.apiWithAuth(new PubtypeServiceApi());

  const [statePubtype, setStatePubtype] = useState(params.pubtype);
  const [popup, setPopup] = useState(null);
  const [popupPeerReview, setPopupPeerReview] = useState(null);
  const [latestPubtype, setLatestPubtype] = useState('');
  const [pubtypeGroup, setPubtypeGroup] = useState(null);
  const [fieldsOfGroup, setFieldsOfGroup] = useState([]);
  const [deleteInit, setDeleteInit] = useState<boolean>(false);
  const [deleteRequest, setDeleteRequest] = useState<boolean>(false);

  useEffect(()=> {
    if (props.modifiedModel.pubtype !== statePubtype){
      const p = statePubtype || props.modifiedModel.pubtype;
      updateField('pubtype', p);
      setStatePubtype(p);
    }

    if (props.modifiedModel && props.modifiedModel.pubtype) {
      if (latestPubtype === props.modifiedModel.pubtype) {
        return;
      }
      Promise.all([
        api
          .getGroups({
            type: GetGroupsTypeEnum[pubtypeToEnumKey(props.modifiedModel.pubtype)],
          })
          .then((results: string[]): string => results[0]),
        api.getFields({
          type: GetFieldsTypeEnum[pubtypeToEnumKey(props.modifiedModel.pubtype)],
        }),
      ])
        .then((results: [string, string[]]): void => {
          setStatePubtype(props.modifiedModel.pubtype);
          setLatestPubtype(props.modifiedModel.pubtype);
          setPubtypeGroup(results[0]);
          setFieldsOfGroup(results[1]);
        })
        .catch((e: Error): void => {
          logger.error(e);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestPubtype, props.modifiedModel, props.modifiedModel.pubtype, statePubtype, params.key]);

  const { redirect, updateField, modifiedModel } = props;
  const { pubtype, adminDataWork } = props.modifiedModel;

  if (!modifiedModel) return <FormattedMessage id="edit.loadling_text" />;
  if (redirect) return <Redirect to={redirect} />;

  // Erzeuge ein Set aus Seiten-Keys mit allen Seiten, auf denen mindestens
  // eine Property des Pubtypes steht sowie der jeweils zu validierenden Fields
  const availablePages: { [key: string]: Set<string> } = {};
  Object.entries(pages).forEach((page: [string, {key: string; fields: string[]}[]]): void => {
    page[1].forEach((value: { key: string; fields: string[]}): void => {
      if (fieldsOfGroup.includes(value.key)) {
        if(!availablePages.hasOwnProperty(page[0])) {
          availablePages[page[0]] = new Set<string>();
        }
        for(const field of value.fields) {
          availablePages[page[0]].add(field);
        }
      }
    });
  });
  return (
    <Subscribe to={[OAuth2Container]}>
      {(container: OAuth2Container) => {
        const themeName =  getThemeNameFromAuth(container);
        const validation: Validation = (!!pubtype) ? validate(modifiedModel, adminDataWork, container.authorizer(), props.mode === EditMode.INSERT, 'work_' + pubtypeEntityMapping[pubtype]) : undefined;
        return (
          <>
            {deleteInit &&
              <DeleteWork
                workId={props.id}
                workTitle={props.currentModel.title}
                isDeleteRequest={deleteRequest}
                onAbort={() => setDeleteInit(false)}
                onSuccess={() => history.push("/search/works")}
                onError={(e) => {
                  switch (e.status) {
                    case 400:
                      alert("Fehler beim Löschen: Es sind weitere Werke mit dieser Entität verknüpft!");
                      break;
                    default:
                      alert("Fehler beim Löschen!");
                      break;
                  }
                  setDeleteInit(false);
                }}
              />
            }
          <Main className="detail-edit">
            {!!popup && (
              <ConfirmDialog
                headline={popup.headline}
                text={popup.text}
                buttons={popup.buttons}
              />
            )}
            {!!popupPeerReview && (
              <PeerReviewPopup
                onClose={() => setPopupPeerReview(false)}
                updateField={updateField}
                submit={props.submit}
              />
            )}


            {!popup && (
              <form>
                <div className="dashboard">
                  <Flex sx={{
                    height: "100%",
                    alignItems: "stretch"
                  }}>
                    <Flex sx={{
                      flexDirection: "column",
                      minWidth: '25em',
                      maxWidth: "25em",
                      borderRightWidth: "1px",
                      borderRightStyle: "solid",
                      borderRightColor: "light",
                    }}>
                      <Styled.h1
                        sx={{
                          mb: 3,
                          pt:4,
                          textAlign: 'start',
                          pl: '20px'
                        }}
                      >
                        {modifiedModel.id == null ? <FormattedMessage id="work.create" /> : <FormattedMessage id="work.edit" />}
                      </Styled.h1>
                      <div sx={{px: '27px', py: '12px'}}>
                        <OAuth2Gate anyRoles={['ROLE_USER']}>
                          <SelectInput
                            required={false}
                            listPartition={'all_user'}
                            value={pubtype}
                            name={intl.formatMessage(messages.pubtype)}
                            inputName={'pubtype'}
                            list={'pubtype_list'}
                            onChange={(newPubtype: string): void => {
                              logger.debug(`Switch pubtype`);
                              apiLostFields(api, pubtype, newPubtype)
                                .then((differences: string[]) => {
                                  if (differences.length < 1) {
                                    updateField('pubtype', newPubtype);
                                  } else {
                                    setPopup(createPubtypePopup(pubtype, newPubtype, differences, updateField, intl, setPopup));
                                  }
                                })
                                .catch((e: Error): void => {
                                  logger.error(e);
                                  updateField('pubtype', pubtype);
                                  setPopup(null);
                                });
                            }}
                          />
                        </OAuth2Gate>
                        <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
                          <SelectInput
                            required={false}
                            listPartition={'all'}
                            value={pubtype}
                            name={intl.formatMessage(messages.pubtype)}
                            inputName={'pubtype'}
                            list={'pubtype_list'}
                            onChange={(newPubtype: string): void => {
                              logger.debug(`Switch pubtype`);
                              apiLostFields(api, pubtype, newPubtype)
                                .then((differences: string[]) => {
                                  if (differences.length < 1) {
                                    setStatePubtype(newPubtype);
                                    updateField('pubtype', newPubtype);
                                  } else {
                                    setPopup(createPubtypePopup(pubtype, newPubtype, differences, updateField, intl, setPopup));
                                  }
                                })
                                .catch((e: Error): void => {
                                  logger.error(e);
                                  setStatePubtype(pubtype);
                                  updateField('pubtype', pubtype);
                                  setPopup(null);
                                });
                            }}
                          />
                        </OAuth2Gate>
                      </div>
                      {!!pubtype && (
                        <WorkNavigation
                          validation={validation}
                          pubtype={statePubtype}
                          id={props.modifiedModel.id}
                          pages={availablePages}
                        />
                      )}
                      {modifiedModel.id == null &&
                      <div sx={{ width: 'fit-content', px: '27px', pb: '12px', pt: pubtype != null ? '25px' : '3px' }}>
                        <div sx={{
                          button: {
                            px: '12px', py: '8px'
                          },
                          mb: '35px'
                        }}>
                          <LinkRouter sx={{color: "white", fontWeight: '600'}} to="/search/works">
                            <Button>
                              <FormattedMessage id="work.import.button.search" />
                            </Button>
                          </LinkRouter>
                        </div>
                        <div>
                          <LinkRouter sx={{
                              width: '100%',
                              color: 'primary',
                              textDecoration: 'none',
                              border:'3px solid',
                              backgroundColor: 'white',
                              p: '8px 8px 2.1px 8px',
                              display: 'inline-flex'
                              }}
                            to="/import">
                            <div>
                              <img src={ImportDoiIsbn} sx={{ width: "55px" }} />
                            </div>
                            <div sx={{ position: 'relative', ml: '0.75em', bottom: themeName === 'uwh' ? '0px' : '3px' }}>
                              <FormattedMessage id="work.import_by_external_id" />
                            </div>
                          </LinkRouter>
                        </div>
                        <ThemeGate themes={["rub"]}>
                          <div>
                            <LinkRouter sx={{
                                width: '100%',
                                color: 'primary',
                                textDecoration: 'none',
                                border:'3px solid',
                                backgroundColor: 'white',
                                p: '7px 8px 1px 8px',
                                display: 'inline-flex',
                                mt: '0.75em'
                              }}
                              to="/upload">
                              <div>
                                <img src={UploadPublist} sx={{ width: "25px" }} />
                              </div>
                              <div sx={{ position: 'relative', ml: '0.75em', bottom: '3px' }}>
                                <FormattedMessage id="work.upload_publist" />
                              </div>
                            </LinkRouter>
                          </div>
                        </ThemeGate>
                        <ThemeGate themes={["hhu"]}>
                          <div>
                            <Subscribe to={[OAuth2Container]}>
                            {(container: OAuth2Container) => {
                              const { user_givenname, user_surname } = container.authorizer().getSession();
                              return (
                              <Link href={`mailto:${intl.formatMessage({ id: "hhu.report_publist.email" })}?subject=${intl.formatMessage({ id: "hhu.report_publist.email.subject(givenname, surname)" }, { givenname: user_givenname, surname: user_surname })}&body=${intl.formatMessage({ id: "hhu.report_publist.email.body" })}`}>
                                <FormattedMessage id="work.report_publist" />
                              </Link>
                              );
                            }}
                            </Subscribe>
                          </div>
                        </ThemeGate>
                      </div>
                      }
                    </Flex>
                    <div sx={{maxWidth: "1000px", width: "100%",  py: 8, m: "0 auto", px: 8}}>
                      {!!pubtype && !!pubtypeGroup && (
                        <React.Fragment>
                          <Grid sx={{
                            gridTemplateColumns: "1fr 1fr",
                            alignItems: "end",
                            gridGap: "1em",
                            // default to dual col
                            "*": {
                              gridColumn: "1/3"
                            }
                          }}>
                            <Route exact path={`/works/add`} render={({match})=>{
                              if (!!props.modifiedModel.pubtype){
                                return <Redirect to={`${match.url}/${props.modifiedModel.pubtype}/basic`} />;
                              }
                              return <></>;
                            }} />
                            <Route exact path={`/import`} render={({match})=>{
                              if (!!props.modifiedModel.pubtype){
                                return <Redirect to={`${match.url}/${props.modifiedModel.pubtype}/basic`} />;
                              }
                              return <></>;
                            }} />
                            <Route exact path={`/work/:id/edit`} render={({match})=>{
                                return <Redirect to={`${match.url}/basic`} />;
                            }} />
                            <Route path={["/works/add/:pubtype/:key", "/work/:id/edit/:key", "/import/:pubtype/:key"]} render={({match, location})=>{
                              // console.log(match)
                              // redirect on pubtype switch
                              return <>
                                {props.mode === EditMode.INSERT && params.pubtype !== props.modifiedModel.pubtype && !location.pathname.startsWith('/import') &&
                                  <Redirect to={`/works/add/${props.modifiedModel.pubtype}/basic`} />
                                }
                                {props.mode === EditMode.INSERT && params.pubtype !== props.modifiedModel.pubtype && location.pathname.startsWith('/import') &&
                                  <Redirect to={`/import/${props.modifiedModel.pubtype}/basic`} />
                                }
                                {(pages[match.params.key] ? pages[match.params.key] : []).map(
                                  (field: { key: string; fields: string[] }): ReactElement =>(
                                      getWorkComponent(
                                        api,
                                        field.key,
                                        modifiedModel,
                                        pubtypeGroup,
                                        intl,
                                        updateField,
                                        validation
                                      )
                                    )
                                )}
                              </>;
                            }} />
                          </Grid>
                          <div sx={{mt: '1em', display: 'inline-flex'}}>
                            <SubmitButton
                            disabled={!props.submitActive || !isValid(validation)}
                            onSubmit={() => {
                              if(
                                (modifiedModel.pubtype === 'article_overview' || modifiedModel.pubtype === 'article_journal') &&
                                  modifiedModel.externalIds &&
                                  modifiedModel?.externalIds.some(item => item.type === 'scopus' || item.type === 'wos') &&
                                  modifiedModel.peerReviewed === 'no_value'
                              ) {
                                setPopupPeerReview(true);
                              }
                              else {
                                props.submit();
                              }
                            }}
                            >
                              <FormattedMessage id="edit.submit_btn" />
                            </SubmitButton>

                            {props.mode === EditMode.INSERT &&
                              <AbortButton>
                                <FormattedMessage id="edit.abort_btn" />
                              </AbortButton>
                            }

                            {props.mode === EditMode.UPDATE &&
                            <>
                            <OAuth2Gate anyRoles={['ROLE_ADMIN']}>
                              <Button
                                disabled={!props.deleteActive}
                                onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                                  e.preventDefault();
                                  setDeleteInit(true);
                                  setDeleteRequest(false);
                                }}
                              >
                                <FormattedMessage id="button.Delete" />
                              </Button>
                            </OAuth2Gate>

                            <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
                              <Button
                                disabled={!props.deleteActive}
                                onClick={async (e: React.FormEvent<HTMLButtonElement>): Promise<void> => {
                                  e.preventDefault();
                                  setDeleteInit(true);
                                  setDeleteRequest(true);
                                }}
                              >
                                <FormattedMessage id="button.Delete.Request" />
                              </Button>
                            </OAuth2Gate>
                            </>
                            }
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                    </Flex>
                </div>
              </form>
            )}
          </Main>
          </>
        );
      }}
    </Subscribe>
  );
};

export const DetailEditWork = connectOAuth2(InnerDetailEditWork);
