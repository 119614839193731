import * as React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';


export interface Props {
  className?: string;
}


export const Main: React.FC<Props> = ({className, children}) => {

  className = !!className ? className : '';

  const classes = ['main'];

  return (
    <main style={{flexGrow: 1}} sx={{
      height: "100%",
      flexGrow: 1,
      flexShrink: 1,
      pb: 10
}} className={classes.join(' ') + (className ? ' ' + className : '')}>
      <div sx={{
        position: "relative",
        height: 'auto',
        }}>{children}</div>
    </main>
  );
};
