/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkPersonOtherDTO
 */
export interface WorkPersonOtherDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkPersonOtherDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkPersonOtherDTO
     */
    givenname?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPersonOtherDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPersonOtherDTO
     */
    role?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPersonOtherDTO
     */
    sequence?: number;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof WorkPersonOtherDTO
     */
    externalIds?: Array<ExternalIdDTO>;
}

export function WorkPersonOtherDTOFromJSON(json: any): WorkPersonOtherDTO {
    return WorkPersonOtherDTOFromJSONTyped(json, false);
}

export function WorkPersonOtherDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkPersonOtherDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'givenname': !exists(json, 'givenname') ? undefined : json['givenname'],
        'surname': !exists(json, 'surname') ? undefined : json['surname'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
    };
}

export function WorkPersonOtherDTOToJSON(value?: WorkPersonOtherDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'givenname': value.givenname,
        'surname': value.surname,
        'role': value.role,
        'sequence': value.sequence,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
    };
}


