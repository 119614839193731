import React from 'react';
import { AdminDataWorkDTO, AdminDataWorkOpenAccessFundedDTO, WorkDTO } from '../../../../api/core/models';
import { defineMessages, injectIntl, IntlShape, useIntl, WrappedComponentProps as IntlProps } from 'react-intl';
import { MultiInputControls } from '../../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultAdminDataWorkOpenAccessFunded } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  funds: {
    id: 'work.attr.adminDataWork.attr.adminDataWorkOpenAccess.attr.adminDataWorkOpenAccessFunded',
  },
  affiliation: {
    id: 'work.attr.adminDataWork.attr.adminDataWorkOpenAccess.attr.adminDataWorkOpenAccessFunded.attr.affiliation',
  },
  type: {
    id: 'work.attr.adminDataWork.attr.adminDataWorkOpenAccess.attr.adminDataWorkOpenAccessFunded.attr.type',
  },
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  pubtypeGroup: string;
  validation: ValidationResult;
}

export function Funds({ modifiedModel, updateField, entity, pubtypeGroup, validation }: Props) {
  const intl: IntlShape = useIntl();
  return (
    <DataModelGate entity={entity} field="admin_data_work_open_access.admin_data_work_open_access_fundeds">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        <div className="col">
          <Border>
            <Styled.h3 sx={{mb: '0.25em', mt:'-0.3em'}}>{intl.formatMessage(messages.funds)}</Styled.h3>
            <MultiInputControls<AdminDataWorkOpenAccessFundedDTO>
              defaultRow={DefaultAdminDataWorkOpenAccessFunded}
              lines={modifiedModel.adminDataWork.adminDataWorkOpenAccess?.adminDataWorkOpenAccessFundeds
                ? modifiedModel.adminDataWork.adminDataWorkOpenAccess.adminDataWorkOpenAccessFundeds
                : []}
              onChange={(adminDataWorkOpenAccessFundeds: AdminDataWorkOpenAccessFundedDTO[]): void => {
                updateField('adminDataWork', {
                  ...modifiedModel.adminDataWork,
                  adminDataWorkOpenAccess: modifiedModel.adminDataWork.adminDataWorkOpenAccess ? {
                    ...modifiedModel.adminDataWork.adminDataWorkOpenAccess,
                    adminDataWorkOpenAccessFundeds,
                  } : {adminDataWorkOpenAccessFundeds},
                });
              }}
              remove
              renderLines={(fund: AdminDataWorkOpenAccessFundedDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number) => {
                const { affiliation, type } = fund;
                return (
                  <div className="innerBorder">
                    <div className="fieldRow" key={idx}>
                      <SelectInputP
                        onChange={(a: string) => onChange2({ ...fund, affiliation: a })}
                        listPartition={pubtypeGroup}
                        value={affiliation}
                        name={intl.formatMessage(messages.affiliation)}
                        list={'affiliations_list'}
                        inputName="affiliation"
                        permissions={checkPermission('affiliation')}
                        validation={validation?.child?.admin_data_work_open_access_fundeds?.children[idx]?.affiliation}
                        autoFocus={true}
                      />
                      <SelectInputP
                        onChange={(t: string) => onChange2({ ...fund, type: t })}
                        listPartition={pubtypeGroup}
                        value={type}
                        name={intl.formatMessage(messages.type)}
                        list={'funded_list'}
                        inputName="type"
                        permissions={checkPermission('type')}
                        validation={validation?.child?.admin_data_work_open_access_fundeds?.children[idx]?.type}
                      />
                      {!!actions.delete && (
                        <div className="deleteButton">
                          <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                        </div>
                      )}
                    </div>
                  </div>

                );
              }}
              renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
                <div className="addButton">
                  <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
                </div>
              )}
            />
          </Border>

        </div>
      )}
    </DataModelGate>
  );
}
