import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { DashboardType } from './Dashboard/types';
import { SearchContext } from './types';
import { ReactNode } from 'react';

type SearchContextMap<T> = { [key in SearchContext]: T };

const detailContextUrl: SearchContextMap<string> = {
  [DashboardType.WORKS]: '/work',
  [DashboardType.PEOPLE]: '/person',
  [DashboardType.ORGS]: '/organisation',
  [DashboardType.PROJECTS]: '/project',
};

const searchContextUrl: SearchContextMap<string> = {
  [DashboardType.WORKS]: '/search/works',
  [DashboardType.PEOPLE]: '/search/persons',
  [DashboardType.ORGS]: '/search/organisations',
  [DashboardType.PROJECTS]: '/search/projects',
};

const createContextUrl: SearchContextMap<string> = {
  [DashboardType.WORKS]: '/works/add',
  [DashboardType.PEOPLE]: '/person/add',
  [DashboardType.ORGS]: '/organisation/add',
  [DashboardType.PROJECTS]: '/project/add',
};

const contextMessage: SearchContextMap<React.ReactNode> = {
  [DashboardType.WORKS]: <FormattedMessage id="entity.works" />,
  [DashboardType.PEOPLE]: <FormattedMessage id="entity.persons" />,
  [DashboardType.ORGS]: <FormattedMessage id="entity.organisations" />,
  [DashboardType.PROJECTS]: <FormattedMessage id="entity.projects" />,
};

export const getDetailContextUrl: (context: DashboardType, id?: (number | string), sub?: "full") => string = (context: SearchContext, id?: number | string, sub?: 'full'): string => {
  if (!detailContextUrl.hasOwnProperty(context)) {
    throw RangeError();
  }
  let url: string = detailContextUrl[context];
  if (undefined !== id) {
    url += `/${id}`;
    if (undefined !== sub) {
      url += `/${sub}`;
    }
  }
  return url;
};

export const getSearchContextUrl = (context: SearchContext): string => {
  if (!searchContextUrl.hasOwnProperty(context)) {
    throw RangeError();
  }
  return searchContextUrl[context];
};
export const getCreateContextUrl = (context: SearchContext): string => {
  if (!createContextUrl.hasOwnProperty(context)) {
    throw RangeError();
  }
  return createContextUrl[context];
};

export const getContextName = (context: SearchContext): ReactNode => {
  return contextMessage[context];
};

export const getContextTitle = (context: SearchContext): React.JSX.Element => {
  return <FormattedMessage id="search.title(context)" values={{ context: getContextName(context) }} />;
};

export const getContextTitleWithValue = (context: SearchContext, value?: string): React.JSX.Element => {
  if (!value) {
    return getContextTitle(context);
  }

  return <FormattedMessage id="search.title(context,value)" values={{ context: getContextName(context), value }} />;
};
