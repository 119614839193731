/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkPublisherPlaceDTO
 */
export interface WorkPublisherPlaceDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkPublisherPlaceDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPublisherPlaceDTO
     */
    place?: string;
}

export function WorkPublisherPlaceDTOFromJSON(json: any): WorkPublisherPlaceDTO {
    return WorkPublisherPlaceDTOFromJSONTyped(json, false);
}

export function WorkPublisherPlaceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkPublisherPlaceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'place': !exists(json, 'place') ? undefined : json['place'],
    };
}

export function WorkPublisherPlaceDTOToJSON(value?: WorkPublisherPlaceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'place': value.place,
    };
}


