import React from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { WorkOtherTitleDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkOtherTitle } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border, FieldRow } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  language: { id: 'work.attr.otherTitle.attr.language' },
  title: { id: 'work.attr.otherTitle.attr.title' },
  titleHelp: { id: 'work.attr.otherTitle.attr.title.help' },
});

interface WorkOtherTitlesProps {
  workOtherTitles?: WorkOtherTitleDTO[];
  listPartition: string;
  onChange: (data: any) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

function InnerWorkOtherTitles({
  workOtherTitles,
  listPartition,
  onChange,
  intl,
  checkPermission,
  validation
}: WorkOtherTitlesProps & WrappedComponentProps) {
  return (
    <>
      <div className="col">
        <Border>
          <Styled.h3>
            <FormattedMessage id="work.otherTitles" />
          </Styled.h3>
          <MultiInputControls<WorkOtherTitleDTO>
            lines={workOtherTitles}
            onChange={onChange}
            remove
            add
            defaultRow={DefaultWorkOtherTitle}
            renderLines={(workOtherTitle: WorkOtherTitleDTO, onChange2: (newValues) => void, actions: { [p: string]: (e: any) => void }, idx: number) => {
              return (
                <div className="innerBorder">
                  <div className="fieldRow" key={idx}>
                    <TextInputP
                      name={intl.formatMessage(messages.title)}
                      help={intl.formatMessage(messages.titleHelp)}
                      value={workOtherTitle.otherTitle}
                      onChange={(otherTitle: string): void => onChange2({ ...workOtherTitle, otherTitle })}
                      permissions={checkPermission('other_title')}
                      validation={validation?.children[idx]?.other_title}
                      autoFocus={true}
                    />
                    <SelectInputP
                      listPartition={listPartition}
                      onChange={(language: string): void => onChange2({ ...workOtherTitle, language })}
                      name={intl.formatMessage(messages.language)}
                      list="language_list"
                      inputName="role"
                      value={workOtherTitle.language}
                      permissions={checkPermission('language')}
                      validation={validation?.children[idx]?.language}
                    />
                    {!!actions.delete && (
                      <div className="deleteButton">
                        <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
            renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
              <div className="addButton">
                <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
              </div>
            )}
          />
        </Border>
      </div>
    </>
  );
}

export const WorkOtherTitles = injectIntl(InnerWorkOtherTitles);
