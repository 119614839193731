// import getLogger from '../../log';
import { OAuth2Container, SessionState } from '../containers/OAuth2Container';

// const logger = getLogger('Auth/OAuth2Authorizer');

/** Authorizer is a helper class to verify roles and permissions using the provided container */
export class Authorizer {
  private container: OAuth2Container;

  constructor(container: OAuth2Container) {
    this.container = container;
  }

  public getContainer(): OAuth2Container {
    return this.container;
  }

  public getSession(): SessionState {
    return this.container.state.session;
  }

  public isActive(): boolean {
    return this.hasActiveSession();
  }

  public isIdle(): boolean {
    return this.hasRefreshableSession() && !this.hasActiveSession();
  }

  public isNotAnonymous(): boolean {
    return this.hasAnySession();
  }

  public isAnonymous(): boolean {
    return !this.hasAnySession();
  }

  public hasRole(requiredRole: string): boolean {
    return this.hasAnyOfRoles([requiredRole]);
  }

  public hasAnyOfRoles(roles: string[]): boolean {
    if (this.isAnonymous()) {
      return roles.includes('ROLE_GUEST');
    }
    const availRoles = this.container.state.session.roles;
    const matchingRoles = roles.filter((role) => availRoles.find((r) => r === role) !== undefined);

    return matchingRoles.length > 0;
  }

  private hasActiveSession(): boolean {
    return !(this.container.state.token?.auth == null || this.container.state.token.auth.expires < Date.now());
  }

  private hasRefreshableSession(): boolean {
    return !(this.container.state.token?.refresh == null || this.container.state.token.refresh.expires < Date.now());
  }

  private hasAnySession(): boolean {
    return this.hasActiveSession() || this.hasRefreshableSession();
  }
}
