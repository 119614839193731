import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  WorkPersonDTO,
  WorkIsPartOfDTO,
  WorkDTO,
  WorkIsPartOfOtherDTO,
  ReferencedOrganisationDTO,
  WorkOtherTitleDTO,
  WorkPublisherPlaceDTO,
  WorkGeoLocationPointDTO,
  WorkTitleAbbreviationDTO,
  WorkPersonOtherDTO,
  WorkOrganisationOtherDTO,
  EventPlaceDTO,
  EventDTO,
  WorkOrganisationDTO,
  WorkLanguageDTO,
  WorkDateTypeDTO,
  WorkPlaceDTO,
  WorkPublisherDTO,
  WorkGeoLocationDTO,
  UrlDTO,
  WorkRelatedIdentifierInternalDTO,
  WorkRelatedIdentifierDTO,
  WorkKeywordDTO,
  SubjectDTO,
  WorkDescriptionDTO,
  AdminDataWorkOpenAccessFundedDTO,
  AdminDataWorkOrcidSyncDTO,
  ExternalIdDTO,
  WorkProjectDTO,
  WorkProjectOtherDTO,
} from 'api/core/models/';
import { Notation } from 'Vocabulary/components/Notation';
import { getDetailContextUrl } from 'data';
import { ViewType } from 'Detail/types';
import {
  getExternalPersonIdImage,
  renderDate,
  renderDateTime,
  renderExternalIds,
  renderIssued,
} from 'Detail/utils';
import { boolFilter, formSubjectFilter, peerReviewStatusFilter, pubStatusFilter, pubtypeFilter } from 'Vocabulary/data';
import { DetailView } from '../theme';
import { OutLink } from 'BaseLayout/components/misc/OutLink';
import { Link } from '@theme-ui/components';
import { sortWorks } from './utils';
import { MathJax } from 'better-react-mathjax';
import parse from 'html-react-parser';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface DetailGuestUserProps {
  work: WorkDTO;
}

export const DetailGuestUser: React.FC<DetailGuestUserProps> = ({ work }: DetailGuestUserProps): JSX.Element => {
  return (
    <div sx={{
        ml: '-3.3em',
        mr: '-3.3em',
        ".MathJax.CtxtMenu_Attached_0": {
          display: 'contents'
        }
      }}>
      <DetailView>
        {/* Titeldaten und Beteiligte */}
        <div className="info-group">
          <div className="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.title_data.desc.label" />
            </h3>
          </div>
          <div className="info-group-content">
            <h4 className="work-title">
              <FormattedMessage id="work.title" />:
            </h4>
            <span className="col-right">
              <MathJax>{parse(work.title)}</MathJax>
            </span>
            <div className="clear" />
            {!!work.subtitle && (
              <>
                <h4 className="work-subtitle">
                  <FormattedMessage id="work.subtitle" />:
                </h4>
                <span className="col-right">
                  <MathJax>{parse(work.subtitle)}</MathJax>
                </span>
                <div className="clear" />
              </>
            )}
            {!!work.titleSupplement && (
              <>
                <h4 className="work-title-supplement">
                  <FormattedMessage id="work.title_supplement" />
                </h4>
                <span className="col-right">
                  <MathJax>{parse(work.titleSupplement)}</MathJax>
                </span>
                <div className="clear" />
              </>
            )}
            {!!work.workOtherTitles && (
              <>
                <h4 className="work-other-titles">
                  <FormattedMessage id="work.otherTitles" />
                </h4>
                <ul>
                  {work.workOtherTitles.map(
                    (workOtherTitleDTO: WorkOtherTitleDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ ml: '1.2em' }}>
                        <div className="work-other-title">
                          <MathJax>{parse(workOtherTitleDTO.otherTitle)}</MathJax>
                        </div>
                        <div className="work-other-title-language">
                          {' '}
                          (<Notation v="language_list" k={workOtherTitleDTO.language} />)
                        </div>
                      </li>
                    )
                  )}
                </ul>
                <div className="clear" />
              </>
            )}
            {!!work.subseriesTitle && (
              <>
                <h4 className="work-subseries-title">
                  <FormattedMessage id="work.subseries_title" />
                </h4>
                <span className="col-right">
                  <MathJax>{parse(work.subseriesTitle)}</MathJax>
                </span>
                <div className="clear" />
              </>
            )}
            {!!work.workTitleAbbreviations && (
              <>
                <h4 className="work-title-abbreviations">
                  <FormattedMessage id="work.title_abbreviations" />
                </h4>
                <ul>
                  {work.workTitleAbbreviations.map(
                    (workTitleAbbreviationDTO: WorkTitleAbbreviationDTO): JSX.Element => (
                      <li sx={{ ml: '1.2em' }}>
                        <MathJax>{parse(workTitleAbbreviationDTO.abbreviation)}</MathJax>
                      </li>
                    )
                  )}
                </ul>
                <div className="clear" />
              </>
            )}
            {!!work.workPersons && (
              <>
                <h4 className="work-persons">
                  <FormattedMessage id="entity.persons" />
                </h4>
                <ol sx={{ listStyleType: 'none' }}>
                  {work.workPersons
                    .sort((per1: WorkPersonDTO, per2: WorkPersonDTO): number => per1.sequence - per2.sequence)
                    .map(
                      (person: WorkPersonDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <div className="work-person-name">
                            <Link href={getDetailContextUrl(ViewType.PEOPLE, person.personName.personId)}>
                              {person.personName.surname}
                              {!!person.personName.givenname && <>, {person.personName.givenname}</>}
                            </Link>
                          </div>
                          {person.personName.isMain !== undefined && (
                            <div className="work-person-is-main">
                              <span className='pre-title'>
                                <FormattedMessage id="work.work_person.person_name.is_main" />:&nbsp;
                              </span>
                              {boolFilter(person.personName.isMain)}
                            </div>
                          )}
                          {!!person.topOrgas &&
                            person.topOrgas.map(
                              (orga: string, j: number): JSX.Element => (
                                <div className="work-person-affiliation" key={j}>
                                  <FormattedMessage id="work.attr.workPerson.attr.affiliation" /> {j + 1}: {orga}
                                </div>
                              )
                            )}
                          {!!person.personName.personId && (
                            <div className="work-person-person-id">
                              <FormattedMessage id="work.work_person.person_name.person_id" />:{' '}
                              {person.personName.personId}
                            </div>
                          )}
                          {!!person.role && (
                            <div className="work-person-role">
                              <FormattedMessage id="work.work_person.role" />:{' '}
                              <Notation v="person_role_list" k={person.role} />
                            </div>
                          )}
                          <div className="work-person-sequence">
                            <FormattedMessage id="work.work_person.sequence" />: {person.sequence}
                          </div>
                        </li>
                      )
                    )}
                </ol>
                <div className="clear" />
              </>
            )}
            {!!work.workPersonOthers && (
              <>
                <h4 className="work-person-others">
                  <FormattedMessage id="work.attr.workPersonOthers" />
                </h4>
                <ol sx={{ display: 'block-flex' }}>
                  {work.workPersonOthers
                    .sort((per1: WorkPersonOtherDTO, per2: WorkPersonOtherDTO) => per1.sequence - per2.sequence)
                    .map(
                      (pOther: WorkPersonOtherDTO, i: number): JSX.Element => (
                        <li key={i} sx={{ listStyleType: 'none' }}>
                          <div className="work-person-other-name">
                            {pOther.surname}
                            {pOther.givenname ? `, ${pOther.givenname}` : ''}
                          </div>
                          {!!pOther.externalIds && (
                            <div className='work-person-other-externalIds' sx={{display: 'inline-flex'}}>
                              {pOther.externalIds.map((externalId: ExternalIdDTO, index: number) => (
                                <div key={index} sx={{flexWrap: 'wrap'}}>
                                  {getExternalPersonIdImage(externalId)}&nbsp;{externalId.externalId}
                                  {(pOther.externalIds[pOther.externalIds.length-1] !== externalId) && (<>&nbsp;&nbsp;|&nbsp;&nbsp;</>)}
                                </div>
                              ))}
                            </div>
                          )}
                          {!!pOther.role && (
                            <div className="work-person-other-role">
                              <FormattedMessage id="work.work_person_other.role" />:{' '}
                              <Notation v="person_role_list" k={pOther.role} />
                            </div>
                          )}
                          {!!pOther.sequence && (
                            <div className="work-person-other-sequence">
                              <FormattedMessage id="work.work_person_other.sequence" />: {pOther.sequence}
                            </div>
                          )}
                        </li>
                      )
                    )}
                </ol>
                <div className="clear" />
              </>
            )}
            {!!work.workOrganisations && (
              <>
                <h4 className="work-organisations">
                  <FormattedMessage id="work.attr.workOrganisations" />
                </h4>
                <ol sx={{ display: 'block-flex' }}>
                  {work.workOrganisations.map(
                    (workOrganisationDTO: WorkOrganisationDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ listStyleType: 'none' }}>
                        <div className="work-organisation-name">
                          <Link href={getDetailContextUrl(ViewType.ORGS, workOrganisationDTO.organisation.id)}>
                            {workOrganisationDTO.organisation.name}
                          </Link>
                        </div>
                        {!!workOrganisationDTO.organisation?.id && (
                          <div className="work-organisation-id">
                            <FormattedMessage id="work.work_organisation.organisation_id" />:{' '}
                            {workOrganisationDTO.organisation.id}
                          </div>
                        )}
                        {!!workOrganisationDTO.role && (
                          <div className="work-organisation-role">
                            <FormattedMessage id="work.work_organisation.role" />:{' '}
                            <Notation v="corporation_role_list" k={workOrganisationDTO.role} />
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ol>
                <div className="clear" />
              </>
            )}
            {!!work.workOrganisationOthers && (
              <>
                <h4 className="work-organisations-others">
                  <FormattedMessage id="work.work_organisation_others" />
                </h4>
                <ol sx={{ display: 'block-flex' }}>
                  {work.workOrganisationOthers.map(
                    (workOrganisationOtherDTO: WorkOrganisationOtherDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ listStyleType: 'none' }}>
                        {!!workOrganisationOtherDTO.name && (
                          <div className="work-organisation-other-name">{workOrganisationOtherDTO.name}</div>
                        )}
                        {!!workOrganisationOtherDTO.role && (
                          <div className="work-organisation-other-role">
                            <FormattedMessage id="work.work_organisation_other.role" />:{' '}
                            <Notation v="corporation_role_list" k={workOrganisationOtherDTO.role} />
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ol>
                <div className="clear" />
              </>
            )}
            {!!work.workProjects && (
              <>
                <h4 className="work-projects">
                  <FormattedMessage id="work.attr.workProjects" />
                </h4>
                <ol sx={{ display: 'block-flex' }}>
                  {work.workProjects.map(
                    (workProjectDTO: WorkProjectDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ listStyleType: 'none' }}>
                        <div className="work-project-name">
                          <Link href={getDetailContextUrl(ViewType.PROJECTS, workProjectDTO.project.id)}>
                            {workProjectDTO.project.name}
                          </Link>
                        </div>
                        {!!workProjectDTO.project.id && (
                          <div className="work-project-id">
                            <FormattedMessage id="work.work_project.project_id" />: {workProjectDTO.project.id}
                          </div>
                        )}
                        {!!workProjectDTO.role && (
                          <div className="work-project-role">
                            <FormattedMessage id="work.work_project.role" />:{' '}
                            <Notation v="corporation_role_list" k={workProjectDTO.role} />
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ol>
                <div className="clear" />
              </>
            )}
            {!!work.workProjectOthers && (
              <>
                <h4 className="work-project-others">
                  <FormattedMessage id="work.work_project_others" />
                </h4>
                <ol sx={{ display: 'block-flex' }}>
                  {work.workProjectOthers.map(
                    (workProjectOtherDTO: WorkProjectOtherDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ listStyleType: 'none' }}>
                        {!!workProjectOtherDTO.name && (
                          <div className="work-project-other-name">{workProjectOtherDTO.name}</div>
                        )}
                        {!!workProjectOtherDTO.role && (
                          <div className="work-project-other-role">
                            <FormattedMessage id="work.work_project_other.role" />:{' '}
                            <Notation v="corporation_role_list" k={workProjectOtherDTO.role} />
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ol>
                <div className="clear" />
              </>
            )}
            {!!work.events && (
              <>
                <h4 className="work-events">
                  <FormattedMessage id="work.attr.events" />
                </h4>
                <ol sx={{ listStyleType: 'none', display: 'block-flex' }}>
                  {work.events.map(
                    (eventDTO: EventDTO, i: number): JSX.Element => (
                      <li key={i}>
                        {!!eventDTO.startDate && (
                          <div className="work-event-start-date">
                            <FormattedMessage id="work.event.start_date" />: {renderDate(eventDTO.startDate)}
                          </div>
                        )}
                        {!!eventDTO.endDate && (
                          <div className="work-event-end-date">
                            <FormattedMessage id="work.event.end_date" />: {renderDate(eventDTO.endDate)}
                          </div>
                        )}
                        {!!eventDTO.eventNumbering && (
                          <div className="work-event-numbering">
                            <FormattedMessage id="work.event.numbering" />: {eventDTO.eventNumbering}
                          </div>
                        )}
                        {!!eventDTO.eventName && (
                          <div className="work-event-name">
                            <FormattedMessage id="work.event.name" />: {eventDTO.eventName}
                          </div>
                        )}
                        {!!eventDTO.eventPlaces && (
                          <div className="work-event-places">
                            <FormattedMessage id="work.event.places" />:{' '}
                            <ul sx={{ listStyleType: 'none', display: 'inline' }}>
                              {eventDTO.eventPlaces.map(
                                (place: EventPlaceDTO, j: number): JSX.Element => (
                                  <li key={i} sx={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                    {place.eventPlace}
                                    {eventDTO.eventPlaces.length - 1 > j && <>,&nbsp;</>}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ol>
                <div className="clear" />
              </>
            )}
          </div>
        </div>

        {/* Basisdaten */}
        {(!!work.pubtype ||
          !!work.formSubject ||
          !!work.publicationStatus ||
          !!work.version ||
          !!work.resourceType ||
          !!work.workLanguages ||
          !!work.edition ||
          !!work.dataVersion ||
          !!work.numberOfVolumes ||
          !!work.issued ||
          !!work.workDateTypes ||
          !!work.workPlaces ||
          !!work.workPublishers ||
          !!work.size ||
          !!work.number ||
          (!!work.urls && work.urls.length > 0) ||
          !!work.format ||
          !!work.medium ||
          !!work.externalIds ||
          !!work.note) && (
          <div className="info-group">
            <div className="info-group-desc">
              <h3>
                <FormattedMessage id="work.detail.table.basic_data.desc.label" />
              </h3>
            </div>
            <div className="info-group-content">
              {!!work.pubtype && (
                <>
                  <h4 className="work-pubtype">
                    <FormattedMessage id="work.attr.pubtyp" />
                  </h4>
                  <span className="col-right">{pubtypeFilter(work.pubtype)}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.formSubject && (
                <>
                  <h4 className="work-form-subject">
                    <FormattedMessage id="work.attr.formSubject" />
                  </h4>
                  <span className="col-right">{formSubjectFilter(work.formSubject)}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.publicationStatus && (
                <>
                  <h4 className="work-publication-status">
                    <FormattedMessage id="work.attr.publicationStatus" />
                  </h4>
                  <span className="col-right">{pubStatusFilter(work.publicationStatus)}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.version && (
                <>
                  <h4 className="work-version">
                    <FormattedMessage id="work.version" />
                  </h4>
                  <span className="col-right">
                    <Notation v="version_list" k={work.version} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {!!work.resourceType && (
                <>
                  <h4 className="work-resource-type">
                    <FormattedMessage id="work.resource_type" />
                  </h4>
                  <span className="col-right">
                    <Notation v="resource_type_list" k={work.resourceType} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {!!work.workLanguages && (
                <>
                  <h4 className="work-languages">
                    <FormattedMessage id="work.languages" />
                  </h4>
                  <ul>
                    {work.workLanguages.map(
                      (workLanguageDTO: WorkLanguageDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <Notation v="language_list" k={workLanguageDTO.language} />
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.edition && (
                <>
                  <h4 className="work-edition">
                    <FormattedMessage id="work.edition" />
                  </h4>
                  <span className="col-right">{work.edition}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.dataVersion && (
                <>
                  <h4 className="work-data-version">
                    <FormattedMessage id="work.data_version" />
                  </h4>
                  <span className="col-right">{work.dataVersion}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.numberOfVolumes && (
                <>
                  <h4 className="work-number-of-volumes">
                    <FormattedMessage id="work.number_of_volumes" />
                  </h4>
                  <span className="col-right">{work.numberOfVolumes}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.issued && (
                <>
                  <h4 className="work-issued">
                    <FormattedMessage id="work.attr.issued" />
                  </h4>
                  <span className="col-right">{renderIssued(work.issued)}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.workDateTypes && (
                <>
                  <h4 className="work-date-types">
                    <FormattedMessage id="work.date_types" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workDateTypes.map(
                      (workDateTypeDTO: WorkDateTypeDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <div>
                            <FormattedMessage id="work.date_type.date" />: {renderDate(workDateTypeDTO.date)}
                          </div>
                          <div>
                            <FormattedMessage id="work.date_type.type" />:{' '}
                            <Notation v="date_types_list" k={workDateTypeDTO.type} />
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.workPlaces && (
                <>
                  <h4 className="work-places">
                    <FormattedMessage id="work.places" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workPlaces.map(
                      (workPlaceDTO: WorkPlaceDTO, i: number): JSX.Element => (
                        <li key={i}>{workPlaceDTO.place}</li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.workPublishers && (
                <>
                  <h4 className="work-publishers">
                    <FormattedMessage id="work.publishers" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workPublishers.map(
                      (workPublisherDTO: WorkPublisherDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <div className="work-publisher-publisher">
                            <FormattedMessage id="work.publisher.publisher" />: {workPublisherDTO.publisher.name}
                          </div>
                          {!!workPublisherDTO.workPublisherPlace && (
                            <div className="work-publisher-places">
                              <FormattedMessage id="work.publisher.places" />:{' '}
                              <ul sx={{ listStyleType: 'none', display: 'inline' }}>
                                {workPublisherDTO.workPublisherPlace.map(
                                  (workPublisherPlaceDTO: WorkPublisherPlaceDTO, j: number): JSX.Element => (
                                    <li key={j} sx={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                      {workPublisherPlaceDTO.place}
                                      {workPublisherDTO.workPublisherPlace.length - 1 > j && <>,&nbsp;</>}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.numberOfPages && (
                <>
                  <h4 className="work-number-of-pages">
                    <FormattedMessage id="work.number_of_pages" />
                  </h4>
                  <span className="col-right">{work.numberOfPages}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.size && (
                <>
                  <h4 className="work-size">
                    <FormattedMessage id="work.size" />
                  </h4>
                  <span className="col-right">{work.size}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.number && (
                <>
                  <h4 className="work-number">
                    <FormattedMessage id="work.number" />
                  </h4>
                  <span className="col-right">{work.number}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.urls && work.urls.length > 0 && (
                <>
                  <h4 className="work-urls">
                    <FormattedMessage id="work.attr.urls" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.urls.map(
                      (urlDTO: UrlDTO, i: number): JSX.Element => (
                        <div key={i}>
                          {!!urlDTO.url && (
                            <li>
                              <OutLink href={urlDTO.url}>{urlDTO.url}</OutLink>
                            </li>
                          )}
                        </div>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.format && (
                <>
                  <h4 className="work-format">
                    <FormattedMessage id="work.format" />
                  </h4>
                  <span className="col-right">
                    <Notation v="format_list" k={work.format} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {!!work.medium && (
                <>
                  <h4 className="work-medium">
                    <FormattedMessage id="work.medium" />
                  </h4>
                  <span className="col-right">
                    <Notation v="medium_list" k={work.medium} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {!!work.peerReviewed && (
              <>
                <h4 className="work-peer-reviewed">
                  <FormattedMessage id="work.peer_reviewed" />
                </h4>
                <span className="col-right">
                  {peerReviewStatusFilter(work.peerReviewed)}
                </span>
                <div className="clear" />
              </>
              )}
              {!!work.externalIds && (
                <>
                  <h4 className="work-external-ids">
                    <FormattedMessage id="work.attr.externalId" />
                  </h4>
                  <div
                    sx={{
                      a: {
                        display: 'inline-flex',
                        flexWrap: 'wrap',
                        svg: {
                          mt: '0.4em',
                        },
                      },
                      ul: {
                        listStyleType: 'none',
                      },
                    }}
                  >
                    {renderExternalIds(work.externalIds, 'work')}
                  </div>
                  <div className="clear" />
                </>
              )}
              {!!work.note && (
                <>
                  <h4 className="work-note">
                    <FormattedMessage id="work.note" />
                  </h4>
                  <span className="col-right">{work.note}</span>
                  <div className="clear" />
                </>
              )}
            </div>
          </div>
        )}

        {/* Spezifische Angaben */}
        {(!!work.publicationNumber ||
          !!work.applicationNumber ||
          !!work.applicationCountry ||
          work.priorityPatentApplication !== undefined ||
          !!work.priorityCountry ||
          !!work.priorityNumber ||
          !!work.grantNumber ||
          !!work.patentFamily ||
          !!work.patentId ||
          !!work.patentType ||
          !!work.workGeoLocations) && (
          <div className="info-group">
            <div className="info-group-desc">
              <h3>
                <FormattedMessage id="work.detail.table.specific_info.desc.label" />
              </h3>
            </div>
            <div className="info-group-content">
              {!!work.publicationNumber && (
                <>
                  <h4 className="work-publication-number">
                    <FormattedMessage id="work.publication_number" />
                  </h4>
                  <span className="col-right">{work.publicationNumber}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.applicationNumber && (
                <>
                  <h4 className="work-application-number">
                    <FormattedMessage id="work.application_number" />
                  </h4>
                  <span className="col-right">{work.applicationNumber}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.applicationCountry && (
                <>
                  <h4 className="work-application-country">
                    <FormattedMessage id="work.application_country" />
                  </h4>
                  <span className="col-right">
                    <Notation v="country_list" k={work.applicationCountry} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {work.priorityPatentApplication !== undefined && (
                <>
                  <h4 className="work-priority-patent-application">
                    <FormattedMessage id="work.priority_patent_application" />
                  </h4>
                  <span className="col-right">{boolFilter(work.priorityPatentApplication)}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.priorityCountry && (
                <>
                  <h4 className="work-priority-country">
                    <FormattedMessage id="work.priority_country" />
                  </h4>
                  <span className="col-right">
                    <Notation v="country_list" k={work.priorityCountry} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {!!work.priorityNumber && (
                <>
                  <h4 className="work-priority-number">
                    <FormattedMessage id="work.priority_number" />
                  </h4>
                  <span className="col-right">{work.priorityNumber}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.grantNumber && (
                <>
                  <h4 className="work-grant-number">
                    <FormattedMessage id="work.grant_number" />
                  </h4>
                  <span className="col-right">{work.grantNumber}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.patentFamily && (
                <>
                  <h4 className="work-patent-family">
                    <FormattedMessage id="work.patent_family" />
                  </h4>
                  <span className="col-right">{work.patentFamily}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.patentId && (
                <>
                  <h4 className="work-patent-id">
                    <FormattedMessage id="work.patent_id" />
                  </h4>
                  <span className="col-right">{work.patentId}</span>
                  <div className="clear" />
                </>
              )}
              {!!work.patentType && (
                <>
                  <h4 className="work-patent-type">
                    <FormattedMessage id="work.patent_type" />
                  </h4>
                  <span className="col-right">
                    <Notation v="affiliations_list" k={work.patentType} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {!!work.workGeoLocations && (
                <>
                  <h4 className="work-geo-locations">
                    <FormattedMessage id="work.geo_locations" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workGeoLocations.map(
                      (workGeoLocationDTO: WorkGeoLocationDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <h4>
                            {i + 1}. <FormattedMessage id="work.attr.geoLocation" />
                          </h4>
                          {!!workGeoLocationDTO.pointLongitude && (
                            <div className="work-geo-location-point-longitude">
                              <FormattedMessage id="work.geo_location.point_longitude" />:{' '}
                              {workGeoLocationDTO.pointLongitude}
                            </div>
                          )}
                          {!!workGeoLocationDTO.pointLatitude && (
                            <div className="work-geo-location-point-latitude">
                              <FormattedMessage id="work.geo_location.point_latitude" />:{' '}
                              {workGeoLocationDTO.pointLatitude}
                            </div>
                          )}
                          {(!!workGeoLocationDTO.boxWestBoundLongitude ||
                            !!workGeoLocationDTO.boxEastBoundLongitude ||
                            !!workGeoLocationDTO.boxSouthBoundLatitude ||
                            !!workGeoLocationDTO.boxNorthBoundLatitude) && (
                            <h4>
                              <FormattedMessage id="work.attr.geoLocation.attr.geoLocationBox" />
                            </h4>
                          )}
                          {!!workGeoLocationDTO.boxWestBoundLongitude && (
                            <div className="work-geo-location-box-west-bound-longitude">
                              <FormattedMessage id="work.geo_location.box_west_bound_longitude" />:{' '}
                              {workGeoLocationDTO.boxWestBoundLongitude}
                            </div>
                          )}
                          {!!workGeoLocationDTO.boxEastBoundLongitude && (
                            <div className="work-geo-location-box-east-bound-longitude">
                              <FormattedMessage id="work.geo_location.box_east_bound_longitude" />:{' '}
                              {workGeoLocationDTO.boxEastBoundLongitude}
                            </div>
                          )}
                          {!!workGeoLocationDTO.boxSouthBoundLatitude && (
                            <div className="work-geo-location-box-south-bound-latitude">
                              <FormattedMessage id="work.geo_location.box_south_bound_latitude" />:{' '}
                              {workGeoLocationDTO.boxSouthBoundLatitude}
                            </div>
                          )}
                          {!!workGeoLocationDTO.boxNorthBoundLatitude && (
                            <div className="work-geo-location-box-north-bound-latitude">
                              <FormattedMessage id="work.geo_location.box_north_bound_latitude" />:{' '}
                              {workGeoLocationDTO.boxNorthBoundLatitude}
                            </div>
                          )}
                          {!!workGeoLocationDTO.place && (
                            <div className="work-geo-location-place">
                              <FormattedMessage id="work.geo_location.place" />: {workGeoLocationDTO.place}
                            </div>
                          )}
                          {!!workGeoLocationDTO.workGeoLocationPolygon && (
                            <div className="work-geo-location-polygon">
                              <h4>
                                <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon" />
                              </h4>
                              {(!!workGeoLocationDTO.workGeoLocationPolygon.inLongitude ||
                                !!workGeoLocationDTO.workGeoLocationPolygon.inLatitude) && (
                                <h4>
                                  <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon.attr.geoLocationInPolygonPoints" />
                                </h4>
                              )}
                              {!!workGeoLocationDTO.workGeoLocationPolygon.inLongitude && (
                                <div className="work-geo-location-polygon-in-longitude">
                                  <FormattedMessage id="work.geo_location.polygon.in_longitude" />:{' '}
                                  {workGeoLocationDTO.workGeoLocationPolygon.inLongitude}
                                </div>
                              )}
                              {!!workGeoLocationDTO.workGeoLocationPolygon.inLatitude && (
                                <div className="work-geo-location-polygon-in-latitude">
                                  <FormattedMessage id="work.geo_location.polygon.in_latitude" />:{' '}
                                  {workGeoLocationDTO.workGeoLocationPolygon.inLatitude}
                                </div>
                              )}
                              {!!workGeoLocationDTO.workGeoLocationPolygon.workGeoLocationPoints && (
                                <>
                                  <h4>
                                    <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon.attr.geoLocationPolygonPoint" />
                                  </h4>
                                  <div className="work-geo-location-polygon-points">
                                    <ul sx={{ listStyleType: 'none' }}>
                                      {workGeoLocationDTO.workGeoLocationPolygon.workGeoLocationPoints.map(
                                        (workGeoLocationPointDTO: WorkGeoLocationPointDTO, j: number): JSX.Element => (
                                          <li key={j}>
                                            <div className="work-geo-location-polygon-point-longitude">
                                              <FormattedMessage id="work.geo_location.polygon.point.longitude" />:{' '}
                                              {workGeoLocationPointDTO.longitude}
                                            </div>
                                            <div className="work-geo-location-polygon-point-latitude">
                                              <FormattedMessage id="work.geo_location.polygon.point.latitude" />:{' '}
                                              {workGeoLocationPointDTO.latitude}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
            </div>
          </div>
        )}

        {/* Gehört zu folgenden Werken */}
        {(!!work.workIsPartOfs ||
          !!work.workIsPartOfOthers ||
          !!work.workHasParts ||
          !!work.internalRelatedIdentifiersSource ||
          !!work.internalRelatedIdentifiersTarget ||
          !!work.externalRelatedIdentifiers) && (
          <div className="info-group">
            <div className="info-group-desc">
              <h3>
                <FormattedMessage id="work.detail.table.belongs_to_work.desc.label" />
              </h3>
            </div>
            <div className="info-group-content">
              {!!work.workIsPartOfs && (
                <>
                  <h4 className="work-is-part-ofs">
                    <FormattedMessage id="work.work.attr.isPartOf" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workIsPartOfs.sort(sortWorks).map(
                      (workIsPartOfDTO: WorkIsPartOfDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <div className="work-is-part-of-referenced-work-title">
                            <FormattedMessage id="work.is_part_of.referenced_work.title" />:{' '}
                            <Link href={getDetailContextUrl(ViewType.WORKS, workIsPartOfDTO.referencedWork.id)}>
                              <MathJax>{parse(workIsPartOfDTO.referencedWork.title)}</MathJax>
                            </Link>
                          </div>
                          {!!workIsPartOfDTO.pageFirst && (
                            <div>
                              <FormattedMessage id="work.is_part_of.page_first" />: {workIsPartOfDTO.pageFirst}
                            </div>
                          )}
                          {!!workIsPartOfDTO.pageLast && (
                            <div>
                              <FormattedMessage id="work.is_part_of.page_last" />: {workIsPartOfDTO.pageLast}
                            </div>
                          )}
                          {!!workIsPartOfDTO.issue && (
                            <div>
                              <FormattedMessage id="work.is_part_of.issue" />: {workIsPartOfDTO.issue}
                            </div>
                          )}
                          {!!workIsPartOfDTO.volume && (
                            <div>
                              <FormattedMessage id="work.is_part_of.volume" />: {workIsPartOfDTO.volume}
                            </div>
                          )}
                          {!!workIsPartOfDTO.articleId && (
                            <div>
                              <FormattedMessage id="work.is_part_of.article_id" />: {workIsPartOfDTO.articleId}
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.workIsPartOfOthers && (
                <>
                  <h4 className="work-is-part-of-others">
                    <FormattedMessage id="work.attr.isPartOfOther" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workIsPartOfOthers.sort(sortWorks).map(
                      (workIsPartOfOtherDTO: WorkIsPartOfOtherDTO, i: number): JSX.Element => (
                        <li key={i}>
                          {!!workIsPartOfOtherDTO.name && (
                            <div sx={{ display: 'inline-flex' }}>
                              <FormattedMessage id="work.is_part_of_other.name" />:&nbsp;<MathJax>{parse(workIsPartOfOtherDTO.name)}</MathJax>
                            </div>
                          )}
                          {!!workIsPartOfOtherDTO.pageFirst && (
                            <div>
                              <FormattedMessage id="work.is_part_of_other.page_first" />:{' '}
                              {workIsPartOfOtherDTO.pageFirst}
                            </div>
                          )}
                          {!!workIsPartOfOtherDTO.pageLast && (
                            <div>
                              <FormattedMessage id="work.is_part_of_other.page_last" />: {workIsPartOfOtherDTO.pageLast}
                            </div>
                          )}
                          {!!workIsPartOfOtherDTO.issue && (
                            <div>
                              <FormattedMessage id="work.is_part_of_other.issue" />: {workIsPartOfOtherDTO.issue}
                            </div>
                          )}
                          {!!workIsPartOfOtherDTO.volume && (
                            <div>
                              <FormattedMessage id="work.is_part_of_other.volume" />: {workIsPartOfOtherDTO.volume}
                            </div>
                          )}
                          {!!workIsPartOfOtherDTO.articleId && (
                            <div>
                              <FormattedMessage id="work.is_part_of_other.article_id" />:{' '}
                              {workIsPartOfOtherDTO.articleId}
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.workHasParts && (
                <>
                  <h4 className="work-has-parts">
                    <FormattedMessage id="work.attr.hasPart" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workHasParts.sort(sortWorks).map(
                      (workIsPartOfDTO: WorkIsPartOfDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <div className="work-has-part-referenced-work-title">
                            <FormattedMessage id="work.has_part.referenced_work.title" />:{' '}
                            <Link href={getDetailContextUrl(ViewType.WORKS, workIsPartOfDTO.referencedWork.id)}>
                              <MathJax>{parse(workIsPartOfDTO.referencedWork.title)}</MathJax>
                            </Link>
                          </div>
                          {!!workIsPartOfDTO.volume && (
                            <div>
                              <FormattedMessage id="work.has_part.volume" />: {workIsPartOfDTO.volume}
                            </div>
                          )}
                          {!!workIsPartOfDTO.issue && (
                            <div>
                              <FormattedMessage id="work.has_part.issue" />: {workIsPartOfDTO.issue}
                            </div>
                          )}
                          {!!workIsPartOfDTO.pageFirst && (
                            <div>
                              <FormattedMessage id="work.has_part.page_first" />: {workIsPartOfDTO.pageFirst}
                            </div>
                          )}
                          {!!workIsPartOfDTO.pageLast && (
                            <div>
                              <FormattedMessage id="work.has_part.page_last" />: {workIsPartOfDTO.pageLast}
                            </div>
                          )}
                          {!!workIsPartOfDTO.articleId && (
                            <div>
                              <FormattedMessage id="work.has_part.article_id" />: {workIsPartOfDTO.articleId}
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {(!!work.internalRelatedIdentifiersSource || !!work.internalRelatedIdentifiersTarget) && (
                <>
                  <h4 className="work-related-identifiers-internal">
                    <FormattedMessage id="work.related_identifiers_internal" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.internalRelatedIdentifiersSource?.map(
                      (workRelatedIdentifierInternalDTO: WorkRelatedIdentifierInternalDTO, i: number): JSX.Element => (
                        <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <div className="work-related-identifier-internal-referenced-work-id">
                            <b><FormattedMessage id="work.related_identifer_internal.thisWork" /> &nbsp;</b>
                          </div>
                          <div className="work-related-identifier-internal-relation-type">
                            <Notation v="relation_type_list" k={workRelatedIdentifierInternalDTO.relationType} />&nbsp;
                          </div>

                          <div className="work-related-identifier-internal-referenced-work-title">
                            <Link
                              href={getDetailContextUrl(
                                ViewType.WORKS,
                                workRelatedIdentifierInternalDTO.referencedWork.id
                              )}
                            >
                              <MathJax>{parse(workRelatedIdentifierInternalDTO.referencedWork.title)}</MathJax>
                            </Link>&nbsp;({workRelatedIdentifierInternalDTO.referencedWork.id})
                          </div>
                        </li>
                      )
                    )}
                    {!!work.internalRelatedIdentifiersSource && (<li key='x' sx={{ display: 'flex', flexWrap: 'wrap', minHeight: '0.5em' }}></li>)}
                    {work.internalRelatedIdentifiersTarget?.map(
                      (workRelatedIdentifierInternalDTO: WorkRelatedIdentifierInternalDTO, i: number): JSX.Element => (
                        <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <div className="work-related-identifier-internal-referenced-work-title">
                            <Link
                              href={getDetailContextUrl(
                                ViewType.WORKS,
                                workRelatedIdentifierInternalDTO.referencedWork.id
                              )}
                            >
                              <MathJax>{parse(workRelatedIdentifierInternalDTO.referencedWork.title)}</MathJax>
                            </Link>&nbsp;({workRelatedIdentifierInternalDTO.referencedWork.id})&nbsp;
                          </div>
                          <div className="work-related-identifier-internal-relation-type">
                            <Notation v="relation_type_list" k={workRelatedIdentifierInternalDTO.relationType} />
                          </div>
                          <div className="work-related-identifier-internal-referenced-work-id">
                            <b>&nbsp;<FormattedMessage id="work.related_identifer_internal.thisWork" /></b>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.externalRelatedIdentifiers && (
                <>
                  <h4 className="work-related-identifiers-external">
                    <FormattedMessage id="work.related_identifiers_external" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.externalRelatedIdentifiers.map(
                      (workRelatedIdentifierDTO: WorkRelatedIdentifierDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <div className="work-related-identifier-external-relation-type">
                            <FormattedMessage id="work.related_identifer_external.relation_type" />:{' '}
                            <Notation v="relation_type_list" k={workRelatedIdentifierDTO.relationType} />
                          </div>
                          <div className="work-related-identifier-external-related-identifier">
                            <FormattedMessage id="work.related_identifer_internal.referenced_work.related_identifier" />
                            : {workRelatedIdentifierDTO.relatedIdentifier}
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
            </div>
          </div>
        )}

        {/* Inhalt */}
        {(!!work.workKeywords || !!work.workSubjects || !!work.workDescriptions) && (
          <div className="info-group">
            <div className="info-group-desc">
              <h3>
                <FormattedMessage id="work.detail.table.content.desc.label" />
              </h3>
            </div>
            <div className="info-group-content">
              {!!work.workKeywords && (
                <>
                  <h4 className="work-keywords">
                    <FormattedMessage id="work.keywords" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workKeywords.map(
                      (workKeywordDTO: WorkKeywordDTO, i: number): JSX.Element => (
                        <li key={i}>{workKeywordDTO.keyword}</li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.workSubjects && (
                <>
                  <h4 className="work-subjects">
                    <FormattedMessage id="work.subjects" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workSubjects.map(
                      (subjectDTO: SubjectDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <div>
                            <FormattedMessage id="work.subject.subject_id" />: {subjectDTO.subjectId}
                          </div>
                          <div>
                            <FormattedMessage id="work.subject.type" />:{' '}
                            <Notation v="subject_type_list" k={subjectDTO.type} />
                          </div>
                          {!!subjectDTO.label && (
                            <div>
                              <FormattedMessage id="work.subject.label" />: {subjectDTO.label}
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
              {!!work.workDescriptions && (
                <>
                  <h4 className="work-descriptions">
                    <FormattedMessage id="work.descriptions" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.workDescriptions.map(
                      (workDescriptionDTO: WorkDescriptionDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <MathJax>
                            <FormattedMessage id="work.description.content" />: {parse(workDescriptionDTO.content)}
                          </MathJax>
                          {!!workDescriptionDTO.language && (
                            <div>
                              <FormattedMessage id="work.description.language" />:{' '}
                              <Notation v="language_list" k={workDescriptionDTO.language} />
                            </div>
                          )}
                          {!!workDescriptionDTO.descriptionType && (
                            <div>
                              <FormattedMessage id="work.description.description_type" />:{' '}
                              <Notation v="description_type_list" k={workDescriptionDTO.descriptionType} />
                            </div>
                          )}
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
            </div>
          </div>
        )}

        {/* Gehört zu folgenden Organisationen */}
        {!!work.adminDataWork.organisations && (
          <div className="info-group">
            <div className="info-group-desc">
              <h3>
                <FormattedMessage id="work.detail.table.belongs_to_orga.desc.label" />
              </h3>
            </div>
            <div className="info-group-content">
              {!!work.adminDataWork.organisations && (
                <>
                  <h4 className="work-admin-data-organisations">
                    <FormattedMessage id="work.attr.workOrganisations" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.adminDataWork.organisations.map(
                      (referencedOrganisationDTO: ReferencedOrganisationDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <Link href={getDetailContextUrl(ViewType.ORGS, referencedOrganisationDTO.id)}>
                            {referencedOrganisationDTO.name}
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
            </div>
          </div>
        )}

        {/* Gehört zu folgenden Projekten */}
        {!!work.adminDataWork.projects && (
          <div className="info-group">
            <div className="info-group-desc">
              <h3>
                <FormattedMessage id="work.detail.table.belongs_to_project.desc.label" />
              </h3>
            </div>
            <div className="info-group-content">
              {!!work.adminDataWork.projects && (
                <>
                  <h4 className="work-admin-data-projects">
                    <FormattedMessage id="work.attr.workGroups" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.adminDataWork.projects.map(
                      (referencedOrganisationDTO: ReferencedOrganisationDTO, i: number): JSX.Element => (
                        <li key={i}>
                          <Link href={getDetailContextUrl(ViewType.PROJECTS, referencedOrganisationDTO.id)}>
                            {referencedOrganisationDTO.name}
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
            </div>
          </div>
        )}

        {/* Administrative Daten */}
        <div className="info-group">
          <div className="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.admin_data.desc.label" />
            </h3>
          </div>
          <div className="info-group-content">
            <h4 className="work-id">
              <FormattedMessage id="work.id" />
            </h4>
            <span className="col-right">{work.id}</span>
            <div className="clear" />
            {!!work.adminDataWork.changed && (
              <>
                <h4 className="work-admin-data-changed">
                  <FormattedMessage id="work.attr.adminDataWork.attr.changed" />
                </h4>
                <span className="col-right">{renderDateTime(work.adminDataWork.changed)}</span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.created && (
              <>
                <h4 className="work-admin-data-created">
                  <FormattedMessage id="work.attr.adminDataWork.attr.created" />
                </h4>
                <span className="col-right">{renderDateTime(work.adminDataWork.created)}</span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.origin && (
              <>
                <h4 className="work-admin-data-origin">
                  <FormattedMessage id="work.attr.adminDataWork.attr.origin" />
                </h4>
                <span className="col-right">{work.adminDataWork.origin}</span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.deskman && (
              <>
                <h4 className="work-admin-data-deskman">
                  <FormattedMessage id="work.attr.adminDataWork.attr.deskman" />
                </h4>
                <span className="col-right">{work.adminDataWork.deskman}</span>
                <div className="clear" />
              </>
            )}
            {work.adminDataWork.hidden !== undefined && (
              <>
                <h4 className="work-admin-data-hidden">
                  <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />
                </h4>
                <span className="col-right">{boolFilter(work.adminDataWork.hidden)}</span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.editorialStatus && (
              <>
                <h4 className="work-admin-data-editorial-status">
                  <FormattedMessage id="work.attr.adminDataWork.attr.editorialStatus" />
                </h4>
                <span className="col-right">
                  <Notation v="editorial_status_list" k={work.adminDataWork.editorialStatus} />
                </span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.adminDataWorkOpenAccess &&
              !!work.adminDataWork.adminDataWorkOpenAccess.adminDataWorkOpenAccessFundeds && (
                <>
                  <h4 className="work-admin-data-open-access-fundeds">
                    <FormattedMessage id="work.admin_data.open_access.fundeds" />
                  </h4>
                  <ul sx={{ listStyleType: 'none' }}>
                    {work.adminDataWork.adminDataWorkOpenAccess.adminDataWorkOpenAccessFundeds.map(
                      (adminDataWorkOpenAccessFundedDTO: AdminDataWorkOpenAccessFundedDTO): JSX.Element => (
                        <li>
                          <div>
                            <FormattedMessage id="work.admin_data.open_access.funded.type" />:{' '}
                            <Notation v="funded_list" k={adminDataWorkOpenAccessFundedDTO.type} />
                          </div>
                          <div>
                            <FormattedMessage id="work.admin_data.open_access.funded.affiliation" />:{' '}
                            <Notation v="affiliations_list" k={adminDataWorkOpenAccessFundedDTO.affiliation} />
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                  <div className="clear" />
                </>
              )}
            {!!work.adminDataWork.adminDataWorkOpenAccess && !!work.adminDataWork.adminDataWorkOpenAccess.accessLevel && (
              <>
                <h4 className="work-admin-data-open-access-access-level">
                  <FormattedMessage id="work.admin_data.open_access.access_level" />
                </h4>
                <span className="col-right">
                  <Notation v="access_level_list" k={work.adminDataWork.adminDataWorkOpenAccess.accessLevel} />
                </span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.license && (
                <>
                  <h4 className="work-admin-data-license">
                    <FormattedMessage id="work.admin_data.license" />
                  </h4>
                  <span className="col-right">
                    <Notation v="license_list" k={work.adminDataWork.license} />
                  </span>
                  <div className="clear" />
                </>
              )}
              {!!work.adminDataWork.licenseText && (
                <>
                  <h4 className="work-admin-data-license-text">
                    <FormattedMessage id="work.admin_data.license_text" />
                  </h4>
                  <span className="col-right">{work.adminDataWork.licenseText}</span>
                  <div className="clear" />
                </>
              )}
            {!!work.adminDataWork.licenseManagementStatus && (
              <>
                <h4 className="work-admin-data-license-management-status">
                  <FormattedMessage id="work.admin_data.license_management_status" />
                </h4>
                <span className="col-right">
                  <Notation v="license_management_status_list" k={work.adminDataWork.licenseManagementStatus} />
                </span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.adminDataWorkOpenAccess &&
              !!work.adminDataWork.adminDataWorkOpenAccess.articleProcessingCharge && (
                <>
                  <h4 className="work-admin-data-open-access-article-processing-charge">
                    <FormattedMessage id="work.admin_data.open_access.article_processing_charge" />
                  </h4>
                  <span className="col-right">
                    {work.adminDataWork.adminDataWorkOpenAccess.articleProcessingCharge}
                  </span>
                  <div className="clear" />
                </>
              )}
            {work.adminDataWork.obligatoryDelivery !== undefined && (
              <>
                <h4 className="work-admin-data-obligatory-delivery">
                  <FormattedMessage id="work.admin_data.obligatory_delivery" />
                </h4>
                <span className="col-right">{boolFilter(work.adminDataWork.obligatoryDelivery)}</span>
                <div className="clear" />
              </>
            )}
            {!!work.adminDataWork.adminDataWorkOrcidSyncs && (
              <>
                <h4 className="work-admin-data-orcid-syncs">
                  <FormattedMessage id="work.detail.orcid_syncs" />
                </h4>
                <span className="col-right">{boolFilter(work.adminDataWork.obligatoryDelivery)}</span>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.adminDataWork.adminDataWorkOrcidSyncs.map(
                    (adminDataWorkOrcidSyncDTO: AdminDataWorkOrcidSyncDTO): JSX.Element => (
                      <li>
                        {!!adminDataWorkOrcidSyncDTO.orcidId && (
                          <div>
                            <FormattedMessage id="work.admin_data.orcid_sync.orcid_id" />:{' '}
                            {adminDataWorkOrcidSyncDTO.orcidId}
                          </div>
                        )}
                        {!!adminDataWorkOrcidSyncDTO.orcidPutCode && (
                          <div>
                            <FormattedMessage id="work.admin_data.orcid_sync.orcid_put_code" />:{' '}
                            {adminDataWorkOrcidSyncDTO.orcidPutCode}
                          </div>
                        )}
                        {!!adminDataWorkOrcidSyncDTO.orcidVisibility && (
                          <div>
                            <FormattedMessage id="work.admin_data.orcid_sync.orcid_visibility" />:{' '}
                            {adminDataWorkOrcidSyncDTO.orcidVisibility}
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
                <div className="clear" />
              </>
            )}
          </div>
        </div>
      </DetailView>
    </div>
  );
};

export default DetailGuestUser;
