import * as React from 'react';
import { Flex } from 'theme-ui';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface SidebarProps {
  className?: string;
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const className = 'sidebar' + (!!props.className ? ' ' + props.className : '');

  return (
    <Flex className={className} sx={{
      flexDirection: "column",
      flexShrink: 0,
      width: "30em",
      p: 4,
      borderRightWidth: "1px",
      borderRightStyle: "solid",
      borderRightColor: "light"
    }}>
        <div>{props.children}</div>
    </Flex>
  );
};

export default Sidebar;
