import * as React from 'react';
import { RenderTagProps } from 'react-tagsinput';
import { SuggestApi } from '../../../api/core/apis/SuggestApi';
import { ReferencedPersonNameDTO, SolrDocument } from '../../../api/core/models';
import { MultiAutocomplete } from '../../../BaseLayout/components/form';
import { DeleteStrategy, Props as MultiAutocompleteProps } from '../../../BaseLayout/components/form/MultiAutocomplete';
import { ReactElement, useState } from 'react';
import { Box, Flex } from 'theme-ui';
import { InputLabel } from '../../../BaseLayout/components/form/generic/InputLabel';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../validation/interfaces';
import { ConnectedTopOrgaTooltip as TopOrgaTooltip } from 'Edit/container/TopOrgaTooltip';
import { TopOrgaEntity } from 'Edit/types';
import { getUnstyledExternalIdIcon, getUnstyledAffiliationIcon} from 'Detail/utils';

export interface State {
  suggestions?: SolrDocument[];
}
export interface InputProps {
  placeholder: string;
}
export interface Props {
  name?: string;
  help?: string;
  onChange?: (values: any[]) => void;
  tags?: ReferencedPersonNameDTO[];
  autocompleteProps?: Partial<MultiAutocompleteProps>;
  inputProps?: Partial<InputProps>;
  single?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
  required?: boolean;
  validation?: ValidationResult;
  autoFocus?: boolean;
}

export function PersonSuggest(props: Props): React.JSX.Element {
  const tags: ReferencedPersonNameDTO[] = props.tags ?? [];
  const onChange: (values: any[]) => void = props.onChange ? props.onChange : (): void => {};
  const single: boolean = props.single ?? false;
  const canDelete: boolean = props.canDelete ?? true;
  const required: boolean = props.required ?? false;
  const disabled: boolean = props.disabled ?? false;
  const autoFocus: boolean = props.autoFocus ?? false;
  const [suggestions, setSuggestions] = useState<SolrDocument[]>([]);
  const validation: ValidationResult = props.validation ?? { valid: true };
  if(required && tags.length < 1) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }

  return (
    <InputLabel
      label={props.name}
      required={required}
      variant="labelY"
      invalid={validation && !validation.valid}
      help={props.help}>
      <MultiAutocomplete
        autoFocus={autoFocus}
        required={required}
        canDelete={canDelete}
        allwaysSuggest={true}
        inputProps={props.inputProps}
        remote={true}
        disabled={disabled}
        tags={tags}
        deleteStrategy={DeleteStrategy.SPLICE}
        single={single}
        options={suggestions}
        onSuggestionsClearRequested={(): void => {}}
        onChange={onChange}
        validation={validation}
        onSuggestionSelected={(event, { suggestion }: { suggestion: SolrDocument }) => {

          let givenname: string = '';
          let surname: string = '';

          if (suggestion.person_name[0]) {

            const lastIndex = suggestion.person_name[0].lastIndexOf(' ');

            if (lastIndex !== -1) {
              givenname = suggestion.person_name[0].slice(0, lastIndex);
              surname = suggestion.person_name[0].slice(lastIndex+1);
            }

          }
          return {
            surname,
            givenname,
            id: parseInt(suggestion.id, 10),
          };
        }}
        renderTag={(props: RenderTagProps): React.JSX.Element => {
          const { tag, key, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
          if (!tag) {
            return <></>;
          }

          const givenname: string = tag.givenname ? tag.givenname : tag?.personNames ? tag.personNames.find(name => name.isMain)?.givenname : '';
          const surname: string = tag.surname ? tag.surname : tag?.personNames ? tag.personNames.find(name => name.isMain)?.surname : '';
          const personId = tag.personId ? tag.personId : tag.id ? tag.id : '';

          return (
            <TopOrgaTooltip
              entity={TopOrgaEntity.PERSONS}
              id={personId}
              name={givenname + ' ' + surname}
              key={givenname + ' ' + surname}
            >
              <span key={key} {...other} sx={{
                display: "inline-flex",
                backgroundColor: "darker",
                color: "white",
                px: 4,
                py: 2,
                mb: 2,
                mr: 3
              }}>
                <div>
                  {getTagDisplayValue(`${givenname} ${surname}`)}
                </div>
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{personId}&nbsp;
                </div>
                {!props.disabled && canDelete && <a  sx={{
                cursor: "pointer",
                color: "white",
                pl: 3
              }} onClick={() => onRemove(Number(key))} >x</a>}
              </span>
            </TopOrgaTooltip>
          );
        }}
        onSuggestionsFetchRequested={async (e) => {
          const api = new SuggestApi();
          const suggestions: SolrDocument[] = await api.personSuggest({ q: e.value });
          setSuggestions(suggestions);
        }}
        renderSuggestions={(suggestion: SolrDocument): ReactElement => {
          return (
            <Flex sx={{flexFlow: 'column wrap'}}>
              <Flex sx={{gap: '0.5em', flexWrap: 'wrap'}}>
                <Box sx={{fontWeight: 'bold'}}>
                  {suggestion.person_name ? suggestion.person_name.join(', ') : '' }
                </Box>
                {suggestion.f_top_orga && suggestion.f_top_orga.map(s =>
                  <Box>
                    {getUnstyledAffiliationIcon(s, {height: '0.7em'}, TopOrgaEntity.PERSONS)}
                  </Box>)}
              </Flex>
              {suggestion.person_aka && <Box><small>{suggestion.person_aka.join(', ')}</small></Box>}
              {suggestion.f_organisation && <Box><small>{suggestion.f_organisation.map((s: string): string => s.substring(s.indexOf('#')+1)).join(', ')}</small></Box>}
              {suggestion.f_group && <Box><small>{suggestion.f_group.map((s: string): string => s.substring(s.indexOf('#')+1)).join(', ')}</small></Box>}
              {(suggestion.gnd_id || suggestion.orcid_id) &&
                <Box><small>
                  <Flex sx={{alignItems: 'center', gap: '0.3em'}}>
                  {suggestion.gnd_id &&
                    <><Box>{getUnstyledExternalIdIcon({type: 'gnd'}, {height: '1em'}, 'person')}&nbsp;{suggestion.gnd_id.join(', ')}</Box></>
                  }
                  {suggestion.gnd_id && suggestion.orcid_id &&
                    <Box>&nbsp;|&nbsp;</Box>
                  }
                  {suggestion.orcid_id &&
                    <Box>{getUnstyledExternalIdIcon({type: 'orcid'}, {height: '1em'}, 'person')}&nbsp;{suggestion.orcid_id.join(', ')}</Box>
                  }
                  </Flex>
                </small></Box>
              }
            </Flex>
          );
        }}
        {...props.autocompleteProps}
      />
    </InputLabel>
  );
}
