import { DatePickerStyled } from 'BaseLayout/components/form/DatePickerDateRange/DatePicker';
import React, { ReactElement } from 'react';
import { defineMessages } from 'react-intl';
import { DateObject } from 'react-multi-date-picker';
import { AdminDataWorkDTO, WorkDTO } from '../../../../api/core';
import { DataModelGate } from '../../../../Auth/components';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';

const messages = defineMessages({
  changed: { id: 'work.attr.adminDataWork.attr.changed' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  validation: ValidationResult;
}

export function DateChanged({modifiedModel, entity, updateField, validation}: Props): ReactElement {
  return <DataModelGate always={false} entity={entity} field="changed">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      readable && <DatePickerStyled
        label={messages.changed.id}
        disabled={true}
        value={modifiedModel.adminDataWork.changed ? modifiedModel.adminDataWork.changed : undefined}
        validation={validation}
        onChange={(data: DateObject): void =>
          updateField('adminDataWork', { ...modifiedModel.adminDataWork, changed: data === null ? undefined : new Date(data.format("YYYY-MM-DD"))})
        }
      />
    )}
  </DataModelGate>;
}
