import React, { ReactElement } from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction } from '../../../../Auth/components/DataModelGate';

/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  workSubjectType: { id: 'work.attr.medium' },
  workSubjectTypeHelp: { id: 'work.attr.medium.help' },
});

interface Props {
  medium?: string;
  listPartition: string;
  onChange: (data: string) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

function InnerMedium({
  medium,
  listPartition,
  onChange,
  intl,
  checkPermission,
  validation
}: Props & WrappedComponentProps): ReactElement {
  return (
    <div className="col" sx={{ gridColumn: "span 1" }}>
      <SelectInputP
        listPartition={listPartition}
        onChange={(value: string): void => onChange(value)}
        name={intl.formatMessage(messages.workSubjectType)}
        help={intl.formatMessage(messages.workSubjectTypeHelp)}
        list="medium_list"
        value={medium ? medium : undefined}
        inputName="role"
        placeholder=""
        permissions={checkPermission()}
        validation={validation}
      />
    </div>
  );
}

export const Medium = injectIntl(InnerMedium);
