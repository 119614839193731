/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReferencedOrganisationDTO
 */
export interface ReferencedOrganisationDTO {
    /**
     * 
     * @type {number}
     * @memberof ReferencedOrganisationDTO
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof ReferencedOrganisationDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ReferencedOrganisationDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferencedOrganisationDTO
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ReferencedOrganisationDTO
     */
    oldId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ReferencedOrganisationDTO
     */
    startDate?: Date;
}

export function ReferencedOrganisationDTOFromJSON(json: any): ReferencedOrganisationDTO {
    return ReferencedOrganisationDTOFromJSONTyped(json, false);
}

export function ReferencedOrganisationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferencedOrganisationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'oldId': !exists(json, 'oldId') ? undefined : json['oldId'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
    };
}

export function ReferencedOrganisationDTOToJSON(value?: ReferencedOrganisationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'name': value.name,
        'note': value.note,
        'oldId': value.oldId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
    };
}


