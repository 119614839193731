import {
  GetDifferenceOneEnum,
  GetDifferenceTwoEnum,
  GetIsPartOfFieldsTypeEnum,
  GetIsPartOfFieldsIpoTypeEnum,
  GetGroupsTypeEnum,
  PubtypeServiceApi,
} from '../api/pubtype';

export const dateToUTC = (date: Date) => {
  try {
    return new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ));
  }
  catch {
    return null;
  }
};



export interface FieldSettings {
  volume: boolean;
  issue: boolean;
  pageFirst: boolean;
  pageLast: boolean;
  articleId: boolean;
}

/**
 * Parses a pubtype into the enumKey for PubtypeApi
 *
 * @param pubtype
 * @return enumKey
 */
export const pubtypeToEnumKey = (pubtype: string): string =>  {
  while (pubtype.includes('_')) {
    pubtype = pubtype.replace('_', '');
  }
  // while loop because replaceAll is not working on chrome
  // pubtype = pubtype.replaceAll('_', '');
  return pubtype.toUpperCase();
};

/**
 * Returns an array of strings containing all fields, which are part of the old pubtype but not of the new pubtype.
 *
 * @param api
 * @param oldPubtype string
 * @param newPubtype string
 * @return string[]
 * @throws Error
 */
export const apiLostFields = async (api: PubtypeServiceApi, oldPubtype: string, newPubtype: string): Promise<string[]> => {
  // If no pubtype is set yet, there are no fields, that get lost on change.
  if (!oldPubtype) {
    return [];
  }
  // Get pubtype groups of both pubtypes
  const promises = [oldPubtype, newPubtype]
    .map((pubtype: string) => GetGroupsTypeEnum[pubtypeToEnumKey(pubtype)])
    .map((type) => api.getGroups({ type }))
    .map(async (resp: Promise<string[]>): Promise<string> => (await resp)[0]);

  const [keyOne, keyTwo] = (await Promise.all(promises)).map((group: string): string => pubtypeToEnumKey(group));
  return await api.getDifference({
    one: GetDifferenceOneEnum[keyOne],
    two: GetDifferenceTwoEnum[keyTwo],
  });
};

export const apiWorkPartFields = async (api: PubtypeServiceApi, parentPubtype: string, partPubtype: string): Promise<string[]> => {
  const parentEnum: string = pubtypeToEnumKey(parentPubtype);
  const partEnum: string = pubtypeToEnumKey(partPubtype);

  return api.getIsPartOfFields({ type: GetIsPartOfFieldsTypeEnum[partEnum], ipoType: GetIsPartOfFieldsIpoTypeEnum[parentEnum] });
};

export const getWorkPartFields = async (api: PubtypeServiceApi, pubtypes: { parent: string; part: string }): Promise<FieldSettings> => {
  return apiWorkPartFields(api, pubtypes.parent, pubtypes.part)
    .then((keys: string[]): FieldSettings => {
      return { issue: keys.includes('ISSUE'), articleId: keys.includes('ARTICLE_ID'), volume: keys.includes('VOLUME'), pageFirst: keys.includes('PAGE_FIRST'), pageLast: keys.includes('PAGE_LAST') };
    })
    .catch((e): FieldSettings => {
      console.error(e);
      return { issue: false, articleId: false, volume: false, pageFirst: false, pageLast: false };
    });
};

export const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/g, (group: string): string => group.toUpperCase().replace('-', '').replace('_', ''));
