/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminDataPersonDTO,
    AdminDataPersonDTOFromJSON,
    AdminDataPersonDTOFromJSONTyped,
    AdminDataPersonDTOToJSON,
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
    PersonEmailDTO,
    PersonEmailDTOFromJSON,
    PersonEmailDTOFromJSONTyped,
    PersonEmailDTOToJSON,
    PersonInstitutionalIdDTO,
    PersonInstitutionalIdDTOFromJSON,
    PersonInstitutionalIdDTOFromJSONTyped,
    PersonInstitutionalIdDTOToJSON,
    PersonNameDTO,
    PersonNameDTOFromJSON,
    PersonNameDTOFromJSONTyped,
    PersonNameDTOToJSON,
    PersonStatusDTO,
    PersonStatusDTOFromJSON,
    PersonStatusDTOFromJSONTyped,
    PersonStatusDTOToJSON,
    PersonToOrganisationDTO,
    PersonToOrganisationDTOFromJSON,
    PersonToOrganisationDTOFromJSONTyped,
    PersonToOrganisationDTOToJSON,
    ReferencedProjectDTO,
    ReferencedProjectDTOFromJSON,
    ReferencedProjectDTOFromJSONTyped,
    ReferencedProjectDTOToJSON,
    UrlDTO,
    UrlDTOFromJSON,
    UrlDTOFromJSONTyped,
    UrlDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PersonDTO
 */
export interface PersonDTO {
    /**
     * 
     * @type {number}
     * @memberof PersonDTO
     */
    id: number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonDTO
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonDTO
     */
    oldId?: string;
    /**
     * 
     * @type {Array<PersonEmailDTO>}
     * @memberof PersonDTO
     */
    personEmails?: Array<PersonEmailDTO>;
    /**
     * 
     * @type {Array<PersonNameDTO>}
     * @memberof PersonDTO
     */
    personNames?: Array<PersonNameDTO>;
    /**
     * 
     * @type {Array<PersonStatusDTO>}
     * @memberof PersonDTO
     */
    personStatuses?: Array<PersonStatusDTO>;
    /**
     * 
     * @type {Array<UrlDTO>}
     * @memberof PersonDTO
     */
    urls?: Array<UrlDTO>;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof PersonDTO
     */
    externalIds?: Array<ExternalIdDTO>;
    /**
     * 
     * @type {Array<PersonInstitutionalIdDTO>}
     * @memberof PersonDTO
     */
    personInstitutionalIds?: Array<PersonInstitutionalIdDTO>;
    /**
     * 
     * @type {Array<PersonToOrganisationDTO>}
     * @memberof PersonDTO
     */
    personOrganisations?: Array<PersonToOrganisationDTO>;
    /**
     * 
     * @type {Array<ReferencedProjectDTO>}
     * @memberof PersonDTO
     */
    projects?: Array<ReferencedProjectDTO>;
    /**
     * 
     * @type {AdminDataPersonDTO}
     * @memberof PersonDTO
     */
    adminDataPerson?: AdminDataPersonDTO;
}

export function PersonDTOFromJSON(json: any): PersonDTO {
    return PersonDTOFromJSONTyped(json, false);
}

export function PersonDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'oldId': !exists(json, 'oldId') ? undefined : json['oldId'],
        'personEmails': !exists(json, 'personEmails') ? undefined : ((json['personEmails'] as Array<any>).map(PersonEmailDTOFromJSON)),
        'personNames': !exists(json, 'personNames') ? undefined : ((json['personNames'] as Array<any>).map(PersonNameDTOFromJSON)),
        'personStatuses': !exists(json, 'personStatuses') ? undefined : ((json['personStatuses'] as Array<any>).map(PersonStatusDTOFromJSON)),
        'urls': !exists(json, 'urls') ? undefined : ((json['urls'] as Array<any>).map(UrlDTOFromJSON)),
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
        'personInstitutionalIds': !exists(json, 'personInstitutionalIds') ? undefined : ((json['personInstitutionalIds'] as Array<any>).map(PersonInstitutionalIdDTOFromJSON)),
        'personOrganisations': !exists(json, 'personOrganisations') ? undefined : ((json['personOrganisations'] as Array<any>).map(PersonToOrganisationDTOFromJSON)),
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ReferencedProjectDTOFromJSON)),
        'adminDataPerson': !exists(json, 'adminDataPerson') ? undefined : AdminDataPersonDTOFromJSON(json['adminDataPerson']),
    };
}

export function PersonDTOToJSON(value?: PersonDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'note': value.note,
        'oldId': value.oldId,
        'personEmails': value.personEmails === undefined ? undefined : ((value.personEmails as Array<any>).map(PersonEmailDTOToJSON)),
        'personNames': value.personNames === undefined ? undefined : ((value.personNames as Array<any>).map(PersonNameDTOToJSON)),
        'personStatuses': value.personStatuses === undefined ? undefined : ((value.personStatuses as Array<any>).map(PersonStatusDTOToJSON)),
        'urls': value.urls === undefined ? undefined : ((value.urls as Array<any>).map(UrlDTOToJSON)),
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
        'personInstitutionalIds': value.personInstitutionalIds === undefined ? undefined : ((value.personInstitutionalIds as Array<any>).map(PersonInstitutionalIdDTOToJSON)),
        'personOrganisations': value.personOrganisations === undefined ? undefined : ((value.personOrganisations as Array<any>).map(PersonToOrganisationDTOToJSON)),
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ReferencedProjectDTOToJSON)),
        'adminDataPerson': AdminDataPersonDTOToJSON(value.adminDataPerson),
    };
}


