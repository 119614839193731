import * as React from 'react';
import { SuggestApi } from '../../../api/core/apis/SuggestApi';
import { ReferencedOrganisationDTO, SolrDocument} from '../../../api/core/models';
import { MultiAutocomplete } from '../../../BaseLayout/components/form';
import { DeleteStrategy, Props as MultiAutocompleteProps } from '../../../BaseLayout/components/form/MultiAutocomplete';
import { Box, Flex } from 'theme-ui';
import { useState } from 'react';
import { ValidationResult } from '../../validation/interfaces';
import { RenderTagProps } from 'react-tagsinput';
import { ConnectedTopOrgaTooltip as TopOrgaTooltip } from 'Edit/container/TopOrgaTooltip';
import { TopOrgaEntity } from 'Edit/types';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { getUnstyledAffiliationIcon, getUnstyledExternalIdIcon } from 'Detail/utils';
import { InputLabel } from '../../../BaseLayout/components/form/generic/InputLabel';

export interface State {
  suggestions?: SolrDocument[];
}
export interface InputProps {
  placeholder: string;
}
export interface Props {
  onChange?: (values: any[]) => void;
  tags?: ReferencedOrganisationDTO[];
  autocompleteProps?: Partial<MultiAutocompleteProps>;
  inputProps?: Partial<InputProps>;
  single?: boolean;
  canDelete?: boolean;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  help?: string;
  validation?: ValidationResult;
  autoFocus?: boolean;
}

export function OrganisationSuggest(props: Props): React.JSX.Element {
  const tags: ReferencedOrganisationDTO[] = props.tags ?? [];
  const single: boolean = props.single ?? false;
  const canDelete: boolean = props.canDelete ?? true;
  const disabled: boolean = props.disabled ?? false;
  const required: boolean = props.required ?? false;
  const autoFocus: boolean = props.autoFocus ?? false;
  const onChange: (values: any[]) => void = props.onChange ? props.onChange : (): void => {};
  const [suggestions, setSuggestions] = useState<SolrDocument[]>([]);
  const validation: ValidationResult = props.validation ?? { valid: true };
  if(required && tags.length < 1) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }

  return (
    <InputLabel
      label={props.name}
      required={required}
      variant="labelY"
      invalid={validation && !validation.valid}
      help={props.help}>
      <MultiAutocomplete
        autoFocus={autoFocus}
        required={required}
        disabled={disabled}
        canDelete={canDelete}
        allwaysSuggest={true}
        inputProps={props.inputProps}
        remote={true}
        tags={tags}
        deleteStrategy={DeleteStrategy.SPLICE}
        options={suggestions}
        onSuggestionsClearRequested={() => {}}
        onChange={onChange}
        single={single}
        validation={validation}
        onSuggestionsFetchRequested={async (e): Promise<void> => {
          const api: SuggestApi = new SuggestApi();
          const fetchedSuggestions: SolrDocument[] = await api.organisationSuggest({ q: e.value });
          setSuggestions(fetchedSuggestions);
        }}
        onSuggestionSelected={(event, { suggestion }: { suggestion: SolrDocument }): ReferencedOrganisationDTO => ({
          id: parseInt(suggestion.id, 10),
          name: suggestion.organisation_name[0],
        })}
        renderTag={(renderTagProps: RenderTagProps): React.JSX.Element => {
          const { tag, key, onRemove, classNameRemove, getTagDisplayValue, ...other } = renderTagProps;
          if (!tag) {
            return <></>;
          }

          const organisationName = tag.name ? tag.name : '';
          const organisationId = tag.id ? tag.id : '';

          return (
            <TopOrgaTooltip
              entity={TopOrgaEntity.ORGS}
              id={organisationId}
              name={organisationName}
              key={organisationName}
            >
              <span key={key} {...other} sx={{
                display: "inline-flex",
                backgroundColor: "darker",
                color: "white",
                px: 4,
                py: 2,
                mb: 2,
                mr: 3
              }}>
                <div>
                  {getTagDisplayValue(`${organisationName}`)}
                </div>
                <div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{organisationId}&nbsp;
                </div>
                {!renderTagProps.disabled && canDelete && <a  sx={{
                cursor: "pointer",
                color: "white",
                pl: 3
              }} onClick={() => onRemove(Number(key))} >x</a>}
              </span>
            </TopOrgaTooltip>
          );
        }}
        renderSuggestions={(suggestion: SolrDocument): React.JSX.Element => {
          return (
            <Flex sx={{gap: '0.5em'}}>
              <Flex sx={{minWidth: '22%', maxWidth: '22%', alignItems: 'center', justifyContent: 'center'}}>
                {getUnstyledAffiliationIcon(suggestion.f_top_orga, {height: '0.7em'})}
              </Flex>
              <Flex sx={{flexFlow: 'column wrap'}}>
                <Box sx={{fontWeight: 'bold'}}>{suggestion.organisation_name ? suggestion.organisation_name.join(', ') : '' }</Box>
                {!!suggestion.organisation_aka &&
                  <Flex>
                    {suggestion.organisation_aka.map((otherName, i, arr): React.JSX.Element => {
                        const divider = i < arr.length - 1 && ',';
                        return (
                          <div className='organisation-otherNames' key={i}>
                            {otherName}
                            {divider}&nbsp;
                          </div>
                        );
                    })}
                  </Flex>
                }
                {suggestion.gnd_id &&
                  <Flex sx={{alignItems: 'center', gap: '0.3em'}}>
                    <Box>{getUnstyledExternalIdIcon({type: 'gnd'}, {height: '0.8em', mb: '-1px'}, 'orga')}</Box>
                    <Box>{suggestion.gnd_id.join(', ')}</Box>
                  </Flex>
                }
                {!suggestion.gnd_id && suggestion.institutional_id &&
                  <Box>{suggestion.institutional_id.join(', ')}</Box>
                }
              </Flex>
            </Flex>
          );
        }}
        {...props.autocompleteProps}
      />
    </InputLabel>
  );
}
