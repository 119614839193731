/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SolrDocument,
    SolrDocumentFromJSON,
    SolrDocumentToJSON,
} from '../models';

export interface OrganisationSuggestRequest {
    q: string;
}

export interface PersonSuggestRequest {
    q: string;
}

export interface ProjectSuggestRequest {
    q: string;
}

export interface PublisherSuggestRequest {
    q: string;
}

export interface WorkSuggestRequest {
    q: string;
}

/**
 * 
 */
export class SuggestApi extends runtime.BaseAPI {

    /**
     */
    async organisationSuggestRaw(requestParameters: OrganisationSuggestRequest): Promise<runtime.ApiResponse<Array<SolrDocument>>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling organisationSuggest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/suggest/organisation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SolrDocumentFromJSON));
    }

    /**
     */
    async organisationSuggest(requestParameters: OrganisationSuggestRequest): Promise<Array<SolrDocument>> {
        const response = await this.organisationSuggestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async personSuggestRaw(requestParameters: PersonSuggestRequest): Promise<runtime.ApiResponse<Array<SolrDocument>>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling personSuggest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/suggest/person`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SolrDocumentFromJSON));
    }

    /**
     */
    async personSuggest(requestParameters: PersonSuggestRequest): Promise<Array<SolrDocument>> {
        const response = await this.personSuggestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async projectSuggestRaw(requestParameters: ProjectSuggestRequest): Promise<runtime.ApiResponse<Array<SolrDocument>>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling projectSuggest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/suggest/project`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SolrDocumentFromJSON));
    }

    /**
     */
    async projectSuggest(requestParameters: ProjectSuggestRequest): Promise<Array<SolrDocument>> {
        const response = await this.projectSuggestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async publisherSuggestRaw(requestParameters: PublisherSuggestRequest): Promise<runtime.ApiResponse<Array<SolrDocument>>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling publisherSuggest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/suggest/publisher`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SolrDocumentFromJSON));
    }

    /**
     */
    async publisherSuggest(requestParameters: PublisherSuggestRequest): Promise<Array<SolrDocument>> {
        const response = await this.publisherSuggestRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async workSuggestRaw(requestParameters: WorkSuggestRequest): Promise<runtime.ApiResponse<Array<SolrDocument>>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling workSuggest.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/suggest/work`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SolrDocumentFromJSON));
    }

    /**
     */
    async workSuggest(requestParameters: WorkSuggestRequest): Promise<Array<SolrDocument>> {
        const response = await this.workSuggestRaw(requestParameters);
        return await response.value();
    }

}
