import * as React from 'react';

export interface Props {
  checked?: boolean;
  value?: string | number;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  titleText?: string;
  children?: any;
  key?: string | number;
}

function Radio (props: Props): React.JSX.Element {
  const onChange = props.onChange ? props.onChange : () => {};
  const className = props.className ? props.className : '';
  const value = props.value ? props.value : '';
  const checked = props.checked ? props.checked : false;
  const name = props.name ? props.name : '';
  const titleText = props.titleText ? props.titleText : '';
  const {children} = props;

  return (
    <label className="radio-label" title={titleText}>
      <input
        type="radio"
        name={name}
        className={'radio' + (!!className ? ' ' + className : '')}
        onChange={onChange}
        value={value}
        checked={checked}
      />
      <span className="helper"></span>
      {children}
    </label>
  );
}

export default Radio;
