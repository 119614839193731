/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminDataProjectDTO,
    AdminDataProjectDTOFromJSON,
    AdminDataProjectDTOFromJSONTyped,
    AdminDataProjectDTOToJSON,
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
    ProjectFundingReferenceDTO,
    ProjectFundingReferenceDTOFromJSON,
    ProjectFundingReferenceDTOFromJSONTyped,
    ProjectFundingReferenceDTOToJSON,
    ProjectInstitutionalIdDTO,
    ProjectInstitutionalIdDTOFromJSON,
    ProjectInstitutionalIdDTOFromJSONTyped,
    ProjectInstitutionalIdDTOToJSON,
    ProjectOtherNameDTO,
    ProjectOtherNameDTOFromJSON,
    ProjectOtherNameDTOFromJSONTyped,
    ProjectOtherNameDTOToJSON,
    ProjectOtherPartnerDTO,
    ProjectOtherPartnerDTOFromJSON,
    ProjectOtherPartnerDTOFromJSONTyped,
    ProjectOtherPartnerDTOToJSON,
    ProjectToPersonDTO,
    ProjectToPersonDTOFromJSON,
    ProjectToPersonDTOFromJSONTyped,
    ProjectToPersonDTOToJSON,
    ReferencedOrganisationDTO,
    ReferencedOrganisationDTOFromJSON,
    ReferencedOrganisationDTOFromJSONTyped,
    ReferencedOrganisationDTOToJSON,
    ReferencedProjectDTO,
    ReferencedProjectDTOFromJSON,
    ReferencedProjectDTOFromJSONTyped,
    ReferencedProjectDTOToJSON,
    SubjectDTO,
    SubjectDTOFromJSON,
    SubjectDTOFromJSONTyped,
    SubjectDTOToJSON,
    UrlDTO,
    UrlDTOFromJSON,
    UrlDTOFromJSONTyped,
    UrlDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectDTO
 */
export interface ProjectDTO {
    /**
     * 
     * @type {number}
     * @memberof ProjectDTO
     */
    id: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDTO
     */
    canEdit?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<ProjectOtherNameDTO>}
     * @memberof ProjectDTO
     */
    otherNames?: Array<ProjectOtherNameDTO>;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    oldId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectDTO
     */
    startDate?: Date;
    /**
     * 
     * @type {Array<ReferencedProjectDTO>}
     * @memberof ProjectDTO
     */
    parents?: Array<ReferencedProjectDTO>;
    /**
     * 
     * @type {Array<ReferencedProjectDTO>}
     * @memberof ProjectDTO
     */
    childs?: Array<ReferencedProjectDTO>;
    /**
     * 
     * @type {Array<ReferencedProjectDTO>}
     * @memberof ProjectDTO
     */
    successor?: Array<ReferencedProjectDTO>;
    /**
     * 
     * @type {Array<ReferencedProjectDTO>}
     * @memberof ProjectDTO
     */
    predecessors?: Array<ReferencedProjectDTO>;
    /**
     * 
     * @type {Array<ReferencedOrganisationDTO>}
     * @memberof ProjectDTO
     */
    partner?: Array<ReferencedOrganisationDTO>;
    /**
     * 
     * @type {Array<ProjectToPersonDTO>}
     * @memberof ProjectDTO
     */
    projectPersons?: Array<ProjectToPersonDTO>;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof ProjectDTO
     */
    externalIds?: Array<ExternalIdDTO>;
    /**
     * 
     * @type {Array<ProjectInstitutionalIdDTO>}
     * @memberof ProjectDTO
     */
    projectInstitutionalIds?: Array<ProjectInstitutionalIdDTO>;
    /**
     * 
     * @type {Array<UrlDTO>}
     * @memberof ProjectDTO
     */
    urls?: Array<UrlDTO>;
    /**
     * 
     * @type {Array<ProjectOtherPartnerDTO>}
     * @memberof ProjectDTO
     */
    projectOtherPartners?: Array<ProjectOtherPartnerDTO>;
    /**
     * 
     * @type {Array<SubjectDTO>}
     * @memberof ProjectDTO
     */
    projectSubjects?: Array<SubjectDTO>;
    /**
     * 
     * @type {Array<ProjectFundingReferenceDTO>}
     * @memberof ProjectDTO
     */
    projectFundingReferences?: Array<ProjectFundingReferenceDTO>;
    /**
     * 
     * @type {AdminDataProjectDTO}
     * @memberof ProjectDTO
     */
    adminDataProject?: AdminDataProjectDTO;
}

export function ProjectDTOFromJSON(json: any): ProjectDTO {
    return ProjectDTOFromJSONTyped(json, false);
}

export function ProjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'otherNames': !exists(json, 'otherNames') ? undefined : ((json['otherNames'] as Array<any>).map(ProjectOtherNameDTOFromJSON)),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'oldId': !exists(json, 'oldId') ? undefined : json['oldId'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'parents': !exists(json, 'parents') ? undefined : ((json['parents'] as Array<any>).map(ReferencedProjectDTOFromJSON)),
        'childs': !exists(json, 'childs') ? undefined : ((json['childs'] as Array<any>).map(ReferencedProjectDTOFromJSON)),
        'successor': !exists(json, 'successor') ? undefined : ((json['successor'] as Array<any>).map(ReferencedProjectDTOFromJSON)),
        'predecessors': !exists(json, 'predecessors') ? undefined : ((json['predecessors'] as Array<any>).map(ReferencedProjectDTOFromJSON)),
        'partner': !exists(json, 'partner') ? undefined : ((json['partner'] as Array<any>).map(ReferencedOrganisationDTOFromJSON)),
        'projectPersons': !exists(json, 'projectPersons') ? undefined : ((json['projectPersons'] as Array<any>).map(ProjectToPersonDTOFromJSON)),
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
        'projectInstitutionalIds': !exists(json, 'projectInstitutionalIds') ? undefined : ((json['projectInstitutionalIds'] as Array<any>).map(ProjectInstitutionalIdDTOFromJSON)),
        'urls': !exists(json, 'urls') ? undefined : ((json['urls'] as Array<any>).map(UrlDTOFromJSON)),
        'projectOtherPartners': !exists(json, 'projectOtherPartners') ? undefined : ((json['projectOtherPartners'] as Array<any>).map(ProjectOtherPartnerDTOFromJSON)),
        'projectSubjects': !exists(json, 'projectSubjects') ? undefined : ((json['projectSubjects'] as Array<any>).map(SubjectDTOFromJSON)),
        'projectFundingReferences': !exists(json, 'projectFundingReferences') ? undefined : ((json['projectFundingReferences'] as Array<any>).map(ProjectFundingReferenceDTOFromJSON)),
        'adminDataProject': !exists(json, 'adminDataProject') ? undefined : AdminDataProjectDTOFromJSON(json['adminDataProject']),
    };
}

export function ProjectDTOToJSON(value?: ProjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'canEdit': value.canEdit,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
        'name': value.name,
        'otherNames': value.otherNames === undefined ? undefined : ((value.otherNames as Array<any>).map(ProjectOtherNameDTOToJSON)),
        'note': value.note,
        'oldId': value.oldId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substr(0,10)),
        'parents': value.parents === undefined ? undefined : ((value.parents as Array<any>).map(ReferencedProjectDTOToJSON)),
        'childs': value.childs === undefined ? undefined : ((value.childs as Array<any>).map(ReferencedProjectDTOToJSON)),
        'successor': value.successor === undefined ? undefined : ((value.successor as Array<any>).map(ReferencedProjectDTOToJSON)),
        'predecessors': value.predecessors === undefined ? undefined : ((value.predecessors as Array<any>).map(ReferencedProjectDTOToJSON)),
        'partner': value.partner === undefined ? undefined : ((value.partner as Array<any>).map(ReferencedOrganisationDTOToJSON)),
        'projectPersons': value.projectPersons === undefined ? undefined : ((value.projectPersons as Array<any>).map(ProjectToPersonDTOToJSON)),
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
        'projectInstitutionalIds': value.projectInstitutionalIds === undefined ? undefined : ((value.projectInstitutionalIds as Array<any>).map(ProjectInstitutionalIdDTOToJSON)),
        'urls': value.urls === undefined ? undefined : ((value.urls as Array<any>).map(UrlDTOToJSON)),
        'projectOtherPartners': value.projectOtherPartners === undefined ? undefined : ((value.projectOtherPartners as Array<any>).map(ProjectOtherPartnerDTOToJSON)),
        'projectSubjects': value.projectSubjects === undefined ? undefined : ((value.projectSubjects as Array<any>).map(SubjectDTOToJSON)),
        'projectFundingReferences': value.projectFundingReferences === undefined ? undefined : ((value.projectFundingReferences as Array<any>).map(ProjectFundingReferenceDTOToJSON)),
        'adminDataProject': AdminDataProjectDTOToJSON(value.adminDataProject),
    };
}


