import * as React from 'react';
import { ReactElement } from 'react';
import { FormattedMessage, useIntl, defineMessages, IntlShape } from 'react-intl';
import { WorkDTO, WorkPersonDTO, WorkPersonOtherDTO, ExternalIdDTO } from 'api/core/models/';
import { ViewType } from 'Detail/types';
import {
  formSubjectFilter,
  pubStatusFilter,
  pubtypeFilter,
  resourceTypeFilter,
} from '../../../Vocabulary/data';
import { OutLink } from '../../../BaseLayout/components/misc/OutLink';
import { getDetailContextUrl } from '../../../data';
import { getAffiliationIcons, getExternalPersonIdImages } from '../../utils';
import { Link } from '@theme-ui/components';
import { MathJax } from 'better-react-mathjax';
import parse from 'html-react-parser';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { getThemeNameFromAuth } from 'BaseLayout/components';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';

const messages = defineMessages({
  volume:     { id: 'work.attr.workPart.attr.volume' },
  issue:      { id: 'work.attr.workPart.attr.issue' },
  articleId:  { id: 'work.attr.isPartOfOther.attr.articleId' },
  pages:      { id: 'work.attr.isPartOfOther.attr.pages' },
  pageFirst:  { id: 'work.attr.isPartOfOther.attr.pageFirst' },
  pageLast:   { id: 'work.attr.isPartOfOther.attr.pageLast' }
});

interface HeaderProps {
  work: WorkDTO;
}

const Header: React.FC<HeaderProps> = (props) => {
  const intl: IntlShape = useIntl();


  /**
   * Personen mit den Rollen dgs, ths oder mms_th2 sollen nach Ticket #1262 her nicht mehr angezeigt werden
    */
  const headerExcludedRoles: string[] = ["dgs", "ths", "mms_th2", "his", "hnr", "dgg", "mms_rea"];

  const { work } = props;

  return (
    <Subscribe to={[OAuth2Container]}>
      {(c: OAuth2Container) => {
        const themeName =  getThemeNameFromAuth(c);

        return (
          <header className="head" sx={{
            mb: '3em',
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            a: {
              textDecoration: 'none',
              color: '#646464',
              ":hover": {
                color:'primary'
              }
            },
            ".MathJax.CtxtMenu_Attached_0": {
              display: 'contents'
            }
          }}>
            <div>
              <div className="head-keywords-color" sx={{
                mt: '-1em',
                mb: '1em',
                ul: {
                  mt: '-0.5em',
                  li: {
                    mt: '0.5em',
                    mr: '0.5em',
                    mb: 'auto',
                    ml: 'auto',
                    display: 'inline',
                    pt: '0.2em',
                    pr: '0.6em',
                    pl: '0.6em',
                    pb: '0.3em',
                    fontSize: '85%',
                    borderRadius: '6px',
                    fontWeight: 'bold',
                    lineHeight: '1',
                    color: '#fff',
                    textAlign: 'center',
                  }
                }
              }}>
                <ul>
                  <li className="keyword-pubtype" sx={{backgroundColor: 'secondary'}}>{pubtypeFilter(work.pubtype)}</li>
                  {work.formSubject && <li className="keyword-subject" sx={{backgroundColor: '#6B7E94'}}>{formSubjectFilter(work.formSubject)}</li>}
                  {work.resourceType && <li className="keyword-resourcetype" sx={{backgroundColor: '#808080'}}>{resourceTypeFilter(work.resourceType)}</li>}
                  {work.publicationStatus && <li className="keyword-pubstatus" sx={{backgroundColor: '#D88D77'}}>{pubStatusFilter(work.publicationStatus)}</li>}
                </ul>
              </div>
              <h1 className="head-name" sx={{
                display: 'block',
                fontSize: '2.5em',
                fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
                fontStyle: 'normal'
              }}>
                {[work.title, work.subseriesTitle]
                  .filter(i => !!i)
                  .map<React.ReactNode>(title => <MathJax>{parse(title)}</MathJax>)
                  .reduce((prev, curr) => [prev, ' / ', curr])
                }
              </h1>
              {(!!work.subtitle) && <h3 className="head-subtitle" sx={{
                fontSize: '2em',
                display: 'block',
                fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
                fontStyle: 'normal'
              }}>
                <MathJax>{parse(work.subtitle)}</MathJax>
              </h3>}
              {(!!work.workPersons || !!work.workPersonOthers) &&
                <div className="head-persons" sx={{
                  mt: '0.5em',
                  mr: 'auto',
                  mb: '1.5em',
                  ml: 'auto',
                  ul: {
                    li: {
                      display: 'inline-block',
                      whiteSpace: 'pre',
                      a: {
                        img: {
                          width: '1em'
                        }
                      }
                    }
                  }
                }}>
                  <ul>
                    {([] as (WorkPersonDTO | WorkPersonOtherDTO)[])
                      .concat(work.workPersons)
                      .concat(work.workPersonOthers)
                      .filter(person =>
                        person !== undefined &&
                        !headerExcludedRoles.includes(person.role)
                      )
                      .sort((per1: WorkPersonDTO | WorkPersonOtherDTO, per2: WorkPersonDTO | WorkPersonOtherDTO): number => per1.sequence - per2.sequence)
                      .map((person: WorkPersonDTO | WorkPersonOtherDTO, i: number, arr: (WorkPersonDTO | WorkPersonOtherDTO)[]): ReactElement => {
                        const divider = i < arr.length - 1 && ', ';
                        if (person.hasOwnProperty('personName')) {
                          return (
                            <li key={i}>
                              <Link href={getDetailContextUrl(ViewType.PEOPLE, (person as WorkPersonDTO).personName.personId)}>
                                {(person as WorkPersonDTO).personName.givenname
                                  ? (person as WorkPersonDTO).personName.givenname + ' '
                                  : ''}
                                {(person as WorkPersonDTO).personName.surname ?? ''}
                              </Link>{' '}
                              {(person as WorkPersonDTO).externalIds && getExternalPersonIdImages(
                                (person as WorkPersonDTO).externalIds.filter((eid: ExternalIdDTO): boolean => eid.type === 'orcid')
                              )}
                              {(person as WorkPersonDTO).topOrgas && getAffiliationIcons(
                                (person as WorkPersonDTO).topOrgas
                              )}
                              {divider}
                            </li>
                          );
                        } else {
                          return (
                            <li key={i}>
                              {(person as WorkPersonOtherDTO).givenname}
                              {!!(person as WorkPersonOtherDTO).surname
                                ? ` ${(person as WorkPersonOtherDTO).surname}`
                                : ''}
                              {divider}
                            </li>
                          );
                        }
                      })}
                  </ul>
                </div>
              }
              <div className="head-meta" sx={{mt: '0.5em'}}>
                {!!work.issued && <b className="head-year" sx={{fontWeight: 'bold', fontSize: '1.2em', mr: '0.25em'}}>{Math.floor(work.issued / Math.pow(10, work.issued.toString().length - 4))}</b>}
                {!!work.workIsPartOfs &&
                  work.workIsPartOfs.map((ipo, i) => {
                    const strs = [];
                    if (ipo.volume) {
                      strs.push(`${intl.formatMessage(messages.volume)} ${ipo.volume}`);
                    }
                    if (ipo.issue) {
                      strs.push(`${intl.formatMessage(messages.issue)} ${ipo.issue}`);
                    }
                    if (ipo.articleId) {
                      strs.push(`${intl.formatMessage(messages.articleId)} ${ipo.articleId}`);
                    }
                    if (ipo.pageFirst && ipo.pageLast) {
                      strs.push(`${intl.formatMessage(messages.pages)} ${ipo.pageFirst}-${ipo.pageLast}`);
                    }
                    else if(ipo.pageFirst) {
                      strs.push(`${intl.formatMessage(messages.pageFirst)} ${ipo.pageFirst}`);
                    }
                    else if(ipo.pageLast) {
                      strs.push(`${intl.formatMessage(messages.pageLast)} ${ipo.pageLast}`);
                    }

                    return (
                      <div sx={{display: 'inline-block'}} key={i}>
                        in{' '}
                        <Link key={i * 2} className="reference" href={`/work/${ipo.referencedWork.id}`} sx={{
                          fontSize: '1.2em',
                          fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
                          fontStyle: 'italic',
                          fontWeight: 'bold',
                          display: 'inline-flex'
                        }}>
                          {ipo.referencedWork.subseriesTitle ?
                            <MathJax>{parse(ipo.referencedWork.title + ` / ${ipo.referencedWork.subseriesTitle}`)}</MathJax>
                            :
                            <MathJax>{parse(ipo.referencedWork.title)}</MathJax>
                          }
                        </Link>
                        , <span key={i * 2 + 1}>{strs.join(', ')}</span>
                      </div>
                    );
                  })}
              </div>
              <div className="head-edition" sx={{fontWeight: '1.2em', fontStyle: 'italic'}}>
                {!!work.workPublishers && work.workPublishers.length > 0 &&
                  <><FormattedMessage id="work.attr.publishers" />: {work.workPublishers.map(p => p.publisher.name).join(', ')}</>
                }
                {!!work.workPublishers && !!work.edition && ' - '}
                {work.edition}
                {!!work.edition && '. '}
                {!!work.edition && <FormattedMessage id="work.attr.edition" />}
              </div>
              <div className="head-dataversion">{work.dataVersion &&
                <><FormattedMessage id="work.attr.dataVersion" />: {work.dataVersion} </>
              }
              </div>
              {!!work.workDescriptions && work.workDescriptions.length > 0 &&
              <div className="head-abstract" sx={{
                fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
                fontStyle: 'normal',
                lineHeight: '23px',
                fontSize: '1.1em',
                ul: {
                  li: {
                    mb: '1.5em',
                    display: 'block'
                  }
                }
              }}>
                <ul>
                  {work.workDescriptions.map((description, i) =>
                    <div key={i}>
                      {!!description.content &&
                      <li><MathJax>{parse(description.content)}</MathJax></li>
                      }
                    </div>
                  )}
                </ul>
              </div>}
              {!!work.workKeywords && work.workKeywords.length > 0 &&
              <div className="head-keywords" sx={{
                mb: '0.8em',
                ul: {
                  mt: '-0.5em',
                  li: {
                    display: 'inline-block',
                    padding: '0.5em',
                    borderRadius: '5px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'black',
                    mt: '0.5em',
                    mr: '0.5em',
                    mb: 'auto',
                    ml: 'auto'
                  }
                }
              }}>
                <ul>
                {work.workKeywords.map((keyword, i) =>
                  <div key={i} sx={{display: 'inline-block'}}>{!!keyword.keyword && <li>{keyword.keyword}</li>}</div>
                )}
                </ul>
              </div>}
              {!!work.urls && work.urls.filter(e => e.type === 'fulltext').length > 0 && (
                <div className="head-url" sx={{mb: '0.8em'}}>
                  {work.urls && work.urls.map((url, i) => {
                    return (
                      <div sx={{display: 'inline-block'}} key={i}>
                        {!!url.url && url.type === 'fulltext' && work.urls.length === 1 && (
                          <OutLink href={url.url}>
                            <FormattedMessage id="view_full_text" />
                          </OutLink>
                        )}
                        {!!url.url && url.type === 'fulltext' && work.urls.length !== 1 && (
                          <div sx={{mr:'1.5em'}}>
                            <OutLink href={url.url}>
                              <FormattedMessage id="view_full_text" /> {i + 1}
                            </OutLink>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {work.adminDataWork.hidden &&
              <span className="stamp is-nope" sx={{
                float: 'right',
                transform: 'rotate(3deg)',
                color: '#d23',
                fontSize: '2rem',
                fontWeight: '700',
                border: '0.5rem',
                borderStyle: 'double',
                borderColor: '#23',
                display: 'inline-block',
                pt: '0.25rem',
                pb: '0.25rem',
                pl: '1rem',
                pr: '1rem',
                textTransform: 'uppercase',
                borderRadius: '1rem',
                fontFamily: 'Courier',
                WebkitMaskImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png')",
                WebkitMaskSize: '944px 604px',
                WebkitMaskPosition: '2rem 3rem',
                mixBlendMode: 'multiply'
              }}>
                <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />
              </span>}
            </div>
          </header>
        );
      }}
    </Subscribe>
  );
};

export default Header;
