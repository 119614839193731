import { css, SerializedStyles } from '@emotion/react';
import { Theme } from 'theme-ui';

// @TODO: Theme the remaining colors?
export default (theme: Theme): SerializedStyles => css(`
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-container--open {
    margin-top: -1px;
    display: block;
    position: absolute;
    width: calc(100%);
    min-width: 20em;
    font-size: 1em;
    z-index: 2;
    max-height: 19em;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    box-shadow: 5px 10px 7px silver;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    padding: 0.2em;
    font-size: 1em;
    cursor: pointer;
    border-top: 0.1em solid;
    color: ${theme.colors?.text};
    border-color: ${theme.colors?.light};
    &.react-autosuggest__suggestion--highlighted {
      color: ${theme.colors?.suggestion_fg};
      background-color: ${theme.colors?.suggestion_bg};
    }
  }
`);
