import { Button } from '@theme-ui/components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HierarchyApi } from '../../api/core/apis/HierarchyApi';
import { Hierarchy, ProjectDTO, ReferencedProjectDTO } from '../../api/core/models';
import { OAuth2Gate } from '../../Auth/components';
import { EmbeddedDashboard } from '../../Dashboard/components/EmbeddedDashboard';
import { DashboardType } from '../../Dashboard/types';
import { getDetailContextUrl } from '../../data';
import { DeleteProject } from '../../Edit/components/DeleteProject';
import { Notation } from '../../Vocabulary/components/Notation';
import { boolFilter, editStatusFilter } from '../../Vocabulary/data';
import { DetailViewDashboardType, DetailViewProps as Props, InnerDetailViewProps as InnerProps, ProjectDashboards, ViewType, ProjectRelationType } from '../types';
import { renderDate, renderDateTime, renderExternalIds, renderInstitutionalIds } from '../utils';
import { DetailView } from '../components/theme';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { History } from 'history';

export const DetailViewProjects = (props: Props): JSX.Element => {
  return (
    <Subscribe to={[OAuth2Container]}>
      {(c: OAuth2Container) => {
        const innerProps = { ...props, authorizer: c.authorizer()};
        return <InnerDetailViewProjects { ...innerProps } />;
      }}
    </Subscribe>
  );
};

function InnerDetailViewProjects(props: InnerProps): JSX.Element {
  const history: History = useHistory();
  const [activeDashboard, setActiveDashboard] = useState<DetailViewDashboardType>(null);
  // Details are open by default for roles ADMIN and EDITOR
  const [detailsOpen, setDetailsOpen] = useState(props.authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN']));
  const [deleteInit, setDeleteInit] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [hierarchy, setHierarchy] = useState<Hierarchy[]>(null);
  const { viewLoading, viewError, dashboardLoading, id } = props;

  const view: ProjectDTO = props.view as ProjectDTO;
  const isLoaded: boolean = !viewLoading && !viewError && !!view;
  const dashboardData: ProjectDashboards = props.dashboardData as ProjectDashboards;

  const apiHierarchy: HierarchyApi = new HierarchyApi();

  useEffect((): void => {
    setHierarchy(null);
  }, [id]);

  const fetchHierarchy = async (projectId: number): Promise<void> => {
    const projectHierarchy: Hierarchy[] = await apiHierarchy.getProjectHierarchy({ projectId });
    setHierarchy(projectHierarchy);
  };

  const getRelatedProjectsByType = (relationType: ProjectRelationType): ReferencedProjectDTO[] => {
    const v: ProjectDTO = props.view as ProjectDTO;

    switch (relationType) {
      case ProjectRelationType.CHILD:
        return v.childs;
      case ProjectRelationType.PARENT:
        return v.parents;
      case ProjectRelationType.PREDECESSOR:
        return v.predecessors;
      case ProjectRelationType.SUCCESSOR:
        return v.successor;
      default:
    }
  };

  if (viewError) {
    return <>viewError</>;
  }

  if (isLoaded && (hierarchy === null || hierarchy === undefined)) {
    void fetchHierarchy(view.id);
  }

  return (
    <>
    {deleteInit &&
    <DeleteProject
      projectId={props.id}
      projectName={view.name}
      isDeleteRequest={deleteRequest}
      onAbort={() => setDeleteInit(false)}
      onSuccess={() => {
        setDeleteInit(false);
        history.push("/search/projects");
      }}
      onError={() => {
        setDeleteInit(false);
      }}
    />
    }
    <DetailView>
      <div className="top">
        {isLoaded && (
          <>
            <article>
              <div className="head">
                <div className="right">
                <h1 className="head-name">{`${view.name}`}</h1>
                  {!!view.otherNames && (
                    <div className="sub-title">
                      <span className="sub-title-label">
                        <FormattedMessage id="entity.attr.otherName" />:&nbsp;
                      </span>
                      {view.otherNames.map((otherNameObj, i, arr) => {
                        const divider = i < arr.length - 1 && ',';
                        return (
                          <div className='project-otherNames' key={i}>
                            {otherNameObj.otherName}
                            {divider}&nbsp;
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="head-meta">
                    {!!view.startDate && (
                      <>
                        <b className="head-year project">{renderDate(view.startDate)}</b>
                        <b>&nbsp;~&nbsp;</b>
                        <b className="head-year project">
                          {view.endDate ? <>{renderDate(view.endDate)}</> : ''}
                        </b>
                      </>
                    )}
                    <div className="externalIds">
                      <span className="head-ids">
                        <FormattedMessage id="entity.attr.externalId" />:{' '}
                      </span>
                      {renderExternalIds(view.externalIds, 'project')}
                    </div>
                    {view.projectInstitutionalIds && (
                      <div className="externalIds">
                        <span className="head-ids">
                          <FormattedMessage id="entity.attr.institutionalId" />:
                        </span>
                        {renderInstitutionalIds(view.projectInstitutionalIds)}
                      </div>
                    )}
                    {!!hierarchy && hierarchy.length > 1 && (
                      <div className="hierarchy">
                        <ol>
                          {hierarchy
                            .sort((project1: Hierarchy, project2: Hierarchy): number => (project1.stage > project2.stage ? -1 : 1))
                            .map((project: Hierarchy, i: number, arr: Hierarchy[]): React.JSX.Element => {
                              const divider: ""|" >" = i < arr.length - 1 && ' >';
                              return view.id === project.entityId ? (
                                <li>
                                  {project.name}
                                  {divider}
                                </li>
                              ) : (
                                <li>
                                  <Link to={getDetailContextUrl(ViewType.PROJECTS) + '/' + project.entityId.toString(10)}>
                                    {project.name}
                                  </Link>
                                  {divider}
                                </li>
                              );
                            })}
                        </ol>
                      </div>
                    )}
                  </div>
                  {!!view.partner && view.partner.length > 0 && (
                    <div className="partner head-data">
                      <span className="head">
                        <Notation v="project_relation_type_list" k={ProjectRelationType.PARTNER} />:{' '}
                      </span>
                      <ul>
                        {view.partner.map((partner, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <li key={i}>
                              <Link to={getDetailContextUrl(ViewType.ORGS, partner.id)}>{partner.name}</Link>
                              {divider}&nbsp;
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {!!getRelatedProjectsByType(ProjectRelationType.PARENT) &&
                    getRelatedProjectsByType(ProjectRelationType.PARENT).length > 0 && (
                    <div className="parent head-data">
                      <span className="head">
                        <Notation v="project_relation_type_list" k={ProjectRelationType.PARENT} />:{' '}
                      </span>
                      <ul>
                        {getRelatedProjectsByType(ProjectRelationType.PARENT).map((parent, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <li key={i}>
                              <Link to={getDetailContextUrl(ViewType.PROJECTS, parent.id)}>{parent.name}</Link>
                              {divider}&nbsp;
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                  {!!view.projectFundingReferences && view.projectFundingReferences.length > 0 && (
                    <div className="funding head-data">
                      <span className="head">
                        <FormattedMessage id="project.detail.financed_by" />:{' '}
                      </span>
                      <ul>
                        {view.projectFundingReferences.map((funder, i, arr) => {
                          const divider = i < arr.length - 1 && ' -';
                          return (
                            <li key={i}>
                              {funder.funderName}
                              {divider}&nbsp;
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              {(view.adminDataProject.hidden !== null && view.adminDataProject.hidden !== undefined) && view.adminDataProject.hidden && (
                <span className="stamp is-nope">
                  <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />:
                </span>
              )}
              <div className="head-toolbar">
                <Button
                  onClick={() => setDetailsOpen(!detailsOpen)}
                >
                  <FormattedMessage id="button.Details" />
                </Button>
                <div className="edit">
                  {view.canEdit && (
                    <Button
                      onClick={() => window.location.href = `/project/${id}/edit`}
                    >
                      <FormattedMessage id="button.Edit" />
                    </Button>
                  )}
                  <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_ADMIN']}>
                    {view.canEdit && (
                      <Button
                        onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          setDeleteInit(true);
                          setDeleteRequest(false);
                        }}
                      >
                        <FormattedMessage id="button.Delete" />
                      </Button>
                    )}
                  </OAuth2Gate>
                  <OAuth2Gate anyRoles={['ROLE_EDITOR']}>
                    {view.adminDataProject.editorialStatus === 'new' ? (
                      <Button
                        onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          setDeleteInit(true);
                          setDeleteRequest(false);
                        }}
                      >
                        <FormattedMessage id="button.Delete" />
                      </Button>
                    ) : (
                      <Button
                        onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          setDeleteInit(true);
                          setDeleteRequest(true);
                        }}
                      >
                        <FormattedMessage id="button.DeleteRequest" />
                      </Button>
                    )}
                  </OAuth2Gate>
                </div>
              </div>
              <div className={`body ${!detailsOpen ? 'hidden' : ''}`}>
                <div className="info-group">
                  <div className="info-group-desc">
                    <h3>
                      <FormattedMessage id="project.detail.relations" />
                    </h3>
                  </div>
                  <div className="info-group-content">
                    {!!getRelatedProjectsByType(ProjectRelationType.PREDECESSOR) &&
                      getRelatedProjectsByType(ProjectRelationType.PREDECESSOR).length > 0 && (
                        <div className="predecessor">
                          <h4 className="head-predecessor">
                            <Notation v="project_relation_type_list" k={ProjectRelationType.PREDECESSOR} />:
                          </h4>
                          <ol>
                            {getRelatedProjectsByType(ProjectRelationType.PREDECESSOR).map((pred, i) => {
                              return (
                                <li key={i}>
                                  <Link to={getDetailContextUrl(ViewType.PROJECTS, pred.id)}>{pred.name}</Link>
                                </li>
                              );
                            })}
                          </ol>
                          <div className="clear"></div>
                        </div>
                      )}
                    {!!getRelatedProjectsByType(ProjectRelationType.SUCCESSOR) &&
                      getRelatedProjectsByType(ProjectRelationType.SUCCESSOR).length > 0 && (
                        <div className="successor">
                          <h4 className="head-successor">
                            <Notation v="project_relation_type_list" k={ProjectRelationType.SUCCESSOR} />:
                          </h4>
                          <ol>
                            {getRelatedProjectsByType(ProjectRelationType.SUCCESSOR).map((succ, i) => {
                              return (
                                <li key={i}>
                                  <Link to={getDetailContextUrl(ViewType.PROJECTS, succ.id)}>{succ.name}</Link>
                                </li>
                              );
                            })}
                          </ol>
                          <div className="clear"></div>
                        </div>
                      )}
                    {!!getRelatedProjectsByType(ProjectRelationType.PARENT)
                      && getRelatedProjectsByType(ProjectRelationType.PARENT).length > 0 && (
                      <div className="parent">
                        <h4 className="head-parent">
                          <Notation v="project_relation_type_list" k={ProjectRelationType.PARENT} />:
                        </h4>
                        <ol>
                          {getRelatedProjectsByType(ProjectRelationType.PARENT).map((parent, i) => {
                            return (
                              <li key={i}>
                                <Link to={getDetailContextUrl(ViewType.PROJECTS, parent.id)}>{parent.name}</Link>
                              </li>
                            );
                          })}
                        </ol>
                        <div className="clear"></div>
                      </div>
                    )}
                    {!!getRelatedProjectsByType(ProjectRelationType.CHILD)
                      && getRelatedProjectsByType(ProjectRelationType.CHILD).length > 0 && (
                      <div className="child">
                        <h4 className="head-child">
                          <Notation v="project_relation_type_list" k={ProjectRelationType.CHILD} />:
                        </h4>
                        <ol>
                          {getRelatedProjectsByType(ProjectRelationType.CHILD).map((child, i) => {
                            return (
                              <li key={i}>
                                <Link to={getDetailContextUrl(ViewType.PROJECTS, child.id)}>{child.name}</Link>
                              </li>
                            );
                          })}
                        </ol>
                        <div className="clear"></div>
                      </div>
                    )}
                    {!!view.partner && view.partner.length > 0 && (
                      <div className="partner">
                        <h4 className="head-partner">
                          <Notation v="project_relation_type_list" k={ProjectRelationType.PARTNER} />:
                        </h4>
                        <div>
                          {view.partner.map((partner, i, arr) => {
                            const divider = i < arr.length - 1 && ' | ';
                            return (
                              <>
                                <Link to={getDetailContextUrl(ViewType.ORGS, partner.id)}>{partner.name}</Link>
                                {divider}
                              </>
                            );
                          })}
                        </div>
                        <div className="clear"></div>
                      </div>
                    )}
                    {!!view.projectOtherPartners && view.projectOtherPartners.length > 0 && (
                      <div className="other-partner">
                        <h4 className="head-other-partner">
                          <FormattedMessage id="project.detail.other_partners" />
                        </h4>
                        <ol>
                          {view.projectOtherPartners.map((partnerOther, i) => {
                            return <li key={i}>{partnerOther.otherPartner}</li>;
                          })}
                        </ol>
                        <div className="clear"></div>
                      </div>
                    )}
                  </div>
                </div>
                {!!view.note && (
                  <div className="info-group">
                    <div className="info-group-desc">
                      <h3>
                        <FormattedMessage id="work.attr.note" />
                      </h3>
                    </div>
                    <div className="info-group-content">
                      <div className="note">
                        {view.note}
                        <div className="clear"></div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="info-group">
                  <div className="info-group-desc">
                    <h3>
                      <FormattedMessage id="additional_information" />
                    </h3>
                  </div>
                  <div className="info-group-content">
                    {!!view.projectFundingReferences && view.projectFundingReferences.length > 0 && (
                      <div className="funding">
                        <h4 className="head-funding">
                          <FormattedMessage id="project.detail.financed_by" />:
                        </h4>
                        <ol className="funding-list">
                          {view.projectFundingReferences.map((funder, i) => {
                            return (
                              <div key={i}>
                                {(!!funder.projectFunder ||
                                  !!funder.funderName ||
                                  !!funder.projectAcronym ||
                                  !!funder.externalProjectIdentifier ||
                                  !!funder.projectTitle ||
                                  !!funder.projectUri) && (
                                  <li>
                                    {!!funder.projectFunder && (
                                      <div className="funder-id">
                                        <b className="head-funder-id">
                                          <FormattedMessage id="project.attr.projectFunding.attr.funderIdentifier" />:{' '}
                                        </b>
                                        {/* @TODO: funderIdentifierType is a notation from funder_id_type_list vocabulary */}
                                        {funder.projectFunder.funderIdentifier} (
                                        {funder.projectFunder.funderIdentifierType})
                                      </div>
                                    )}
                                    {!!funder.externalProjectIdentifier && (
                                      <div className="funder-project-id">
                                        <b className="head-funder-project-id">
                                          <FormattedMessage id="project.attr.projectFunding.attr.externalProjectIdentifier" />:{' '}
                                        </b>
                                        {funder.externalProjectIdentifier}
                                      </div>
                                    )}
                                    {!!funder.funderName && (
                                      <div className="funder-name">
                                        <b className="head-funder-name">
                                          <FormattedMessage id="project.attr.projectFunding.attr.funderName" />:{' '}
                                        </b>
                                        {funder.funderName}
                                      </div>
                                    )}
                                    {!!funder.projectAcronym && (
                                      <div className="funder-project-acronym">
                                        <b className="head-funder-project-acronym">
                                          <FormattedMessage id="project.attr.projectFunding.attr.projectAcronym" />:{' '}
                                        </b>
                                        {funder.projectAcronym}
                                      </div>
                                    )}
                                    {!!funder.projectFundingReferenceFundingStreams &&
                                      funder.projectFundingReferenceFundingStreams.length > 0 && (
                                        <div className="funder-funding-stream">
                                          <b className="head-funding-stream">
                                            <FormattedMessage id="project.attr.projectFunding.attr.projectFundingReferenceFundingStreams" />:{' '}
                                          </b>
                                          <ul>
                                            {funder.projectFundingReferenceFundingStreams.map((fstream, j) => {
                                              return (
                                                <div key={j}>
                                                  {!!fstream.fundingStream && (
                                                    <li>
                                                      <Notation v="funding_stream_list" k={fstream.fundingStream} />
                                                    </li>
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      )}
                                    {!!funder.projectFundingReferenceFundingSubstreams &&
                                      funder.projectFundingReferenceFundingSubstreams.length > 0 && (
                                        <div className="funder-funding-substream1">
                                          <b className="head-funding-substream1">Funding substreams:{' '}
                                            <FormattedMessage id="project.attr.projectFunding.attr.projectFundingReferenceFundingSubstreams" />:{' '}
                                          </b>
                                          <ul>
                                            {funder.projectFundingReferenceFundingSubstreams.map((fsubstream, j) => {
                                              return (
                                                <div key={j}>
                                                  {!!fsubstream.fundingSubstream && (
                                                    <li>
                                                      <Notation
                                                        v="funding_substream_1_list"
                                                        k={fsubstream.fundingSubstream}
                                                      />
                                                    </li>
                                                  )}
                                                </div>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      )}
                                    {!!funder.projectUri && (
                                      <div className="funder-project-uri">
                                        <b className="head-funder-project-uri">
                                          <FormattedMessage id="project.attr.projectFunding.attr.projectUri" />:{' '}
                                        </b>
                                        {funder.projectUri}
                                      </div>
                                    )}
                                    {!!funder.projectTitle && (
                                      <div className="funder-project-title">
                                        <b className="head-funder-project-title">
                                          <FormattedMessage id="project.attr.projectFunding.attr.projectTitle" />:{' '}
                                        </b>
                                        {funder.projectTitle}
                                      </div>
                                    )}
                                  </li>
                                )}
                              </div>
                            );
                          })}
                        </ol>
                        <div className="clear"></div>
                      </div>
                    )}
                    {!!view.projectSubjects && view.projectSubjects.length > 0 && (
                      <div className="subjects">
                        <h4 className="head-subjects">
                          <FormattedMessage id="work.attr.subject" />:
                        </h4>
                        <ol>
                          {view.projectSubjects.map((subj, i) => {
                            return (
                              <>
                                {!!subj.label && !!subj.subjectId && !!subj.type && (
                                  <li key={i}>
                                    <div className="subject-id">
                                      <b className="head-subject-id">
                                        <FormattedMessage id="work.attr.subject.attr.id" />:{' '}
                                      </b>
                                      {subj.subjectId}
                                    </div>
                                    <div className="subject-type">
                                      <b className="head-subject-type">
                                        <FormattedMessage id="work.attr.subject.attr.type" />:{' '}
                                      </b>
                                      {subj.type}
                                    </div>
                                    <div className="subject-label">
                                      <b className="head-subject-label">
                                        <FormattedMessage id="work.attr.subject.attr.label" />:{' '}
                                      </b>
                                      {subj.label}
                                    </div>
                                  </li>
                                )}
                              </>
                            );
                          })}
                        </ol>
                        <div className="clear"></div>
                      </div>
                    )}
                    {!!view.urls && view.urls.length > 0 && (
                      <div className="websites">
                        <h4 className="head-websites">
                          <FormattedMessage id="work.attr.urls" />:
                        </h4>
                        <ol>
                          {view.urls.map((url, i) => {
                            return (
                              <li key={i}>
                                <a href={url.url}>{url.url}</a>
                              </li>
                            );
                          })}
                        </ol>
                        <div className="clear"></div>
                      </div>
                    )}
                  </div>
                </div>
                {!!view.adminDataProject && (
                  <div className="info-group">
                    <div className="info-group-desc">
                      <h3>
                        <FormattedMessage id="edit.project.admin_head" />
                      </h3>
                    </div>
                    <div className="info-group-content">
                      {!!view.adminDataProject.id && (
                        <div className="id">
                          <h4 className="head-id">ID:{' '}
                            <FormattedMessage id="work.attr.adminDataWork.attr.id" />:
                          </h4>
                          {view.adminDataProject.id}
                        </div>
                      )}
                      {!!view.adminDataProject.origin && (
                        <div className="origin">
                          <h4 className="head-origin">
                            <FormattedMessage id="work.attr.adminDataWork.attr.origin" />:
                          </h4>
                          {view.adminDataProject.origin}
                        </div>
                      )}
                      {!!view.adminDataProject.changed && (
                        <div className="changed">
                          <h4 className="head-changed">
                            <FormattedMessage id="work.attr.adminDataWork.attr.changed" />:
                          </h4>
                          {renderDateTime(view.adminDataProject.changed)}
                        </div>
                      )}
                      {!!view.adminDataProject.created && (
                        <div className="created">
                          <h4 className="head-created">
                            <FormattedMessage id="work.attr.adminDataWork.attr.created" />:
                          </h4>
                          {renderDateTime(view.adminDataProject.created)}
                        </div>
                      )}
                      {!!view.adminDataProject.deskman && (
                        <div className="deskman">
                          <h4 className="head-deskman">
                            <FormattedMessage id="work.attr.adminDataWork.attr.deskman" />:
                          </h4>
                          {view.adminDataProject.deskman}
                        </div>
                      )}
                      {!!view.adminDataProject.editorialStatus && (
                        <div className="editorialStatus">
                          <h4 className="head-editorialStatus">
                            <FormattedMessage id="work.attr.adminDataWork.attr.editorialStatus" />:
                          </h4>
                          {editStatusFilter(view.adminDataProject.editorialStatus)}
                        </div>
                      )}
                      {(view.adminDataProject.hidden !== null && view.adminDataProject.hidden !== undefined) && (
                        <div className="hidden">
                          <h4 className="head-hidden">
                            <FormattedMessage id="work.attr.adminDataWork.attr.hidden" />:
                          </h4>
                          {boolFilter(view.adminDataProject.hidden)}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </article>
          </>
        )}
      </div>
      <div className="dashboard-tabs">
        <div className="dashboard-tabs-inner">
          <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST']}>
            <button
              className={
                activeDashboard === DetailViewDashboardType.WORKS || activeDashboard === null ? 'tab active' : 'tab'
              }
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.WORKS);
              }}
            >
              <FormattedMessage id="entity.works" />
            </button>
          </OAuth2Gate>
          <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
            <button
              className={activeDashboard === DetailViewDashboardType.WORKS ? 'tab active' : 'tab'}
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.WORKS);
              }}
            >
              <FormattedMessage id="entity.works" />
            </button>
          </OAuth2Gate>

          <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST']}>
            <button
              className={activeDashboard === DetailViewDashboardType.PEOPLE ? 'tab active' : 'tab'}
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.PEOPLE);
              }}
            >
              <FormattedMessage id="entity.persons" />
            </button>
          </OAuth2Gate>
          <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
            <button
              className={
                activeDashboard === DetailViewDashboardType.PEOPLE || activeDashboard === null ? 'tab active' : 'tab'
              }
              onClick={(e) => {
                e.preventDefault();
                setActiveDashboard(DetailViewDashboardType.PEOPLE);
              }}
            >
              <FormattedMessage id="entity.persons" />
            </button>
          </OAuth2Gate>

          <button
            className={activeDashboard === DetailViewDashboardType.PEOPLE_INCLUDING_CHILDS ? 'tab active' : 'tab'}
            onClick={(e) => {
              e.preventDefault();
              setActiveDashboard(DetailViewDashboardType.PEOPLE_INCLUDING_CHILDS);
            }}
          >
            <FormattedMessage id="entity.persons" /> <small>(<FormattedMessage id="including_childs" />)</small>
          </button>
        </div>
      </div>
      <div className="dasboard">
        {!!dashboardData && (
          <>
            <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
              {(activeDashboard === DetailViewDashboardType.PEOPLE || activeDashboard === null) &&
              !dashboardLoading &&
              dashboardData.persons ? (
                <EmbeddedDashboard
                  entity={DashboardType.PEOPLE}
                  searchQuery={{
                    q: 'id:' + (dashboardData.persons.length ? dashboardData.persons.join(' OR id:') : '0'),
                  }}
                />
              ) : (
                ''
              )}
            </OAuth2Gate>
            <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST']}>
              {activeDashboard === DetailViewDashboardType.PEOPLE && !dashboardLoading && dashboardData.persons ? (
                <EmbeddedDashboard
                  entity={DashboardType.PEOPLE}
                  searchQuery={{
                    q: 'id:' + (dashboardData.persons.length ? dashboardData.persons.join(' OR id:') : '0'),
                  }}
                />
              ) : (
                ''
              )}
            </OAuth2Gate>

            {activeDashboard === DetailViewDashboardType.PEOPLE_INCLUDING_CHILDS &&
            !dashboardLoading &&
            dashboardData.personsByProjectIncludingChilds ? (
              <EmbeddedDashboard
                entity={DashboardType.PEOPLE}
                searchQuery={{
                  q:
                    'id:' +
                    (dashboardData.personsByProjectIncludingChilds.length
                      ? dashboardData.personsByProjectIncludingChilds.join(' OR id:')
                      : '0'),
                }}
              />
            ) : (
              ''
            )}

            <OAuth2Gate anyRoles={['ROLE_EDITOR', 'ROLE_ADMIN']}>
              {activeDashboard === DetailViewDashboardType.WORKS && !dashboardLoading ? (
                <EmbeddedDashboard
                  entity={DashboardType.WORKS}
                  searchQuery={{
                    q: 'project_id_belongs_to:' + id.toString(10),
                  }}
                />
              ) : (
                ''
              )}
            </OAuth2Gate>
            <OAuth2Gate anyRoles={['ROLE_USER', 'ROLE_GUEST']}>
              {(activeDashboard === DetailViewDashboardType.WORKS || activeDashboard === null) &&
              !dashboardLoading ? (
                <EmbeddedDashboard
                  entity={DashboardType.WORKS}
                  searchQuery={{
                    q: 'project_id_belongs_to:' + id.toString(10),
                  }}
                />
              ) : (
                ''
              )}
            </OAuth2Gate>
          </>
        )}
      </div>
    </DetailView>
    </>
  );
}
