import React, { ReactElement } from 'react';
import { WorkGeoLocationPointDTO } from 'api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkGeoLocationPoint } from '../../../../data/emptyModels';
import { AddButton, DeleteButton } from 'BaseLayout/components/form/MultiInput/Buttons';
import { FieldRow } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../../validation/interfaces';

const messages = defineMessages({
  id: { id: 'work.attr.geoLocationPoints.attr.id' },
  latitude: { id: 'work.attr.geoLocationPoints.attr.latitude' },
  longitude: { id: 'work.attr.geoLocationPoints.attr.longitude' },
});

interface GeoLocationPointsProps {
  geoLocationPoints: WorkGeoLocationPointDTO[];
  onChange: (geo: WorkGeoLocationPointDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

export function GeoLocationPoints({
  geoLocationPoints,
  onChange,
  checkPermission,
  validation
}: GeoLocationPointsProps): ReactElement {

  const intl: IntlShape = useIntl();
  // min Count is 4
  while (geoLocationPoints.length < 4) {
    geoLocationPoints.push({ latitude: '', longitude: '' });
  }
  const count = geoLocationPoints.filter((l) => l !== null).length;
  return (
    <>
      <MultiInputControls<WorkGeoLocationPointDTO>
        lines={geoLocationPoints}
        onChange={onChange}
        defaultRow={DefaultWorkGeoLocationPoint}
        add
        remove
        renderLines={(point: WorkGeoLocationPointDTO, onChange2: (data: WorkGeoLocationPointDTO) => void, actions: { [p: string]: (e?: any) => void }, idx: number) => (
            <FieldRow>
              <TextInputP
                permissions={checkPermission('work_geo_location_polygon.work_geo_location_points.longitude')}
                name={intl.formatMessage(messages.longitude)}
                value={point.longitude ? point.longitude : ''}
                onChange={(longitude: string): void => onChange2({ ...point, longitude })}
                validation={validation?.children[idx]?.longitude}
                autoFocus={count > 4 ? true : false}
              />
              <TextInputP
                permissions={checkPermission('work_geo_location_polygon.work_geo_location_points.latitude')}
                name={intl.formatMessage(messages.latitude)}
                value={point.latitude ? point.latitude : ''}
                onChange={(latitude: string): void => onChange2({ ...point, latitude })}
                validation={validation?.children[idx]?.latitude}
              />
              {!!actions.delete && count > 4 && (
                <div className="deleteButton">
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </FieldRow>
        )}
        renderEnd={(actionAdd) =>
          <div sx={{mt: '-1em'}}>
            <AddButton onClick={actionAdd} disabled={!checkPermission().write}/>
          </div>

        }
      />
    </>
  );
}
