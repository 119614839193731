import React, { ReactElement } from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { WorkKeywordDTO } from '../../../../api/core/models';
import { MultiInputControls } from '../../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkKeyword } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  keyword: { id: 'work.attr.workKeyword.attr.keyword' },
});

interface KeywordsProps {
  workKeywords?: WorkKeywordDTO[];
  onChange: (data: any) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

function InnerKeywords({
  workKeywords,
  onChange,
  intl,
  checkPermission,
  validation
}: KeywordsProps & WrappedComponentProps): ReactElement {
  return (
    <div className="col">
      <Styled.h2>
        <FormattedMessage id="work.attr.keywords" />
      </Styled.h2>
      <Border>
        <MultiInputControls<WorkKeywordDTO>
          add
          remove
          defaultRow={DefaultWorkKeyword}
          lines={workKeywords}
          onChange={onChange}
          renderLines={(
            workKeyword: WorkKeywordDTO,
            onChange2: (data: WorkKeywordDTO) => void,
            actions,
            idx: number
          ): ReactElement => (
            <div className="fieldRow" key={idx}>
              <TextInputP
              name={intl.formatMessage(messages.keyword)}
              value={workKeyword.keyword}
              inputName={'keyword'}
              onChange={(keyword: string): void => onChange2({ ...workKeyword, keyword })}
              permissions={checkPermission()}
              validation={validation?.children[idx]?.keyword}
              autoFocus={true}
              />
              {!!actions.delete && (
                <div className="deleteButton">
                  <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                </div>
              )}
            </div>
          )}
          renderEnd={(actionAdd) => (
            <div className="addButton">
              <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
            </div>
          )}
        />
      </Border>

    </div>
  );
}

export const Keywords = injectIntl(InnerKeywords);
