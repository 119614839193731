import React from 'react';
import Popup from 'BaseLayout/components/popup/Popup';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export const PeerReviewPopup: React.FC<{
    onClose: () => void;
    updateField: (name: string, data: any) => void;
    submit: () => Promise<void>;
}> = ({onClose, updateField, submit}) => {
  const intl: IntlShape = useIntl();

  return (
    <Popup
      open={true}
      title={intl.formatMessage({id: "edit.work.page.popup.peerReview.title"})}
      onClose={() => onClose()}
      buttons={[
        {
          label: intl.formatMessage({ id: 'edit.work.page.popup.peerReview.button.yes' }),
          onClick: () => {
              updateField('peerReviewed', 'true');
              submit();
          }
        },
        {
          label: intl.formatMessage({ id: 'edit.work.page.popup.peerReview.button.no' }),
          onClick: () => {
              updateField('peerReviewed', 'false');
              submit();
          }
        }
      ]}
      maxWidth='40em'
    >
      <FormattedMessage id="edit.work.page.popup.peerReview.text"/>
    </Popup>
  );
};
