import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownList } from 'react-widgets';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface PaginationProps {
  onPrevious: () => void;
  onNext: () => void;
  onFirst?: () => void;
  onLast?: () => void;
  totalEntries?: number;
  page?: number;
  first: boolean;
  last: boolean;
  pageSize?: number;
  pageSizeOptions?: number[];
  onChangePageSize?: (pageSize: number) => void;
}

export const Pagination = (props: PaginationProps): JSX.Element => {

  const { onPrevious, onNext, onFirst, onLast, totalEntries, page, first, last, pageSize, pageSizeOptions, onChangePageSize } = props;

  const ListItem = ({ item }) => <div>{item}</div>;

  const PageInfo = (): JSX.Element => {
    if (page != null && pageSize && totalEntries) {
      const firstPageEntry = (page * pageSize) + 1;
      let lastPageEntry = (page * pageSize) + pageSize;
      if (lastPageEntry > totalEntries) {
        lastPageEntry = totalEntries;
      }
      return <span className="pageInfo">{firstPageEntry}-{lastPageEntry} <FormattedMessage id="pagination.of" /> {totalEntries}</span>;
    }
    return (<></>);
  };

  return (
    <div className="pagination"
      sx={{
        mt: "1em",
        display: "flow-root",
      }}
    >
      <div className="inner" sx={{ float: "right", display: 'inline-flex' }}>
        {pageSizeOptions &&
          <div sx={{
            display: "inline-block",
            ".rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover": {
              backgroundColor: 'primary',
              borderColor: 'primary',
              color: "white"
            },
            ".rw-dropdown-list": {
              cursor: "pointer"
            },
            ".rw-btn": {
              color: 'darker',
            },
            ".rw-dropdown-list-input": {
              maxWidth: "initial",
              color: 'darker',
              fontWeight: 'bold'
            },
            ".rw-input": {
              p: "0 0 0.25em 0.5em"
            },
            ".rw-btn-select": {
              pb: '0.1em',
              width: '1em'
            },
            ".rw-widget-input": {
              boxShadow: "none"
            },
            ".rw-widget-container": {
              background: "none",
              border: "none",
            },
            ".rw-widget-picker": {
              height: "auto",
              borderRadius: 0
            },
            ".rw-popup": {
              borderRadius: 0
            },
            ".rw-popup-container": {
              right: "auto"
            },
            ".rw-list-option": {
              p: "0.15em 0.5em",
              whiteSpace: "nowrap"
            }
          }}>
            <DropdownList
              data={pageSizeOptions}
              value={pageSize ?? pageSizeOptions[0]}
              textField="displayName"
              itemComponent={ListItem}
              onChange={option => onChangePageSize(option)}
            />
          </div>
        }
        <div className="controls"
          sx={{
            display: "inline-block",
            ml: "10px",
            ".arrow": {
              display: "inline-block",
              fontSize: "0.6em",
              mx: "0.6em",
              cursor: "pointer",
              "&.inaktiv": {
                opacity: 0.5
              }
            }
          }}
        >
          {onFirst &&
          <i className={`icon icon-andenanfang_aktiv arrow ${first ? 'inaktiv' : ''}`} onClick={onFirst}></i>
          }
          <i className={`icon icon-links_aktiv arrow ${first ? 'inaktiv' : ''}`} onClick={onPrevious}></i>
          <PageInfo />
          <i className={`icon icon-rechts_aktiv arrow ${last ? 'inaktiv' : ''}`} onClick={onNext}></i>
          {onLast &&
          <i className={`icon icon-ansende_aktiv arrow ${last ? 'inaktiv' : ''}`} onClick={onLast}></i>
          }
        </div>
      </div>
    </div>
  );
};

export default Pagination;