import React  from 'react';
import { Textarea as ThemeTextarea } from 'theme-ui';
import { ReadWritePermissions } from '../../../../Auth/components/DataModelGate';
import { isRequired } from './utils';
import { ValidationResult } from '../../../../Edit/validation/interfaces';
import { InputLabel } from './InputLabel';
/** @jsx jsx */
import { jsx } from 'theme-ui';

interface Props {
  onChange: (value: string) => void;
  name?: string;
  help?: string;
  inputName: string;
  disabled?: boolean;
  inputProps?: {[key: string]: any};
  required?: boolean;
  value?: string;
  classes?: string[];
  validation?: ValidationResult;
  autoFocus?: boolean;
  key?: string;
}

export function Textarea({ value, classes, onChange, name, inputName, inputProps, disabled, required, validation, autoFocus, help }: Props): React.JSX.Element {
  if(!validation) validation = {valid: true};
  if(required && !value) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }
  classes = classes ? classes : [];
  if (required){
    classes.push("required");
  }
  if(validation && !validation.valid) {
    classes.push("invalid");
  }
  return (
    <InputLabel variant="labelY" classes={classes} label={name||''} required={required} help={help}>
      <div sx={{ display: "flex", alignItems: "center" }}>
        <ThemeTextarea
          sx={{ pr: "1.8em" }}
          {...inputProps}
          name={inputName ?? undefined}
          className={classes.join(" ")}
          value={value || ''}
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
          disabled={disabled || false}
          required={required || false}
          autoFocus={autoFocus || false}
        />
        <i className="icon-schliessen_weiss"
          sx={{
            "::before": { color: "primary" },
            ml: "-1.2em",
            fontSize: "1.4em",
            cursor: "pointer",
          }}
          onClick={() => onChange(null)}
        />
      </div>
    </InputLabel>
  );
}

interface PropsP extends Props {
  permissions: ReadWritePermissions;
}

/**
 * Textarea mit integrierter Lese- und Schreibberechtigungsprüfung
 *
 * @param props
 * @constructor
 */
export function TextAreaP(props: PropsP): React.JSX.Element {
  if (!props.permissions.read) {
    return <></>;
  }
  return (
    <Textarea
      {...props}
      disabled={!props.permissions.write || props.disabled}
      name={props.name}
      required={isRequired(props.permissions)}
    />
  );
}
