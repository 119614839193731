/** @jsx jsx */
import { Flex, jsx, Link, ThemeUIStyleObject } from 'theme-ui';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { StatisticsMobile } from './StatisticsMobile';
import { StatisticsDesktop } from './StatisticsDesktop';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export interface StatisticsProps {
  workCount: number;
  publicationsWosCount: number;
  publicationsScopusCount: number;
  publicationsPmidCount: number;
  viewLoading: boolean;
  viewError: string | null;
  orga: string;
}

export function Statistics(props: StatisticsProps) {
  const breakpoint = useBreakpointIndex({ defaultIndex: 0 });
  const isLoaded = !props.viewLoading && !props.viewError;

  const thousandSeparator = (number: number) => {
    return new Intl.NumberFormat().format(number).replace(',', '.');
  };

  const styling: ThemeUIStyleObject = {
    px: 3,
    py: 3,
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: 'white',
    borderBottomWidth: 8,
    borderBottomColor: 'secondary',
    borderBottomStyle: 'solid',
  };

  const data: {value: string; link: string; label: string}[] = [];
  switch (props.orga) {
    case "hhu":
      // hhu zeigt all und wos
      data.push({
        value: thousandSeparator(props.workCount),
        link: '/search/works?fq=f_top_orga%3A"Heinrich-Heine-Universität%20Düsseldorf"',
        label: 'statistics.workCount'
      });
      data.push({
        value: thousandSeparator(props.publicationsWosCount),
        link: '/search/works?fq=f_external_identifier%3A"wos"&fq=f_top_orga%3A"Heinrich-Heine-Universität%20Düsseldorf"',
        label: 'statistics.publicationsWosCount'
      });
      break;
    case "rub":
      // rub zeigt all, wos und scopus
      data.push({
        value: thousandSeparator(props.workCount),
        link: '/search/works?fq=f_top_orga%3A"Ruhr-Universität%20Bochum"',
        label: 'statistics.workCount'
      });
      data.push({
        value: thousandSeparator(props.publicationsWosCount),
        link: '/search/works?fq=f_external_identifier%3A"wos"&fq=f_top_orga%3A"Ruhr-Universität%20Bochum"',
        label: 'statistics.publicationsWosCount'
      });
      data.push({
        value: thousandSeparator(props.publicationsScopusCount),
        link: '/search/works?fq=f_external_identifier%3A"scopus"&fq=f_top_orga%3A"Ruhr-Universität%20Bochum"',
        label: 'statistics.publicationsScopusCount'
      });
      break;
    case "tudo":
      // tudo zeigt all, wos und scopus
      data.push({
        value: thousandSeparator(props.workCount),
        link: '/search/works?fq=f_top_orga%3A"Technische%20Universität%20Dortmund"',
        label: 'statistics.workCount'
      });
      data.push({
        value: thousandSeparator(props.publicationsWosCount),
        link: '/search/works?fq=f_external_identifier%3A"wos"&fq=f_top_orga%3A"Technische%20Universität%20Dortmund"',
        label: 'statistics.publicationsWosCount'
      });
      data.push({
        value: thousandSeparator(props.publicationsScopusCount),
        link: '/search/works?fq=f_external_identifier%3A"scopus"&fq=f_top_orga%3A"Technische%20Universität%20Dortmund"',
        label: 'statistics.publicationsScopusCount'
      });
      break;
    case "uwh":
      // tudo zeigt all, pmid und scopus
      data.push({
        value: thousandSeparator(props.workCount),
        link: '/search/works?fq=f_top_orga%3A"Universität%20Witten%2FHerdecke"',
        label: 'statistics.workCount'
      });
      data.push({
        value: thousandSeparator(props.publicationsPmidCount),
        link: '/search/works?fq=f_top_orga%3A"Universität%20Witten%2FHerdecke"&fq=f_external_identifier%3A"pmid"',
        label: 'statistics.publicationsPmidCount'
      });
      data.push({
        value: thousandSeparator(props.publicationsScopusCount),
        link: '/search/works?fq=f_top_orga%3A"Universität%20Witten%2FHerdecke"&fq=f_external_identifier%3A"scopus"',
        label: 'statistics.publicationsScopusCount'
      });
      break;
  }

  const tiles: JSX.Element[] = data.map((dat): React.ReactElement => (
      <Flex sx={styling}>
        <b>{dat.value}</b>
        <span>
                  <Link href={dat.link}>
                    <FormattedMessage id={dat.label}/>
                  </Link>
                </span>
      </Flex>));

  return isLoaded && breakpoint <= 2 ? (
    <StatisticsMobile tiles={tiles} />
  ) : (
    <StatisticsDesktop tiles={tiles} />
  );
}
