/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmploymentPeriodDTO,
    EmploymentPeriodDTOFromJSON,
    EmploymentPeriodDTOFromJSONTyped,
    EmploymentPeriodDTOToJSON,
    ReferencedPersonDTO,
    ReferencedPersonDTOFromJSON,
    ReferencedPersonDTOFromJSONTyped,
    ReferencedPersonDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganisationToPersonDTO
 */
export interface OrganisationToPersonDTO {
    /**
     * 
     * @type {number}
     * @memberof OrganisationToPersonDTO
     */
    id?: number | null;
    /**
     * 
     * @type {ReferencedPersonDTO}
     * @memberof OrganisationToPersonDTO
     */
    person?: ReferencedPersonDTO;
    /**
     * 
     * @type {Array<EmploymentPeriodDTO>}
     * @memberof OrganisationToPersonDTO
     */
    employmentPeriods?: Array<EmploymentPeriodDTO>;
}

export function OrganisationToPersonDTOFromJSON(json: any): OrganisationToPersonDTO {
    return OrganisationToPersonDTOFromJSONTyped(json, false);
}

export function OrganisationToPersonDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationToPersonDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'person': !exists(json, 'person') ? undefined : ReferencedPersonDTOFromJSON(json['person']),
        'employmentPeriods': !exists(json, 'employmentPeriods') ? undefined : ((json['employmentPeriods'] as Array<any>).map(EmploymentPeriodDTOFromJSON)),
    };
}

export function OrganisationToPersonDTOToJSON(value?: OrganisationToPersonDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'person': ReferencedPersonDTOToJSON(value.person),
        'employmentPeriods': value.employmentPeriods === undefined ? undefined : ((value.employmentPeriods as Array<any>).map(EmploymentPeriodDTOToJSON)),
    };
}


