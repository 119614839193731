import * as React from 'react';

import { Alert, Box } from 'theme-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faInfoCircle, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { IntlShape, useIntl } from 'react-intl';
import { AlertComponentProps, types } from 'react-alert';

export const AlertTemplate: React.FC<AlertComponentProps> = ({ message, options, close }: AlertComponentProps) => {

  const intl: IntlShape = useIntl();

  return (
    <Alert variant={options.type}>
      <Box className="alert-icon alert-severity">
        <FontAwesomeIcon className="alert-severity-icon"
          icon={options.type === types.SUCCESS ? faCheck : options.type === types.INFO ? faInfoCircle : faBan}
        />
      </Box>
      <Box className="alert-message">
        <Box className="alert-message-title">
          {options.type === types.SUCCESS
            ? intl.formatMessage({ id: 'alert.title.success' })
            : options.type === types.INFO
              ? intl.formatMessage({ id: 'alert.title.info' })
              : intl.formatMessage({ id: 'alert.title.error' })}
        </Box>
        <Box className="alert-message-body">
          {message}
        </Box>
      </Box>
      <Box className="alert-close">
        <FontAwesomeIcon icon={faWindowClose} onClick={close} className="alert-close-icon" />
      </Box>
    </Alert>
  );
};
