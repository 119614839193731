/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdminDataOrganisationDTO,
    AdminDataOrganisationDTOFromJSON,
    AdminDataOrganisationDTOFromJSONTyped,
    AdminDataOrganisationDTOToJSON,
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
    OrganisationInstitutionalIdDTO,
    OrganisationInstitutionalIdDTOFromJSON,
    OrganisationInstitutionalIdDTOFromJSONTyped,
    OrganisationInstitutionalIdDTOToJSON,
    OrganisationOtherNameDTO,
    OrganisationOtherNameDTOFromJSON,
    OrganisationOtherNameDTOFromJSONTyped,
    OrganisationOtherNameDTOToJSON,
    OrganisationToPersonDTO,
    OrganisationToPersonDTOFromJSON,
    OrganisationToPersonDTOFromJSONTyped,
    OrganisationToPersonDTOToJSON,
    ReferencedOrganisationDTO,
    ReferencedOrganisationDTOFromJSON,
    ReferencedOrganisationDTOFromJSONTyped,
    ReferencedOrganisationDTOToJSON,
    ReferencedProjectDTO,
    ReferencedProjectDTOFromJSON,
    ReferencedProjectDTOFromJSONTyped,
    ReferencedProjectDTOToJSON,
    SubjectDTO,
    SubjectDTOFromJSON,
    SubjectDTOFromJSONTyped,
    SubjectDTOToJSON,
    UrlDTO,
    UrlDTOFromJSON,
    UrlDTOFromJSONTyped,
    UrlDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganisationDTO
 */
export interface OrganisationDTO {
    /**
     * 
     * @type {number}
     * @memberof OrganisationDTO
     */
    id: number | null;
    /**
     * 
     * @type {Date}
     * @memberof OrganisationDTO
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<OrganisationOtherNameDTO>}
     * @memberof OrganisationDTO
     */
    otherNames?: Array<OrganisationOtherNameDTO>;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDTO
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationDTO
     */
    oldId?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrganisationDTO
     */
    startDate?: Date;
    /**
     * 
     * @type {Array<ReferencedProjectDTO>}
     * @memberof OrganisationDTO
     */
    projects?: Array<ReferencedProjectDTO>;
    /**
     * 
     * @type {Array<ReferencedOrganisationDTO>}
     * @memberof OrganisationDTO
     */
    parents?: Array<ReferencedOrganisationDTO>;
    /**
     * 
     * @type {Array<ReferencedOrganisationDTO>}
     * @memberof OrganisationDTO
     */
    childs?: Array<ReferencedOrganisationDTO>;
    /**
     * 
     * @type {Array<ReferencedOrganisationDTO>}
     * @memberof OrganisationDTO
     */
    successor?: Array<ReferencedOrganisationDTO>;
    /**
     * 
     * @type {Array<ReferencedOrganisationDTO>}
     * @memberof OrganisationDTO
     */
    predecessors?: Array<ReferencedOrganisationDTO>;
    /**
     * 
     * @type {Array<OrganisationToPersonDTO>}
     * @memberof OrganisationDTO
     */
    organisationPersons?: Array<OrganisationToPersonDTO>;
    /**
     * 
     * @type {Array<SubjectDTO>}
     * @memberof OrganisationDTO
     */
    organisationSubjects?: Array<SubjectDTO>;
    /**
     * 
     * @type {AdminDataOrganisationDTO}
     * @memberof OrganisationDTO
     */
    adminDataOrganisation?: AdminDataOrganisationDTO;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof OrganisationDTO
     */
    externalIds?: Array<ExternalIdDTO>;
    /**
     * 
     * @type {Array<OrganisationInstitutionalIdDTO>}
     * @memberof OrganisationDTO
     */
    organisationInstitutionalIds?: Array<OrganisationInstitutionalIdDTO>;
    /**
     * 
     * @type {Array<UrlDTO>}
     * @memberof OrganisationDTO
     */
    urls?: Array<UrlDTO>;
}

export function OrganisationDTOFromJSON(json: any): OrganisationDTO {
    return OrganisationDTOFromJSONTyped(json, false);
}

export function OrganisationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganisationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'otherNames': !exists(json, 'otherNames') ? undefined : ((json['otherNames'] as Array<any>).map(OrganisationOtherNameDTOFromJSON)),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'oldId': !exists(json, 'oldId') ? undefined : json['oldId'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ReferencedProjectDTOFromJSON)),
        'parents': !exists(json, 'parents') ? undefined : ((json['parents'] as Array<any>).map(ReferencedOrganisationDTOFromJSON)),
        'childs': !exists(json, 'childs') ? undefined : ((json['childs'] as Array<any>).map(ReferencedOrganisationDTOFromJSON)),
        'successor': !exists(json, 'successor') ? undefined : ((json['successor'] as Array<any>).map(ReferencedOrganisationDTOFromJSON)),
        'predecessors': !exists(json, 'predecessors') ? undefined : ((json['predecessors'] as Array<any>).map(ReferencedOrganisationDTOFromJSON)),
        'organisationPersons': !exists(json, 'organisationPersons') ? undefined : ((json['organisationPersons'] as Array<any>).map(OrganisationToPersonDTOFromJSON)),
        'organisationSubjects': !exists(json, 'organisationSubjects') ? undefined : ((json['organisationSubjects'] as Array<any>).map(SubjectDTOFromJSON)),
        'adminDataOrganisation': !exists(json, 'adminDataOrganisation') ? undefined : AdminDataOrganisationDTOFromJSON(json['adminDataOrganisation']),
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
        'organisationInstitutionalIds': !exists(json, 'organisationInstitutionalIds') ? undefined : ((json['organisationInstitutionalIds'] as Array<any>).map(OrganisationInstitutionalIdDTOFromJSON)),
        'urls': !exists(json, 'urls') ? undefined : ((json['urls'] as Array<any>).map(UrlDTOFromJSON)),
    };
}

export function OrganisationDTOToJSON(value?: OrganisationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString().substr(0,10)),
        'name': value.name,
        'otherNames': value.otherNames === undefined ? undefined : ((value.otherNames as Array<any>).map(OrganisationOtherNameDTOToJSON)),
        'note': value.note,
        'oldId': value.oldId,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString().substr(0,10)),
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ReferencedProjectDTOToJSON)),
        'parents': value.parents === undefined ? undefined : ((value.parents as Array<any>).map(ReferencedOrganisationDTOToJSON)),
        'childs': value.childs === undefined ? undefined : ((value.childs as Array<any>).map(ReferencedOrganisationDTOToJSON)),
        'successor': value.successor === undefined ? undefined : ((value.successor as Array<any>).map(ReferencedOrganisationDTOToJSON)),
        'predecessors': value.predecessors === undefined ? undefined : ((value.predecessors as Array<any>).map(ReferencedOrganisationDTOToJSON)),
        'organisationPersons': value.organisationPersons === undefined ? undefined : ((value.organisationPersons as Array<any>).map(OrganisationToPersonDTOToJSON)),
        'organisationSubjects': value.organisationSubjects === undefined ? undefined : ((value.organisationSubjects as Array<any>).map(SubjectDTOToJSON)),
        'adminDataOrganisation': AdminDataOrganisationDTOToJSON(value.adminDataOrganisation),
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
        'organisationInstitutionalIds': value.organisationInstitutionalIds === undefined ? undefined : ((value.organisationInstitutionalIds as Array<any>).map(OrganisationInstitutionalIdDTOToJSON)),
        'urls': value.urls === undefined ? undefined : ((value.urls as Array<any>).map(UrlDTOToJSON)),
    };
}


