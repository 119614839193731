import React, { useEffect } from 'react';
import workReportImg from 'BaseLayout/assets/img/homepage/work-report.svg';
import banner from 'BaseLayout/assets/img/mandants/uwh/banner.jpeg';
import publicationEmbedImg from 'BaseLayout/assets/img/homepage/publication-embed.svg';
/** @jsx jsx */
import { jsx, Grid, Card, Container, Flex, Input, Link } from 'theme-ui';
import { defineMessages, FormattedHTMLMessage, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { OAuth2Sender } from 'Auth/components';
import { Link as LinkRouter } from "react-router-dom";
import { useState } from 'react';
import ConnectedStatistics from 'HomePage/container/Statistics';
import { HomepageDesktopStyling } from '../theme';
import { Props as HomepageProps } from 'HomePage/pages/Homepage';


const messages = defineMessages({ placeholder: { id: 'search.placeholder' } });

export const UWHHomepageDesktop = (props: { props: HomepageProps }): React.JSX.Element => {
  const searchTerm: string = props.props.changedSearchTerm ? props.props.changedSearchTerm : props.props.searchTerm;
  const intl: IntlShape = useIntl();
  const [term, setTerm] = useState(searchTerm);

  const onChangeSearchHandler = (value: string): void => {
    const search: string = value ? value : "";
    setTerm(search);
  };

  useEffect((): void => {
    setTerm('');
  }, []);

  return (
    <React.Fragment>
      <HomepageDesktopStyling {...props}>
        <Container className='banner' variant="banner" sx={{height: props.props.breakpoint <= 3 ? '23em' : '715px',
          backgroundImage:  `url(${banner})`,
          }}>
          <h1 sx={{
            py: '12px !important',
            px: '17px !important'
          }}>
            <FormattedMessage id="uwh.homepage.title" />
          </h1>
          <Flex className='search'>
            <Input
              sx={{
                borderWidth: '2px',
                borderStyle: 'solid',
                borderColor: 'secondary',
                '::placeholder': {
                  color: 'secondary',
                  opacity: 1,
                },
              }}
              variant="inputFrontSearch"
              type="text"
              placeholder={intl.formatMessage(messages.placeholder)}
              value={term}
              onChange={(e): void => {
                onChangeSearchHandler(e.target.value);
              }}
              onKeyUp={(e): void => {
                if(e.keyCode === 13) {
                  props.props.onSearchSubmit((e.target as HTMLInputElement).value);
                }
              }}
            />
            <i className="icon-suche" onClick={() => { props.props.onSearchSubmit(term); }} />
            <i className="icon-schliessen_weiss" onClick={() => onChangeSearchHandler(null)} />
          </Flex>
        </Container>
        <Card>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div>
              <FormattedHTMLMessage id="uwh.homepage.intro.leftColumn" />
            </div>
            <div>
              <FormattedHTMLMessage id="uwh.homepage.intro.rightColumn" />
            </div>
          </Grid>
        </Card>

        <Card sx={{ backgroundColor: 'lighter' }}>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div>
              <h2 sx={{mb: '0.4em'}}>
                <FormattedMessage id="uwh.homepage.teaser1.title" />
              </h2>
              <p>
                <FormattedHTMLMessage id="uwh.homepage.teaser1.text" />
              </p>
              <br />
              <OAuth2Sender
                render={({ authUrl }) => (
                  <Link className='button' href={authUrl}>
                    <FormattedMessage id="uwh.homepage.teaser1.linktext" />
                  </Link>
                )}
              />
            </div>
            <div>
              <img src={workReportImg} />
            </div>
          </Grid>
        </Card>
        {/*  -> später, wenn orcid für uwh eingerichtet ist!
        <Card>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div>
              <img src={orcidLinkImg} />
            </div>
            <div>
              <h2>
                <FormattedMessage id="uwh.homepage.teaser2.title" />
              </h2>
              <p>
                <FormattedHTMLMessage id="uwh.homepage.teaser2.text" />
              </p>
              <br />
                <Link className='button' href="/user/settings/orcid">
                  <FormattedMessage id="uwh.homepage.teaser2.linktext" />
              </Link>
            </div>
          </Grid>
        </Card>
        */}
        <Card /* x={{ backgroundColor: 'lighter' }} ---> wenn orcid eingerichtet wurde*/>
          <Grid className='card-grid' columns={[2]} mx-auto>
            <div> {/* div entfernen, wenn orcid eingerichtet wurde */}
              <img src={publicationEmbedImg}/>
            </div>
            <div>
              <h2 sx={{mb: '0.4em'}}>
                <FormattedMessage id="uwh.homepage.teaser3.title" />
              </h2>
              <p>
                <FormattedHTMLMessage id="uwh.homepage.teaser3.text" />
              </p>
              <br />
              <LinkRouter className='button' to="/publist/generator">
                <FormattedMessage id="uwh.homepage.teaser3.linktext" />
              </LinkRouter>
            </div>
            {/* ---> wenn orcid eingerichtet wurde
            <div>
              <img src={publicationEmbedImg} />
            </div>
            */}
          </Grid>
        </Card>
        <ConnectedStatistics />
      </HomepageDesktopStyling>
    </React.Fragment>
  );
};
