import React, { ReactElement } from 'react';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { WorkDTO, WorkDescriptionDTO } from 'api/core/models';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultWorkDescription } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled} from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';
import { TextAreaP } from '../../../../BaseLayout/components/form/generic/Textarea';

const messages = defineMessages({
  description: { id: 'work.attr.description.attr.description' },
  language: { id: 'work.attr.description.attr.language' },
  type: { id: 'work.attr.description.attr.type' },
});

interface DescriptionsProps {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: WorkDescriptionDTO[]) => void;
  entity: string;
  pubtypeGroup: string;
  validation: ValidationResult;
}

export function Descriptions({
  modifiedModel, updateField, entity, pubtypeGroup, validation
}: DescriptionsProps): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <DataModelGate always={false} entity={entity} field="work_descriptions">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        <div className="col">
          <Styled.h2>
            <FormattedMessage id="work.attr.description" />
          </Styled.h2>
          <Border>
            <MultiInputControls<WorkDescriptionDTO>
              lines={modifiedModel.workDescriptions ? modifiedModel.workDescriptions : []}
              onChange={(data: WorkDescriptionDTO[]): void => updateField('workDescriptions', data)}
              remove
              add
              defaultRow={DefaultWorkDescription}
              renderLines={(
                description: WorkDescriptionDTO,
                onChange2: (data: WorkDescriptionDTO) => void,
                actions,
                idx: number
              ) => (
                <div key={idx}>
                  <div className="fieldRow">
                    <TextAreaP
                      key={`content-${idx}`}
                      inputName={`content-${idx}`}
                      inputProps={{ 'rows': 1 }}
                      classes={["flat"]}
                      permissions={checkPermission('content')}
                      name={intl.formatMessage(messages.description)}
                      value={description.content}
                      onChange={(content: string): void => onChange2({ ...description, content })}
                      validation={validation?.children[idx]?.content}
                      autoFocus={true}
                    />
                  </div>
                  <div className="fieldRow">
                    <SelectInputP
                        listPartition={pubtypeGroup}
                        onChange={(language: string): void => onChange2({ ...description, language })}
                        name={intl.formatMessage(messages.language)}
                        list="language_list"
                        value={description.language}
                        inputName="language"
                        permissions={checkPermission('language')}
                        placeholder=""
                        validation={validation?.children[idx]?.language}
                    />
                    <SelectInputP
                      listPartition={pubtypeGroup}
                      onChange={(descriptionType: string): void =>
                        onChange2({
                          ...description,
                          descriptionType,
                        })
                      }
                      name={intl.formatMessage(messages.type)}
                      list="description_types_list"
                      value={description.descriptionType}
                      inputName="descriptionType"
                      permissions={checkPermission('description_type')}
                      placeholder=""
                      validation={validation?.children[idx]?.description_type}
                    />
                  </div>
                  {!!actions.delete && (
                    <div sx={{mb: '1em', mt:'-0.5em'}}>
                      <DeleteButton onClick={actions.delete} disabled={!checkPermission().write}  />
                    </div>
                  )}
                </div>
              )}
              renderEnd={(actionAdd) => (
                <div className="addButton">
                  <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
                </div>
              )}
            />
          </Border>
        </div>
      )}
    </DataModelGate>
  );
}
