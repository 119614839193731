import React from 'react';
import { useHistory } from 'react-router-dom';
import { ResourceNotFoundError } from 'BaseLayout/components/error/resource-not-found-error';
import { GeneralError } from 'BaseLayout/components/error/general-error';
import { useEffect, createContext, useMemo, useState, useContext } from 'react';
import { AlertManager, types, useAlert } from 'react-alert';
import { defineMessages, useIntl, FormattedMessage, IntlShape } from 'react-intl';
import { MathJax } from 'better-react-mathjax';
import parse from 'html-react-parser';
/** @jsx jsx */
import { jsx } from 'theme-ui';

interface StatusCode {
  status: number;
  action?: undefined | 'delete' | 'insert' | 'update';
  data?: {
      entity: string;
      name: string;
      id: number | string;
      username?: string;
  } | undefined;
  errorCode?: string;
}

const messages = defineMessages({
  delete: { id: 'alert.action.success.delete' },
  insert: { id: 'alert.action.success.insert' },
  update: { id: 'alert.action.success.update' },
  entityWork: { id: 'entity.work'},
  entityPerson: { id: 'entity.person'},
  entityProject: { id: 'entity.project'},
  entityOrga: { id: 'entity.organisation'},
  actionFail_403: { id: 'alert.action.fail.403'},
  actionFail_502_503: { id: 'alert.action.fail.502_503'}
});

const StatusCodeContext: React.Context<any> = createContext(null);

export const StatusCodeHandler = ({ children }): any => {
  const history = useHistory();
  const alert: AlertManager = useAlert();
  const intl: IntlShape = useIntl();
  const [statusCode, setStatusCode] = useState<StatusCode>(undefined);

  const messageMapping = (entity: string): string => {
    switch(entity) {
      case 'user':
        return 'alert.action.success.user(name, action)';
      case 'publist':
        return 'alert.action.success.publist(name, id, action)';
      case 'publistShare':
        return 'alert.action.success.publistShare(name, id, username)';
      case 'publistShareDeleteBy':
        return 'alert.action.success.publistShareDeleteBy(name, id, username)';
      case 'publistShareDeleteWith':
        return 'alert.action.success.publistShareDeleteWith(name, id, username)';
      default:
        return 'alert.action.success.entity(entity, name, id, action)';
    }
  };

  const entityMapping = (entity: string): string | undefined => {
    switch(entity) {
      case 'works':
        return intl.formatMessage(messages.entityWork);
      case 'people':
        return intl.formatMessage(messages.entityPerson);
      case 'orgs':
        return intl.formatMessage(messages.entityOrga);
      case 'projects':
        return intl.formatMessage(messages.entityProject);
      default:
        return undefined;
    }
  };

  const actionMapping = (action: string): string | undefined => {
    switch(action) {
      case 'delete':
        return intl.formatMessage(messages.delete);
      case 'insert':
        return intl.formatMessage(messages.insert);
      case 'update':
        return intl.formatMessage(messages.update);
      default:
        return '';
    }
  };

  useEffect(() => {
    return history.listen(() => setStatusCode(undefined));
  }, [history]);

  const renderContent = () => {
    if (statusCode?.status === 200 || statusCode?.status === 204 || statusCode?.status === 201) {
      return React.cloneElement(children,
        {alert:
          statusCode?.data.entity.includes('publist') ?
          alert.show(
          <FormattedMessage id={messageMapping(statusCode.data.entity)}
            values = {{
              entity: entityMapping(statusCode.data.entity),
              name: statusCode.data.name,
              id: statusCode.data.id,
              username: statusCode.data.username,
              action: actionMapping(statusCode.action)
            }}
          />,
            {
              type: types.SUCCESS,
            }
          )
          :
          alert.show(
            <div sx={{
              ".MathJax.CtxtMenu_Attached_0": {
                display: 'contents'
              }
            }}>
              <MathJax>
                {parse(
                  intl.formatMessage(
                  { id: messageMapping(statusCode.data.entity) },
                  {
                    entity: entityMapping(statusCode.data.entity),
                    name: statusCode.data.name,
                    id: statusCode.data.id,
                    action: actionMapping(statusCode.action)
                  }
                ))}
              </MathJax>
            </div>
            ,
            {
              type: types.SUCCESS,
            }
          )
        });
    }

    if (statusCode?.status === 403) {
      return React.cloneElement(children,
        {alert:
          alert.show(intl.formatMessage(messages.actionFail_403),
            {
              type: types.ERROR,
            }
          )
        });
    }

    if (statusCode?.status === 404) {
      return <ResourceNotFoundError />;
    }

    if (statusCode?.status === 422) {
      return React.cloneElement(children,
        {alert:
          alert.show(intl.formatMessage({ id: 'errormsg.code.' + statusCode.errorCode }),
            {
              type: types.ERROR,
            }
          )
        });
    }

    if (statusCode?.status === 502 || statusCode?.status === 503) {
      return React.cloneElement(<GeneralError />,
        {alert:
          alert.show(intl.formatMessage(messages.actionFail_502_503),
            {
              type: types.ERROR,
            }
          )
        });
    }

    if (statusCode?.status >= 500 && statusCode?.status !== 502 && statusCode?.status !== 503) {
      return <GeneralError />;
    }

    return children;
  };

  const contextPayload = useMemo(
    () => ({ setStatusCode }),
    [setStatusCode]
  );

  return (
    <StatusCodeContext.Provider value={contextPayload}>
      {renderContent()}
    </StatusCodeContext.Provider>
  );
};

export const useStatusCode = (): any => useContext(StatusCodeContext);
