import * as React from 'react';
import { useEffect, useState } from 'react';
import { PublistServiceApi, GetSingleEntryRequest } from '../../api/publist/apis';
import { connectOAuth2, OAuth2AwareProps } from '../../Auth/utils/connect';

import getLogger from '../../log';
const logger = getLogger('Citation');

export type FetchState = 'UNLOADED' | 'LOADING' | 'LOADED' | 'ERROR';
export type RenderFunction = (state: FetchState, data: string, error: any) => React.ReactNode;

export interface Props {
  id: number;
  style?: string;
  locale?: string;
  render: RenderFunction;
}

function Citation(props: { apiWithAuth?: any; style?: any; id?: any; locale?: any; render?: any }) {
  const [fetchState, setFetchState] = useState<FetchState>('UNLOADED');
  let _fetchState = 'UNLOADED';
  const [data, setData] = useState<string>(null);
  const [error, setError] = useState<any>(null);
  const api = props.apiWithAuth(new PublistServiceApi());

  useEffect(()=>{
    _fetchCitation();
  },[]);

  useEffect(() => {
    _fetchCitation();
  },[props.style]);

  const _fetchCitation = async (force: boolean = false): Promise<boolean> => {
    const { id, style, locale } = props;

    if (_fetchState === 'LOADING') {
      return false;
    }

    if (!force && _fetchState !== 'UNLOADED') {
      return false;
    }

    _fetchState = 'LOADING';
    setFetchState('LOADING');
    const request: GetSingleEntryRequest = { workId: id, style, locale };
    try {
      const response = await api.getSingleEntry(request);
      _fetchState = 'LOADED';
      setFetchState('LOADED');
      setData(response);
      setError(null);
      return true;
    } catch (error) {
      logger.find('_fetchCitation').error(props, error);
      _fetchState = 'ERROR';
      setFetchState('ERROR');
      setData(null);
      setError(error);
      return true;
    }
  };

  const { render } = props;

  return render(fetchState, data, error);
}

export const ConnectedCitation = connectOAuth2(Citation);
