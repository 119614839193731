/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublistConfigProjectOrganisation
 */
export interface PublistConfigProjectOrganisation {
    /**
     * 
     * @type {Array<number>}
     * @memberof PublistConfigProjectOrganisation
     */
    organisationIds?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof PublistConfigProjectOrganisation
     */
    withOrganisationHierarchy?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PublistConfigProjectOrganisation
     */
    projectIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof PublistConfigProjectOrganisation
     */
    determinationType?: PublistConfigProjectOrganisationDeterminationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublistConfigProjectOrganisation
     */
    filterMembers?: PublistConfigProjectOrganisationFilterMembersEnum;
    /**
     * 
     * @type {boolean}
     * @memberof PublistConfigProjectOrganisation
     */
    considerEmploymentPeriod?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublistConfigProjectOrganisation
     */
    restrictToRoles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PublistConfigProjectOrganisation
     */
    excludeByRoles?: Array<string>;
}

export function PublistConfigProjectOrganisationFromJSON(json: any): PublistConfigProjectOrganisation {
    return PublistConfigProjectOrganisationFromJSONTyped(json, false);
}

export function PublistConfigProjectOrganisationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublistConfigProjectOrganisation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisationIds': !exists(json, 'organisationIds') ? undefined : json['organisationIds'],
        'withOrganisationHierarchy': !exists(json, 'withOrganisationHierarchy') ? undefined : json['withOrganisationHierarchy'],
        'projectIds': !exists(json, 'projectIds') ? undefined : json['projectIds'],
        'determinationType': !exists(json, 'determinationType') ? undefined : json['determinationType'],
        'filterMembers': !exists(json, 'filterMembers') ? undefined : json['filterMembers'],
        'considerEmploymentPeriod': !exists(json, 'considerEmploymentPeriod') ? undefined : json['considerEmploymentPeriod'],
        'restrictToRoles': !exists(json, 'restrictToRoles') ? undefined : json['restrictToRoles'],
        'excludeByRoles': !exists(json, 'excludeByRoles') ? undefined : json['excludeByRoles'],
    };
}

export function PublistConfigProjectOrganisationToJSON(value?: PublistConfigProjectOrganisation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisationIds': value.organisationIds,
        'withOrganisationHierarchy': value.withOrganisationHierarchy,
        'projectIds': value.projectIds,
        'determinationType': value.determinationType,
        'filterMembers': value.filterMembers,
        'considerEmploymentPeriod': value.considerEmploymentPeriod,
        'restrictToRoles': value.restrictToRoles,
        'excludeByRoles': value.excludeByRoles,
    };
}

/**
* @export
* @enum {string}
*/
export enum PublistConfigProjectOrganisationDeterminationTypeEnum {
    MEMBERS = 'MEMBERS',
    SELF = 'SELF',
    DIRECT = 'DIRECT'
}
/**
* @export
* @enum {string}
*/
export enum PublistConfigProjectOrganisationFilterMembersEnum {
    CURRENT = 'CURRENT',
    FORMER = 'FORMER',
    ALL = 'ALL'
}


