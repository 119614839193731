/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WorkDTO,
    WorkDTOFromJSON,
    WorkDTOToJSON,
} from '../models';

export interface MergeDuplicatesRequest {
    unicumId: number;
    requestBody: Array<number>;
}

/**
 * 
 */
export class MergeServiceApi extends runtime.BaseAPI {

    /**
     */
    async mergeDuplicatesRaw(requestParameters: MergeDuplicatesRequest): Promise<runtime.ApiResponse<WorkDTO>> {
        if (requestParameters.unicumId === null || requestParameters.unicumId === undefined) {
            throw new runtime.RequiredError('unicumId','Required parameter requestParameters.unicumId was null or undefined when calling mergeDuplicates.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling mergeDuplicates.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/merge/work/{unicumId}`.replace(`{${"unicumId"}}`, encodeURIComponent(String(requestParameters.unicumId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkDTOFromJSON(jsonValue));
    }

    /**
     */
    async mergeDuplicates(requestParameters: MergeDuplicatesRequest): Promise<WorkDTO> {
        const response = await this.mergeDuplicatesRaw(requestParameters);
        return await response.value();
    }

}
