import * as React from 'react';

// Theming
import { css, Global } from '@emotion/core';
import { ThemeProvider, useThemeUI } from 'theme-ui';
import { getThemeName, getThemeNameForDomain, ThemeName } from '../utils';
/** @jsx jsx */
import { jsx } from 'theme-ui';

// Thrid-party components
import addDatePickerStyle from './react-datepicker.styled';
import addAutoSuggestStyle from './react-autosuggest.styled';
import addTagsInputStyle from './react-tagsinput.styled';

// Rub Fonts
import '../assets/fonts/rub-fonts/css/rub-font.css';
// Default Font
import 'BaseLayout/assets/fonts/titillum.css';

// OAuth2Container
import { Subscribe } from 'unstated';
import { OAuth2Container } from '../../Auth/containers/OAuth2Container';
import { globalFormStyles } from './form/styled';
import theme from 'BaseLayout/theme';
import { Header } from './base-layout/Header';
import { createThemeableComponent, footerComponents } from 'BaseLayout/themeable-components';
import { SearchContext } from 'types';
import { ErrorPagesContext } from 'Utils/ErrorPagesProvider';
import { useContext } from 'react';
import { GeneralError } from './error/general-error';

// Global Styling
const globalStyles = css`
  html {
    box-sizing: border-box;
    font-family: Titillium, Arial, Helvetica, sans-serif;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

// Global Styling UWH
const globalStylesUWH = css`
  html {
    box-sizing: border-box;
    font-family: Lato, sans-serif !important;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
`;

export interface Props {
  className?: string;
  searchTerm: string;
  topBarVisibility: boolean;
  searchContext: SearchContext;
  onSearchSubmit: (value: string) => void;
  children?: any;
}

export const ThemeContext = React.createContext('light');

export const getThemeNameFromOrigin = (): ThemeName => {
  const hostname: string = document.location.hostname;
  return getThemeNameForDomain(hostname.split('.').slice(-2).join('.'));
};

export const getThemeNameFromAuth = (container: OAuth2Container): null | ThemeName => {
  if (!container.state.session || !container.state.session.user_name) {
    return null;
  }
  const email: string = container.state.session.user_name;
  const at: number = !!email ? email.indexOf('@') : -1;
  if (at >= 0) {
    const domain: string = email.substr(at + 1);
    return getThemeNameForDomain(domain);
  }
  return null;
};

/**
 * Wrap StyledBaseLayout inside ThemeProvider
 */
export function BaseLayout(props: Props): React.JSX.Element {
  const {searchTerm, topBarVisibility, onSearchSubmit, className} = props;
  const headerProps = {searchTerm,topBarVisibility,onSearchSubmit,};
  const { hideHeaderFooter, generalError } = useContext(ErrorPagesContext);

  const getThemeNameFromDocument = () => {
    const rootElement = document.getElementById('root');
    const themeName = rootElement !== null ? rootElement.getAttribute('data-theme') : null;
    return getThemeName(themeName);
  };
  const Footer = createThemeableComponent(footerComponents);

  return (
    <Subscribe to={[OAuth2Container]}>
      {(c: OAuth2Container) => {
        const themeName =
          getThemeNameFromDocument() ||
          getThemeNameFromAuth(c) ||
          getThemeNameFromOrigin() ||
          'rub';

        const GlobalStyles = () => {
          const {theme} = useThemeUI();
          return (
          <>
            <Global styles={themeName === 'uwh' ? globalStylesUWH : globalStyles} />
            <Global styles={globalFormStyles(theme)} />
            <Global styles={[ addDatePickerStyle(theme), addAutoSuggestStyle(theme), addTagsInputStyle(theme) ]} />
          </>
        );};

        return (
          <ThemeContext.Provider value={themeName}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <div sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column'}} className={className}>
                {!hideHeaderFooter && (
                  <>
                    <Header {...headerProps} />
                    {props.children}
                    <Footer />
                  </>
                )}
                {hideHeaderFooter && generalError && (
                  <GeneralError/>
                )}
                {hideHeaderFooter && !generalError && (
                  <>
                    {props.children}
                  </>
                )}
              </div>
            </ThemeProvider>
          </ThemeContext.Provider>
        );
      }}
    </Subscribe>
  );
}
