/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelDate
 */
export interface ModelDate {
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ModelDate
     */
    date_parts?: Array<Array<string>>;
    /**
     * 
     * @type {string}
     * @memberof ModelDate
     */
    season?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDate
     */
    circa?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDate
     */
    literal?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelDate
     */
    raw?: string;
}

export function ModelDateFromJSON(json: any): ModelDate {
    return ModelDateFromJSONTyped(json, false);
}

export function ModelDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date_parts': !exists(json, 'date-parts') ? undefined : json['date-parts'],
        'season': !exists(json, 'season') ? undefined : json['season'],
        'circa': !exists(json, 'circa') ? undefined : json['circa'],
        'literal': !exists(json, 'literal') ? undefined : json['literal'],
        'raw': !exists(json, 'raw') ? undefined : json['raw'],
    };
}

export function ModelDateToJSON(value?: ModelDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date-parts': value.date_parts,
        'season': value.season,
        'circa': value.circa,
        'literal': value.literal,
        'raw': value.raw,
    };
}


