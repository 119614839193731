export type Language = 'de' | 'en';
export const languages: Language[] = ['de', 'en'];
export type LangMessages = Record<string, string>;
export type Messages = Record<Language, LangMessages>;

export const fallback = 'de-DE';

export function pickLocale(preferences: readonly string[]): string {
  // Split locale into language and location
  const prefLangs = preferences.map((p) => {
    const [lang, loc] = p.split('-', 2);
    return [lang.toLowerCase(), loc];
  });

  // Search preferred locales for a supported language
  for (const pref of prefLangs) {
    const idx = languages.findIndex((e) => e === pref[0]);
    if (idx >= 0) {
      return `${pref[0]}${!!pref[1] ? '-' + pref[1] : ''}`;
    }
  }
  return fallback;
}

export function getLanguageFromLocale(locale: string): Language {
  return locale.split('-', 2)[0] as Language;
}
