import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { AdminDataWorkDTO, WorkDTO } from 'api/core';
import { DataModelGate } from 'Auth/components';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';

const messages = defineMessages({
  deskman: { id: 'work.attr.adminDataWork.attr.deskman' }
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: AdminDataWorkDTO) => void;
  entity: string;
  className?: string;
  validation: ValidationResult;
}

export function Deskman({modifiedModel, entity, updateField, className, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate always={false} entity={entity} field="deskman">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <TextInputP
        classes={[className]}
        name={intl.formatMessage(messages.deskman)}
        value={modifiedModel.adminDataWork.deskman ? modifiedModel.adminDataWork.deskman : ''}
        inputName="deskman"
        permissions={checkPermission()}
        inputProps={{ disabled: true }}
        onChange={(data: string): void => updateField('adminDataWork', { ...modifiedModel.adminDataWork, deskman: data })}
        validation={validation}
      />
    )}
  </DataModelGate>;
}
