import * as React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import  Radio  from '../../BaseLayout/components/form/Radio';
import { ConnectedCitation, FetchState, RenderFunction } from '../container/Citation';
import { citationStyles } from '../data';
import { Button, Flex } from 'theme-ui';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { htmlToText } from 'html-to-text';
import { useState } from 'react';
import Popup from 'BaseLayout/components/popup/Popup';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface Props {
  id: number;
  onClose?: () => void;
}

export function CitationPopup (props: Props): JSX.Element {
  const { id } = props;
  const [style, setStyle] = useState<string>('harvard-cite-them-right');
  const [locale, setLocale] = useState<string>();
  const intl: IntlShape = useIntl();

  const _renderPopup = (state: FetchState, data: string, error: any): React.ReactNode => {
    const { onClose } = props;
    const _style  = style;

    const handleStyleChange = (s: string) => () => {
      setStyle(s);
    };

    const handleOnClose = () => {
      if (onClose) {
        onClose();
      }
    };

    return (
      <Popup
        open={true}
        title={intl.formatMessage({id: "popup.citation.title"})}
        onClose={() => handleOnClose()}
        buttons={[]}
        maxWidth='50em'
      >
        <div className="menu">
          <div className="style">
            <b><FormattedMessage id="popup.citation.style" />:</b>
            <span>
              {citationStyles.map(({key, value}): React.JSX.Element => (
                <Radio
                  key={key}
                  name="citation-style-radio"
                  checked={_style === key}
                  value={key}
                  onChange={handleStyleChange(key)}
                  titleText={`CSL: ${key}`}
                >
                  {value}
                </Radio>
              ))}
            </span>
          </div>
        </div>
        <div sx={{
          border: "2px solid",
          borderColor: "light",
          color: "darker",
          a: {
            color: "darker",
          },
          height: '8em',
          overflowY: 'auto',
          p: 4,
          my: '1.5em'
        }}>
          {_renderCitation(state, data, error)}
        </div>
        <Flex sx={{gap: 6, alignItems: "center", button: { p: '10px' }}}>
          <CopyToClipboard text={htmlToText(data)}>
            <Button>
              <FormattedMessage id={"copy-clipboard.label"} />
            </Button>
          </CopyToClipboard>
          <b><FormattedMessage id="popup.citation.download"/>:</b>
          <Button>
            <FormattedMessage id="popup.citation.bibtex" />
          </Button>
          <Button>
            <FormattedMessage id="popup.citation.ris" />
          </Button>
        </Flex>
      </Popup>
    );
  };

  const _renderCitation = (state: FetchState, data: string, error: any): React.ReactNode => {
    switch (state) {
      case 'LOADED':
        return <div className="citation-text" dangerouslySetInnerHTML={{ __html: data }} />;
      case 'ERROR':
        return (
          <div className="citation-error">
            <p>
              <FormattedMessage id="error.request" />
            </p>
            <small>{String(error)}</small>
          </div>
        );
      default:
        return (
          <div className="citation-loading">
            <FormattedMessage id="Loading..." />
          </div>
        );
    }
  };

  const _boundRenderFun: RenderFunction = _renderPopup;

  return <ConnectedCitation id={id} style={style} locale={locale} render={_boundRenderFun} />;

}
