import * as React from 'react';
import Moment from 'react-moment';
import { DashboardType } from '../../types';
import ProjectResult from './ProjectResult';
import WorkResult from './WorkResult';
import PersonResult from './PersonResult';
import OrgaResult from './OrgaResult';

export const renderDateTime = (dt: Date): React.ReactNode => {
  return <Moment date={dt} format="lll" fromNowDuring={24 * 60 * 60 * 1000} />;
};

export const idHashFilter = (value: string): { id?: number; name: string } => {
  const idx = value.indexOf('#');
  if (idx === -1) {
    return { name: value };
  } else {
    return {
      id: parseInt(value.slice(0, idx), 10),
      name: value.slice(idx + 1),
    };
  }
};

export interface ResultState {
  deleteInit: boolean;
  deleteDone: boolean;
  exclusiveWorks: number[];
}

const resultClassMap: { [key in DashboardType] } = {
  [DashboardType.WORKS]: WorkResult,
  [DashboardType.PEOPLE]: PersonResult,
  [DashboardType.ORGS]: OrgaResult,
  [DashboardType.PROJECTS]: ProjectResult,
};

export const Result = (dashboard: DashboardType) => {
  return resultClassMap[dashboard];
};
