import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { FormattedNumber as FN } from 'react-intl';
import { ViewType } from 'Detail/types';
import { Notation } from 'Vocabulary/components/Notation';
import { Link } from '@theme-ui/components';
import { Subscribe } from 'unstated';
import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { Authorizer } from 'Auth/utils/Authorizer';
import { SearchWork } from 'Dashboard/types';
import { getDetailContextUrl } from 'data';
import { DeleteWork } from 'Edit/components/DeleteWork';
import { CitationPopup } from 'Detail/components/CitationPopup';
import OptionList from './OptionList';
import { renderDateTime } from 'Detail/utils';
import { MathJax } from 'better-react-mathjax';
import parse from 'html-react-parser';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { WorkApi, WorkDTO } from 'api/core';
import { OAuth2AwareProps, connectOAuth2 } from 'Auth/utils/connect';
import WorkBelongToProjects from '../WorkBelongToProjects';

export interface WorkResultProps {
  index: number;
  result: SearchWork;
  updateResults: () => {};
}

type InnerProps = OAuth2AwareProps<WorkResultProps & WrappedComponentProps>;

export const ConnWorkResult = (props: InnerProps): JSX.Element => {
  return (
    <Subscribe to={[OAuth2Container]}>{(c: OAuth2Container) => {
      const innerProps = { ...props, authorizer: c.authorizer()};
      return <InnerWorkResult {...innerProps} />;
    }}
    </Subscribe>
  );
};

interface InnerWorkResultProps extends WorkResultProps {
  authorizer: Authorizer;
}

type InnerPropsAuthorizer = OAuth2AwareProps<InnerWorkResultProps & WrappedComponentProps>;

const InnerWorkResult = (props: InnerPropsAuthorizer): JSX.Element => {
  const [authorsExpanded, setAuthorsExpanded] = useState(false);
  const [citePopupOpen, setCitePopupOpen] = useState(false);
  const [deleteInit, setDeleteInit] = useState(false);

  // needed for profile page
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [work, setWork] = useState<WorkDTO>(null);
  const workApi = props.apiWithAuth(new WorkApi());
  const [loading, setLoading] = useState<boolean>(false);

  const { index, result, authorizer } = props;

  const shortAuthorsCount = 3;
  const authors = result.personName;
  const authorCount = undefined !== authors ? authors.length : 0;

  const handleExpandAuthors = (value: boolean) => (e: React.MouseEvent<HTMLSpanElement>) => {
    setAuthorsExpanded(value);
  };
  const authorClasses = ['authors'];
  if (authorCount > shortAuthorsCount) {
    authorClasses.push('expandable');
  }
  if (authorsExpanded) {
    authorClasses.push('expanded');
  }

  const options = [];
  const optionListClassNames: Set<string> = new Set();
  if (authorizer.hasAnyOfRoles(['ROLE_EDITOR', 'ROLE_ADMIN']) && window.location.pathname === '/search/works') {
    options.push(
      <li key="edit">
        <Link href={`/work/${props.result.id}/edit`}>
          <FormattedMessage id="button.Edit" />
        </Link>
      </li>
    );
    options.push(
      <li key="delete">
        <a
          href="#"
          onClick={async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setDeleteInit(true);
          }}
        >
          {authorizer.hasRole('ROLE_ADMIN') &&
          <FormattedMessage id="button.Delete" />
          }
          {authorizer.hasRole('ROLE_EDITOR') &&
          <FormattedMessage id="button.DeleteRequest" />
          }
        </a>
      </li>
    );
  }
  else if (authorizer.hasAnyOfRoles(['ROLE_USER', 'ROLE_EDITOR', 'ROLE_ADMIN']) && window.location.pathname === '/user/profile') {
    if(!result?.projectsChecked) {
      // Set invalid, if project-work-connections hasn't been checked by anybody yet
      optionListClassNames.add('invalid');
    }
    options.push(
      <li key="editProjects">
        <a
          href="#"
          className={ result?.projectsChecked ? '' : 'invalid'}
          onClick={async (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            setOpenPopup(true);
            setLoading(true);
            const responseWork = await workApi.getWork({ workId: Number(props.result.id) });
            setWork(responseWork);
            setLoading(false);
          }}
        >
          <FormattedMessage id="button.edit.projects" />
        </a>
      </li>
    )
  }

  return (
    <>
      {deleteInit &&
      <DeleteWork
        workId={parseInt(result.id, 10)}
        workTitle={result.mainTitle}
        isDeleteRequest={!authorizer.hasRole('ROLE_ADMIN')}
        onAbort={() => setDeleteInit(false)}
        onSuccess={() => {
          setDeleteInit(false);
          props.updateResults();
        }}
        onError={(e) => {
          switch (e.status) {
            case 400:
              alert("Fehler beim Löschen: Es sind weitere Werke mit dieser Entität verknüpft!");
              break;
            default:
              alert("Fehler beim Löschen!");
              break;
          }
          setDeleteInit(false);
        }}
      />
      }
      <WorkBelongToProjects
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        workApi={workApi}
        work={work}
        setWork={setWork}
        loading={loading}
        setLoading={setLoading}
      />
      <div className="result" key={result.id}
        sx={{
          ".MathJax.CtxtMenu_Attached_0": {
            display: 'contents'
          }
        }}
      >
        <div className={'citation-popup-container' + (!citePopupOpen ? ' hidden' : '')}>
          {citePopupOpen &&
          <CitationPopup id={Number(result.id)} onClose={() => setCitePopupOpen(false)} />
          }
        </div>
        <div className="content">
          <div className="info main">
            <div className="main-title">
              <Link href={getDetailContextUrl(ViewType.WORKS, result.id)}>
                {[result.mainTitle, result.subseriesTitle]
                  .filter(i => !!i)
                  .map<React.ReactNode>(title => <MathJax>{parse(title)}</MathJax>)
                  .reduce((prev, curr) => [prev, ' / ', curr])
                }
              </Link>
              {result.issued ? ' (' + result.issued + ')' : ''}
            </div>
            {result.subTitle &&
            <span className="sub-title">
              <MathJax>{parse(result.subTitle)}</MathJax>
            </span>
            }
            <div className={authorClasses.join(' ')}>
              {authorCount > 0 ? (
                <>
                  <span className="content">{authors.slice(0, shortAuthorsCount).join('; ')}</span>
                  {authorCount > shortAuthorsCount ? (
                    <span className="more">; {authors.slice(shortAuthorsCount).join('; ')}</span>
                  ) : (
                    ''
                  )}
                  <span className="more-button" onClick={handleExpandAuthors(true)}>
                    <FormattedMessage id="button.more" />
                  </span>
                  <span className="less-button" onClick={handleExpandAuthors(false)}>
                    <FormattedMessage id="button.less" />
                  </span>
                </>
              ) : (
                <i key="none">
                  <FormattedMessage id="No_information" />
                </i>
              )}
            </div>
          </div>
          <div className="info right">
            <div className="record status">
              <FormattedMessage id="entity.attr.editorialStatus" />:{' '}
              <Notation v="editorial_status_list" k={result.editorialStatus} />
            </div>
            <div className="record creation">
              <FormattedMessage id="entity.attr.recordCreationDate" />: {renderDateTime(result.recordCreationDate)}
            </div>
            <div className="record change">
              <FormattedMessage id="entity.attr.recordChangeDate" />: {renderDateTime(result.recordChangeDate)}
            </div>
          </div>
        </div>
        <div className="actions">
          <span className="index">
            #<FN value={index + 1} />
          </span>
          <button type="button" className="cite freestyle" onClick={() => setCitePopupOpen(true)} sx={{mr: '1em'}}>
            <span className="icon-zitieren_blau" sx={{mr: '0.35em', fontSize: '0.8em', ':before': {color: 'icon_color'}}}/>
            <FormattedMessage id="button.Cite" />
          </button>
          {options.length > 0 && <OptionList
            id={result.id}
            options={options}
            updateResults={props.updateResults}
            classNames={optionListClassNames}
            // alert={ !result.projectsChecked && window.location.pathname === '/user/profile'}
          />}
        </div>
      </div>
    </>
  );
};

export const WorkResult = injectIntl(connectOAuth2(ConnWorkResult));

export default WorkResult;
