import * as React from 'react';
import { Redirect } from 'react-router-dom';

interface RedirectComputedPathProps {
  computePathFunc: () => string;
}

export const RedirectComputedPath: React.FC<RedirectComputedPathProps> = (props) => {
  return <Redirect to={props.computePathFunc()} />;
};
