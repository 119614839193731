import { WorkPersonAny } from "./components/WorkPersonList";

// helper functions
export const deleteByIdx = (list: WorkPersonAny[], pos) => {
  list.splice(pos, 1);
  if (list.length > pos){
    for (const wp of list) {
      if (wp.person.sequence >= list[pos].person.sequence) {
        wp.person.sequence--;
      }
    }
  }
};

export const moveUp = (list: WorkPersonAny[], pos) => {
  const seq = list[pos].person.sequence;

  for (const w of list) {
    if (w.person.sequence === seq - 1) {
      w.person.sequence++;
    }
  }
  list[pos].person.sequence--;
};

export const moveDown = (list: WorkPersonAny[], pos) => {
  const seq = list[pos].person.sequence;

  for (const w of list) {
    if (w.person.sequence === seq + 1) {
      w.person.sequence--;
    }
  }
  list[pos].person.sequence++;
};
