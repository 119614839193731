/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PagePublistMetadata,
    PagePublistMetadataFromJSON,
    PagePublistMetadataToJSON,
} from '../models';

export interface GetKeysbyOrganisationIdRequest {
    orgaId: string;
    page?: number;
    size?: number;
    nameSortDir?: GetKeysbyOrganisationIdNameSortDirEnum;
}

export interface GetKeysbyPersonIdRequest {
    personId: string;
    page?: number;
    size?: number;
    nameSortDir?: GetKeysbyPersonIdNameSortDirEnum;
}

export interface GetKeysbyProjectIdRequest {
    projectId: string;
    page?: number;
    size?: number;
    nameSortDir?: GetKeysbyProjectIdNameSortDirEnum;
}

export interface GetKeysbyUserIdRequest {
    userId: number;
    page?: number;
    size?: number;
    nameSortDir?: GetKeysbyUserIdNameSortDirEnum;
}

export interface GetKeysbyWorkIdRequest {
    workId: number;
    page?: number;
    size?: number;
    nameSortDir?: GetKeysbyWorkIdNameSortDirEnum;
}

/**
 * 
 */
export class KeyServiceApi extends runtime.BaseAPI {

    /**
     */
    async getKeysbyOrganisationIdRaw(requestParameters: GetKeysbyOrganisationIdRequest): Promise<runtime.ApiResponse<PagePublistMetadata>> {
        if (requestParameters.orgaId === null || requestParameters.orgaId === undefined) {
            throw new runtime.RequiredError('orgaId','Required parameter requestParameters.orgaId was null or undefined when calling getKeysbyOrganisationId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.nameSortDir !== undefined) {
            queryParameters['nameSortDir'] = requestParameters.nameSortDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/key/organisation/{orgaId}`.replace(`{${"orgaId"}}`, encodeURIComponent(String(requestParameters.orgaId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePublistMetadataFromJSON(jsonValue));
    }

    /**
     */
    async getKeysbyOrganisationId(requestParameters: GetKeysbyOrganisationIdRequest): Promise<PagePublistMetadata> {
        const response = await this.getKeysbyOrganisationIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getKeysbyPersonIdRaw(requestParameters: GetKeysbyPersonIdRequest): Promise<runtime.ApiResponse<PagePublistMetadata>> {
        if (requestParameters.personId === null || requestParameters.personId === undefined) {
            throw new runtime.RequiredError('personId','Required parameter requestParameters.personId was null or undefined when calling getKeysbyPersonId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.nameSortDir !== undefined) {
            queryParameters['nameSortDir'] = requestParameters.nameSortDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/key/person/{personId}`.replace(`{${"personId"}}`, encodeURIComponent(String(requestParameters.personId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePublistMetadataFromJSON(jsonValue));
    }

    /**
     */
    async getKeysbyPersonId(requestParameters: GetKeysbyPersonIdRequest): Promise<PagePublistMetadata> {
        const response = await this.getKeysbyPersonIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getKeysbyProjectIdRaw(requestParameters: GetKeysbyProjectIdRequest): Promise<runtime.ApiResponse<PagePublistMetadata>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getKeysbyProjectId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.nameSortDir !== undefined) {
            queryParameters['nameSortDir'] = requestParameters.nameSortDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/key/project/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePublistMetadataFromJSON(jsonValue));
    }

    /**
     */
    async getKeysbyProjectId(requestParameters: GetKeysbyProjectIdRequest): Promise<PagePublistMetadata> {
        const response = await this.getKeysbyProjectIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getKeysbyUserIdRaw(requestParameters: GetKeysbyUserIdRequest): Promise<runtime.ApiResponse<PagePublistMetadata>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getKeysbyUserId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.nameSortDir !== undefined) {
            queryParameters['nameSortDir'] = requestParameters.nameSortDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/key/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePublistMetadataFromJSON(jsonValue));
    }

    /**
     */
    async getKeysbyUserId(requestParameters: GetKeysbyUserIdRequest): Promise<PagePublistMetadata> {
        const response = await this.getKeysbyUserIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getKeysbyWorkIdRaw(requestParameters: GetKeysbyWorkIdRequest): Promise<runtime.ApiResponse<PagePublistMetadata>> {
        if (requestParameters.workId === null || requestParameters.workId === undefined) {
            throw new runtime.RequiredError('workId','Required parameter requestParameters.workId was null or undefined when calling getKeysbyWorkId.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.nameSortDir !== undefined) {
            queryParameters['nameSortDir'] = requestParameters.nameSortDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            if (typeof this.configuration.accessToken === 'function') {
                headerParameters["Authorization"] = this.configuration.accessToken("OAuth2", []);
            } else {
                headerParameters["Authorization"] = this.configuration.accessToken;
            }
        }

        const response = await this.request({
            path: `/key/work/{workId}`.replace(`{${"workId"}}`, encodeURIComponent(String(requestParameters.workId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePublistMetadataFromJSON(jsonValue));
    }

    /**
     */
    async getKeysbyWorkId(requestParameters: GetKeysbyWorkIdRequest): Promise<PagePublistMetadata> {
        const response = await this.getKeysbyWorkIdRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetKeysbyOrganisationIdNameSortDirEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetKeysbyPersonIdNameSortDirEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetKeysbyProjectIdNameSortDirEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetKeysbyUserIdNameSortDirEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum GetKeysbyWorkIdNameSortDirEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
