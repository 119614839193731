import * as React from 'react';
import { Subscribe } from 'unstated';
import { OAuth2Container } from '../containers/OAuth2Container';
import { Authorizer } from '../utils/Authorizer';

export interface RenderParams {
  /** The URL that starts the authentication process */
  authUrl: string;
  /** An authorizer helper instance */
  authorizer: Authorizer;
}

export interface Props {
  render: (params: RenderParams) => JSX.Element;
}

/**
 * Create a login URL that starts the authentication process with the auth service.
 *
 * The renderer is specified in the `render` prop and receives the `authUrl` and an `authorizer`.
 */
export function OAuth2Sender(props: Props): React.JSX.Element {
  return (
    <Subscribe to={[OAuth2Container]}>
      {(container: OAuth2Container) => {
        const authorizer = container.authorizer();
        const renderParams = {
          authUrl: container.getAuthUrl(),
          authorizer,
        };
        return props.render(renderParams);
      }}
    </Subscribe>
  );
}
