import * as React from 'react';
import { injectIntl } from 'react-intl';
import { getLanguageFromLocale } from '../../Intl/utils';
import { getNotation } from '../util';

import getLogger, {Logger} from '../../log';

const logger: Logger = getLogger('Vocabulary/Notation');

export interface Props {
  v: string; // vocabulary
  l?: string; // language
  p?: string; // partition
  k: string; // key
  r?: boolean; // required
  children?: (notation: string) => React.ReactNode; // optional render function
}

function InnerNotation(props: { l?: any; p?: any; r?: any; children?: any; v: any; k: any; intl: any }) {
  const l = props.l ? props.l : null;
  const p = props.p ? props.p : '*';
  const r = props.r ? props.r : false;
  const children = props.children ? props.children : null;
  const v = props.v;
  const k = props.k;
  const intl = props.intl;
  const lang = !!l ? l : getLanguageFromLocale(intl.locale);

  try {
    const notation = getNotation(v, k, lang, p);
    if (typeof children !== 'function') {
      return notation;
    }

    return children(notation);
  } catch (e) {
    if (r) {
      throw e;
    }
    logger.warn('Lookup failed', props);
    return <i>{k}</i>;
  }
}

export const Notation = injectIntl(InnerNotation);
