import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { WorkDTO } from '../../../../api/core';
import { SelectInputP } from '../../../../BaseLayout/components/form/generic/Select';
import { DataModelGate } from '../../../../Auth/components';
import { ValidationResult } from '../../../validation/interfaces';
import { CheckPermissionFunction, WritableParam } from '../../../../Auth/components/DataModelGate';


const messages = defineMessages({
  applicationCountry: { id: 'work.attr.applicationCountry' },
});

interface Props {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: string) => void;
  entity: string;
  pubtypeGroup: string;
  className?: string;
  validation: ValidationResult;
}

export function ApplicationCountry({modifiedModel, pubtypeGroup, entity, updateField, className, validation}: Props): ReactElement {
  const intl: IntlShape = useIntl();
  return <DataModelGate entity={entity} field="application_country">
    {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
      <SelectInputP
        classes={[className]}
        listPartition={pubtypeGroup}
        inputName="applicationCountry"
        permissions={checkPermission()}
        validation={validation}
        name={intl.formatMessage(messages.applicationCountry)}
        list={'country_list'}
        value={modifiedModel.applicationCountry ? modifiedModel.applicationCountry : ''}
        onChange={(pc: string): void => updateField('applicationCountry', pc)}
      />
    )}
  </DataModelGate>;
}
