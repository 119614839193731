import * as React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { WorkApi } from '../../api/core/apis';
import { PatchDocumentFromJSON } from '../../api/core/models';
import { connectOAuth2, OAuth2AwareProps } from 'Auth/utils/connect';
import Popup from 'BaseLayout/components/popup/Popup';
import { useStatusCode } from 'Utils/StatusCodeHandler';
import { handleStatusCode422 } from '../../Utils/StatusCodeUtils';
import { ApiResponse } from '../../api/core';
import { MathJax } from 'better-react-mathjax';
import parse from 'html-react-parser';

/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface DeleteWorkProps {
  workId: number;
  workTitle: string;
  isDeleteRequest?: boolean;
  onAbort?: () => void;
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

type InnerProps = OAuth2AwareProps<DeleteWorkProps>;

const InnerDeleteWork = (props: InnerProps): JSX.Element => {
  const intl: IntlShape = useIntl();
  const workApi: WorkApi = props.apiWithAuth(new WorkApi());
  const { setStatusCode } = useStatusCode();
  const title: string = props.isDeleteRequest
    ? intl.formatMessage({ id: 'work.deleteRequest.popup.title' })
    : intl.formatMessage({ id: 'work.delete.popup.title' });

  const submitDelete = async (): Promise<void> => {
    if (props.isDeleteRequest) {
      try {
        await workApi.requestDelete({
          workId: props.workId,
          patchDocument: [
            PatchDocumentFromJSON({
              op: 'replace',
              path: '/adminDataWork/editorialStatus',
              value: 'deleted',
            }),
          ]
        });
        props.onSuccess();
      } catch(e) {
        props.onError(e);
      }
      return;
    }
    try {
      const response: ApiResponse<void> = await workApi.deleteWorkRaw({ workId: props.workId });
      setStatusCode({
        status: response.raw.status,
        action: 'delete',
        data: { entity: 'works', name: props.workTitle, id: props.workId },
      });
      props.onSuccess();
    } catch (error) {
      if (error.status === 422) return handleStatusCode422(error, setStatusCode);
      setStatusCode({status: error.status});
    }
  };

  return (
    <Popup
      open={true}
      title={title}
      onClose={props.onAbort}
      buttons={[
        {
          label: intl.formatMessage({ id: 'popup.button.confirm' }),
          onClick: submitDelete,
        },
        {
          label: intl.formatMessage({ id: 'popup.button.abort' }),
          onClick: props.onAbort,
        },
      ]}
      maxWidth='40em'
    >
      <div sx={{".MathJax.CtxtMenu_Attached_0": { display: 'contents' }}}>
        {props.isDeleteRequest && (
          <MathJax>{parse(intl.formatMessage({ id: 'work.deleteRequest.popup.message(workTitle)' }, { workTitle: props.workTitle }))}</MathJax>
        )}
        {!props.isDeleteRequest && (
          <MathJax>{parse(intl.formatMessage({ id: 'work.delete.popup.message(workTitle)' }, { workTitle: props.workTitle }))}</MathJax>
        )}
      </div>
    </Popup>
  );
};

export const DeleteWork = connectOAuth2(InnerDeleteWork);
