/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkProjectOtherDTO
 */
export interface WorkProjectOtherDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkProjectOtherDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WorkProjectOtherDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkProjectOtherDTO
     */
    role?: string;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof WorkProjectOtherDTO
     */
    externalIds?: Array<ExternalIdDTO>;
}

export function WorkProjectOtherDTOFromJSON(json: any): WorkProjectOtherDTO {
    return WorkProjectOtherDTOFromJSONTyped(json, false);
}

export function WorkProjectOtherDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkProjectOtherDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
    };
}

export function WorkProjectOtherDTOToJSON(value?: WorkProjectOtherDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'role': value.role,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
    };
}


