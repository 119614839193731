/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Hierarchy
 */
export interface Hierarchy {
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    entityId?: number;
    /**
     * 
     * @type {string}
     * @memberof Hierarchy
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    stage?: number;
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    requestId?: number;
}

export function HierarchyFromJSON(json: any): Hierarchy {
    return HierarchyFromJSONTyped(json, false);
}

export function HierarchyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hierarchy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'stage': !exists(json, 'stage') ? undefined : json['stage'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
    };
}

export function HierarchyToJSON(value?: Hierarchy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityId': value.entityId,
        'name': value.name,
        'stage': value.stage,
        'requestId': value.requestId,
    };
}


