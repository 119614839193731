import * as React from 'react';
import { injectIntl } from 'react-intl';
import { getLanguageFromLocale } from '../../Intl/utils';
import { getVocabulary } from '../util';

import getLogger from '../../log';
const logger = getLogger('Vocabulary/Vocabulary');

export interface Props {
  v: string; // vocabulary
  l?: string; // language
  p?: string; // partition
  sort?: boolean; // Sort alphabetically
  children?: (notations: Map<string, string>) => React.ReactNode; // optional render function
}

function InnerVocabulary(props: { l?: any; p?: any; sort?: any; intl: any; v: any; children?: any }) {
  const l = props.l ? props.l : null;
  const p = props.p ? props.p : '*';
  const sort = props.sort ? props.sort : false;
  const intl = props.intl;
  const v = props.v;
  const children = props.children;
  const lang = !!l ? l : getLanguageFromLocale(intl.locale);

  try {
    const notations: Map<string, string> = getVocabulary(
      !!v ? v.toLowerCase() : v,
      !!lang ? lang.toLowerCase() : lang,
      !!p ? p.toLowerCase() : p
    );
    if (sort) {
      return children(
        new Map<string, string>(
          [...notations].sort(([k1, v1], [k2, v2]) => {
            if (v1.toLowerCase() > v2.toLowerCase()) return 1;
            if (v2.toLowerCase() > v1.toLowerCase()) return -1;
            return 0;
          })
        )
      );
    }
    return children(notations);
  } catch (e) {
    logger.warn('Lookup failed', e);
    throw e;
  }
}

export const Vocabulary = injectIntl(InnerVocabulary);
