/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
    ReferencedPersonNameDTO,
    ReferencedPersonNameDTOFromJSON,
    ReferencedPersonNameDTOFromJSONTyped,
    ReferencedPersonNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReferencedPersonDTO
 */
export interface ReferencedPersonDTO {
    /**
     * 
     * @type {number}
     * @memberof ReferencedPersonDTO
     */
    id?: number | null;
    /**
     * 
     * @type {Array<ReferencedPersonNameDTO>}
     * @memberof ReferencedPersonDTO
     */
    personNames?: Array<ReferencedPersonNameDTO>;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof ReferencedPersonDTO
     */
    externalIds?: Array<ExternalIdDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferencedPersonDTO
     */
    topOrgas?: Array<string>;
}

export function ReferencedPersonDTOFromJSON(json: any): ReferencedPersonDTO {
    return ReferencedPersonDTOFromJSONTyped(json, false);
}

export function ReferencedPersonDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferencedPersonDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'personNames': !exists(json, 'personNames') ? undefined : ((json['personNames'] as Array<any>).map(ReferencedPersonNameDTOFromJSON)),
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
        'topOrgas': !exists(json, 'topOrgas') ? undefined : json['topOrgas'],
    };
}

export function ReferencedPersonDTOToJSON(value?: ReferencedPersonDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'personNames': value.personNames === undefined ? undefined : ((value.personNames as Array<any>).map(ReferencedPersonNameDTOToJSON)),
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
        'topOrgas': value.topOrgas,
    };
}


