import * as React from 'react';
import { PersonApi } from 'api/core/apis/';
import { OAuth2AwareProps, connectOAuth2 } from 'Auth/utils/connect';
import { PersonNameDTO, ReferencedPersonNameDTO } from 'api/core/models';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidationResult } from '../../validation/interfaces';
import { Select } from 'theme-ui';
import { InputLabel } from '../../../BaseLayout/components/form/generic/InputLabel';

export interface State {
  names: ReferencedPersonNameDTO[];
}
export interface Props {
  onChange?: (values: PersonNameDTO) => void;
  value: number;
  personId: number;
  disabled?: boolean;
  validation?: ValidationResult;
  required?: boolean;
  name?: string;
  help?: any;
}

function InnerPersonNameSuggest(props: OAuth2AwareProps<Props>) {
  const [names, setNames] = useState<ReferencedPersonNameDTO[]>([]);
  const { onChange, value, disabled } = props;
  const required: boolean = props.required ?? false;
  const validation: ValidationResult = props.validation ?? { valid: true };
  if(required && !value) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }

  if (!names || props.personId !== names[0]?.personId) {
    const api: PersonApi = props.apiWithAuth(new PersonApi());
    void api.getPersonNames({ personId: props.personId }).then((rpns: ReferencedPersonNameDTO[]) => {
      setNames(rpns);
    });
    return <span><FormattedMessage id='error.failed'/></span>;
  }
  // Wenn kein Name ausgewählt, setze auf Defautname aus der geladenen Liste (isMain)
  if (!value) {
    onChange(names.find((name: PersonNameDTO): boolean => name.isMain));
  }
  const classes: string[] = [];
  if (required){
    classes.push("required");
  }
  if(validation && !validation.valid) {
    classes.push('invalid');
  }
  return (
    <InputLabel
      label={props.name}
      required={required}
      variant="labelY"
      invalid={validation && !validation.valid}
      help={props.help}>
      <Select
        className={classes.join(" ")}
        value={value ? value : ''}
        onChange={(e: React.FormEvent<HTMLSelectElement>) =>
          onChange(names.find((name: PersonNameDTO) => parseInt(e.currentTarget.value, 10) === name.id))
        }
        disabled={disabled !== undefined && disabled !== null ? disabled : false}
      >
        {names.map((name: PersonNameDTO, idx: number) => {
          return (
            <option key={idx} value={name.id}>
              {name.givenname}
              {name.surname ? ' ' + name.surname : ''}
            </option>
          );
        })}
      </Select>
    </InputLabel>
  );
}

export const PersonNameSuggest = connectOAuth2(InnerPersonNameSuggest);

export default PersonNameSuggest;
