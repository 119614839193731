/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectFunderDTO,
    ProjectFunderDTOFromJSON,
    ProjectFunderDTOFromJSONTyped,
    ProjectFunderDTOToJSON,
    ProjectFundingReferenceFundingStreamDTO,
    ProjectFundingReferenceFundingStreamDTOFromJSON,
    ProjectFundingReferenceFundingStreamDTOFromJSONTyped,
    ProjectFundingReferenceFundingStreamDTOToJSON,
    ProjectFundingReferenceFundingSubstreamDTO,
    ProjectFundingReferenceFundingSubstreamDTOFromJSON,
    ProjectFundingReferenceFundingSubstreamDTOFromJSONTyped,
    ProjectFundingReferenceFundingSubstreamDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectFundingReferenceDTO
 */
export interface ProjectFundingReferenceDTO {
    /**
     * 
     * @type {number}
     * @memberof ProjectFundingReferenceDTO
     */
    id?: number | null;
    /**
     * 
     * @type {ProjectFunderDTO}
     * @memberof ProjectFundingReferenceDTO
     */
    projectFunder?: ProjectFunderDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectFundingReferenceDTO
     */
    funderName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFundingReferenceDTO
     */
    projectAcronym?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFundingReferenceDTO
     */
    externalProjectIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFundingReferenceDTO
     */
    projectTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectFundingReferenceDTO
     */
    projectUri?: string;
    /**
     * 
     * @type {Array<ProjectFundingReferenceFundingStreamDTO>}
     * @memberof ProjectFundingReferenceDTO
     */
    projectFundingReferenceFundingStreams?: Array<ProjectFundingReferenceFundingStreamDTO>;
    /**
     * 
     * @type {Array<ProjectFundingReferenceFundingSubstreamDTO>}
     * @memberof ProjectFundingReferenceDTO
     */
    projectFundingReferenceFundingSubstreams?: Array<ProjectFundingReferenceFundingSubstreamDTO>;
}

export function ProjectFundingReferenceDTOFromJSON(json: any): ProjectFundingReferenceDTO {
    return ProjectFundingReferenceDTOFromJSONTyped(json, false);
}

export function ProjectFundingReferenceDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectFundingReferenceDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'projectFunder': !exists(json, 'projectFunder') ? undefined : ProjectFunderDTOFromJSON(json['projectFunder']),
        'funderName': !exists(json, 'funderName') ? undefined : json['funderName'],
        'projectAcronym': !exists(json, 'projectAcronym') ? undefined : json['projectAcronym'],
        'externalProjectIdentifier': !exists(json, 'externalProjectIdentifier') ? undefined : json['externalProjectIdentifier'],
        'projectTitle': !exists(json, 'projectTitle') ? undefined : json['projectTitle'],
        'projectUri': !exists(json, 'projectUri') ? undefined : json['projectUri'],
        'projectFundingReferenceFundingStreams': !exists(json, 'projectFundingReferenceFundingStreams') ? undefined : ((json['projectFundingReferenceFundingStreams'] as Array<any>).map(ProjectFundingReferenceFundingStreamDTOFromJSON)),
        'projectFundingReferenceFundingSubstreams': !exists(json, 'projectFundingReferenceFundingSubstreams') ? undefined : ((json['projectFundingReferenceFundingSubstreams'] as Array<any>).map(ProjectFundingReferenceFundingSubstreamDTOFromJSON)),
    };
}

export function ProjectFundingReferenceDTOToJSON(value?: ProjectFundingReferenceDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectFunder': ProjectFunderDTOToJSON(value.projectFunder),
        'funderName': value.funderName,
        'projectAcronym': value.projectAcronym,
        'externalProjectIdentifier': value.externalProjectIdentifier,
        'projectTitle': value.projectTitle,
        'projectUri': value.projectUri,
        'projectFundingReferenceFundingStreams': value.projectFundingReferenceFundingStreams === undefined ? undefined : ((value.projectFundingReferenceFundingStreams as Array<any>).map(ProjectFundingReferenceFundingStreamDTOToJSON)),
        'projectFundingReferenceFundingSubstreams': value.projectFundingReferenceFundingSubstreams === undefined ? undefined : ((value.projectFundingReferenceFundingSubstreams as Array<any>).map(ProjectFundingReferenceFundingSubstreamDTOToJSON)),
    };
}


