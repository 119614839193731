import { DetailViewProps, ViewType } from './types';
import { DetailViewProjects } from './pages/Project';
import { DetailViewPeople } from './pages/Person';
import { DetailViewWorks } from './pages/Work';
import { DetailViewOrgs } from './pages/Organisation';
import { ComponentClass, FunctionComponent } from 'react';

export interface Icon {
  name: string;
  icon: string;
}

export interface IconList {
  [key: string]: Icon;
}

export interface ExternalId extends Icon {
  link: string;
  disabled?: boolean;
}

export interface ExternalIdList {
  [key: string]: ExternalId;
}

export const externalIdsWork: ExternalIdList = {
  doi: {
    name: 'DOI',
    icon: require('../BaseLayout/assets/img/doi.svg'),
    link: 'https://doi.org/:id',
  },
  wos: {
    name: 'Web of Science',
    icon: require('../BaseLayout/assets/img/wos.png'),
    link: 'https://www.webofscience.com/wos/woscc/full-record/WOS::id',
  },
  pmid: {
    name: 'PubMed',
    icon: require('../BaseLayout/assets/img/pubmed.png'),
    link: 'https://www.ncbi.nlm.nih.gov/pubmed/:id',
  },
  scopus: {
    name: 'Scopus',
    icon: require('../BaseLayout/assets/img/scopus.svg'),
    link: 'https://www.scopus.com/record/display.uri?eid=:id&origin=inward',
  },
  isbn: {
    name: 'ISBN',
    icon: null,
    link: 'https://de.wikipedia.org/wiki/Spezial:ISBN-Suche?isbn=:id',
  },
  issn: {
    name: 'ISSN',
    icon: null,
    link: null,
  },
  ismn: {
    name: 'ISMN',
    icon: null,
    link: null,
  },
  zdb: {
    name: 'ZDB',
    icon: null,
    link: 'https://ld.zdb-services.de/resource/:id',
  },
  hbz: {
    name: 'HBZ',
    icon: null,
    link: null,
  },
  urn: {
    name: 'URN',
    icon: null,
    link: 'https://nbn-resolving.org/:id',
  },
  handle: {
    name: 'HANDLE',
    icon: null,
    link: 'http://hdl.handle.net/:id',
  },
};

export const externalIdsPerson: ExternalIdList = {
  gnd: {
    name: 'GND',
    icon: require('../BaseLayout/assets/img/gnd.png'),
    link: 'https://d-nb.info/gnd/:id',
  },
  orcid: {
    name: 'ORCID',
    icon: require('../BaseLayout/assets/img/orcid.svg'),
    link: 'https://orcid.org/:id',
  },
  scopus: {
    name: 'Scopus',
    icon: require('../BaseLayout/assets/img/scopus.svg'),
    link: 'https://www.scopus.com/authid/detail.uri?authorId=:id&origin=recordpage'
  },
  researcher: {
    name: 'Web of Science ID',
    icon: require('../BaseLayout/assets/img/wos.png'),
    link: null,
  },
  arxiv: {
    name: 'Arxiv',
    icon: require('../BaseLayout/assets/img/arxiv.svg'),
    link: null,
  },
};


export const externalIdsProject: ExternalIdList = {
  gnd: {
    name: 'GND',
    icon: require('../BaseLayout/assets/img/gnd.png'),
    link: 'https://d-nb.info/gnd/:id',
  },
  isni: {
    name: 'ISNI ID',
    icon: require('../BaseLayout/assets/img/isni.gif'),
    link: 'http://isni.org/isni/:alnumid', // HTTPS not available due to self-signed cert
  },
  grid: {
    name: 'GRID ID',
    icon: require('../BaseLayout/assets/img/grid.png'),
    link: 'https://www.grid.ac/institutes/grid.:id',
    disabled: true,
  },
  ror: {
    name: 'ROR',
    icon: null,
    link: 'https://ror.org/:alnumid',
  },
  ringgold: {
    name: 'Ringgold ID',
    icon: require('../BaseLayout/assets/img/ringgold.png'),
    link: 'https://ido.ringgold.com/search/results?simple=:id',
  },
};

export const externalIdsOrganisation: ExternalIdList = {
  gnd: {
    name: 'GND',
    icon: require('../BaseLayout/assets/img/gnd.png'),
    link: 'https://d-nb.info/gnd/:id',
  },
  isni: {
    name: 'ISNI ID',
    icon: require('../BaseLayout/assets/img/isni.gif'),
    link: 'http://isni.org/isni/:alnumid', // HTTPS not available due to self-signed cert
  },
  grid: {
    name: 'GRID ID',
    icon: require('../BaseLayout/assets/img/grid.png'),
    link: 'https://www.grid.ac/institutes/grid.:id',
    disabled: true,
  },
  ror: {
    name: 'ROR',
    icon: null,
    link: 'https://ror.org/:alnumid',
  },
  ringgold: {
    name: 'Ringgold ID',
    icon: require('../BaseLayout/assets/img/ringgold.png'),
    link: 'https://ido.ringgold.com/search/results?simple=:id',
  }
};

export const externalIdsProjectFunder: ExternalIdList = {
  crossref: {
    name: 'Crossref',
    icon: null,
    link: null
  },
  isni: {
    name: 'ISNI ID',
    icon: require('../BaseLayout/assets/img/isni.gif'),
    link: 'http://isni.org/isni/:alnumid', // HTTPS not available due to self-signed cert
  },
  grid: {
    name: 'GRID ID',
    icon: require('../BaseLayout/assets/img/grid.png'),
    link: 'https://www.grid.ac/institutes/grid.:id',
  },
  ror: {
    name: 'ROR',
    icon: null,
    link: 'https://ror.org/:alnumid',
  },
};

export const institutionalIdIcons: ExternalIdList = {
  rub: {
    name: 'RUB ID',
    icon: require('../BaseLayout/assets/img/rub.gif'),
    link: null,
  },
  tudo: {
    name: 'TuDo ID',
    icon: require('../BaseLayout/assets/img/tudo.svg'),
    link: null,
  },
};

export const affiliationIcons: IconList = {
  rub_icon: {
    name: 'RUB Icon',
    icon: require('../BaseLayout/assets/img/rub_icon.jpg')
  },
  tudo_icon: {
    name: 'TUDO Icon',
    icon: require('../BaseLayout/assets/img/tudo_icon.jpg')
  },
  hhu_icon: {
    name: 'HHU Icon',
    icon: require('../BaseLayout/assets/img/hhu_icon.jpeg')
  },
  uwh_icon: {
    name: 'UWH Icon',
    icon: require('../BaseLayout/assets/img/logos/logo_uwh.svg')
  }
};

export const citationStyles: { key: string, value: string}[] = [
  {
    key: 'american-medical-association',
    value: 'AMA'
  },
  { key: 'apa',
    value: 'APA'
  },
  {
    key: 'chicago-fullnote-bibliography',
    value: 'Chicago'
  },
  {
    key: 'frontiers',
    value: 'Frontiers'
  },
  {
    key: 'harvard-cite-them-right',
    value: 'Harvard'
  },
  {
    key: 'ieee',
    value: 'IEEE'
  },
  {
    key: 'springer-lecture-notes-in-computer-science',
    value: 'LNCS'
  },
  {
    key: 'modern-language-association',
    value: 'MLA'
  }
];

const detailViewClassMap: {
  [key in ViewType]: typeof DetailViewPeople | typeof DetailViewWorks | typeof DetailViewProjects | typeof DetailViewOrgs;
} = {
  [ViewType.WORKS]: DetailViewWorks,
  [ViewType.PEOPLE]: DetailViewPeople,
  [ViewType.ORGS]: DetailViewOrgs,
  [ViewType.PROJECTS]: DetailViewProjects,
};

export const DetailView = (type: ViewType | string): string | FunctionComponent<DetailViewProps> | ComponentClass<DetailViewProps, any> => {
  return detailViewClassMap[type];
};
