import React, { ReactElement } from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { ReferencedOrganisationDTO, WorkOrganisationDTO, WorkOrganisationOtherDTO } from 'api/core/models';
import { OrganisationSuggest } from '../../auto-complete/OrganisationSuggest';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultWorkOrganisation, DefaultWorkOrganisationOther } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from 'BaseLayout/components/form/MultiInput/Buttons';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  organisations: { id: 'work.attr.workOrganisations' },
  others: { id: 'work.attr.workOrganisationOthers' },
  organisation: { id: 'work.attr.workOrganisation.attr.organisation' },
  organisationHelp: { id: 'work.attr.workOrganisation.attr.organisation.help' },
  name: { id: 'work.attr.workOrganisation.attr.othername' },
  nameHelp: { id: 'work.attr.workOrganisation.attr.othername.help' },
  role: { id: 'work.attr.workOrganisation.attr.role' },
});

interface WorkOrganisationProps {
  checkPermission: CheckPermissionFunction;
  checkPermissionOthers: CheckPermissionFunction;
  workOrganisations: WorkOrganisationDTO[];
  workOrganisationOthers: WorkOrganisationOtherDTO[];
  listPartition: string;
  onChangeWo: (data: WorkOrganisationDTO[]) => void;
  onChangeWoo: (data: WorkOrganisationOtherDTO[]) => void;
  validations: {work_organisations: ValidationResult; work_organisation_others: ValidationResult};
}

export function WorkOrganisations({
  workOrganisations,
  workOrganisationOthers,
  listPartition,
  onChangeWo,
  onChangeWoo,
  checkPermission,
  checkPermissionOthers,
  validations
}: WorkOrganisationProps): ReactElement {


  const intl: IntlShape = useIntl();


  return (
    <div className="col">
      <Styled.h2>{intl.formatMessage(messages.organisations)}</Styled.h2>
      <Border>
        <MultiInputControls<any>
          lines={workOrganisations ? workOrganisations : []}
          onChange={(wo: WorkOrganisationDTO[]): void => {
            onChangeWo(wo);
          }}
          add
          remove
          defaultRow={DefaultWorkOrganisation}
          renderLines={(
            organisation: WorkOrganisationDTO,
            onChangeWo2: (data: WorkOrganisationOtherDTO) => void,
            actions: {[p: string]: (e: any) => void},
            idx: number
          ): ReactElement => {
            return (
              <div className="fieldRow" key={organisation.id}>
                {checkPermission('organisation').read && (
                  <OrganisationSuggest
                    validation={validations.work_organisations.children[idx].organisation}
                    required={isRequired(checkPermission('organisation'))}
                    name={intl.formatMessage(messages.organisation)}
                    help={intl.formatMessage(messages.organisationHelp)}
                    single={true}
                    canDelete={false}
                    tags={organisation.organisation ? [organisation.organisation] : []}
                    onChange={(referencedOrgs: ReferencedOrganisationDTO[]): void => {
                      organisation.organisation = {
                        id: referencedOrgs[0].id,
                        name: referencedOrgs[0].name,
                      };
                      return onChangeWo2(organisation);
                    }}
                    disabled={!checkPermission('organisation').write}
                    autoFocus={true}
                  />
                )}
                <SelectInputP
                  validation={validations.work_organisations.children[idx].role}
                  listPartition={listPartition}
                  onChange={(role: string): void => {
                    onChangeWo2({ ...organisation, role });
                  }}
                  value={organisation.role}
                  list={'corporation_role_list'}
                  name={intl.formatMessage(messages.role)}
                  inputName="role"
                  permissions={checkPermission('role')}
                />
                {!!actions.delete && (
                  <div className="deleteButton">
                    <DeleteButton onClick={actions.delete}/>
                  </div>
                )}
              </div>
            );
          }}
          renderEnd={(actionAdd: (e: any) => void|undefined): ReactElement => (
            <div className="addButton">
              <AddButton onClick={(e: React.MouseEvent<HTMLButtonElement>): void => {
                actionAdd(e);
                onChangeWo(workOrganisations ? [...workOrganisations] : []);
              }} />
            </div>
          )}
        />
      </Border>
      <div sx={{mt: '1em'}}>
        <Styled.h2> {intl.formatMessage(messages.others)} </Styled.h2>
        <Border>
          <MultiInputControls<WorkOrganisationOtherDTO>
            lines={workOrganisationOthers ? workOrganisationOthers : []}
            onChange={onChangeWoo}
            add
            remove
            defaultRow={DefaultWorkOrganisationOther}
            renderLines={(
              workOrganisationOther: WorkOrganisationOtherDTO,
              onChangeWoo2: (data: WorkOrganisationOtherDTO) => void,
              actions: { [p: string]: (e: any) => void },
              idx: number
            ): ReactElement => (
              <div className="fieldRow" key={idx}>
                <TextInputP
                  validation={validations?.work_organisation_others?.children[idx]?.name}
                  name={intl.formatMessage(messages.name)}
                  help={intl.formatMessage(messages.nameHelp)}
                  inputName="workOrganisationOtherName"
                  value={workOrganisationOther.name}
                  onChange={(name: string): void => onChangeWoo2({ ...workOrganisationOther, name })}
                  permissions={checkPermissionOthers('name')}
                  autoFocus={true}
                />
                <SelectInputP
                  listPartition={listPartition}
                  validation={validations?.work_organisation_others?.children[idx]?.role}
                  onChange={(role: string): void => onChangeWoo2({ ...workOrganisationOther, role })}
                  value={workOrganisationOther.role}
                  list={'corporation_role_list'}
                  name={intl.formatMessage(messages.role)}
                  inputName="role"
                  permissions={checkPermissionOthers('role')}
                />
                {!!actions.delete && (
                  <div className="deleteButton">
                    <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                  </div>
                )}
              </div>
            )}
            renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
              <div className="addButton">
                <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
              </div>
            )}
          />
        </Border>
      </div>
    </div>
  );
}

