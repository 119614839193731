import compare, { Patch } from '@mms-v3/patch-lib';
import * as React from 'react';
import { WorkApi, ProjectApi, OrganisationApi, PersonApi } from 'api/core/apis';
import * as ApiModels from 'api/core/models';
import { connectOAuth2, OAuth2AwareProps } from 'Auth/utils/connect';
import { ViewType } from 'Detail/types';
import { Edit } from 'Edit/data';
import { BaseDetailModel as BaseModel, DetailEditModels, DetailEditProps, EditMode } from 'Edit/types';
import { emptyModelProject, emptyModelOrg, emptyModelPerson, emptyModelWork } from '../data/emptyModels';
import cloneDeep from 'lodash/cloneDeep';
import { injectIntl, WrappedComponentProps as IntlProps } from 'react-intl';

// Logger
import getLogger from 'log';
import { useEffect, useState } from 'react';
import { useStatusCode } from 'Utils/StatusCodeHandler';
import { handleStatusCode422 } from '../../Utils/StatusCodeUtils';
import { OrganisationDTO, PersonDTO, PersonNameDTO, ProjectDTO, WorkDTO, WorkPublisherDTO } from 'api/core/models';
import { ApiResponse } from '../../api/core';
import { DashboardType } from '../../Dashboard/types';
const logger = getLogger('DetailEdit/container');

interface Props<Model extends BaseModel> {
  entity: ViewType;
  mode: EditMode;
  id?: number;
  newModelOverwrite?: Model;
}

type OwnProps<Model extends BaseModel> = OAuth2AwareProps<Props<Model> & IntlProps>;

/**
 * Factory for a connected dashboard with a desired entity type
 *
 * @param props
 */
function ConnectedInnerEdit<Model extends BaseModel>(props: OwnProps<Model>) {
  const [deleteInit, setDeleteInit] = useState<boolean>(false);
  const [deleteDone, setDeleteDone] = useState<boolean>(false);
  const [requestDone, setRequestDone] = useState<boolean>(false);
  const [requestActive, setRequestActive] = useState<boolean>(false);
  const [modelType, setModelType] = useState<ViewType>();
  const [fetchError, setFetchError] = useState<any>();
  const [currentModel, setCurrentModel] = useState<Model>();
  const workApi = props.apiWithAuth(new WorkApi());
  const projectApi = props.apiWithAuth(new ProjectApi());
  const organisationApi = props.apiWithAuth(new OrganisationApi());
  const personApi = props.apiWithAuth(new PersonApi());
  const intl = props.intl;
  const { setStatusCode } = useStatusCode();
  let modified: Model;

  const getNewModel = (): Model => {
    if (props.newModelOverwrite)
      return props.newModelOverwrite;
    switch (props.entity) {
      case ViewType.PROJECTS:
        return cloneDeep(emptyModelProject);
      case ViewType.ORGS:
        return cloneDeep(emptyModelOrg);
      case ViewType.PEOPLE:
        return cloneDeep(emptyModelPerson);
      case ViewType.WORKS:
        return cloneDeep(emptyModelWork);
      default:
        logger.find('getNewModel').error('Illegal entity type', props.entity);
        break;
    }
  };

  const [modifiedModel, setModifiedModel] = useState<Model>(getNewModel());

  const getAdminPropName = (): string => {
    switch (props.entity) {
      case ViewType.PROJECTS:
        return 'adminDataProject';
      case ViewType.ORGS:
        return 'adminDataOrganisation';
      case ViewType.PEOPLE:
        return 'adminDataPerson';
      case ViewType.WORKS:
        return 'adminDataWork';
      default:
        logger.find('getAdminPropName').error('Illegal entity type', props.entity);
        break;
    }
  };

  const adminPropName: string = getAdminPropName();

  useEffect(()=>{
    setModifiedModel(getNewModel());

    if (modelType === props.entity || fetchError || requestActive) {
      return;
    }

    if (props.mode === EditMode.UPDATE) {
      fetchModel();
    }
  },[]);

  useEffect(()=>{
    if (props.mode === EditMode.INSERT && modelType !== props.entity) {
      setModifiedModel(getNewModel());
      setModelType(props.entity);
    }
  },[props]);

  useEffect(()=>{
    if (modifiedModel &&
      props.mode !== EditMode.UPDATE &&
      (modelType !== props.entity || props.id != modifiedModel.id)) {
        setModifiedModel(getNewModel());
        setModelType(props.entity);
      }

    if (modelType === props.entity || fetchError || requestActive) {
      return;
    }

    if (props.mode === EditMode.UPDATE) {
      fetchModel();
    }
  });

  const updateField = (field: keyof Model | string, value: any) => {
    value = value === '' ? null : value;
    if (field === 'workPersonOthers') {
      setModifiedModel({...modified, [field]: value});
      setModifiedModel((modifiedModelSet) => modifiedModelSet);
    } else {
      setModifiedModel({ ...modifiedModel, [field]: value });
      setModifiedModel((modifiedModelSet) => modifiedModelSet);
      modified = { ...modifiedModel, [field]: value };
    }
  };

  const _delete = async () => {
    setDeleteInit(true);
  };

  const deleteAbort = async () => {
    setDeleteInit(false);
  };

  const submit = async () => {
    if (requestActive) return;
    setRequestActive(true);
    try {
      if (props.mode === EditMode.INSERT) {
        let alertError: boolean = false;
        if(props.entity === ViewType.WORKS) {
          // Make sure there aren't any workPublishers having publisher wout id (as result of import-process) to
          // avoid duplicates in the database
          const work = modifiedModel as ApiModels.WorkDTO;
          work.workPublishers.forEach((wp: WorkPublisherDTO): void => {
            if(wp.publisher.id == null) {
              alertError = true;
              alert(intl.formatMessage({ id: "work.workpublisher.import.alert.publisher.msg(name)" }, { name: wp.publisher.name }));
            }
          });
        }
        if(!alertError) {
          sendCreateRequest(modified !== undefined && modified !== modifiedModel ? modified : modifiedModel)
          .then((response: ApiResponse<ProjectDTO> | ApiResponse<OrganisationDTO> | ApiResponse<PersonDTO> | ApiResponse<WorkDTO>): void => {
            (response.value() as Promise<unknown>)
            .then((res): void => {
              const newCurrentModel: Model = res as Model & {personNames?: PersonNameDTO[]};
              setCurrentModel(newCurrentModel);

              let _name: string;
              if (newCurrentModel.personNames) {
                const personName = newCurrentModel.personNames.filter((e) => e.isMain === true);
                _name = personName[0].givenname + ' ' + personName[0].surname;
              }
              else if (newCurrentModel.title) {
                _name = newCurrentModel.title;
              }
              else {
                _name = newCurrentModel.name;
              }

              setStatusCode({status: response.raw.status, action: props.mode, data: {entity: props.entity, name: _name, id: newCurrentModel.id}});
            });
          })
          .catch((error) => {
            if (error.status === 422) return handleStatusCode422(error, setStatusCode);
            setStatusCode({status: error.status});
          });
        }
      } else {
        // clone modifiedModel and convert dates to string, bcs json-patch can't handle it
        let diff: Patch[]= compare(currentModel, cloneDeep(modifiedModel));
        if(modified !== undefined && modified !== modifiedModel) diff = compare(currentModel, cloneDeep(modified));

        let _name: string;
        if (modifiedModel.personNames) {
          const personName = modifiedModel.personNames.filter((e) => e.isMain === true);
          _name = personName[0].givenname + ' ' + personName[0].surname;
        }
        else if (modifiedModel.title) {
          _name = modifiedModel.title;
        }
        else {
          _name = modifiedModel.name;
        }

        const response: ApiResponse<ProjectDTO> | ApiResponse<OrganisationDTO> | ApiResponse<PersonDTO> | ApiResponse<WorkDTO> = await sendPatchRequest(diff);
        setStatusCode({status: response.raw.status, action: props.mode, data: {entity: props.entity, name: _name, id: modifiedModel.id}});
      }
      setRequestDone(true);
    } catch (error) {
      setRequestActive(false);
      if (error.status === 422) return handleStatusCode422(error, setStatusCode);
      setStatusCode({status: error.status});
    }
    setRequestActive(false);
  };

  const updateAdminField = (field, value): void => {
    const m = modifiedModel;
    const a = modifiedModel[adminPropName];
    a[field] = value;
    m[adminPropName] = a;
    setModifiedModel(m);
  };

  const fetchModel = (): void => {
    setRequestActive(true);
    const modelType: DashboardType = props.entity;
    fetchModelData()
      .then((model: DetailEditModels): void => {
        setCurrentModel(cloneDeep(model));
        setModifiedModel(cloneDeep(model));
        setRequestActive(false);
        setModelType(modelType);
      })
      .catch(async (e) => {
        setStatusCode({status: e.status});
      });
  };

  const getRenderProps = (): DetailEditProps<Model> => {
    const { id, mode } = props;
    return {
      id,
      mode,
      modifiedModel: getModifiedModel(),
      currentModel,
      submit,
      delete: _delete,
      deleteAbort,
      redirect: getRedirect(),
      updateAdminField,
      updateField,
      deleteInit,
      deleteActive: !deleteDone && !requestActive,
      submitActive: !deleteDone && !requestActive,
    };
  };

  const getRedirect = (): string => {
    switch (props.entity) {
      case ViewType.PROJECTS:
        if (!requestDone && props.mode === EditMode.DELETE) {
          return `/search/projects`;
        }
        if (requestDone && currentModel) {
          return `/project/${currentModel.id}`;
        }
        return null;
      case ViewType.ORGS:
        if (!requestDone && props.mode === EditMode.DELETE) {
          return `/search/organisations`;
        }
        if (requestDone && currentModel) {
          return `/organisation/${currentModel.id}`;
        }
        return null;
      case ViewType.PEOPLE:
        if (!requestDone && props.mode === EditMode.DELETE) {
          return `/search/persons`;
        }
        if (requestDone && currentModel) {
          return `/person/${currentModel.id}`;
        }
        return null;
      case ViewType.WORKS:
        if (!requestDone && props.mode === EditMode.DELETE) {
          return `/search/works`;
        }
        if (requestDone && currentModel) {
          return `/work/${currentModel.id}`;
        }
        return null;
      default:
        logger.find('getRedirect').error('Illegal entity type', props.entity);
        break;
    }
  };

  const getModifiedModel = (): [Model, React.Dispatch<React.SetStateAction<Model>>][0] => {
    return modifiedModel && props.entity === modelType
      ? modifiedModel
      : getNewModel();
  };

  const fetchModelData = async () => {
    if (!props.id) {
      throw new Error('Id is required in Edit Mode. Please check the router.');
    }
    let model: DetailEditModels;
    switch (props.entity) {
      case ViewType.PROJECTS:
        model = await projectApi.getProjectReduced1({
          projectId: props.id,
          accept: 'application/project.full+json',
        });
        break;
      case ViewType.ORGS:
        model = await organisationApi.getReducedOrganisation1({
          organisationId: props.id,
          accept: 'application/orga.full+json',
        });
        break;
      case ViewType.PEOPLE:
        model = await personApi.getPerson({ personId: props.id });
        break;
      case ViewType.WORKS:
        model = await workApi.getWork({ workId: props.id });
        break;
      default:
        logger.find('fetchModelData').error('Illegal entity type', props.entity);
        break;
    }
    return model;
  };

  const sendCreateRequest = async (model: ApiModels.ProjectDTO | ApiModels.OrganisationDTO | ApiModels.PersonDTO | ApiModels.WorkDTO): Promise<ApiResponse<ProjectDTO> | ApiResponse<PersonDTO> | ApiResponse<WorkDTO> | ApiResponse<OrganisationDTO>> => {
    switch (props.entity) {
      case ViewType.PROJECTS:
        return (projectApi.createProjectRaw({
          projectDTO: model as ApiModels.ProjectDTO,
        })) ;
      case ViewType.ORGS:
        return (organisationApi.createOrganisationRaw({
          organisationDTO: model as ApiModels.OrganisationDTO,
        })) ;
      case ViewType.PEOPLE:
        return (personApi.createPersonRaw({
          personDTO: model as ApiModels.PersonDTO,
        })) ;
      case ViewType.WORKS:
        return (workApi.createWorkRaw({
          workDTO: model as ApiModels.WorkDTO,
        })) ;
      default:
        logger.find('sendCreateRequest').error('Illegal entity type', props.entity);
        break;
    }
  };

  const sendPatchRequest = async (patch: any): Promise<ApiResponse<ProjectDTO> | ApiResponse<PersonDTO> | ApiResponse<WorkDTO> | ApiResponse<OrganisationDTO>> => {
    if (!props.id) {
      throw new Error('Id is required in Edit Mode. Please check the router.');
    }
    switch (props.entity) {
      case ViewType.PROJECTS:
        return projectApi.updateProjectRaw({
          projectId: props.id,
          patchDocument: patch,
        });
      case ViewType.ORGS:
        return organisationApi.updateOrganisationRaw({ organisationId: props.id, patchDocument: patch });
      case ViewType.PEOPLE:
        return personApi.updatePersonRaw({
          personId: props.id,
          patchDocument: patch,
        });
      case ViewType.WORKS:
        return workApi.updateWorkRaw({ workId: props.id, patchDocument: patch });
      default:
        logger.find('sendPatchRequest').error('Illegal entity type', props.entity);
        break;
    }
  };

  const _props: DetailEditProps<Model> = getRenderProps();

  return React.createElement(Edit<Model>(props.entity), _props);
}

const ConnectedEdit = injectIntl(connectOAuth2(ConnectedInnerEdit));

export default ConnectedEdit;
