/* tslint:disable */
/* eslint-disable */
/**
 * Duplicate Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExternalIdDTO,
    ExternalIdDTOFromJSON,
    ExternalIdDTOFromJSONTyped,
    ExternalIdDTOToJSON,
    ReferencedPersonNameDTO,
    ReferencedPersonNameDTOFromJSON,
    ReferencedPersonNameDTOFromJSONTyped,
    ReferencedPersonNameDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkPersonDTO
 */
export interface WorkPersonDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkPersonDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPersonDTO
     */
    affiliation?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkPersonDTO
     */
    role?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkPersonDTO
     */
    sequence?: number;
    /**
     * 
     * @type {ReferencedPersonNameDTO}
     * @memberof WorkPersonDTO
     */
    personName?: ReferencedPersonNameDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkPersonDTO
     */
    topOrgas?: Array<string>;
    /**
     * 
     * @type {Array<ExternalIdDTO>}
     * @memberof WorkPersonDTO
     */
    externalIds?: Array<ExternalIdDTO>;
}

export function WorkPersonDTOFromJSON(json: any): WorkPersonDTO {
    return WorkPersonDTOFromJSONTyped(json, false);
}

export function WorkPersonDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkPersonDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'affiliation': !exists(json, 'affiliation') ? undefined : json['affiliation'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'sequence': !exists(json, 'sequence') ? undefined : json['sequence'],
        'personName': !exists(json, 'personName') ? undefined : ReferencedPersonNameDTOFromJSON(json['personName']),
        'topOrgas': !exists(json, 'topOrgas') ? undefined : json['topOrgas'],
        'externalIds': !exists(json, 'externalIds') ? undefined : ((json['externalIds'] as Array<any>).map(ExternalIdDTOFromJSON)),
    };
}

export function WorkPersonDTOToJSON(value?: WorkPersonDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'affiliation': value.affiliation,
        'role': value.role,
        'sequence': value.sequence,
        'personName': ReferencedPersonNameDTOToJSON(value.personName),
        'topOrgas': value.topOrgas,
        'externalIds': value.externalIds === undefined ? undefined : ((value.externalIds as Array<any>).map(ExternalIdDTOToJSON)),
    };
}


