import { isNullOrUndefined } from 'is-what';
import { OAuth2Config } from './containers/OAuth2Container';

const authUrl = !isNullOrUndefined(process.env.REACT_APP_AUTH_URL)
  ? process.env.REACT_APP_AUTH_URL
  : 'http://localhost:8080/mms3-auth';

const publicUrl = !isNullOrUndefined(process.env.REACT_APP_PUBLIC_URL)
  ? process.env.REACT_APP_PUBLIC_URL
  : 'http://localhost:3000';

export const oauth2Config: OAuth2Config = {
  authUrl: `${authUrl}/oauth2/authorize`,
  tokenUrl: `${authUrl}/oauth2/token`,
  clientId: 'frontend',
  clientSecret: 'fa6Fkxf4K9GHwvTV',
  flowType: 'code',

  logoutUrl: `${authUrl}/logout`,
};
