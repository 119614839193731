import { names, vocabularies, VocabularyName } from './data';

export function getVocabularyNames(): VocabularyName[] {
  return names as VocabularyName[];
}

function _buildVocabularyNameRaw(vocab: string, language: string, partition: string = ''): string {
  return `${vocab}_${language}${partition.length ? '_' + partition : ''}`;
}

function _buildVocabularyName(vocab: string, language: string, partition: string = ''): VocabularyName {
  const name = _buildVocabularyNameRaw(vocab, language, partition);
  if (!names.find((n) => n === name)) {
    throw new Error(`Unrecognized vocabulary name "${name}"`);
  }
  return name as VocabularyName;
}

export function getVocabularyRaw(vocabName: VocabularyName): Map<string, string> {
  return vocabularies[vocabName];
}

export function getVocabularyAsArray(vocab: string, language: string, partition: string = ''): {abbr: string; name: string}[] {
  return Array.from(getVocabulary(vocab, language, partition)).map(([abbr, name]) => ({ abbr, name }));
}

/**
 *
 * @param vocab
 * @param language
 * @param partition "*" => Vereinigung aller Partitionen
 */
export function getVocabulary(vocab: string, language: string, partition: string = ''): Map<string, string> {
  const baseName = _buildVocabularyNameRaw(vocab, language);

  if (partition !== '*') {
    let map: Map<string, string> = new Map<string, string>();
    // Setze map = die Werte der generic-Parition
    if (
      partition &&
      partition.length > 0 &&
      getVocabularyNames().filter((n) => n === baseName + '_generic').length === 1
    ) {
      map = new Map<string, string>([...getVocabularyRaw(_buildVocabularyName(vocab, language, 'generic'))]);
    }
    // Wenn es die angegebene Partition gibt, ersetzte die Map durch die Werte der angegebenen Partition
    if (
      partition &&
      partition.length > 0 &&
      getVocabularyNames().filter((n) => n === baseName + '_' + partition).length === 1
    ) {
      map = new Map<string, string>([...getVocabularyRaw(_buildVocabularyName(vocab, language, partition))]);
    }
    // Wenn map noch leer ist, ersetzte die Map durch die Werte
    if ([...map].length < 1 && getVocabularyNames().filter((n) => n === baseName).length === 1) {
      map = new Map<string, string>([...getVocabularyRaw(_buildVocabularyName(vocab, language, ''))]);
    }
    return map;
  }

  const vocabNames = getVocabularyNames().filter((n) => n === baseName || n.startsWith(baseName + '_'));
  return vocabNames
    .map((n) => getVocabularyRaw(n))
    .reduce(
      (a: Map<string, string>, v: Map<string, string>): Map<string, string> => new Map([...a, ...v]),
      new Map<string, string>()
    );
}

export function getNotationRaw(vocabName: VocabularyName, key: string): string {
  const vo = getVocabularyRaw(vocabName);
  const value = vo.get(key);
  if (undefined === value) {
    throw new Error(`Unrecognized key "${key}" in vocabulary`);
  }

  return value;
}

/**
 *
 * @param vocab
 * @param key
 * @param language
 * @param partition "*" => Vereinigung aller Partitionen
 */
export function getNotation(vocab: string, key: string, language: string, partition: string = ''): string {
  const vo = getVocabulary(vocab, language, partition);
  const value = vo.get(key);
  if (undefined === value) {
    throw new Error(`Unrecognized key "${key}" in vocabulary`);
  }

  return value;
}
