import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';
import { connectOAuth2, OAuth2AwareProps } from '../../../../../Auth/utils/connect';
import { PublisherApi, PublisherDTO } from '../../../../../api/core';
import { TextInput } from '../../../../../BaseLayout/components/form/generic/TextInput';
import { useState } from 'react';
import { Button } from '@theme-ui/components';
import { FieldRow } from 'Edit/components/theme';
import Popup from 'BaseLayout/components/popup/Popup';

/** @jsx jsx */
import { jsx } from 'theme-ui';

const messages = defineMessages({
  namePlaceholder: { id: 'work.attr.publisher.attr.name.placeholder'},
  createPublisher: { id: 'work.workpublisher.createPublisher'},
  createPublisherError: { id: 'work.attr.publisher.error'},
  createPublisherTitle: { id: 'work.workpublisher.createPublisher.title' }
});

interface Props {
  id?: number;
  onClose?: () => void;
  onCreated?: (workPublisher: PublisherDTO) => void;
}
type InnerProps = OAuth2AwareProps<Props> & WrappedComponentProps;

function InnerCreatePublisherPopup(props: InnerProps) {
  const [newPublisher, setNewPublisher] = useState({id:null, name: ''});
  const api = props.apiWithAuth(new PublisherApi());

  const onSubmit = (): void => {
    api
      .createPublisher({ publisherDTO: newPublisher })
      .then((publisher: PublisherDTO): void => {
        setNewPublisher({id: null, name: ''});
        props.onCreated(publisher);
        close();
      })
      .catch((e): void => {
        console.error(e);
        alert(props.intl.formatMessage(messages.createPublisherError));
      });
  };

  const close = (): void => {
    setNewPublisher({id: null, name: ''});
    props.onClose();
  };

  return (
    <Popup
      open={true}
      title={props.intl.formatMessage(messages.createPublisherTitle)}
      onClose={() => close()}
      buttons={[]}
    >
      <FieldRow>
        <TextInput
          placeholder={props.intl.formatMessage(messages.namePlaceholder)}
          name={props.intl.formatMessage(messages.createPublisher)}
          required={false}
          value={newPublisher.name}
          onChange={(_name: string): void => {
            setNewPublisher({id: null, name: _name});
          }}
        />
        <div className="saveButton">
          <Button
            sx={{
              backgroundColor: '#fff',
              color: 'primary',
              border: '1.5px',
              borderColor: 'light',
              borderStyle: 'solid',
              p: 4,
              mt: '0.25em',
              fontWeight: 'bold',
              fontSize: '0.85em'
            }}
            disabled={newPublisher.name.length < 1}
            onClick={async (e: React.FormEvent<HTMLButtonElement>) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <FormattedMessage id="work.detail.publisher.button.create" />
          </Button>
        </div>
      </FieldRow>
    </Popup>
  );
}

export const CreatePublisherPopup = connectOAuth2(injectIntl(InnerCreatePublisherPopup));
