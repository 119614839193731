import React, { ReactElement } from 'react';
import { SubjectDTO } from '../../../api/core/models';
import { MultiInputControls } from '../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultSubject } from '../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../BaseLayout/components/form/MultiInput/Buttons';
import { ValidationResult } from '../../validation/interfaces';

const messages = defineMessages({
  id: { id: 'subject.attr.id' },
  label: { id: 'subject.attr.label' },
  subjectId: { id: 'subject.attr.subjectId' },
  type: { id: 'subject.attr.type' },
});

interface SubjectsProps {
  checkPermission: CheckPermissionFunction;
  subjects: SubjectDTO[];
  onChange: (subject: SubjectDTO[]) => void;
  validation?: ValidationResult;
}

export function InnerSubjects({ subjects, onChange, intl, checkPermission, validation }: SubjectsProps & WrappedComponentProps): React.JSX.Element {
  return (
    <MultiInputControls<SubjectDTO>
      lines={subjects}
      onChange={onChange}
      remove
      add
      defaultRow={DefaultSubject}
      renderLines={(subject: SubjectDTO, onRowChange: (data: SubjectDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
        <div>
          <React.Fragment>
              <TextInputP
                name={intl.formatMessage(messages.label)}
                inputName={'label'}
                permissions={checkPermission('label')}
                value={subject.label ? subject.label : ''}
                onChange={(label: string): void => onRowChange({ ...subject, label })}
                validation={validation?.children[idx]?.label}
                autoFocus={true}
              />
              <TextInputP
                name={intl.formatMessage(messages.subjectId)}
                inputName={'subjectId'}
                permissions={checkPermission('subject_id')}
                value={subject.subjectId ? subject.subjectId : ''}
                onChange={(subjectId: string): void => onRowChange({ ...subject, subjectId })}
                validation={validation?.children[idx]?.subject_id}
              />
              <SelectInputP
                name={intl.formatMessage(messages.type)}
                inputName={'type'}
                permissions={checkPermission('type')}
                list={'unit_subject_type_list'}
                listPartition={'*'}
                value={subject.type ? subject.type : ''}
                onChange={(type: string): void => onRowChange({ ...subject, type })}
                validation={validation?.children[idx]?.type}
              />
            {!!actions.delete && (
              <div className='deleteButton'>
                <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
              </div>
            )}
          </React.Fragment>
        </div>
      )}
      renderEnd={(actionAdd: ((e: any) => void) | undefined): ReactElement => (
        <div className='addButton'>
          <AddButton onClick={actionAdd}/>
        </div>
      )}
    />
  );
}

export const Subjects = injectIntl(InnerSubjects);
