import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  WorkDTO,
  ReferencedOrganisationDTO,
  WorkPersonDTO,
  WorkIsPartOfDTO,
  WorkIsPartOfOtherDTO,
  WorkOtherTitleDTO,
  WorkTitleAbbreviationDTO,
  WorkPersonOtherDTO,
  WorkOrganisationDTO,
  WorkOrganisationOtherDTO,
  WorkProjectDTO,
  WorkProjectOtherDTO,
  EventPlaceDTO,
  WorkLanguageDTO,
  EventDTO,
  WorkDateTypeDTO,
  WorkPublisherDTO,
  WorkPublisherPlaceDTO,
  WorkPlaceDTO,
  UrlDTO,
  WorkGeoLocationDTO,
  WorkGeoLocationPointDTO,
  WorkRelatedIdentifierInternalDTO,
  WorkRelatedIdentifierDTO,
  WorkKeywordDTO,
  AdminDataWorkOpenAccessFundedDTO,
  AdminDataWorkOrcidSyncDTO,
  SubjectDTO,
  WorkDescriptionDTO,
  ExternalIdDTO,
} from 'api/core/models/';
import { OutLink } from 'BaseLayout/components/misc/OutLink';
import { Table, Content, Desc } from '../../components/theme';
import { Flex, Link } from '@theme-ui/components';
import { ViewType } from 'Detail/types';
import {
  getExternalPersonIdImage,
  renderDate,
  renderDateTime,
  renderExternalIds, renderIssued,
} from 'Detail/utils';
import { getDetailContextUrl } from 'data';
import { Notation } from 'Vocabulary/components/Notation';
import { boolFilter, formSubjectFilter, peerReviewStatusFilter, pubStatusFilter, pubtypeFilter } from 'Vocabulary/data';
import { FlexRow, sortWorks } from './utils';
import { MathJax } from 'better-react-mathjax';
import parse from 'html-react-parser';
/** @jsx jsx */
import { jsx } from 'theme-ui';

export interface DetailEditorAdminProps {
  work: WorkDTO;
}

export const DetailEditorAdmin: React.FC<DetailEditorAdminProps> = ({ work }: DetailEditorAdminProps): JSX.Element => {
  return (
    <div sx={{
        display: 'block-flex',
        ".MathJax.CtxtMenu_Attached_0": {
          display: 'contents'
        }
      }}>
      {/* Titeldaten und Beteiligte */}
      <Table name="info-group-table">
        <Desc name="info-group-desc">
          <h3>
            <FormattedMessage id="work.detail.table.title_data.desc.label" />
          </h3>
        </Desc>
        <Content name="info-group-content">
          <FlexRow className="work-title" label={<FormattedMessage id="work.title" />}>
            <MathJax>{parse(work.title)}</MathJax>
          </FlexRow>
          {!!work.subtitle && (
            <FlexRow className="work-subtitle" label={<FormattedMessage id="work.subtitle" />}>
              <MathJax>{parse(work.subtitle)} </MathJax>
            </FlexRow>
          )}
          {!!work.titleSupplement && (
            <FlexRow className="work-title-supplement" label={<FormattedMessage id="work.title_supplement" />}>
              <MathJax>{parse(work.titleSupplement)}</MathJax>
            </FlexRow>
          )}
          {!!work.workOtherTitles && (
            <FlexRow className="work-other-titles" label={<FormattedMessage id="work.otherTitles" />}>
              <ul>
                {work.workOtherTitles.map(
                  (workOtherTitleDTO: WorkOtherTitleDTO, i: number): JSX.Element => (
                    <li key={i} sx={{ ml: '1.2em' }}>
                      <Flex variant="layout.multiRow">
                        <MathJax>{parse(workOtherTitleDTO.otherTitle)}</MathJax>
                        <div className="work-other-title-language">
                          {' '}
                          (<Notation v="language_list" k={workOtherTitleDTO.language} />)
                        </div>
                      </Flex>
                    </li>
                  )
                )}
              </ul>
            </FlexRow>
          )}
          {!!work.subseriesTitle && (
            <FlexRow className="work-subseries-title" label={<FormattedMessage id="work.subseries_title" />}>
              <MathJax>{parse(work.subseriesTitle)}</MathJax>
            </FlexRow>
          )}
          {!!work.workTitleAbbreviations && (
            <FlexRow className="work-title-abbreviations" label={<FormattedMessage id="work.title_abbreviations" />}>
              <ul>
                {work.workTitleAbbreviations.map(
                  (abbr: WorkTitleAbbreviationDTO): JSX.Element => (
                    <li sx={{ ml: '1.2em' }}>
                      <MathJax>{parse(abbr.abbreviation)}</MathJax>
                    </li>
                  )
                )}
              </ul>
            </FlexRow>
          )}
          {!!work.workPersons && (
            <FlexRow
              className="work-persons"
              label={<FormattedMessage id="entity.persons" />}
              // TODO: rename label: label={<FormattedMessage id="work.persons" />}
            >
              <ol sx={{ listStyleType: 'none' }} className="work-person-list">
                {work.workPersons
                  .sort((per1: WorkPersonDTO, per2: WorkPersonDTO): number => per1.sequence - per2.sequence)
                  .map(
                    (person: WorkPersonDTO, i: number): JSX.Element => (
                      <li key={i} className="work-person">
                        <div className="work-person-name">
                          <Link href={getDetailContextUrl(ViewType.PEOPLE, person.personName.personId)}>
                            {person.personName.surname}
                            {!!person.personName.givenname && <>, {person.personName.givenname}</>}
                          </Link>
                        </div>
                        {!!person.personName.salutation && (
                          <div className="work-person-salutation">
                            <span className='pre-title'>
                              <FormattedMessage id="work.work_person.person_name.salutation" />:&nbsp;
                            </span>
                            <Notation v="salutation_list" k={person.personName.salutation} />
                          </div>
                        )}
                        {person.personName.isMain !== undefined && (
                          <div className="work-person-is-main">
                            <span className='pre-title'>
                              <FormattedMessage id="work.work_person.person_name.is_main" />:&nbsp;
                            </span>
                            {boolFilter(person.personName.isMain)}
                          </div>
                        )}
                        {!!person.topOrgas &&
                          person.topOrgas.map((topOrga: string, j: number): JSX.Element => (
                            <div className="work-person-affiliation" key={j}>
                              <span className='pre-title'>
                                <FormattedMessage id="work.attr.workPerson.attr.affiliation" /> {j + 1}:&nbsp;
                              </span>
                              {topOrga}
                            </div>
                          )
                        )}
                        {!!person.personName.personId && (
                          <div className="work-person-person-id">
                            <span className='pre-title'>
                              <FormattedMessage id="work.work_person.person_name.person_id" />:&nbsp;
                            </span>
                            {person.personName.personId}
                          </div>
                        )}
                        {!!person.role && (
                          <div className="work-person-role">
                            <span className='pre-title'>
                              <FormattedMessage id="work.work_person.role" />:&nbsp;
                            </span>
                            <Notation v="person_role_list" k={person.role} />
                          </div>
                        )}
                        <div className="work-person-sequence">
                          <span className='pre-title'>
                            <FormattedMessage id="work.work_person.sequence" />:&nbsp;
                          </span>
                          {person.sequence}
                        </div>
                      </li>
                    )
                  )}
              </ol>
            </FlexRow>
          )}
          {!!work.workPersonOthers && (
            <FlexRow
              className="work-person-others"
              label={<FormattedMessage id="work.attr.workPersonOthers" />}
              // TODO: rename label: label={<FormattedMessage id="work.work_person_others" />}
            >
              <ol sx={{ display: 'block-flex' }} className="work-person-other-list">
                {work.workPersonOthers
                  .sort((per1: WorkPersonOtherDTO, per2: WorkPersonOtherDTO): number => per1.sequence - per2.sequence)
                  .map(
                    (workPersonOtherDTO: WorkPersonOtherDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ listStyleType: 'none' }} className="work-person-other">
                        <div className="work-person-other-name">
                          {workPersonOtherDTO.surname}
                          {workPersonOtherDTO.givenname ? `, ${workPersonOtherDTO.givenname}` : ''}
                        </div>
                        {!!workPersonOtherDTO.externalIds && (
                          <div className='work-person-other-externalIds' sx={{display: 'inline-flex'}}>
                            {workPersonOtherDTO.externalIds.map((externalId: ExternalIdDTO, index: number) => (
                              <div key={index} sx={{flexWrap: 'wrap'}} className="work-person-other-externalIds-icon">
                                {getExternalPersonIdImage(externalId)}{externalId.externalId}
                                {(workPersonOtherDTO.externalIds[workPersonOtherDTO.externalIds.length-1] !== externalId) && (<>&nbsp;&nbsp;|&nbsp;&nbsp;</>)}
                              </div>
                            ))}
                          </div>
                        )}
                        {!!workPersonOtherDTO.role && (
                          <div className="work-person-other-role">
                            <span className='pre-title'>
                              <FormattedMessage id="work.work_person_other.role" />:&nbsp;
                            </span>
                            <Notation v="person_role_list" k={workPersonOtherDTO.role} />
                          </div>
                        )}
                        {!!workPersonOtherDTO.sequence && (
                          <div className="work-person-other-sequence">
                            <span className='pre-title'>
                              <FormattedMessage id="work.work_person_other.sequence" />:&nbsp;
                            </span>
                            {workPersonOtherDTO.sequence}
                          </div>
                        )}
                      </li>
                    )
                  )}
              </ol>
            </FlexRow>
          )}
          {!!work.workOrganisations && (
            <FlexRow
              className="work-organisations"
              label={<FormattedMessage id="work.attr.workOrganisations" />}
              // TODO: rename label: label={<FormattedMessage id="work.work_organisations" />}
            >
              <ol sx={{ display: 'block-flex' }}>
                {work.workOrganisations.map(
                  (workOrganisationDTO: WorkOrganisationDTO, i: number): JSX.Element => (
                    <li key={i} sx={{ listStyleType: 'none' }}>
                      <div className="work-organisation-name">
                        <Link href={getDetailContextUrl(ViewType.ORGS, workOrganisationDTO.organisation.id)}>
                          {workOrganisationDTO.organisation.name}
                        </Link>
                      </div>
                      {!!workOrganisationDTO.organisation?.id && (
                        <div className="work-organisation-id">
                          <FormattedMessage id="work.work_organisation.organisation_id" />:{' '}
                          {workOrganisationDTO.organisation.id}
                        </div>
                      )}
                      {!!workOrganisationDTO.role && (
                        <div className="work-organisation-role">
                          <FormattedMessage id="work.work_organisation.role" />:{' '}
                          <Notation v="corporation_role_list" k={workOrganisationDTO.role} />
                        </div>
                      )}
                    </li>
                  )
                )}
              </ol>
            </FlexRow>
          )}
          {!!work.workOrganisationOthers && (
            <FlexRow
              className="work-organisation-others"
              label={<FormattedMessage id="work.work_organisation_others" />}
            >
              <ol sx={{ display: 'block-flex' }}>
                {work.workOrganisationOthers.map(
                  (workOrganisationOtherDTO: WorkOrganisationOtherDTO, i: number): JSX.Element => (
                    <li key={i} sx={{ listStyleType: 'none' }}>
                      {!!workOrganisationOtherDTO.name && (
                        <div className="work-organisation-other-name">{workOrganisationOtherDTO.name}</div>
                      )}
                      {!!workOrganisationOtherDTO.role && (
                        <div className="work-organisation-other-role">
                          <FormattedMessage id="work.work_organisation_other.role" />:{' '}
                          <Notation v="corporation_role_list" k={workOrganisationOtherDTO.role} />
                        </div>
                      )}
                    </li>
                  )
                )}
              </ol>
            </FlexRow>
          )}
          {!!work.workProjects && (
            <FlexRow
              className="work-projects"
              label={<FormattedMessage id="work.attr.workProjects" />}
              // TODO: rename label: label={<FormattedMessage id="work.work_projects" />}
            >
              <ol sx={{ display: 'block-flex' }}>
                {work.workProjects.map(
                  (workProjectDTO: WorkProjectDTO, i: number): JSX.Element => (
                    <li key={i} sx={{ listStyleType: 'none' }}>
                      <div className="work-project-name">
                        <Link href={getDetailContextUrl(ViewType.PROJECTS, workProjectDTO.project.id)}>
                          {workProjectDTO.project.name}
                        </Link>
                      </div>
                      {!!workProjectDTO.project.id && (
                        <div className="work-project-id">
                          <FormattedMessage id="work.work_project.project_id" />: {workProjectDTO.project.id}
                        </div>
                      )}
                      {!!workProjectDTO.role && (
                        <div className="work-project-role">
                          <FormattedMessage id="work.work_project.role" />:{' '}
                          <Notation v="corporation_role_list" k={workProjectDTO.role} />
                        </div>
                      )}
                    </li>
                  )
                )}
              </ol>
            </FlexRow>
          )}
          {!!work.workProjectOthers && (
            <FlexRow className="work-project-others" label={<FormattedMessage id="work.work_project_others" />}>
              <ol sx={{ display: 'block-flex' }}>
                {work.workProjectOthers.map(
                  (workProjectOtherDTO: WorkProjectOtherDTO, i: number): JSX.Element => (
                    <li key={i} sx={{ listStyleType: 'none' }}>
                      {!!workProjectOtherDTO.name && (
                        <div className="work-project-other-name">{workProjectOtherDTO.name}</div>
                      )}
                      {!!workProjectOtherDTO.role && (
                        <div className="work-project-other-role">
                          <FormattedMessage id="work.work_project_other.role" />:{' '}
                          <Notation v="corporation_role_list" k={workProjectOtherDTO.role} />
                        </div>
                      )}
                    </li>
                  )
                )}
              </ol>
            </FlexRow>
          )}
          {!!work.events && (
            <FlexRow
              className="work-events"
              label={<FormattedMessage id="work.attr.events" />}
              // TODO: rename label: label={<FormattedMessage id="work.events" />}
            >
              <ol sx={{ listStyleType: 'none', display: 'block-flex' }}>
                {work.events.map(
                  (eventDTO: EventDTO, i: number): JSX.Element => (
                    <li key={i}>
                      {!!eventDTO.startDate && (
                        <div className="work-event-start-date">
                          <FormattedMessage id="work.event.start_date" />: {renderDate(eventDTO.startDate)}
                        </div>
                      )}
                      {!!eventDTO.endDate && (
                        <div className="work-event-end-date">
                          <FormattedMessage id="work.event.end_date" />: {renderDate(eventDTO.endDate)}
                        </div>
                      )}
                      {!!eventDTO.eventNumbering && (
                        <div className="work-event-numbering">
                          <FormattedMessage id="work.event.numbering" />: {eventDTO.eventNumbering}
                        </div>
                      )}
                      {!!eventDTO.eventName && (
                        <div className="work-event-name">
                          <FormattedMessage id="work.event.name" />: {eventDTO.eventName}
                        </div>
                      )}
                      {!!eventDTO.eventPlaces && (
                        <div className="work-event-places">
                          <FormattedMessage id="work.event.places" />:{' '}
                          <ul sx={{ listStyleType: 'none', display: 'inline' }}>
                            {eventDTO.eventPlaces.map(
                              (eventPlaceDTO: EventPlaceDTO, j: number): JSX.Element => (
                                <li key={i} sx={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                  {eventPlaceDTO.eventPlace}
                                  {eventDTO.eventPlaces.length - 1 > j && <>,&nbsp;</>}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                    </li>
                  )
                )}
              </ol>
            </FlexRow>
          )}
        </Content>
      </Table>

      {/* Basisdaten */}
      {(!!work.pubtype ||
        !!work.formSubject ||
        !!work.publicationStatus ||
        !!work.version ||
        !!work.resourceType ||
        !!work.workLanguages ||
        !!work.edition ||
        !!work.dataVersion ||
        !!work.numberOfVolumes ||
        !!work.issued ||
        !!work.workDateTypes ||
        !!work.workPlaces ||
        !!work.workPublishers ||
        !!work.size ||
        !!work.number ||
        (!!work.urls && work.urls.length > 0) ||
        !!work.format ||
        !!work.medium ||
        !!work.externalIds ||
        !!work.note) && (
        <Table name="info-group-table">
          <Desc name="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.basic_data.desc.label" />
            </h3>
          </Desc>
          <Content name="info-group-content">
            {!!work.pubtype && (
              <FlexRow
                className="work-pubtype"
                label={<FormattedMessage id="work.attr.pubtyp" />}
                // TODO: rename label: label={<FormattedMessage id="work.pubtyp" />}
              >
                {pubtypeFilter(work.pubtype)}
              </FlexRow>
            )}
            {!!work.formSubject && (
              <FlexRow
                className="work-form-subject"
                label={<FormattedMessage id="work.attr.formSubject" />}
                // TODO: rename label: label={<FormattedMessage id="work.form_subject" />}
              >
                {formSubjectFilter(work.formSubject)}
              </FlexRow>
            )}
            {!!work.publicationStatus && (
              <FlexRow
                className="work-publication-status"
                label={<FormattedMessage id="work.attr.publicationStatus" />}
                // TODO: rename label: label={<FormattedMessage id="work.publication_status" />}
              >
                {pubStatusFilter(work.publicationStatus)}
              </FlexRow>
            )}
            {!!work.version && (
              <FlexRow className="work-version" label={<FormattedMessage id="work.version" />}>
                <Notation v="version_list" k={work.version} />
              </FlexRow>
            )}
            {!!work.resourceType && (
              <FlexRow className="work-resource-type" label={<FormattedMessage id="work.resource_type" />}>
                <Notation v="resource_type_list" k={work.resourceType} />
              </FlexRow>
            )}
            {!!work.workLanguages && (
              <FlexRow className="work-languages" label={<FormattedMessage id="work.languages" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workLanguages.map(
                    (workLanguageDTO: WorkLanguageDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <Notation v="language_list" k={workLanguageDTO.language} />
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.edition && (
              <FlexRow className="work-edition" label={<FormattedMessage id="work.edition" />}>
                {work.edition}
              </FlexRow>
            )}
            {!!work.dataVersion && (
              <FlexRow className="work-data-version" label={<FormattedMessage id="work.data_version" />}>
                {work.dataVersion}
              </FlexRow>
            )}
            {!!work.numberOfVolumes && (
              <FlexRow className="work-number-of-volumes" label={<FormattedMessage id="work.number_of_volumes" />}>
                {work.numberOfVolumes}
              </FlexRow>
            )}
            {!!work.issued && (
              <FlexRow
                className="work-issued"
                label={<FormattedMessage id="work.attr.issued" />}
                // TODO: rename label: label={<FormattedMessage id="work.issued" />}
              >
                {renderIssued(work.issued)}
              </FlexRow>
            )}
            {!!work.workDateTypes && (
              <FlexRow className="work-date-types" label={<FormattedMessage id="work.date_types" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workDateTypes.map(
                    (workDateTypeDTO: WorkDateTypeDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <div>
                          <FormattedMessage id="work.date_type.date" />: {renderDate(workDateTypeDTO.date)}
                        </div>
                        <div>
                          <FormattedMessage id="work.date_type.type" />:{' '}
                          <Notation v="date_types_list" k={workDateTypeDTO.type} />
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.workPlaces && (
              <FlexRow className="work-places" label={<FormattedMessage id="work.places" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workPlaces.map(
                    (workPlaceDTO: WorkPlaceDTO, i: number): JSX.Element => (
                      <li key={i}>{workPlaceDTO.place}</li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.workPublishers && (
              <FlexRow className="work-publishers" label={<FormattedMessage id="work.publishers" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workPublishers.map(
                    (workPublisherDTO: WorkPublisherDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <div className="work-publisher-publisher">
                          <FormattedMessage id="work.publisher.publisher" />: {workPublisherDTO.publisher.name}
                        </div>
                        {!!workPublisherDTO.workPublisherPlace && (
                          <div className="work-publisher-places">
                            <FormattedMessage id="work.publisher.places" />:{' '}
                            <ul sx={{ listStyleType: 'none', display: 'inline' }}>
                              {workPublisherDTO.workPublisherPlace.map(
                                (workPublisherPlaceDTO: WorkPublisherPlaceDTO, j: number): JSX.Element => (
                                  <li key={j} sx={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                    {workPublisherPlaceDTO.place}
                                    {workPublisherDTO.workPublisherPlace.length - 1 > j && <>,&nbsp;</>}
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.numberOfPages && (
              <FlexRow className="work-number-of-pages" label={<FormattedMessage id="work.number_of_pages" />}>
                {work.numberOfPages}
              </FlexRow>
            )}
            {!!work.size && (
              <FlexRow className="work-size" label={<FormattedMessage id="work.size" />}>
                {work.size}
              </FlexRow>
            )}
            {!!work.number && (
              <FlexRow className="work-number" label={<FormattedMessage id="work.number" />}>
                {work.number}
              </FlexRow>
            )}
            {!!work.urls && work.urls.length > 0 && (
              <FlexRow
                className="work-urls"
                label={<FormattedMessage id="work.attr.urls" />}
                // TODO: rename label: label={<FormattedMessage id="work.urls" />}
              >
                <ul sx={{ listStyleType: 'none' }}>
                  {work.urls.map(
                    (urlDTO: UrlDTO, i: number): JSX.Element => (
                      <div key={i}>
                        {!!urlDTO.url && (
                          <li>
                            <OutLink href={urlDTO.url}>{urlDTO.url}</OutLink>
                          </li>
                        )}
                      </div>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.format && (
              <FlexRow className="work-format" label={<FormattedMessage id="work.format" />}>
                <Notation v="format_list" k={work.format} />
              </FlexRow>
            )}
            {!!work.medium && (
              <FlexRow className="work-medium" label={<FormattedMessage id="work.medium" />}>
                <Notation v="medium_list" k={work.medium} />
              </FlexRow>
            )}
            {!!work.peerReviewed && (
            <FlexRow className="work-peer-reviewed" label={<FormattedMessage id="work.peer_reviewed" />}>
              {peerReviewStatusFilter(work.peerReviewed)}
            </FlexRow>
            )}
            {!!work.externalIds && (
              <FlexRow className="work-external-ids" label={<FormattedMessage id="work.attr.externalId" />}>
                <div
                  sx={{
                    a: {
                      display: 'inline-flex',
                      flexWrap: 'wrap',
                      svg: {
                        mt: '0.4em',
                      },
                    },
                    ul: {
                      listStyleType: 'none',
                    },
                  }}
                >
                  {renderExternalIds(work.externalIds, 'work')}
                </div>
              </FlexRow>
            )}
            {!!work.note && (
              <FlexRow className="work-note" label={<FormattedMessage id="work.note" />}>
                {work.note}
              </FlexRow>
            )}
          </Content>
        </Table>
      )}

      {/* Spezifische Angaben */}
      {(!!work.publicationNumber ||
        !!work.applicationNumber ||
        !!work.applicationCountry ||
        work.priorityPatentApplication !== undefined ||
        !!work.priorityCountry ||
        !!work.priorityNumber ||
        !!work.grantNumber ||
        !!work.patentFamily ||
        !!work.patentId ||
        !!work.patentType ||
        !!work.workGeoLocations) && (
        <Table name="info-group-table">
          <Desc name="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.specific_info.desc.label" />
            </h3>
          </Desc>
          <Content name="info-group-content">
            {!!work.publicationNumber && (
              <FlexRow className="work-publication-number" label={<FormattedMessage id="work.publication_number" />}>
                {work.publicationNumber}
              </FlexRow>
            )}
            {!!work.applicationNumber && (
              <FlexRow className="work-application-number" label={<FormattedMessage id="work.application_number" />}>
                {work.applicationNumber}
              </FlexRow>
            )}
            {!!work.applicationCountry && (
              <FlexRow className="work-application-country" label={<FormattedMessage id="work.application_country" />}>
                <Notation v="country_list" k={work.applicationCountry} />
              </FlexRow>
            )}
            {work.priorityPatentApplication !== undefined && (
              <FlexRow
                className="work-priority-patent-application"
                label={<FormattedMessage id="work.priority_patent_application" />}
              >
                {boolFilter(work.priorityPatentApplication)}
              </FlexRow>
            )}
            {!!work.priorityCountry && (
              <FlexRow className="work-priority-country" label={<FormattedMessage id="work.priority_country" />}>
                <Notation v="country_list" k={work.priorityCountry} />
              </FlexRow>
            )}
            {!!work.priorityNumber && (
              <FlexRow className="work-priority-number" label={<FormattedMessage id="work.priority_number" />}>
                {work.priorityNumber}
              </FlexRow>
            )}
            {!!work.grantNumber && (
              <FlexRow className="work-grant-number" label={<FormattedMessage id="work.grant_number" />}>
                {work.grantNumber}
              </FlexRow>
            )}
            {!!work.patentFamily && (
              <FlexRow className="work-patent-family" label={<FormattedMessage id="work.patent_family" />}>
                {work.patentFamily}
              </FlexRow>
            )}
            {!!work.patentId && (
              <FlexRow className="work-patent-id" label={<FormattedMessage id="work.patent_id" />}>
                {work.patentId}
              </FlexRow>
            )}
            {!!work.patentType && (
              <FlexRow className="work-patent-type" label={<FormattedMessage id="work.patent_type" />}>
                <Notation v="affiliations_list" k={work.patentType} />
              </FlexRow>
            )}
            {!!work.workGeoLocations && (
              <FlexRow className="work-geo-locations" label={<FormattedMessage id="work.geo_locations" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workGeoLocations.map(
                    (workGeoLocationDTO: WorkGeoLocationDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <h4>
                          {i + 1}. <FormattedMessage id="work.attr.geoLocation" />
                        </h4>
                        {!!workGeoLocationDTO.pointLongitude && (
                          <div className="work-geo-location-point-longitude">
                            <FormattedMessage id="work.geo_location.point_longitude" />:{' '}
                            {workGeoLocationDTO.pointLongitude}
                          </div>
                        )}
                        {!!workGeoLocationDTO.pointLatitude && (
                          <div className="work-geo-location-point-latitude">
                            <FormattedMessage id="work.geo_location.point_latitude" />:{' '}
                            {workGeoLocationDTO.pointLatitude}
                          </div>
                        )}
                        {(!!workGeoLocationDTO.boxWestBoundLongitude ||
                          !!workGeoLocationDTO.boxEastBoundLongitude ||
                          !!workGeoLocationDTO.boxSouthBoundLatitude ||
                          !!workGeoLocationDTO.boxNorthBoundLatitude) && (
                          <h4>
                            <FormattedMessage id="work.attr.geoLocation.attr.geoLocationBox" />
                          </h4>
                        )}
                        {!!workGeoLocationDTO.boxWestBoundLongitude && (
                          <div className="work-geo-location-box-west-bound-longitude">
                            <FormattedMessage id="work.geo_location.box_west_bound_longitude" />:{' '}
                            {workGeoLocationDTO.boxWestBoundLongitude}
                          </div>
                        )}
                        {!!workGeoLocationDTO.boxEastBoundLongitude && (
                          <div className="work-geo-location-box-east-bound-longitude">
                            <FormattedMessage id="work.geo_location.box_east_bound_longitude" />:{' '}
                            {workGeoLocationDTO.boxEastBoundLongitude}
                          </div>
                        )}
                        {!!workGeoLocationDTO.boxSouthBoundLatitude && (
                          <div className="work-geo-location-box-south-bound-latitude">
                            <FormattedMessage id="work.geo_location.box_south_bound_latitude" />:{' '}
                            {workGeoLocationDTO.boxSouthBoundLatitude}
                          </div>
                        )}
                        {!!workGeoLocationDTO.boxNorthBoundLatitude && (
                          <div className="work-geo-location-box-north-bound-latitude">
                            <FormattedMessage id="work.geo_location.box_north_bound_latitude" />:{' '}
                            {workGeoLocationDTO.boxNorthBoundLatitude}
                          </div>
                        )}
                        {!!workGeoLocationDTO.place && (
                          <div className="work-geo-location-place">
                            <FormattedMessage id="work.geo_location.place" />: {workGeoLocationDTO.place}
                          </div>
                        )}
                        {!!workGeoLocationDTO.workGeoLocationPolygon && (
                          <div className="work-geo-location-polygon">
                            <h4>
                              <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon" />
                            </h4>
                            {(!!workGeoLocationDTO.workGeoLocationPolygon.inLongitude ||
                              !!workGeoLocationDTO.workGeoLocationPolygon.inLatitude) && (
                              <h4>
                                <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon.attr.geoLocationInPolygonPoints" />
                              </h4>
                            )}
                            {!!workGeoLocationDTO.workGeoLocationPolygon.inLongitude && (
                              <div className="work-geo-location-polygon-in-longitude">
                                <FormattedMessage id="work.geo_location.polygon.in_longitude" />:{' '}
                                {workGeoLocationDTO.workGeoLocationPolygon.inLongitude}
                              </div>
                            )}
                            {!!workGeoLocationDTO.workGeoLocationPolygon.inLatitude && (
                              <div className="work-geo-location-polygon-in-latitude">
                                <FormattedMessage id="work.geo_location.polygon.in_latitude" />:{' '}
                                {workGeoLocationDTO.workGeoLocationPolygon.inLatitude}
                              </div>
                            )}
                            {!!workGeoLocationDTO.workGeoLocationPolygon.workGeoLocationPoints && (
                              <>
                                <h4>
                                  <FormattedMessage id="work.attr.geoLocation.attr.geoLocationPolygon.attr.geoLocationPolygonPoint" />
                                </h4>
                                <div className="work-geo-location-polygon-points">
                                  <ul sx={{ listStyleType: 'none' }}>
                                    {workGeoLocationDTO.workGeoLocationPolygon.workGeoLocationPoints.map(
                                      (workGeoLocationPointDTO: WorkGeoLocationPointDTO, j: number): JSX.Element => (
                                        <li key={j}>
                                          <div className="work-geo-location-polygon-point-longitude">
                                            <FormattedMessage id="work.geo_location.polygon.point.longitude" />:{' '}
                                            {workGeoLocationPointDTO.longitude}
                                          </div>
                                          <div className="work-geo-location-polygon-point-latitude">
                                            <FormattedMessage id="work.geo_location.polygon.point.latitude" />:{' '}
                                            {workGeoLocationPointDTO.latitude}
                                          </div>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
          </Content>
        </Table>
      )}

      {/* Gehört zu folgenden Werken */}
      {(!!work.workIsPartOfs ||
        !!work.workIsPartOfOthers ||
        !!work.workHasParts ||
        !!work.internalRelatedIdentifiersSource ||
        !!work.internalRelatedIdentifiersTarget ||
        !!work.externalRelatedIdentifiers) && (
        <Table name="info-group-table">
          <Desc name="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.belongs_to_work.desc.label" />
            </h3>
          </Desc>
          <Content name="info-group-content">
            {!!work.workIsPartOfs && (
              <FlexRow
                className="work-is-part-ofs"
                label={<FormattedMessage id="work.attr.isPartOf" />}
                // TODO: rename label: label={<FormattedMessage id="work.is_part_of" />}
              >
                <ul>
                  {work.workIsPartOfs.sort(sortWorks).map(
                    (workIsPartOfDTO: WorkIsPartOfDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className="work-is-part-of-referenced-work-title">
                          <b><FormattedMessage id="work.is_part_of.referenced_work.title" />:</b>&nbsp;
                          <Link href={getDetailContextUrl(ViewType.WORKS, workIsPartOfDTO.referencedWork.id)}>
                            <MathJax>{parse(workIsPartOfDTO.referencedWork.title)}</MathJax>
                          </Link>
                          {(!!workIsPartOfDTO.pageFirst || !!workIsPartOfDTO.pageLast || !!workIsPartOfDTO.issue || !!workIsPartOfDTO.volume || !!workIsPartOfDTO.articleId) && (
                            <>,&nbsp;</>
                          )}
                        </div>
                        {!!workIsPartOfDTO.pageFirst && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of.page_first" />:</b>&nbsp;{workIsPartOfDTO.pageFirst}
                            {(!!workIsPartOfDTO.pageLast || !!workIsPartOfDTO.issue || !!workIsPartOfDTO.volume || !!workIsPartOfDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfDTO.pageLast && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of.page_last" />:</b>&nbsp;{workIsPartOfDTO.pageLast}
                            {(!!workIsPartOfDTO.issue || !!workIsPartOfDTO.volume || !!workIsPartOfDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfDTO.issue && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of.issue" />:</b>&nbsp;{workIsPartOfDTO.issue}
                            {(!!workIsPartOfDTO.volume || !!workIsPartOfDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfDTO.volume && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of.volume" />:</b>&nbsp;{workIsPartOfDTO.volume}
                            {!!workIsPartOfDTO.articleId && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfDTO.articleId && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of.article_id" />:</b>&nbsp;{workIsPartOfDTO.articleId}
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.workIsPartOfOthers && (
              <FlexRow className="work-is-part-of-others" label={<FormattedMessage id="work.attr.isPartOfOther" />}>
                <ul>
                  {work.workIsPartOfOthers.sort(sortWorks).map(
                    (workIsPartOfOtherDTO: WorkIsPartOfOtherDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {!!workIsPartOfOtherDTO.name && (
                          <div sx={{ display: 'inline-flex' }}>
                            <b><FormattedMessage id="work.is_part_of_other.name" />:</b>&nbsp;<MathJax>{parse(workIsPartOfOtherDTO.name)}</MathJax>
                            {(!!workIsPartOfOtherDTO.pageFirst || !!workIsPartOfOtherDTO.pageLast || !!workIsPartOfOtherDTO.issue || !!workIsPartOfOtherDTO.volume || !!workIsPartOfOtherDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfOtherDTO.pageFirst && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of_other.page_first" />:</b>&nbsp;{workIsPartOfOtherDTO.pageFirst}
                            {(!!workIsPartOfOtherDTO.pageLast || !!workIsPartOfOtherDTO.issue || !!workIsPartOfOtherDTO.volume || !!workIsPartOfOtherDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfOtherDTO.pageLast && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of_other.page_last" />:</b>&nbsp;{workIsPartOfOtherDTO.pageLast}
                            {(!!workIsPartOfOtherDTO.issue || !!workIsPartOfOtherDTO.volume || !!workIsPartOfOtherDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfOtherDTO.issue && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of_other.issue" />:</b>&nbsp;{workIsPartOfOtherDTO.issue}
                            {(!!workIsPartOfOtherDTO.volume || !!workIsPartOfOtherDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfOtherDTO.volume && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of_other.volume" />:</b>&nbsp;{workIsPartOfOtherDTO.volume}
                            {!!workIsPartOfOtherDTO.articleId && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfOtherDTO.articleId && (
                          <div>
                            <b><FormattedMessage id="work.is_part_of_other.article_id" />:</b>&nbsp;{workIsPartOfOtherDTO.articleId}
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.workHasParts && (
              <FlexRow
                className="work-has-parts"
                label={<FormattedMessage id="work.attr.hasPart" />}
                // TODO: rename label: label={<FormattedMessage id="work.has_parts" />}
              >
                <ul>
                  {work.workHasParts.sort(sortWorks).map(
                    (workIsPartOfDTO: WorkIsPartOfDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className="work-has-part-referenced-work-title">
                          <b><FormattedMessage id="work.has_part.referenced_work.title" />:</b>&nbsp;
                          <Link href={getDetailContextUrl(ViewType.WORKS, workIsPartOfDTO.referencedWork.id)}>
                            <MathJax>{parse(workIsPartOfDTO.referencedWork.title)}</MathJax>
                          </Link>
                          {(!!workIsPartOfDTO.pageFirst || !!workIsPartOfDTO.pageLast || !!workIsPartOfDTO.issue || !!workIsPartOfDTO.volume || !!workIsPartOfDTO.articleId) && (
                            <>,&nbsp;</>
                          )}
                        </div>
                        {!!workIsPartOfDTO.volume && (
                          <div>
                            <b><FormattedMessage id="work.has_part.volume" />:</b>&nbsp;{workIsPartOfDTO.volume}
                            {(!!workIsPartOfDTO.pageFirst || !!workIsPartOfDTO.pageLast || !!workIsPartOfDTO.issue || !!workIsPartOfDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfDTO.issue && (
                          <div>
                            <b><FormattedMessage id="work.has_part.issue" />:</b>&nbsp;{workIsPartOfDTO.issue}
                            {(!!workIsPartOfDTO.pageFirst || !!workIsPartOfDTO.pageLast || !!workIsPartOfDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfDTO.pageFirst && (
                          <div>
                            <b><FormattedMessage id="work.has_part.page_first" />:</b>&nbsp;{workIsPartOfDTO.pageFirst}
                            {(!!workIsPartOfDTO.pageLast || !!workIsPartOfDTO.articleId) && (
                              <>,&nbsp;</>
                            )}
                            </div>
                        )}
                        {!!workIsPartOfDTO.pageLast && (
                          <div>
                            <b><FormattedMessage id="work.has_part.page_last" />:</b>&nbsp;{workIsPartOfDTO.pageLast}
                            {!!workIsPartOfDTO.articleId && (
                              <>,&nbsp;</>
                            )}
                          </div>
                        )}
                        {!!workIsPartOfDTO.articleId && (
                          <div>
                            <b><FormattedMessage id="work.has_part.article_id" />:</b>&nbsp;{workIsPartOfDTO.articleId}
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {(!!work.internalRelatedIdentifiersSource || !!work.internalRelatedIdentifiersTarget) && (
              <FlexRow
                className="work-related-identifiers-internal"
                label={<FormattedMessage id="work.related_identifiers_internal" />}
              >
                <ul>
                  {work.internalRelatedIdentifiersSource?.map(
                    (workRelatedIdentifierInternalDTO: WorkRelatedIdentifierInternalDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className="work-related-identifier-internal-referenced-work-id">
                          <b><FormattedMessage id="work.related_identifer_internal.thisWork" /> &nbsp;</b>
                        </div>
                        <div className="work-related-identifier-internal-relation-type">
                          <Notation v="relation_type_list" k={workRelatedIdentifierInternalDTO.relationType} />&nbsp;
                        </div>

                        <div className="work-related-identifier-internal-referenced-work-title">
                          <Link
                            href={getDetailContextUrl(
                              ViewType.WORKS,
                              workRelatedIdentifierInternalDTO.referencedWork.id
                            )}
                          >
                            <MathJax>{parse(workRelatedIdentifierInternalDTO.referencedWork.title)}</MathJax>
                          </Link>&nbsp;({workRelatedIdentifierInternalDTO.referencedWork.id})
                        </div>
                      </li>
                    )
                  )}
                  {!!work.internalRelatedIdentifiersSource && (<li key='x' sx={{ display: 'flex', flexWrap: 'wrap', minHeight: '0.5em' }}></li>)}
                  {work.internalRelatedIdentifiersTarget?.map(
                    (workRelatedIdentifierInternalDTO: WorkRelatedIdentifierInternalDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <div className="work-related-identifier-internal-referenced-work-title">
                        <Link
                          href={getDetailContextUrl(
                            ViewType.WORKS,
                            workRelatedIdentifierInternalDTO.referencedWork.id
                          )}
                        >
                          <MathJax>{parse(workRelatedIdentifierInternalDTO.referencedWork.title)}</MathJax>
                        </Link>&nbsp;({workRelatedIdentifierInternalDTO.referencedWork.id})&nbsp;
                      </div>
                      <div className="work-related-identifier-internal-relation-type">
                        <Notation v="relation_type_list" k={workRelatedIdentifierInternalDTO.relationType} />
                      </div>
                      <div className="work-related-identifier-internal-referenced-work-id">
                        <b>&nbsp;<FormattedMessage id="work.related_identifer_internal.thisWork" /></b>
                      </div>
                    </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.externalRelatedIdentifiers && (
              <FlexRow
                className="work-related-identifiers-external"
                label={<FormattedMessage id="work.related_identifiers_external" />}
              >
                <ul>
                  {work.externalRelatedIdentifiers.map(
                    (workRelatedIdentifierDTO: WorkRelatedIdentifierDTO, i: number): JSX.Element => (
                      <li key={i} sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <div className="work-related-identifier-external-relation-type">
                          <b><FormattedMessage id="work.related_identifer_external.relation_type" />:</b>&nbsp;
                          <Notation v="relation_type_list" k={workRelatedIdentifierDTO.relationType} />,&nbsp;
                        </div>
                        <div className="work-related-identifier-external-related-identifier">
                          <b><FormattedMessage id="work.related_identifer_external.related_identifier" />:</b>&nbsp;
                          {workRelatedIdentifierDTO.relatedIdentifier}
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
          </Content>
        </Table>
      )}

      {/* Inhalt */}
      {(!!work.workKeywords || !!work.workSubjects || !!work.workDescriptions) && (
        <Table name="info-group-table">
          <Desc name="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.content.desc.label" />
            </h3>
          </Desc>
          <Content name="info-group-content">
            {!!work.workKeywords && (
              <FlexRow className="work-keywords" label={<FormattedMessage id="work.keywords" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workKeywords.map(
                    (workKeywordDTO: WorkKeywordDTO, i: number): JSX.Element => (
                      <li key={i}>{workKeywordDTO.keyword}</li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.workSubjects && (
              <FlexRow className="work-subjects" label={<FormattedMessage id="work.subjects" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workSubjects.map(
                    (subjectDTO: SubjectDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <div>
                          <FormattedMessage id="work.subject.subject_id" />: {subjectDTO.subjectId}
                        </div>
                        <div>
                          <FormattedMessage id="work.subject.type" />:{' '}
                          <Notation v="subject_type_list" k={subjectDTO.type} />
                        </div>
                        {!!subjectDTO.label && (
                          <div>
                            <FormattedMessage id="work.subject.label" />: {subjectDTO.label}
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
            {!!work.workDescriptions && (
              <FlexRow className="work-descriptions" label={<FormattedMessage id="work.descriptions" />}>
                <ul sx={{ listStyleType: 'none' }}>
                  {work.workDescriptions.map(
                    (workDescriptionDTO: WorkDescriptionDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <MathJax>
                          <FormattedMessage id="work.description.content" />: {parse(workDescriptionDTO.content)}
                        </MathJax>
                        {!!workDescriptionDTO.language && (
                          <div>
                            <FormattedMessage id="work.description.language" />:{' '}
                            <Notation v="language_list" k={workDescriptionDTO.language} />
                          </div>
                        )}
                        {!!workDescriptionDTO.descriptionType && (
                          <div>
                            <FormattedMessage id="work.description.description_type" />:{' '}
                            <Notation v="description_type_list" k={workDescriptionDTO.descriptionType} />
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
          </Content>
        </Table>
      )}

      {/* Gehört zu folgenden Organisationen */}
      {!!work.adminDataWork.organisations && (
        <Table name="info-group-table">
          <Desc name="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.belongs_to_orga.desc.label" />
            </h3>
          </Desc>
          <Content name="info-group-content">
            {!!work.adminDataWork.organisations && (
              <FlexRow
                className="work-admin-data-organisations"
                label={<FormattedMessage id="work.attr.workOrganisations" />}
                // TODO: rename label: label={<FormattedMessage id="work.admin_data.organisations" />}
              >
                <ul sx={{ listStyleType: 'none' }}>
                  {work.adminDataWork.organisations.map(
                    (referencedOrganisationDTO: ReferencedOrganisationDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <Link href={getDetailContextUrl(ViewType.ORGS, referencedOrganisationDTO.id)}>
                          {referencedOrganisationDTO.name}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
          </Content>
        </Table>
      )}

      {/* Gehört zu folgenden Gruppen */}
      {!!work.adminDataWork.projects && (
        <Table name="info-group-table">
          <Desc name="info-group-desc">
            <h3>
              <FormattedMessage id="work.detail.table.belongs_to_project.desc.label" />
            </h3>
          </Desc>
          <Content name="info-group-content">
            {!!work.adminDataWork.projects && (
              <FlexRow
                className="work-admin-data-projects"
                label={<FormattedMessage id="work.attr.workGroups" />}
                // TODO: rename label: label={<FormattedMessage id="work.admin_data.projects" />}
              >
                <ul sx={{ listStyleType: 'none' }}>
                  {work.adminDataWork.projects.map(
                    (referencedOrganisationDTO: ReferencedOrganisationDTO, i: number): JSX.Element => (
                      <li key={i}>
                        <Link href={getDetailContextUrl(ViewType.PROJECTS, referencedOrganisationDTO.id)}>
                          {referencedOrganisationDTO.name}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
          </Content>
        </Table>
      )}

      {/* Administrative Daten */}
      <Table name="info-group-table">
        <Desc name="info-group-desc">
          <h3>
            <FormattedMessage id="work.detail.table.admin_data.desc.label" />
          </h3>
        </Desc>
        <Content name="info-group-content">
          <FlexRow className="work-id" label={<FormattedMessage id="work.id" />}>
            {work.id}
          </FlexRow>
          {!!work.adminDataWork.changed && (
            <FlexRow
              className="work-admin-data-changed"
              label={<FormattedMessage id="work.attr.adminDataWork.attr.changed" />}
              // TODO: rename label: label={<FormattedMessage id="work.admin_data.changed" />}
            >
              {renderDateTime(work.adminDataWork.changed)}
            </FlexRow>
          )}
          {!!work.adminDataWork.created && (
            <FlexRow
              className="work-admin-data-created"
              label={<FormattedMessage id="work.attr.adminDataWork.attr.created" />}
              // TODO: rename label: label={<FormattedMessage id="work.admin_data.created" />}
            >
              {renderDateTime(work.adminDataWork.created)}
            </FlexRow>
          )}
          {!!work.adminDataWork.origin && (
            <FlexRow
              className="work-admin-data-origin"
              label={<FormattedMessage id="work.attr.adminDataWork.attr.origin" />}
              // TODO: rename label: label={<FormattedMessage id="work.admin_data.origin" />}
            >
              {work.adminDataWork.origin}
            </FlexRow>
          )}
          {!!work.adminDataWork.deskman && (
            <FlexRow
              className="work-admin-data-deskman"
              label={<FormattedMessage id="work.attr.adminDataWork.attr.deskman" />}
              // TODO: rename label: label={<FormattedMessage id="work.admin_data.deskman" />}
            >
              {work.adminDataWork.deskman}
            </FlexRow>
          )}
          {work.adminDataWork.hidden !== undefined && (
            <FlexRow
              className="work-admin-data-hidden"
              label={<FormattedMessage id="work.attr.adminDataWork.attr.hidden" />}
              // TODO: rename label: label={<FormattedMessage id="work.admin_data.hidden" />}
            >
              {boolFilter(work.adminDataWork.hidden)}
            </FlexRow>
          )}
          {!!work.adminDataWork.editorialStatus && (
            <FlexRow
              className="work-admin-data-editorial-status"
              label={<FormattedMessage id="work.attr.adminDataWork.attr.editorialStatus" />}
              // TODO: rename label: label={<FormattedMessage id="work.admin_data.editorial_status" />}
            >
              <Notation v="editorial_status_list" k={work.adminDataWork.editorialStatus} />
            </FlexRow>
          )}
          {!!work.adminDataWork.adminDataWorkOpenAccess &&
            !!work.adminDataWork.adminDataWorkOpenAccess.adminDataWorkOpenAccessFundeds && (
              <FlexRow
                className="work-admin-data-open-access-fundeds"
                label={<FormattedMessage id="work.admin_data.open_access.fundeds" />}
              >
                <ul sx={{ listStyleType: 'none' }}>
                  {work.adminDataWork.adminDataWorkOpenAccess.adminDataWorkOpenAccessFundeds.map(
                    (adminDataWorkOpenAccessFundedDTO: AdminDataWorkOpenAccessFundedDTO): JSX.Element => (
                      <li>
                        <div>
                          <FormattedMessage id="work.admin_data.open_access.funded.type" />:{' '}
                          <Notation v="funded_list" k={adminDataWorkOpenAccessFundedDTO.type} />
                        </div>
                        <div>
                          <FormattedMessage id="work.admin_data.open_access.funded.affiliation" />:{' '}
                          <Notation v="affiliations_list" k={adminDataWorkOpenAccessFundedDTO.affiliation} />
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </FlexRow>
            )}
          {!!work.adminDataWork.adminDataWorkOpenAccess && !!work.adminDataWork.adminDataWorkOpenAccess.accessLevel && (
            <FlexRow
              className="work-admin-data-open-access-access-level"
              label={<FormattedMessage id="work.admin_data.open_access.access_level" />}
            >
              <Notation v="access_level_list" k={work.adminDataWork.adminDataWorkOpenAccess.accessLevel} />
            </FlexRow>
          )}
          {!!work.adminDataWork.license && (
              <FlexRow className="work-license" label={<FormattedMessage id="work.admin_data.license" />}>
                <Notation v="license_list" k={work.adminDataWork.license} />
              </FlexRow>
            )}
            {!!work.adminDataWork.licenseText && (
              <FlexRow className="work-license-text" label={<FormattedMessage id="work.admin_data.license_text" />}>
                {work.adminDataWork.licenseText}
              </FlexRow>
            )}
          {!!work.adminDataWork.licenseManagementStatus && (
            <FlexRow
              className="work-license-management-status"
              label={<FormattedMessage id="work.admin_data.license_management_status" />}
            >
              <Notation v="license_management_status_list" k={work.adminDataWork.licenseManagementStatus} />
            </FlexRow>
          )}
          {!!work.adminDataWork.adminDataWorkOpenAccess &&
            !!work.adminDataWork.adminDataWorkOpenAccess.articleProcessingCharge && (
              <FlexRow
                className="work-admin-data-open-access-article-processing-charge"
                label={<FormattedMessage id="work.admin_data.open_access.article_processing_charge" />}
              >
                {work.adminDataWork.adminDataWorkOpenAccess.articleProcessingCharge}
              </FlexRow>
            )}
          {work.adminDataWork.obligatoryDelivery !== undefined && (
            <FlexRow
              className="work-admin-data-obligatory-delivery"
              label={<FormattedMessage id="work.admin_data.obligatory_delivery" />}
            >
              {boolFilter(work.adminDataWork.obligatoryDelivery)}
            </FlexRow>
          )}
          {!!work.adminDataWork.adminDataWorkOrcidSyncs && (
            <FlexRow
              className="work-admin-data-orcid-syncs"
              label={<FormattedMessage id="work.detail.orcid_syncs" />}
              // TODO: rename label: label={<FormattedMessage id="work.admin_data.orcid_syncs" />}
            >
              <ul sx={{ listStyleType: 'none' }}>
                {work.adminDataWork.adminDataWorkOrcidSyncs.map(
                  (adminDataWorkOrcidSyncDTO: AdminDataWorkOrcidSyncDTO): JSX.Element => (
                    <li>
                      {!!adminDataWorkOrcidSyncDTO.orcidId && (
                        <div>
                          <FormattedMessage id="work.admin_data.orcid_sync.orcid_id" />:{' '}
                          {adminDataWorkOrcidSyncDTO.orcidId}
                        </div>
                      )}
                      {!!adminDataWorkOrcidSyncDTO.orcidPutCode && (
                        <div>
                          <FormattedMessage id="work.admin_data.orcid_sync.orcid_put_code" />:{' '}
                          {adminDataWorkOrcidSyncDTO.orcidPutCode}
                        </div>
                      )}
                      {!!adminDataWorkOrcidSyncDTO.orcidVisibility && (
                        <div>
                          <FormattedMessage id="work.admin_data.orcid_sync.orcid_visibility" />:{' '}
                          {adminDataWorkOrcidSyncDTO.orcidVisibility}
                        </div>
                      )}
                    </li>
                  )
                )}
              </ul>
            </FlexRow>
          )}
        </Content>
      </Table>
    </div>
  );
};

export default DetailEditorAdmin;
