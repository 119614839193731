/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReferencedWorkDTO,
    ReferencedWorkDTOFromJSON,
    ReferencedWorkDTOFromJSONTyped,
    ReferencedWorkDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WorkRelatedIdentifierInternalDTO
 */
export interface WorkRelatedIdentifierInternalDTO {
    /**
     * 
     * @type {number}
     * @memberof WorkRelatedIdentifierInternalDTO
     */
    id?: number | null;
    /**
     * 
     * @type {ReferencedWorkDTO}
     * @memberof WorkRelatedIdentifierInternalDTO
     */
    referencedWork?: ReferencedWorkDTO;
    /**
     * 
     * @type {string}
     * @memberof WorkRelatedIdentifierInternalDTO
     */
    relationType?: string;
}

export function WorkRelatedIdentifierInternalDTOFromJSON(json: any): WorkRelatedIdentifierInternalDTO {
    return WorkRelatedIdentifierInternalDTOFromJSONTyped(json, false);
}

export function WorkRelatedIdentifierInternalDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkRelatedIdentifierInternalDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'referencedWork': !exists(json, 'referencedWork') ? undefined : ReferencedWorkDTOFromJSON(json['referencedWork']),
        'relationType': !exists(json, 'relationType') ? undefined : json['relationType'],
    };
}

export function WorkRelatedIdentifierInternalDTOToJSON(value?: WorkRelatedIdentifierInternalDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'referencedWork': ReferencedWorkDTOToJSON(value.referencedWork),
        'relationType': value.relationType,
    };
}


