import React, { ReactElement } from 'react';
import { defineMessages, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { WorkDTO, WorkDateTypeDTO } from '../../../../api/core/models';
import { MultiInputControls } from '../../../../BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction, DataModelGate, WritableParam } from 'Auth/components/DataModelGate';
import { DefaultWorkDateType } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';
import { ValidationResult } from '../../../validation/interfaces';
import { DatePickerStyled } from 'BaseLayout/components/form/DatePickerDateRange/DatePicker';
import { DateObject } from 'react-multi-date-picker';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { isRequired } from '../../../../BaseLayout/components/form/generic/utils';

const messages = defineMessages({
  type: { id: 'work.attr.dates.attr.type' },
  date: { id: 'work.attr.dates.attr.date' },
  dateHelp: { id: 'work.attr.dates.attr.date.help' },
});

interface DateTypeProps {
  modifiedModel: WorkDTO;
  updateField: (key: string, value: WorkDateTypeDTO[]) => void;
  entity: string;
  pubtypeGroup: string;
  validation: ValidationResult;
}

export function WorkDateTypes({
  modifiedModel,
  entity,
  updateField,
  pubtypeGroup,
  validation
}: DateTypeProps): ReactElement {
  const intl: IntlShape = useIntl();
  return (
    <DataModelGate always={false} entity={entity} field="work_date_types">
      {(writable: WritableParam, readable: boolean, checkPermission: CheckPermissionFunction) => (
        <div className="col">
          <Border>
            <Styled.h3>
              <FormattedMessage id="work.attr.dates" />
            </Styled.h3>
            <MultiInputControls<WorkDateTypeDTO>
              remove
              lines={modifiedModel.workDateTypes ? modifiedModel.workDateTypes : []}
              onChange={(data: WorkDateTypeDTO[]): void => updateField('workDateTypes', data)}
              defaultRow={DefaultWorkDateType}
              add
              renderLines={(dateType: WorkDateTypeDTO, onChange2: (data: WorkDateTypeDTO) => void, actions: { [p: string]: (e?: any) => void }, idx: number): ReactElement => (
                <div className="innerBorder">
                  <div className="fieldRow" key={idx}>
                    <DatePickerStyled
                      label={messages.date.id}
                      help={intl.formatMessage(messages.dateHelp)}
                      validation={validation?.children[idx]?.date}
                      onChange={(date: DateObject): void =>
                        onChange2({ ...dateType, date: date === null ? undefined : new Date(date.format("YYYY-MM-DD")) })
                      }
                      value={dateType.date ? dateType.date : undefined}
                      required={isRequired(checkPermission('date'))}
                      disabled={!checkPermission('date').write}
                    />
                    <SelectInputP
                      listPartition={pubtypeGroup}
                      onChange={(type: string): void => onChange2({ ...dateType, type })}
                      name={intl.formatMessage(messages.type)}
                      permissions={checkPermission('type')}
                      validation={validation?.children[idx]?.type}
                      list="date_types_list"
                      value={dateType.type}
                      inputName="type"
                      placeholder=""
                    />
                    {!!actions.delete && (
                      <div className="deleteButton">
                        <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                      </div>
                    )}
                  </div>
                </div>
              )}
              renderEnd={(actionAdd) => (
                <div className="addButton">
                  <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
                </div>
              )}
            />
          </Border>

        </div>
      )}
    </DataModelGate>
  );
}
