import React, { useState } from 'react';
/** @jsx jsx */
import { jsx, Label } from 'theme-ui';
import { usePopper } from 'react-popper';
import { useBreakpointIndex } from '@theme-ui/match-media';


interface Props {
  label: string;
  help?: string;
  required?: boolean;
  invalid?: boolean;
  classes?: string[];
  variant: "labelY"|"labelX";
  children?: JSX.Element;
}

/**
 * Wrapper to add a label optional with help component to the child element.
 * Supports optional required and invalid styling.
 *
 * @param props
 * @constructor
 */
export function InputLabel(props: Props): React.JSX.Element {
  const breakpoint = useBreakpointIndex({ defaultIndex: 0 });
  const [helpVis, setHelpVis] = useState<boolean>(false);
  const [openWithClick, setOpenWithClick] = useState<boolean>(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: breakpoint <= 2 ? 'top-start' : 'right-start' });

  const classes = props.classes ? props.classes : [];
  if(props.invalid) classes.push('invalid');
  if(props.required) classes.push('required');

  const mobilePopper = {
    ".popper": {
      backgroundColor: '#9e9e9e',
      width: 'auto',
    }
  };

  return <Label variant={props.variant} className={classes.join(" ")}>
    <div sx={breakpoint < 3 ? mobilePopper : {}}>
      {props.label}
      {props.required ? '*' : ''}
      {!!props.help && (
        <>&nbsp;<i
          className="icon-info"
          ref={setReferenceElement}
          onMouseOver={() => {
            if (breakpoint >= 3) setHelpVis(true);
          }}
          onMouseOut={() => {
            if (breakpoint >= 3) { setHelpVis(false); setOpenWithClick(false);}
          }}
          onClick={() => {
            if ((breakpoint < 3 && !helpVis) || breakpoint === 3) {
              setHelpVis(true);
              setOpenWithClick(true);
            }
          }}
        /></>
      )}
      {helpVis && (
        <div className='popper'
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {breakpoint <= 3 && openWithClick &&
            <div sx={{
                borderStyle: 'solid',
                borderColor: 'danger',
                width: 'fit-content',
                float: 'right',
                ml: '0.5em'
              }}
              onClick={() => { setHelpVis(false); setOpenWithClick(false); }}
            >
              <i className="icon-schliessen_weiss"
                sx={{
                  "::before": {
                    color: "danger",
                    display: 'block',
                    m: '-4px'
                  },
                  fontSize: "1.6em",
                  cursor: "pointer",
                }}
              />
            </div>
          }
          <div sx={{
            mt: openWithClick ? '0.5em' : 0
          }}>
            {props.help}
          </div>
        </div>
      )}
    </div>
    {props.children}
  </Label>;
}
