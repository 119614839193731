/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonInstitutionalIdDTO
 */
export interface PersonInstitutionalIdDTO {
    /**
     * 
     * @type {number}
     * @memberof PersonInstitutionalIdDTO
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PersonInstitutionalIdDTO
     */
    institution?: string;
    /**
     * 
     * @type {string}
     * @memberof PersonInstitutionalIdDTO
     */
    value?: string;
}

export function PersonInstitutionalIdDTOFromJSON(json: any): PersonInstitutionalIdDTO {
    return PersonInstitutionalIdDTOFromJSONTyped(json, false);
}

export function PersonInstitutionalIdDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonInstitutionalIdDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'institution': !exists(json, 'institution') ? undefined : json['institution'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function PersonInstitutionalIdDTOToJSON(value?: PersonInstitutionalIdDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'institution': value.institution,
        'value': value.value,
    };
}


