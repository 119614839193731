import React from 'react';
import uwhLogoFooter from '../../assets/img/logos/logo_uwh.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { FormattedHTMLMessage, FormattedMessage, useIntl, defineMessages, IntlShape } from 'react-intl';

import { Flex, Box, Styled } from 'theme-ui';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FooterDesktopStyling } from './theme';

const messages = defineMessages({ dataProtection: { id: 'uwh.footer.dataProtection_link' },
                                  imprint: { id: 'uwh.footer.imprint_link'},
                                  email: { id: 'uwh.footer.email'},
                                  logoLink: { id: 'uwh.logo_link'},
                                  orcidLink: { id: 'orcid.logo_link'}
                                });

export const UWHFooterDesktop = (): React.JSX.Element => {
  const intl: IntlShape = useIntl();
  return (
    <FooterDesktopStyling>
      <Flex className='footer'>
        <Flex className='logos'
          sx={{
            ".orcidLogo": {
              mx: 10,
              my: 6,
            },
            ".uwhLogo": {
              ml: '60px',
              mt: '-23.5px',
              mb: '-19px'
            },
          }}
        >
          <a href={intl.formatMessage(messages.logoLink)}>
            <img className="uwhLogo" src={uwhLogoFooter} />
          </a>
          {/* ---> wenn orcid eingerichtet wurde
          <a href={intl.formatMessage(messages.orcidLink)}>
            <img className="orcidLogo" src={orcidLogo} />
          </a>
          */}
        </Flex>
        <Box px={8} py={6} className='info'>
          <p sx={{mb: '0.7em'}}>
            <Styled.a href={`mailto:${intl.formatMessage(messages.email)}`}>
              <FontAwesomeIcon icon={faAt} sx={{verticalAlign: '-0.15em'}}/> <FormattedMessage id="uwh.footer.email" />
            </Styled.a>
          </p>
          {/*
          <p>
            <FontAwesomeIcon icon={faPhone} sx={{verticalAlign: '-0.15em'}} /> <FormattedMessage id="uwh.footer.telephone" />
          </p>
          */}
          <br />
          <p sx={{mb: '0.4em'}}>
            <FormattedHTMLMessage id="uwh.footer.infotext" />
          </p>
          <Flex className='links'>
            <Styled.a href={intl.formatMessage(messages.dataProtection)}><FormattedMessage id="footer.dataProtection" /></Styled.a>
            <Styled.a href={intl.formatMessage(messages.imprint)}><FormattedMessage id="footer.imprint" /></Styled.a>
          </Flex>
        </Box>
      </Flex>
    </FooterDesktopStyling>
  );
};
