import * as React from 'react';
import { ReactElement } from 'react';
import { Subscribe } from 'unstated';
import { OAuth2Container } from '../containers/OAuth2Container';

interface OAuth2RequireAuthProps {
  preAuth?: () => void;
}

export const OAuth2RequireAuth: React.FC<OAuth2RequireAuthProps> = (props): ReactElement => {

  return (
    <Subscribe to={[OAuth2Container]}>
    {(container: OAuth2Container) => {
      if (container.authorizer().isAnonymous()) {
        if (props.preAuth) props.preAuth();
        window.location.href = container.getAuthUrl();
        return <></>;
      } else {
        return <>{props.children}</>;
      }
    }}
    </Subscribe>
  );
};
