import React, { useEffect } from 'react';
import workReportImg from 'BaseLayout/assets/img/homepage/work-report.svg';
import banner from 'BaseLayout/assets/img/mandants/hhu/banner.png';
import publicationEmbedImg from 'BaseLayout/assets/img/homepage/publication-embed.svg';
/** @jsx jsx */
import { jsx, Container, Flex, Input, Link } from 'theme-ui';
import { defineMessages, FormattedHTMLMessage, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { OAuth2Sender } from 'Auth/components';
import { Link as LinkRouter } from "react-router-dom";
import { useState } from 'react';
import ConnectedStatistics from 'HomePage/container/Statistics';
import { HomepageMobileStyling } from '../theme';
import { Props as HomepageProps } from 'HomePage/pages/Homepage';


const messages = defineMessages({ placeholder: { id: 'search.placeholder' } });

export const HHUHomepageMobile = (props: { props: HomepageProps }): React.JSX.Element => {
  const searchTerm: string = props.props.changedSearchTerm ? props.props.changedSearchTerm : props.props.searchTerm;
  const intl: IntlShape = useIntl();
  const [term, setTerm] = useState(searchTerm);

  const onChangeSearchHandler = (value) => {
    let search = value;
    if (!search && search !== 0) {
      search = "";
    }
    setTerm(search);
  };

  useEffect(() => {
    setTerm('');
  }, []);

  return (
    <React.Fragment>
        <HomepageMobileStyling>
            <Container variant="banner" className='banner' sx={{ backgroundImage:  `url(${banner})` }}></Container>
            <h1>
                <FormattedMessage id="hhu.homepage.title" />
            </h1>
            <Flex className='search'>
                <Input
                    variant="inputFrontSearch"
                    type="text"
                    placeholder={intl.formatMessage(messages.placeholder)}
                    value={term}
                    onChange={(e): void => {
                    onChangeSearchHandler(e.target.value);
                    }}
                    onKeyUp={(e): void => {
                    if(e.keyCode === 13) {
                        props.props.onSearchSubmit((e.target as HTMLInputElement).value);
                    }
                    }}
                />
                <i className="icon-suche" onClick={() => { props.props.onSearchSubmit(term); }} />
            </Flex>

            <div className='leftColumn'>
                <FormattedHTMLMessage id="hhu.homepage.intro.leftColumn" />
            </div>
            <div className='rightColumn'>
                <FormattedHTMLMessage id="hhu.homepage.intro.rightColumn" />
            </div>

            <div sx={{ backgroundColor: 'lighter' }}>
                <div className='image'>
                    <img src={workReportImg} />
                </div>
                <div className='text'>
                    <h2>
                        <FormattedMessage id="hhu.homepage.teaser1.title" />
                    </h2>
                    <p>
                        <FormattedHTMLMessage id="hhu.homepage.teaser1.text" />
                    </p>
                    <OAuth2Sender
                        render={({ authUrl }) => (
                            <Link className='button' href={authUrl}>
                                <FormattedMessage id="hhu.homepage.teaser1.linktext" />
                            </Link>
                        )}
                    />
                </div>
            </div>

            {/*  -> später, wenn orcid für hhu eingerichtet ist!
            <Card>
                <Grid className='card-grid' columns={[2]} mx-auto>
                    <div className='image'>
                        <img src={orcidLinkImg} />
                    </div>
                    <div className='text'>
                        <h2>
                            <FormattedMessage id="hhu.homepage.teaser2.title" />
                        </h2>
                        <p>
                            <FormattedHTMLMessage id="hhu.homepage.teaser2.text" />
                        </p>
                        <Link className='button' href="/user/settings/orcid">
                            <FormattedMessage id="hhu.homepage.teaser2.linktext" />
                        </Link>
                    </div>
                </Grid>
            </Card>
            */}

            <div /* x={{ backgroundColor: 'lighter' }} ---> wenn orcid eingerichtet wurde*/>
                <div className='image'>
                    <img src={publicationEmbedImg}/>
                </div>
                <div className='text'>
                    <h2>
                        <FormattedMessage id="hhu.homepage.teaser3.title" />
                    </h2>
                    <p>
                        <FormattedHTMLMessage id="hhu.homepage.teaser3.text" />
                    </p>
                    <LinkRouter className='button' to="/publist/generator">
                        <FormattedMessage id="hhu.homepage.teaser3.linktext" />
                    </LinkRouter>
                </div>
            </div>
            <ConnectedStatistics />
        </HomepageMobileStyling>
    </React.Fragment>
  );
};
