import React, { ReactElement } from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { SubjectDTO } from '../../../../api/core/models';
import { TextInputP } from 'BaseLayout/components/form/generic/TextInput';
import { MultiInputControls } from 'BaseLayout/components/form/MultiInput/MultiInputControls';
import { SelectInputP } from 'BaseLayout/components/form/generic/Select';
import { CheckPermissionFunction } from 'Auth/components/DataModelGate';
import { DefaultSubject } from '../../../data/emptyModels';
import { AddButton, DeleteButton } from '../../../../BaseLayout/components/form/MultiInput/Buttons';
import { Styled } from 'theme-ui';
import { Border } from 'Edit/components/theme';

/** @jsx jsx */
import { jsx } from 'theme-ui';
import { ValidationResult } from '../../../validation/interfaces';

const messages = defineMessages({
  workSubjectType: { id: 'work.attr.subject.attr.type' },
  workSubjectId: { id: 'work.attr.subject.attr.id' },
  workSubjectSubjectId: { id: 'work.attr.subject.attr.subjectId' },
  workSubjectLabel: { id: 'work.attr.subject.attr.label' },
});

interface WorkSubjectProps {
  workSubjects?: SubjectDTO[];
  listPartition: string;
  onChange: (subjects: SubjectDTO[]) => void;
  checkPermission: CheckPermissionFunction;
  validation: ValidationResult;
}

function InnerWorkSubjects({
  workSubjects,
  listPartition,
  onChange,
  intl,
  checkPermission,
  validation
}: WorkSubjectProps & WrappedComponentProps) {
  return (
    <div className="col">
      <Styled.h2>
        <FormattedMessage id="work.attr.subjects" />
      </Styled.h2>
      <Border>
        <MultiInputControls<SubjectDTO>
          lines={workSubjects}
          onChange={onChange}
          add
          remove
          defaultRow={DefaultSubject}
          renderLines={(subject: SubjectDTO, onChange2: (subjects: SubjectDTO) => void, actions: { [p: string]: (e: any) => void }, idx: number): ReactElement => (
            <div key={idx}>
              <React.Fragment>
                <div className="fieldRow">
                  <TextInputP
                    name={intl.formatMessage(messages.workSubjectSubjectId)}
                    value={subject.subjectId ? subject.subjectId : ''}
                    onChange={(subjectId: string): void => onChange2({ ...subject, subjectId })}
                    permissions={checkPermission('subject_id')}
                    validation={validation?.children[idx]?.subject_id}
                    autoFocus={true}
                  />
                  <TextInputP
                    name={intl.formatMessage(messages.workSubjectLabel)}
                    value={subject.label ? subject.label : ''}
                    onChange={(label: string): void => onChange2({ ...subject, label })}
                    permissions={checkPermission('label')}
                    validation={validation?.children[idx]?.label}
                  />
                  <SelectInputP
                    onChange={(type: string): void => onChange2({ ...subject, type })}
                    value={subject.type ? subject.type : ''}
                    listPartition={listPartition}
                    list={'subject_type_list'}
                    name={intl.formatMessage(messages.workSubjectType)}
                    inputName={'type'}
                    permissions={checkPermission('type')}
                    validation={validation?.children[idx]?.type}
                  />
                  {!!actions.delete && (
                    <div className="deleteButton">
                      <DeleteButton onClick={actions.delete} disabled={!checkPermission().write} />
                    </div>
                  )}
                </div>
              </React.Fragment>
            </div>
          )}
          renderEnd={(actionAdd: ((e: any) => void) | undefined) => (
            <div className="addButton">
              <AddButton onClick={actionAdd}  disabled={!checkPermission().write} />
            </div>
          )}
        />
      </Border>

    </div>
  );
}

export const WorkSubjects = injectIntl(InnerWorkSubjects);
