import { OAuth2Container } from 'Auth/containers/OAuth2Container';
import { getThemeNameFromAuth } from 'BaseLayout/components';
import React from 'react';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Subscribe } from 'unstated';

export const Left: React.FC<{name: string}> = ({ name, children }) => {
  return (
    <div
      className={name}
      sx={{
        display: 'block-flex',
        borderRight: '1.5px',
        borderRightColor: 'primary',
        borderRightStyle: 'solid',
        pt: '0.5em',
        pl: '0.5em',
        pb: '0.5em',
        minWidth: '20em',
        maxWidth: '20em'
      }}
    >
      {children}
    </div>
  );
};

export const Right: React.FC<{name: string}> = ({ name, children }) => {
  return (
    <div
      className={name}
      sx={{
        display: 'block-flex',
        pt: '0.5em',
        pl: '0.5em',
        pb: '0.5em',
        minWidth: '40em',
        maxWidth: 'auto'
      }}
    >
      {children}
    </div>
  );
};

export const Render: React.FC<{name: string}> = ({ name, children }) => {
  return (
    <div
      className={name}
      sx={{
        mb: '0.3em',
        ul: {
          listStyleType: 'none',
          display: 'flex',
          flexWrap: 'wrap',
          float: 'left',
          li: {
            display: 'inline-flex',
            flexWrap:'wrap',
            "::before": {
              content: '"|  "',
              whiteSpace: 'pre',
              pr: '0.1em'
            },
            pl: '0.5em',
            pr: '0.1em',
            float: 'left',
            a: {
              display: 'inline-flex',
              flexWrap: 'wrap',
              svg: {
                mt: '0.4em'
              }
            },
            img: {
              display: 'inline-flex',
              height: '1em',
              width: '1em',
              verticalAlign: 'middle',
              mr: '0.2em',
              mt: '0.35em'
            },
          },
          span: {
            display: 'inline-block',
            whiteSpace: 'unset',
            verticalAlign: 'middle'
          }
        }
      }}
    >
      {children}
    </div>
  );
};

export const Table: React.FC<{name: string}> = ({ name, children }) => {
  return (
    <div
      className={name}
      sx={{
        overflowX: 'hidden',
        display: 'block-flex',
        a: {
          textDecoration: 'underline',
          color: 'black',
          ":hover": {
            color:'secondary'
          }
        },
        ".work-has-part-referenced-work-title, .work-is-part-of-referenced-work-title, .work-related-identifier-internal-referenced-work-title": {
          a: {
            display: 'inline-flex',
            textDecoration: 'none',
            div: {
              color: 'primary',
              fontWeight: '600',
              ":hover": {
                color:'secondary'
              },
            }
          }
        }
      }}
    >
      {children}
    </div>
  );
};

export const Desc: React.FC<{name: string}> = ({ name, children }) => {
  return (
    <div
      className={name}
      sx={{
        fontSize: '1em',
        color: 'white',
        backgroundColor: 'primary',
        borderBottom: '1.5px',
        borderBottomColor: 'primary',
        borderBottomStyle: 'solid',
        h3: {
          fontSize: '1.2em',
          pl: '0.5em',
          pt: '0.25em',
          pb: '0.25em',
          fontWeight: 'bold'
        }
      }}
    >
      {children}
    </div>
  );
};

export const Content: React.FC<{name: string}> = ({ name, children }) => {
  return (
    <div
      className={name}
      sx={{
        display: 'block-flex',
        backgroundColor: 'white',
        border: '1.5px',
        borderColor: 'primary',
        borderStyle: 'solid',
        div: {
          color: '#000',
          ":last-child": {
            mb: '0px',
            pb: '0px',
            borderBottom: '0px'
          }
        },
        h4: {
          display: 'flex',
          color: 'primary',
          fontWeight: 'bold',
          fontSize: '1.05em'
        },
        ul: {
          width: '100%'
        },
        ".work-person-name, .work-person-other-name": {
          fontWeight: 'bold',
          color: 'primary',
          a: {
            color: 'primary',
            ":hover": {
              color: 'secondary',
            }
          },
        },
        ".pre-title": {
          fontWeight: 'bold'
        },
        ".work-person-other-externalIds-icon": {
          a: {
            mr: '2px'
          }
        },
        ".work-person, .work-person-other": {
          mb: '1.5em'
        },
        ".work-person-list, .work-person-other-list": {
          "> li:last-child": {
            mb: '0em'
          }
        }
      }}
    >
      {children}
    </div>
  );
};

export const Clear: React.FC<{name: string}> = ({ name, children }) => {
  return (
    <div
      className={name}
      sx={{
        clear: 'both'
      }}
    >
      {children}
    </div>
  );
};

export const DetailView: React.FC = ({children})=>{
  return (
    <Subscribe to={[OAuth2Container]}>
      {(c: OAuth2Container) => {
        const themeName =  getThemeNameFromAuth(c);

        return (
          <div sx={{
            padding: "0 3%",
            ".duplicate-popup": {
              ".react-tagsinput": { margin: "0.5em 0" },
              button: { borderRadius: "0px", marginRight: "0.5em" }
            },
            ".head-persons": {
              ul: { li: { a: { img: { width: "1em" } } } },
              margin: "0.5em auto 1.5em auto",
              "ul li": { display: "inline-block", whiteSpace: "pre" }
            },
            ".person": { marginBottom: "0.3em", fontSize: "1.2em" },
            ".avatar": { objectFit: "cover" },
            "img.external-id": {
              display: "inline-block",
              height: "1em",
              width: "1em",
              verticalAlign: "middle",
              marginRight: "0.2em",
              mb: "0.15em"
            },
            ".work-external-ids": {
              ".divider": { marginRight: "0.2em", marginLeft: "0.2em" }
            },
            ".detail-role": { marginTop: "0.3em" },
            ".detail-roleOther": { marginBottom: "0.3em" },
            ".detail-project": { fontSize: "1.2em", marginBottom: "0.3em" },
            ".detail-organisation": { fontSize: "1.2em", marginBottom: "0.3em" },
            ".detail-event": { fontSize: "1.2em", marginBottom: "0.3em" },
            ".detail-eventPeriod": { marginBottom: "0.3em" },
            ".detail-eventEndDate": { marginTop: "0.3em" },
            ".externalIds": {
              marginTop: "0.3em",
              marginBottom: "0.3em",
              ul: {
                listStyleType: "none",
                display: "inline-block",
                flexWrap: "wrap",
                li: {
                  display: "inline-block",
                  padding: "0 5px",
                },
                img: {
                  display: "inline-block",
                  height: "1em",
                  width: "1em",
                  verticalAlign: "middle",
                  marginRight: "0.2em"
                },
                span: {
                  display: "inline-block",
                  whiteSpace: "unset",
                  verticalAlign: "middle"
                },
                ".divider": { margin: "0 0.5em", color: "#646464" }
              },
              ".head-ids": { marginRight: "0.5em", fontWeight: "bold" }
            },
            ".hierarchy": {
              ol: {
                listStyleType: "none",
                li: { display: "inline-block", marginRight: "0.3em" }
              }
            },
            ".head": {
              marginBottom: "0.8em",
              display: "flex",
              position: "relative",
              justifyContent: "space-between"
            },
            ".orgs,\n  .projects": { li: { display: "inline-block", margin: "0.1em" } },
            ".funding-list": { "> li": { margin: "1em 0" } },
            ".aka": { fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif', ".head-aka": { fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif' } },
            ".head-year.orga": { marginRight: "0px" },
            ".status,\n  .children,\n  .parents,\n  .predecessor,\n  .successor,\n  .projects,\n  .websites": {
              marginTop: "0.5em",
              fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
              ".head-status": { fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif' }
            },
            ".websites": { marginTop: "1.5em" },
            ".websites ul": {
              display: "inline-grid",
              listStyleType: "none",
              marginLeft: "0.5em"
            },
            ".websites-more a": { fontWeight: "bold" },
            ".head-name": {
              display: "block",
              fontSize: "2.5em",
              fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
              fontStyle: "normal"
            },
            ".head-subtitle": {
              fontSize: "2em",
              display: "block",
              fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
              fontStyle: "normal"
            },
            ".head-meta": {
              marginTop: "0.5em",
              ".reference": {
                fontSize: "1.2em",
                fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
                fontStyle: "italic"
              },
              ".externalIds": { marginTop: "0.5em" }
            },
            ".head-edition": {
              fontWeight: 1.2,
              fontStyle: "italic"
            },
            ".head-year": { fontWeight: "bold", fontSize: "1.2em", marginRight: "0.5em" },
            ".head-data": { marginTop: "0.5em" },
            ".head-abstract": {
              fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
              fontStyle: "normal",
              lineHeight: "23px",
              fontSize: "1.1em",
              "ul li": { marginBottom: "1.5em", display: "block" }
            },
            ".head-keywords": {
              marginBottom: "0.8em",
              ul: {
                marginTop: "-0.5em",
                li: {
                  display: "inline-block",
                  padding: "0.5em",
                  borderRadius: "5px",
                  border: "1px solid black",
                  margin: "0.5em 0.5em auto auto"
                }
              }
            },
            ".head-url": {
              marginBottom: "0.8em",
              "*": {
                "&:first-child": { marginLeft: "0" },
                display: "inline-block",
                margin: "0 0.5em"
              }
            },
            ".head-keywords-color": {
              marginTop: "-1em",
              marginBottom: "1em",
              ul: {
                marginTop: "-0.5em",
                li: {
                  margin: "0.5em 0.5em auto auto",
                  display: "inline",
                  padding: "0.2em 0.6em 0.3em",
                  fontSize: "85%",
                  borderRadius: "6px",
                  fontWeight: "bold",
                  lineHeight: 1,
                  color: "#fff",
                  textAlign: "center",
                  "&.keyword-pubtype": { backgroundColor: "secondary" },
                  "&.keyword-subject": { backgroundColor: "rgb(107, 126, 148)" },
                  "&.keyword-resourcetype": { backgroundColor: "rgb(128, 128, 128)" },
                  "&.keyword-pubstatus": { backgroundColor: "rgb(216, 141, 119)" }
                }
              }
            },
            ".head-toolbar": {
              marginBottom: "3em",
              "button:first-child": { marginLeft: "0" },
              "button": {
                background: "primary",
                color: "white",
                border: "none",
                padding: "0.5em",
                margin: "0 0.25em",
                fontSize: "1em",
                cursor: "pointer",
                "&:active": { background: "secondary_active" },
                "&:hover": { background: "secondary_hover" }
              }
            },
            ".info-group": {
              marginBottom: "1em",
              a: {
                textDecoration: "none",
                color: "rgb(100, 100, 100)",
                "&:hover": { color: "primary" }
              },
              ".info-group-desc": {
                padding: "1em",
                fontSize: "1em",
                color: "#17365c",
                borderLeft: "5px solid #17365c",
                backgroundColor: "white",
                h3: { fontSize: "1.2em" },
                borderBottom: "1px solid #17365c"
              },
              ".info-group-content": {
                padding: "1em",
                marginLeft: "5px",
                backgroundColor: "white",
                boxShadow: "0 3px 4px rgba(200, 200, 200, 0.5)",
                li: { margin: "0px" },
                "> div": {
                  color: "#000",
                  marginBottom: "1.2em",
                  paddingBottom: "1.2em",
                  borderBottom: "1px solid #eee"
                },
                "> div:last-child": {
                  marginBottom: "0px",
                  paddingBottom: "0px",
                  borderBottom: "0px"
                },
                h4: { color: "#17365c", cssFloat: "left", width: "15%" },
                "ol,\n      ul,\n      .value,\n      .col-right": {
                  cssFloat: "left",
                  maxWidth: "85%"
                },
                "ul li": { marginLeft: "1em" },
                "ol ul": {
                  cssFloat: "none",
                  maxWidth: "100%",
                  li: { display: "inline-block" }
                },
                ".note": { margin: "0px" },
                ".websites,\n      .funding": { marginLeft: "0px" }
              }
            },
            ".info-group.open": {
              boxShadow: "none",
              ".info-group-content": { display: "block" }
            },
            ".clear": { clear: "both" },
            ".top": {
              minHeight: "15em",
              margin: "0 auto",
              display: "flex",
              a: {
                textDecoration: "none",
                color: "rgb(100, 100, 100)",
                "&:hover": { color: "primary" }
              },
              td: { color: "rgb(100, 100, 100)" },
              th: { color: "rgb(27, 27, 27)", textAlign: "left" },
              "th,\n    td": { padding: "0.2em 0.5em" },
              aside: {
                marginRight: "2em",
                paddingTop: "1em",
                flexGrow: 0,
                h1: { textAlign: "center" },
                ".top-tabs": {
                  paddingTop: "2.5em",
                  borderRight: "1px solid primary",
                  height: "100%",
                  ".tab": {
                    display: "block",
                    border: "none",
                    marginRight: "-1px",
                    width: "8em",
                    borderLeft: "5px solid body_bg",
                    "&.active": {
                      borderLeft: "5px solid primary",
                      borderBottom: "1px solid primary",
                      borderTop: "1px solid primary",
                      borderRight: "1px solid body_bg"
                    },
                    "&:hover": { borderLeft: "5px solid secondary" }
                  }
                }
              },
              article: { padding: "3em 0", flexGrow: 1 }
            },
            ".tab": {
              fontSize: "1.2em",
              padding: "0.2em 1em",
              cursor: "pointer",
              backgroundColor: "transparent",
              color: "primary"
            },
            ".dashboard-tabs": {
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: "primary",
              ".dashboard-tabs-inner": { display: "flex", justifyContent: "center" },
              ".tab": {
                border: "none",
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: "primary",
                borderTop: "5px solid transparent",
                marginBottom: "-1px",
                "&.active": {
                  borderTopWidth: "5px",
                  borderTopStyle: "solid",
                  borderTopColor: "primary",
                  borderLeftWidth: "1px",
                  borderLeftStyle: "solid",
                  borderLeftColor: "primary",
                  borderRightWidth: "1px",
                  borderRightStyle: "solid",
                  borderRightColor: "primary",
                },
                "&:hover": { borderTopWidth: "5px", borderTopStyle: "solid", borderTopColor: "secondary" }
              }
            },
            ".debug": { textAlign: "center" },
            ".edit": {
              float: "right",
              ".btn": { borderRadius: "0px", marginRight: "0.5em" }
            },
            "// Group\n  .stamp": {
              cssFloat: "right",
              transform: "rotate(12deg)",
              color: "#555",
              fontSize: "3rem",
              fontWeight: 700,
              border: "0.25rem solid #555",
              display: "inline-block",
              padding: "0.25rem 1rem",
              textTransform: "uppercase",
              borderRadius: "1rem",
              fontFamily: "'Courier'",
              WebkitMaskImage:
                "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png')",
              WebkitMaskSize: "944px 604px",
              mixBlendMode: "multiply"
            },
            ".is-nope": {
              color: "#d23",
              border: "0.5rem double #d23",
              transform: "rotate(3deg)",
              WebkitMaskPosition: "2rem 3rem",
              fontSize: "2rem"
            },
            ".head-year.project": { marginRight: "0px" },
            ".body.hidden": { display: "none" },
            ".fullOpen": { marginBottom: "1em", ".fullOpenBtn": { cursor: "pointer" } },
            ".partner,\n  .parent,\n  .funding": {
              fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif',
              ".head": {
                display: "initial",
                marginBottom: "initial",
                fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif'
              },
              "ul,\n    li": { display: "inline-block" },
              a: { fontFamily: themeName === 'uwh' ? 'Lato, sans-serif' : 'serif' }
            },
            ".citation-popup-container": { "&.hidden": { display: "none" } },
            ".work-has-part-referenced-work-title, .work-is-part-of-referenced-work-title, .work-related-identifier-internal-referenced-work-title": {
              a: {
                display: 'inline-flex',
                textDecoration: 'none',
              }
            },
            ".project-otherNames, .organisation-otherNames": {
              display: 'inline-flex'
            }
          }} >
            {children}
          </div>
        );
      }}
    </Subscribe>
  );
};
