/* tslint:disable */
/* eslint-disable */
/**
 * Publist Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DatePeriod
 */
export interface DatePeriod {
    /**
     * 
     * @type {Date}
     * @memberof DatePeriod
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DatePeriod
     */
    end?: Date;
}

export function DatePeriodFromJSON(json: any): DatePeriod {
    return DatePeriodFromJSONTyped(json, false);
}

export function DatePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): DatePeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
    };
}

export function DatePeriodToJSON(value?: DatePeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start === undefined ? undefined : (value.start.toISOString().substr(0,10)),
        'end': value.end === undefined ? undefined : (value.end.toISOString().substr(0,10)),
    };
}


