/* tslint:disable */
/* eslint-disable */
/**
 * Core Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchFacet
 */
export interface SearchFacet {
    /**
     * 
     * @type {string}
     * @memberof SearchFacet
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchFacet
     */
    count?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof SearchFacet
     */
    entries?: { [key: string]: number; };
}

export function SearchFacetFromJSON(json: any): SearchFacet {
    return SearchFacetFromJSONTyped(json, false);
}

export function SearchFacetFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFacet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'entries': !exists(json, 'entries') ? undefined : json['entries'],
    };
}

export function SearchFacetToJSON(value?: SearchFacet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'count': value.count,
        'entries': value.entries,
    };
}


