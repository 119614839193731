import * as React from 'react';
import { RenderTagProps } from 'react-tagsinput';
import { SuggestApi } from 'api/core/apis/SuggestApi';
import { ReferencedWorkDTO, SolrDocument } from 'api/core/models';
import { MultiAutocomplete } from 'BaseLayout/components/form';
import { DeleteStrategy, Props as MultiAutocompleteProps } from 'BaseLayout/components/form/MultiAutocomplete';
import { MathJax } from 'better-react-mathjax';
import parse from 'html-react-parser';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useState } from 'react';
import { ValidationResult } from '../../validation/interfaces';
import { InputLabel } from '../../../BaseLayout/components/form/generic/InputLabel';

export interface State {
  suggestions?: SolrDocument[];
}
export interface Props {
  onChange?: (values: any[]) => void;
  tags?: ReferencedWorkDTO[];
  autocompleteProps?: Partial<MultiAutocompleteProps>;
  single?: boolean;
  canDelete?: boolean;
  deleteStrategy?: DeleteStrategy;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  help?: string;
  inputProps?: {};
  renderId?: {};
  validation?: ValidationResult;
  autoFocus?: boolean;
}

export function WorkSuggest(props: Props) {
  const tags: ReferencedWorkDTO[] = props.tags ?? [];
  const onChange: (values: any[]) => void = props.onChange ? props.onChange : () => {};
  const single: boolean = props.single ?? false;
  const canDelete: boolean = props.canDelete ?? true;
  const required: boolean = props.required ?? false;
  const disabled: boolean = props.disabled ?? false;
  const renderId: {} = props.renderId ?? null;
  const autoFocus: boolean = props.autoFocus ?? false;
  const [suggestions, setSuggestions] = useState<SolrDocument[]>([]);
  const validation: ValidationResult = props.validation ?? { valid: true };
  if(required && tags.length < 1) {
    validation.valid = false;
    validation.reason = 'Empty required';
  }

  return (
    <InputLabel
      label={props.name}
      required={required}
      variant="labelY"
      help={props.help}
      invalid={validation && !validation.valid}>
      <MultiAutocomplete
        autoFocus={autoFocus}
        inputProps={props.inputProps}
        required={required}
        disabled={disabled}
        canDelete={canDelete}
        allwaysSuggest={true}
        remote={true}
        tags={tags}
        deleteStrategy={DeleteStrategy.SPLICE}
        single={single}
        options={suggestions}
        onSuggestionsClearRequested={() => {}}
        onChange={onChange}
        validation={validation}
        onSuggestionSelected={(event, { suggestion }: { suggestion: SolrDocument }) => {
          return { title: suggestion.main_title, id: suggestion.id, pubtype: suggestion.f_publication_type, subseries_title: suggestion.subseries_title, sub_title: suggestion.sub_title  };
        }}
        renderTag={(props: RenderTagProps) => {
          const { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
          if (!tag) {
            return <></>;
          }
          return (
            <span key={key} {...other} sx={{
              display: "inline-flex",
              backgroundColor: "darker",
              color: "white",
              px: 4,
              py: 2,
              mb: 2,
              mr: 3,
              ".MathJax.CtxtMenu_Attached_0": { display: 'contents' }
            }}>
              <MathJax>
                {parse(renderId ? getTagDisplayValue(`${tag.id}`) : getTagDisplayValue(`${tag.title}${tag.subseriesTitle ? ` / ${tag.subseriesTitle}` : ''}`))}
              </MathJax>
              {!renderId && (
                <div sx={{pl: 4}}>
                  {tag.id}
                </div>
              )}
              {!disabled && canDelete &&
                <a sx={{cursor: "pointer", color: "white", pl: 4}} onClick={() => onRemove(Number(key))}>x</a>
              }
            </span>
          );
        }}
        onSuggestionsFetchRequested={async (e) => {
          const api = new SuggestApi();
          const suggestions: SolrDocument[] = await api.workSuggest({ q: e.value });
          setSuggestions(suggestions);
        }}
        renderSuggestions={(suggestion: SolrDocument) => {
          return (
            <span sx={{".MathJax.CtxtMenu_Attached_0": { display: 'contents' }}}>
              <ul style={{ listStyleType: 'none' }}>
              {renderId && <li key={suggestion.id}><strong><small> ID: {suggestion.id}</small></strong></li>}
                <li>
                  <MathJax>{parse(suggestion.main_title ? suggestion.main_title : '')}</MathJax>
                  {suggestion.subseries_title && <MathJax><small> / {parse(suggestion.subseries_title)}</small></MathJax>}
                  {suggestion.sub_title && <MathJax><small>{parse(suggestion.sub_title)}</small></MathJax>}
                </li>
                <li>
                  <small>
                    <ul>
                      {suggestion.f_issued && <li key={suggestion.f_issued}> ({suggestion.f_issued})</li>}
                      {suggestion.person_name &&
                        suggestion.person_name.slice(0, 3).map((author: string) => <li key={author}> {author}</li>)
                      }
                    </ul>
                  </small>
                </li>
              </ul>
            </span>
          );
        }}
        {...props.autocompleteProps}
      />
    </InputLabel>
  );
}
